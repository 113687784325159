import React, { useEffect, useMemo, useState } from 'react';
import { LabelButton, NativeCheckbox, Select, TextField } from '@pp-labs/ui-components';
import AudiSpacer from 'utils/AudiSpacer';
import StepIndicator from 'utils/StepIndicator';
import { Form, Formik } from 'formik';
import { Text } from '@pp-labs/ui-components';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { AdminPanelSteps, StepModules } from './adminPanelTypes';
import { ConfigModuleIdentifiers } from 'ApiHandler/dclxInterfaces';
import { BaseStrings } from '../../../Localization/BaseStrings';

const validationIdentityProvider = Yup.object({
  hidden: Yup.string().required(strings.required),
  providerName: Yup.string().required(strings.required),
  clientId: Yup.string().required(strings.required),
  clientSecret: Yup.string().required(strings.required),
  issuer: Yup.string().required(strings.required),
  jwksUrl: Yup.string().required(strings.required),
}).required(strings.required);

const validation = Yup.object({
  hidden: Yup.string().required(strings.required),
}).required(strings.required);

type Module = {
  id: ConfigModuleIdentifiers;
  text: BaseStrings;
  hasDetails?: boolean;
  tmText?: BaseStrings;
};

type ModuleChecked = Module & {
  checked: boolean;
};
const allModules: Module[] = [
  { id: ConfigModuleIdentifiers.onDemandSessions, text: 'onDemandSessions' },
  {
    id: ConfigModuleIdentifiers.liveStreamingSessions,
    text: 'liveStreamingSessions',
  },
  {
    id: ConfigModuleIdentifiers.liveAma,
    text: 'liveAmas',
  },
  {
    id: ConfigModuleIdentifiers.videoConferenceSessions,
    text: 'videoConferenceSessions',
  },
  {
    id: ConfigModuleIdentifiers.mainSurvey,
    text: 'mainSurvey',
  },
  {
    id: ConfigModuleIdentifiers.liveChatInVideoConferenceSessions,
    text: 'liveChatInConferenceSessions',
  },
  {
    id: ConfigModuleIdentifiers.pollInVideoConferenceSessions,
    text: 'pollInConferenceSessions',
  },
  {
    id: ConfigModuleIdentifiers.surveyInLiveStreamingSessions,
    text: 'surveyInLiveSessions',
  },
  {
    id: ConfigModuleIdentifiers.surveyInOnDemandSessions,
    text: 'surveyInOnDemandSessions',
  },
  {
    id: ConfigModuleIdentifiers.surveyInVideoConferenceSessions,
    text: 'surveyInVideoConferenceSessions',
  },
  {
    id: ConfigModuleIdentifiers.introRequired,
    text: 'requireWatchingIntroVoDs',
  },
  {
    id: ConfigModuleIdentifiers.registerVideo,
    text: 'registerVideo',
    hasDetails: true,
    tmText: 'setRegistionVideo',
  },
  {
    id: ConfigModuleIdentifiers.gamification,
    text: 'gamification',
    tmText: 'setGamification',
  },
];
/** Module selection in Event Creation */
const EventSetupStepModules = (props: AdminPanelSteps) => {
  const [modules, setModules] = useState<ModuleChecked[]>([]);
  const [identityProviders, setIdentityProviders] = useState<boolean>(
    props.values.userPoolConfiguration?.identityProviders
      ? props.values.userPoolConfiguration.identityProviders.length > 0
      : props.initialData?.userPoolConfiguration?.identityProviders
      ? props.initialData?.userPoolConfiguration.identityProviders.length > 0
      : false
  );
  useEffect(() => {
    setModules(
      allModules.map((m) => ({
        ...m,
        checked:
          props.values.configModuleIds?.includes(m.id) ||
          props.initialData?.configModules?.some((mo) => mo.moduleId === m.id) ||
          false,
      }))
    );
  }, [props.values, props.initialData]);

  const initialValues: StepModules = useMemo(() => {
    return {
      mfa:
        props.values.poolSecurity === 'mfa' || props.initialData?.poolSecurity === 'mfa' || false,
      hidden: props.values.hidden?.toString() || props.initialData?.hidden?.toString() || 'false',
      providerName:
        props.values.userPoolConfiguration?.identityProviders?.[0]?.providerName ||
        props.initialData?.userPoolConfiguration?.identityProviders?.[0]?.providerName ||
        '',
      clientId:
        props.values.userPoolConfiguration?.identityProviders?.[0]?.clientId ||
        props.initialData?.userPoolConfiguration?.identityProviders?.[0]?.clientId ||
        '',
      clientSecret:
        props.values.userPoolConfiguration?.identityProviders?.[0]?.clientSecret ||
        props.initialData?.userPoolConfiguration?.identityProviders?.[0]?.clientSecret ||
        '',
      issuer:
        props.values.userPoolConfiguration?.identityProviders?.[0]?.issuer ||
        props.initialData?.userPoolConfiguration?.identityProviders?.[0]?.issuer ||
        '',
      jwksUrl:
        props.values.userPoolConfiguration?.identityProviders?.[0]?.jwksUrl ||
        props.initialData?.userPoolConfiguration?.identityProviders?.[0]?.jwksUrl ||
        '',
    };
  }, [props.values, props.initialData]);
  const getErrors = (touched: any, errors: any) => {
    return {
      hidden: touched.hidden ? errors.hidden : '',
      providerName: touched.providerName ? errors.providerName : '',
      clientId: touched.clientId ? errors.clientId : '',
      clientSecret: touched.clientSecret ? errors.clientSecret : '',
      issuer: touched.issuer ? errors.issuer : '',
      jwksUrl: touched.jwksUrl ? errors.jwksUrl : '',
    };
  };
  const setValues = (v: StepModules) =>
    props.setValues({
      ...props.values,
      hidden: v.hidden === 'true',
      poolSecurity: v.mfa ? 'mfa' : 'default',
      configModuleIds: modules.filter((m) => m.checked).map((m) => m.id),
      userPoolConfiguration: {
        ...props.values.userPoolConfiguration,
        identityProviders: identityProviders
          ? [
              {
                providerName: v.providerName,
                clientId: v.clientId,
                clientSecret: v.clientSecret,
                issuer: v.issuer,
                jwksUrl: v.jwksUrl,
              },
            ]
          : [],
      },
    });

  const changeModule = (moduleId: number) => {
    const newModules = modules.slice();
    const i = newModules.findIndex((m) => m.id === moduleId);
    if (i < 0) return;
    newModules[i] = {
      ...newModules[i],
      checked: !newModules[i].checked,
    };
    setModules(newModules);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={identityProviders ? validationIdentityProvider : validation}
      onSubmit={(v: any) => {
        setValues(v);
        const details = modules.some((m) => m.hasDetails && m.checked);
        props.setStep(details ? 2.5 : 3);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => {
        const et = getErrors(touched, errors);
        return (
          <>
            <StepIndicator step={2} numSteps={4} />
            <Form>
              <AudiSpacer spaceStackEnd={'l'} />
              <NativeCheckbox
                inputId={'mfa'}
                name={strings.mfaAuthentication}
                onChange={() => setFieldValue('mfa', !values.mfa)}
                checked={values.mfa}
              >
                <Text as="p" variant="copy1">
                  {strings.mfaAuthentication}
                </Text>
              </NativeCheckbox>
              <AudiSpacer spaceStackEnd={'l'} />
              {modules.map((m) => {
                return (
                  <React.Fragment key={m.id}>
                    <NativeCheckbox
                      inputId={`module_${m.id}`}
                      name={strings[m.text]}
                      onChange={() => changeModule(m.id)}
                      checked={m.checked}
                    >
                      <Text as="p" variant="copy1">
                        {m.tmText ? strings[m.tmText] : strings[m.text]}
                      </Text>
                    </NativeCheckbox>
                    <AudiSpacer spaceStackEnd={'l'} />
                  </React.Fragment>
                );
              })}
              <NativeCheckbox
                inputId={`identityProviders`}
                name={'identitiyProviders'}
                onChange={() => setIdentityProviders(!identityProviders)}
                checked={identityProviders}
              >
                <Text as="p" variant="copy1">
                  {strings.setIdentityProviders}
                </Text>
              </NativeCheckbox>
              {identityProviders && (
                <>
                  <TextField
                    label="Provider Name"
                    name="providerName"
                    error={et.providerName}
                  ></TextField>
                  <TextField label="Client ID" name="clientId" error={et.clientId}></TextField>
                  <TextField label="Client Secret" name="clientSecret"></TextField>
                  <TextField label="Issuer" name="issuer"></TextField>
                  <TextField label="JWKS URL" name="jwksUrl"></TextField>
                </>
              )}
              <AudiSpacer spaceStackEnd={'l'} />
              <Select label={strings.setHidden} name="hidden" error={et.hidden}>
                <option value="true">{strings.hiddenYes}</option>
                <option value="false">{strings.hiddenNo}</option>
              </Select>
              <AudiSpacer spaceStackEnd={'l'} />
              <LabelButton
                className={props.class}
                variant="secondary"
                id="back"
                onClick={() => {
                  setValues(values);
                  props.setStep(1);
                }}
              >
                {strings.back}
              </LabelButton>
              <LabelButton variant="primary" type="submit" id="next">
                {strings.next}
              </LabelButton>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export { EventSetupStepModules };
