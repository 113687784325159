import React, { useEffect, useState } from 'react';
import { NativeCheckbox, Text, useIsTheme } from '@pp-labs/ui-components';
import { Question, TestType } from '../../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { BlackClasses, blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import { withStyles, withWidth } from '@material-ui/core';
import { LabelButton } from '@pp-labs/ui-components';
// @ts-ignore
import { ReactComponent as Correct } from '../../../media/Correct.svg';
// @ts-ignore
import { ReactComponent as Wrong } from '../../../media/Wrong.svg';
import { client } from '../../../ApiHandler/client';
import { byAnswerId } from '../../../utils/sort';

/** interface for Test props coming from parent components Quiz, DclxTest and TopicTable */
interface P {
  testType: TestType | 'interrupt';
  questions: Question[];
  finish: (correctAnswers: number) => void;
  black?: boolean;
  classes: BlackClasses;
  width: string;
}

/**
 * Displays Questions in an exam or interrupt
 */

const Test = (props: P) => {
  const [position, setPosition] = useState<number>(-1);
  const [selectedAnswers, setSelectedAnswers] = useState<Array<boolean>>(new Array<boolean>(0));
  const [hasFeedback, setHasFeedback] = useState<boolean>(false);
  const [questionSubmitted, setQuestionSubmitted] = useState<boolean>(false);
  const [correct, setCorrect] = useState<boolean>(false);
  const [know, setKnow] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const theme = useIsTheme();
  useEffect(() => {
    newPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newPage = () => {
    if (!props.questions.length) return;
    const pos = position + 1;
    const selectedAnswersArray = new Array<boolean>(props.questions[pos].answers.length).fill(false);
    setPosition(pos);
    setSelectedAnswers(selectedAnswersArray);
  };

  const onChange = (index: number) => {
    const n = selectedAnswers.slice();
    n[index] = !n[index];
    setSelectedAnswers(n);
  };

  const isEnd = (): boolean => {
    return position + 1 === props.questions?.length;
  };

  const generateFeedbackText = () => {
    if (know) {
      if (correct) {
        return strings.answerCorrect;
      } else {
        return strings.answerWrong;
      }
    } else {
      return strings.noFeedbackPermissions;
    }
  };

  const submitToServer = async () => {
    const data: any = {};

    const q = props.questions[position];
    q.answers.forEach((a, index) => {
      data[`${a.answerId}`] = selectedAnswers[index];
    });
    let d;
    let k;
    try {
      d = (await client.post(`questions/${q.questionId}/answers/evaluate`, data)).data;
      k = true;
    } catch {
      d = { success: true };
      k = false;
    }
    if (d.success) {
      setHasFeedback(true);
      setCorrect(true);
      setKnow(k);
      setCorrectAnswers(correctAnswers + 1);
    } else {
      setHasFeedback(true);
      setCorrect(false);
      setKnow(k);
    }
    setQuestionSubmitted(true);
  };

  const onNextClick = () => {
    if (isEnd()) {
      props.finish(correctAnswers);
    } else {
      setHasFeedback(false);
      setCorrect(false);
      setQuestionSubmitted(false);
      newPage();
    }
  };

  const showCorrectAnswers = () => props.testType !== 'pre';

  if (position < 0) return null;
  const question = props.questions[position];
  const textCls = props.black ? props.classes.blackText : '';
  const btnCls = props.black && !theme.neutralDark ? props.classes.blackButton : '';
  const blackCheckbox = props.black ? 'black-checkbox' : '';

  const critical = props.width === 'xs' || props.width === 'sm';
  const stdSize = critical ? 'copy2' : 'copy1';
  const headline = critical ? 'order4' : 'order2';

  let feedback;
  if (hasFeedback && !showCorrectAnswers()) {
    feedback = (
      <Text className={textCls} variant={stdSize} as="p">
        {generateFeedbackText()}
      </Text>
    );
  }

  return (
    <div>
      <Text as="p" variant={stdSize} className={textCls}>
        {strings.selectAnswer}
      </Text>
      <div style={{ padding: '15px 0' }}>
        <Text as="p" variant={headline} className={textCls}>
          {getLocalizedValue(question.questionText)}
        </Text>
      </div>
      {question.answers.sort(byAnswerId).map((answer, index) => (
        <div key={`question-${question.questionId}-answer-${index}`} style={{ padding: '10px 0' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>
              {hasFeedback && showCorrectAnswers() && (
                <div>{answer.correct ? <Correct /> : <Wrong />}</div>
              )}
            </div>
            <NativeCheckbox
              inputId={`question-${question.questionId}-answer-${index}`}
              onChange={() => onChange(index)}
              disabled={hasFeedback}
              className={
                hasFeedback && showCorrectAnswers() && answer.correct !== selectedAnswers[index]
                  ? 'red-checkbox'
                  : blackCheckbox
              }
            >
              <Text
                className={
                  hasFeedback && showCorrectAnswers() && answer.correct !== selectedAnswers[index]
                    ? props.classes.redText
                    : textCls
                }
                variant={stdSize}
                as="p"
              >
                {getLocalizedValue(answer.answerText)}
              </Text>
            </NativeCheckbox>
          </div>
        </div>
      ))}
      <div style={{ padding: '20px 0' }}>{feedback}</div>
      <Text as="p" variant={stdSize} className={textCls}>
        &lt; {position + 1}/{props.questions.length} &gt;
      </Text>
      <LabelButton
        variant={critical ? 'secondary' : 'primary'}
        className={btnCls}
        disabled={!selectedAnswers.filter((a) => a).length}
        onClick={() => {
          if (questionSubmitted) onNextClick();
          else submitToServer();
        }}
      >
        {questionSubmitted
          ? isEnd()
            ? `${strings.send}${
                props.testType === 'post'
                  ? ' ' + strings.postTest
                  : props.testType === 'pre'
                  ? ' ' + strings.preTest
                  : ''
              }`
            : strings.next
          : strings.send}
      </LabelButton>
    </div>
  );
};

export default withStyles(blackStyle)(withWidth()(Test));
