import { makeStyles } from '@material-ui/core';
import { MuteIcon } from '@pp-labs/ui-components';
import { VolumeIcon } from 'icons';
import React from 'react';

const useStyles = makeStyles({
  soundController: {
    position: 'absolute',
    background: '#000000a3',
    bottom: 0,
    right: 0,
    padding: '20px 20px 14px 20px',
    cursor: 'pointer',
    fontSize: '24px',
  },
});

type P = {
  mute: boolean;
  setMute: (mute: boolean) => void;
};

/** Button to mute/unmute a video */
const SoundMuteController = (props: P) => {
  const cls = useStyles();
  return (
    <div className={cls.soundController} onClick={() => props.setMute(!props.mute)}>
      {props.mute ? <MuteIcon /> : <VolumeIcon />}
    </div>
  );
};

export { SoundMuteController };
