import { ABC } from './utils/ControlBar/ABCVote';
import { Thumbs } from './utils/ControlBar/ThumbsVote';
import {
  BreakoutCommand,
  CustomCommand,
  CustomCommandValue,
  RunningState,
  StreamChangeOut,
} from './WebsocketCommands';
import { BreakoutRoom } from './Provider/LotProvider';

/**
 * Collection of Websocket Commands related to LOTs
 */

export const subscribeCommand = (room: string) =>
  JSON.stringify({
    Action: 'subscribe',
    ChannelId: `conf-${room}`,
  });

export const helloCommand = (room: string) =>
  JSON.stringify({
    Action: 'hello',
    ChannelId: `conf-${room}`,
  });

export const handLowCommand = (room: string, sub: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'unhand',
    CommandValue: sub,
  });

export const setActiveCommand = (room: string, newActive: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'setActive',
    CommandValue: newActive,
  });

export const activeTrainerCommand = (room: string, sub: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'setTrainer',
    CommandValue: sub,
  });

export const toggleScreenshareCommand = (room: string, allowed: boolean) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'screenshareAllowed',
    CommandValue: allowed,
  });

export const unsetActiveCommand = (room: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'setActive',
    CommandValue: '',
  });

export const pushToTalkCommand = (room: string, muted: boolean) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandValue: !muted,
    CommandKey: 'pushToTalk',
  });

export const runningStateCommand = (room: string, rState: RunningState) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'runningState',
    CommandValue: rState,
  });

export const muteCommand = (room: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'mute',
    CommandValue: true,
  });

export const participantModeCommand = (room: string, mode: 'muted' | 'audio') =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'participantMode',
    CommandValue: mode,
  });

export const abcCommand = (room: string, abc: ABC) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'abc',
    CommandValue: abc,
  });

export const thumbCommand = (room: string, thumb: Thumbs) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'thumbs',
    CommandValue: thumb,
  });

export const clapCommand = (room: string, clapped: boolean) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'clapHands',
    CommandValue: clapped,
  });

export const clearCommand = (room: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'clear',
  });
export const clearThumbsCommand = (room: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'clearThumbs',
  });
export const clearABCCommand = (room: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'clearABC',
  });

export const handRaiseCommand = (room: string, sub: string) =>
  JSON.stringify({
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'hand',
    CommandValue: sub,
  });

export const sendCommand = (room: string, msg: string, breakoutRoom: string) =>
  JSON.stringify({
    Action: 'send',
    ChannelId: `conf-${room}`,
    Text: msg,
    BreakoutRoom: breakoutRoom,
  });

export const customCommand = (room: string, value: CustomCommandValue) => {
  const command: CustomCommand = {
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'notify',
    CommandValue: value,
  };
  return JSON.stringify(command);
};

export const breakoutCommand = (room: string, value: BreakoutRoom[]) => {
  const command: BreakoutCommand = {
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'breakoutRooms',
    CommandValue: value,
  };
  return JSON.stringify(command);
};

export const setStreamCommand = (room: string, value: number) => {
  const command: StreamChangeOut = {
    Action: 'command',
    ChannelId: `conf-${room}`,
    CommandKey: 'streamChange',
    CommandValue: value,
  };
  return JSON.stringify(command);
};
