import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

/**
 * Collection of various Icons from Audi's Icon Library
 */

export const LangIconLarge = () => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 48 48">
      {/* eslint-disable-next-line max-len */}
      <path
        d="M32.5,23.9 C32.5,35.8 28.7,45.5 24,45.5 C19.3,45.5 15.5,35.9 15.5,24 C15.5,12.1 19.3,2.5 24,2.5 C28.7,2.5 32.5,12.1 32.5,23.9 Z M5.3,34.5 L42.7,34.5 L5.3,34.5 Z M2.5,24.5 L45.5,24.5 L2.5,24.5 Z M4.8,14.5 L43.3,14.5 L4.8,14.5 Z M45.5,23.9 C45.5,35.8 35.9,45.4 24.1,45.5 C12.2,45.5 2.6,35.9 2.5,24.1 C2.5,12.2 12.1,2.6 23.9,2.5 C23.9,2.5 23.9,2.5 24,2.5 C35.8,2.5 45.4,12.1 45.5,23.9 Z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const ClockIcon = () => {
  return (
    <SvgIcon viewBox="0 0 48 48" style={{ fontSize: '50px' }}>
      {/* eslint-disable-next-line max-len */}
      <path
        d="M21.5,7.6 L21.5,4.5 L21.5,7.6 Z M25.5,7.6 L25.5,4.5 L25.5,7.6 Z M16.5,1.5 L30.5,1.5 L30.5,4.5 L16.5,4.5 L16.5,1.5 Z M37.6,14.3 L40.5,11.4 L37.7,8.6 L34.8,11.5 M12.3,11.4 L9.4,8.5 L6.6,11.3 L9.5,14.2 M23.5,28.5 C25.1568542,28.5 26.5,27.1568542 26.5,25.5 C26.5,23.8431458 25.1568542,22.5 23.5,22.5 C21.8431458,22.5 20.5,23.8431458 20.5,25.5 C20.5,27.1568542 21.8431458,28.5 23.5,28.5 Z M26.3,26.5 L34.8,29.6 M23.5,43.5 C33.4411255,43.5 41.5,35.4411255 41.5,25.5 C41.5,15.5588745 33.4411255,7.5 23.5,7.5 C13.5588745,7.5 5.5,15.5588745 5.5,25.5 C5.5,35.4411255 13.5588745,43.5 23.5,43.5 Z"
        stroke="black"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const SelectIcon = () => {
  return (
    <SvgIcon fontSize="inherit">
      {/* eslint-disable-next-line max-len */}
      <path
        d="M6 12.2L10.3 16.4 18.4 6.1"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const UnselectIcon = () => {
  return (
    <SvgIcon fontSize="inherit">
      {/* eslint-disable-next-line max-len */}
      <path
        d="M6 12.2L10.3 16.4 18.4 6.1"
        stroke="currentColor"
        strokeWidth="0"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const ExitFullscreen = () => {
  return (
    <SvgIcon fontSize="inherit">
      <path
        d="M22.5,11.5 C22.5,17.5751322 17.5751322,22.5 11.5,22.5 C5.42486775,22.5 0.5,17.5751322 0.5,11.5 C0.5,5.42486775 5.42486775,0.5 11.5,0.5 C14.4173814,0.5 17.2152744,1.65892524 19.2781746,3.72182541 C21.3410748,5.78472557 22.5,8.58261861 22.5,11.5 Z M12.5,6 L12.5,10.5 L17,10.5 M12.5,10.5 L16.6316,6.3684 L12.5,10.5 Z M10.5,12.5 L6.3684,16.6316 L10.5,12.5 Z M6,12.5 L10.5,12.5 L10.5,17"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const EnterFullscreen = () => {
  return (
    <SvgIcon fontSize="inherit">
      <path
        d="M12,6.5 L16.5,6.5 L16.5,11 M12.3684,10.6316 L16.5,6.5 L12.3684,10.6316 Z M10.6316,12.3684 L6.5,16.5 L10.6316,12.3684 Z M6.5,12 L6.5,16.5 L11,16.5 M11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,8.58261861 21.3410748,5.78472557 19.2781746,3.72182541 C17.2152744,1.65892524 14.4173814,0.5 11.5,0.5 Z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const AudioIcon = () => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
      <path
        d="M7.5,14.5 L3.5,14.5 L3.5,8.5 L7.5,8.5 L7.5,14.5 Z M7.5,14.5 L11.5,17.4 L11.5,5.6 L7.5,8.5 M17.5,17.7 C24.2,11.2 17.5,5.4 17.5,5.4 M15,15 C16.9,13 16.9,9.9 15,7.9"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const ArrowLeft = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <g
      id="_Icon_Library_1_-_Content_Icons_Core_Library_arrow-up-small_black"
      data-name="_Icon Library/1 - Content Icons/Core Library/arrow-up-small/black"
      transform="translate(24) rotate(90)"
    >
      <g id="arrow-up-small" transform="translate(6.5 9)">
        <path
          id="Shape"
          d="M11,5.5,5.5,0,0,5.5"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <rect id="Rechteck_11" data-name="Rechteck 11" width="24" height="24" fill="none" />
    </g>
  </SvgIcon>
);

export const ArrowUp = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <g
      id="_Icon_Library_1_-_Content_Icons_Core_Library_arrow-up-small_black"
      data-name="_Icon Library/1 - Content Icons/Core Library/arrow-up-small/black"
      transform="translate(24) rotateX(180)"
    >
      <g id="arrow-up-small" transform="translate(6.5 9)">
        <path
          id="Shape"
          d="M11,5.5,5.5,0,0,5.5"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <rect id="Rechteck_11" data-name="Rechteck 11" width="24" height="24" fill="none" />
    </g>
  </SvgIcon>
);

export const ArrowDown = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <g
      id="_Icon_Library_1_-_Content_Icons_Core_Library_arrow-up-small_black"
      data-name="_Icon Library/1 - Content Icons/Core Library/arrow-up-small/black"
      transform="translate(24 24) rotate(180)"
    >
      <g id="arrow-up-small" transform="translate(6.5 9)">
        <path
          id="Shape"
          d="M11,5.5,5.5,0,0,5.5"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <rect id="Rechteck_11" data-name="Rechteck 11" width="24" height="24" fill="none" />
    </g>
  </SvgIcon>
);

export const DotsIcon = () => {
  return (
    <SvgIcon fontSize="inherit">
      <path
        d="M18.5,12.6 C19.1075132,12.6 19.6,12.1075132 19.6,11.5 C19.6,10.8924868 19.1075132,10.4 18.5,10.4 C17.8924868,10.4 17.4,10.8924868 17.4,11.5 C17.4,12.1075132 17.8924868,12.6 18.5,12.6 Z M11.5,12.6 C12.1075132,12.6 12.6,12.1075132 12.6,11.5 C12.6,10.8924868 12.1075132,10.4 11.5,10.4 C10.8924868,10.4 10.4,10.8924868 10.4,11.5 C10.4,12.1075132 10.8924868,12.6 11.5,12.6 Z M4.5,12.6 C5.10751322,12.6 5.6,12.1075132 5.6,11.5 C5.6,10.8924868 5.10751322,10.4 4.5,10.4 C3.89248678,10.4 3.4,10.8924868 3.4,11.5 C3.4,12.1075132 3.89248678,12.6 4.5,12.6 Z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const CopyIcon = FileCopyOutlined;

export const TickIcon = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 30 30">
    <g id="Gruppe_1042" data-name="Gruppe 1042" transform="translate(0 1)">
      <g id="select-large" transform="translate(3.154 4)">
        <path
          id="Shape"
          d="M0,11.125l8.248,8.167L22.708,0"
          transform="translate(0.492 0.354)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <rect
        id="Rechteck_123"
        data-name="Rechteck 123"
        width="30"
        height="30"
        transform="translate(0 -1)"
        fill="none"
      />
    </g>
  </SvgIcon>
);

export const GroupIcon = () => (
  <SvgIcon fontSize="inherit" viewBox="0 0 24 24">
    <g id="groups-small" transform="translate(1 2.5)">
      <path
        id="Shape"
        d="M3,7h7.5V1a1,1,0,0,0-1-1H0"
        transform="translate(11 9.5)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path"
        width="6"
        height="6"
        rx="2"
        transform="translate(12.5 0.5)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M0,7V1A1,1,0,0,1,1,0H11a1,1,0,0,1,1,1V7Z"
        transform="translate(0.5 11.5)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path-2"
        data-name="Rectangle-path"
        width="6"
        height="6"
        rx="2"
        transform="translate(3.5 2.5)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect id="Rechteck_56" data-name="Rechteck 56" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const ArrowUpDown = () => {
  return (
    <div style={{ display: 'inline-block' }}>
      <div>
        <SvgIcon fontSize="inherit">
          <path
            d="M18 15.5L12.5 10 7 15.5"
            stroke="#333333"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />
        </SvgIcon>
      </div>
      <div>
        <SvgIcon fontSize="inherit">
          <path
            d="M7 10L12.5 15.5 18 10"
            stroke="#333333"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          />
        </SvgIcon>
      </div>
    </div>
  );
};

export const BookIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <rect id="Rechteck_5" data-name="Rechteck 5" width="24" height="24" fill="none" />
    <rect
      id="Rechteck_70"
      data-name="Rechteck 70"
      width="14"
      height="20.519"
      transform="translate(1.481 22.542) rotate(-90)"
      fill="none"
      stroke="#000"
      strokeWidth="1"
    />
    <path
      id="Pfad_106"
      data-name="Pfad 106"
      d="M0,3.968V0"
      transform="translate(8 1.097)"
      fill="none"
      stroke="#000"
      strokeWidth="1"
    />
    <path
      id="Pfad_107"
      data-name="Pfad 107"
      d="M0,5.542V0"
      transform="translate(1.481 3)"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeWidth="1"
    />
    <line
      id="Linie_11"
      data-name="Linie 11"
      y1="4"
      transform="translate(15 1)"
      fill="none"
      stroke="#000"
      strokeWidth="1"
    />
    <line
      id="Linie_12"
      data-name="Linie 12"
      y1="6"
      transform="translate(22 3)"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeWidth="1"
    />
    <line
      id="Linie_13"
      data-name="Linie 13"
      x1="5"
      transform="translate(17 3)"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeWidth="1"
    />
    <line
      id="Linie_14"
      data-name="Linie 14"
      x1="4"
      transform="translate(2 3)"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeWidth="1"
    />
    <line
      id="Linie_15"
      data-name="Linie 15"
      x1="3"
      transform="translate(10 3)"
      fill="none"
      stroke="#000"
      strokeLinecap="square"
      strokeWidth="1"
    />
  </SvgIcon>
);

export const LinkIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <path
      id="external-link-small"
      d="M18.5,11.594V20.5H3.5V5.5h7.969m4.031-3h6v6m-9,3,9-9Z"
      transform="translate(-0.5)"
      fill="none"
      stroke="#000"
      strokeWidth="1"
      fillRule="evenodd"
    />
    <rect id="Rechteck_80" data-name="Rechteck 80" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const PlayIconFirstSteps = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <g id="system-play-small">
      <path
        id="Shape"
        d="M0,0,6.261,4.23,0,8.575Z"
        transform="translate(9.913 7.712)"
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="11.478"
        cy="11.478"
        r="11.478"
        transform="translate(0.522 0.522)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect id="Rechteck_55" data-name="Rechteck 55" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const ProfileIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <g id="user-small" transform="translate(4.435 1.717)">
      <path
        id="Shape"
        d="M0,8.7V1.087A1.09,1.09,0,0,1,1.087,0H13.044a1.09,1.09,0,0,1,1.087,1.087V8.7H0Z"
        transform="translate(0.5 11.37)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect
        id="Rectangle-path"
        width="7.609"
        height="7.609"
        rx="2"
        transform="translate(3.761 0.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect id="Rechteck_71" data-name="Rechteck 71" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const StreamIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <rect id="Rechteck_5" data-name="Rechteck 5" width="24" height="24" fill="none" />
    <g id="vehicle-wi-fi-3-large" transform="translate(23.878 3.803) rotate(90)">
      <path
        id="Path"
        d="M15.395,2.385A12.49,12.49,0,0,0,7.7,0,12.423,12.423,0,0,0,0,2.385"
        transform="translate(0.5 0.5)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <g
        id="Ellipse_1"
        data-name="Ellipse 1"
        transform="translate(5.812 9.493)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      >
        <circle cx="2.385" cy="2.385" r="2.385" stroke="none" />
        <circle cx="2.385" cy="2.385" r="1.885" fill="none" />
      </g>
      <path
        id="Path-2"
        data-name="Path"
        d="M8.062,1.532A5.738,5.738,0,0,0,4.031,0,5.738,5.738,0,0,0,0,1.532"
        transform="translate(12.229 18.099) rotate(180)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path-3"
        data-name="Path"
        d="M0,1.532A5.738,5.738,0,0,1,4.031,0,5.738,5.738,0,0,1,8.062,1.532"
        transform="translate(4.166 5.658)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path-4"
        data-name="Path"
        d="M15.395,2.385A12.49,12.49,0,0,0,7.7,0,12.423,12.423,0,0,0,0,2.385"
        transform="translate(15.895 23.257) rotate(180)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </SvgIcon>
);

export const SurveyIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <path
      id="Shape"
      d="M0,16.427v4.732l3.625-3.042L11.1,2.076a.667.667,0,0,0-.322-.886L8.366.063A.667.667,0,0,0,7.48.385Z"
      transform="translate(6 1.341)"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <path
      id="Path"
      d="M0,0,6.642,3.1,4.059,8.636"
      transform="translate(12.328 4.196)"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <path
      id="Path-2"
      data-name="Path"
      d="M0,0,3.626,1.69"
      transform="translate(6 17.768)"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
    <rect id="Rechteck_36" data-name="Rechteck 36" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const RemoveIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props}>
    <g id="system-remove-small">
      <path
        id="Shape"
        d="M7.3,0H0"
        transform="translate(8.348 11.924)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="11.478"
        cy="11.478"
        r="11.478"
        transform="translate(0.522 0.522)"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
    <rect id="Rechteck_75" data-name="Rechteck 75" width="24" height="24" fill="none" />
  </SvgIcon>
);

export const PlayIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 48 48">
    <g id="system-play-large" transform="translate(2 2)">
      <path
        id="Shape"
        d="M0,0,10.209,6.835,0,13.855Z"
        transform="translate(18.089 15.072)"
        fill="none"
        stroke="#ffffff"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        strokeWidth="1"
      />
      <circle
        id="Oval"
        cx="21.511"
        cy="21.511"
        r="21.511"
        transform="translate(0.489 0.489)"
        fill="none"
        stroke="#ffffff"
        stroke-miterlimit="10"
        strokeWidth="1"
      />
    </g>
  </SvgIcon>
);

export const VolumeIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <title>audio-volume-small</title>
    <polyline points="100,100 150,25 150,75 200,0" fill="none" stroke="white" />
    <path
      d="M7.5,14.5 L3.5,14.5 L3.5,8.5 L7.5,8.5 L7.5,14.5 Z M7.5,14.5 L11.5,17.4 L11.5,5.6 L7.5,8.5 M17.5,17.7 C24.2,11.2 17.5,5.4 17.5,5.4 M15,15 C16.9,13 16.9,9.9 15,7.9"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export const BlackClapIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 25.011 24.424">
    <g id="clap_small" transform="translate(0.511 0.189)">
      <rect id="Rechteck_80" data-name="Rechteck 80" width="24" height="24" fill="none" />
      <g id="Gruppe_181" data-name="Gruppe 181" transform="translate(-0.286 0.079)">
        <path
          id="Pfad_110"
          data-name="Pfad 110"
          d="M2.567,21.178c-.211-1.646.143-12.585.143-12.585a1.072,1.072,0,0,1,.995-1.034A2.005,2.005,0,0,1,5.853,9.477l.085,2.079,5.425-8.362a1.522,1.522,0,0,1,2.562,1.643L9.957,11.1l4.82-7.606a1.639,1.639,0,1,1,2.76,1.768L12.654,12.8l3.929-5.944a1.517,1.517,0,0,1,2.552,1.639l-3.787,6.071,2.184-3.256a1.465,1.465,0,0,1,2.462,1.586l-6.146,9.933A6.212,6.212,0,0,1,4.19,23.968,4.858,4.858,0,0,1,2.567,21.178Z"
          transform="translate(-2.214 -2.213)"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_111"
          data-name="Pfad 111"
          d="M68.509,11.932c.023-.02.915-.807.939-.825A1.453,1.453,0,0,1,71.3,13.341l-1.382,1.2,2.514-2.214a1.564,1.564,0,1,1,2.072,2.344l-3.862,3.416,2.571-2.2a1.448,1.448,0,0,1,1.915,2.171l-5.062,4.584,2.855-2.419a1.4,1.4,0,0,1,1.846,2.1l-8.236,7.514A5.928,5.928,0,0,1,61.563,31.3"
          transform="translate(-51.319 -9.119)"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </SvgIcon>
);

export const WhiteClapIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 25.011 24.424">
    <g id="clap_small" transform="translate(0.511 0.189)">
      <rect id="Rechteck_80" data-name="Rechteck 80" width="24" height="24" fill="none" />
      <g id="Gruppe_181" data-name="Gruppe 181" transform="translate(-0.286 0.079)">
        <path
          id="Pfad_110"
          data-name="Pfad 110"
          d="M2.567,21.178c-.211-1.646.143-12.585.143-12.585a1.072,1.072,0,0,1,.995-1.034A2.005,2.005,0,0,1,5.853,9.477l.085,2.079,5.425-8.362a1.522,1.522,0,0,1,2.562,1.643L9.957,11.1l4.82-7.606a1.639,1.639,0,1,1,2.76,1.768L12.654,12.8l3.929-5.944a1.517,1.517,0,0,1,2.552,1.639l-3.787,6.071,2.184-3.256a1.465,1.465,0,0,1,2.462,1.586l-6.146,9.933A6.212,6.212,0,0,1,4.19,23.968,4.858,4.858,0,0,1,2.567,21.178Z"
          transform="translate(-2.214 -2.213)"
          fill="none"
          stroke="#ffffff"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_111"
          data-name="Pfad 111"
          d="M68.509,11.932c.023-.02.915-.807.939-.825A1.453,1.453,0,0,1,71.3,13.341l-1.382,1.2,2.514-2.214a1.564,1.564,0,1,1,2.072,2.344l-3.862,3.416,2.571-2.2a1.448,1.448,0,0,1,1.915,2.171l-5.062,4.584,2.855-2.419a1.4,1.4,0,0,1,1.846,2.1l-8.236,7.514A5.928,5.928,0,0,1,61.563,31.3"
          transform="translate(-51.319 -9.119)"
          fill="none"
          stroke="#ffffff"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </SvgIcon>
);

export const DownloadIcon = () => (
  <SvgIcon fontSize="large" viewBox="0 0 24 24">
    <path
      d="M15,15.5 L8,15.5 M11.5,7 L11.5,13.4 M8.3,10.2 L11.5,13.4 L14.7,10.2 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="black"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export const CameraMutedIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="medium" fill="currentColor" color="inherit" {...props} viewBox="0 0 22 17">
    <title>Group 2</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Desktop-Layouts" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="dCLX_mytraining_LOT_one-speaker-(Userview)-NEW-Copy"
        transform="translate(-883.000000, -1753.000000)"
      >
        <g id="Group-2" transform="translate(883.000000, 1753.000000)">
          <g id="video-on-copy" transform="translate(2.000000, 3.000000)">
            <g id="Group-3" stroke="currentColor">
              <rect
                id="Rectangle"
                x="-1.02774931e-13"
                y="-8.9015215e-14"
                width="13.3834821"
                height="10.9918519"
                rx="1.19"
              ></rect>
              <polyline
                id="Path"
                stroke-linecap="round"
                stroke-linejoin="round"
                points="13.4075893 7.59 19.2214286 10.9918519 19.2214286 0.171111111 13.3834821 3.58925926"
              ></polyline>
            </g>
          </g>
          <line
            x1="0.5"
            y1="16.5"
            x2="17.5"
            y2="0.5"
            id="Line"
            stroke="currentColor"
            stroke-linecap="round"
          ></line>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const CameraIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="medium" fill="currentColor" {...props} viewBox="0 0 22 14">
    <title>video-on copy</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Desktop-Layouts" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g id="video-on-copy" transform="translate(1.000000, 1.000000)">
        <g id="Group-3" stroke="currentColor">
          <rect
            id="Rectangle"
            x="-1.02774931e-13"
            y="-8.9015215e-14"
            width="13.3834821"
            height="10.9918519"
            rx="1.19"
          ></rect>
          <polyline
            id="Path"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="13.4075893 7.59 19.2214286 10.9918519 19.2214286 0.171111111 13.3834821 3.58925926"
          ></polyline>
        </g>
      </g>
    </g>
  </SvgIcon>
);
