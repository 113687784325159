import { IconButton, Link, makeStyles, MenuItem } from '@material-ui/core';
import { LanguageIcon } from '@pp-labs/ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import AudiMenu from 'utils/AudiMenu';
import { Text } from '@audi/audi-ui-react';
import { setLanguage } from 'AppSlice';
import { useDispatch } from 'react-redux';
import { useEventLanguages, useUser } from 'utils/hooks';
import { client } from 'ApiHandler/client';
import { Channel, Language } from 'ApiHandler/dclxInterfaces';
import { getChannelsLanguages } from '../../Localization/Localizer';

const useStyles = makeStyles(() => ({
  buttonCollapse: {
    boxShadow: 'none',
    position: 'relative',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  black: {
    color: '#000',
  },
}));

/** Allows the user to switch the language */
export const LanguageMenu = () => {
  const cls = useStyles();
  const user = useUser();
  const eventLangs = useEventLanguages();
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    const fetchChannels = async () => {
      const channels = (await client.get(`channels`)).data;
      setChannels(channels);
    };
    fetchChannels();
  }, []);

  const languageList: Language[] = useMemo(() => {
    // Getting list of languages from event settings and channels then comparing them and keeping event settings languages only
    const channelsLanguages = getChannelsLanguages(channels);
    return channelsLanguages.filter((lang) => eventLangs.find((l) => l.code === lang.code));
  }, [eventLangs, channels]);

  const [anchor, setAnchor] = useState<any | null>(null);

  const handleOpen = (event: any) => setAnchor(event.currentTarget);

  const handleClose = () => setAnchor(null);
  const dispatch = useDispatch();

  const open = !!anchor;

  const selectLang = (lang: Language) => {
    if (user) {
      client.post('users', {
        language: JSON.stringify(lang),
      });
    }
    dispatch(setLanguage(lang));
  };

  return (
    <>
      {languageList && languageList.length > 1 && (
        <>
          <Link underline="none" onClick={handleOpen}>
            <IconButton className={cls.black}>
              <LanguageIcon />
            </IconButton>
          </Link>
          <div className={cls.buttonCollapse}>
            <AudiMenu
              id="menu-language"
              anchorEl={anchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              // see https://stackoverflow.com/a/52551100
              getContentAnchorEl={null}
              open={open}
              onClose={handleClose}
            >
              <div onClick={handleClose}>
                {languageList.map((language: Language) => {
                  return (
                    <Link
                      underline="none"
                      onClick={() => selectLang(language)}
                      className="navLink navLink--userMenuIcon"
                      key={language.code}
                    >
                      <MenuItem>
                        <Text as="span" variant="copy1">
                          {language.name}
                        </Text>
                      </MenuItem>
                    </Link>
                  );
                })}
              </div>
            </AudiMenu>
          </div>
        </>
      )}
    </>
  );
};
