import React, { useMemo } from 'react';
import StepIndicator from 'utils/StepIndicator';
import { LabelButton, UploadButton } from '@pp-labs/ui-components';
import AudiSpacer from 'utils/AudiSpacer';
import { Text } from '@pp-labs/ui-components';
import { StepImages, AdminPanelSteps } from './adminPanelTypes';
import { Form, Formik } from 'formik';
import { requiredThumb } from '../../../utils/validator';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { ConfigModuleIdentifiers } from '../../../ApiHandler/dclxInterfaces';

type ShouldDisplayFunction = (configModuleIds: number[] | undefined) => boolean;

/** type UploadFile consist type of upload fields */
type UploadFile = {
  name: string;
  string: string;
  dimensions: string;
  neutralDimensions?: string;
  shouldDisplay?: ShouldDisplayFunction;
};

const shouldDisplayRegister: ShouldDisplayFunction = (configModuleIds) =>
  !!configModuleIds?.includes(ConfigModuleIdentifiers.registerVideo);

export const uploadFields: UploadFile[] = [
  {
    name: 'favicon',
    string: strings.uploadFavicon,
    dimensions: '256 x 256 px',
  },
  {
    name: 'logo',
    string: strings.uploadRegistrationLogo,
    dimensions: '288 x 100 px',
    neutralDimensions: 'height 256px',
  },
  {
    name: 'navLogo',
    string: strings.uploadNavLogo,
    dimensions: 'height 256px',
  },
  {
    name: 'heroImage',
    string: strings.uploadHeroImage,
    dimensions: '2500 x 1800 px',
  },
  {
    name: 'profileImage',
    string: strings.uploadProfileImage,
    dimensions: '500 x 500 px',
  },
  {
    name: 'registerVideoThumb',
    string: 'Register Thumb',
    shouldDisplay: shouldDisplayRegister,
    dimensions: '960 x 540 px',
  },
];

/** Images in Event Creation */
const EventSetupStepImages = (props: AdminPanelSteps) => {
  const neutralTheme = () => {
    if (props?.values?.theme) {
      const checkTheme = JSON.parse(props?.values?.theme);
      if (checkTheme.type === 'audi') {
        return false;
      } else return true;
    }
  };
  const validation = useMemo(() => {
    const v: any = {};
    uploadFields
      .filter((f) => !f.shouldDisplay || f.shouldDisplay(props.values.configModuleIds))
      .forEach((f) => (v[f.name] = requiredThumb));
    return Yup.object(v).required(strings.required);
  }, [props.values.configModuleIds]);

  const initialValues: StepImages = useMemo(() => {
    const data: any = {};
    uploadFields.forEach(
      (f) =>
        (data[f.name] =
          props.values[f.name] ||
          (props.initialData
            ? new File([''], strings.unchanged, {
                type: 'image/png',
              })
            : null))
    );
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const setValues = (v: any) => {
    const data: any = {};
    uploadFields.forEach(
      (f) => (data[f.name] = v[f.name]?.name === strings.unchanged ? null : v[f.name])
    );
    props.setValues({ ...props.values, ...data });
  };

  return (
    <Formik
      onSubmit={(v: any) => {
        setValues(v);
        props.setStep(4);
      }}
      initialValues={initialValues}
      validationSchema={validation}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <>
            <StepIndicator step={3} numSteps={4} />
            <AudiSpacer spaceStackEnd="m" />
            <Form>
              <div id="fileUpload">
                {uploadFields.map((f) => {
                  if (f.shouldDisplay && !f.shouldDisplay(props.values.configModuleIds))
                    return null;
                  // @ts-ignore
                  const v = values[f.name]?.name;
                  // @ts-ignore
                  const err = touched[f.name] ? errors[f.name] : '';
                  return (
                    <div key={f.name} id={'upload_' + f.name}>
                      <Text as="p" variant="copy1" weight="bold">
                        {f.string}
                      </Text>
                      <UploadButton
                        handleFile={setFieldValue}
                        type={'image'}
                        name={f.name}
                        label={v || props.values[f.name]?.name || f.string}
                        error={err}
                        imgDimensions={
                          neutralTheme() && f.neutralDimensions ? f.neutralDimensions : f.dimensions
                        }
                      />
                      <AudiSpacer spaceStackEnd="xs" />
                    </div>
                  );
                })}
              </div>
              <AudiSpacer spaceStackEnd={'l'} />
              <LabelButton
                className={props.class}
                id="back"
                variant="secondary"
                onClick={() => {
                  setValues(values);
                  props.setStep(2);
                }}
              >
                {strings.back}
              </LabelButton>
              <LabelButton variant="primary" type="submit" id="next">
                {strings.next}
              </LabelButton>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export { EventSetupStepImages };
