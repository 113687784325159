import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationState {
  message: string;
  type?: 'success' | 'info' | 'warning' | 'error';
  update: boolean;
  persist?: boolean;
}

const initialState: NotificationState = {
  message: '',
  update: false,
};

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    triggerNotification: (state: Draft<NotificationState>, action: PayloadAction<any>) => {
      state.message = action.payload[0];
      state.type = action.payload[1];
      state.persist = action.payload[2];
      state.update = !state.update;
    },
    closeNotification: (state: Draft<NotificationState>) => {
      state.message = '';
      state.type = undefined;
    },
  },
});

export const { triggerNotification, closeNotification } = NotificationSlice.actions;

export default NotificationSlice.reducer;
