import { client } from '../../../ApiHandler/client';
import { CustomCommandValue } from './WebsocketCommands';
import { serverNow } from '../../../utils/convert';
import { AnsweredAma } from '../../../ApiHandler/dclxInterfaces';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useNotify } from '../../../utils/hooks';
import strings from '../../../Localization/Localizer';
import {
  LabelButton,
  Text,
  NativeTextArea,
  useIsTheme,
  makeThemedStyles,
  useThemedStyles,
  PrivateIcon,
  PublicIcon,
} from '@pp-labs/ui-components';
import clsx from 'clsx';
const styles = makeThemedStyles((theme) => {
  return {
    supportContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 32,
    },
    supportBody: {
      overflow: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column-reverse',
      border: ({ isTheme }) => (isTheme.neutralDark ? '1px solid white' : '1px solid grey'),
    },
    chatText: {
      fontSize: '14px',
      lineHeight: '1.4',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    message: {
      margin: '4px 0',
      display: 'flex',
    },
  };
});

/** interface for Amas props coming from parent component Live */
interface P {
  sessionId: number;
  sendAmaSignal: (c: CustomCommandValue) => void;
  amas: AnsweredAma[];
  height: number;
}

/** Live Ama Panel */
export const Amas = (props: P) => {
  const [msg, setMsg] = useState<string>('');
  const notify = useNotify();
  const theme = useIsTheme();
  const displayAmas = useMemo(() => {
    const a = props.amas.slice();
    a.reverse();
    return a;
  }, [props.amas]);
  const cls = useThemedStyles(styles);
  const submit = async () => {
    await client.post(`sessions/${props.sessionId}/liveAmas`, { question: msg });
    props.sendAmaSignal({ name: 'refreshAmaDir', time: serverNow() });
    notify(strings.thanksForAma, 'success');
    setMsg('');
  };
  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMsg(event.target.value);
  };
  return (
    <div className={cls.supportContainer}>
      <Text variant="order2" weight="bold" spaceStackEnd="l" spaceStackStart="l">
        {strings.anyQuestions}
      </Text>
      <div className={cls.supportBody} style={{ maxHeight: props.height - 100, padding: 16 }}>
        {!!displayAmas.length ? (
          displayAmas.map((ama) => {
            return (
              <div className={cls.message} key={ama.liveAmaId}>
                <div style={{ fontSize: 20, paddingRight: 16 }}>
                  {ama.answerMode === 'public' ? <PublicIcon /> : <PrivateIcon />}
                </div>
                <div>
                  <Text as="p" variant="copy1" className={cls.chatText}>
                    <b>{ama.question}</b>
                    <br />
                    {ama.answer}
                  </Text>
                </div>
              </div>
            );
          })
        ) : (
          <div className={cls.message}>
            <Text variant="copy1">{strings.noAmasYet}</Text>
          </div>
        )}
      </div>
      <div style={{ marginTop: '16px' }}>
        <NativeTextArea
          onChange={onChange}
          rows={3}
          value={msg}
          inputId="amaQuestion"
          label={strings.typeLiveAma}
          className={clsx(theme.neutralDark && 'lot-textarea')}
        />
      </div>
      <div style={{ marginTop: '16px' }}>
        <LabelButton variant="primary" onClick={submit} disabled={!msg}>
          {strings.send}
        </LabelButton>
      </div>
    </div>
  );
};
