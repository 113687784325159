import axios from 'axios';
import { APIUrl } from '../config';
import { getIdToken } from './Cognito';

/**
 * Axios Instance for main in-event-API with up-to-date Auth-Bearer.
 *
 * Usage: e.g. `client.get('sessions')`
 */

const client = axios.create({
  baseURL: `${APIUrl}/event`,
});

client.interceptors.request.use(async (config) => {
  const token = await getIdToken();
  config.headers = {
    Authorization: token,
  };
  return config;
});

export { client };
