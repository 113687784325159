import React, { useMemo, useState } from 'react';
import StepIndicator from 'utils/StepIndicator';
import {
  Divider,
  EraseIcon,
  LabelButton,
  NativeCheckbox,
  Select,
  Text,
  TextField,
} from '@pp-labs/ui-components';
import { AdminPanelSteps, CustomField } from './adminPanelTypes';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings from 'Localization/Localizer';
import AudiSpacer from 'utils/AudiSpacer';
import { IconButton } from '@material-ui/core';
import { adminClient } from 'ApiHandler/adminClient';
import { S3File } from 'ApiHandler/dclxInterfaces';
import axios from 'axios';
import { useNotify } from 'utils/hooks';
import { uploadFields } from './EventSetupStepImages';

type Field = {
  name: string;
  default: string;
  label: string;
};

const fields: Field[] = [
  { name: 'username', default: 'custom_12', label: 'Username AirLST field' },
  { name: 'user_group', default: 'custom_7', label: 'User Group AirLST field' },
  { name: 'given_name', default: 'first_name', label: 'Given Name AirLST field' },
  { name: 'family_name', default: 'last_name', label: 'Family Name AirLST field' },
  { name: 'email', default: 'email', label: 'Email AirLST field' },
  { name: 'primary_phone', default: 'phone', label: 'Phone AirLST field' },
  { name: 'gender', default: 'sex', label: 'Gender AirLST field' },
  { name: 'enabled', default: 'custom_23', label: 'Enabled AirLST field' },
];

type AnyObject = { [key: string]: any };
const requiredFields: AnyObject = {};
fields.forEach((f) => (requiredFields[f.name] = Yup.string().required(strings.required)));

const validation = Yup.object({
  ...requiredFields,
  idProviderIdentifier: Yup.string().required(strings.required),
  customField: Yup.array()
    .notRequired()
    .of(
      Yup.object({
        fieldName: Yup.string().required(strings.required),
        fieldLabel: Yup.string().required(strings.required),
      })
    ),
  verificationTypes: Yup.string().required(strings.required),
});
interface UploadUrls {
  [key: string]: S3File;
}

/** Airlist mappings in Event Creation */
const EventSetupStepMappings = (props: AdminPanelSteps) => {
  const notify = useNotify();
  const [count, setCount] = useState<number>(0);
  const [customFields, setCustomFields] = useState<number[]>([]);
  const initialValues: any = useMemo(() => {
    const values: AnyObject = {};
    fields.forEach(
      (f) =>
        (values[f.name] = props.values?.userPoolConfiguration?.defaultFields?.[f.name] || f.default)
    );
    values['idProviderIdentifier'] = props.values?.idProviderIdentifier || '';

    const cfs =
      props.values.userPoolConfiguration?.customFields ||
      props.initialData?.userPoolConfiguration?.customFields;
    if (cfs && cfs.length > 0) {
      const initCFs = Array(cfs.length)
        .fill(null)
        .map((u, i) => i);

      setCustomFields(initCFs);
      setCount(cfs.length);
      values.customField = cfs.map((cf: CustomField) => ({
        fieldName: cf.fieldName,
        fieldLabel: cf.fieldLabel,
        isChannelCategory: cf.isChannelCategory!!,
        isRequired: cf.isRequired!!,
      }));
      values.registerSettings = {
        verificationTypes: props.values.verificationTypes || [],
      };
    }

    return values;
  }, [props.values, props.initialData]);

  const uploadFiles = async (data: any, responseData: UploadUrls) => {
    const promises: Promise<any>[] = [];
    uploadFields.forEach((f) => {
      const key = f.name + 'UploadUrl';
      const d = responseData[key];
      if (d) {
        promises.push(
          axios.put(d.url, data[f.name], {
            headers: { 'Content-Type': d.contentType },
          })
        );
      }
    });
    try {
      await Promise.all(promises);
      if (props.initialData) {
        notify(strings.eventUpdated, 'success');
      } else {
        notify(strings.eventCreated, 'success');
      }
    } catch {
      notify(strings.uploadFailed, 'error');
    }
  };

  const sendToServer = async (data: any, imgData: any) => {
    try {
      if (props.initialData) {
        const res = await adminClient.put(`events/${props.currentEventId}`, imgData);
        await uploadFiles(data, res.data);
        props.getEvents();
      } else {
        const res = await adminClient.post('events', imgData);
        await uploadFiles(data, res.data);
        props.getEvents();
      }
    } catch (e: any) {
      notify(e.toString(), 'error');
    }
  };

  const getCustomFields = (v: any) => {
    const cf = customFields.map((f: number) => ({
      fieldName: v.customField && v.customField[f] ? v.customField[f].fieldName : '',
      fieldLabel: v.customField && v.customField[f] ? v.customField[f].fieldLabel : '',
      externalFieldName: v.customField && v.customField[f] ? v.customField[f].fieldName : '',
      isChannelCategory:
        v.customField && v.customField[f] ? v.customField[f].isChannelCategory : false,
      isRequired: v.customField && v.customField[f] ? v.customField[f].isRequired : false,
    }));
    if (v.jobFunction && v.jobFunction.length > 0)
      cf.push({
        fieldLabel: v.jobFunction,
        externalFieldName: v.jobFunction,
        fieldName: 'jobFunction',
        isChannelCategory: false,
        isRequired: false,
      });

    if (v.language && v.language.length > 0)
      cf.push({
        fieldLabel: v.language,
        externalFieldName: v.language,
        fieldName: 'language',
        isChannelCategory: false,
        isRequired: false,
      });
    return cf;
  };

  const setValues = (v: any) => {
    const defaults: any = {};
    fields.forEach((f) => (defaults[f.name] = v[f.name]));
    const data = {
      ...props.values,
      idProviderIdentifier: v.idProviderIdentifier,
      userPoolConfiguration: {
        ...props.values.userPoolConfiguration,
        customFields: getCustomFields(v),
        defaultFields: defaults,
      },
      registerSettings: {
        verificationTypes:
          v.verificationTypes === 'both' ? ['email', 'sms'] : [v.verificationTypes],
      },
    };
    props.setValues(data);
  };

  const getImgData = (data: { [key: string]: any }) => {
    const imgData = {
      ...data,
    };
    uploadFields.forEach((f) => {
      const file: File | null = props.values[f.name];
      if (file) imgData[f.name] = file.name;
    });
    return imgData;
  };
  const updateEvent = async () => {
    notify(`${strings.updating}...... Uploading images`, 'success');
    props.onFinish();
    await sendToServer(props.values, getImgData(props.values));
    notify(`Images uploaded`, 'success');
  };
  const createEvent = async (v: any) => {
    const defaults: any = {};
    fields.forEach((f) => (defaults[f.name] = v[f.name]));
    const data = {
      ...props.values,
      poolSecurity: undefined,
      idProviderIdentifier: v.idProviderIdentifier,
      userPoolConfiguration: {
        ...props.values.userPoolConfiguration,
        security: props.values.poolSecurity,
        customFields: getCustomFields(v),
        defaultFields: defaults,
      },
      registerSettings: {
        verificationTypes:
          v.verificationTypes === 'both' ? ['email', 'sms'] : [v.verificationTypes],
      },
    };

    notify(`${strings.creating}...... Uploading images`, 'success');
    props.onFinish();
    await sendToServer(data, getImgData(data));
    notify(`Images uploaded`, 'success');
  };

  const addCustomField = () => {
    const a: number[] = [];
    a.push(...customFields);
    a.push(count);
    setCount(count + 1);
    setCustomFields(a);
  };
  const removeCustomField = (i: number) => {
    const a = customFields.filter(function (n: number) {
      return n !== i;
    });
    setCustomFields(a);
  };
  const getCustomFieldError = (errors: any, index: number, field: string) => {
    if (errors && 'customField' in errors) {
      if (index in errors.customField && errors.customField[index]) {
        if (field in errors.customField[index]) return errors.customField[index][field];
      }
    }
    return '';
  };

  if (props.initialData) {
    return (
      <>
        <StepIndicator step={4} numSteps={4} />
        <Text as="h1" variant="order2" weight={'bold'}>
          AirLST {strings.mappedWith} id :{' '}
          {props.initialData.idProviderIdentifier
            ? props.initialData.idProviderIdentifier
            : 'NotFound'}
        </Text>

        <AudiSpacer spaceStackEnd={'s'} />
        <Text as="p" variant="copy1">
          {strings.mappingsOnlyCreating}
        </Text>

        <AudiSpacer spaceStackEnd={'l'} />
        <LabelButton
          className={props.class}
          variant="secondary"
          id="back"
          onClick={() => {
            props.setStep(3);
          }}
        >
          {strings.back}
        </LabelButton>
        <LabelButton
          variant="primary"
          type="submit"
          id="submit"
          onClick={() => {
            updateEvent();
          }}
        >
          {strings.submit}
        </LabelButton>
      </>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={createEvent}>
      {({ errors, touched, values, setFieldValue }) => {
        const et = errors;
        return (
          <>
            <StepIndicator step={4} numSteps={4} />
            <Text as="h1" variant="order2" weight={'bold'}>
              BB onsite
            </Text>
            <Text as="h1" variant="order2" weight={'bold'}>
              Matching-Service
            </Text>
            <Form>
              <AudiSpacer spaceStackEnd={'s'} />
              <div style={{ width: '50%', margin: 'auto' }}>
                <div style={{ textAlign: 'center' }}>
                  <Text as="p" variant="copy1" weight={'bold'}>
                    - Match event -
                  </Text>
                </div>
                <TextField
                  name={'idProviderIdentifier'}
                  label={'AirLST-ID'}
                  error={et.idProviderIdentifier?.toString()}
                  disabled={props.initialData === undefined}
                />
              </div>
              <AudiSpacer spaceStackEnd={'l'} />
              <Divider />
              <AudiSpacer spaceStackEnd={'l'} />
              <div style={{ width: '50%', margin: 'auto' }}>
                <div style={{ textAlign: 'center' }}>
                  <Text as="p" variant="copy1" weight={'bold'}>
                    - Match data -
                  </Text>
                </div>
              </div>

              {fields.map((f) => (
                <TextField
                  name={f.name}
                  label={f.label}
                  error={touched[f.name] ? errors[f.name]?.toString() : ''}
                  key={f.name}
                />
              ))}
              <AudiSpacer spaceStackEnd={'l'} />
              <div style={{ width: '50%', margin: 'auto' }}>
                <div style={{ textAlign: 'center' }}>
                  <Text as="p" variant="copy1" weight={'bold'}>
                    - Optional -
                  </Text>
                </div>
              </div>

              <AudiSpacer spaceStackEnd={'l'} />
              <TextField
                name={'jobFunction'}
                label={'Job Function'}
                error={touched['jobFunction'] ? errors['jobFunction']?.toString() : ''}
                key={'jobFunction'}
              />

              <TextField
                name={'language'}
                label={strings.language}
                error={touched['language'] ? errors['language']?.toString() : ''}
                key={'language'}
              />

              <AudiSpacer spaceStackEnd={'l'} />
              {customFields.length > 0 &&
                customFields.map((f: number) => {
                  return (
                    <>
                      <div style={{ width: '100%', display: 'grid', columnCount: 4 }}>
                        <div style={{ gridColumn: 1 }}>
                          <TextField
                            name={`customField.${f}.fieldName`}
                            label={'AirLST Field'}
                            error={getCustomFieldError(et, f, 'fieldName')}
                          />
                        </div>

                        <div style={{ gridColumn: 2 }}>
                          <TextField
                            name={`customField.${f}.fieldLabel`}
                            label={'Cognito Title'}
                            error={getCustomFieldError(et, f, 'fieldLabel')}
                          />
                        </div>
                        <div
                          style={{
                            gridColumn: 3,
                            marginTop: '5px',
                          }}
                        >
                          <NativeCheckbox
                            inputId={`customField.${f}.isChannelCategory`}
                            name={'Channel'}
                            onChange={() =>
                              setFieldValue(
                                `customField.${f}.isChannelCategory`,
                                values.customField && values.customField[f]
                                  ? !values.customField[f].isChannelCategory
                                  : true
                              )
                            }
                            checked={
                              values.customField && values.customField[f]
                                ? values.customField[f].isChannelCategory
                                : false
                            }
                          >
                            <Text as="p" variant="copy1">
                              Channel
                            </Text>
                          </NativeCheckbox>

                          <NativeCheckbox
                            inputId={`customField.${f}.isRequired`}
                            name={'Channel'}
                            onChange={() =>
                              setFieldValue(
                                `customField.${f}.isRequired`,
                                values.customField && values.customField[f]
                                  ? !values.customField[f].isRequired
                                  : true
                              )
                            }
                            checked={
                              values.customField && values.customField[f]
                                ? values.customField[f].isRequired
                                : false
                            }
                          >
                            <Text as="p" variant="copy1">
                              Required
                            </Text>
                          </NativeCheckbox>
                        </div>
                        <div style={{ gridColumn: 4 }}>
                          <IconButton
                            onClick={() => {
                              const cf = values.customField;
                              cf.splice(f, 1);
                              setFieldValue('customField', cf);
                              removeCustomField(f);
                            }}
                          >
                            <EraseIcon />
                          </IconButton>
                        </div>
                      </div>
                    </>
                  );
                })}
              <LabelButton variant="secondary" type="button" id="add" onClick={addCustomField}>
                Add Custom Field
              </LabelButton>

              <AudiSpacer spaceStackEnd={'l'} />

              <Select
                name={'verificationTypes'}
                label={'User Verification'}
                error={et.verificationTypes?.toString()}
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
                <option value="both">Both</option>
              </Select>

              <LabelButton
                className={props.class}
                variant="secondary"
                id="back"
                onClick={() => {
                  setValues(values);
                  props.setStep(3);
                }}
              >
                Back
              </LabelButton>
              <LabelButton variant="primary" type="submit" id="submit">
                Submit
              </LabelButton>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export { EventSetupStepMappings };
