import { NEWFIELD } from './config';
import { LabelButton } from '@pp-labs/ui-components';
import { stringify } from 'csv-string';

/** interface for DownloadCsv props coming from parent component UploadMailTemplates, UploadTrainingsCSV, EditTest */
interface P {
  loadData: () => Promise<string[][] | null>;
  fileName: string;
  title?: string;
}

/** Allows the download of a csv files, which data is provided by the loadData function */
export const DownloadCsv = (props: P) => {
  const download = (content: string, filename: string) => {
    const encodedUri = encodeURI(content);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    link.click();
    link.remove();
  };
  const parseCsv = (data: string[][]) => 'data:text/csv;charset=utf-8,' + stringify(data, NEWFIELD);
  const click = async () => {
    const data = await props.loadData();
    if (!data) return;
    download(parseCsv(data), props.fileName);
  };
  return (
    <LabelButton variant="secondary" onClick={click}>
      {props.title || `Download ${props.fileName}`}
    </LabelButton>
  );
};
