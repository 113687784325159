import { makeStyles } from '@material-ui/core/styles';
import { LocationImage } from './LocationImage';
import { Text } from '@audi/audi-ui-react';
import AudiTabs from 'utils/AudiTabs/AudiTabs';
import ABEC_01_EG from './images/ABEC_01_EG.png';
import ABEC_02_OG from './images/ABEC_02_OG.png';
import ABEC_03_OG from './images/ABEC_03_OG.png';
import ATC_II_01_EG from './images/ATC_II_01_EG.png';
import ATC_II_02_OG from './images/ATC_II_02_OG.png';
import P5_Hilton from './images/P5_Hilton.png';

type SubLabel = {
  name: string;
  image: string;
};
type Label = {
  name: string;
  subLabels: SubLabel[];
};

const getLabels = (): Label[] => [
  {
    name: 'ABEC',
    subLabels: [
      {
        name: 'Ground Floor',
        image: ABEC_01_EG,
      },
      {
        name: 'First Floor',
        image: ABEC_02_OG,
      },
      {
        name: 'Second Floor',
        image: ABEC_03_OG,
      },
    ],
  },
  {
    name: 'ATC II',
    subLabels: [
      {
        name: 'Ground Floor',
        image: ATC_II_01_EG,
      },
      {
        name: 'First Floor',
        image: ATC_II_02_OG,
      },
    ],
  },
  {
    name: 'P5 - Hilton',
    subLabels: [
      {
        name: '',
        image: P5_Hilton,
      },
    ],
  },
];

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
});

interface P {
  title?: string;
}

export const LocationTabs = (props: P) => {
  const cls = useStyles();
  const labels = getLabels();
  return (
    <div className={cls.wrapper}>
      <Text variant="order2" spaceStackEnd="l">
        {props.title || 'Location Map'}
      </Text>
      <AudiTabs
        spacingBetweenInPixel={32}
        scrollable
        tabLabels={labels.map((l) => l.name)}
        tabContents={labels.map((l) => {
          return (
            <AudiTabs
              secondary
              spacingBetweenInPixel={32}
              scrollable
              key={l.name}
              tabLabels={l.subLabels.map((s) => s.name)}
              tabContents={l.subLabels.map((s) => (
                <LocationImage key={s.name} url={s.image} />
              ))}
            />
          );
        })}
      />
    </div>
  );
};
