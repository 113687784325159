import * as Cognito from '../ApiHandler/Cognito';
import { smsClient } from '../ApiHandler/smsClient';
import { NotifyType } from '../utils/hooks';
import strings from '../Localization/Localizer';
import { CustomError } from '../SoftButton';

/** Status of a user */
export type Status = 'canLogIn' | 'notFound' | 'canRegister' | 'unknown' | 'disabled';

/** Attempts to login a user with a wrong password, in order to retrieve their current status */
export const checkUsername = async (username: string): Promise<Status> => {
  try {
    await Cognito.signIn(username, 'test');
    return 'unknown';
  } catch (e: any) {
    return getFailReason(e);
  }
};

/** Translates the AWS Exception to a valid reason why the username check failed */
export const getFailReason = (exception: { code: string; message: string }) => {
  if (exception.message.includes('disabled')) return 'disabled';
  if (exception.code === 'NotAuthorizedException') return 'canLogIn';
  if (exception.code === 'UserNotFoundException') return 'notFound';
  if (exception.code === 'PasswordResetRequiredException') return 'canRegister';
  return 'unknown';
};

export type CodeMethod = null | 'mail' | 'sms';

/** Activates SMS authentication for the user */
export const activateSms = async (
  username: string,
  poolId: string,
  notify: NotifyType
): Promise<boolean> => {
  try {
    const res = (await smsClient.post('activate', { username: username, poolId: poolId })).data;
    if (!res) {
      notify(getErrorMessage(102, strings.noPhoneNumber), 'error');
      return false;
    }
    return true;
  } catch {
    notify(getErrorMessage(102, strings.noPhoneNumber), 'error');
    return false;
  }
};

/** Deactivates SMS authentication for the user */
export const deactivateSms = async (username: string, poolId: string) => {
  try {
    await smsClient.post('deactivate', { username: username, poolId: poolId });
  } catch {}
};

/** Assembles error code and message to an error message */
export const getErrorMessage = (code: number, string: string) => `${string} (#${code})`;

/** Assembles the message when an email/sms was sent */
export const getSentMessage = (sms: boolean, destination: string) =>
  `${sms ? strings.sentSmsTo : strings.sentMailTo} ${destination}`;

/** Error message of a "soft" Form, which you can attempt tp submit, when not validating */
export const getFormErrorMessage = (error: CustomError) =>
  error.type === 'missing' || error.message === strings.required
    ? strings.makeSureFieldsFilled
    : error.message;
