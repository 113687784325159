import React from 'react';
import { EditDialog } from '@pp-labs/ui-components';
import strings from '../../../Localization/Localizer';
import { useTexts } from '../../../utils/hooks';
import { EditorParser } from '../../../features/Editor/EditorParser';

/** interface for InstructionPopup props coming from parent component LOT */
interface P {
  close: () => void;
}

/**
 * Dialogue for displaying long info texts
 */

export const InstructionPopup = (props: P) => {
  const { getText, status } = useTexts();
  const text = getText('lotDisclaimer');
  if (!text) {
    if (status === 'loaded') props.close();
    return null;
  }
  return (
    <EditDialog title={strings.lotAExpHead} close={props.close}>
      <EditorParser inputString={text} />
    </EditDialog>
  );
};
