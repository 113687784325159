import { Text } from '@pp-labs/ui-components';
import strings from '../Localization/Localizer';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textWrapper: {
    display: 'flex',
    marginTop: '4px',
  },
  icon: {
    marginLeft: '6px',
  },
}));

const specialChars = '@$!%*#?()&,_';
const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const largeAlphabet = alphabet.toUpperCase();
const numbers = '0123456789';
const allChars = [...specialChars, ...alphabet, ...largeAlphabet, ...numbers];

const testPassword = (pw: string) => {
  return {
    length: pw.length >= 12,
    upper: pw !== pw.toLowerCase(),
    lower: pw !== pw.toUpperCase(),
    number: /\d/.test(pw),
    special: [...specialChars].some((c) => pw.includes(c)),
    unallowed: [...pw].filter((c) => !allChars.includes(c)),
  };
};

/** Component to verify a password against the requirements and list them */
export const PasswordVerifier = (props: { password: string }) => {
  const pwCheck = testPassword(props.password);
  const cls = useStyles();
  return (
    <div style={{ padding: '48px 0 24px' }}>
      <Text as="p" variant="copy1">
        {strings.passwordRules}
      </Text>
      <div style={{ marginTop: '23px' }} />
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        {strings.charsLong} {pwCheck.length && <CheckCircleIcon className={cls.icon} />}
      </Text>
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        {strings.upper} {pwCheck.upper && <CheckCircleIcon className={cls.icon} />}
      </Text>
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        {strings.lower} {pwCheck.lower && <CheckCircleIcon className={cls.icon} />}
      </Text>
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        {strings.number} {pwCheck.number && <CheckCircleIcon className={cls.icon} />}
      </Text>
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        {strings.special}
        {pwCheck.special && <CheckCircleIcon className={cls.icon} />}
      </Text>
      <Text as="p" variant="copy1" className={cls.textWrapper}>
        - Does not contain any unallowed characters
        {pwCheck.unallowed.length ? (
          <>
            <span style={{ paddingLeft: 8 }}>{'('}</span>
            <span className="field-error">{pwCheck.unallowed.join('')}</span>
            {')'}
          </>
        ) : (
          <CheckCircleIcon className={cls.icon} />
        )}
      </Text>
    </div>
  );
};
