import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { client } from '../../../../ApiHandler/client';
import { LabelButton, TextArea, Text, FormGroup } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { useNotify } from '../../../../utils/hooks';
import { PostAma } from '../../../../ApiHandler/dclxInterfaces';

const validation = Yup.object({
  question: Yup.string(),
  topic: Yup.string(),
}).required();

type F = Yup.InferType<typeof validation>;

/** interface for ConventionAma props coming from parent component Watch */
interface P {
  sessionId: number;
}
/**
 * Ask Me Anything Form for Convention-Like Amas
 */
export const ConventionAma = (props: P) => {
  const notify = useNotify();
  const initialValues: F = {
    question: '',
    topic: '',
  };
  return (
    <>
      <Text as="h3" variant="order2" weight={'bold'}>
        {strings.ama}
      </Text>
      <div style={{ paddingTop: '10px' }}>
        <Text as="h3" variant="copy1">
          {strings.amaExplain}
        </Text>
      </div>
      <div style={{ padding: '10px' }} />
      <Formik
        enableReinitialize={true}
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={async (values: F, { resetForm }) => {
          if (!values.question) return;
          const data: PostAma = {
            sessionId: props.sessionId,
            question: values.question,
            topic: values.topic || '',
          };
          await client.post(`sessions/${props.sessionId}/vodAmas`, data);
          notify(strings.thanksForFeedback, 'success');
          resetForm();
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <FormGroup>
              <div style={{ padding: '16px 0' }} />
              <TextArea
                name="question"
                label={strings.typeConventionQuestion}
                error={touched.question ? errors.question : ''}
                rows={2}
              />

              <div style={{ padding: '8px 0' }} />
              <LabelButton type="submit" variant="primary" disabled={!values.question}>
                {strings.send}
              </LabelButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </>
  );
};
