import React, { useState } from 'react';
import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AudiMenu from '../../../utils/AudiMenu';
import {
  CancelIcon,
  makeThemedStyles,
  MenuIcon,
  useIsTheme,
  useThemedStyles,
} from '@pp-labs/ui-components';
import strings from '../../../Localization/Localizer';
import { Text } from '@pp-labs/ui-components';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const styles = makeThemedStyles((theme: Theme) => {
  return {
    buttonCollapse: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px',
      boxShadow: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '10px 10px 10px 2px',
      },
    },
    paper: {
      width: '100%',
      maxWidth: '100% !important',
      left: '0 !important',
      right: '0 !important',
      top: '56px !important',
      height: '100%',
      boxShadow: 'none',
      borderRadius: '0px !important',
      paddingTop: '22px',
      maxHeight: '100% !important',
      background: ({ isTheme }) => (isTheme.neutralDark ? '#000000' : undefined),
    },
    menuContent: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '16px',
      },
      [theme.breakpoints.only('md')]: {
        paddingLeft: '26px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '12px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
      },
    },
    logoWrapper: {
      marginTop: '8px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      [theme.breakpoints.only('sm')]: {
        paddingLeft: ({ isTheme }) => (isTheme.audi ? '30px' : undefined),
      },
    },
    whiteIcon: {
      color: '#ffffff',
    },
  };
});

/** interface for ButtonAppBarCollapse props coming from parent component NavBar */
interface P {
  children: JSX.Element[] | JSX.Element;
  event?: string;
  logo: string;
  setMobileMenuOpen: (open: boolean) => void;
}
/** Mobile Burger Navigation */
const ButtonAppBarCollapse = (props: P) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const classes = useThemedStyles(styles);
  const theme = useIsTheme();
  const handleMenu = (event: any) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
    props.setMobileMenuOpen(!anchorEl);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props.setMobileMenuOpen(false);
    setMenuOpen(false);
  };
  return (
    <>
      <div className={classes.buttonCollapse}>
        <IconButton
          onClick={menuOpen ? handleClose : handleMenu}
          className={clsx(theme.neutralDark && classes.whiteIcon)}
        >
          {menuOpen ? <CancelIcon /> : <MenuIcon />}
        </IconButton>
        <Text as="span" variant="copy1">
          {strings.menu}
        </Text>
        <div className="test-me">
          <AudiMenu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            PopoverClasses={{ paper: classes.paper }}
          >
            <div onClick={handleClose} className={classes.menuContent}>
              {props.children}
            </div>
          </AudiMenu>
        </div>
      </div>
      <div className={classes.logoWrapper}>
        {!anchorEl && (
          <Link to={`/${props.event}/home`}>
            <img src={props?.logo} alt={'logo'} />
          </Link>
        )}
      </div>
    </>
  );
};

export default ButtonAppBarCollapse;
