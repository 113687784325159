import { IconButton } from '@material-ui/core';
import { client } from 'ApiHandler/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AudiTabs from 'utils/AudiTabs/AudiTabs';
import { NotificationMessage } from 'DCLX/CMS/Messages/Messages';
import { NotificationTile } from './NotificationTile';
import { useOutsideAlerter, useRealTime } from 'utils/hooks';
import { Text, NotificationIcon, useThemedStyles, makeThemedStyles } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';

const style = makeThemedStyles((theme) => {
  return {
    wrapper: {
      position: 'absolute',
      right: 0,
      top: '80px',
      width: '380px',
      border: '1px solid black',
      borderWidth: '1px 1px 1px 1px',
      maxHeight: '520px',
      minHeight: '520px',
      overflow: 'auto',
      background: '#FFFFFF',
      paddingTop: '6px',
      [theme.breakpoints.down('xs')]: {
        top: '71px',
        width: '320px',
      },
    },
    iconWrapper: {
      position: 'relative',
      cursor: 'pointer',
    },
    notificationIcon: {
      position: 'absolute',
      top: ({ isTheme }) => (isTheme.audi ? '0px' : '10px'),
      right: ({ isTheme }) => (isTheme.audi ? '6px' : '11px'),
      width: 20,
      height: 20,
      borderRadius: '50%',
      background: '#F50537',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    whiteText: {
      color: '#FFFFFF',
    },
    tip: {
      width: '20px',
      height: '20px',
      borderTop: '1px solid black',
      borderLeft: '1px solid black',
      position: 'absolute',
      right: ({ isTheme }) => (isTheme.audi ? '14px' : '20px'),
      bottom: ({ isTheme }) => (isTheme.audi ? '-26px' : '-20px'),
      backgroundColor: 'white',
      transform: 'rotate(45deg)',
      zIndex: 140,
      [theme.breakpoints.down('xs')]: {
        bottom: ({ isTheme }) => (isTheme.audi ? '-22px' : '-16px'),
      },
    },
  };
});

/** Message/Notification Panel in the Navbar */
const MessageNotification = () => {
  const cls = useThemedStyles(style);
  const ref = useRef<HTMLDivElement>(null);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const close = useCallback(() => setOpenNotification(false), []);
  useOutsideAlerter<HTMLDivElement>(ref, close);
  const currentTime = useRealTime(1000);
  const [allMessages, setAllMessages] = useState<NotificationMessage[]>([]);
  const relevantMessages = useMemo(
    () =>
      allMessages
        .filter((m) => m.validFrom * 1000 < currentTime)
        .sort((n1, n2) => n2.validFrom - n1.validFrom),
    [allMessages, currentTime]
  );
  const getMessages = useCallback(async () => {
    const data = (await client.get<NotificationMessage[]>('/notifications/active')).data;
    setAllMessages(data);
  }, []);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      getMessages();
    }, 30000);
    getMessages();
    return () => {
      clearInterval(messageInterval);
    };
  }, [getMessages]);
  const newMessages = useMemo(
    () => relevantMessages?.filter((m) => !m.visited),
    [relevantMessages]
  );
  const getTabs = [
    <div>
      {newMessages.map((m) => (
        <NotificationTile
          key={m.notificationId}
          message={m}
          refresh={getMessages}
          currentTime={currentTime}
        />
      ))}
    </div>,
    <div>
      {relevantMessages?.map((m) => (
        <NotificationTile
          key={m.notificationId}
          message={m}
          refresh={getMessages}
          currentTime={currentTime}
        />
      ))}
    </div>,
  ];

  return (
    <div ref={ref}>
      <div className={cls.iconWrapper}>
        <IconButton onClick={() => setOpenNotification(!openNotification)}>
          <NotificationIcon />
        </IconButton>
        {newMessages.length > 0 && (
          <div className={cls.notificationIcon}>
            <Text className={cls.whiteText} variant="copy3">
              {newMessages.length}
            </Text>
          </div>
        )}
      </div>
      {openNotification && (
        <>
          <div style={{ position: 'relative' }}>
            <div className={cls.tip} />
          </div>
          <div className={cls.wrapper}>
            <AudiTabs
              tabLabels={[strings.unreadMessages, strings.allMessages]}
              tabContents={getTabs}
              notification={true}
            />
          </div>
        </>
      )}
    </div>
  );
};
export { MessageNotification };
