import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  LabelButton,
  EditDialog,
  LocalizedForm,
  Toggle,
  Text,
  TextField,
} from '@pp-labs/ui-components';
import { useProgressionChannels, useNotify } from 'utils/hooks';
import { atLeastOneLanguage } from 'utils/validator';
import { makeStyles } from '@material-ui/core';
import { CmsText } from './TextCms';
import strings, { useLocalizedLanguages } from '../../Localization/Localizer';
import { ApiText } from '../../ApiHandler/dclxInterfaces';
import { client } from '../../ApiHandler/client';
import { Editor } from '../Editor/Editor';
import AudiSpacer from '../../utils/AudiSpacer';

/** interface for EditText props coming from parent component TextCms */
interface P {
  close: (refresh?: boolean) => void;
  prevText: CmsText;
}

const validation = Yup.object({
  text: atLeastOneLanguage(),
}).required(strings.required);

interface FormValues {
  text: string;
}

const useStyle = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: '18px',
  },
});

/**
 * Allows users to edit a text
 */
export const EditText = (props: P) => {
  const notify = useNotify();
  const cls = useStyle();
  const channels = useProgressionChannels();
  const languages = useLocalizedLanguages(channels);
  const [isActive, setIsActive] = useState<boolean>(props.prevText.isActive);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
  const initialValues: FormValues = useMemo(() => {
    const text = props.prevText.text ? JSON.parse(props.prevText.text) : '';
    return { text: text };
  }, [props.prevText]);

  const submitToServer = async (v: FormValues) => {
    const data: ApiText = {
      textKey: props.prevText.key,
      textString: JSON.stringify(v.text),
      isActive: isActive,
    };
    await client.post('texts', [data]);
    notify(strings.uploadedText, 'success');
    props.close(true);
  };

  return (
    <EditDialog title="Edit text" close={props.close} maxWidth="md" cms>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={submitToServer}>
        <LocalizedForm
          languages={languages}
          localizeInputs={['text']}
          initialValues={{ text: initialValues.text }}
          autoParse={[]}
          onLanguageChange={(i: number) => {
            setSelectedLanguage(i);
          }}
        >
          {props.prevText.type === 'editor' ? (
            <>
              <input name="text" hidden />
              <Editor formikName="text" label={'text'} selectedLanguage={selectedLanguage} />
            </>
          ) : (
            <TextField name="text" label={'text'} />
          )}
          <AudiSpacer spaceStackEnd="m" />
          <Text>{strings.public}</Text>
          <AudiSpacer spaceStackEnd="s" />
          <Toggle inputId="activeText" onChange={() => setIsActive(!isActive)} on={isActive} />

          <div className={cls.buttonsWrapper}>
            <LabelButton variant="secondary" onClick={() => props.close()}>
              {strings.cancel}
            </LabelButton>
            <LabelButton type="submit" variant="primary" className={cls.submitButton}>
              {strings.save}
            </LabelButton>
          </div>
        </LocalizedForm>
      </Formik>
    </EditDialog>
  );
};
