import { EditDialog, LabelButton } from '@pp-labs/ui-components';
import { Channel, EditLevel, Interrupt } from 'ApiHandler/dclxInterfaces';
import strings from 'Localization/Localizer';
import React, { useState } from 'react';
import { EditTimeCode } from './EditTimeCode';
import { InterruptTable } from './InterruptTable';

/** interface for InterruptsDialogs props coming from parent component DCLXCmsSessionEdit */
interface P {
  openQuestionDialog: boolean;
  closeQuestionDialog: () => void;
  sessionId: number;
  interrupts: Interrupt[];
  level: EditLevel;
  refreshInterrupts: () => Promise<void>;
  channel: Channel;
}

/** Edit interrupts */
const InterruptsDialogs = (props: P) => {
  const [currentEdit, setCurrentEdit] = useState<Interrupt | 'new' | 'none'>('none');

  if (!props.openQuestionDialog) return null;
  return (
    <>
      <EditDialog maxWidth="lg" title={strings.questions} close={props.closeQuestionDialog}>
        {props.interrupts.map((interrupt, i) => (
          <InterruptTable
            key={i}
            interrupt={interrupt}
            refreshInterrupts={props.refreshInterrupts}
            editInterrupt={(interruptParam) => setCurrentEdit(interruptParam)}
            level={props.level}
            channel={props.channel}
          />
        ))}
        <LabelButton
          variant="primary"
          spaceInlineEnd="l"
          onClick={() => {
            setCurrentEdit('new');
          }}
        >
          {strings.newTimeCode}
        </LabelButton>
      </EditDialog>
      {currentEdit !== 'none' && (
        <EditTimeCode
          close={() => setCurrentEdit('none')}
          currentInterrupt={currentEdit === 'new' ? null : currentEdit}
          sessionId={props.sessionId}
          refreshInterrupts={props.refreshInterrupts}
        />
      )}
    </>
  );
};

export { InterruptsDialogs };
