import React from 'react';
import { Text } from '@pp-labs/ui-components';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import Chrome from '../../../media/browserLogos/chrome.png';
import Edge from '../../../media/browserLogos/edge.png';
import IECross from '../../../media/IECross.png';
import DesktopMobile from '../../../media/DesktopMobile.svg';
import SafariCross from '../../../media/browserLogos/SafariCross.svg';
import Wifi from '../../../media/Wifi.svg';
import ContactSupport from '../../../features/FAQ/ContactSupport';
import strings from '../../../Localization/Localizer';

/** Displays some help in a LOT */
const LotHelpDialogue = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      mainContainer: {
        paddingRight: '90px',
      },
      subTitle: {
        paddingTop: '48px',
        paddingBottom: '30px',
      },
      descPoints: {
        paddingTop: '18px',
      },
      browsersContainer: {
        marginTop: '20px',
      },
      browsers: {
        marginTop: '18px',
        display: 'flex',
        justifyContent: 'start',
        textAlign: 'center',
      },
      browserImg: {
        height: '58px',
      },
      spacer: {
        height: '2px',
        backgroundColor: 'black',
        width: '100%',
        marginTop: '22px',
        marginBottom: '22px',
      },
      contactContainer: {
        marginTop: '120px',
      },
    })
  );

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={8} className={classes.mainContainer}>
        <Text as="h2" variant="order2" weight="bold">
          {strings.lHTitle}
        </Text>
        <div className={classes.subTitle}>
          <Text as="h3" variant="order3" weight="bold">
            {strings.lHSubTitle}
          </Text>
          <div className={classes.descPoints}>
            <Text as="h4" variant="order4" weight="bold">
              {strings.lHFirstParagraphTitle}
            </Text>
            <Text as="p" variant="copy1">
              {strings.lHFirstParagraphDesc}
            </Text>
          </div>
          <div className={classes.descPoints}>
            <Text as="h4" variant="order4" weight="bold">
              {strings.lHSecondParagraphTitle}
            </Text>
            <Text as="p" variant="copy1">
              {strings.lHSecondParagraphDesc}
            </Text>
          </div>
          <div className={classes.descPoints}>
            <Text as="h4" variant="order4" weight="bold">
              {strings.lHThirdParagraphTitle}
            </Text>
            <Text as="p" variant="copy1">
              {strings.lHThirdParagraphDesc}
            </Text>
          </div>
          <div className={classes.subTitle}>
            <Text as="h3" variant="order3" weight="bold">
              {strings.lHBottomParagraphTitle}
            </Text>
            <Text as="p" variant="copy1">
              {strings.lHBottomParagraphDesc}
            </Text>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Text as="p" variant="copy1" weight="bold">
          {strings.lHSidebarTitle}
        </Text>
        <div className={classes.browsers}>
          <div style={{ marginRight: '8px' }}>
            <img className={classes.browserImg} src={Edge} alt="edge" />
            <Text as="p" variant="copy1">
              Microsoft
            </Text>
            <Text as="p" variant="copy1">
              Edge
            </Text>
            <Text as="p" variant="copy2">
              {strings.versionMicrosoftEdge}
            </Text>
          </div>
          <div style={{ marginRight: '8px' }}>
            <img className={classes.browserImg} src={Chrome} alt="chrome" />
            <Text as="p" variant="copy1">
              Google
            </Text>
            <Text as="p" variant="copy1">
              Chrome
            </Text>
            <Text as="p" variant="copy2">
              {strings.versionGoogleChrome}
            </Text>
          </div>
        </div>
        <div className={classes.browsersContainer}>
          <img style={{ height: '52px', marginLeft: '-10px' }} src={SafariCross} alt="safari" />
          <img style={{ height: '52px', marginLeft: '-12px' }} src={IECross} alt="ie" />
        </div>
        <Text as="p" variant="copy2" weight="bold">
          {strings.lHBrowserDesc}
        </Text>
        <div style={{ marginTop: '22px' }}>
          <Text as="p" variant="copy1" weight="bold">
            {strings.lhConnectionTitle}
            <img style={{ height: '32px' }} src={Wifi} alt="wifi" />
          </Text>
          <Text as="p" variant="copy2">
            {strings.lhConnectionDesc}
          </Text>
        </div>
        <div style={{ marginTop: '22px' }}>
          <Text as="p" variant="copy1" weight="bold">
            {strings.lhSupportDevicesTitle}
            <img
              style={{ height: '48px', margin: '-7px 6px' }}
              src={DesktopMobile}
              alt="DesktopMobile"
            />
          </Text>
        </div>
        <Text as="p" variant="copy2">
          {strings.lhSupportDevicesDesc}
        </Text>
      </Grid>
      <div className={classes.spacer} />
      <div>
        <Text as="h3" variant="order3" weight="bold">
          {strings.lHfooter}
        </Text>
        <div className={classes.contactContainer}>
          <ContactSupport lotSupport={true} />
        </div>
      </div>
    </Grid>
  );
};

export { LotHelpDialogue };
