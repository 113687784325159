import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { EditDialog, LabelButton } from '@pp-labs/ui-components';
import strings from '../../../../../Localization/Localizer';
import { LotHelpDialogue } from '../../LotHelpDialogue';

/** interface for LotSupport props coming from parent component LotTopBar  */
interface P {
  isTrainer: boolean;
  streamingLot: boolean;
  mobile: boolean;
}

const useStyles = makeStyles(() => ({
  helpButton: {
    border: '1px solid white !important',
    margin: '0 4px',
  },
  textContainer: {
    marginLeft: '32px',
  },
  stream: {
    backgroundColor: 'black',
    width: '100%',
  },
  iconWrapper: {
    background: '#000000',
    color: '#FFFFFF',
    border: '1px solid',
    fontFamily: 'inherit',
    fontSize: '18px',
    display: 'flex',
    width: '41px',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 * Lot support for the LOT
 */

export const LotSupport = (props: P) => {
  const cls = useStyles();
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  return (
    <>
      {props.mobile ? (
        <div className={cls.iconWrapper} onClick={() => setOpenHelp(true)}>
          ?
        </div>
      ) : (
        <div className={'lot__videoTools'}>
          <LabelButton
            className={cls.helpButton}
            variant="secondary"
            onClick={() => setOpenHelp(true)}
            lotWhiteOutline
          >
            {strings.lothelp}
          </LabelButton>
        </div>
      )}
      {openHelp && (
        <EditDialog title={''} close={() => setOpenHelp(false)}>
          <LotHelpDialogue />
        </EditDialog>
      )}
    </>
  );
};
