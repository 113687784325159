import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  image: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
});

interface P {
  url: string;
}

export const LocationImage = (props: P) => {
  const cls = useStyles();
  return <img src={props.url} alt="Unable to load location plan" className={cls.image} />;
};
