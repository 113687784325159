import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { useEffect, useMemo, useState } from 'react';
import { EditDialog, LabelButton, Select, TextArea } from '@pp-labs/ui-components';
import { useQuestionState } from './QuestionState';
import { client } from '../../../ApiHandler/client';
import { AmaAnswer, AmaAssign, AnswerModes } from '../../../ApiHandler/dclxInterfaces';
import { serverNow, sleep } from '../../../utils/convert';
import { useUser } from '../../../utils/hooks';
import AudiSpacer from '../../../utils/AudiSpacer';

const getValidation = () =>
  Yup.object({
    question: Yup.string().required(strings.required),
    type: Yup.string().required(strings.required),
    answer: Yup.string(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

/** Add/edit the answer for a Live AMA */
export const AnswerDialog = () => {
  const user = useUser()!;
  const [sending, setSending] = useState<boolean>(false);
  const { answering, setAnswering, wsSend } = useQuestionState();
  const validationSchema = useMemo(getValidation, []);
  const initialValues = useMemo(
    () => ({
      answer: answering?.answerMode === 'live' ? '' : answering?.answer || '',
      question: answering?.question || '',
      type: answering?.answerMode || '',
    }),
    [answering]
  );
  useEffect(() => {
    const assign = async () => {
      if (!answering) return;
      const data: AmaAssign = {
        startAnswer: serverNow(),
        answeredBy: user.sub,
      };
      await client.put(`sessions/liveAmas/${answering.liveAmaId}`, data);
      wsSend({ name: 'refreshAmaDir', time: serverNow() });
    };
    assign();
  }, [answering, user.sub, wsSend]);

  const abort = async () => {
    if (!answering) return;
    const data = {
      startAnswer: null,
      answeredBy: null,
    };
    await client.put(`sessions/liveAmas/${answering.liveAmaId}`, data);
    wsSend({ name: 'refreshAmaDir', time: serverNow() });
    setAnswering(null);
  };

  const submit = async (values: F) => {
    if (!answering) return;
    setSending(true);
    const id = answering.liveAmaId;
    const mode = values.type as AnswerModes;
    const data: AmaAnswer = {
      question: values.question,
      answer: values.answer || answering.answer || 'pending',
      answerMode: mode,
      dismissed: false,
      answeredBy: user.sub,
      startAnswer: null,
    };
    await client.put(`sessions/liveAmas/${id}`, data);

    if (mode === 'private' || mode === 'public')
      wsSend({
        name: 'refreshAmaVis',
        user: mode === 'public' ? 'all' : answering.username,
      });
    await sleep(500);
    wsSend({ name: 'refreshAmaDir', time: serverNow() });
    setSending(false);
    setAnswering(null);
  };
  if (!answering) return null;
  return (
    <EditDialog close={abort} title={strings.answerQuestion}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ values, touched, errors }) => {
          const et = {
            question: touched.question ? errors.question : '',
            answer: touched.answer ? errors.answer : '',
            type: touched.type ? errors.type : '',
          };
          return (
            <Form>
              <TextArea name="question" label={strings.question} error={et.question} rows={3} />
              <AudiSpacer spaceStackStart="l" />
              <Select name="type" label={strings.answerType} error={et.type}>
                <option value="public">{strings.public}</option>
                <option value="private">{strings.private}</option>
                <option value="live">{strings.live}</option>
              </Select>
              <AudiSpacer spaceStackStart="l" />
              <TextArea
                name="answer"
                label={strings.answer}
                error={et.answer}
                rows={3}
                disabled={!values.type || values.type === 'live'}
              />
              <AudiSpacer spaceStackStart="l" />
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <LabelButton
                  variant="secondary"
                  disabled={sending}
                  spaceInlineEnd="s"
                  onClick={abort}
                >
                  {strings.cancel}
                </LabelButton>
                <LabelButton
                  variant="primary"
                  type="submit"
                  disabled={!values.type || sending || (!values.answer && values.type !== 'live')}
                >
                  {strings.submitAnswer}
                </LabelButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
