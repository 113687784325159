import { IconButton, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import * as Yup from 'yup';
import {
  DraggableComponent,
  DroppableComponent,
  EditDialog,
  handleDrop,
  LabelButton,
  LocalizedForm,
  TableRow,
  TextField,
  tableStyles,
  TableCell,
  Text,
  EditIcon,
  EraseIcon,
} from '@pp-labs/ui-components';
import { client } from 'ApiHandler/client';
import { MenuItem } from 'ApiHandler/dclxInterfaces';
import { Form, Formik, FormikErrors } from 'formik';
import strings, { getLocalizedValue } from 'Localization/Localizer';

import React, { useCallback, useEffect, useState } from 'react';
import { useEventLanguageSelection, useNotify } from 'utils/hooks';
import { atLeastOneLanguage } from 'utils/validator';

const useStyles = makeStyles({
  ...tableStyles(),
  cancelButton: {
    textAlign: 'right',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rowBackground: {
    background: '#f2f2f2',
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

export const MenuItemsCMS = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [addMenuItem, setAddMenuItem] = useState<boolean>(false);
  const [editMenuItem, setEditMenuItem] = useState<MenuItem | undefined>(undefined);
  const cls = useStyles();
  const notify = useNotify();

  const fetchData = useCallback(async () => {
    const s: MenuItem[] = (await client.get('channels/menuItems')).data;
    setMenuItems(s);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDragEnd = (result: any) => {
    const menuItem = handleDrop(result, menuItems);
    if (menuItem) setMenuItems(menuItem);
  };
  const confirmOrder = async () => {
    if (!menuItems) return;
    await client.post(
      `channels/menuItems/order`,
      menuItems.map((s) => s.menuItemId)
    );
    notify(strings.orderSuccessfullyChanged, 'success');
  };

  const deleteMenuItem = async (menuItem: MenuItem) => {
    try {
      await client.delete(`channels/menuItem/${menuItem.menuItemId}`);
      fetchData();
      notify('Item deleted', 'success');
    } catch (e) {
      notify('Item in use, cannot delete.', 'error');
    }
  };

  const getButtons = () => {
    return (
      <div>
        <LabelButton
          variant="primary"
          onClick={() => setAddMenuItem(true)}
          id="addMenuItem"
          spaceInlineEnd="m"
        >
          {'Create New Menu Item'}
        </LabelButton>
        <LabelButton variant="secondary" className={cls.btnRight} onClick={confirmOrder}>
          {strings.confirmNewOrder}
        </LabelButton>
      </div>
    );
  };

  return (
    <div
      style={{
        width: '100vw',
        maxWidth: 2000,
        padding: 48,
      }}
    >
      <TableContainer style={{ marginTop: '4px' }} className={'sessionTable'}>
        <Table className={cls.table} aria-label="simple table">
          <TableBody component={DroppableComponent(onDragEnd)}>
            {menuItems.map((item: MenuItem, index) => {
              return (
                <TableRow
                  id="session-row"
                  component={DraggableComponent(item.menuItemKey, index)}
                  key={item.menuItemId}
                  className={cls.rowBackground}
                >
                  <TableCell center>
                    <Text as="h3" variant="order3">
                      {item.menuItemKey}
                    </Text>
                  </TableCell>
                  <TableCell center>
                    <Text as="h3" variant="order3">
                      {getLocalizedValue(item.title)}
                    </Text>
                  </TableCell>
                  <TableCell center buttons>
                    <IconButton
                      onClick={() => {
                        setEditMenuItem(item);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell center buttons>
                    <IconButton onClick={() => deleteMenuItem(item)}>
                      <EraseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ padding: '20px 0 60px 0' }}>{getButtons()}</div>
      {(addMenuItem || editMenuItem) && (
        <MenuItemEditFormDialog
          editItem={editMenuItem}
          closeEdit={() => {
            setEditMenuItem(undefined);
            setAddMenuItem(false);
            fetchData();
          }}
        />
      )}
    </div>
  );
};

export const MenuItemEditFormDialog = (props: { editItem?: MenuItem; closeEdit: () => void }) => {
  return (
    <EditDialog title={''} close={() => props.closeEdit()}>
      <MenuItemEditForm
        editItem={props.editItem}
        onShouldClose={() => props.closeEdit()}
      ></MenuItemEditForm>
    </EditDialog>
  );
};

const validation = () =>
  Yup.object({
    title: atLeastOneLanguage(),
    menuItemKey: Yup.string().required(strings.required),
  }).required(strings.required);

interface MenuItemForm {
  title: string;
  menuItemKey: string;
}

export const MenuItemEditForm = (props: { editItem?: MenuItem; onShouldClose: () => void }) => {
  const languageSelection = useEventLanguageSelection();
  const cls = useStyles();
  const notify = useNotify();
  let title = '';
  try {
    if (props.editItem) title = JSON.parse(props.editItem.title);
  } catch {}
  const initialValues: MenuItemForm = {
    title: props.editItem ? title : '',
    menuItemKey: props.editItem ? props.editItem.menuItemKey : '',
  };

  const [sending, setSending] = useState<boolean>();

  const onSubmit = async (item: MenuItemForm) => {
    setSending(true);

    try {
      if (!!props.editItem) {
        await client.put(`channels/menuItem/${props.editItem?.menuItemId}`, {
          menuItemKey: item.menuItemKey,
          title: JSON.stringify(item.title),
        });
      } else {
        await client.post('channels/menuItem', {
          menuItemKey: item.menuItemKey,
          title: JSON.stringify(item.title),
        });
      }
      notify('Menu Item Uploaded', 'success');
      props.onShouldClose();
    } catch (e: any) {
      if (e.response.status === 409) notify('Menu Item Key already taken.', 'error');
      else {
        notify(strings.error, 'error');
      }
    }
    setSending(false);
  };
  const getErrors = (touched: any, errors: FormikErrors<MenuItemForm>) => {
    return {
      title: touched.title ? errors.title : '',
      menuItemKey: touched.menuItemKey ? errors.menuItemKey : '',
    };
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validation()}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ errors, touched, setFieldValue, values }) => {
          const et = getErrors(touched, errors);
          return (
            <Form>
              <TextField name="menuItemKey" label={'Menu Item Key'} error={et.menuItemKey} />
              <LocalizedForm
                languages={languageSelection.languages.map((l) => l.name)}
                localizeInputs={['title']}
                initialValues={{ title: initialValues.title }}
                autoParse={[]}
              >
                <TextField name="title" label={strings.title} error={et.title} />
              </LocalizedForm>

              <div className={cls.inline} style={{ paddingLeft: '32px' }}>
                <LabelButton
                  variant="secondary"
                  className={cls.btnLeft}
                  onClick={props.onShouldClose}
                  disabled={sending}
                >
                  {strings.abort}
                </LabelButton>

                <LabelButton
                  variant="primary"
                  className={cls.btnRight}
                  type="submit"
                  disabled={sending}
                >
                  {strings.save}
                </LabelButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
