import React from 'react';
import { IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { LeaveTraining } from './LeaveTraining';
import { FullscreenIcon, LabelButton, useIsTheme, HandRaiseIcon } from '@pp-labs/ui-components';
import { LotSupport } from './utils/ControlBar/LotSupport';
import { RunningState } from './WebsocketCommands';
import strings from 'Localization/Localizer';
import clsx from 'clsx';
import { KeyIcon } from '@pp-labs/ui-components';
import { controlBarHeight } from './config';

const useStyle = makeStyles((theme) => ({
  mainWrapper: {
    background: 'black',
    height: controlBarHeight,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: '12px',
    },
  },
  iconButton: {
    color: 'white !important',
  },
  muteButton: {
    margin: 2,
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  flex: {
    display: 'flex',
  },
  icon: {
    height: '20px',
    width: '20px',
    marginRight: '16px',
  },
  trainerButton: {
    margin: '0 4px',
  },
  startTraining: {
    background: '#FFFFFF !important',
    color: '#000000 !important',
  },
  whiteBtn: {
    margin: '0 4px',
    borderColor: '#ffffff !important',
    color: '#ffffff !important',
    '&:hover': {
      borderColor: '#707070 !important',
      color: '#ffffff !important',
    },
    '&:disabled': {
      borderColor: '#4C4C4C !important',
      color: '#4C4C4C !important',
    },
  },
  keyIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  keyIcon: {
    fontSize: '24px',
    marginRight: '18px',
  },
  keyIconMobile: {
    background: '#000000',
    color: '#FFFFFF',
    border: '1px solid',
    fontSize: '18px',
    width: '41px',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
}));

/** interface for LotTopBar props coming from parent component LOT  */
interface P {
  noLeave?: boolean;
  isTrainer: boolean;
  leaveTraining: () => void;
  runningState: RunningState;
  toggleFullscreen: () => void;
  fullscreen: boolean;
  streamingLot: boolean;
  hand: boolean;
  toggleHand: () => void;
  sendActiveTrainerCommand: () => void;
  sendChangeRunningStateCommand: (runningState: RunningState) => void;
  canTakeOverTraining: boolean;
  togglePreview: () => void;
  preview: boolean;
  popupOpen: Function;
  preJoin: boolean;
  trainerJoin: () => void;
}

const LotTopBar = (props: P) => {
  const cls = useStyle();
  const muiTheme = useTheme();
  const theme = useIsTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const bigMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  if ((props.runningState === 0 || props.runningState === 1) && !props.isTrainer) return null;

  if (!props.preJoin && props.isTrainer && props.runningState === 0) {
    return (
      <div className={cls.mainWrapper}>
        <div className={cls.innerWrapper}>
          <LabelButton
            variant="primary"
            className={clsx(cls.trainerButton, theme.audi && cls.startTraining)}
            onClick={props.trainerJoin}
          >
            {strings.preJoin}
          </LabelButton>
        </div>
      </div>
    );
  }

  if (props.preJoin) {
    return (
      <div className={cls.mainWrapper}>
        <div className={cls.innerWrapper}>
          <LabelButton
            variant="primary"
            className={clsx(cls.trainerButton, theme.audi && cls.startTraining)}
            onClick={() => props.sendChangeRunningStateCommand(3)}
          >
            {strings.startTraining}
          </LabelButton>
        </div>
      </div>
    );
  }
  return (
    <div className={cls.mainWrapper}>
      {/* Visitor view top bar for both Jitsi and big lots  */}
      {!props.isTrainer && (
        <div className={cls.innerWrapper}>
          {!props.streamingLot && (
            <IconButton className={cls.iconButton} onClick={props.toggleFullscreen}>
              <FullscreenIcon fullscreen={props.fullscreen} />
            </IconButton>
          )}
          {!props.isTrainer && !props.streamingLot && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton className={cls.iconButton} onClick={props.toggleHand}>
                <HandRaiseIcon active={props.hand} />
              </IconButton>
            </div>
          )}
          {props.runningState >= 2 && (
            <LotSupport
              mobile={mobile}
              isTrainer={props.isTrainer}
              streamingLot={props.streamingLot}
            />
          )}
          {!props.noLeave && <LeaveTraining mobile={mobile} leaveTraining={props.leaveTraining} />}
        </div>
      )}
      {/* Top bar for jitsi lots trainer view */}
      {!props.streamingLot && props.isTrainer && (
        <div className={cls.innerWrapper}>
          <IconButton className={cls.iconButton} onClick={props.toggleFullscreen}>
            <FullscreenIcon fullscreen={props.fullscreen} />
          </IconButton>
          {!bigMobile && (
            <>
              <LabelButton
                variant={'secondary'}
                className={cls.whiteBtn}
                onClick={() =>
                  props.sendChangeRunningStateCommand(props.runningState === 3 ? 2 : 3)
                }
                disabled={props.runningState <= 1}
                lotWhiteOutline
              >
                {props.runningState === 2 ? strings.enterTile : strings.enterPresenter}
              </LabelButton>{' '}
              <LabelButton
                variant={'secondary'}
                className={cls.whiteBtn}
                onClick={() => props.sendActiveTrainerCommand()}
                disabled={!props.canTakeOverTraining}
                lotWhiteOutline
              >
                {strings.takeOverTraining}
              </LabelButton>
            </>
          )}
          {(props.runningState === 1 || props.runningState === 0) && (
            <LabelButton
              variant={'primary'}
              className={clsx(cls.trainerButton, theme.audi && cls.startTraining)}
              onClick={() => props.sendChangeRunningStateCommand(3)}
            >
              {props.runningState === 1 ? strings.restartTraining : strings.startTraining}
            </LabelButton>
          )}
          {!props.noLeave && !(props.runningState === 1 || props.runningState === 0) && (
            <LeaveTraining mobile={mobile} leaveTraining={props.leaveTraining} />
          )}
        </div>
      )}
      {/* Top bar for Big lots trainer view */}
      {props.streamingLot && props.isTrainer && (
        <div className={cls.innerWrapper}>
          {mobile ? (
            <div className={cls.keyIconMobile}>
              <KeyIcon />
            </div>
          ) : (
            <LabelButton
              variant="secondary"
              className={cls.whiteBtn}
              onClick={() => props.popupOpen()}
              lotWhiteOutline
            >
              <span className={cls.keyIconWrapper}>
                <span className={cls.keyIcon}>
                  <KeyIcon />
                </span>
                {strings.streamKey}
              </span>
            </LabelButton>
          )}
          {props.runningState === 0 && (
            <LabelButton variant="secondary" className={cls.whiteBtn} onClick={props.togglePreview}>
              {props.preview ? strings.stopPreview : strings.startPreview}
            </LabelButton>
          )}
          {(props.runningState === 1 || props.runningState === 0) && (
            <LabelButton
              variant={'primary'}
              className={clsx(cls.trainerButton, theme.audi && cls.startTraining)}
              onClick={() => props.sendChangeRunningStateCommand(3)}
            >
              {props.runningState === 1 ? strings.restartTraining : strings.startTraining}
            </LabelButton>
          )}

          {!props.noLeave && props.runningState === 3 && (
            <LeaveTraining mobile={mobile} leaveTraining={props.leaveTraining} />
          )}
        </div>
      )}
    </div>
  );
};

export { LotTopBar };
