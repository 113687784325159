import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import strings from '../../Localization/Localizer';
import { LabelButton, AudiDialog } from '@pp-labs/ui-components';

/** interface for DeleteDialogue props coming from parent component AdminPanelTable, ChannelTable, sessionTable,... */
interface P {
  confirm: () => void;
  reject: () => void;
  title: string;
  message: string;
}

/**
 * Dialogue to confirm deletions
 */

const ConfirmDialogue = (props: P) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const confirm = async () => {
    setSubmitting(true);
    await props.confirm();
    setSubmitting(false);
  };

  const reject = () => {
    props.reject();
  };
  const theme = useIsTheme();
  return (
    <AudiDialog
      open={true}
      onClose={reject}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ backgroundColor: theme.neutralDark ? 'black' : undefined }}>
        <DialogTitle id="alert-dialog-title">
          <Text as="p" variant="order4">
            {props.title}
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Text as="p" variant="copy1">
              {props.message}
            </Text>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LabelButton onClick={() => props.reject()} variant="secondary" disabled={submitting}>
            {strings.abort}
          </LabelButton>
          <LabelButton onClick={() => confirm()} variant="secondary" disabled={submitting}>
            {strings.yes}
          </LabelButton>
        </DialogActions>
      </div>
    </AudiDialog>
  );
};
export default ConfirmDialogue;
