import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { EditIcon, EraseIcon, Text } from '@pp-labs/ui-components';
import { Channel, EditLevel, Interrupt } from 'ApiHandler/dclxInterfaces';
import { getTime } from 'utils/convert';
import { QuestionTable } from './QuestionsTable';
import { client } from 'ApiHandler/client';
import { TableArrows } from 'DCLX/utils/TableArrows';

/** interface for InterruptsDialogs props coming from parent component InterruptDialogs */
interface P {
  interrupt: Interrupt;
  editInterrupt: (interrupt: Interrupt) => void;
  level: EditLevel;
  refreshInterrupts: () => Promise<void>;
  channel: Channel;
}

/** List session interrupts */
const InterruptTable = (props: P) => {
  const [showQuestions, setShowQuestions] = useState<boolean>(true);

  const deleteInterrupt = async () => {
    await client.delete(`interrupts/${props.interrupt.interruptId}`);
    await props.refreshInterrupts();
  };

  return (
    <>
      <div
        style={{
          justifyContent: 'space-between',
          padding: '30px',
          background: '#e5e5e5',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginTop: '12px',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => setShowQuestions(!showQuestions)}
        >
          <IconButton style={{ color: 'black' }} disabled>
            {<TableArrows open={showQuestions} />}
          </IconButton>
          <Text variant="copy1" weight={'bold'}>
            Timecode
          </Text>
        </div>
        <div style={{ width: '20%', display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ padding: '12px' }}>
            <Text variant="copy1" weight={'bold'}>
              {getTime(props.interrupt.timeCode, true)}
            </Text>
          </div>
          <IconButton
            onClick={() => {
              props.editInterrupt(props.interrupt);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={deleteInterrupt}>
            <EraseIcon />
          </IconButton>
        </div>
      </div>
      {showQuestions && (
        <QuestionTable
          interrupt={props.interrupt}
          level={props.level}
          refreshInterrupts={props.refreshInterrupts}
          channel={props.channel}
        />
      )}
    </>
  );
};
export { InterruptTable };
