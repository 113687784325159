import { SurveyProvider } from '../../../features/Survey/SurveyProvider';
import { SurveyCms as SCMS } from '@pp-labs/survey';
import { Text } from '@pp-labs/ui-components';
import React from 'react';
import strings, { getLocalizedLanguages } from 'Localization/Localizer';
import AudiSpacer from '../../../utils/AudiSpacer';
import { useCMSChannel } from 'utils/hooks';

/** CMS for Surveys */
export const SurveyCMS = () => {
  const selectedChannel = useCMSChannel();
  if (!selectedChannel) return null;
  return (
    <SurveyProvider>
      <Text variant={'order2'} weight={'bold'}>
        {strings.cmsMenuSurvey}
      </Text>
      <AudiSpacer spaceStackEnd={'l'} />{' '}
      <SCMS
        channelId={selectedChannel.channelId}
        languages={getLocalizedLanguages([selectedChannel])}
      />
    </SurveyProvider>
  );
};
