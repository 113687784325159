import axios from 'axios';
import { APIUrl } from '../config';
import { getIdToken } from './Cognito';

/** Client for the admin endpoints used to create and edit events */
const adminClient = axios.create({
  baseURL: `${APIUrl}/config`,
});

adminClient.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${await getIdToken()}`,
  };
  return config;
});

export { adminClient };
