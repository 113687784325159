import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Channel, FAQ, PostFaq } from '../../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedLanguages } from '../../../Localization/Localizer';
import {
  EditDialog,
  LabelButton,
  LocalizedForm,
  tableStyles,
  TextArea,
  TextField,
} from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { atLeastOneLanguage } from 'utils/validator';
import AudiSpacer from '../../../utils/AudiSpacer';

const useStyles = makeStyles({
  ...tableStyles(),
  top: {
    verticalAlign: 'top',
  },
  cancelButton: {
    marginTop: '12px',
    textAlign: 'right',
  },
});

const validation = Yup.object({
  question: atLeastOneLanguage(),
  answer: atLeastOneLanguage(),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

/** interface for FAQEdit props coming from parent component FAQTable */
interface P {
  thisQuestion: FAQ | null;
  close: (refresh?: boolean) => void;
  open: boolean;
  newPosition: number;
  channel: Channel;
}

/**
 * Form for editing FAQs
 */

const FAQEdit = (props: P) => {
  const classes = useStyles();
  const submitToServer = async (v: F) => {
    const data: PostFaq = {
      question: JSON.stringify(v.question),
      answer: JSON.stringify(v.answer),
      channels: [props.channel.channelId],
      position: props.thisQuestion ? props.thisQuestion.position : props.newPosition,
    };
    if (props.thisQuestion) {
      await client.put(`faq/${props.thisQuestion.faqId}`, data);
    } else {
      await client.post('faq', data);
    }
    props.close(true);
  };

  const close = () => {
    props.close();
  };

  const getInitialValues = () => {
    return {
      question: props.thisQuestion?.question ? JSON.parse(props.thisQuestion?.question) : '',
      answer: props.thisQuestion?.answer ? JSON.parse(props.thisQuestion?.answer) : '',
    };
  };

  return (
    <EditDialog
      title={props.thisQuestion ? strings.editQuestion : strings.addQuestion}
      close={close}
      maxWidth="lg"
      cms
    >
      <Formik
        validationSchema={validation}
        initialValues={getInitialValues()}
        onSubmit={(values: F) => {
          submitToServer(values);
        }}
      >
        {({ errors, touched }) => {
          const et = {
            question: touched.question ? errors.question : '',
            answer: touched.answer ? errors.answer : '',
          };
          return (
            <LocalizedForm
              languages={getLocalizedLanguages([props.channel])}
              localizeInputs={['question', 'answer']}
              initialValues={getInitialValues()}
              autoParse={[]}
            >
              <TextField
                name={'question'}
                label={strings.question}
                error={touched.question ? errors.question : ''}
              />
              <AudiSpacer spaceStackEnd={'m'} />
              <TextArea name="answer" label={strings.description} error={et.answer} />
              <div className={classes.cancelButton}>
                <LabelButton variant="secondary" className={classes.btnLeft} onClick={close}>
                  {strings.cancel}
                </LabelButton>
                <LabelButton variant="primary" type="submit" className={classes.btnRight}>
                  {strings.save}
                </LabelButton>
              </div>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};

export default FAQEdit;
