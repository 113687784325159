import { LayoutItem, Text, PollClearIcon } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import { ABC, ABCVote } from './utils/ControlBar/ABCVote';
import { ABCTotal, ThumbsTotal } from './utils/interactionTypes';
import { Thumbs, ThumbsVote } from './utils/ControlBar/ThumbsVote';
import { IconButton } from '@material-ui/core';
import strings from '../../../Localization/Localizer';
import { useLotState } from './Provider/LotProvider';

const useStyles = makeStyles((theme) => ({
  ...blackStyle(theme),
  allWhite: {
    color: 'white',
  },
  clear: {
    padding: 0,
    color: 'black',
    marginLeft: 16,
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
}));

/** interface for Poll props coming from parent component SideBar  */
interface P {
  toggleAbc: (abc: ABC) => void;
  uiAbc: ABC;
  abc: ABCTotal;
  toggleThumbs: (abc: Thumbs) => void;
  uiThumbs: Thumbs;
  thumbs: ThumbsTotal;
  maxParts: number;
  sendClearCommand: (what: string) => void;
  maxWidth: number;
}

/** Polls for user interaction */
export const Poll = (props: P) => {
  const cls = useStyles();
  const lotState = useLotState();
  const commonProps = {
    maxParts: props.maxParts,
    inactive: lotState.users.iAmTrainer,
    maxWidth: props.maxWidth - 144,
  };

  return (
    <div className={cls.allWhite}>
      <LayoutItem spaceStackStart={'s'} spaceStackEnd={'xl'}>
        <Text variant="order4" weight="bold">
          {strings.voteNow}
        </Text>
      </LayoutItem>
      <LayoutItem spaceStackEnd={'s'}>
        <div className={cls.headlineWrapper}>
          <Text as="span" weight="bold" variant="copy2">
            {strings.likes}/{strings.dislikes}
          </Text>
          {lotState.users.iAmTrainer && (
            <IconButton onClick={() => props.sendClearCommand('thumbs')} className={cls.clear}>
              <PollClearIcon />
            </IconButton>
          )}
        </div>
      </LayoutItem>
      <ThumbsVote
        toggleThumb={props.toggleThumbs}
        uiThumbs={props.uiThumbs}
        thumbsTotal={props.thumbs}
        {...commonProps}
      />

      <LayoutItem spaceStackStart={'xl'} spaceStackEnd={'s'}>
        <div className={cls.headlineWrapper}>
          <Text as="span" weight="bold" variant="copy2">
            {strings.multipleChoice}
          </Text>
          {lotState.users.iAmTrainer && (
            <IconButton onClick={() => props.sendClearCommand('ABC')} className={cls.clear}>
              <PollClearIcon />
            </IconButton>
          )}
        </div>
      </LayoutItem>
      <ABCVote toggleABC={props.toggleAbc} uiAbc={props.uiAbc} abc={props.abc} {...commonProps} />
    </div>
  );
};
