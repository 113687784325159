import React, { useEffect, useState } from 'react';
import { IconButton, Table, TableBody, TableContainer } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { appendZero, getDisplayDate } from '../../../utils/convert';
import {
  EditLevel,
  Session,
  SessionType,
  UserPicture,
  TimeSlot,
  ChannelTopic,
} from '../../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import DeleteDialogue from '../../../utils/ConfirmDialogue/DeleteDialogue';
import {
  EditIcon,
  EraseIcon,
  LabelButton,
  tableStyles,
  TableCell,
  TableRow,
  DraggableComponent,
  DroppableComponent,
  handleDrop,
  KeyIcon,
  SessionTableCell,
} from '@pp-labs/ui-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { client } from '../../../ApiHandler/client';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigateEventNewTab, useNotify } from '../../../utils/hooks';
import { byPosition } from '../../../utils/sort';
import StreamAccessDialog from 'utils/StreamAccessDialog';
import { format } from 'date-fns';
import { NotCreatedPlaceholder } from './NotCreatedPlaceholder';
import { TableArrows } from 'DCLX/utils/TableArrows';
import { standardFormat } from 'config';
import { LinkIcon, StreamIcon } from 'icons';
import { SessionSelection } from './SessionSelection';
import ConfirmDialogue from 'utils/ConfirmDialogue/ConfirmDialogue';

/** interface for SessionTable props coming from parent component DCLXEvents */
interface P {
  channelId: number;
  sessions: Session[];
  trainings: TimeSlot[];
  edit: (sessionId: number, topic: ChannelTopic | null) => void;
  level: EditLevel;
  createSession: (topic: ChannelTopic | null, type: SessionType, stream: boolean) => void;
  topic: ChannelTopic | null;
  events?: boolean;
  refresh: Function;
  trainers: Array<UserPicture>;
}

const useStyles = makeStyles({
  ...tableStyles(),
  topicTitle: {
    padding: '30px',
    background: '#e5e5e5',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '12px',
  },
  cancelButton: {
    textAlign: 'right',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rowBackground: {
    background: '#f2f2f2',
  },
});

/**
 * Component for listing Sessions
 */
const SessionTable = (props: P) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [toBeDeleted, setToBeDeleted] = useState<Session | null>(null);
  const [addSession, setAddSession] = useState<boolean>(false);
  const [showStreamInfo, setShowStreamInfo] = useState<Session | null>(null);
  const [hideSession, setHideSession] = useState<boolean>(false);

  const [changeStream, setChangeStream] = useState<Session | undefined>(undefined);

  const notify = useNotify();
  const navigate = useNavigateEventNewTab();

  useEffect(() => {
    // For some reason props.sessions changes regulary and thereby resets the local session order.
    // We need to avoid this.
    const change =
      props.sessions.length !== sessions.length ||
      props.sessions.some((s) => {
        const inHere = sessions.find((i) => s.sessionId === i.sessionId);
        if (!inHere) return true;
        return JSON.stringify(inHere) !== JSON.stringify(s);
      });
    if (change) setSessions(props.sessions.sort(byPosition));
  }, [props.sessions, sessions]);

  const cls = useStyles();

  const getSessionURL = (session: Session) => {
    switch (session.sessionType) {
      case SessionType.TRAINING:
        return null;
      case SessionType.DEMAND:
        return `/onDemand/${session.sessionId}`;
      case SessionType.LIVE:
        return `/live/${session.sessionId}`;
      case SessionType.LIVETERMINATED:
        return `/live/${session.sessionId}`;
      default:
        return null;
    }
  };

  const handleDelete = (session: Session) => {
    setToBeDeleted(session);
  };

  const finishDelete = async () => {
    await client.delete(`sessions/${toBeDeleted?.sessionId}`);
    setToBeDeleted(null);
    props.refresh();
  };

  const abortDelete = () => {
    setToBeDeleted(null);
  };

  const handleEdit = (session: Session) => props.edit(session.sessionId, props.topic);

  const onDragEnd = (result: any) => {
    const newSessions = handleDrop(result, sessions);
    if (newSessions) setSessions(newSessions);
  };

  const getStatus = (session: Session) => {
    if (session.adapted) {
      return (
        <div className={cls.checkIconWrapper}>
          <Text as="span" variant="copy1">
            {strings.completed}
          </Text>
          <CheckCircleIcon fontSize={'small'} />
        </div>
      );
    } else {
      return (
        <Text as="span" variant="copy1">
          {strings.inComplete}
        </Text>
      );
    }
  };

  const confirmOrder = async () => {
    if (!sessions) return;
    await client.post(
      `sessions/order`,
      sessions.map((s) => s.sessionId)
    );
    notify(strings.orderSuccessfullyChanged, 'success');
  };

  const createSession = (t: SessionType, stream?: boolean) => {
    props.createSession(props.topic, t, !!stream);
  };

  const getButtons = () => {
    return (
      <div>
        <LabelButton
          variant="primary"
          onClick={() => setAddSession(true)}
          id="addSession"
          spaceInlineEnd="m"
        >
          {strings.addSession}
        </LabelButton>
        <LabelButton variant="secondary" className={cls.btnRight} onClick={confirmOrder}>
          {strings.confirmNewOrder}
        </LabelButton>
      </div>
    );
  };

  const getTrainingDescription = (type: SessionType) => {
    switch (type) {
      case SessionType.DEMAND:
        return strings.onDemandTraining;
      case SessionType.EXTERNAL:
        return strings.external;
      case SessionType.LIVETERMINATED:
        return strings.onDemandTerminated;
      case SessionType.LIVE:
        return strings.sessionTypeEvents;
      case SessionType.TRAINING:
        return strings.lot;
    }
  };

  const timeslot = (timeslotObject: TimeSlot) => {
    const duration = (timeslotObject.endAt - timeslotObject.startAt) / 60;
    const trainer = props.trainers.find((t) => t.username === timeslotObject.trainerName);
    return (
      <TableRow key={`timeslot-${timeslotObject.trainingId}`}>
        <TableCell noLimitWidth>
          <div>
            <div style={{ display: 'inline-block' }}>
              <Text as="p" variant="copy1">
                {getDisplayDate(timeslotObject.startAt, true)}
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.duration}: {duration} {strings.minutesShort}
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.numberParts}: {appendZero(timeslotObject.bookings || 0)}/
                {appendZero(timeslotObject.maxParticipants || 0)}
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.trainer}: {trainer?.givenName} {trainer?.familyName}
              </Text>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      {changeStream && (
        <ConfirmDialogue
          title={'Change stream'}
          message={`Are you sure you want to create a new stream channel for "${getLocalizedValue(
            changeStream?.title
          )}"? This cannot be undone.`}
          confirm={async () => {
            await client.put(`sessions/${changeStream.sessionId}/livestream`);
            setChangeStream(undefined);
            notify('Created new stream channel', 'success');
          }}
          reject={() => setChangeStream(undefined)}
        />
      )}

      {!!props.topic ? (
        <div
          className={cls.topicTitle}
          style={{ justifyContent: 'flex-start' }}
          onClick={() => setHideSession(!hideSession)}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: 'calc(50% + 152px)' }}>
            <IconButton style={{ color: 'black' }} disabled>
              {<TableArrows open={!hideSession} />}
            </IconButton>
            <Text variant="copy1" weight={'bold'}>
              {getLocalizedValue(props.topic?.title)}
            </Text>
          </div>
          <div style={{ display: 'flex', width: '28%', justifyContent: 'space-between' }}>
            <div style={{ marginRight: '20%' }}>
              <Text variant="copy1">{strings.startsAt}</Text>
              <Text variant="copy1" weight={'bold'}>
                {format(props.topic?.startAt * 1000, standardFormat)}
              </Text>
            </div>
            <div>
              <Text variant="copy1">{strings.endsAt}</Text>
              <Text variant="copy1" weight={'bold'}>
                {format(props.topic?.endAt * 1000, standardFormat)}
              </Text>
            </div>
          </div>
        </div>
      ) : (
        <div className={cls.topicTitle} onClick={() => setHideSession(!hideSession)}>
          <IconButton style={{ color: 'black' }} disabled>
            {<TableArrows open={!hideSession} />}
          </IconButton>
          <Text variant="copy1" weight={'bold'}>
            {strings.topicIndependent}
          </Text>
        </div>
      )}
      {addSession && (
        <SessionSelection createSession={createSession} close={() => setAddSession(false)} />
      )}
      {!hideSession && (
        <>
          {!!sessions.length ? (
            <TableContainer style={{ marginTop: '4px' }} className={'sessionTable'}>
              <Table className={cls.table} aria-label="simple table">
                <TableBody component={DroppableComponent(onDragEnd)}>
                  {sessions.map((session, index) => {
                    const status = getStatus(session);
                    const trainings = props.trainings.filter(
                      (t) => t.sessionId === session.sessionId
                    );
                    return (
                      <TableRow
                        id="session-row"
                        component={DraggableComponent(session.sessionId.toString(), index)}
                        key={session.sessionId}
                        className={cls.rowBackground}
                      >
                        <SessionTableCell image>
                          <img
                            style={{ height: '100%', width: '140px', display: 'block' }}
                            src={session.thumbnail?.url}
                            alt={getLocalizedValue(session.title)}
                          />
                        </SessionTableCell>
                        <SessionTableCell title>
                          <div className={cls.titleContainer}>
                            <Text as="p" variant="copy1" weight="bold">
                              {getLocalizedValue(session.title)}
                            </Text>
                            <div>
                              {!!trainings.length && (
                                <TableContainer>
                                  <Table className={cls.table}>
                                    <TableBody>
                                      {trainings.map((tp) => {
                                        return timeslot(tp);
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                              {session.sessionType === SessionType.LIVE && (
                                <IconButton
                                  style={{ color: 'black' }}
                                  onClick={() => {
                                    setShowStreamInfo(session);
                                  }}
                                >
                                  <KeyIcon />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        </SessionTableCell>
                        <SessionTableCell>
                          <Text as="p" variant="copy3">
                            {strings.sessionType}
                          </Text>
                          <Text as="p" variant="copy1">
                            {getTrainingDescription(session.sessionType)}
                          </Text>
                        </SessionTableCell>
                        <SessionTableCell>
                          <Text as="p" variant="copy3">
                            {strings.adaption}
                          </Text>
                          <Text as="p" variant="copy1">
                            {status}
                          </Text>
                        </SessionTableCell>
                        <SessionTableCell controls>
                          {getSessionURL(session) && (
                            <IconButton
                              onClick={() => {
                                navigate(getSessionURL(session) || '');
                              }}
                            >
                              <LinkIcon />
                            </IconButton>
                          )}
                          {session.sessionType === SessionType.LIVE && (
                            <IconButton
                              onClick={() => {
                                setChangeStream(session);
                              }}
                              style={{ color: 'black' }}
                            >
                              <StreamIcon />
                            </IconButton>
                          )}

                          <IconButton
                            style={{ color: 'black' }}
                            onClick={() => {
                              handleEdit(session);
                            }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            style={{ color: 'black' }}
                            id="delete-session"
                            onClick={() => {
                              handleDelete(session);
                            }}
                          >
                            <EraseIcon />
                          </IconButton>
                        </SessionTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NotCreatedPlaceholder text={strings.noSessions} />
          )}
          <div style={{ padding: '20px 0 60px 0' }}>{getButtons()}</div>
        </>
      )}
      <DeleteDialogue
        toBeDeleted={toBeDeleted?.sessionId || null}
        delete={finishDelete}
        reject={abortDelete}
        toDeleteLabel={toBeDeleted ? getLocalizedValue(toBeDeleted.title) : ''}
      />
      <StreamAccessDialog
        onClose={() => setShowStreamInfo(null)}
        open={showStreamInfo !== null}
        streamServers={showStreamInfo?.streamServers}
      />
    </div>
  );
};

export { SessionTable };
