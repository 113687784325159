import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useFormikContext } from 'formik';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  errorText: {
    color: '#f50537',
  },
});
type EditorProps = {
  formikName: string;
  label: string;
  selectedLanguage?: number;
};

type FormikValues<Name extends string> = {
  [key in Name]: string;
};

// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html

/** Editor Component, designed to be used with formik.
 * Gets passed a formikName and label and that's it, selectedLanguage is a optional props which is passed when you want to use
 * Editor with localized form. selectedLanguage have the index value of the language you want to use.f for example 1 for english and 2 for German.
 * If you use localized form you need to add a hidden input, with name equal to the formikName, to the LocalizedForm which will store the different values for the languages.
 * This is due to the DecoupledEditor not being able to be cloned by the LocalizedForm.
 *  No need to pass errors or something! */
export const Editor = (props: EditorProps) => {
  const cls = useStyles();
  const formik = useFormikContext<FormikValues<any>>();
  const [updateLocalizedField, setUpdateLocalizedField] = useState<string | undefined>(undefined);

  const error = formik.touched[props.formikName] ? formik.errors[props.formikName] : '';

  useEffect(() => {
    if (updateLocalizedField !== undefined) {
      formik.setFieldValue(
        `${props.formikName}?lang:${props.selectedLanguage}`,
        updateLocalizedField
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLocalizedField]);

  return (
    <div>
      <Text>{props.label}</Text>
      <LayoutItem spaceStackStart="l" spaceStackEnd="s" className="editorWrapper">
        <CKEditor
          id={`${props.formikName}-editor`}
          editor={DecoupledEditor}
          data={
            props.selectedLanguage !== undefined
              ? formik.values[`${props.formikName}?lang:${props.selectedLanguage}`]
              : formik.values[props.formikName]
          }
          onReady={(editor: any) => {
            editor?.ui?.getEditableElement()?.parentElement?.append(editor.ui.view.toolbar.element);
          }}
          onChange={(_: unknown, editor: any) => {
            const data: string = editor.getData();
            try {
              if (props.selectedLanguage !== undefined) {
                setUpdateLocalizedField(data);
              } else {
                formik.setFieldValue(props.formikName, data);
              }
            } catch (e) {}
          }}
          config={{
            toolbar: [
              'heading',
              'bold',
              'underline',
              'link',
              'numberedList',
              'bulletedList',
              'indent',
              'outdent',
              'insertTable',
              'tableColumn',
              'tableRow',
            ],
          }}
        />
        {!!error && (
          <Text className={cls.errorText} variant="copy2">
            {error}
          </Text>
        )}
      </LayoutItem>
    </div>
  );
};
