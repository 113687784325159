import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  confirmPasswordValidator,
  newPasswordValidator,
  usernameValidator,
} from '../utils/validator';
import { TextField } from '@pp-labs/ui-components';
import strings from '../Localization/Localizer';
import { CustomError, FormSoftButton } from '../SoftButton';
import { useEventSettings, useNotify } from '../utils/hooks';
import { getFormErrorMessage } from './utils';
import { PasswordVerifier } from './PasswordVerifier';
import { getValidationType } from '../utils/convert';

const S1Validation = () =>
  Yup.object({
    username: usernameValidator(),
  }).required(strings.required);

const S2Validation = () =>
  Yup.object({
    newPassword: newPasswordValidator(),
    confirmPassword: confirmPasswordValidator(),
    code: Yup.string().required(strings.required),
  }).required(strings.required);

type RecoverPasswordData = Yup.InferType<ReturnType<typeof S2Validation>>;

/** interface for RecoverPassword props coming from parent component Login */
interface BaseProps {
  username: string;
  requestCode: (username: string, sms: boolean) => Promise<boolean>;
}

interface P extends BaseProps {
  recover: (v: RecoverPasswordData, username: string, sms: boolean) => void;
}

type CodeMethod = null | 'mail' | 'sms';

interface S1Props extends BaseProps {
  setCodeMethod: (method: CodeMethod) => void;
  setUsername: (username: string) => void;
}

/** Request the necessary email to recover a password. */
const S1 = (props: S1Props) => {
  const notify = useNotify();
  const event = useEventSettings()!;
  const validationTypes = getValidationType(event);
  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  const [sendCode, setSendCode] = useState<CodeMethod>(null);

  const requestCode = async (username: string, code: 'mail' | 'sms') => {
    const success = await props.requestCode(username, code === 'sms');
    if (success) {
      props.setUsername(username);
      setSendCode(code);
    }
  };
  const initialValues = { username: props.username };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={S1Validation()}
      onSubmit={async (values) => {
        if (sendCode) {
          await requestCode(values.username, sendCode);
          props.setCodeMethod(sendCode);
        }
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div>
            <div style={{ maxWidth: 400, marginBottom: 64 }}>
              <TextField
                name="username"
                label={strings.username}
                type="text"
                error={touched.username ? errors.username : ''}
                autoComplete="username"
              />
            </div>
            {validationTypes.includes('email') && (
              <div>
                <FormSoftButton
                  relevantFields={['username']}
                  errorCallback={handleError}
                  variant="primary"
                  additionalSuccessCallback={() => setSendCode('mail')}
                >
                  {strings.recoverPswMail}
                </FormSoftButton>
              </div>
            )}
            {validationTypes.includes('sms') && (
              <div>
                <FormSoftButton
                  relevantFields={['username']}
                  errorCallback={handleError}
                  variant="primary"
                  additionalSuccessCallback={() => setSendCode('sms')}
                >
                  {strings.recoverPswSms}
                </FormSoftButton>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

/** Form to recover a lost password */
const RecoverPassword = (props: P) => {
  const notify = useNotify();
  const [sendCode, setSendCode] = useState<CodeMethod>(null);
  const [username, setUsername] = useState<string>(props.username);

  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  const initialValues: RecoverPasswordData = {
    newPassword: '',
    confirmPassword: '',
    code: '',
  };

  if (!sendCode) {
    return (
      <S1
        username={username}
        requestCode={props.requestCode}
        setCodeMethod={setSendCode}
        setUsername={setUsername}
      />
    );
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={S2Validation()}
        onSubmit={(values: RecoverPasswordData) => {
          props.recover(values, username, sendCode === 'sms');
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div style={{ marginBottom: 40 }}>
              <TextField
                name="code"
                label={strings.passwordResetCode}
                type="text"
                error={touched.code ? errors.code : ''}
                autoComplete="one-time-code"
              />
            </div>
            <PasswordVerifier password={values.newPassword} />
            <TextField
              name="newPassword"
              label={strings.newPassword}
              type="password"
              error={touched.newPassword ? errors.newPassword : ''}
              autoComplete="new-password"
            />
            <TextField
              name="confirmPassword"
              label={strings.confirmPassword}
              type="password"
              error={touched.confirmPassword ? errors.confirmPassword : ''}
              autoComplete="new-password"
            />
            <div style={{ marginTop: 28 }}>
              <FormSoftButton
                relevantFields={['code', 'newPassword', 'confirmPassword']}
                errorCallback={handleError}
                variant="primary"
              >
                {strings.changePassword}
              </FormSoftButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { RecoverPassword };
export type { RecoverPasswordData };
