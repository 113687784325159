import { LabelButton } from '@pp-labs/ui-components';
import React, { useState } from 'react';
import { useEventSettings } from '../../utils/hooks';
import { useHistory } from 'react-router-dom';
import strings from 'Localization/Localizer';

/** Button to initialize login via IDP */
export const IDPLoginButton = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const eventSettings = useEventSettings();
  const history = useHistory();

  const handleClick = async () => {
    if (!eventSettings) return;
    setLoading(true);
    history.push('ssologin');
  };
  return (
    <>
      <LabelButton variant="primary" onClick={handleClick} disabled={loading}>
        {loading ? 'Anmeldung läuft...' : strings.proceedNext}
      </LabelButton>
    </>
  );
};
