/** Example mail template csv */
export const ExampleTemplate =
  ';German;French;Dutch;English;Finish;Polish;Spanish;Swedish;Italian;Danish;Ukrainian;SASpanish;Turkish;Hungarian;Russian;Bulgarian;Czech;Greek;Portuguese;Romanian;Serbian;Slovakian;Slovenian\n' +
  'Subject;Audi Central Launch eXperience 2021 – Ihre Veranstaltung;Audi Central eXperience 2021 - Votre événement !;Audi Central Launch eXperience 2021 – Uw evenement;Audi Central Launch eXperience 2021 – Your Event;;Audi Central Launch eXperience 2021 - Twoje Wydarzenie;Audi Central Launch eXperience 2021 – Su evento;Kära deltagare;Audi Central Launch eXperience 2021 – Il tuo evento;Audi CLX 2021 - din event;CLX 2021 - подія;Audi Central Launch eXperience 2021 – Tu Evento;;Audi Central Launch eXperience – rendezvényed;Audi Central Launch eXperience – Ваше мероприятие;Audi Central Launch eXperience – Вашето събитие;Audi Central Launch eXperience – Vaše událost;Audi Central Launch eXperience –Το Event σας;Audi Central Launch eXperience – o seu evento;Audi Central Launch eXperience – Your Event;;Audi Central Launch eXperience – Tvoj tréning;Audi Central Launch eXperience – vaš dogodek\n' +
  'LOTCanceled;"Sehr geehrte_r Teilnehmer_in,\n' +
  'Ihr Live Online Training zum Thema [TITLE], gebucht für den [DATE] um [TIME] Uhr wurde abgesagt. Wir bitten Sie daher über die Trainingsplattform des CLX 2021 einen neuen Termin zu buchen.\n' +
  'Vielen Dank!\n' +
  'Ihr CLX Team";"Cher participant,\n' +
  'Votre ""Live Formateur"" sur  [TITLE] que vous avez réservée pour [DATE] à [TIME] a été annulé. Nous vous demandons de bien vouloir aller sur la plateforme du CLX 2021 pour effectuer une nouvelle réservation. S\'il n\'y a pas d\'autres dates disponibles pour cette formation en ligne en direct, veuillez aller sur la plateforme ultérieurement car elle est mise à jour régulièrement.\n' +
  'Cordialement,\n' +
  'Votre équipe du CLX ";"Beste deelnemer,\n' +
  'Je Live Online Training over [TITLE] die je hebt geboekt voor [DATE] op [TIME] is geannuleerd. We vragen u vriendelijk om het CLX 2021 platform opnieuw te bezoeken om een nieuwe boeking te maken. Als er geen alternatieve data beschikbaar zijn voor deze Live Online Training, ga dan in een later stadium opnieuw naar het platform, omdat ze zo snel mogelijk worden bijgewerkt.\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  'Your Live Online Training on [TITLE] which you booked for [DATE] at [TIME] has been cancelled. We kindly ask you to visit the CLX 2021 platform again to make a new booking. If there are no alternative dates available for this Live Online Training, please visit the platform again at a later stage as they are being updated as soon as possible.\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  'Live Online Training -moduulisi [TITLE], johon teit varauksen [DATE] kello [TIME] on peruutettu. Pyydämme sinua ystävällisesti käymään Audi CLX 2021 -alustalla, jotta voit tehdä uuden varauksen. Jos kyseiselle Live Online Training -moduulille ei ole vaihtoehtoisia ajankohtia jäljellä, ole hyvä ja tarkista tilanne CLX-alustalta myöhemmin uudelleen, sillä tiedot päivitetään mahdollisimman pian.\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Drogi uczestniku,\n' +
  'Twoje szkolenie online na żywo na [TITLE], które zarezerwowałeś na [DATE] o [TIME] zostało anulowane. Uprzejmie prosimy o ponowne odwiedzenie platformy CLX 2021 w celu dokonania nowej rezerwacji. Jeśli nie ma dostępnych alternatywnych dat dla tego szkolenia online na żywo, odwiedź platformę ponownie później, ponieważ jest ona aktualizowana tak szybko, jak to możliwe.\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"\n' +
  'Querido Participante,\n' +
  'La formación en directo sobre [TITLE] que reservaste para [DATE] a las [TIME] ha sido cancelada. Te rogamos que vuelvas a visitar la plataforma CLX 2021 para realizar una nueva reserva. Si no hay fechas alternativas disponibles para esta formación en directo, visita la plataforma más adelante, ya que las fechas se irán actualizando lo antes posible.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX ";"Bästa deltagare,\n' +
  'Din Live Online Training om [TITLE] som du bokade för [DATE] klockan [TIME] har ställts in. Vi ber dig att besöka CLX 2021-plattformen igen för att göra en ny bokning. Om det inte finns några alternativa datum tillgängliga för denna Live Online Training, besök plattformen igen vid ett senare tillfälle eftersom de uppdateras så snart som möjligt.\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team """;"Buongiorno,\n' +
  'il Live Online Training [TITLE] per cui è prevista la tua presenza il [DATE] alle ore [Time] è stato cancellato. Ti preghiamo di visitare la piattaforma CLX 2021 e di effettuare una nuova iscrizione per partecipare. Se non sono ancora presenti date alternative per questo Live Online Training, ti preghiamo di riprovare in un secondo momento, stiamo provvedendo ad aggiornare le date e orari.\n' +
  'Cordiali Saluti,\n' +
  'il team CLX";Kære deltager. Den bookede træning er desværre aflyst og vi beder dig besøge sitet og booke en ny tid. De bedste hilsener CLX teamet;"Шановний учаснику,\n' +
  'Ваш Live Online Тренінг [TITLE] , який Ви забронювали на [DATE] о [TIME], скасовано. Просимо Вас ще раз зайти на патформу CLX 2021 і зробити нове бронювання. Якщо альтернативні дати для даного Live Online Тренінгу недоступні, будь ласка спробуйти увійти на платформу пізніше, дані оновляться найближчим часом.\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"\n' +
  'Querido Participante,\n' +
  'La formación en directo sobre [TITLE] que reservaste para [DATE] a las [TIME] ha sido cancelada. Te solicitamos que vuelvas a visitar la plataforma CLX 2021 para realizar una nueva reserva. Si no hay fechas alternativas disponibles para esta formación en directo, visita la plataforma más adelante, ya que las fechas se irán actualizando lo antes posible.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX ";"Sayın Katılımcı,\n' +
  'xx.xx.xxxx (tarih) tarihi ve xx.xx (zaman) saati için rezervasyon yaptığınız xxx (konu) ile ilgili Canlı Çevrimiçi Eğitim iptal edilmiştir. Yeni bir rezervasyon yapmak için CLX 2021 platformunu tekrar ziyaret etmenizi rica ediyoruz. Bu Canlı Çevrimiçi Eğitim için herhangi bir alternatif tarih mevcut değilse, mümkün olan en kısa sürede güncellendiğinden platformu daha sonraki aşamalarda lütfen tekrar ziyaret edin.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves résztvevő,\n' +
  'A [DATE], [TIME] időpontra lefoglalt [TITLE] témájú élő online képzésed törlésre került.Kérjük, látogasd meg újra a CLX platformot és foglalj le egy új időpontot. Ha nincs alternatív időpont erre az élő online képzésre, kérjük látogasson el később a platformra, mivel frissíteni fogjuk amint lehetséges.\n' +
  'Üdvözlettel,\n' +
  'A CLX csapatod";"Уважаемый участник!\n' +
  'Ваш онлайн-тренинг в режиме реального времени по теме [TITLE], который должен был состояться [DATE] в [TIME], отменён. Просим вас зайти на платформу CLX и ещё раз пройти регистрацию на тренинг. Если для этого онлайн-тренинга в режиме реального времени не предусмотрены альтернативные даты, зайдите на платформу позже, так как доступные даты постоянно обновляются.\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми участници,\n' +
  'Вашият Live Online Training на тема [TITLE], за който се регистрирахте за [DATE] в [TIME] е отменен. Молим ви да посетите CLX платформата, за да направите нова регистрация. Ако не са налични алтернативни дати за този Live Online Training, моля посетете платформата отново по-късно, те ще бъдат обновени максимално скоро.\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'Vaše živé online školení na téma [TITLE], které jste si rezervoval/a na [DATE] v [TIME], bylo zrušen. Žádáme Vás, abyste znovu navštívili platformu CLX a provedli novou rezervaci. Pokud nejsou k dispozici žádné náhradní termíny pro toto živé online školení, navštivte prosím platformu znovu později, protože termíny budou co nejdříve aktualizovány.\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα,\n' +
  'Το δικό σας  Live Online Training με θέμα [TITLE] που έχετε κάνει κράτηση για την [DATE] στις [TIME] έχει ακυρωθεί. Σε παρακαλούμε όπως επισκεφθείτε ξανά την πλατφόρμα CLX platform για να κάνετε μια νέα κράτηση. Αν δεν υπάρχουν διαθέσιμες εναλλακτικές ημερομηνίες για αυτό το Live Online Training, παρακαλούμε όπως επισκεφθείτε ξανά αργότερα την πλατφόρμα όταν θα έχουν γίνει update το συντομότερο δυνατό.\n' +
  'Με φιλικους χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX";"Caro participante,\n' +
  'Seu Live Online Training sobre [TITLE] que você reservou para [DATE] às [TIME] foi cancelado. Pedimos a gentileza de visitar a plataforma CLX novamente para fazer uma nova reserva. Se não houver datas alternativas disponíveis para este Live Online Training, visite a plataforma novamente em um estágio posterior, pois eles serão atualizados o mais rápido possível.\n' +
  'Atenciosamente,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Trainingul live online [TITLE] pe care l-ati rezervat pentru data de [DATE] la ora [TIME] a fost anulat. Va rugam sa vizitati din nou platforma CLX pentru o noua rezervare. In cazul in care nu exista datele alternative pentru acest training online live, va rugam sa accesati platforma in perioada imediat urmatoare pentru update-uri.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Poštovani,\n' +
  'Vaš Live Online Training na [TITLE] koji ste rezervisali za [DATE] u [TIME] je otkazan. Ljubazno vas molimo da ponovo posetite CLX platformu kako biste napravili novu rezervaciju. Ako nema dostupnih alternativnih datuma za ovaj Live Online Training, ponovo posetite platformu u kasnijoj fazi dok se ažuriraju što je pre moguće.\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý účastník,\n' +
  'váš Live Online Training na [TITLE] na ktorý ste sa prihlásili [DATE] o [TIME], bol zrušený. Prosíme vás znovu navštíviť platformu CLX a prihlásiť sa znovu. Ak na nej momentálne nie sú vhodné alternatívne termíny pre tento Live Online Training, prosím navštívte platformu neskôr znova, keďže termíny na nej sú updatované.\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Spoštovani udeleženec,\n' +
  'Vaše spletno usposabljanje v živo na [TITLE], ki ste ga rezervirali za [DATE] ob [TIME], je bilo preklicano. Vljudno vas prosimo, da ponovno obiščete platformo CLX in opravite novo rezervacijo. Če za to spletno usposabljanje v živo ni na voljo nobenih alternativnih datumov, vas prosimo, da pozneje znova obiščite platformo, saj se sproti posodablja.\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTMoved;"Sehr geehrte_r Teilnehmer_in,\n' +
  'Ihr Live Online Training zum Thema [TITLE], gebucht für den [OLD_DATE] um [OLD_TIME] Uhr wurde auf den [NEW_DATE] um [NEW_TIME] Uhr verschoben. Wenn das neue Datum inkl. Uhrzeit für Sie passt müssen Sie nichts unternehmen. Sollte das nicht der Fall sein, gehen Sie bitte auf die CLX Trainingsplattform und wählen Sie einen neuen Termin aus.\n' +
  'Wir freuen uns, Sie bei dem Live Online Training zu sehen!\n' +
  'Viele Grüße,\n' +
  'Ihr CLX Team";"Cher participant,\n' +
  'Votre ""Live Formateur"" sur [TITLE] que vous avez réservée pour le [OLD_DATE] à [OLD_TIME] a été déplacée vers [NEW_DATE] à [NEW_TIME]. Votre réservation a été automatiquement déplacée à la nouvelle date. Si vous êtes satisfait de la nouvelle date, vous n\'avez rien à faire. Assurez-vous simplement d\'enregistrer la date dans votre agenda. Si la nouvelle date ne vous convient pas, veuillez vous connecter à la plateforme, annuler la réservation et choisir une autre session de ""Live Formateur"".\n' +
  'Nous avons hâte de vous voir bientôt!\n' +
  'Cordialement,\n' +
  'Votre équipe du CLX ";"Beste deelnemer,\n' +
  'Je Live Online Training over  [TITLE] die je hebt geboekt voor [OLD_DATE] op [NEW_TIME] is verplaatst naar op [NEW_DATE] op [NEW_TIME]. Je boeking is automatisch verplaatst naar de nieuwe datum. Als je tevreden bent met de nieuwe datum, hoef je niets te doen. Zorg ervoor dat je de datum opslaat in je agenda. Als de nieuwe datum niet bij je past, log dan in op het platform, annuleer de boeking en kies een andere Live Online Training sessie.\n' +
  'We kijken ernaar uit om je binnenkort te zien!\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  'Your Live Online Training on [TITLE] which you booked for [OLD_DATE] at [OLD_TIME] has been moved to [NEW_DATE] at [NEW_TIME]. Your booking has automatically been moved to the new date. If you are happy with the new date, there is nothing for you to do. Just make sure you save the date in your calendar. If the new date does not suit you, please log on to the platform, cancel the booking and choose a different Live Online Training session.\n' +
  'We look forward to seeing you soon!\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  'Live Online Training -moduulisi [TITLE], johon teit varauksen [OLD_DATE] kello [OLD_TIME] on siirretty alkamaan [NEW_DATE] kello [NEW_TIME]. Varauksesi on automaattisesti siirretty kyseiselle uudelle ajankohdalle. Mikäli uusi ajankohta sopii sinulle, sinun ei tarvitse tehdä mitään. Muista vain merkitä ajankohta kalenteriisi. Jos uusi ajankohta ei sopi sinulle, ole hyvä kirjaudu Audi CLX -alustalle, peruuta varaus ja valitse toinen ajankohta kyseiselle Live Online Training -moduulille.\n' +
  'Nähdään pian!\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Drogi uczestniku,\n' +
  'Twoje szkolenie online na żywo na [TITLE], które zarezerwowałeś na [OLD_DATE] o [OLD_TIME] zostało przeniesione na [NEW_DATE] o [NEW_TIME]. Twoja rezerwacja została automatycznie przeniesiona na nową datę. Jeśli nowy termin Ci odpowiada, nie musisz nic robić. Po prostu upewnij się, że zapiszesz datę w kalendarzu. Jeśli nowa data Ci nie odpowiada, zaloguj się na platformę, anuluj rezerwację i wybierz inną sesję treningową online na żywo.\n' +
  'Do zobaczenia wkrótce!\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"Querido Participante,\n' +
  'La formación en directo sobre [TITLE] que reservaste para [OLD_DATE] a las [OLD_TIME] se ha trasladado a [NEW_DATE] a las [NEW_TIME]. Tu reserva se ha movido automáticamente a la nueva fecha. Si estás satisfecho con la nueva fecha, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Bästa deltagare,\n' +
  'Din Live Online Training om [TITLE] som du bokade för [OLD_DATE] klockan [OLD_TIME] har flyttats till [NEW_DATE] klockan [NEW_TIME]. Din bokning har automatiskt flyttats till det nya datumet. Om du är nöjd med det nya datumet behöver du inte göra något. Se bara till att du sparar datumet i din kalender. Om det nya datumet inte passar dig, logga in på plattformen, avbryt bokningen och välj ett annat Live Online-pass.\n' +
  'Vi ser fram emot att se dig snart!\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team";"Buongiorno,\n' +
  "l'orario o la data del Live Online Training [TITLE] per cui è prevista la tua presenza il [OLD_DATE] alle ore [OLD_TIME] è stato spostato al giorno [NEW_DATE] alle ore [NEW_TIME]. La tua partecipazione è stata variata automaticamente per questo appuntamento. Se sei soddisfatto della nuova data, assicurati solo di prenderne nota. Se la nuova data non ti soddisfa, accedi alla piattaforma, cancella la prenotazione e scegli un'altra sessione di Live Online Training.\n" +
  'Ti aspettiamo!\n' +
  'Il team CLX";Kære deltager. Din bookede træning er flyttet og du er automatisk flyttet til det nye tidspunkt. Hvis det ikke passer dig, så besøg sitet og foretag en ny booking. De bedste hilsener CLX teamet;"Шановний учаснику,\n' +
  "Ваш Live Online Тренінг [TITLE], який Ви забронювали на [OLD_DATE]  о [OLD_TIME], перенесно на  [NEW_DATE] о [NEW_TIME]. Ваше бронювання автоматично перенесено на нову дату. Якщо нова дата Вас влаштовує, Вам нічого робити не потрібно. Тільки запам'ятайте та збережіть нову дату у календарі. Якщо нова дата Вам не підходить, будь ласка, зайдіть на платформу, скасуйте автоматичне бронювання та оберіть інший слот Live Online Тренінгу.\n" +
  'Чекаємо на зустріч з Вами!\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"Querido Participante,\n' +
  'La formación en directo sobre [TITLE] que reservaste para [OLD_DATE] a las [OLD_TIME] se ha trasladado a [NEW_DATE] a las [TIME]. Tu reserva se ha movido automáticamente a la nueva fecha. Si estás satisfecho con la nueva fecha, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Sayın Katılımcı,\n' +
  'xx.xx.xxxx (tarih) tarihi ve xx.xx (zaman) saati için rezervasyon yaptığınız xxx (konu) ile ilgili Canlı Çevrimiçi Eğitim tarihi xx.xx.xxxx ve saati xx.xx olarak değiştirilmiştir. (zaman). Rezervasyonunuz, otomatik olarak yeni tarihle değiştirilmiştir. Yeni tarih sizin için uygunsa, yapacağınız başka bir şey yoktur.  Sadece yeni tarihi takviminize kaydettiğinizden emin olun. Yeni tarih sizin için uygun değilse, lütfen platforma giriş yapın, rezervasyonu iptal edin ve farklı bir Canlı Çevrimiçi Eğitim oturumu seçin.\n' +
  'Sizinle tanışmayı sabırsızlıkla bekliyoruz.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves résztvevő,\n' +
  'A [OLD_DATE], [OLD_TIME] időpontra lefoglalt [TITLE] témájú élő online képzésed [NEW_DATE], [NEW_TIME] időpontra módosult. A foglalásod automatikusan módosult az új időpontra.Ha megfelel az új időpont, akkor nincs további tennivalód. Csupán bizonyosdj meg róla, hogy elmentetted az új dátumot a naptáradban. Ha nem jó számodra az új dátum, kérjük lépj be a platfromra, töröld a foglalást és válassz egy másik élő online képzési időszakot.\n' +
  'Alig várjuk, hogy hamarossan találkozzunk!\n' +
  'Üdvözlettel,\n' +
  'A CLX csapatod";"Уважаемый участник!\n' +
  'Ваш онлайн-тренинг в режиме реального времени по теме [TITLE], который должен был состояться [OLD_DATE] в [OLD_TIME], перенесён на[NEW_DATE] в [NEW_TIME]. Ваша заявка автоматически перенесена на новую дату. В случае согласия с новой датой вам не нужно ничего предпринимать. Просто запишите новую дату в свой ежедневник, чтобы не забыть её. Если новая дата вам не подходит, зайдите на платформу, отмените регистрацию и выберите другую сессию онлайн-тренинга в режиме реального времени.\n' +
  'Мы с нетерпением ждём встречи с вами!\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми участници,\n' +
  'Вашият Live Online Training на тема [TITLE], за който се регистрирахте за [OLD_DATE] в [OLD_TIME] е преместен за [NEW_DATE] в [NEW_TIME]. Вашата регистрация е автоматично преместена за новата дата. Ако сте съгласни с това, не е нужно да правите нищо допълнително, освен да го запишете в календара си. Ако новата дата не е удобна за вас, моля влезте в платформата, откажете регистрацията и изберете друга Live Online Training сесия.\n' +
  'Очакваме да се видим с нетърпение!\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'Vaše živé online školení na téma [TITLE], které jste si rezervoval/a na [OLD_DATE] v [OLD_TIME], bylo přesunut na [NEW_DATE] v [NEW_TIME]. Vaše rezervace byla automaticky přesunuta na nový termín. Pokud jste s novým termínem spokojeni, není co řešit. Jen si ho nezapomeňte uložit do kalendáře. Pokud Vám nový termín nevyhovuje, přihlaste se na platformu, zrušte rezervaci a vyberte si nový termín živého online školení.\n' +
  'Těšíme se na brzké setkání!\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα,\n' +
  'Το δικό σας Live Online Training με θέμα [TITLE] που έχετε κάνει κράτηση για την [OLD_DATE] στις [OLD_TIME] έχει μετατεθεί για την [NEW_DATE] στις  [NEW_TIME]. Η κρατησή σας έχει μετετεθεί στη νέα ημερομηνία. Αν είστε θετικός ως προς τη νέα ημερομηνία, δεν χρειάζεται να κάνετε κάτι. Απλά βεβαιωθείτε πως έχει κρατήσει την ημερομηνία στο ημερολόγιό σας. Αν η νέα ημερομηνία δεν ταιριάζει στο πρόγραμμά σας, παρακαλούμε όπως κάνετε  log on στην πλατφόρμα, ακυρώσετε την κράτηση και επιλέξετε μία διαφορετική Live Online Training συνεδρία\n' +
  'Περιμένοντας με ανυπομονησία να σας δούμε σύντομα!\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX";"Caro participante,\n' +
  'Seu Live Online Training [TITLE] que você reservou para [OLD_DATE] às [OLD_TIME] foi alterado para [NEW_DATE] às [NEW_TIME]. Sua reserva foi automaticamente movida para a nova data. Se você está satisfeito com a nova data, não há nada com o que se preocupar. Apenas certifique-se de salvá-la em seu calendário. Se a nova data não lhe for oportuna, aceda a plataforma, cancele a reserva e escolha outra sessão, caso haja disponibilidade.\n' +
  'Estamos ansiosos para vê-lo em breve!\n' +
  'Atenciosamente,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Trainingul live online [TITLE] pe care l-ati rezervat pentru data de [OLD_DATE] ola ora [OLD_TIME] a fost mutat in data de [NEW_DATE] la ora  [NEW_TIME]. Rezervarea a fost mutata automat pentru noua data. Daca aceasta modificare este ok, nu sunt necesare modificari pe platforma. In cazul in care noua data se suprapune cu alte evenimente din calendar, va rugam sa va logati pe platforma, sa anulati rezevarea si sa alegeti o sesiune de training diferita.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Poštovani,\n' +
  'Vaš Live Online Training na [TITLE] koji ste rezervisali za [OLD_DATE] u [OLD_TIME] je pomeren za [NEW_DATE] u [NEW_TIME]. Vaša rezervacija je automatski premeštena na novi datum. Ako ste zadovoljni novim datumom, ne morate ništa da uradite. Samo se pobrinite da sačuvate datum u kalendaru. Ako vam novi datum ne odgovara, prijavite se na platformu, otkažite rezervaciju i odaberite drugu sesiju obuke uživo na mreži.\n' +
  'Radujemo se što ćemo vas uskoro videti!\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý účastník,\n' +
  'váš Live Online Training na [TITLE] na ktorý ste sa prihlásili [DATE] o [TIME], bol preložený na [NEW_DATE] o [NEW_TIME]. Vaša prihláška bola automaticky presunutá na nový termín. Ak vám tento nový termín vyhovuje, nemusíte pre účasť na ňom nič viac urobiť. Len si ho poznačte, resp. vložte do vášho kalendára. Ak vám nový termín nevyhovuje, prosím nalogujte sa na platformu, zrušte tento termín a vyberte si iný termín pre váš Live Online Training.\n' +
  'Tešíme sa na skoré stretnutie!\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Spoštovani udeleženec,\n' +
  'Vaše spletno usposabljanje v živo za [TITLE], ki ste ga rezervirali za [OLD_DATE] na [OLD_TIME], je bilo premaknjeno na [NEW_DATE] ob [NEW_TIME]. Vaša rezervacija je bila samodejno prestavljena na nov datum. Če ste zadovoljni z novim datumom, ne potrebujete ničesar storiti. Prepričajte se, da ste shranili datum v koledar. Če vam nov datum ne ustreza, se prijavite na platformo, prekličite rezervacijo in izberite drugo spletno usposabljanje v živo.\n' +
  'Veselimo se, da se kmalu vidimo!\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTBooked;"Sehr geehrte_r Teilnehmer_in,\n' +
  'Sie wurden als Teilnehmer dem Live Online Training zum Thema [TITLE] am [DATE] um [TIME] Uhr zugebucht. Wir hoffe, dass der Zeitpunkt für Sie passt. Wenn ja, müssen Sie nichts weiter unternehmen. Übertragen Sie den Termin einfach in Ihren Kalender. Wenn der Zeitpunkt nicht passt, gehen Sie bitte auf die CLX Trainingsplattform, stornieren Sie die Buchung und wählen Sie einen anderen Zeitpunkt für das Live Online Training aus.\n' +
  'Wir freuen uns, Sie im Live Online Training zu sehen!\n' +
  'Viele Grüße,\n' +
  'Ihr CLX Team";"Cher participant,\n' +
  'Vous avez été ajouté en tant que participant au ""Live Formateur"" sur [TITLE] le [DATE] de [START_TIME] à  [END_TIME]. Nous espérons que cette date et cette heure vous conviennent. Si c\'est le cas, vous n\'avez rien à faire. Assurez-vous simplement d\'enregistrer la date dans votre agenda. Si la date ne vous convient pas, veuillez vous connecter à la plateforme, annuler la réservation et choisir une autre session de ""Live Formateur"".\n' +
  'Nous avons hâte de vous voir bientôt!\n' +
  'Cordialement,\n' +
  'Votre équipe du CLX ";"Beste deelnemer,\n' +
  'Je bent als deelnemer toegevoegd aan de Live Online Training op [TITLE] voor [DATE] van [START_TIME] tot [END_TIME]. We hopen dat de datum en tijd uitkomt . Als dat zo is, hoef je niets te doen te doen. Zorg ervoor dat u de datum opslaat in uw agenda. Als de datum niet bij je past, log dan in op het platform, annuleer de boeking en kies een andere Live Online Training sessie.\n' +
  'We kijken ernaar uit om je binnenkort te zien!\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  'You have been added as a participant to the Live Online Training on [TITLE] for [DATE] at [START_TIME] to [END_TIME]. We hope this date and time suits you. If so, there is nothing for you to do. Just make sure you save the date in your calendar. If the date does not suit you, please log on to the platform, cancel the booking and choose a different Live Online Training session.\n' +
  'We look forward to seeing you soon!\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  'sinut on lisätty osallistumaan Live Online Training -moduuliin [TITLE], joka toteutetaan [DATE] alkaen kello [START_TIME] ja päättyen kello [END_TIME]. Toivomme, että tämä ajankohta sopii sinulle. Mikäli ajankohta sopii sinulle, sinun ei tarvitse tehdä mitään. Muista vain merkitä ajankohta kalenteriisi. Jos ajankohta ei sovi sinulle, ole hyvä ja kirjaudu Audi CLX -alustalle, peruuta varaus ja valitse toinen ajankohta kyseiselle Live Online Training -moduulille.\n' +
  'Nähdään pian!\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Drogi uczestniku,\n' +
  'Zostałeś dodany jako uczestnik do szkolenia online na żywo na [TITLE] w dniu [DATE] od [START_TIME] do [END_TIME]. Mamy nadzieję, że ta data i godzina Ci odpowiada. Jeśli tak, nie misisz nic robić. Po prostu upewnij się, że zapiszesz datę w kalendarzu. Jeśli data Ci nie odpowiada, zaloguj się na platformę, anuluj rezerwację i wybierz inną sesję treningową online na żywo.\n' +
  'Do zobaczenia wkrótce!\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"Querido Participante,\n' +
  'Se te ha agregado como participante a la sesión en formación en directo sobre [TITLE] para [DATE] desde el [START_TIME] hasta el [END_TIME]. Si estás satisfecho con la fecha y hora de la sesión, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Bästa deltagare,\n' +
  'Du har lagts till som deltagare i Live Online Training på [TITLE] under [DATE] från [START_TIME] till [END_TIME]. Vi hoppas att detta datum och tid passar dig. Om så är fallet behöver du inte göra något. Se bara till att du sparar datumet i din kalender. Om datumet inte passar dig, logga in på plattformen, avbryt bokningen och välj en annan Live Online Training.\n' +
  'Vi ser fram emot att se dig snart!\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team";"Buongiorno,\n' +
  "sei stato aggiunto come partecipante nel Live Online Training [TITLE] che si terrà il giorno [DATE] dalle ore [START_TIME] alle ore [END_TIME]. Ci auguriamo che questo appuntamento incontri le tue esigenze. Se la data non ti soddisfa, accedi alla piattaforma, cancella la prenotazione e scegli un'altra sessione di Live Online Training.\n" +
  'Ti aspettiamo!\n' +
  'Il team CLX";Kære deltager. Du er tilføjet som deltager på en LOT og vi håber tidspunktet passer dig. Hvis det ikke passer dig, så besøg sitet og foretag en ny booking. De bedste hilsener CLX teamet;"Шановний учаснику,\n' +
  "Вас включено в якості учасника до Live Online Тренінгу [TITLE]  на [DATE] від [START_TIME] до [END_TIME]. Сподіваємось, ця дата і час Вас влаштовують. Якщо так -Вам нічого робити не потрібно. Тільки запам'ятайте та збережіть нову дату у календарі. Якщо нова дата Вам не підходить, будь ласка, зайдіть на платформу, скасуйте бронювання та оберіть інший слот Live Online Тренінгу.\n" +
  'Чекаємо на зустріч з Вами!\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"Querido Participante,\n' +
  'Se te ha agregado como participante a la sesión en formación en directo sobre [TITLE] para [DATE] desde el [START_TIME] hasta el [END_TIME]. Si estás satisfecho con la fecha y hora de la sesión, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Sayın Katılımcı,\n' +
  'xxx (konu) ile ilgili Canlı Çevrimiçi Eğitime xx.xx.xxxx (tarih) tarihinde ve xx.xx (zaman) ile xx.xx (zaman) arasında katılımcı olarak eklendiniz. Umarız bu tarih ve zaman sizin için uygundur. Uygunsa, yapacağınız başka bir şey yoktur. Sadece yeni tarihi takviminize kaydettiğinizden emin olun. Tarih sizin için uygun değilse, lütfen platforma giriş yapın, rezervasyonu iptal edin ve farklı bir Canlı Çevrimiçi Eğitim oturumu seçin.\n' +
  'Sizinle tanışmayı sabırsızlıkla bekliyoruz.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves résztvevő,\n' +
  'Felvettek résztvevőként a [DATE], [START_TIME] és [END_TIME] között zajló xx [TITLE] témájú élő online képzésre.Reméljük, hogy megelel számodra ez az időpont. Ha igen, akkor nincs további tennivalód. Csupán bizonyosdj meg róla, hogy elmentetted a dátumot a naptáradba. Amennyiben nem felel meg neked a dárum , kérjük lépj be a platformra, töröld a foglalást és válassz egy másik élő online képzési időszakot.\n' +
  'Alig várjuk, hogy hamarossan találkozzunk!\n' +
  'Üdvözlettel,\n' +
  'A CLX csapatod";"Уважаемый участник!\n' +
  'Вы зарегистрированы в качестве участника онлайн-тренинга в режиме реального времени по теме xx [TITLE], который пройдёт [DATE] с [START_TIME] по [END_TIME]. Мы надеемся, что вам подходят эти дата и время. В этом случае вам не нужно ничего предпринимать. Просто запишите новую дату в свой ежедневник, чтобы не забыть её. Если дата вам не подходит, зайдите на платформу, отмените регистрацию и выберите другую сессию онлайн-тренинга в режиме реального времени.\n' +
  'Мы с нетерпением ждём встречи с вами!\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми участници,\n' +
  'Вие сте регистрирани за Live Online Training на тема [TITLE] за [DATE] от [START_TIME] до [END_TIME]. Надяваме се, че тези дата и час са удобни за вас. Ако това е така, не е нужно да правите нишо допълнително, освен да го запишете в календара си. Ако датата не е удобна за вас, моля влезте в платформата, откажете регистрацията и изберете друга Live Online Training сесия.\n' +
  'Очакваме да се видим с нетърпение!\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'byli jste přidáni jako účastníci živého online školení na téma [TITLE] na [DATE] od [START_TIME] do [END_TIME]. Doufáme, že vám toto datum a čas vyhovuje. Pokud ano, není co řešit. Jen si datum nezapomeňte uložit do kalendáře. Pokud Vám termín nevyhovuje, přihlaste se na platformu, zrušte rezervaci a vyberte si nový termín živého online školení.\n' +
  'Těšíme se na brzké setkání!\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα,\n' +
  'Έχετε προστεθεί ως συμμετέχοντας στο Live Online Training με θέμα [TITLE] στις [DATE] από [START_TIME] έως [END_TIME]. Ευελπιστούμε πως αυτή η ημερομηνία και ώρα ταιριάζει στο πρόγραμμά σας. Αν ναι δεν χρειάζεται να κάνετε τίποτα. Απλά βεβαιώθείτε πως έχετε κρατήσει την ημερομηνία στο ημερολόγιό σας. Αν η νέα ημερομηνία δεν ταιριάζει στο πρόγραμμά σας, παρακαλούμε όπως κάνετε  log on στην πλατφόρμα, ακυρώσετε την κράτηση και επιλέξετε μία διαφορετική Live Online Training συνεδρία\n' +
  'Περιμένοντας με ανυπομονησία να σας δούμε σύντομα!\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX";"Caro participante,\n' +
  'Você foi adicionado como participante do Live Online Training [TITLE] para [DATE] das [START_TIME] às [END_TIME]. Esperamos que esta data e hora sejam adequadas para você. Nesse caso, não precisa de fazer nada. Apenas certifique-se de salvar a data em seu calendário. Se a nova data não lhe for oportuna, aceda a plataforma, cancele a reserva e escolha outra sessão, caso haja disponibilidade.\n' +
  'Estamos ansiosos para vê-lo em breve!\n' +
  'Atenciosamente,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Ati fost adaugati ca si participanti la Live Online Training [TITLE] pentru data de [DATE] de la ora [START_TIME] la ora [END_TIME]. Daca aceasta programare este ok, nu sunt necesare modificari pe platforma. In cazul in care data se suprapune cu alte evenimente din calendar, va rugam sa va logati pe platforma, sa anulati rezevarea si sa alegeti o sesiune de training diferita.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Poštovani,\n' +
  'Dodati ste kao učesnik Live Online Training na [TITLE] za [DATE] od [START_TIME] do [END_TIME]. Nadamo se da vam ovaj datum i vreme odgovaraju. Ako je tako, nemate šta da radite. Samo se pobrinite da sačuvate datum u kalendaru. Ako vam datum ne odgovara, molimo vas da se prijavite na platformu, otkažete rezervaciju i odaberete drugu sesiju obuke uživo na mreži.\n' +
  'Radujemo se što ćemo vas uskoro videti!\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý účastník,\n' +
  'boli ste pridaný ako účastník do online tréningu  Live Online Training pre [TITLE] na [DATE] fod [START_TIME] to [END_TIME]. Dúfame, že sa vám tento termín hodí. Ak áno, pre vašu účasť na ňom už nemusíte nič viac urobiť, len si tento termín zafixovať do vášho kalendára. Ak vám tento termín nepasuje, prosím nalogujte sa na platformu, zrušte tento termín a vyberte si iný termín pre Live Online Training.\n' +
  'Tešíme sa na skoré stretnutie!\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Spoštovani udeleženec,\n' +
  'Dodani ste bili kot udeleženec spletnega usposabljanja v živo na [TITLE] za [DATE] od [START_TIME] do [END_TIME]. Upamo, da vam ta datum in ura ustrezata. Če je tako, ne potrebujete ničesar storiti. Prepričajte se, da ste shranili datum v koledar. Če vam datum ne ustreza, se prijavite na platformo, prekličite rezervacijo in izberite drugo spletno vadbo v živo.\n' +
  'Veselimo se, da se kmalu vidimo!\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTRebooked;"Sehr geehrte_r Teilnehmer_in,\n' +
  'Sie wurden zu einem anderen Zeitpunkt des Live Online Trainings mit dem Thema [TITLE] umgebucht.\n' +
  'Der vorherige Zeitpunkt war der [OLD_DATE] um [OLD_TIME] Uhr.\n' +
  'Der neue Zeitpunkt ist der [NEW_DATE] umd [NEW_TIME) Uhr.\n' +
  'Wenn der neue Zeitpunkt für Sie passt, müssen Sie nichts weiter unternehmen. Übertragen Sie den Termin einfach noch in Ihren Kalender. Wenn der Zeitpunkt nicht passt, gehen Sie bitte auf die CLX Trainingsplattform, stornieren Sie die Buchung und wählen Sie einen anderen Zeitpunkt für das Live Online Training aus.\n' +
  'Wir freuen uns, Sie im Live Online Training zu sehen!\n' +
  'Viele Grüße,\n' +
  'Ihr CLX Team\n' +
  '";"Cher participant,\n' +
  'Vous avez été inscrit pour une autre session ""Live Formateur"" sur le même [TITLE].\n' +
  'Votre réservation précédente était pour le [OLD_DATE] à [OLD_TIME].\n' +
  'Votre nouvelle réservation est pour le [OLD_DATE] à [NEW_TIME].\n' +
  'Si vous êtes satisfait de la nouvelle date, vous n\'avez rien à faire. Assurez-vous simplement d\'enregistrer la date dans votre agenda. Si la date ne vous convient pas, veuillez vous connecter à la plateforme, annuler la réservation et choisir une autre session de ""Live Formateur"".\n' +
  'Nous avons hâte de vous voir bientôt!\n' +
  'Cordialement,\n' +
  'Votre équipe du CLX ";"Beste deelnemer,\n' +
  'Je bent omgeboekt voor een andere Live Online Sessie over hetzelfde onderwerp [TITLE].\n' +
  'Je vorige boeking was voor [OLD_DATE] op [OLD_TIME].\n' +
  'Je nieuwe boeking is voor [OLD_DATE] op [NEW_TIME].\n' +
  'Als je tevreden bent met de nieuwe datum, dan hoef je niets te doen. Zorg ervoor dat je de datum opslaat in uw agenda. Als de datum niet bij u past, log dan in op het platform, annuleer de boeking en kies een andere Live Online Training sessie.\n' +
  'We kijken ernaar uit om je binnenkort te zien!\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  'You have been rebooked for another Live Online Session on the same [TITLE].\n' +
  'Your previous booking was for [OLD_DATE] at [OLD_TIME].\n' +
  'Your new booking is for [NEW_DATE] at [NEW_TIME].\n' +
  'If you are happy with the new date, there is nothing for you to do. Just make sure you save the date in your calendar. If the date does not suit you, please log on to the platform, cancel the booking and choose a different Live Online Training session.\n' +
  'We look forward to seeing you soon!\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  'sinulle on varattu toinen Live Online Training -moduuli samasta aiheesta [TITLE].\n' +
  'Aikaisempi varauksesi oli [OLD_DATE] kello [OLD_TIME].\n' +
  'Uusi varauksesi on [OLD_DATE] kello [OLD_TIME].\n' +
  'Mikäli uusi ajankohta sopii sinulle, sinun ei tarvitse tehdä mitään. Muista vain merkitä päivä kalenteriisi. Jos ajankohta ei sovi sinulle, ole hyvä ja kirjaudu Audi CLX -alustalle, peruuta varaus ja valitse toinen ajankohta kyseiselle Live Online Training -moduulille.\n' +
  'Nähdään pian!\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Drogi uczestniku,\n' +
  'Twoja rezerwacja została przeniesiona na inną sesję online na żywo na ten sam temat [TITLE].\n' +
  'Poprzednia rezerwacja w dniu [OLD_DATE] na [OLDTIME].\n' +
  'Twoja nowa rezerwacja jest w dniu [NEW_DATE] na [NEW_TIME].\n' +
  'Jeśli nowy termin Ci odpowiada, nie musisz nic robić. Po prostu upewnij się, że zapiszesz datę w kalendarzu. Jeśli data Ci nie odpowiada, zaloguj się na platformę, anuluj rezerwację i wybierz inną sesję treningową online na żywo.\n' +
  'Do zobaczenia wkrótce!\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"Querido Participante,\n' +
  'Se te ha reservado plaza en otra sesión de formación en directo sobre el mismo tema [TITLE].\n' +
  'Tu reserva anterior fue para [OLD_DATE] a las [OLD_TIME].\n' +
  'Tu nueva reserva es para [NEW_DATE] a las [NEW_TIME].\n' +
  'Si estás satisfecho con la fecha y hora de la sesión, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Bästa deltagare,\n' +
  'Du har bokats om till en annan Live Online Training om samma ämne [TITLE].\n' +
  'Din tidigare bokning var för [OLD_DATE] [OLD_TIME].\n' +
  'Din nya bokning är för [NEW_DATE] vid [NEW_TIME].\n' +
  'Om du är nöjd med det nya datumet fbehöver du inte göra något Se bara till att du sparar datumet i din kalender. Om datumet inte passar dig, logga in på plattformen, avbryt bokningen och välj en annan Live Online Training.\n' +
  'Vi ser fram emot att se dig snart!\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team";"Buongiorno,\n' +
  'sei stato iscritto a un Live Online Training a tema [TITLE].\n' +
  'La tua precedente iscrizione era per il giorno [OLD_DATE] alle ore [OLD_TIME].\n' +
  'La tua nuova prenotazione è per il giorno [NEW_DATE] alle ore [NEW_TIME].\n' +
  "Ci auguriamo che questo appuntamento incontri le tue esigenze. Se la data non ti soddisfa, accedi alla piattaforma, cancella la prenotazione e scegli un'altra sessione di Live Online Training.\n" +
  'Ti aspettiamo!\n' +
  'Il team CLX";Kære deltager. Din LOT er blevet re-booket til nyt tidspunkt. Hvis det ikke passer dig, så besøg sitet og foretag en ny booking. De bedste hilsener CLX teamet;"Шановний учаснику,\n' +
  'Змінено дату і час Вашого бронювання Live Online Тренінгу за темою [TITLE].\n' +
  'Ваше попереднє бронювання було на [OLD_DATE] о [OLD_TIME].\n' +
  'Ваше нове бронювання на [NEW_DATE] о [NEW_TIME].\n' +
  "Якщо нова дата Вас влаштовує, Вам нічого робити не потрібно. Тільки запам'ятайте та збережіть нову дату у календарі. Якщо нова дата Вам не підходить, будь ласка, зайдіть на платформу, скасуйте бронювання та оберіть інший слот Live Online Тренінгу.\n" +
  'Чекаємо на зустріч з Вами!\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"Querido Participante,\n' +
  'Se te ha reservado plaza en otra sesión de formación en directo sobre el mismo tema [TITLE].\n' +
  'Tu reserva anterior fue para [OLD_DATE] a las [OLD_TIME].\n' +
  'Tu nueva reserva es para [NEW_DATE] a las [NEW_TIME].\n' +
  'Si estás satisfecho con la fecha y hora de la sesión, no tienes que hacer nada más. Solo asegúrate de guardar la fecha en tu calendario. Si la nueva fecha no te conviene, inicia sesión en la plataforma, cancela la reserva y elige una sesión en otro horario.\n' +
  '¡Esperamos verte pronto!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Sayın Katılımcı,\n' +
  'Aynı xx konusuyla ilgili Canlı Çevrimiçi Eğitime tekrar rezervasyonunuz yapıldı.\n' +
  'Önceki rezervasyonunuz xx.xx.xxxx (tarih) tarihi ve xx.xx (zaman) saati içindi.\n' +
  'Yeni rezervasyonunuz xx.xx.xxxx (tarih) tarihi ve xx.xx (zaman) saati içindir.\n' +
  'Yeni tarih sizin için uygunsa, yapacağınız başka bir şey yoktur. Sadece yeni tarihi takviminize kaydettiğinizden emin olun. Tarih sizin için uygun değilse, lütfen platforma giriş yapın, rezervasyonu iptal edin ve farklı bir Canlı Çevrimiçi Eğitim oturumu seçin.\n' +
  'Sizinle tanışmayı sabırsızlıkla bekliyoruz.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves résztvevő,\n' +
  'Átfoglaltak egy másik élő online képzési időszakra ugyanabban a témában [TITLE].\n' +
  'A korábbi foglalásod [OLD_DATE], [OLD_TIME] időpont volt.\n' +
  'Az új foglalsod [NEW_DATE], [NEW_TIME].\n' +
  'Ha megfelel az új időpont, akkor nincs további tennivalód. Csupán bizonyosdj meg róla, hogy elmentetted az új dátumot a naptáradban. Ha nem jó számodra az új dátum, kérjük lépj be a platfromra, töröld a foglalást és válassz egy másik élő online képzési időszakot.\n' +
  'Alig várjuk, hogy hamarossan találkozzunk!\n' +
  'Üdvözlettel,\n' +
  'A CLX csapatod";"Уважаемый участник!\n' +
  'Вы зарегистрированы на другую сессию онлайн-тренинга в режиме реального времени по этой же теме [TITLE].\n' +
  'Дата и время предыдущей сессии: [OLD_DATE] в [OLD_TIME].\n' +
  'Дата и время новой сессии: [NEW_DATE] в [NEW_TIME].\n' +
  'В случае согласия с новой датой вам не нужно ничего предпринимать. Просто запишите новую дату в свой ежедневник, чтобы не забыть её. Если дата вам не подходит, зайдите на платформу, отмените регистрацию и выберите другую сессию онлайн-тренинга в режиме реального времени.\n' +
  'Мы с нетерпением ждём встречи с вами!\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми участници,\n' +
  'Вашата регистрация е променена за друга Live Online Session сесия на същата тема [TITLE].\n' +
  'Вашата предишна регистрация беше за [OLD_DATE] в [OLD_TIME].\n' +
  'Вашата нова регистрация е за [NEW_DATE] в [NEW_TIME].\n' +
  'Надяваме се, че тези дата и час са удобни за вас. Ако това е така, не е нужно да правите нишо допълнително, освен да го запишете в календара си. Ако датата не е удобна за вас, моля влезте в платформата, откажете регистрацията и изберете друга Live Online Training сесия.\n' +
  'Очакваме да се видим с нетърпение!\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'byla provedena změna Vaší rezervace na jiný termín živého online školení na stejné téma [TITLE].\n' +
  'Vaše předchozí rezervace byla na [OLD_DATE] v [OLD_TIME].\n' +
  'Vaše nová rezervace je na [NEW_DATE] v [NEW_TIME].\n' +
  'Pokud jste s novým termínem spokojeni, není co řešit. Jen si ho nezapomeňte uložit do kalendáře. Pokud Vám termín nevyhovuje, přihlaste se na platformu, zrušte rezervaci a vyberte si nový termín živého online školení.\n' +
  'Těšíme se na brzké setkání!\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα,\n' +
  'Έχετε κάνει μία νέα κράτηση για ένα άλλο Live Online Session της ίδια θεματολογίας [TITLE].\n' +
  'Η προηγούμενη κράτησή σας ήταν για την [OLD_DATE] στις [OLD_TIME].\n' +
  'Η νέα σας κράτηση είναι για την [NEW_DATE] στις [NEW_TIME].\n' +
  'Αν είστε εντάξει με το πρόγραμμά σας,  δεν χρειάζεται να κάνετε τίποτα. Απλά βεβαιωθείτε πως έχετε κρατήσει την ημερομηνία στο ημερολόγιό σας. Αν η νέα ημερομηνία δεν ταιριάζει στο πρόγραμμά σας, παρακαλούμε όπως κάνετε  log on στην πλατφόρμα, ακυρώσετε την κράτηση και επιλέξετε μία διαφορετική Live Online Training συνεδρία\n' +
  'Περιμένοντας με ανυπομονησία να σε δούμε σύντομα!\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX";"Caro participante,\n' +
  'Você foi reagendado para outra Sessão Online ao Vivo, sobre o mesmo tema [TITLE].\n' +
  'Sua reserva anterior era para [OLD_DATE] às [OLD_TIME].\n' +
  'Sua nova reserva é para [NEW_DATE] às [NEW_TIME].\n' +
  'Se você está satisfeito com a nova data, não precisa de fazer nada. Apenas certifique-se de salvar a data em seu calendário. Se a nova data não lhe for oportuna, aceda a plataforma, cancele a reserva e escolha outra sessão, caso haja disponibilidade.\n' +
  'Estamos ansiosos para vê-lo em breve!\n' +
  'Atenciosamente,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Ati fost reprogramati pentru o alta sesiune Live Online Training pentru acelasi subiect [TITLE].\n' +
  'Rezervarea anterioara a fost efectuata pentru data de [OLD_DATE] la ora [OLD_TIME].\n' +
  'Noua rezervare este pentru data [NEW_DATE] la ora [NEW_TIME].\n' +
  'Daca aceasta programare este ok, nu sunt necesare modificari pe platforma. In cazul in care data se suprapune cu alte evenimente din calendar, va rugam sa va logati pe platforma, sa anulati rezevarea si sa alegeti o sesiune de training diferita.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Poštovani,\n' +
  'Prebukirani ste za drugu Live Online sesiju na istu temu [TITLE].\n' +
  'Vaša prethodna rezervacija je bila za [OLD_DATE] u [OLD_TIME].\n' +
  'Vaša nova rezervacija je za [NEW_DATE] u [NEW_TIME].\n' +
  'Ako ste zadovoljni novim datumom, ne morate ništa da uradite. Samo se pobrinite da sačuvate datum u kalendaru. Ako vam datum ne odgovara, molimo vas da se prijavite na platformu, otkažete rezervaciju i odaberete drugu sesiju obuke uživo na mreži.\n' +
  'Radujemo se što ćemo vas uskoro videti!\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý účastník,\n' +
  'váš termín pre online tréning bol zmenený na iný termín Live Online Session s tou istou témou [TITLE].\n' +
  'Vaša pôvodná prihláška bola na termín [OLD_DATE] at [OLD_TIME].\n' +
  'Vaša nová prihláška je na termín [NEW_DATE] at [NEW_TIME].\n' +
  'Ak vám tento nový termín vyhovuje, nemusíte pre účasť na ňom nič viac urobiť. Len si ho poznačte, resp. vložte do vášho kalendára. Ak vám nový termín nevyhovuje, prosím nalogujte sa na platformu, zrušte tento termín a vyberte si iný termín pre váš Live Online Training.\n' +
  'Tešíme sa na skoré stretnutie!\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Spoštovani udeleženec,\n' +
  'Bili ste prestavljeni na drugo spletno usposabljanje v živo na isto temo [TITLE].\n' +
  'Vaša prejšnja rezervacija je bila za [OLD_DATE] ob [OLD_TIME].\n' +
  'Vaša nova rezervacija je za [NEW_DATE] ob [NEW_TIME].\n' +
  'Če ste zadovoljni z novim datumom, ne potrebujete ničesar storiti. Prepričajte se, da ste shranili datum v koledar. Če vam datum ne ustreza, se prijavite na platformo, prekličite rezervacijo in izberite drugo spletno vadbo v živo.\n' +
  'Veselimo se, da se kmalu vidimo!\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTBookingDeleted;"Sehr geehrte_r Teilnehmer_in,\n' +
  'wir wollen Sie darüber informieren, dass Ihre Buchung für das Live Online Training zum Thema [TITLE] am [DATE] um [TIME] Uhr storniert wurde. Bitte gehen Sie auf die CLX Trainingsplattform und buchen Sie sich für einen anderen Zeitpunkt in das Live Online Training ein.\n' +
  'Vielen Dank,\n' +
  'Ihr CLX Team";"Cher participant,\n' +
  'Nous tenons à vous informer que votre réservation pour le ""Live Formateur"" sur [TITLE] le [DATE] à [TIME] a été annulée. N\'hésitez pas à effectuer une nouvelle réservation pour un autre ""Live Formateur"".\n' +
  'Cordialement,\n' +
  'Votre équipe du  CLX ";"Beste deelnemer,\n' +
  'Wij informeren je graag dat je boeking voor de Live Online Training op [TITLE] voor [DATE] op [TIME] is geannuleerd. Maak gerust een nieuwe boeking voor een andere Live Online Training.\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  'We would like to inform you that your booking for the Live Online Training on [TITLE] for [DATE] at [TIME] has been cancelled. Please feel free to make a new booking for another Live Online Training.\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  'haluamme ilmoittaa sinulle, että varauksesi Live Online Training -moduulista [TITLE] [DATE] kello [TIME] on peruutettu. Voit halutessasi tehdä uuden varauksen toiseen Live Online Training -moduuliin.\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Drogi uczestniku,\n' +
  'Informujemy, że twoja rezerwacja na szkolenie online na żywo na (TITLE) w dniu [DATE] o [TIME] została anulowana. Prosimy o dokonanie nowej rezerwacji na kolejne szkolenie online na żywo.\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"""Querido Participante,\n' +
  'Nos gustaría informarte que tu reserva para la formación en directo sobre [TITLE] para [DATE] a las [TIME] ha sido cancelada. No dudes en hacer una nueva reserva para otra formación en directo.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX """;"Bästa deltagare,\n' +
  'Vi vill informera dig om att din bokning för Live Online Training på [TITLE] för [DATE] klockan [TIME] har annullerats. Gör gärna en ny bokning för en annan Live Online Training.\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team ";"Buongiorno,\n' +
  'ti informiamo che la tua iscrizione per il Live Online Training a tema ""XX"" [TITLE] per il giorno [DATE] alle ore [TIME] è stata cancellata. Potrai procedere in autonomia con una nuova iscrizione ad altra sessione di Live Online Training.\n' +
  'Ti aspettiamo!\n' +
  'Il team CLX";Kære deltager. Din LOT er blevet annulleret. Du er velkommen til at besøge sitet og foretage en ny booking. De bedste hilsener CLX teamet;"Шановний учаснику,\n' +
  'Хочемо повідомити, що Ваше бронювання Live Online Тренінгу [TITLE]  на [DATE] о [TIME] було скасовано. Будь ласка, забронюйте участь в іншому  Live Online Тренінгу.\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"Querido Participante,\n' +
  'Nos gustaría informarte que tu reserva para la formación en directo sobre [TITLE] para [DATE] a las [TIME] ha sido cancelada. No dudes en hacer una nueva reserva para otra formación en directo.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Sayın Katılımcı,\n' +
  'xx.xx.xxxx (tarih) tarihindeki ve xx.xx (zaman) saatindeki ""xx"" (konu) ile ilgili Canlı Çevrimiçi Eğitim için yaptığınız rezervasyonun iptal edildiğini bildirmek istiyoruz. Başka bir Canlı Çevrimiçi Eğitim için yeni bir rezervasyon yapabilirsiniz.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves résztvevő,\n' +
  'Tájékoztatni szeretnénk, hogy a [DATE], [TIME] időpontra lefoglalt [TITLE] témájú élő online képzésed törlésre került. Nyugodtan foglalj le új időpontot egy másik élő online képzésre.\n' +
  'Üdvözlettel,\n' +
  'A CLX csapatod";"Уважаемый участник!\n' +
  'Сообщаем об отмене вашей регистрации на онлайн-тренинг в режиме реального времени по теме [TITLE] [DATE] в [TIME]. Зарегистрируйтесь на другой онлайн-тренинг.\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми участници,\n' +
  'Искаме да ви информираме, че вашата регистрация за Live Online Training сесия на тема [TITLE] за [DATE] в [TIME] беше отменена. Моля направете нова регистрация за друга Live Online Training сесия.\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'rádi bychom Vás informovali, že Vaše rezervace na živé online školení na téma [TITLE] na [DATE] v [TIME] byla zrušena. Neváhejte a rezervujte si nový termín živého online školení.\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα,\n' +
  'Θα θέλαμε να σας ενημερώουμε πως η κράτησή σας για το Live Online Training με θέμα [TITLE] για τις [DATE] στις [TIME] ακυρώθηκε. Παρακαλούμε κάντε μία νέα κράτηση για ένα άλλο Live Online Training.\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX ";"Caro participante,\n' +
  'Gostaríamos de informar que sua reserva para o Live Online Training [TITLE] em [DATE] às [TIME] foi cancelada. Fique à vontade para fazer uma nova reserva para outra sessão ao Vivo.\n' +
  'Atenciosamente,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Programarea pentru Live Online Training [TITLE] din data de [DATE] de la ora [TIME] a fost anulata. Va rugam sa efectuati o noua rezervare pentru Live Online Training.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Poštovani,\n' +
  'Želimo da vas obavestimo da je vaša rezervacija za Live Online Training na [TITLE] za [DATE] u [TIME] otkazana. Slobodno napravite novu rezervaciju za još jednu obuku uživo na mreži.\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý účastník,\n' +
  'dovoľte nám informovať vás, že vaša prihláška na Live Online Training pre [TITLE] na [DATE] o [TIME] bola zrušená. Prosím prihláste sa na iný termín Live Online Training.\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Spoštovani udeleženec,\n' +
  'Obveščamo vas, da je bila vaša rezervacija za spletno usposabljanje v živo na [TITLE] za [DATE] na [TIME] preklicana. Prosimo, da naredite novo rezervacijo za drugo spletno usposabljanje v živo.\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTCotrainerNamed;"Sehr geehrte_r Trainer_in,\n' +
  'Sie wurden als Back-up Trainer von Trainer [TRAINER] für das Live Online Training zum Thema [TITLE] am [DATE] umd [TIME] Uhr ausgewählt. Sie finden dieses Live Online Training in der Übersicht aller Ihrer LOTs im CMS auf der CLX Trainingsplattform.\n' +
  'Viele Grüße,\n' +
  'Ihr CLX Team";"Cher formateur,\n' +
  'Vous avez été nominé comme ""Back-up""  par [TRAINER] pour le ""Live Formateur"" sur [TITLE] le [DATE] à [TIME]. Vous verrez le ""Live Formateur""  dans le résumé où toutes les sessions sont répertoriées dans le CMS.\n' +
  'Sincères amitiés,\n' +
  'Votre équipe CLX ";"Beste trainer,\n' +
  'Je bent door [TRAINER} genomineerd als Back-up Trainer voor de Live Online Training [TITLE] voor [DATE] op [TIME]. Je ziet de Live Online Training in het overzicht waar alle sessies in het CMS worden vermeld.\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear trainer,\n' +
  'You have been nominated as Back-up Trainer by [TRAINER] for the Live Online Training [TITLE] for [DATE] at [TIME]. You will see the Live Online Training in the overview where all sessions are listed in the CMS.\n' +
  'Kind regards,\n' +
  'Your CLX team";"Tervehdys,\n' +
  '[TRAINER] on nimennyt sinut ""Back-up""-kouluttajaksi Live Online Training -moduuliin [TITLE] [DATE] kello [TIME]. Näet Live Onlinen Training -moduulin yleiskatsauksen Audi CLX -alustalta kohdasta, jossa kaikki moduulit on listattu.\n' +
  'Ystävällisin terveisin,\n' +
  'Audi CLX -tiimisi";"Szanowny Trenerze,\n' +
  'Zostałeś wybrany jako Back-up Trainer przez [TRAINER] do szkolenia online na żywo [TITLE} w dniu [DATE] na [TIME]. Szkolenia online na żywo zostaną wyświetlone w przeglądzie, w którym wszystkie sesje są wymienione w CMS.\n' +
  'Serdecznie pozdrawiamy,\n' +
  'Twój zespół CLX";"Estimado formador,\n' +
  'Has sido nominado como formador de respaldo por [TRAINER] para la formación en directo [TITLE] para [DATE] a las [TIME]. Verás la formación en directo en la descripción general donde se enumeran todas las sesiones en el CMS.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Bästa trainer\n' +
  'Du har nominerats som Back-up Trainer av [TRAINER] till Live Online Training [TITLE] för [DATE] klockan [TIME]. Du kommer att se Live Online Training i översikten där alla sessioner är listade i CMS.\n' +
  'Vänliga Hälsningar,\n' +
  'Ditt CLX-team";"Caro trainer,\n' +
  "sei stato inserito come Trainer di Back-up da [TRAINER] per il Live Online Training [TITLE] del giorno [DATE] alle ore [TIME]. Troverai il Live Online Training nell'overview dove tutte le sessioni sono elencate nel CMX.\n" +
  'il team CLX";Kære træner. Du er nomineret som back-up træner og kan se alle dine sessions i CMS. De bedste hilsener CLX teamet;"Шановний колего,\n' +
  'Вас призначено  співтренером з тренером xx для проведення Live Online Тренінгу [TITLE]  на [DATE] о [TIME]. Ви побачите цей Live Online Тренінг в розділі, де наведені всі слоти в CMS.\n' +
  'З повагою,\n' +
  'Ваша команда CLX ";"Estimado formador,\n' +
  'Has sido nominado como formador de respaldo por [TRAINER] para la formación en directo [TITLE] para [DATE] a las [TIME]. Verás la formación en directo en la descripción general donde se enumeran todas las sesiones en el CMS.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Sayın eğitmen,\n' +
  'xx.xx.xxxx (tarih) tarihindeki ve xx.xx (zaman) saatindeki ""xx"" (konu) ile ilgili Canlı Çevrimiçi Eğitim için xx (eğitmen) tarafından Yedek Eğitmen olarak tayin edildiniz. Canlı Çevrimiçi Eğitimi, tüm oturumların CMS\'de listelendiği genel bakışta göreceksiniz.\n' +
  'Saygılarımızla,\n' +
  'CLX ekibiniz";"Kedves oktató,\n' +
  '[TRAINER téged jelölt tartalék oktatónak a [DATE], [TIME] zajló [TITLE] témájú elő online képzésen. Az élő online képzést láthatod az áttekintésben, ahol megtalálod az összes CMS-ben szereplő időszakot.\n' +
  'Üdvözlette,\n' +
  'A CLX csapatod";"Уважаемый инструктор!\n' +
  '[TRAINER] назначил вас резервным инструктором на онлайн-тренинг в режиме реального времени по теме [TITLE] [DATE] в [TIME]. Этот онлайн-тренинг отображается в перечне всех сессий в системе CMS.\n' +
  'С уважением,\n' +
  'команда платформы CLX";"Уважаеми тренери,\n' +
  'Вие сте избрани за Допълнителен тренер от [TRAINER] за Live Online Training сесията на тема [TITLE] за [DATE] в [TIME]. Ще видите този Live Online Training в прегледа с всички сесии в CMS системата.\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený trenére,\n' +
  '[TRAINER] Vás nominoval jako náhradního trenéra pro živé online školení na téma [TITLE] na [DATE] v [TIME]. Živé online školení se zobrazí v přehledu, kde jsou v systému CMS uvedena všechny termíny.\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ εκπαιδευτή,\n' +
  'Έχετε οριστεί ως Back-up Trainer από τον [TRAINER] για το Live Online Training [TITLE] για τις [DATE] στις [TIME]. Θα δείτε το Live Online Training στη λίστα με όλες τις συνεδρίες στο CMS.\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX ";"Caro formador,\n' +
  'Você foi nomeado como Formador de Apoio por [TRAINER] para o Live Online Training [TITLE] em [DATE] às [TIME]. Você verá o Treinamento Online ao Vivo na visão geral, onde todas as sessões estão listadas no CMS.\n' +
  'Atenciosamente,\n' +
  'Sua equipe CLX";"Stimate trainer,\n' +
  'Ati fost nominalizat ca si Back-up Trainer de catre [TRAINER] pentru Live Online Training [TITLE] din data de [DATE] la ora [TIME]. Aveti un overview al Live Online Trainings in CMS.\n' +
  'Va multumim,\n' +
  'Echipa CLX";"Dragi treneru,\n' +
  'Nominovani ste kao Back-up Trener od strane [TRAINER] za Live Online Training [TITLE] za [DATE] u [TIME]. Obuku uživo na mreži ćete videti u pregledu gde su navedene sve sesije u CMS-u.\n' +
  'Srdačan pozdrav\n' +
  'Vaš CLX tim";"Milý tréner,\n' +
  'boli ste menovaný ako náhradný tréner za trénera [TRAINER] pre Live Online Training [TITLE] na [DATE] o [TIME]. Tento Live Online Training budete vidieť v prehľade, v ktorom sú všetky termíny uvedené v CMS.\n' +
  'S pozdravom,\n' +
  'váš CLX team";"Dragi trener,\n' +
  '[TRAINER] vas je imenoval za rezervnega trenerja za spletni trening v živo [TITLE] za [DATE] ob [TIME]. Spletno usposabljanje v živo boste videli v pregledu, kjer so vse seje navedene v CMS.\n' +
  'Prijazni pozdrav,\n' +
  'Vaša ekipa CLX"\n' +
  'LOTReminder;"Sehr geehrte_r Teilnehmer_in,\n' +
  'dies ist eine freundliche Erinnerung, dass Ihre nächste Veranstaltung [TITLE] in 30 Minuten beginnt. Bitte stellen Sie sicher, dass Sie pünktlich zum Start erscheinen, damit Sie nichts verpassen. Vielen Dank und viel Spaß!\n' +
  'Mit freundlichen Grüssen,\n' +
  'Ihr CLX-Team";"Cher participant,\n' +
  'Ceci est un rappel pour votre prochaine formation [TITLE] qui commence dans 30 minutes. Veuillez vous assurer de vous joindre à la session à temps pour ne rien manquer et de contrôler vos connections. Merci et bon travail !\n' +
  'Salutations amicales,";"Beste deelnemer,\n' +
  'Dit is een vriendelijke herinnering dat uw volgende evenement [TITLE] over 30 minuten begint. Zorg ervoor dat u op tijd deelneemt aan de sessie, zodat u niets mist. Bedankt en geniet!\n' +
  'Vriendelijke groeten\n' +
  'Uw CLX-team";"Dear participant,\n' +
  "This is a friendly reminder that your next event [TITLE] starts in 30 minutes. Please make sure to join the session on time so you don't miss out on anything. Thank you and enjoy!\n" +
  'Kind regards,\n' +
  'Your CLX team\n' +
  '";;"Szanowny uczestniku,\n' +
  'uprzejmie przypominamy, że Twoje następne wydarzenie [TITLE] rozpocznie się za 30 minut. Pamiętaj, aby dołączyć do sesji online na czas, aby niczego nie przegapić. Dziękuję i miłej zabawy!\n' +
  'Z poważaniem,\n' +
  'Twój zespół CLX ";"Querido participante.\n' +
  'Esto es un recordatorio conforme tu próxima formación [TITLE] comienza en 30 minutos. Por favor, acude a la sesión de forma puntual para no perderte nada. ¡Esperamos que la disfrutes!\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";"Detta är en vänlig påminnelse om att ditt nästa event [TITLE] startar om 30 minuter. Vänligen anslutet till sessionen i tid så du inte missar något. Tack på förhand och ha så kul.\n' +
  'Med vänlig hälsning, ditt CLX team";"Buongiorno,\n' +
  'ti ricordiamo che la sessione [TITLE] inizierà tra 30 minuti. Ti preghiamo di accedere in orario per non perdere nulla.\n' +
  'Grazie e buon divertimento!\n' +
  'Il team CLX";"Kære deltager\n' +
  'Her en venlig reminder om, at din næste event xxx (navn) starter om 30 minutter. Sørg for at logge på i god tid, så du ikke misser noget. Tak.\n' +
  'Bedste hilsener,\n' +
  'CLX teamet";"Шановні учасники,\n' +
  'дозвольте нагадати, що ваш наступний навчальний модуль [TITLE] розпочинається за 30 хвилин. Будь ласка, не забудьте вчасно приєднатися, щоб нічого не пропустити. Дякуємо і приємного навчання!\n' +
  'З повагою,\n' +
  'Ваша команда CLX";"Estimado participante,\n' +
  'Este es un recordatorio de que tu evento [TITLE} comienza en 30 minutos.\n' +
  'Por favor asegúrate de unirte a la sesión a tiempo, de tal manera que no pierdas ningún detalle. Muchas gracias y disfruta tu sesión.\n' +
  'Saludos cordiales,\n' +
  'Tu equipo CLX";;"Kedves résztvevő,\n' +
  'Ez egy emlékeztetés a következő eseményedhez [TITLE], ami 30 perc múlva kezdődik. Kérlek, győződj meg arról, hogy tudsz-e csatlakozni időben, működik-e a technika, és így nem maradsz le semmilyen fontos információról.\n' +
  'Köszönöm, és találkozunk online,\n' +
  'Karé Emőke";"Уважаемый участник,\n' +
  'Напоминаем Вам, что следующее мероприятие [TITLE] начнётся через 30 минут. Пожалуйста, подключайтесь вовремя, чтобы ничего не пропустить. Благодарим Вас и желаем Вам получить удовольствие от мероприятия!\n' +
  'С наилучшими пожеланиями,\n' +
  'Ваша команда CLX";"Уважаеми участници,\n' +
  'Това е напомнящо съобщение, че Вашето следващо събитие [TITLE] започва след 30 минути. Моля присъединете се към сесията навреме, за да не изпуснете нищо. Благодарим и пожелаваме успех!\n' +
  'С уважение,\n' +
  'Вашият CLX екип";"Vážený účastníku,\n' +
  'tímto Vám připomínáme, že Vaše další online akce [TITLE] začíná za 30 minut. Dbejte na to, abyste se na online školení dostavili včas a o nic nepřišli. Děkujeme a přejeme příjemnou zábavu!\n' +
  'S pozdravem,\n' +
  'Váš tým CLX";"Αγαπητέ συμμετέχοντα\n' +
  'Αυτή είναι μία φιλική υπενθύμιση ότι το επόμενο σας event [TITLE] ξεκινάει σε 30 λεπτά. Παρακαλώ όπως συμμετέχετε στην προβλεπόμενη ώρα στη συνεδρία ώστε  να μην χάσετε τίποτα. Σας ευχαριστούμε και σας ευχόμαστε να το απολαύσετε!\n' +
  'Με φιλικούς χαιρετισμούς,\n' +
  'Η ομάδα σας του CLX ";"Estimado participante,\n' +
  'Este é um rápido lembrete de que seu próximo evento [TITLE] começa em 30 minutos. Certifique-se de entrar na sessão a tempo para não perder nada. Obrigado e divirta-se!\n' +
  'Cumprimentos,\n' +
  'Sua equipa CLX";"Dragi participanti,\n' +
  'Acesta este un friendly reminder.  Urmatorul eveniment [TITLE] incepe in 30 de minute. Va rugam sa va asigurati ca accesati sesiunea live la timp, astfel incat sa nu pierdeti continutul trainingului.\n' +
  'Va multumim,\n' +
  'Echipa CLX";;"Milý účastník,\n' +
  'toto je priateľské upozornenie, že vasa nasledujúca tréningová lekcia [TITLE] sa začína o 30 minút. Prosím zabezpečte si program tak, aby ste sa na tréningu zúčastnili od začiatku a nič nezmeškali. Ďakujeme za pochopenie, užite si tréning!\n' +
  '\n' +
  'So srdečným pozdravom,\n' +
  'Váš CLX team ";"Spoštovani udeleženec,\n' +
  'To je prijazen opomnik, da se vaš naslednji dogodek [TITLE] začne čez 30 minut. Prosimo, da se pravočasno pridružite seji, da ne boste ničesar zamudili. Hvala in uživaj!\n' +
  'Prijazni pozdravi,\n' +
  'Vaša ekipa CLX"\n';
