import { useQuery } from 'react-query';
import { client } from '../../ApiHandler/client';

export type FilterParams = {
  category?: 'users' | 'views' | 'results';
  channels: number[];
  from?: string;
  to?: string;
};

const transformToRequestFilter = ({ channels, from, to }: Omit<FilterParams, 'category'>) => {
  return {
    channels: channels || [0],
    function: 'internal',
    from: from,
    to: to,
  };
};

const getDashboardData = async ({ category, channels, from, to }: FilterParams) => {
  const { data } = await client.post('/reports', {
    categories: [category],
    filters: transformToRequestFilter({
      channels,
      from,
      to,
    }),
  });
  return data[0].items;
};

/** Hook to access the loaded dashboard date */
const useDashboardData = ({ category, channels, from, to }: FilterParams) => {
  return useQuery(['dashboardData', category, channels, from, to], () =>
    getDashboardData({
      category,
      channels,
      from,
      to,
    })
  );
};

export { useDashboardData, transformToRequestFilter };
