import { parseCsvString, RowParser, SetResult, UploadCsv } from '../../utils/csvParser/UploadCsv';
import { useLoad, useNotify } from '../../utils/hooks';
import React, { useState } from 'react';
import { LabelButton, Text } from '@pp-labs/ui-components';
import { client } from '../../ApiHandler/client';
import { DownloadCsv } from '../../utils/csvParser/DownloadCsv';
import { ExampleTemplate } from './MailTemplates/exampleTemplate';
import strings from 'Localization/Localizer';

const getHtmlBody = (entry: string) => {
  const html = entry.replaceAll('\n\n', '<p/>').replaceAll('\n', '<br/>');
  return `<html>
    <head>
        <style type="text/css">
            p {
                font-family: Arial, Helvetica, sans-serif;
            }
        </style>
    </head>
    <body>
      ${html}
    </body>
  </html>`;
};

type MailTemplate = {
  templateId: string;
  subject: string;
  body: string;
  bodyType: 'html';
};

type AllTemplates = {
  [key: string]: MailTemplate[];
};

const defaultSubject = 'Your Event';

const getBody = (s: string) =>
  s
    .split('<body>')[1]
    .split('</body>')[0]
    .replaceAll('\n', '')
    .replaceAll('<p/>', '\n\n')
    .replaceAll('<br/>', '\n')
    .replaceAll('  ', ' ')
    .replace('   ', '');

const staticProps = { constantLineLength: true, headerLine: 0, additionalHeaderLine: 1 };

const generateTemplateCsv = (templates: MailTemplate[]) => {
  const header = [''];
  const subjects = ['Subject'];
  const sortedTemplates: AllTemplates = {};
  templates.forEach((t) => {
    const s = t.templateId.split('_');
    const key = s[0];
    const lang = s[1];
    if (!header.includes(lang)) {
      header.push(lang);
      subjects.push(t.subject || defaultSubject);
    }
    if (sortedTemplates[key]) {
      sortedTemplates[key].push(t);
    } else {
      sortedTemplates[key] = [t];
    }
  });
  const rows = Object.keys(sortedTemplates).map((key) => {
    const ts = sortedTemplates[key];
    const row = header.map((lang) => {
      const f = ts.find((t) => t.templateId.split('_')[1] === lang);
      return f ? getBody(f.body) : '';
    });
    row[0] = key;
    return row;
  });
  return [header, subjects, ...rows];
};

/** Allows to upload mail templates */
export const UploadMailTemplates = () => {
  const [json, setJson] = useState<MailTemplate[]>([]);
  const [templates, refreshTemplates] = useLoad<MailTemplate[]>('templates/mail');
  const notify = useNotify();
  const rowParser: RowParser<MailTemplate[]> = (lineNumber, row, header, additionalHeader) => {
    if (!header) throw new Error(`Header in line 1 not found`);
    if (!additionalHeader) throw new Error(`Subjects not found in line 2`);
    if (!row[0]) throw new Error(`No template key provided in line ${lineNumber}`);
    return header.slice(1).map((lang, i) => {
      if (!lang) throw new Error(`No lang found in column ${i + 1}`);
      return {
        templateId: `${row[0]}_${lang}`,
        subject: additionalHeader[i + 1] || defaultSubject,
        body: getHtmlBody(row[i + 1]),
        bodyType: 'html',
      } as MailTemplate;
    });
  };

  const setResult: SetResult<MailTemplate[]> = (rows) => setJson(rows.flat());
  const submit = async () => {
    await client.post(`templates/mail`, json);
    notify('Successfully submitted mail templates', 'success');
    refreshTemplates();
  };

  const loadData = async () => {
    if (!templates) return null;
    if (templates.length) {
      return generateTemplateCsv(templates);
    } else {
      const res = parseCsvString(ExampleTemplate, { ...staticProps, rowParser: rowParser });
      return generateTemplateCsv(res.flat());
    }
  };

  if (!templates) return null;

  return (
    <div>
      <Text as="h3" variant="order2" spaceStackStart="xl">
        {strings.emailCommunication}
      </Text>
      <UploadCsv
        rowParser={rowParser}
        setResult={setResult}
        onFileChange={() => setJson([])}
        {...staticProps}
      />
      <LabelButton variant="primary" onClick={submit} disabled={!json.length} spaceInlineEnd="xs">
        Submit Mail Templates
      </LabelButton>
      <DownloadCsv
        loadData={loadData}
        fileName={templates?.length ? 'mailTemplates.csv' : 'exampleTemplates.csv'}
      />
    </div>
  );
};
