import { Text } from '@pp-labs/ui-components';
import { LabelButton, tableStyles } from '@pp-labs/ui-components';
import React, { useEffect, useState } from 'react';
import { client } from '../../../ApiHandler/client';
import { Channel, ChannelTopic } from 'ApiHandler/dclxInterfaces';
import EditChannel from './ChannelForm';
import strings from 'Localization/Localizer';
import { NotCreatedPlaceholder } from '../Sessions/NotCreatedPlaceholder';
import { ChannelTable } from './ChannelTable';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  ...tableStyles(),
});

/**
 * Main Component responsible for Editing the Channels and their Topics
 */

const ChannelSettings = () => {
  const [openChannelForm, setOpenChannelForm] = useState<boolean>(false);
  const [channels, setChannels] = useState<Channel[] | null>(null);
  const [topics, setTopics] = useState<ChannelTopic[] | null>(null);

  const refresh = async () => {
    const cdata: Channel[] = (await client.get(`channels`)).data;
    setChannels(cdata.sort((a, b) => a.categoryUniqueName.localeCompare(b.categoryUniqueName)));

    const tdata = (await client.get(`topics`)).data;
    setTopics(tdata);
  };

  useEffect(() => {
    refresh();
  }, []);
  const cls = useStyles();
  return (
    <div>
      <Text as="h2" variant="order2">
        <b>{strings.channels}</b>
      </Text>
      {!!channels?.length ? (
        <div style={{ marginTop: '48px' }}>
          {channels.map((c, i) => (
            <ChannelTable key={i} channel={c} topics={topics} refresh={refresh} />
          ))}
        </div>
      ) : (
        <NotCreatedPlaceholder text={strings.noChannel} />
      )}
      <LabelButton
        variant="primary"
        className={cls.btnLeft}
        onClick={() => {
          setOpenChannelForm(true);
        }}
      >
        {strings.addChannel}
      </LabelButton>

      <EditChannel
        open={openChannelForm}
        channel={null}
        close={() => {
          setOpenChannelForm(false);
          refresh();
        }}
        duplicate={false}
      />
    </div>
  );
};

export { ChannelSettings };
