import ReactPlayer from 'react-player/lazy';
import React, { Component } from 'react';
import screenfull from 'screenfull';
import ReactDOM from 'react-dom';
import { playerConfig } from './convert';
import VideoControls from './VideoControls';
import '../features/Sessions/Watch/VideoPlayer/VideoPlayer.scss';
import { defaultVolume } from '../config';

/** interface for SimpleVideoPlayer props coming from parent component AmaWrapper, ExplainerVideo */
interface P {
  url: string;
}

/** interface for SimpleVideoPlayer component state  */
interface S {
  playing: boolean;
  controls: boolean;
  volume: number;
  muted: boolean;
  played: number;
  playedSeconds: number;
  loaded: number;
  duration: number;
  seeking: boolean;
  fullscreen: boolean;
}

/**
 * Basic VideoPlayer
 */

class SimpleVideoPlayer extends Component<P, S> {
  timeout: any;
  player: any;
  wrapper: any;

  constructor(props: P) {
    super(props);
    this.state = {
      playing: false,
      controls: true,
      volume: defaultVolume,
      muted: false,
      played: 0,
      playedSeconds: 0,
      loaded: 0,
      duration: 0,
      seeking: false,
      fullscreen: false,
    };
    this.timeout = null;
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.load();
    this.addFullscreenEventListeners();
  }

  componentWillUnmount() {
    this.handlePause();
    clearTimeout(this.timeout);
  }

  load = () => {
    this.setState({
      played: 0,
      loaded: 0,
    });
  };

  handleControls = () => {
    clearTimeout(this.timeout);
    this.enableControls();
    this.timeout = setTimeout(() => {
      this.disableControls();
    }, 3000);
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };

  handleVolumeChange = (e: any) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e: any) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e: any) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (current: any) => {
    if (!this.state.seeking) {
      this.setState(current);
    }
  };

  handleDuration = (duration: any) => {
    this.setState({ duration });
  };

  enableControls = () => {
    this.setState({ controls: true });
  };

  disableControls = () => {
    this.setState({ controls: false });
  };

  ref = (player: any) => {
    this.player = player;
  };

  addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', this.handleEsc);
    document.addEventListener('mozfullscreenchange', this.handleEsc);
    document.addEventListener('fullscreenchange', this.handleEsc);
    document.addEventListener('MSFullscreenChange', this.handleEsc);
  };

  handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      this.exitFullscreen();
    }
  };

  exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    this.setState({ fullscreen: false });
  };

  toggleFullscreen = () => {
    if (this.state.fullscreen) {
      this.exitFullscreen();
    } else {
      this.setState({
        fullscreen: true,
      });
      /* @ts-ignore*/
      screenfull.request(
        /* eslint-disable-next-line react/no-find-dom-node */
        ReactDOM.findDOMNode(this.wrapper.current)
      );
    }
  };

  render() {
    const { playing, volume, muted, duration, played, seeking } = this.state;
    const { url } = this.props;
    return (
      <div className="player-wrapper" onMouseMove={this.handleControls} ref={this.wrapper}>
        <ReactPlayer
          config={playerConfig}
          ref={this.ref}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          pip={false}
          playing={playing}
          controls={false}
          light={false}
          loop={false}
          playbackRate={1}
          progressInterval={250}
          volume={volume}
          muted={muted}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
        <VideoControls
          playerMode="full"
          visible={this.state.controls}
          duration={duration}
          played={played}
          muted={muted}
          playing={playing && !seeking}
          pause={this.handlePause}
          play={this.handlePlay}
          seekDown={this.handleSeekMouseDown}
          seekUp={this.handleSeekMouseUp}
          seekChange={this.handleSeekChange}
          setMuted={(isMuted: boolean) => this.setState({ muted: isMuted })}
          fullscreen={this.state.fullscreen}
          toggleFullscreen={this.toggleFullscreen}
          volume={this.state.volume}
          setVolume={(volumeValue: number) => this.setState({ volume: volumeValue })}
        />
      </div>
    );
  }
}

export default SimpleVideoPlayer;
