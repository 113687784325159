import { useLoad } from '../../utils/hooks';
import {
  GetSurveyTemplate,
  GetSurveyTemplateItem,
  GetUserSurvey,
  GetUserSurveyItem,
  jsonToSurveyText,
  SurveyType,
} from '@pp-labs/survey';

import { LabelButton } from '@pp-labs/ui-components';
import { format } from 'date-fns';
import { UserPicture } from 'ApiHandler/dclxInterfaces';
import { getLocalizedValue } from 'Localization/Localizer';

export type MultiLanguageString = {
  lang: string;
  value: string;
};
const getDefaultLang = (t?: MultiLanguageString[]): string => {
  if (!t) return '';
  // for some reason this seems to be already parsed

  return (
    t?.find((i) => i.lang === 'English')?.value ||
    t?.[0]?.value ||
    t?.find((i) => i.value)?.value ||
    ''
  );
};

const parsePlainLangJson = (text?: string) => {
  if (!text) return '';
  try {
    return getDefaultLang(JSON.parse(text));
  } catch {
    return '';
  }
};

const getCreation = (userSurvey: GetUserSurvey) => {
  try {
    const date = new Date(userSurvey.createdAt);
    return [format(date, 'dd.MM.yyyy'), format(date, 'HH:mm')];
  } catch (e) {
    console.error(e);
    return ['', ''];
  }
};

const cycleThroughSurvey = (
  surveyTemplates: GetSurveyTemplate[],
  surveyData: GetUserSurvey[],
  users: UserPicture[],
  type: SurveyType,
  parser: Parser
) => {
  return surveyTemplates
    .map((surveyTemplate) => {
      const matchingUserSurveys = surveyData.filter(
        (s) => s.surveyTemplateId === surveyTemplate.surveyTemplateId
      );
      const matchingItems = surveyTemplate.templateItems.filter((t) => t.surveyType === type);
      return matchingUserSurveys
        .map((userSurvey) => {
          const user = users.find((u) => userSurvey.username === u.username);

          let customFields = {};
          try {
            customFields = JSON.parse(user?.customFields || '');
          } catch (error) {}

          const csvEntries: string[][] = [];
          userSurvey.surveyItems.forEach((userSurveyItem) => {
            const surveyTemplateItem = matchingItems.find(
              (item) => item.surveyTemplateItemId === userSurveyItem.surveyTemplateItemId
            );
            if (!surveyTemplateItem) return;
            const parent1 = surveyTemplate.templateItems.find(
              (i) => i.surveyTemplateItemId === surveyTemplateItem.indentParent
            );
            const parent2 = surveyTemplate.templateItems.find(
              (i) => i.surveyTemplateItemId === parent1?.indentParent
            );
            parser(
              csvEntries,
              surveyTemplate,
              surveyTemplateItem,
              userSurvey,
              userSurveyItem,
              user,
              parent2,
              parent1,
              customFields
            );
          });
          return csvEntries;
        })
        .flat();
    })
    .flat();
};

type Parser = (
  csvEntries: unknown[][],
  surveyTemplate: GetSurveyTemplate,
  surveyTemplateItem: GetSurveyTemplateItem,
  userSurvey: GetUserSurvey,
  userSurveyItem: GetUserSurveyItem,
  user?: UserPicture,
  parent2?: GetSurveyTemplateItem,
  parent1?: GetSurveyTemplateItem,
  customFields?: { [key: string]: string }
) => void;

const baseHeaderStart = [
  'Context / Session',
  'Date',
  'Time',
  'Survey',
  'Headline 1',
  'Headline 2',
  'Question',
  'Answer',
];
const baseHeaderEnd = ['Username', 'Email', 'First Name', 'Last Name'];
const defaultHeader = [...baseHeaderStart, ...baseHeaderEnd];
const mcHeader = [...baseHeaderStart, 'Checked', ...baseHeaderEnd];

const standardParser: Parser = (
  csvEntries,
  surveyTemplate,
  surveyTemplateItem,
  userSurvey,
  userSurveyItem,
  user,
  parent2,
  parent1,
  customFields
) => {
  if (!userSurveyItem.surveyValue) return;
  const [date, time] = getCreation(userSurvey);
  const headline1 = parsePlainLangJson(parent2?.surveyText);
  const headline2 = parsePlainLangJson(parent1?.surveyText);
  csvEntries.push([
    getLocalizedValue(userSurvey.contextTitle) || '',
    date,
    time,
    parsePlainLangJson(surveyTemplate.title),
    headline1.length === 0 ? headline2 : headline1,
    headline1.length === 0 ? '' : headline2,
    parsePlainLangJson(surveyTemplateItem.surveyText),
    userSurveyItem.surveyValue,
    user?.cognitoUsername || '',
    user?.email || '',
    user?.givenName || '',
    user?.familyName || '',
    ...(customFields ? Object.entries(customFields).map(([key, value]) => value || '') : []),
  ]);
};

const mcAnswerParser: Parser = (
  csvEntries,
  surveyTemplate,
  surveyTemplateItem,
  userSurvey,
  userSurveyItem,
  user,
  parent2,
  parent1,
  customFields
) => {
  const mcText = jsonToSurveyText(surveyTemplateItem.surveyText);
  if (!mcText) return;
  const userAnswers = JSON.parse(userSurveyItem.surveyValue) as number[];
  const [date, time] = getCreation(userSurvey);
  mcText.answers.forEach((a, i) => {
    const checked = userAnswers.includes(i);
    const headline1 = parsePlainLangJson(parent2?.surveyText);
    const headline2 = parsePlainLangJson(parent1?.surveyText);
    csvEntries.push([
      getLocalizedValue(userSurvey.contextTitle) || '',
      date,
      time,
      parsePlainLangJson(surveyTemplate.title),
      headline1.length === 0 ? headline2 : headline1,
      headline1.length === 0 ? '' : headline2,
      getDefaultLang(mcText.text),
      getDefaultLang(a),
      checked ? 'yes' : 'no',
      user?.cognitoUsername || '',
      user?.email || '',
      user?.givenName || '',
      user?.familyName || '',
      ...(customFields ? Object.entries(customFields).map(([key, value]) => value || '') : []),
    ]);
  });
};

export const DownloadSurveys = () => {
  const [surveyTemplates] = useLoad<GetSurveyTemplate[]>('surveys/templates');
  const [surveyData] = useLoad<GetUserSurvey[]>('surveys');
  const [users] = useLoad<UserPicture[]>('users/all');

  let customFields = {};
  const user = users?.[0];
  try {
    customFields = JSON.parse(user?.customFields || '');
  } catch (error) {}

  const downloadClick = () => {
    if (!surveyTemplates || !surveyData || !users) return null;
    const common = [surveyTemplates, surveyData, users] as const;
    download(
      parseCsv([
        [...defaultHeader, ...Object.keys(customFields)],
        ...cycleThroughSurvey(...common, 'rating', standardParser),
      ]),
      'survey_ratings.csv'
    );
    download(
      parseCsv([
        [...defaultHeader, ...Object.keys(customFields)],
        ...cycleThroughSurvey(...common, 'text', standardParser),
      ]),
      'survey_answers.csv'
    );
    download(
      parseCsv([
        [...mcHeader, ...Object.keys(customFields)],
        ...cycleThroughSurvey(...common, 'mc', mcAnswerParser),
      ]),
      'survey_mc.csv'
    );
  };
  return (
    <LabelButton variant="primary" onClick={downloadClick} spaceInlineStart="xxl">
      Download Survey Results
    </LabelButton>
  );
};
export const download = (content: string, filename: string) => {
  //const encodedUri = encodeURI(content);
  const file = new Blob([content], {
    type: 'text/csv',
  });
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(file));
  link.setAttribute('download', filename);
  link.click();
};

const NewLine = '\n';
const NewCell = ';';
const Escape = '"';

export const parseCsv = (data: unknown[][]) => {
  const csv = data
    .map((e) => e.map((v) => Escape + (v as string).replaceAll('"', '""') + Escape).join(NewCell))
    .join(NewLine);
  return csv;
};
