import React, { useState } from 'react';
import { EditDialog } from '@pp-labs/ui-components';
import { useTexts } from '../../../utils/hooks';
import { EditorParser } from '../../../features/Editor/EditorParser';
import AudiSpacer from '../../../utils/AudiSpacer';

/** interface for DisclaimerDialogue props coming from parent components Live, EventWatch and Watch  */
interface P {
  type: 'demand' | 'live';
}

/**
 * Dialogue for displaying long info texts
 */

export const DisclaimerDialogue = (props: P) => {
  const { getText } = useTexts();
  const [opened, setOpened] = useState(true);
  const closeExp = () => {
    setOpened(false);
  };
  const text = getText(props.type === 'demand' ? 'onDemandDisclaimer' : 'liveDisclaimer');
  if (!opened || !text) return null;
  return (
    <EditDialog title="" close={closeExp} maxWidth={'md'}>
      <EditorParser inputString={text} />
      <AudiSpacer spaceStackStart="m" />
    </EditDialog>
  );
};
