import React, { Component } from 'react';
import { AMA } from '../../../../ApiHandler/dclxInterfaces';
import { Text, LayoutItem } from '@pp-labs/ui-components';
import { createStyles, IconButton, withStyles } from '@material-ui/core';
import { client } from '../../../../ApiHandler/client';
import { getLocalizedValue } from 'Localization/Localizer';
import { UpOutlineWrapper, UpFilledWrapper } from '@pp-labs/ui-components';

const styles = () =>
  createStyles({
    questionWrapper: {
      display: 'inline-block',
      width: 'calc(100% - 100px)',
      // display: 'table-cell',
    },
    ratingWrapper: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      width: '100px',
      // paddingTop: '30px',
      textAlign: 'right',
      alignItems: 'flex-end',
      // display: 'table-cell',
      // verticalAlign: 'bottom',
    },
    inline: {
      display: 'inline-block',
    },
    row: {
      // display: 'table-row',
      // paddingBottom: '5px',
      // paddingTop: '20px',
      borderBottom: '1px solid black',
    },
    // table: {
    //   display: 'table',
    //   borderCollapse: 'collapse',
    // },
    // innerRow: {
    //   width: '100%',
    //   paddingTop: '30px',
    // },
  });

interface Classes {
  questionWrapper: string;
  ratingWrapper: string;
  inline: string;
  // table: string,
  row: string;
  // innerRow: string,
}

/** interface for Overview props coming from parent components LotAskedQuestions, AskMeAnything */
interface P {
  questions: Array<AMA>;
  classes: Classes;
  refresh: Function;
  noInteract?: boolean;
  smallText?: boolean;
}

/**
 * View CLX-like-AMAs others have asked
 */

class Overview extends Component<P> {
  vote = async (ama: AMA) => {
    await client.put(`sessions/amas/${ama.supportQuestionId}/vote`);
    this.props.refresh();
  };

  render() {
    return (
      <div>
        {this.props.questions.map((q, index) => {
          return (
            <LayoutItem key={index} spaceStackEnd={'l'}>
              <div className={this.props.classes.row}>
                <LayoutItem spaceStackEnd={'s'}>
                  <div className={this.props.classes.questionWrapper}>
                    <Text as="p" variant={this.props.smallText ? 'copy2' : 'copy1'}>
                      {getLocalizedValue(q.question)}
                    </Text>
                  </div>
                  <div className={this.props.classes.ratingWrapper}>
                    <div className={this.props.classes.inline}>
                      <Text as="p" variant="copy1">
                        {q.votes}
                      </Text>
                    </div>

                    {!this.props.noInteract && (
                      <div className={this.props.classes.inline}>
                        <IconButton onClick={() => this.vote(q)} style={{ padding: '0 0 0 11px' }}>
                          {q.selfVoted ? <UpFilledWrapper /> : <UpOutlineWrapper />}
                        </IconButton>
                      </div>
                    )}
                  </div>
                </LayoutItem>
              </div>
            </LayoutItem>
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(Overview);
