import { useEffect, useState } from 'react';
import { standardFormat } from '../../../config';
import { client } from '../../../ApiHandler/client';
import { Text } from '@audi/audi-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import { gutterStyle } from '../../../utils/gutter';
import { format, fromUnixTime } from 'date-fns';
import { GetAma } from '../../../ApiHandler/dclxInterfaces';
import { useParams } from 'react-router-dom';
import strings from '../../../Localization/Localizer';

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  normalHead: {
    fontSize: '40px',
    lineHeight: '60px',
    fontWeight: 'bold',
  },
  bigHead: {
    fontSize: '60px',
    lineHeight: '90px',
    fontWeight: 'bold',
  },
  redHead: {
    fontSize: '40px',
    lineHeight: '60px',
    fontWeight: 'bold',
    color: '#bb0a30',
  },
}));

/** View a specific ama in a new window */
export const AmaView = () => {
  const { id } = useParams<{ id: string }>();
  const [ama, setAma] = useState<GetAma | undefined>(undefined);
  const cls = useStyles();

  useEffect(() => {
    const fetchAma = async () => {
      const amaValue: GetAma = (await client.get(`sessions/vodAmas/${id}`)).data;
      setAma(amaValue);
    };
    fetchAma();
  }, [id]);

  if (!ama) return null;
  return (
    <div className={cls.gutterAll}>
      <div style={{ padding: '80px 0' }}>
        <Text variant="order1" className={cls.bigHead}>
          {ama.question}
        </Text>
      </div>
      <div>
        <Text variant="order3" className={cls.redHead}>
          {strings.sent} {format(fromUnixTime(ama.sentAt), standardFormat)}
        </Text>
      </div>
    </div>
  );
};
