import { makeStyles } from '@material-ui/core';
import { Text, GroupPeopleIcon, LiveIcon, SessionPlayIcon } from '@pp-labs/ui-components';
import { Session, SessionType, TimeSlot } from 'ApiHandler/dclxInterfaces';
import clsx from 'clsx';
import { env } from 'config';
import strings from 'Localization/Localizer';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useEventSettings, useRealTime } from 'utils/hooks';

const useStyles = makeStyles({
  redDot: {
    height: '12px',
    width: '12px',
    backgroundColor: '#F50537',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
  },
  absoluteText: {
    position: 'absolute',
    right: '20px',
    background: '#000000ba',
    padding: '3px 12px',
    bottom: '8px',
    zIndex: 100,
  },
  liveText: {
    display: 'flex',
    alignItems: 'center',
  },
  whiteText: {
    color: '#ffffff',
  },
});

type P = {
  session: Session;
  timeSlot?: TimeSlot;
  locked?: boolean;
};

/** Session Overlay displaying various info */
const SessionsOverlay = (props: P) => {
  const currentTime = useRealTime(3000);
  const cls = useStyles();
  const sessionType = useMemo(() => {
    if (props.session.sessionType === SessionType.TRAINING) return 'lot';
    if (
      props.session.sessionType === SessionType.DEMAND ||
      props.session.sessionType === SessionType.EXTERNAL
    )
      return 'onDemand';
    if (
      props.session.sessionType === SessionType.EXTERNALTERMINATED ||
      SessionType.LIVETERMINATED ||
      SessionType.LIVE
    )
      return 'live';
  }, [props.session.sessionType]);

  const getUnlock = (sessionEntry: Session, timeSlot?: TimeSlot) => {
    if (sessionEntry.unlockAt) return sessionEntry.unlockAt;
    if (timeSlot) return timeSlot.startAt - 15 * 60;
    const drift = [SessionType.EXTERNAL, SessionType.LIVE].includes(sessionEntry.sessionType) ? 15 : 0;
    return (sessionEntry.startAt || 0) - drift * 60;
  };
  const unlockAt = getUnlock(props.session, props.timeSlot);
  const live = unlockAt * 1000 < currentTime;
  const event = useEventSettings()?.identifier || '';
  if (sessionType === 'onDemand') {
    return (
      <div>
        {!props.locked ? (
          <NavLink to={`/${event}/${sessionType}/${props.session.sessionId}`}>
            <div className="overlay">
              <SessionPlayIcon />
            </div>
          </NavLink>
        ) : (
          <div>
            <div className={cls.absoluteText}>
              <Text variant="copy2" className={cls.whiteText}>
                {strings.contentLocked}
              </Text>
            </div>
          </div>
        )}
      </div>
    );
  }
  if (sessionType === 'live') {
    return (
      <div>
        {live ? (
          <NavLink to={`/${event}/${sessionType}/${props.session.sessionId}`}>
            <div className="overlay">
              <LiveIcon />
            </div>
            <div className={clsx(cls.liveText, cls.absoluteText)}>
              <span className={cls.redDot}></span>
              <Text variant="copy2" className={cls.whiteText} spaceInlineStart="s">
                {strings.nowLive}
              </Text>
            </div>
          </NavLink>
        ) : (
          <div>
            <div className={cls.absoluteText}>
              <Text variant="copy2" className={cls.whiteText}>
                {strings.startsIn}
              </Text>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (sessionType === 'lot') {
    if (!props.timeSlot) return null;
    if (env() !== 'dev' && !live) return null;
    return (
      <div>
        {live ? (
          <NavLink to={`/${event}/${sessionType}/${props.timeSlot.trainingId}`}>
            <div className="overlay">
              <GroupPeopleIcon />
            </div>
            <div className={clsx(cls.liveText, cls.absoluteText)}>
              <span className={cls.redDot}></span>
              <Text variant="copy2" className={cls.whiteText} spaceInlineStart="s">
                {strings.running}
              </Text>
            </div>
          </NavLink>
        ) : (
          <div></div>
        )}
      </div>
    );
  } else return null;
};

export default SessionsOverlay;
