import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { MediaOverlay } from './MediaOverlay';
import { client } from 'ApiHandler/client';
import { Session } from 'ApiHandler/dclxInterfaces';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';

/** interface for WaitingOverlay props coming from parent component LOT  */
interface P {
  runningState: number;
  videoHeight: number;
  earlyAccess: boolean;
  session: Session;
}

interface StyleProps {
  height: number;
}
const useStyles = makeStyles((theme) => ({
  overlayText: {
    color: 'inherit',
    position: 'relative',
    top: '50%',
  },
  wrapper: (props: StyleProps) => ({
    height: props.height,
    position: 'absolute',
    top: 0,
    zIndex: 20,
    color: 'white',
    textAlign: 'center',
    width: '80%',
    [theme.breakpoints.down('xl')]: {
      width: '75%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '71%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
}));

/**
 * Overlay displaying while the training is not running
 */

export type Medium = { type: 'video' | 'image'; url: string };

/** Determines the appropriate LOT Lobby Media and displays them if necessary */
export const WaitingOverlay = (props: P) => {
  const cls = useStyles({ height: props.videoHeight });
  const [medium, setMedium] = useState<Medium | null>(null);
  useEffect(() => {
    const setLobbyMedia = async () => {
      if (props.session.video) {
        setMedium({ type: 'video', url: props.session.video.url });
      } else if (props.session.lobbyImage) {
        setMedium({ type: 'image', url: props.session.lobbyImage.url });
      } else {
        const defaults = (
          await client.get<{ lobbyImage?: MediaFile; lobbyVideo?: MediaFile }>(`/trainings/lobby`)
        ).data;
        if (defaults.lobbyVideo) {
          setMedium({ type: 'video', url: defaults.lobbyVideo?.url });
        } else if (defaults.lobbyImage) {
          setMedium({ type: 'image', url: defaults.lobbyImage?.url });
        }
      }
    };
    setLobbyMedia();
  }, [props.session]);
  if (props.runningState >= 2 || (props.runningState === 0 && props.earlyAccess)) {
    // training running
    return null;
  } else if (props.runningState === 0) {
    // training not started yet
    return (
      <div className={cls.wrapper}>
        {medium ? (
          <MediaOverlay medium={medium} height={props.videoHeight} />
        ) : (
          <Text variant={'copy1'} weight={'bold'} className={cls.overlayText}>
            {strings.waitingStartTraining}
          </Text>
        )}
      </div>
    );
  } else if (props.runningState === 1) {
    // training closed
    return (
      <div className={cls.wrapper} style={{ width: '100%', background: 'black' }}>
        <Text variant={'copy1'} weight={'bold'} className={cls.overlayText}>
          {strings.trainingClosed}
        </Text>
      </div>
    );
  } else {
    // should be -1, websocket not active
    return (
      <div className={cls.wrapper} style={{ width: '100%' }}>
        <Text variant={'copy1'} weight={'bold'} className={cls.overlayText}>
          {strings.connecting}...
        </Text>
      </div>
    );
  }
};
