import { Session, Stat } from '../../../ApiHandler/dclxInterfaces';
import { makeStyles } from '@material-ui/core';
import { AdaptedIcon } from '@pp-labs/ui-components';
import { useEffect, useState } from 'react';
import { isSessionWatched } from '../../../utils/convert';

/** interface for AlreadyDoneOverlay props coming from parent component DCLXSessionTiles  */
interface P {
  thisSession: Session;
  stats: Stat[];
}

const useStyles = makeStyles(() => ({
  adaptedPosition: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    height: 24,
    width: 24,
    fontSize: 24,
  },
}));

/** Overlay over sessions to display whether they are already done */
export const AlreadyDoneOverlay = (props: P) => {
  const [alreadyDone, setAlreadyDone] = useState<boolean>(false);
  const cls = useStyles();
  useEffect(() => {
    const isDone = async () => {
      const withQ = !!props.thisSession.interrupts?.length;
      if (withQ) {
        setAlreadyDone(!!props.stats.find((s) => s.sessionId === props.thisSession.sessionId));
      } else {
        setAlreadyDone(isSessionWatched(props.thisSession));
      }
    };
    isDone();
  }, [props.thisSession, props.stats]);
  if (!alreadyDone) return null;
  return (
    <div className={cls.adaptedPosition}>
      <AdaptedIcon />
    </div>
  );
};
