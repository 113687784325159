import cx from 'clsx';
import React from 'react';

const grey80 = '#333333';

type HorizontalBarProps = {
  className?: string;
  x: number;
  height: number;
  width: number;
  y: number;
  textInside?: number | string;
  textOutside?: number | string;
  color?: string;
  textColor?: string;
};

/** Horizontal svg bar, used for Polls */
function HorizontalBar({
  textInside,
  textOutside,
  className,
  x,
  height,
  width,
  y,
  color = grey80,
  textColor = grey80,
}: HorizontalBarProps) {
  return (
    <>
      {width > 0 && <circle fill={color} cy={y} cx={x + width - height / 2} r={height / 2} />}
      {width > 0 && (
        <rect
          className={cx(className)}
          fill={color}
          x={x}
          height={height}
          width={Math.max(0, width - height / 2)}
          y={y - height / 2}
        />
      )}
      {textInside && (
        <text
          fontFamily="AudiType"
          fontWeight={700}
          textAnchor={'end'}
          dx={'2px'}
          dy={'4px'}
          x={x + width - height / 2}
          y={y}
          fill="white"
          fontSize={'12px'}
        >
          {textInside}
        </text>
      )}
      {textOutside && (
        <text
          fontFamily="AudiType"
          fontWeight="bold"
          textAnchor={'start'}
          dy={'4px'}
          x={Math.max(x + width - height / 2, 0)}
          dx={'15px'}
          y={y}
          fill={textColor}
          fontSize={'12px'}
        >
          {textOutside}
        </text>
      )}
    </>
  );
}
export { HorizontalBar };
