import React, { useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { EditDialog, EditIcon, Text } from '@pp-labs/ui-components';

import ChangePasswordForm from '../../auth/ChangePasswordForm';
import headerImage from '../../media/fallbackHeader.jpg';
import profileImage from '../../media/fallbackProfile.png';
import { gutterStyle } from '../../utils/gutter';
import EditUserInfo from '../../DCLX/FE/Profile/EditUserInfo';
import { ConfigModuleIdentifiers, UserPicture } from '../../ApiHandler/dclxInterfaces';
import strings from '../../Localization/Localizer';
import { useCustomFields, useEventSettings, useLoad, useNamedConfigModule, useUser } from 'utils/hooks';
import { Gamification } from './Gamification/Gamification';
import clsx from 'clsx';
import { doesRoleMatch, hasAwards, Role } from '../../utils/RoleDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
  banner: {
    textAlign: 'center',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    height: '300px',
  },
  bannerEdit: {
    right: 32,
    top: 284,
  },
  cardThumb: {
    borderRadius: '50%',
    height: '300px',
    width: '300px',
    position: 'absolute',
    top: '150px',
    left: '50px',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  profileEdit: {
    left: 285,
    top: 385,
    zIndex: 100,
  },
  pictureContainer: {
    position: 'relative',
    paddingBottom: '180px',
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  profileContainer: {
    padding: 32,
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  badgesContainer: {
    padding: 32,
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  aboutMeContainer: {
    padding: 32,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  passwordContainer: {
    padding: 32,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: 48,
    width: 48,
    borderRadius: '50%',
    position: 'absolute',
    fontSize: 24,
    cursor: 'pointer',
  },
}));

/**
 * Displays the users profile, progress, etc.
 *
 * Note that the progress is actually missing in the design, but can easily be added as the two relevant numbers
 * are part of the Awards object.
 */
const Profile = () => {
  const user = useUser()!;
  const configModule = useNamedConfigModule();
  const [openUserInfo, setOpenUserInfo] = useState<'profile' | 'header' | null>(null);
  const eventSettings = useEventSettings();
  const [userData, refreshUserData] = useLoad<UserPicture>('users');
  const cls = useStyles();
  const closeUserForm = (refresh?: boolean) => {
    setOpenUserInfo(null);
    if (refresh) refreshUserData();
  };

  const hasProfile = useMemo(
    () => doesRoleMatch(user, [Role.VISITOR, Role.TRAINER, Role.MANAGER]),
    [user]
  );

  return (
    <>
      <div className={cls.pictureContainer}>
        <div
          style={{
            backgroundImage: `url(${
              userData?.header || eventSettings?.profileImage || headerImage
            })`,
          }}
          className={cls.banner}
        >
          {hasProfile && (
            <EditButton edit={() => setOpenUserInfo('header')} className={cls.bannerEdit} />
          )}
        </div>
        <div
          style={{
            backgroundImage: `url(${userData?.thumb || profileImage})`,
          }}
          className={cls.cardThumb}
        />

        {hasProfile && (
          <EditButton edit={() => setOpenUserInfo('profile')} className={cls.profileEdit} />
        )}
      </div>
      <div className={cls.rowContainer}>
        <ProfileInfo userData={userData} />
        <div className={cls.badgesContainer}>
          {doesRoleMatch(user, hasAwards) && configModule(ConfigModuleIdentifiers.gamification) && (
            <Gamification />
          )}
        </div>
        {hasProfile && (
          <div className={cls.aboutMeContainer}>
            <Text variant="order3" weight="bold">
              {strings.aboutMe}
            </Text>
            <EditUserInfo close={refreshUserData} user={userData} mode="aboutMe" />
          </div>
        )}
        <div className={cls.passwordContainer}>
          <Text variant="order3" weight="bold">
            {strings.changePassword}
          </Text>
          <ChangePasswordForm />
        </div>
      </div>
      {openUserInfo && (
        <EditDialog title={strings.editUserInfo} close={closeUserForm} maxWidth="md">
          <EditUserInfo user={userData} close={closeUserForm} mode={openUserInfo} />
        </EditDialog>
      )}
    </>
  );
};

const EditButton = (props: { edit: () => void; className: string }) => {
  const cls = useStyles();
  return (
    <div className={clsx(cls.editButton, props.className)} onClick={props.edit}>
      <EditIcon />
    </div>
  );
};

const ProfileInfo = ({ userData }: { userData: UserPicture | null }) => {
  const cls = useStyles();
  const customFields = useCustomFields(userData);
  return (
    <div className={cls.profileContainer}>
      <Text as="h2" variant="order2">
        {userData?.givenName} {userData?.familyName}
      </Text>
      <Text as="h2" variant="order4">
        {customFields['custom:jobFunction']}
      </Text>
      <Text as="h2" variant="copy1">
        {userData?.aboutMe || ''}
      </Text>
    </div>
  );
};

export default Profile;
