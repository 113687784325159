import { ReactComponent as GoogleAuthIcon } from '../media/GoogleAuthIcon.svg';
import { Text } from '@pp-labs/ui-components';
import { MfaBadge } from '../utils/StoreBadge';
import MicrosoftAuthIcon from '../media/MicrosoftAuthIcon.png';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import strings from 'Localization/Localizer';

const useStyles = makeStyles({
  spacer: {
    marginRight: 40,
  },
  typeBox: {
    display: 'flex',
    height: 80,
  },
  smallSpacer: {
    marginRight: 8,
  },
  inline: {
    display: 'inline-block',
    margin: 24,
  },
  mfaBadge: {
    margin: 4,
    display: 'inline-block',
  },
});

/** Download links/badges to different MFA Authenticators */
export const MfaBadges = ({ noDownload }: { noDownload?: boolean }) => {
  const cls = useStyles();
  return (
    <div>
      <div className={cls.inline}>
        <div className={cls.typeBox}>
          <div className={cls.smallSpacer}>
            <GoogleAuthIcon />
          </div>
          <Text as="p" variant={'copy1'} weight={'bold'}>
            Google
            <br /> {strings.authenticator}
          </Text>
        </div>
        {!noDownload && (
          <div>
            <div className={cls.mfaBadge}>
              <MfaBadge platform="ios" type="google" />
            </div>
            <div className={cls.mfaBadge}>
              <MfaBadge platform="android" type="google" />
            </div>
          </div>
        )}
      </div>
      <div className={cls.inline}>
        <div className={cls.typeBox}>
          <div className={cls.smallSpacer}>
            <img src={MicrosoftAuthIcon} alt="MicrosoftAuthIcon" style={{ height: '50px' }} />
          </div>
          <Text as="p" variant={'copy1'} weight={'bold'}>
            Microsoft <br />
            {strings.authenticator}
          </Text>
        </div>
        {!noDownload && (
          <div>
            <div className={cls.mfaBadge}>
              <MfaBadge platform="ios" type="microsoft" />
            </div>
            <div className={cls.mfaBadge}>
              <MfaBadge platform="android" type="microsoft" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
