import { client } from './client';
import {
  Awards,
  Channel,
  ChannelTopic,
  GetMySchedule,
  GetTest,
  Session,
  Stat,
  TimeSlot,
} from './dclxInterfaces';
import { byDate, byPosition } from '../utils/sort';
import { GetSurveyTemplate, GetUserSurvey } from '@pp-labs/survey';

/** Forgiving load for exam stats */
export const loadExamStats = async (): Promise<number[]> => {
  try {
    return (await client.get<GetTest[]>('exams/stats')).data.map((t) => t.examId);
  } catch {
    return [];
  }
};

/** Forgiving load for session stats */
export const loadSessionStats = async (): Promise<Stat[]> => {
  try {
    return (await client.get<Stat[]>('sessions/stats')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for topics */
export const loadTopics = async (): Promise<ChannelTopic[]> => {
  try {
    return (await client.get<ChannelTopic[]>('topics')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for exams */
export const loadExams = async (): Promise<GetTest[]> => {
  try {
    return (await client.get<GetTest[]>('exams')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for booked timeslots */
export const loadTimeslots = async (): Promise<TimeSlot[]> => {
  try {
    return (await client.get('trainings/bookings')).data.sort(byDate);
  } catch {
    return [];
  }
};

/** Forgiving load for sessions */
export const loadSessions = async (): Promise<Session[]> => {
  try {
    return (await client.get('sessions')).data.sort(byPosition);
  } catch {
    return [];
  }
};

/** Forgiving load for survey templates */
export const loadSurveyTemplates = async (): Promise<GetSurveyTemplate[]> => {
  try {
    return (await client.get('surveys/templates')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for user surveys */
export const loadUserSurveys = async (): Promise<GetUserSurvey[]> => {
  try {
    return (await client.get('surveys')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for my schedule */
export const loadSchedule = async (): Promise<GetMySchedule[]> => {
  try {
    return (await client.get('mySchedule')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for channels */
export const loadChannels = async (): Promise<Channel[]> => {
  try {
    return (await client.get('channels')).data;
  } catch {
    return [];
  }
};

/** Forgiving load for awards/badges */
export const loadAwards = async (): Promise<Awards | null> => {
  try {
    return (await client.get('awards')).data;
  } catch {
    return null;
  }
};
