import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import strings from '../../Localization/Localizer';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import { LabelButton, EditDialog } from '@pp-labs/ui-components';
import TechTest from '../../features/TechTest/TechTest';

/** TechTest rendered in Support Section of the site */
const TechTestSupport = () => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useIsTheme();
  const useStyles = makeStyles((themeParam) => ({
    techTest: {
      margin: '0 0 0 20% !important',
      [themeParam.breakpoints.down('md')]: {
        margin: '32px 0 0 0 !important',
      },
    },
    text: {
      marginBottom: '24px !important',
    },
  }));

  const cls = useStyles();

  const close = () => {
    setOpen(false);
  };
  return (
    <>
      {open && (
        <EditDialog title={''} close={close}>
          <TechTest onFinish={close} external={false} hideSteps />
        </EditDialog>
      )}
      <div className={cls.techTest}>
        <Text
          className={cls.text}
          as="h2"
          variant={theme.audi ? 'order3' : 'order2'}
          weight={theme.audi ? 'bold' : 'normal'}
        >
          {strings.techTestHeadline}
        </Text>
        <Text className={cls.text} as="p" variant="copy1">
          {strings.makeTechTestNow}
        </Text>
        <LabelButton variant="secondary" onClick={() => setOpen(true)}>
          {strings.startTechTest}
        </LabelButton>
      </div>
    </>
  );
};

export { TechTestSupport };
