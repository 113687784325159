import { CancelIcon, makeThemedStyles, useThemedStyles } from '@pp-labs/ui-components';
import React, { useEffect, useState } from 'react';
import { Text } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { BreakoutRoom, useLotState } from '../Provider/LotProvider';

const styles = makeThemedStyles(() => ({
  container: {
    position: 'absolute',
    top: 100,
    right: 10,
    pointerEvents: 'all',
    backgroundColor: ({ colorScheme }) => colorScheme.background,
    maxWidth: '40%',
  },

  textContainer: {
    padding: '24px',
    paddingTop: 36,
    position: 'relative',
  },
  cancelIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer',
    zIndex: 10,
  },
}));

let CloseTimeout: ReturnType<typeof setTimeout> | null = null;
/**
 * Component for notifying people that their room changed.
 */
export const BreakoutReminder = () => {
  const cls = useThemedStyles(styles);
  const [lastBreakout, setLastBreakout] = useState<BreakoutRoom | 'initial' | null>('initial');
  const [reminderOpen, setReminderOpen] = useState<BreakoutRoom | null | false>(false);
  const lotState = useLotState();
  useEffect(() => {
    if (
      lastBreakout !== 'initial' &&
      lastBreakout?.Id === lotState.breakoutRooms.myBreakoutRoom?.Id
    )
      return;
    if (lastBreakout === 'initial') {
      setLastBreakout(lotState.breakoutRooms.myBreakoutRoom);
    } else {
      setLastBreakout(lotState.breakoutRooms.myBreakoutRoom);
      setReminderOpen(lotState.breakoutRooms.myBreakoutRoom);
      if (CloseTimeout) clearTimeout(CloseTimeout);
      CloseTimeout = setTimeout(() => {
        setReminderOpen(false);
      }, 5000);
    }
  }, [lotState.breakoutRooms.myBreakoutRoom, lastBreakout]);

  useEffect(() => {
    return () => {
      if (CloseTimeout) clearTimeout(CloseTimeout);
    };
  }, []);

  if (reminderOpen === false) return null;

  return (
    <div className={cls.container}>
      <div
        className={cls.cancelIcon}
        onClick={() => {
          if (CloseTimeout) clearTimeout(CloseTimeout);
          setReminderOpen(false);
        }}
      >
        <CancelIcon />
      </div>
      <div className={cls.textContainer}>
        <Text as="p" variant="copy1" weight="bold" spaceStackEnd="m">
          {reminderOpen === null
            ? strings.breakoutYouAreNowMainHead
            : strings.breakoutYouAreNowBreakoutHead}
        </Text>
        <Text as="p" variant="copy1">
          {reminderOpen === null
            ? strings.breakoutYouAreNowMainBody
            : strings.breakoutYouAreNowBreakoutBody}
        </Text>
      </div>
    </div>
  );
};
