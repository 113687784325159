import { ImportError, PlatformEvent } from 'ApiHandler/dclxInterfaces';
import AudiSpacer from 'utils/AudiSpacer';
import { Divider, NativeTextField, Search, Text } from '@pp-labs/ui-components';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import AudiTabs from 'utils/AudiTabs/AudiTabs';
import { adminClient } from 'ApiHandler/adminClient';
import { tableStyles } from '@pp-labs/ui-components';
import { getDisplayDate } from 'utils/convert';
import strings from 'Localization/Localizer';

const useStyles = makeStyles((theme: Theme) => ({
  ...tableStyles(),
  search: {
    maxWidth: '339px',
  },
  tabs: {
    flexGrow: 1,
    width: '100%',
  },
}));

/** interface for AdminEventUserManager props coming from parent component AdminPanelTable */
interface P {
  event: PlatformEvent;
}

/** User Manager to check failed imports for the eventAdmin used in AdminPanelTable*/
const AdminEventUserManager = (props: P) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [errors, setErrors] = useState<ImportError[]>([]);
  const classes = useStyles();

  const refresh = async () => {
    const data: ImportError[] = (
      await adminClient.get(`events/${props.event.tenantId}/users/failed`)
    ).data;
    setErrors(data);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = (tab: string) => {
    if (errors.length === 0)
      return (
        <Text as="h3" variant="order3">
          {strings.noErrorsFoundForEvent}
        </Text>
      );
    return (
      <>
        <Search
          inputId="search__input"
          label="Search"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={classes.search}
        />
        <AudiSpacer spaceStackEnd={'s'} />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.username}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.error}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.operation}
                  </Text>
                </TableCell>

                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.date}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors
                .filter((error: ImportError) => {
                  if (searchValue.length < 2) return true;
                  return (
                    error.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    error.message?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    error.operation?.toLowerCase().includes(searchValue.toLowerCase())
                  );
                })
                .map((error, k) => {
                  return (
                    <TableRow key={k}>
                      <TableCell>
                        <Text as="p" variant="copy1">
                          {error.username}
                        </Text>
                      </TableCell>

                      <TableCell>
                        <Text as="p" variant="copy1">
                          {error.message || strings.unknownError}
                        </Text>
                      </TableCell>

                      <TableCell>
                        <Text as="p" variant="copy1">
                          {error.operation}
                        </Text>
                      </TableCell>

                      <TableCell>
                        <Text as="p" variant="copy1">
                          {getDisplayDate(new Date(error.timestamp))}
                        </Text>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <>
      <AudiSpacer spaceStackEnd={'s'} />
      <div style={{ width: '50%', margin: 'auto' }}>
        <div style={{ textAlign: 'center' }}>
          <Text as="h1" variant="order4" weight={'bold'}>
            {strings.importedFrom}
          </Text>
        </div>
        <NativeTextField
          inputId={'idProviderIdentifier'}
          name={'idProviderIdentifier'}
          label={'AirLST-ID'}
          disabled={true}
          value={props.event.idProviderIdentifier}
        />
      </div>
      <AudiSpacer spaceStackEnd={'l'} />
      <Divider />
      <AudiSpacer spaceStackEnd={'l'} />

      <div className={classes.tabs}>
        <AudiTabs tabLabels={[strings.failedUserImports]} tabContents={[getContent('errors')]} />
      </div>
      <AudiSpacer spaceStackEnd={'l'} />
    </>
  );
};

export { AdminEventUserManager };
