import SessionTiles from '../DCLXSessionTiles';
import Locked from '../Locked';
import React, { useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Test from './Test';
import { useRealTime, useUser } from '../../../../utils/hooks';
import { getLocalizedValue } from 'Localization/Localizer';
import { gutterStyle } from 'utils/gutter';
import { followProgression, ProgressionReturn, TopicProgression } from '../../../../progression';
import clsx from 'clsx';
import { useIsTheme, Text } from '@pp-labs/ui-components';
import { TopicClosing } from './TopicClosing';
import { doesRoleMatch, Role } from 'utils/RoleDefinitions';

/** interface for Topic props coming from parent component DclxMyTrainings */
interface P {
  topic: TopicProgression;
  progression: ProgressionReturn;
  refresh: () => void;
  block: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
  tileRow: {
    marginRight: -2,
    marginLeft: -2,
  },
  topicMainWrapper: {
    padding: '32px 0 0 0',
  },
  topicInnerWrapper: {
    padding: '0 0 32px',
  },
  blackText: {
    color: '#ffffff !important',
  },
}));

/** Topic displaying all the session tiles */
export const Topic = (props: P) => {
  const user = useUser()!;
  const unlockRequired = useMemo(() => followProgression(user), [user]);
  const cls = useStyles();
  const now = useRealTime();
  const theme = useIsTheme();

  const pre = props.progression.info.exams.find(
    (t) => t.topicId === props.topic.topicId && t.examType === 'pre'
  );
  const post = props.progression.info.exams.find(
    (t) => t.topicId === props.topic.topicId && t.examType === 'post'
  );
  const title = getLocalizedValue(props.topic.title);
  const sessions = props.progression.info.sessions.filter(
    (s) =>
      s.topicIds.includes(props.topic.topicId) &&
      /* If there is an end-date, make sure it is not over yet */
      (!s.endAt || now / 1000 < s.endAt)
  );
  const isEventManager = doesRoleMatch(user, Role.MARKET);
  const topicEnded = props.topic.endAt * 1000 <= now && !isEventManager;
  const topicStarted = props.topic.startAt * 1000 <= now;
  return (
    <div className={cls.topicMainWrapper} id={`topic${props.topic.topicId}`}>
      <div className={cls.topicInnerWrapper} title={title}>
        <Text
          as="h2"
          variant="order3"
          weight={'bold'}
          className={clsx(cls.maxTwoLines, theme.neutralDark && cls.blackText)}
        >
          {title}
        </Text>
      </div>
      {topicEnded ? (
        <TopicClosing topic={props.topic} />
      ) : topicStarted ? (
        <div>
          {pre && (
            <Test
              test={pre}
              progression={props.progression}
              refresh={props.refresh}
              block={props.block}
            />
          )}
          <div className={cls.tileRow}>
            <SessionTiles
              sessions={sessions}
              maxCols={4}
              unlockRequired={unlockRequired}
              firstUnlocked={props.topic.progression.unlocked}
              refresh={props.refresh}
              disableVideoProgression={!!props.topic.disableVideoProgression}
            />
          </div>
          {post && (
            <Test
              test={post}
              progression={props.progression}
              refresh={props.refresh}
              block={props.block}
            />
          )}
        </div>
      ) : (
        <Locked topic={props.topic} />
      )}
    </div>
  );
};
