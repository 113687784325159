import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { connect } from 'react-redux';
import { gutterStyle } from '../../../utils/gutter';
import { WithStyles, withStyles } from '@material-ui/core';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';

/** interface for ResetMFA props coming from parent component CMS */
interface P extends WithStyles<typeof gutterStyle> {
  triggerNotification: Function;
}

const validation = Yup.object({
  username: Yup.string().required(strings.required),
  phone: Yup.string()
    .required(strings.required)
    .test(
      'phoneNumberFormat',
      strings.unsupportedPhoneFormat,
      (value) => !value || value.includes('+')
    ),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

const initialValues: F = {
  username: '',
  phone: '',
};

/**
 * Form for resetting the MFA of a user
 */

class ResetMFA extends Component<P> {
  submit = async (values: F) => {
    const payload = {
      username: values.username,
      phoneNumber: values.phone,
    };
    try {
      await client.post('users/pool/mfa/sms', payload);
      this.props.triggerNotification([strings.mfaReset, 'success']);
    } catch {
      this.props.triggerNotification([strings.somethingWentWrong, 'error']);
    }
  };

  render() {
    return (
      <div className={this.props.classes.gutterAll} style={{ maxWidth: '600px' }}>
        <Formik
          validationSchema={validation}
          initialValues={initialValues}
          onSubmit={(values: F, { resetForm }) => {
            this.submit(values);
            resetForm();
          }}
        >
          {({ errors, touched }) => {
            const et = {
              username: touched.username ? errors.username : '',
              phone: touched.phone ? errors.phone : '',
            };
            return (
              <Form>
                <TextField name="username" label={strings.username} error={et.username} />
                <TextField name="phone" label={strings.phoneNumber} error={et.phone} />
                <LabelButton variant="primary" type="submit">
                  Reset MFA
                </LabelButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = { triggerNotification };

export default connect(null, mapDispatchToProps)(withStyles(gutterStyle)(ResetMFA));
