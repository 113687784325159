import { IconButton, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import {
  DraggableComponent,
  DroppableComponent,
  EditIcon,
  EraseIcon,
  handleDrop,
  LabelButton,
  TableCell,
  TableRow,
  tableStyles,
  Text,
} from '@pp-labs/ui-components';
import { ArrowUpDown } from '../../../icons';
import { GetFirstStep } from '../../../ApiHandler/dclxInterfaces';
import { useEffect, useState } from 'react';
import { byPosition } from '../../../utils/sort';
import { client } from '../../../ApiHandler/client';
import { useCMSChannel, useLoad, useNotify } from '../../../utils/hooks';
import { EditFirstStepItem } from './EditFirstStepItem';
import { useInnerProgression } from '../../../progression';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';

export const maxItems = 3;

const useStyles = makeStyles({
  ...tableStyles(),
});

interface Editing {
  id: number;
  position: number | undefined;
}

const maxPosition = (items: GetFirstStep[]) =>
  items.map((i) => i.position).reduce((acc, curr) => Math.max(acc, curr), 0);

/** List all the first steps for a channel */
export const FirstStepsCms = () => {
  const [firstSteps, refresh] = useLoad<GetFirstStep[]>('firstSteps');
  const notify = useNotify();
  const cls = useStyles();
  const [currentlyEditing, setCurrentlyEditing] = useState<Editing | null>(null);
  const [myItems, setMyItems] = useState<GetFirstStep[]>([]);
  const channel = useCMSChannel()!;
  const progression = useInnerProgression({
    channelId: channel.channelId,
    refreshInterval: 600000,
  });

  const setItems = (items: GetFirstStep[]) =>
    setMyItems(items.filter((i) => i.channelId === channel.channelId).sort(byPosition));

  useEffect(() => {
    if (firstSteps) setItems(firstSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSteps]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel.channelId]);

  const onDragEnd = (result: any) => {
    const newItems = handleDrop(result, myItems);
    if (newItems) setItems(newItems.map((i, index) => ({ ...i, position: index })));
  };

  const confirmOrder = async () => {
    const newOrder: number[] = myItems.map((i) => i.firstStepId);
    await client.post(`firstSteps/order`, newOrder);
    notify('Order successfully changed', 'success');
    refresh();
  };

  const edit = (id: number) => setCurrentlyEditing({ id: id, position: undefined });

  const closeEdit = (change: boolean) => {
    if (change) refresh();
    setCurrentlyEditing(null);
  };

  const createNew = (position: number) => setCurrentlyEditing({ id: 0, position: position });

  const del = async (id: number) => {
    await client.delete(`firstSteps/${id}`);
    refresh();
  };
  if (progression === 'loading') return null;
  return (
    <div>
      <TableContainer>
        <Table className={cls.table} aria-label="simple table">
          <TableBody component={DroppableComponent(onDragEnd)}>
            {myItems.map((item, index) => {
              const id = item.firstStepId.toString();
              return (
                <TableRow component={DraggableComponent(id, index)}>
                  <TableCell center style={{ paddingLeft: '24px' }}>
                    <Text variant="copy3">First step item type</Text>
                    <Text as="p" variant="copy1">
                      {item.stepType}
                    </Text>
                  </TableCell>

                  <TableCell center>
                    <Text variant="copy3">Title</Text>
                    <Text as="p" variant="copy1">
                      {getLocalizedValue(item.title)}
                    </Text>
                  </TableCell>
                  <TableCell buttons>
                    <IconButton style={{ color: 'black', padding: 0 }} disabled>
                      <ArrowUpDown />
                    </IconButton>

                    <IconButton style={{ color: 'black' }} onClick={() => edit(item.firstStepId)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton style={{ color: 'black' }} onClick={() => del(item.firstStepId)}>
                      <EraseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <LabelButton
        variant="primary"
        onClick={() => createNew(maxPosition(myItems) + 1)}
        className={cls.btnLeft}
      >
        {strings.createFirstStep}
      </LabelButton>
      <LabelButton variant="secondary" onClick={confirmOrder} className={cls.btnRight}>
        {strings.confirmNewOrder}
      </LabelButton>

      {currentlyEditing !== null && (
        <EditFirstStepItem
          item={
            currentlyEditing
              ? myItems.find((i) => i.firstStepId === currentlyEditing.id) || null
              : null
          }
          close={closeEdit}
          position={currentlyEditing.position || maxItems}
          info={progression.info}
          channel={channel}
        />
      )}
    </div>
  );
};
