import React from 'react';
import { Text } from '@pp-labs/ui-components';
import { Session } from 'ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import AudiSpacer from '../../../utils/AudiSpacer';
import styles from 'features/Sessions/styles';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { standardFormat } from 'config';
import { useLotState } from './Provider/LotProvider';
import { EditorParser } from 'features/Editor/EditorParser';

/** interface for LotDescription props coming from parent component LOT  */
interface P {
  Session: Session;
  tablet?: boolean;
  startAt: number;
}
const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  wrapper: {
    minWidth: '500px',
    [theme.breakpoints.only('sm')]: {
      padding: '20px 52px 52px 20px',
      minHeight: '460px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      width: '100%',
    },
  },
  outerwrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'top',
    width: '100%',
  },
}));

/** Displays LOT Metadata */
const LotDescription = (props: P) => {
  const cls = useStyles();
  const lotState = useLotState();
  const bo = lotState.breakoutRooms.myBreakoutRoom;
  if (bo) {
    return (
      <div className={cls.outerwrap}>
        <div className={cls.wrapper} style={{ paddingTop: '12px' }}>
          <Text variant="copy1">{getLocalizedValue(props.Session.title)}</Text>
          <Text variant={'order2'} weight={'bold'}>
            {strings.breakoutRoom}: {bo.Title}
          </Text>
        </div>
      </div>
    );
  }
  return (
    <div className={cls.outerwrap}>
      <div className={cls.wrapper} style={{ width: props.tablet ? '100%' : '70%' }}>
        <AudiSpacer spaceStackStart={'s'} />
        <Text variant={'order2'} weight="bold">
          {getLocalizedValue(props.Session.title)}
        </Text>
        <AudiSpacer spaceStackStart={'xxl'} neutralSpaceStackStart={'l'} />
        <Text variant={'copy1'} weight={'bold'}>
          {format(props.startAt * 1000, standardFormat)}
        </Text>
        <EditorParser inputString={getLocalizedValue(props.Session.description)} />
      </div>
    </div>
  );
};

export { LotDescription };
