import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { LabelButton, Text } from '@pp-labs/ui-components';
import { Channel } from 'ApiHandler/dclxInterfaces';
import { Form, Formik } from 'formik';
import strings from 'Localization/Localizer';
import React, { useState } from 'react';

/** interface for SelectChannels props coming from parent component Messages  */
interface P {
  onSubmit: (selectedChannelIds: number[]) => void;
  availableChannels: Channel[];
}

/** Multi selection of channels */
const SelectChannels = (props: P) => {
  const [currentChannels, setCurrentChannels] = useState<number[]>([]);
  const initialValues: { channelId: number[] } = {
    channelId: [],
  };
  const submit = () => {
    props.onSubmit(currentChannels);
  };
  return (
    <div>
      {props.availableChannels.length !== 0 ? (
        <div
          style={{
            padding: '12px',
            marginTop: '22px',
            marginBottom: '30px',
          }}
        >
          <Formik initialValues={initialValues} onSubmit={submit}>
            {() => {
              return (
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    multiple
                    placeholder={strings.selectAChannel}
                    label={strings.selectAChannel}
                    name="channelId"
                    value={currentChannels}
                    input={<OutlinedInput name="channelId" />}
                    onChange={(event: any) => {
                      setCurrentChannels(event.target.value);
                    }}
                  >
                    {props.availableChannels.map((c, i) => (
                      <MenuItem key={i} value={c.channelId}>
                        {c.channelName}
                      </MenuItem>
                    ))}
                  </Select>
                  <LabelButton
                    type="submit"
                    variant="primary"
                    disabled={currentChannels.length <= 0}
                  >
                    {strings.createMessage}
                  </LabelButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Text>{strings.pleaseCreateChannelFirst}</Text>
      )}
    </div>
  );
};

export { SelectChannels };
