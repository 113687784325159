import React, { useEffect, useState } from 'react';
import {
  EditLevel,
  MultiLanguage,
  Session,
  SessionType,
} from '../../../../ApiHandler/dclxInterfaces';
import { makeStyles, TableRow } from '@material-ui/core';

import strings from '../../../../Localization/Localizer';
import { SelectMediaButton } from 'DCLX/CMS/Documents/SelectMediaButton';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';
import { BigButton, DocumentIcon } from '@pp-labs/ui-components';
import { ClockIcon } from '../../../../icons';

/** interface for MediaSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  thisSession: Session | null;
  headline: string;
  inline: string;
  et: any;
  openDocManager: () => void;
  setFieldValue: (name: string, file: MediaFile) => void;
  values: any;
  initialSubtitles?: { [key: string]: MediaFile | null };
  selectedLanguageCode?: string;
  level: EditLevel;
  sessionId: number | undefined;
  openInterruptDialog: () => void;
}

const useStyle = makeStyles({
  mainWrapper: {
    display: 'inline-flex',
    marginTop: '20px',
  },
  uploadBtn: {
    marginLeft: '12px',
  },
});

/** Subform to upload the various types of media */
const MediaSubform = (props: P) => {
  const [subtitleFile, setSubtitleFile] = useState<MediaFile | null>(null);
  const cls = useStyle();
  useEffect(() => {
    props.selectedLanguageCode &&
      setSubtitleFile((props.initialSubtitles?.[props.selectedLanguageCode] as MediaFile) || null);
  }, [props.selectedLanguageCode, props.initialSubtitles]);

  const getSubtitleName = () => {
    if (props.values.subtitle) {
      const sub = props.values.subtitle.find(
        (s: MultiLanguage<any>) => s.lang === props.selectedLanguageCode
      );
      const name = sub.value?.originalFile;
      if (name) return name as string;
    }
    return 'Subtitle';
  };

  return (
    <TableRow key="media">
      <div className={cls.mainWrapper}>
        <SelectMediaButton
          name="thumbnail"
          type="image"
          label={props.values.thumbnail ? props.values.thumbnail.name : strings.thumbnail}
          prevUrl={props?.values?.thumbnail?.url || props.thisSession?.thumbnail?.url}
          prevName={
            props?.values?.thumbnail?.originalFile || props.thisSession?.thumbnail?.originalFile
          }
          variant={'dark'}
          imgDimensions="960 x 540 px"
        />
        {props.type === SessionType.TRAINING && (
          <div className={cls.uploadBtn}>
            <SelectMediaButton
              name="lobbyImage"
              type="image"
              label={props.values.lobbyImage ? props.values.lobbyImage.name : 'Lobby image'}
              prevUrl={
                props?.values?.lobbyImage === null
                  ? undefined
                  : props?.values?.lobbyImage?.url || props?.thisSession?.lobbyImage?.url
              }
              prevName={
                props?.values?.lobbyImage === null
                  ? undefined
                  : props?.values?.lobbyImage?.originalFile ||
                    props?.thisSession?.lobbyImage?.originalFile
              }
              variant={'dark'}
              optional
              imgDimensions="1920 x 1080 px"
            />
          </div>
        )}
        {props.type !== SessionType.LIVE && (
          <div className={cls.uploadBtn}>
            <SelectMediaButton
              name="video"
              type="video"
              label={props.values.video ? props.values.video.name : strings.video}
              prevUrl={
                props?.values?.video === null
                  ? undefined
                  : props?.values?.video?.url || props?.thisSession?.video?.downloadUrl
              }
              prevName={
                props?.values?.video === null
                  ? undefined
                  : props?.values?.video?.originalFile || props?.thisSession?.video?.originalFile
              }
              variant={'light'}
              optional={props.type === SessionType.TRAINING}
            />
          </div>
        )}
        {props.type === SessionType.DEMAND && (
          <div className={cls.uploadBtn}>
            <BigButton
              text={strings.questions}
              handleClick={props.openInterruptDialog}
              icon={ClockIcon}
            />
          </div>
        )}
        <div className={cls.uploadBtn}>
          <BigButton
            text={strings.manageDocuments}
            handleClick={props.openDocManager}
            icon={DocumentIcon}
          />
        </div>
        <div className={cls.uploadBtn}>
          {props.type !== SessionType.LIVE &&
            props.type !== SessionType.TRAINING &&
            props.selectedLanguageCode !== undefined && (
              <div className={props.inline}>
                <SelectMediaButton
                  name="subtitle"
                  type="vtt"
                  label={getSubtitleName()}
                  prevUrl={subtitleFile?.url || ''}
                  prevName={subtitleFile?.originalFile || ''}
                  variant={'light'}
                />
              </div>
            )}
        </div>
      </div>
    </TableRow>
  );
};

export default MediaSubform;
