import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { LayoutItem, makeThemedStyles, Text, useThemedStyles } from '@pp-labs/ui-components';
import * as Yup from 'yup';
import { Link } from '@material-ui/core';
import { newPasswordValidator, usernameValidator } from '../utils/validator';
import strings from '../Localization/Localizer';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import { useEventSettings, useNotify } from '../utils/hooks';
import { SupportEmail } from './SupportEmail';
import { CustomError, FormSoftButton } from '../SoftButton';
import { getFormErrorMessage } from './utils';
import { IDPLoginButton } from './IDP/IDPLoginButton';
import { NeutralLogo } from '../utils/Theming/NeutralLogo';
import AudiSpacer from '../utils/AudiSpacer';
import { ProviderSetting } from 'ApiHandler/dclxInterfaces';
import { publicClient } from 'ApiHandler/publicClient';
import { loginContext } from '../features/StateProvider/LoginProvider';

const validation = () =>
  Yup.object({
    username: usernameValidator(),
    password: newPasswordValidator(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof validation>>;
/** interface for LoginForm props coming from parent component Login  */
interface P {
  login: (username: string, password: string) => void;
  triggerForgotPassword: (username: string) => void;
  triggerRegister: () => void;
  error: string | null;
  title: string;
  username: string;
}

const styles = makeThemedStyles((theme) => {
  return {
    pageTitle: {
      color: ({ isTheme }) => (isTheme.audi ? '#333' : ''),
    },
    buttons: {
      display: 'flex',
      justifyContent: ({ isTheme }) => (isTheme.audi ? 'space-between' : 'start'),
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: ({ isTheme }) => (isTheme.audi ? 'block' : 'flex'),
      },
    },
    registerButton: {
      marginLeft: ({ isTheme }) => (isTheme.neutral ? '20px' : undefined),
    },
  };
});
/**
 * Form for normal login, rendered on Landing Page
 */
const LoginForm = (props: P) => {
  const eventSettings = useEventSettings();
  const notify = useNotify();
  const cls = useThemedStyles(styles);
  const [isIdp, setIsIdp] = useState<boolean>(false);
  const [preSelect, setPreSelect] = useState<boolean>(false);

  useEffect(() => {
    const getLoginInfo = async () => {
      const loginInfo: ProviderSetting = (
        await publicClient.get(`event:${eventSettings?.identifier}/loginInfo`)
      ).data;
      const isIdpGetter = () =>
        loginInfo !== null &&
        loginInfo.providers &&
        loginInfo.providers.length > 0 &&
        loginInfo.providers[0].authorizeUrl !== null;

      setIsIdp(isIdpGetter());
      setPreSelect(isIdpGetter());
    };
    getLoginInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: F = {
    username: props.username,
    password: '',
  };

  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  return (
    <div>
      <NeutralLogo />
      <AudiSpacer neutralSpaceStackStart={'s'} />
      <div style={{ paddingBottom: 40 }}>
        <Text as="h1" variant="order2" weight={'bold'} className={cls.pageTitle}>
          {props.title}
        </Text>
      </div>
      {isIdp && preSelect && (
        <div>
          <AudiSpacer spaceStackEnd="xxl" />
          <Text as="h2" variant="order3" weight="bold">
            {strings.continueAsParticipant}
          </Text>
          <AudiSpacer spaceStackEnd="s" />
          <IDPLoginButton />
          <AudiSpacer spaceStackEnd="s" />
          <SupportEmail />
          <AudiSpacer spaceStackEnd="xxxl" />
          <div style={{ background: 'black', height: '1px', width: '100%' }} />
          <AudiSpacer spaceStackEnd="xxxl" />
        </div>
      )}
      {!preSelect && isIdp && (
        <>
          <Link underline="always" onClick={() => setPreSelect(true)}>
            <Text as="span" variant="copy1">
              {`< ${strings.back}`}
            </Text>
          </Link>
          <AudiSpacer spaceStackEnd="l" />
        </>
      )}
      <AudiSpacer neutralSpaceStackStart={'s'} spaceStackStart={'s'} />
      {preSelect && (
        <>
          <Text as="h2" variant="order3">
            {strings.eventAdministration}
          </Text>
          <LabelButton variant="secondary" onClick={() => setPreSelect(false)}>
            {`${strings.registrationLogin}`}
          </LabelButton>
        </>
      )}

      {!preSelect && (
        <Formik
          initialValues={initialValues}
          validationSchema={validation()}
          onSubmit={(values: F) => props.login(values.username, values.password)}
        >
          {({ values, touched, errors }) => {
            return (
              <Form>
                <LoginReceiver />
                <div>
                  <div>
                    <TextField
                      name={'username'}
                      label={strings.username}
                      error={touched.username ? errors.username : ''}
                      autoComplete="username"
                    />
                  </div>
                  <AudiSpacer spaceStackStart={'s'} />
                  <div>
                    <TextField
                      name={'password'}
                      label={strings.password}
                      type="password"
                      error={touched.password ? errors.password : ''}
                      autoComplete="current-password"
                    />
                  </div>
                  {props.error && <div className="field-error">{props.error}</div>}
                  <LayoutItem spaceStackStart={'l'} spaceStackEnd={'xxs'} className={cls.buttons}>
                    <FormSoftButton
                      id="login"
                      variant="primary"
                      relevantFields={['username', 'password']}
                      errorCallback={handleError}
                    >
                      {strings.login}
                    </FormSoftButton>
                    <LabelButton
                      className={cls.registerButton}
                      variant="secondary"
                      onClick={() => props.triggerRegister()}
                    >
                      {strings.register}
                    </LabelButton>
                  </LayoutItem>
                  <LabelButton
                    size="small"
                    variant="text"
                    onClick={() => props.triggerForgotPassword(values.username)}
                  >
                    {strings.forgotPassword}
                  </LabelButton>
                </div>
                {!isIdp && eventSettings?.identifier !== 'produktionsqualifizierung2022-pilot' && (
                  <LayoutItem spaceStackStart={'xxl'} spaceStackEnd={'xxl'}>
                    <Text as="h2" variant="order4" weight={'bold'} spaceStackEnd={'l'}>
                      {strings.newHere}
                    </Text>
                    {eventSettings?.poolSecurity === 'mfa' ? (
                      <Text as="p" variant="copy1">
                        {strings.needRegister}
                      </Text>
                    ) : (
                      <Text as="p" variant="copy1">
                        {strings.withoutMfaNeedRegister}
                      </Text>
                    )}
                  </LayoutItem>
                )}
                <SupportEmail />
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export const LoginReceiver = () => {
  const { values } = useFormikContext<{ username: string }>();
  const { setCurrentUsername } = useContext(loginContext);

  useEffect(() => {
    setCurrentUsername(values.username);
  }, [setCurrentUsername, values.username]);
  return null;
};

export default LoginForm;
