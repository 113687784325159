import registerThumb from '../../media/registerThumb.png';
import React, { useMemo } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useEventSettings } from '../../utils/hooks';
import { getLocalizedValue } from '../../Localization/Localizer';
import { PlayIcon } from '@pp-labs/ui-components';

const useStyles = makeStyles((theme) => ({
  outer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: `${(4 / 7) * 100}%`,
    },
  },
  video: {
    width: '100%',
    position: 'relative',
    paddingTop: '56.25%',
  },
  inner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  innerPlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 72,
    },
  },
}));

/** Link to the registration video on vimeo. */
export const VimeoVideo = () => {
  const cls = useStyles();
  const event = useEventSettings()!;
  const link = useMemo(() => {
    if (!event.registerVideoIdentifier) return '';
    const id = getLocalizedValue(event.registerVideoIdentifier);
    if (!id || id === '-') return '';
    return `https://player.vimeo.com/video/${id}`;
  }, [event.registerVideoIdentifier]);

  const openVideo = () => {
    if (!link) return;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
  };

  if (!link) return null;

  return (
    <div className={cls.outer}>
      <div className={cls.video}>
        <div
          className={cls.inner}
          style={{ backgroundImage: `url(${event.registerVideoThumb || registerThumb})` }}
        />
        <div className={cls.innerPlay}>
          <IconButton onClick={openVideo} color="inherit" className={cls.button}>
            <PlayIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
