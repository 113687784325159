import * as Yup from 'yup';
import { sixDigitCode } from '../utils/validator';
import strings from '../Localization/Localizer';
/**
 * MFA-Utils shared across components, avoiding circular dependencies
 */
const validation = () =>
  Yup.object({
    validationCode: sixDigitCode(),
  }).required(strings.required);

type MFAData = Yup.InferType<ReturnType<typeof validation>>;

export { validation };
export type { MFAData };
