import { BaseBreakoutRoom, LotState, RoomWithUsers, useLotState } from '../Provider/LotProvider';
import { BreakoutRoom, CommonBreakoutRoomProps } from './BreakoutRoom';
import { LabelButton } from '@pp-labs/ui-components';
import React, { useMemo, useState } from 'react';
import { UserPicture } from '../../../../ApiHandler/dclxInterfaces';
import { EditBreakoutRoom } from './EditBreakoutRoom';
import { fisherYatesShuffle } from '../../../../utils/convert';
import strings from '../../../../Localization/Localizer';

/** Sort users to their rooms */
export const getParticipants = (lotState: LotState) => {
  const withUsers: RoomWithUsers[] = lotState.breakoutRooms.rooms.map((r) => ({
    room: r,
    users: [],
  }));
  const main: UserPicture[] = [];
  const online: UserPicture[] = [];
  const offline: UserPicture[] = [];
  lotState.users.allUsers.forEach((u) => {
    if (lotState.online.onlineSubs && !lotState.online.onlineSubs?.includes(u.username)) {
      offline.push(u);
    } else {
      online.push(u);
      const myRoom = lotState.breakoutRooms.rooms.findIndex((r) =>
        r.Participants.includes(u.username)
      );
      if (myRoom >= 0) {
        withUsers[myRoom].users.push(u);
      } else {
        main.push(u);
      }
    }
  });
  return {
    main: main,
    online: online,
    offline: offline,
    breakout: withUsers,
  };
};

/** List of the various Rooms */

export const BreakoutRooms = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [editBreakoutRoom, setEditBreakoutRoom] = useState<BaseBreakoutRoom | null | undefined>(
    undefined
  );
  const [currentSelected, setCurrentSelected] = useState<string[]>([]);

  const toggleSelected = (username: string) => {
    const newSelected = currentSelected.slice();
    const index = newSelected.findIndex((u) => u === username);
    index < 0 ? newSelected.push(username) : newSelected.splice(index, 1);
    setCurrentSelected(newSelected);
  };

  const startEdit = (room: BaseBreakoutRoom) => setEditBreakoutRoom(room);

  const resetSelected = () => setCurrentSelected([]);

  const lotState = useLotState();
  const participants = useMemo(() => getParticipants(lotState), [lotState]);

  /** Shuffles all currently online participants to a pseudo random breakout room. Trainers stay where they are. */
  const shuffle = () => {
    const online = lotState.users.participants
      .map((u) => u.username)
      .filter((u) => lotState.online.onlineSubs?.includes(u));
    fisherYatesShuffle(online);
    const config = lotState.breakoutRooms.rooms.map((r, bi) => {
      const users = online.filter((_, ui) => ui % lotState.breakoutRooms.rooms.length === bi);
      return { users: users, id: r.Id };
    });
    lotState.breakoutRooms.moveTo(config);
  };

  const common: CommonBreakoutRoomProps = {
    currentSelected: currentSelected,
    toggleSelected: toggleSelected,
    resetSelected: resetSelected,
    startEdit: startEdit,
    searchValue: searchValue,
    setSearch: setSearchValue,
  };

  return (
    <div>
      <BreakoutRoom
        room={{
          Id: 'main',
          Title: strings.breakoutMain,
          CustomProperties: { active: true, deactivateAt: null },
        }}
        index={0}
        users={participants.main}
        type="main"
        {...common}
      />
      {participants.breakout.map((r, i) => (
        <BreakoutRoom
          room={r.room}
          index={i}
          users={r.users}
          type="breakout"
          {...common}
          key={r.room.Id}
        />
      ))}
      {lotState.type === 'lot' && lotState.users.iAmTrainer && (
        <div>
          <LabelButton
            variant="primary"
            onClick={() => setEditBreakoutRoom(null)}
            spaceInlineEnd="l"
          >
            {strings.breakoutCreateNew}
          </LabelButton>
          <LabelButton variant="secondary" onClick={shuffle}>
            {strings.breakoutShuffle}
          </LabelButton>
        </div>
      )}
      <BreakoutRoom
        room={{
          Id: 'offline',
          Title: strings.breakoutNotPresent,
          CustomProperties: { active: true, deactivateAt: null },
        }}
        index={-1}
        users={participants.offline}
        type="offline"
        {...common}
      />
      {editBreakoutRoom !== undefined && (
        <EditBreakoutRoom
          currentRoom={editBreakoutRoom}
          close={() => setEditBreakoutRoom(undefined)}
        />
      )}
    </div>
  );
};
