import { Popover } from '@material-ui/core';
import { DotsIcon } from '../../../../icons';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import { MenuItem } from './Menu';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import clsx from 'clsx';

const useStyles = makeStyles({
  iconWrapper: {
    marginLeft: 24,
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    cursor: 'pointer',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    alignItems: 'center',
  },
  paper: {
    borderRadius: 20,
    backgroundColor: 'lightgray',
  },
  menuItem: {
    cursor: 'pointer',
    padding: '8px 0px',
    width: '100%',
  },
  white: {
    color: 'white',
  },
});

type P = {
  items: MenuItem[];
};

/** Light popover menu */
export const LightPopover = (props: P) => {
  const cls = useStyles();
  const [anchor, setAnchor] = useState<any | null>(null);
  const theme = useIsTheme();
  const handleToggle = (event: any) => setAnchor(anchor ? null : event.currentTarget);
  const handleClose = () => setAnchor(null);

  if (!props.items.length) return null;
  return (
    <>
      <div className={clsx(cls.iconWrapper, theme.neutralDark && cls.white)} onClick={handleToggle}>
        <DotsIcon />
      </div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: cls.paper,
        }}
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
      >
        <div className={cls.wrapper}>
          {props.items.map((i, index) => {
            return (
              <div
                onClick={() => {
                  i.onClick(i.identifier);
                  handleClose();
                }}
                className={cls.menuItem}
                style={{ borderTop: index !== 0 ? ' 1px solid black' : undefined }}
              >
                <Text black>{i.text}</Text>
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};
