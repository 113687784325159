import { makeStyles, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Text } from '@pp-labs/ui-components';
import AudiSpacer from 'utils/AudiSpacer';
import {
  TableCell,
  TableRow,
  tableStyles,
  LabelButton,
  EditDialog,
  EraseIcon,
  EditIcon,
  UserIcon,
} from '@pp-labs/ui-components';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import { AdminEventSetup } from './AdminEventSetup';
import { blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import { PlatformEvent } from 'ApiHandler/dclxInterfaces';
import { adminClient } from 'ApiHandler/adminClient';
import { useNotify } from 'utils/hooks';
import DeleteDialogue from 'utils/ConfirmDialogue/DeleteDialogue';
import { AdminEventUserManager } from './AdminEventUserManager';
import strings from 'Localization/Localizer';

const useStyles = makeStyles((theme) => ({
  ...blackStyle(theme),
  ...tableStyles(),
  mainWrapper: {
    width: '90%',
    margin: 'auto',
  },
}));

/** List of all Events */
const AdminPanelTable = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openUserManagement, setOpenUserManagement] = useState<boolean>(false);
  const [events, setEvents] = useState<PlatformEvent[]>([]);
  const [editEvent, setEditEvent] = useState<PlatformEvent | null>(null);
  const [currentEventId, setCurrentEventId] = useState<number>(0);
  const [deleteEvent, setDeleteEvent] = useState<PlatformEvent | null>(null);
  const classes = useStyles();
  const notify = useNotify();
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const res = await adminClient.get('events');
    setEvents(res.data);
  };
  const editCurrentEvent = async (eventId: number) => {
    const event = (await adminClient.get(`events/${eventId}`)).data;
    setCurrentEventId(eventId);
    setEditEvent(event);
    setOpen(true);
  };

  const userManagementCurrentEvent = (eventId: number) => {
    const event = events.find((e) => e.tenantId === eventId)!;
    setCurrentEventId(eventId);
    setOpenUserManagement(true);
    setEditEvent(event);
  };

  const deleteCurrentEvent = async (event: PlatformEvent) => {
    setDeleteEvent(event);
  };

  const confirmDelete = async () => {
    const toDelete = deleteEvent?.tenantId;
    setDeleteEvent(null);
    try {
      await adminClient.delete(`events/${toDelete}`);
      getEvents();
      notify(strings.eventDeleteSuccess, 'success');
    } catch {
      notify(strings.eventDeleteFailed, 'error');
    }
  };
  const abortDelete = () => {
    setDeleteEvent(null);
  };

  const closeDialogue = () => {
    setCurrentEventId(0);
    setEditEvent(null);
    setOpen(false);
    setOpenUserManagement(false);
    getEvents();
  };

  const finishEventEdit = () => {
    setCurrentEventId(0);
    setEditEvent(null);
    setOpen(false);
    getEvents();
  };
  return (
    <div className={classes.mainWrapper} id="eventTable">
      <AudiSpacer spaceStackEnd={'xl'} />
      <Grid container>
        <Grid item lg={10} md={10} xs={12}>
          <Text as="h1" variant="order2" weight={'bold'}>
            {strings.allEventsTable}
          </Text>
        </Grid>
        <Grid item lg={2} md={2} xs={12} style={{ textAlign: 'right' }}>
          <LabelButton id="createEvent" variant="primary" onClick={() => setOpen(true)}>
            {strings.createEvent}
          </LabelButton>
        </Grid>
        <AudiSpacer spaceStackEnd={'xl'} />
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {events.map((event) => {
                return (
                  <TableRow key={event.tenantId}>
                    <TableCell center>
                      <img src={event.heroImage} alt="heroImage" />
                    </TableCell>
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {event.identifier}
                      </Text>
                    </TableCell>
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {event.title}
                      </Text>
                    </TableCell>

                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {event.poolName}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        id={event.tenantId.toString()}
                        onClick={() => userManagementCurrentEvent(event.tenantId)}
                        className={
                          event.failedUsers ? classes.signalRedButton : classes.signalGreenButton
                        }
                      >
                        <UserIcon />
                      </IconButton>
                      <div style={{ paddingRight: '5px', display: 'inline' }}></div>
                      <IconButton
                        id={event.tenantId.toString()}
                        onClick={() => editCurrentEvent(event.tenantId)}
                        className={classes.whiteButton}
                      >
                        <EditIcon />
                      </IconButton>
                      <div style={{ paddingRight: '5px', display: 'inline' }}></div>
                      <IconButton
                        id={'delete_' + event.identifier}
                        onClick={() => deleteCurrentEvent(event)}
                        className={classes.redButton}
                      >
                        <EraseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {open && (
          <EditDialog maxWidth="md" title={strings.createEventLong} close={() => closeDialogue()}>
            <AdminEventSetup
              initialData={editEvent}
              onFinish={finishEventEdit}
              getEvents={getEvents}
              currentEventId={currentEventId}
            />
          </EditDialog>
        )}
        {openUserManagement && editEvent && (
          <EditDialog
            maxWidth="lg"
            title={`${strings.userImportStateFor} ${editEvent?.title} (${editEvent?.poolName})`}
            close={() => closeDialogue()}
          >
            <AdminEventUserManager event={editEvent} />
          </EditDialog>
        )}
      </Grid>
      {deleteEvent?.tenantId && (
        <DeleteDialogue
          toBeDeleted={deleteEvent?.tenantId}
          toDeleteLabel={deleteEvent.title}
          delete={confirmDelete}
          reject={abortDelete}
        />
      )}
    </div>
  );
};

export { AdminPanelTable };
