import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { optionalProfilePicture, optionalThumb } from '../../../utils/validator';
import * as Yup from 'yup';
import { S3UserFiles, UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { Text } from '@pp-labs/ui-components';

import axios from 'axios';
import strings from '../../../Localization/Localizer';
import { LabelButton, TextArea, UploadButton } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';

/** interface for EditUserInfo props coming from parent component Profile  */
interface P {
  close: (refresh?: boolean) => void;
  user: UserPicture | null;
  mode: 'aboutMe' | 'header' | 'profile';
}

const editValidation = Yup.object({
  aboutMe: Yup.string(),
  thumb: optionalProfilePicture,
  header: optionalThumb,
}).required(strings.required);

interface FormValues {
  aboutMe: string;
  thumb: File | null;
  header: File | null;
}

/**
 * Allows users to edit their profile info
 */
const EditUserInfo = (props: P) => {
  const [uploading, setUploading] = useState<boolean>(false);

  const getErrors = (touched: any, errors: any) => {
    return {
      aboutMe: touched.aboutMe ? errors.aboutMe : '',
      thumb: touched.thumb ? errors.thumb : '',
      header: touched.header ? errors.header : '',
    };
  };

  const submitToServer = async (v: FormValues) => {
    const data = {
      aboutMe: v.aboutMe,
      thumb: v.thumb?.name,
      header: v.header?.name,
    };
    const res = await client.put('users', data);
    sendFiles(v, res.data);
  };

  const sendFiles = (data: FormValues, responseData: S3UserFiles) => {
    setUploading(true);
    const promises = [];
    if (data.thumb) {
      promises.push(
        axios.put(responseData.thumbUrl.url, data.thumb, {
          headers: { 'Content-Type': responseData.thumbUrl.contentType },
        })
      );
    }
    if (data.header) {
      promises.push(
        axios.put(responseData.headerUrl.url, data.header, {
          headers: { 'Content-Type': responseData.headerUrl.contentType },
        })
      );
    }
    Promise.all(promises).then(() => {
      setUploading(false);
      props.close(true);
    });
  };

  const initialValues: FormValues = {
    aboutMe: props.user?.aboutMe || '',
    thumb: null,
    header: null,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editValidation}
      onSubmit={async (values: FormValues, { resetForm }) => {
        setUploading(true);
        await submitToServer(values);
        resetForm();
      }}
    >
      {({ errors, touched, values, setFieldValue }) => {
        const et = getErrors(touched, errors);
        return (
          <Form>
            {props.mode === 'aboutMe' && (
              <TextArea name="aboutMe" label={strings.aboutMe} error={et.aboutMe} />
            )}
            {props.mode === 'profile' && (
              <UploadButton
                handleFile={setFieldValue}
                type="image"
                name="thumb"
                noText
                label={values.thumb?.name || strings.uploadingProfile}
                error={et.thumb}
                imgDimensions={`${strings.recommendedResolution}: 500 x 500 px`}
              />
            )}
            {props.mode === 'header' && (
              <UploadButton
                handleFile={setFieldValue}
                type="image"
                name="header"
                noText
                label={values.header?.name || strings.uploadingHeader}
                error={et.header}
                imgDimensions={`${strings.recommendedResolution}: 3000 x 520 px`}
              />
            )}
            {uploading ? (
              <Text as="p" variant="copy1">
                {strings.uploading}...
              </Text>
            ) : (
              <LabelButton type="submit" variant="primary">
                {strings.save}
              </LabelButton>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditUserInfo;
