import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from './dclxInterfaces';

/** Temporary Session handed out by Cognito API */
export interface TmpSession {
  challengeName: string;
}

/** Sign in using username and password against a already configured client. */
export const signIn = async (username: string, password: string): Promise<TmpSession> => {
  return await Auth.signIn(username, password);
};

/** Return value of Auth.forgotPassword
 *
 * Added separately because Auth types are garbage.*/
interface ForgotPassword {
  CodeDeliveryDetails: {
    Destination: string;
  };
}

/** Trigger forgot password flow */
export const forgotPassword = async (username: string): Promise<ForgotPassword> => {
  return Auth.forgotPassword(username);
};

/** Finish forgot password flow */
export const forgotPasswordSubmit = async (
  username: string,
  validationCode: string,
  password: string
) => {
  return Auth.forgotPasswordSubmit(username, validationCode, password);
};

/** Initialize MFA Setup */
export const setupTOTP = async (tempSession: TmpSession): Promise<string> => {
  return Auth.setupTOTP(tempSession);
};

/** Sets the preferred MFA method */
export const setPreferredMfa = async (
  tempSession: TmpSession,
  newMfa: 'TOTP' | 'SMS' | 'NOMFA'
): Promise<string> => {
  return Auth.setPreferredMFA(tempSession, newMfa);
};

/** Complete new password challenge */
export const completeNewPassword = async (
  tmp: TmpSession,
  newPassword: string
): Promise<TmpSession> => {
  return Auth.completeNewPassword(tmp, newPassword);
};

/** Don't know the properties. */
interface CurrentAuthedUser {}

/** Returns the current authenticated user. */
export const currentAuthenticatedUser = async (): Promise<CurrentAuthedUser | null> => {
  return Auth.currentAuthenticatedUser();
};

/** Changes the password of the current user. */
export const changePassword = async (oldPassword: string, newPassword: string) => {
  const user = await currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
};

/** Cognito uses different strings for mfa everywhere... */
type MFA = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
/** Returns the preferred mfa of the current authenticated user. */
export const getPreferredMFA = async (user: CurrentAuthedUser): Promise<MFA> => {
  return (await Auth.getPreferredMFA(user)) as MFA;
};

/** Finishes the setup MFA challenge. */
export const verifyTotpToken = async (tmpSession: TmpSession, code: string) => {
  return Auth.verifyTotpToken(tmpSession, code);
};

/** Finishes the MFA login challenge. */
export const confirmSignIn = async (tmpSession: TmpSession, code: string, mfa: MFA) => {
  await Auth.confirmSignIn(tmpSession, code, mfa);
};

/** Hook to sign out the current user forgiving. */
export const useSignOut = () => {
  return async () => {
    try {
      await Auth.signOut();
    } catch {}
  };
};

/** Returns the current user. */
export const currentSession = async () => Auth.currentSession();

/** Gets the id token of the current user. */
export const getIdToken = async (): Promise<string> => {
  try {
    return (await currentSession()).getIdToken().getJwtToken();
  } catch (e) {
    return '';
  }
};

/** Gets the access token of the current user. */
export const getAccessToken = async () => (await currentSession()).getAccessToken().getJwtToken();

/** Gets the user properties of the current user. */
export const getUser = async () =>
  (await currentSession()).getIdToken().decodePayload() as CognitoUser;

/** Sets up the cognito client. */
export const setupPool = (poolId: string, clientId: string) =>
  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId: poolId,
      userPoolWebClientId: clientId,
    },
  });
