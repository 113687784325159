import { Text } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { LabelButton } from '@pp-labs/ui-components';
import React, { useState } from 'react';
import DCLXTest from '../../Test/DCLXTest';
import { GetTest } from '../../../../ApiHandler/dclxInterfaces';
import { ProgressionReturn, TestProgression } from '../../../../progression';

/** interface for TrainingsSpacer props coming from parent components Topic and Quiz */
interface P {
  test: GetTest;
  progression: ProgressionReturn;
  refresh: () => void;
  block: string;
}

/** Single exam button */
const Test = (props: P) => {
  const [open, setOpen] = useState<boolean>(false);

  const isDisabled = () => {
    if (props.progression.info.solvedExams.includes(props.test.examId)) return true;
    const testProg: TestProgression = props.progression.matrix[props.block].find(
      (t) => 'examId' in t && t.examId === props.test.examId
    ) as unknown as TestProgression;
    return !testProg.progression.unlocked;
  };

  const closeTest = () => {
    setOpen(false);
  };

  const finishTest = () => {
    closeTest();
    props.refresh();
  };

  return (
    <>
      <div style={{ padding: '0 0 32px' }}>
        <Text as="p" variant="order4" weight={'bold'}>
          {props.test.examType === 'pre' ? strings.knowledgeAlready : strings.knowledgeNow}
        </Text>
        <LabelButton variant="secondary" onClick={() => setOpen(true)} disabled={isDisabled()}>
          {props.test.examType === 'pre' ? strings.startPreTest : strings.startPostTest}
        </LabelButton>
      </div>
      {open && (
        <DCLXTest test={props.test} open={open} closeDialog={closeTest} finish={finishTest} />
      )}
    </>
  );
};

export default Test;
