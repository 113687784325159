import { Component } from 'react';
import { Store } from '../store';
import { connect } from 'react-redux';
import { closeNotification } from './NotificationSlice';
import { withSnackbar } from 'notistack';

/** interface for Notification props coming from parent component app */
interface P {
  type?: 'success' | 'info' | 'warning' | 'error' | 'admin';
  persist?: boolean;
  message?: string;
  update: boolean;
  closeNotification: Function;
  enqueueSnackbar: Function;
}

/**
 * Toast Notifications, connect with triggerNotification redux reducer and use
 * (this.)props.triggerNotification(["message", "error"])
 * (this.)props.triggerNotification(["message", "error", true]) for a persistent notification.
 */

class Notification extends Component<P> {
  componentDidUpdate(prevProps: P) {
    if (prevProps.update !== this.props.update) {
      let message;
      if (this.props.type === 'admin') {
        message = 'admin' + this.props.message;
      } else {
        message = this.props.message;
      }
      this.props.enqueueSnackbar(message, {
        variant: this.props.type === 'admin' ? 'warning' : this.props.type,
        persist: this.props.persist!!,
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: Store) => ({
  message: state.notification.message,
  type: state.notification.type,
  update: state.notification.update,
  persist: state.notification.persist,
});

const mapDispatchToProps = { closeNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withSnackbar(Notification));
