import React, { Component } from 'react';
import { createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  AMA,
  BookingUser,
  CognitoUser,
  Session,
  TrainingWithUsers,
  UserPicture,
} from '../../../ApiHandler/dclxInterfaces';
import { isBigLOT, keepAliveCommand, noNullUser, sleep, toInt } from '../../../utils/convert';
import { getWebsocket } from '../../../ApiHandler/websocket';
import { AxiosInstance } from 'axios';
import { Store } from '../../../store';
import { connect } from 'react-redux';
import './LOT.scss';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { enterFullscreen, exitFullscreen } from '../../../AppSlice';
import LotBottomTabs from './LotBottomTabs';
import { HandQueue, HandUp } from './utils/HandQueue';
import { WaitingOverlay } from './utils/WaitingOverlay';
import { ABC } from './utils/ControlBar/ABCVote';
import { Thumbs } from './utils/ControlBar/ThumbsVote';
import { blackStyle } from 'features/Sessions/Watch/blackStyle';
import {
  disposeJitsi,
  getDisplayName,
  hangUp,
  initialiseJitsi,
  Jitsi,
  jitsiContainerId,
  jitsiOverrideConfig,
  jitsiSetAudioMuted,
  jitsiSetDisplayName,
  jitsiSetVideoMuted,
  jitsiSetView,
  jitsiToggleScreenshare,
  Role,
} from './jitsiAPI';
import {
  abcCommand,
  activeTrainerCommand,
  breakoutCommand,
  clearCommand,
  handLowCommand,
  handRaiseCommand,
  helloCommand,
  muteCommand,
  participantModeCommand,
  runningStateCommand,
  sendCommand,
  subscribeCommand,
  thumbCommand,
  toggleScreenshareCommand,
} from './lotCommands';
import {
  CustomHelloProps,
  GeneralCommand,
  MessageTypes,
  PrivateMessage,
  RunningState,
  RunningStateReturn,
} from './WebsocketCommands';
import { SideBar } from './SideBar';
import screenfull from 'screenfull';
import { Join, JoinPopup } from './JoinPopup';
import { InstructionPopup } from './InstructionPopup';
import { LotLeaveDialogue } from './LotLeaveDialogue';
import { controlBarHeight, getToolbar } from './config';
import strings from '../../../Localization/Localizer';
import { client } from '../../../ApiHandler/client';
import { ABCTotal, ThumbsTotal } from './utils/interactionTypes';
import { byVotes } from '../../../utils/sort';
import { ExportChat } from './ExportChat';
import { gutterStyle } from '../../../utils/gutter';
import IVSLivePlayer from 'features/Sessions/Watch/VideoPlayer/IVSLivePlayer';
import StreamAccessDialog from 'utils/StreamAccessDialog';
import { LotDescription } from './LotDescription';
import styles from 'features/Sessions/styles';
import { LotTopBar } from './LotTopBar';
import clsx from 'clsx';
import { LotFloatingButton } from './LotFloatingButton';
import { BaseBreakoutRoom, BreakoutRoom, lotContext, MoveTo } from './Provider/LotProvider';
import { ModHandle } from '../../../config';
import { TimeCountdown } from './Breakout/TimeCountdown';
import { BreakoutReminder } from './Breakout/BreakoutReminder';
import { SwitchStream } from './SwitchStream';

const useStyles = (theme: Theme) =>
  createStyles({
    ...blackStyle(theme),
    ...gutterStyle(theme),
    ...styles(theme),
    wrapper: {
      width: '100%',
      paddingTop: '56.25%',
      position: 'relative',
    },
    streamWrapper: {
      width: '100%',
      paddingTop: `calc(56.25% + ${controlBarHeight}px)`,
      position: 'relative',
    },
    jitsi: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    player: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: controlBarHeight,
      left: 0,
    },
    playerMarginTop: {
      marginTop: '0 !important',
    },
    playerWrapper: {
      width: '80%',
      position: 'relative',
      marginTop: '-82px',
      [theme.breakpoints.down('xl')]: {
        width: '75%',
      },
      [theme.breakpoints.down('lg')]: {
        width: '71%',
      },
      [theme.breakpoints.down('md')]: {
        width: '70%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    sideBar: {
      width: '20%',
      [theme.breakpoints.down('xl')]: {
        width: '25%',
      },
      [theme.breakpoints.down('lg')]: {
        width: '29%',
      },
      [theme.breakpoints.down('md')]: {
        width: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    lotDescription: {
      padding: '34px 0 0px 56px',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        paddingRight: 0,
      },
    },
    mobileSideBar: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    greyTab: {
      width: '100%',
      height: '58px',
      background: '#333333',
    },
  });

/** interface for LOT props coming from parent component App  */
interface P extends WithStyles<typeof useStyles> {
  match: {
    params: {
      id: string | number;
    };
  };
  user: CognitoUser;
  triggerNotification: (o: string[]) => void;
  fullscreen: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
  tablet: boolean;
  mobile: boolean;
}

type PopupOpen = 'instruct' | 'join' | 'leave' | 'bigLot' | null;

/** interface for LOT component state  */
interface S {
  training?: TrainingWithUsers;
  session?: Session;
  trainer?: UserPicture;
  cotrainer?: UserPicture;
  users: Array<BookingUser>;
  handRaise?: boolean;
  handsUp: Array<HandUp>;
  ama: Array<AMA>;
  ourAma: Array<AMA>;
  activeABC: ABC;
  activeThumb: Thumbs;
  abcTotal: ABCTotal;
  thumbsTotal: ThumbsTotal;
  currentlyOnline: string[] | undefined;
  refSessions: Array<Session>;
  messages: PrivateMessage[];
  videoHeight: number;
  muted: boolean;
  canTakeOverTraining: boolean;
  runningState: RunningState;
  isTrainerOrCotrainer: boolean;
  streamingLot: boolean;
  jitsi: Jitsi;
  room: string;
  videoUrl?: string;
  iAmActiveTrainer: boolean;
  lotCancel: boolean;
  changesToChat: string[];
  changeToPoll: boolean;
  popupOpen: PopupOpen;
  prevView: RunningState;
  allMuted: boolean;
  preview: boolean;
  breakoutRooms: BreakoutRoom[];
  allRooms: BreakoutRoom[];
  myBreakoutRoom: BreakoutRoom | null;
  originalJoin: Join | null;
  selectedChat: BaseBreakoutRoom | 'main';
  roomSettings: RoomSetting[];
  isScreenShareAllowed: boolean;
  myId: string;
  currentlySharing: string | null;
  preJoin: boolean;
}

type RoomSetting = { room: string; settings: { video: boolean; audio: boolean } };

/**
 * Main component for LOTs
 */

type Interval = ReturnType<typeof setInterval>;

class LOT extends Component<P, S> {
  wrapper: any;
  jitsiWrapper: any;
  websocket: WebSocket | null = null;
  keepAliveInterval: Interval | null = null;
  checkForConnection: Interval | null = null;
  forceTrainerDisplayNameInterval: Interval | null = null;
  websocketCurrentlyBuilding: boolean = false;
  firstTileViewChangeHappened: boolean = false;
  switching: boolean = false;

  constructor(props: P) {
    super(props);
    this.state = {
      training: undefined,
      session: undefined,
      trainer: undefined,
      users: [],
      ama: [],
      ourAma: [],
      handsUp: [],
      activeABC: ABC.NONE,
      activeThumb: Thumbs.NONE,
      abcTotal: { '1': 0, '2': 0, '3': 0 },
      thumbsTotal: { '1': 0, '2': 0 },
      currentlyOnline: undefined,
      refSessions: [],
      messages: [],
      videoHeight: 700,
      muted: true,
      canTakeOverTraining: false,
      runningState: -1,
      isTrainerOrCotrainer: false,
      streamingLot: false,
      room: '',
      jitsi: undefined,
      videoUrl: undefined,
      iAmActiveTrainer: false,
      lotCancel: false,
      changesToChat: [],
      changeToPoll: false,
      popupOpen: 'instruct',
      prevView: 2,
      allMuted: false,
      preview: false,
      breakoutRooms: [],
      allRooms: [],
      myBreakoutRoom: null,
      originalJoin: null,
      selectedChat: 'main',
      roomSettings: [],
      isScreenShareAllowed: false,
      myId: '',
      currentlySharing: null,
      preJoin: false,
    };
    this.jitsiWrapper = React.createRef();
    this.wrapper = React.createRef();
  }

  async componentDidMount() {
    this.addFullscreenEventListeners();
    await this.fetch();
    window.addEventListener('resize', this.onResize);
    this.onResize();
    await this.buildWebsocket();
    this.keepAlive();
    this.checkWebsocket();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    disposeJitsi(this.state.jitsi);
    this.websocket?.close();
    if (this.keepAliveInterval) clearInterval(this.keepAliveInterval);
    if (this.checkForConnection) clearInterval(this.checkForConnection);
    if (this.forceTrainerDisplayNameInterval) clearInterval(this.forceTrainerDisplayNameInterval);
  }

  shouldStartLiveStream = (showNotification: boolean): boolean => {
    const now = Date.now() / 1000;
    // allow the training so start an hour early.
    const r =
      now > (this.state.training?.startAt || 0) - 3600 && now < (this.state.training?.endAt || 0);
    if (showNotification && !r) {
      this.props.triggerNotification([strings.notAllowedToStreamNoTraining, 'error']);
    }
    return r;
  };

  fetch = async () => {
    const trainingsId = toInt(this.props.match.params.id);
    const training: TrainingWithUsers = (await client.get(`trainings/${trainingsId}`)).data;
    const users: TrainingWithUsers = (await client.get(`trainings/${trainingsId}/bookings/users`))
      .data;
    const session: Session = (
      await client.get(`sessions/${training.sessionId}`, {
        withCredentials: true,
      })
    ).data;
    const trainer: UserPicture = (await client.get(`users/${training.trainerId}`)).data;
    const cotrainer: UserPicture | undefined = training.coTrainerId
      ? (await client.get(`users/${training.coTrainerId}`)).data
      : undefined;
    await client.get(`trainings/${trainingsId}/attended`);
    const me: string = this.props.user.sub;
    const isTrainerOrCotrainer = training.trainerName === me || training.coTrainerName === me;
    const isStreamingLot = isBigLOT(training);

    this.setState({
      training: training,
      session: session,
      trainer: trainer,
      cotrainer: cotrainer,
      users: users.bookingsUsers,
      streamingLot: isStreamingLot,
      isTrainerOrCotrainer: isTrainerOrCotrainer,
      room: training.conference,
      videoUrl: training.playbackUrls?.[0],
    });
    if (isTrainerOrCotrainer) {
      const r = session.sessionRefs || [];
      const s: Array<Session> = (await client.get(`sessions`)).data;
      const refs: Array<Session> = [];
      for (let i = 0; i < r.length; i++) {
        const f = s.find((a) => a.sessionId === r[i]);
        if (f) refs.push(f);
      }
      this.setState({ refSessions: refs });
      await this.fetchQuestions(r, client, users.bookingsUsers);
    }
  };

  fetchQuestions = async (
    sessionRefs: Array<number>,
    h: AxiosInstance,
    users: Array<BookingUser>
  ) => {
    const amas: Array<AMA> = [];
    for (let i = 0; i < sessionRefs.length; i++) {
      amas.push(...(await h.get(`sessions/${sessionRefs[i]}/amas`)).data);
    }
    amas.sort(byVotes);
    const our = amas.filter((ama) => !!users.find((u) => u.user.username === ama.username));
    this.setState({ ama: amas, ourAma: our });
  };

  toggleHand = () => {
    const prev = this.state.handRaise;
    if (prev) {
      this.websocketSend(handLowCommand(this.state.room, this.props.user.sub));
    } else {
      this.websocketSend(handRaiseCommand(this.state.room, this.props.user.sub));
    }
    this.setState({ handRaise: !prev });
  };

  lowerHand = () => {
    this.websocketSend(handLowCommand(this.state.room, this.props.user.sub));
    this.setState({ handRaise: false });
  };

  toggleABC = (s: ABC) => {
    if (this.state.activeABC === s) {
      this.setAbc(ABC.NONE);
      this.setState({ activeABC: ABC.NONE });
    } else {
      this.setAbc(s);
      this.setState({ activeABC: s });
    }
  };

  setAbc = (abc: ABC) => {
    this.websocketSend(abcCommand(this.state.room, abc));
  };

  setThumb = (thumb: Thumbs) => {
    this.websocketSend(thumbCommand(this.state.room, thumb));
  };

  toggleThumb = (s: Thumbs) => {
    if (this.state.activeThumb === s) {
      this.setThumb(Thumbs.NONE);
      this.setState({ activeThumb: Thumbs.NONE });
    } else {
      this.setThumb(s);
      this.setState({ activeThumb: s });
    }
  };

  resetSurveys = () => {
    if (this.state.isTrainerOrCotrainer) {
      this.setState({
        abcTotal: { '1': 0, '2': 0, '3': 0 },
        thumbsTotal: { '1': 0, '2': 0 },
      });
    } else {
      this.setState({
        activeThumb: Thumbs.NONE,
        activeABC: ABC.NONE,
        abcTotal: { '1': 0, '2': 0, '3': 0 },
        thumbsTotal: { '1': 0, '2': 0 },
      });
    }
  };

  changeAbc = (obj: ABCTotal, calledByHello?: boolean) =>
    this.setState({ abcTotal: obj, changeToPoll: !calledByHello });
  changeThumbs = (obj: ThumbsTotal, calledByHello?: boolean) =>
    this.setState({ thumbsTotal: obj, changeToPoll: !calledByHello });

  clear = () => this.websocketSend(clearCommand(this.state.room));

  setCanTakeOverTraining = (can: boolean) => {
    this.setState({ canTakeOverTraining: can });
  };

  setRunningState = (rState: RunningState, resetPre: boolean) => {
    if (this.state.preJoin && resetPre) this.setState({ preJoin: false });
    if (rState >= 2 && this.state.runningState <= 1) {
      this.setState({ runningState: rState });
      this.joinTraining(rState, false);
    } else if (rState === 1 && this.state.runningState >= 2) {
      this.setState({ runningState: rState, popupOpen: 'leave' });
    } else {
      this.setState({ runningState: rState });
    }
  };

  changeStream = (newStream: number) => {
    const newUrl = this.state.training?.playbackUrls?.[newStream];
    if (newUrl) this.setState({ videoUrl: newUrl });
  };

  changeHand = (up: boolean, sub: string) => {
    const n = this.state.handsUp.slice();
    if (up) {
      n.push({
        sub: sub,
        date: Date.now(),
      });
    } else {
      const f = n.findIndex((s) => s.sub === sub);
      if (f !== -1) n.splice(f, 1);
    }
    n.sort((a, b) => a.date - b.date);
    this.setState({ handsUp: n });
  };

  setOnline = (online: Array<string>) => {
    this.setState({ currentlyOnline: online });
  };

  onMuteStatusChanged = (muted: boolean) => {
    this.setState({ muted: muted });
    this.setRoomSettings('change', this.state.myBreakoutRoom, muted, undefined);
    if (this.state.allMuted && !muted && !this.state.isTrainerOrCotrainer) {
      this.props.triggerNotification([strings.cantUnmute, 'success']);
      this.getMutedByTrainer();
    }
  };

  onVideoMuteStatusChanged = (muted: boolean) => {
    this.setRoomSettings('change', this.state.myBreakoutRoom, undefined, muted);
  };

  setRoomSettings = (
    origin: 'change' | 'initial',
    room: BaseBreakoutRoom | null,
    audioMuted?: boolean,
    videoMuted?: boolean
  ): RoomSetting | null => {
    if (this.switching && origin !== 'initial') return null;
    const newSettings = this.state.roomSettings.slice();
    const currentRoomIndex = newSettings.findIndex((r) => r.room === (room?.Id || 'main'));
    const currentRoom: RoomSetting | undefined = newSettings[currentRoomIndex];
    if (
      origin === 'change' &&
      currentRoom &&
      (audioMuted === currentRoom.settings.audio || videoMuted === currentRoom.settings.video)
    ) {
      return currentRoom;
    }
    const fallbackRoom: RoomSetting | undefined =
      newSettings.find((r) => r.room === 'main') || newSettings[0];
    const settings: RoomSetting = {
      room: room?.Id || 'main',
      settings: {
        audio:
          audioMuted !== undefined
            ? audioMuted
            : currentRoom
            ? currentRoom.settings.audio
            : fallbackRoom
            ? fallbackRoom.settings.audio
            : true,
        video:
          videoMuted !== undefined
            ? videoMuted
            : currentRoom
            ? currentRoom.settings.video
            : fallbackRoom
            ? fallbackRoom.settings.video
            : true,
      },
    };
    if (currentRoomIndex >= 0) newSettings.splice(currentRoomIndex, 1);
    newSettings.push(settings);
    this.setState({ roomSettings: newSettings });
    return settings;
  };

  fetchIfNotInList = async (sub: string): Promise<null | Array<BookingUser>> => {
    const inUsers =
      this.state.trainer?.username === sub ||
      this.state.cotrainer?.username === sub ||
      this.state.users.find((userEntry) => userEntry.user.username === sub);
    if (inUsers) return null;
    const userPictureData: UserPicture = (await client.get(`users/name/${sub}`)).data;
    const n = this.state.users.slice();
    n.push({ user: userPictureData, bookingId: 0 });
    return n;
  };

  changeOnline = async (add: boolean, online: string) => {
    // lets ignore this until "welcome" was received
    if (this.state.currentlyOnline === undefined) {
      setTimeout(() => {
        this.changeOnline(add, online);
      }, 1000);
      return;
    }
    let newUsers: null | Array<BookingUser> = null;
    const n = this.state.currentlyOnline.slice();
    let change = false;
    if (add) {
      const inOnline = n.find((o) => o === online);
      newUsers = await this.fetchIfNotInList(online);
      if (!inOnline) {
        n.push(online);
        change = true;
      } else if (newUsers) {
        // it may be possible that a user is already online but not in the list
        change = true;
      }
    } else {
      const f = n.findIndex((o) => o === online);
      if (f !== -1) {
        n.splice(f, 1);
        change = true;
      }
    }
    if (!change) return;
    this.notifyChangeOnline(newUsers, online, add);
    let newState: any = { currentlyOnline: n };
    if (newUsers) newState.users = newUsers;
    this.setState(newState);
  };

  notifyChangeOnline = (newUsers: null | Array<BookingUser>, online: string, add: boolean) => {
    // only for trainers and only if the lot is not longer than 105 Minutes.
    if (
      !this.state.isTrainerOrCotrainer ||
      !this.state.training ||
      (this.state.training.endAt - this.state.training.startAt) / 60 > 105
    )
      return;
    const p = (newUsers || this.state.users).find((u) => u.user.username === online);
    if (!p) return;
    const name = `${p.user.givenName} ${p.user.familyName}`;
    this.props.triggerNotification([
      `${strings.participant} ${name} ${add ? strings.joined : strings.left}.`,
      'info',
    ]);
  };

  /** For visitors every message is relevant, as they can only recieve those from their current room
   * As trainer a message is only relevant if it is written in the current room or includes the mod handle
   */
  messageChangeIsRelevant = (message: PrivateMessage): boolean => {
    if (!this.state.isTrainerOrCotrainer) return true;
    if (message.BreakoutRoom === (this.state.myBreakoutRoom?.Id || 'main')) return true;
    return message.Message.includes(ModHandle);
  };

  receiveMessage = (message: PrivateMessage) => {
    const id = message.BreakoutRoom;
    const changes = this.state.changesToChat.slice();
    if (!changes.includes(id) && this.messageChangeIsRelevant(message)) changes.push(id);
    this.setState({
      messages: [message, ...this.state.messages],
      changesToChat: changes,
    });
  };

  getTargetRoom = (): string => {
    if (!this.state.isTrainerOrCotrainer) return this.state.myBreakoutRoom?.Id || 'main';
    return this.state.selectedChat === 'main' ? 'main' : this.state.selectedChat.Id;
  };

  sendMsg = (m: string) => {
    this.websocketSend(sendCommand(this.state.room, m, this.getTargetRoom()));
  };

  isOnline = (username?: string) => {
    if (username) {
      if (this.props.user.sub === username) return true;
      return !!this.state.currentlyOnline?.find((u) => u === username);
    }
    if (this.props.user.sub === this.state.trainer?.username) return true;
    return !!this.state.currentlyOnline?.find((u) => u === this.state.trainer?.username);
  };

  addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', this.handleEsc);
    document.addEventListener('mozfullscreenchange', this.handleEsc);
    document.addEventListener('fullscreenchange', this.handleEsc);
    document.addEventListener('MSFullscreenChange', this.handleEsc);
  };

  handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      this.exitFullscreen();
    }
  };

  exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    this.props.exitFullscreen();
  };

  toggleFullscreen = () => {
    if (this.props.fullscreen) {
      this.exitFullscreen();
    } else {
      this.props.enterFullscreen();
      // @ts-ignore
      screenfull.request();
    }
  };

  onResize = () => {
    if (this.jitsiWrapper?.current) {
      this.setState({ videoHeight: this.jitsiWrapper.current.offsetHeight });
    } else {
      setTimeout(() => {
        this.onResize();
      }, 1000);
    }
  };

  keepAlive = () => {
    this.keepAliveInterval = setInterval(() => {
      this.websocketSend(keepAliveCommand(`conf-${this.state.room}`));
    }, 60000);
  };

  /**
   * Forces the display name of the active trainer every 5 seconds.
   * We need this, because in the case of a disconnect jitsi reloads with the display name "inactive",
   * but the Websocket does not send the welcome message, containing the info who is the active trainer,
   * at every disconnect. This results in the trainer being in an Backup-Trainer state but without the
   * ability to take over the training. Now we store the info who is active Trainer in the state
   * and are able to set his display name (if he is active trainer) every 5 seconds. This method only runs
   * for potential trainers (Trainer or Backup(Co)-Trainer.
   */

  forceTrainerDisplayName = () => {
    this.forceTrainerDisplayNameInterval = setInterval(async () => {
      if (this.state.iAmActiveTrainer) this.setMeAsTrainer();
    }, 5000);
  };

  checkWebsocket = () => {
    this.checkForConnection = setInterval(async () => {
      if (!this.isWebsocketOpen()) {
        await this.buildWebsocket();
      }
    }, 2000);
  };

  isWebsocketOpen = (): boolean => {
    return !!this.websocket && this.websocket?.readyState === this.websocket?.OPEN;
  };

  websocketSend = (command: string) => {
    if (this.isWebsocketOpen()) {
      this.websocket?.send(command);
    } else {
      this.buildWebsocket().then(() => {
        setTimeout(() => {
          this.websocket?.send(command);
        }, 1000);
      });
    }
  };

  setMeAsNone = () => {
    this.setDisplayName('participant');
  };

  setDisplayName = (role: Role) => {
    jitsiSetDisplayName(this.state.jitsi, getDisplayName(this.props.user, role));
  };

  setParticipantMode = (mode: string) => {
    const muted = mode === 'muted';
    this.setState({ allMuted: muted });
    if (muted) this.getMutedByTrainer();
  };

  getMutedByTrainer = () => {
    if (!this.state.isTrainerOrCotrainer) {
      this.setAudioMuted(true);
    }
  };

  changeRunningState = (runningState: RunningStateReturn, stateOnly?: boolean) => {
    const rs = toInt(runningState) as RunningState;
    this.setRunningState(rs, true);
    if (!stateOnly) this.setJitsiToRunningState(rs);
  };

  leaveCall = async () => {
    if (this.state.jitsi) {
      hangUp(this.state.jitsi);
      await sleep(500);
      disposeJitsi(this.state.jitsi);
      this.firstTileViewChangeHappened = false;
      this.setState({ jitsi: undefined });
    }
  };

  setJitsiToRunningState = async (rs: RunningState) => {
    if (rs === 1) {
      await this.leaveCall();
    }
    if (rs === 2 || rs === 3) jitsiSetView(this.state.jitsi, rs);
  };

  setAudioMuted = (muted: boolean) => jitsiSetAudioMuted(this.state.jitsi, muted);

  setVideoMuted = (muted: boolean) => jitsiSetVideoMuted(this.state.jitsi, muted);

  setActiveTrainer = (activeTrainer: string, stateOnly?: boolean) => {
    if (!this.state.isTrainerOrCotrainer) return;
    if (!activeTrainer) {
      this.setCanTakeOverTraining(false);
      this.sendActiveTrainer();
      return;
    }
    if (activeTrainer !== this.props.user.sub) {
      this.setCanTakeOverTraining(true);
      this.setState({ iAmActiveTrainer: false });
      if (!stateOnly) this.setMeAsCoTrainer();
    } else {
      this.setCanTakeOverTraining(false);
      this.setState({ iAmActiveTrainer: true });
      if (!stateOnly) this.setMeAsTrainer();
    }
  };

  sendActiveTrainer = () => {
    this.websocketSend(activeTrainerCommand(this.state.room, this.props.user.sub));
  };

  setMeAsTrainer = () => {
    this.setDisplayName('trainer');
  };

  setMeAsCoTrainer = () => {
    this.setDisplayName('backup');
  };

  setInitialMessages = (m: PrivateMessage[]) => {
    this.setState({ messages: m });
  };

  hello = (online: Array<string>, p?: CustomHelloProps) => {
    this.setOnline(online);
    if (!p) return;
    if (p.messages) this.setInitialMessages(p.messages);
    this.changeRunningState(p.runningState, true);
    this.setState({ allMuted: p.participantMode === 'muted' });
    this.changeAbc(p.abc, true);
    this.changeThumbs(p.thumbs, true);
    if (this.state.isTrainerOrCotrainer) {
      this.setActiveTrainer(p.trainer, true);
    }
    this.onScreenShareAllowed(p.screenshareAllowed);
    this.setState({ isScreenShareAllowed: p.screenshareAllowed });
    this.breakoutRoomChange(p.breakoutRooms || []);
    this.changeStream(p.currentStream);
  };

  goodBye = async (sub: string) => {
    await this.changeOnline(false, sub);
  };

  buildWebsocket = async () => {
    if (this.websocketCurrentlyBuilding) return;
    this.websocketCurrentlyBuilding = true;
    const ws = await getWebsocket();
    ws.onopen = () => {
      ws.send(subscribeCommand(this.state.room));
      ws.send(helloCommand(this.state.room));
      this.websocketCurrentlyBuilding = false;
    };
    ws.onmessage = (m) => {
      const parsed: GeneralCommand = JSON.parse(m.data);
      // Ignore messages sent in other channels. This should rarely happen but better be safe.
      if (parsed.ChannelId !== `conf-${this.state.room}`) return;
      if ('CommandKey' in parsed) {
        switch (parsed.CommandKey) {
          case 'hand':
            this.changeHand(true, parsed.CommandValue);
            break;
          case 'unhand':
            this.changeHand(false, parsed.CommandValue);
            break;
          case 'mute':
            this.getMutedByTrainer();
            break;
          case 'participantMode':
            this.setParticipantMode(parsed.CommandValue);
            break;
          case 'runningState':
            this.changeRunningState(parsed.CommandValue);
            break;
          case 'setTrainer':
            this.setActiveTrainer(parsed.CommandValue);
            break;
          case 'abcTotal':
            this.changeAbc(parsed.CommandValue);
            break;
          case 'thumbsTotal':
            this.changeThumbs(parsed.CommandValue);
            break;
          case 'cleared':
            this.resetSurveys();
            break;
          case 'hello':
            this.changeOnline(true, parsed.CommandValue);
            break;
          case 'goodbye':
            this.goodBye(parsed.CommandValue);
            break;
          case 'breakoutRooms':
            this.breakoutRoomChange(parsed.CommandValue);
            break;
          case 'screenshareAllowed':
            this.onScreenShareAllowed(parsed.CommandValue);
            break;
          case 'streamChange':
            this.changeStream(parsed.CommandValue.state);
            break;
        }
      }
      if (parsed.Action === 'welcome' && 'Users' in parsed) {
        this.hello(parsed.Users, parsed.CustomProperties);
      }
      if (parsed.Action === 'message' && parsed.MessageType === MessageTypes.TRAINING) {
        this.receiveMessage(parsed as PrivateMessage);
      }
    };
    this.websocket = ws;
  };

  sendMuteCommand = () => {
    this.props.triggerNotification([strings.participantsTemporarilyMuted, 'success']);
    this.websocketSend(muteCommand(this.state.room));
  };

  sendChangeParticipantModeCommand = (mute: boolean) => {
    const notification = mute ? strings.participantsPermanentlyMuted : strings.participantsUnmuted;
    this.props.triggerNotification([notification, 'success']);
    this.websocketSend(participantModeCommand(this.state.room, mute ? 'muted' : 'audio'));
  };

  sendRunningState = (state: RunningState) => {
    this.websocketSend(runningStateCommand(this.state.room, state));
  };

  /** This resets the changes on the chat that is the current (aka probably left at the moment). */
  resetChatChanges = () => {
    const id = this.state.selectedChat === 'main' ? 'main' : this.state.selectedChat.Id;
    const newChanges = this.state.changesToChat.slice();
    const index = newChanges.findIndex((c) => c === id);
    if (index < 0) return;
    newChanges.splice(index, 1);
    this.setState({ changesToChat: newChanges });
  };

  resetPollChanges = () => {
    this.setState({ changeToPoll: false });
  };

  joinTraining = (runningState: RunningState, fromInstruct: boolean) => {
    /* Skip if we are not closing the instruct popup and if the current popup is the instruct one */
    if (!fromInstruct && this.state.popupOpen === 'instruct') return;
    let newPopup: PopupOpen;
    if (this.state.streamingLot) {
      newPopup =
        this.state.isTrainerOrCotrainer && this.shouldStartLiveStream(true) ? 'bigLot' : null;
    } else {
      newPopup = runningState >= 2 ? 'join' : null;
    }
    this.setState({ popupOpen: newPopup });
  };

  trainerPreJoin = () => {
    this.setState({ preJoin: true });
    this.setRunningState(3, false);
  };

  closeInstructPopup = () => {
    this.joinTraining(this.state.runningState, true);
  };

  closeBigLotPopup = () => this.setState({ popupOpen: null });

  joinConferenceWith = async (j: Join) => {
    this.setState({ popupOpen: null, originalJoin: j });
    await this.startJitsi(j);
  };

  tileViewListener = (enabled: boolean) => {
    const desired = this.state.runningState === 3;
    if (enabled === desired) return;
    this.setJitsiToRunningState(this.state.runningState);
    setTimeout(() => this.setJitsiToRunningState(this.state.runningState), 2000);
  };

  screenShareListener = (enabled: boolean) => {
    if (this.state.preJoin) return;
    if (enabled) {
      this.setState({ prevView: this.state.runningState });
      this.sendRunningState(2);
    } else {
      this.sendRunningState(this.state.prevView);
    }
  };

  contentSharingParticipantsChanged = (d: { data: string[] }) => {
    this.setState({ currentlySharing: d.data[0] });
  };

  onConferenceJoin = (d: { id: string }) => {
    this.setState({ myId: d.id });
  };

  onScreenShareAllowed = (allowed: boolean) => {
    this.setState({ isScreenShareAllowed: allowed });
    if (this.state.myBreakoutRoom) return;
    this.applyScreenShareSettings(allowed);
  };

  applyScreenShareSettings = (allowed: boolean) => {
    if (this.state.isTrainerOrCotrainer) return;
    jitsiOverrideConfig(this.state.jitsi, {
      toolbarButtons: getToolbar(allowed),
    });

    if (!allowed) {
      if (this.state.currentlySharing === this.state.myId) {
        jitsiToggleScreenshare(this.state.jitsi);
      }
    }
  };

  trainerAllowScreenshare = () => {
    this.websocketSend(toggleScreenshareCommand(this.state.room, !this.state.isScreenShareAllowed));
  };

  leaveTraining = () => {
    if (this.state.isTrainerOrCotrainer) {
      if (this.state.runningState >= 2) this.sendRunningState(1);
    } else {
      this.setState({ popupOpen: 'leave' });
    }
  };

  sendBreakoutStat = async () => {
    try {
      await client.post(`trainings/breakout`, {
        Id: this.state.myBreakoutRoom?.Id || 'main',
        Title: this.state.myBreakoutRoom?.Title || 'Main Conference',
      });
    } catch {}
  };

  startJitsi = async (j?: Join) => {
    this.switching = true;
    if (this.state.jitsi) await this.leaveCall();
    this.sendBreakoutStat();
    const subRoom = this.state.myBreakoutRoom?.Id ? `-${this.state.myBreakoutRoom?.Id}` : '';
    const room = `${this.state.room}${subRoom}`;
    const settings = this.setRoomSettings(
      'initial',
      this.state.myBreakoutRoom,
      j ? true : undefined,
      j ? j !== 'video' : undefined
    )!;
    const jitsi = await initialiseJitsi({
      user: this.props.user,
      room: room,
      muteListener: this.onMuteStatusChanged,
      videoMuteListener: this.onVideoMuteStatusChanged,
      role: this.state.iAmActiveTrainer
        ? 'trainer'
        : this.state.isTrainerOrCotrainer
        ? 'backup'
        : 'participant',
      jitsiUrl: process.env.REACT_APP_JITSI_URL || '',
      tileViewListener: this.tileViewListener,
      screenShareListener: this.screenShareListener,
      videoConferenceJoined: this.onConferenceJoin,
      audioMutedInitial: settings.settings.audio,
      videoMutedInitial: settings.settings.video,
      screenshareChanged: this.contentSharingParticipantsChanged,
      overwrite: {
        toolbarButtons: getToolbar(
          this.state.isTrainerOrCotrainer ||
            !!this.state.myBreakoutRoom ||
            this.state.isScreenShareAllowed
        ),
      },
    });

    this.setState({ jitsi: jitsi }, () => {
      if (this.state.isTrainerOrCotrainer) this.forceTrainerDisplayName();
      setTimeout(() => (this.switching = false), 3000);
    });
  };

  togglePreview = () => {
    const now = this.state.preview;
    if (!now) {
      if (!this.shouldStartLiveStream(true)) return;
    }
    this.setState({ preview: !now });
  };

  bigLotPopup = () => {
    this.setState({ popupOpen: 'bigLot' });
  };

  sideBar = () => {
    return (
      <div className={this.props.classes.sideBar}>
        <SideBar
          runningState={this.state.runningState}
          toggleThumbs={this.toggleThumb}
          uiThumbs={this.state.activeThumb}
          toggleAbc={this.toggleABC}
          uiAbc={this.state.activeABC}
          thumbs={this.state.thumbsTotal}
          abc={this.state.abcTotal}
          maxParts={this.state.currentlyOnline?.length || 10}
          sendClearCommand={this.clear}
        />
      </div>
    );
  };

  breakoutRoomChange = async (newRooms: BreakoutRoom[]) => {
    const activeRooms = newRooms.filter((r) => r.CustomProperties?.active);
    this.setState({ allRooms: newRooms, breakoutRooms: activeRooms });
    const myNextRoom =
      activeRooms.find((r) => r.Participants.includes(this.props.user.sub)) || null;
    const prev = Object.assign({}, this.state.myBreakoutRoom);
    this.setState({ myBreakoutRoom: myNextRoom }, async () => {
      if (myNextRoom?.Id !== prev?.Id) {
        // avoid creating two rooms. If we don't have a jitsi yet this will be handled by the dialog
        if (this.state.jitsi) {
          await this.startJitsi();
        }
      }
    });
  };

  moveTo: MoveTo = (newDist) => {
    const newRooms = this.state.breakoutRooms.slice();
    newDist.forEach(({ users, id }) => {
      const newRoom = newRooms.findIndex((r) => r.Id === id);
      users.forEach((user) => {
        const currentRoom = newRooms.findIndex((r) => r.Participants.includes(user));

        // remove this user from the oldRoom if it exists
        if (currentRoom > -1) {
          newRooms[currentRoom].Participants = newRooms[currentRoom].Participants.filter(
            (p) => p !== user
          );
        }
        // add them to the new Room. If the Room is main or offline just remove, don't add.
        if (newRoom >= 0) newRooms[newRoom].Participants.push(user);
      });
    });
    this.websocketSend(breakoutCommand(this.state.room, newRooms));
  };

  createBreakoutRoom = (newRoom: BreakoutRoom) => {
    const newRooms = [...this.state.breakoutRooms, newRoom];
    this.websocketSend(breakoutCommand(this.state.room, newRooms));
  };

  deleteBreakoutRoom = (oldRoom: BaseBreakoutRoom | 'all') => {
    const newRooms = this.state.breakoutRooms.slice();
    if (oldRoom === 'all') {
      newRooms.forEach((r) => (r.CustomProperties.active = false));
    } else {
      const myRoom = this.state.breakoutRooms.findIndex((r) => r.Id === oldRoom.Id);
      if (myRoom >= 0) {
        newRooms[myRoom].CustomProperties.active = false;
      }
    }
    this.websocketSend(breakoutCommand(this.state.room, newRooms));
  };

  updateBreakoutRoom = (oldRoom: BaseBreakoutRoom, newTitle: string) => {
    const newRooms = this.state.breakoutRooms.slice();
    const myRoom = this.state.breakoutRooms.findIndex((r) => r.Id === oldRoom.Id);
    if (myRoom >= 0) {
      newRooms[myRoom].Title = newTitle;
    }
    this.websocketSend(breakoutCommand(this.state.room, newRooms));
  };

  closeBreakoutRoomSoon = (oldRoom: BaseBreakoutRoom | 'all', ms: number) => {
    const newRooms = this.state.breakoutRooms.slice();
    const deactivateAt = Date.now() + ms;
    if (oldRoom === 'all') {
      newRooms.forEach((r) => (r.CustomProperties.deactivateAt = deactivateAt));
    } else {
      const myRoom = this.state.breakoutRooms.findIndex((r) => r.Id === oldRoom.Id);
      if (myRoom >= 0) {
        newRooms[myRoom].CustomProperties.deactivateAt = deactivateAt;
      }
    }
    this.websocketSend(breakoutCommand(this.state.room, newRooms));
  };

  render() {
    if (
      !this.state.training ||
      !this.state.session ||
      !this.state.trainer ||
      this.state.runningState === -1
    )
      return null;
    const trainers = [this.state.trainer];
    if (this.state.cotrainer) trainers.push(this.state.cotrainer);
    const isTrainer = this.state.isTrainerOrCotrainer;
    const participants = this.state.users.map((u) => u.user);
    return (
      <lotContext.Provider
        value={{
          type: 'lot',
          breakoutRooms: {
            roomsWithDel: this.state.allRooms,
            rooms: this.state.breakoutRooms,
            moveTo: this.moveTo,
            createRoom: this.createBreakoutRoom,
            myBreakoutRoom: this.state.myBreakoutRoom,
            deleteRoom: this.deleteBreakoutRoom,
            closeRoomSoon: this.closeBreakoutRoomSoon,
            updateRoom: this.updateBreakoutRoom,
          },
          handsUp: { handQueue: this.state.handsUp },
          online: { onlineSubs: this.state.currentlyOnline },
          users: {
            participants: participants,
            trainers: trainers,
            allUsers: [...trainers, ...participants],
            iAmTrainer: isTrainer,
          },
          search: {
            currentSearch: '',
          },
          chat: {
            changeToChats: this.state.changesToChat,
            resetChanges: this.resetChatChanges,
            selectedChat: this.state.selectedChat,
            messages: this.state.messages,
            switchSelectedChat: (chat) => this.setState({ selectedChat: chat }),
            sendMessage: this.sendMsg,
          },
          polls: {
            changeToPoll: this.state.changeToPoll,
            resetChanges: this.resetPollChanges,
          },
          websocket: {
            send: this.websocketSend,
          },
          general: {
            training: this.state.training,
            streamUrl: this.state.videoUrl || '',
            session: this.state.session,
            roomId: this.state.room,
          },
        }}
      >
        <Grid container spacing={0} innerRef={this.wrapper} style={{ overflow: 'auto' }}>
          <LotTopBar
            isTrainer={this.state.isTrainerOrCotrainer}
            leaveTraining={this.leaveTraining}
            runningState={this.state.runningState}
            toggleFullscreen={this.toggleFullscreen}
            fullscreen={this.props.fullscreen}
            streamingLot={this.state.streamingLot}
            hand={!!this.state.handRaise}
            toggleHand={this.toggleHand}
            sendActiveTrainerCommand={this.sendActiveTrainer}
            sendChangeRunningStateCommand={this.sendRunningState}
            canTakeOverTraining={this.state.canTakeOverTraining}
            togglePreview={this.togglePreview}
            preview={this.state.preview}
            popupOpen={this.bigLotPopup}
            preJoin={this.state.preJoin}
            trainerJoin={this.trainerPreJoin}
          />
          <Grid container style={{ position: 'relative' }}>
            <div
              className={clsx(
                this.props.classes.playerWrapper,
                this.state.runningState === 1 && this.props.classes.playerMarginTop,
                this.state.runningState === 0 &&
                  !this.state.streamingLot &&
                  this.props.classes.playerMarginTop
              )}
            >
              <div
                className={
                  this.state.streamingLot
                    ? this.props.classes.streamWrapper
                    : this.props.classes.wrapper
                }
                ref={this.jitsiWrapper}
              >
                {!this.state.streamingLot ? (
                  <>
                    <div id={jitsiContainerId} className={this.props.classes.jitsi} />
                    <TimeCountdown room={this.state.myBreakoutRoom} mode="overlay" />
                    <LotFloatingButton
                      muteAll={this.sendMuteCommand}
                      changeParticipantMode={this.sendChangeParticipantModeCommand}
                      allMuted={this.state.allMuted}
                      runningState={this.state.runningState}
                      isTrainer={this.state.isTrainerOrCotrainer}
                      streamingLot={this.state.streamingLot}
                      screenShareAllowed={this.state.isScreenShareAllowed}
                      toggleScreenshare={this.trainerAllowScreenshare}
                    />
                    <BreakoutReminder />
                  </>
                ) : (
                  <div className={this.props.classes.player}>
                    {this.state.videoUrl && (this.state.runningState >= 2 || this.state.preview) && (
                      <div>
                        <IVSLivePlayer url={this.state.videoUrl} surveyEnabled={false} />
                      </div>
                    )}
                  </div>
                )}
                <HandQueue />
              </div>
              {!this.props.tablet && !(this.state.runningState === 0) && (
                <div className={this.props.classes.lotDescription}>
                  <LotDescription
                    Session={this.state.session}
                    startAt={this.state.training?.startAt}
                  />
                </div>
              )}
            </div>
            <WaitingOverlay
              runningState={this.state.runningState}
              videoHeight={this.state.videoHeight}
              earlyAccess={this.state.preview}
              session={this.state.session}
            />
            {!this.props.tablet && this.sideBar()}
          </Grid>
          {this.state.runningState === 0 && !this.props.tablet && !this.props.mobile && (
            <div className={this.props.classes.lotDescription}>
              <LotDescription Session={this.state.session} startAt={this.state.training?.startAt} />
            </div>
          )}
          {this.props.tablet && (
            <div className={this.props.classes.mobileSideBar}>
              {this.props.mobile ? (
                <div>
                  <LotDescription
                    Session={this.state.session}
                    tablet
                    startAt={this.state.training?.startAt}
                  />
                </div>
              ) : (
                <div style={{ width: '50%' }}>
                  {!(this.state.runningState === 0 || this.state.runningState === 1) && (
                    <div className={this.props.classes.greyTab} />
                  )}
                  <LotDescription
                    Session={this.state.session}
                    tablet
                    startAt={this.state.training?.startAt}
                  />
                </div>
              )}
              {this.sideBar()}
            </div>
          )}
          <Grid item xs={12} style={{ marginTop: '46px' }}>
            <div className={this.props.classes.lotBottomWrapper}>
              <LotBottomTabs
                sessionRefs={this.state.session.sessionRefs}
                refSessions={this.state.refSessions}
                ama={this.state.ama}
                ourAma={this.state.ourAma}
                training={this.state.training}
                parentSession={this.state.session}
              />
            </div>
          </Grid>

          {this.state.popupOpen === 'instruct' && (
            <InstructionPopup close={this.closeInstructPopup} />
          )}
          {this.state.popupOpen === 'join' && <JoinPopup join={this.joinConferenceWith} />}
          {this.state.popupOpen === 'bigLot' && (
            <StreamAccessDialog
              open={true}
              onClose={this.closeBigLotPopup}
              streamServers={this.state.training.streamServers}
            />
          )}
          {this.state.popupOpen === 'leave' && (
            <LotLeaveDialogue
              channelId={this.state.session.channelId}
              triggerNotification={this.props.triggerNotification}
              trainingId={this.state.training.trainingId}
            />
          )}
          {this.state.isTrainerOrCotrainer && (
            <Grid item xs={12} className={this.props.classes.gutterDown}>
              {this.state.streamingLot && <SwitchStream />}
              <ExportChat />
            </Grid>
          )}
        </Grid>
      </lotContext.Provider>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
  fullscreen: state.app.fullscreen,
});

const mapDispatchToProps = {
  triggerNotification,
  enterFullscreen,
  exitFullscreen,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(LOT));
