import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { ChannelTopic } from 'ApiHandler/dclxInterfaces';
import { getLocalizedValue } from 'Localization/Localizer';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    alignItems: 'center',
    padding: '0px 52px',
    minHeight: '335px',
    [theme.breakpoints.down('md')]: {
      minHeight: '235px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px',
    },
  },
  whiteText: {
    color: '#FFFFFF',
  },
  textContainer: {
    position: 'relative',
    width: '100%',
  },
}));

type P = {
  topic: ChannelTopic;
};

/** Closed, already ended Topic */
const TopicClosing = (props: P) => {
  const cls = useStyles();
  return (
    <div
      className={cls.wrapper}
      style={{
        backgroundImage: `linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,.5)),url(${props.topic.countdownImage?.url}`,
      }}
    >
      <Text className={cls.whiteText} variant="order3">
        {getLocalizedValue(props?.topic?.closingMessage)}
      </Text>
    </div>
  );
};

export { TopicClosing };
