import ReactPlayer from 'react-player/lazy';
import React, { Component } from 'react';
import './VideoPlayer.scss';
import VideoControls from '../../../../utils/VideoControls';
import screenfull from 'screenfull';
import ReactDOM from 'react-dom';
import {
  AudioTrack,
  CognitoUser,
  Interrupt,
  PlayerSub,
  S3File,
  Session,
  SubtitleLanguage,
} from '../../../../ApiHandler/dclxInterfaces';
import Quiz from './Quiz';
import {
  getSessionSettings,
  noNullUser,
  playerConfig,
  playerConfigTracks,
} from '../../../../utils/convert';
import { Store } from '../../../../store';
import { connect } from 'react-redux';
import { doesRoleMatch, Role } from '../../../../utils/RoleDefinitions';
import { client } from '../../../../ApiHandler/client';
import CallToAction from './CallToAction';
import { defaultVolume } from '../../../../config';
import strings, { allLangs } from 'Localization/Localizer';

/** interface for OnDemandPlayer props coming from parent components EventWatch, Watch and Live */
interface P {
  url: string;
  session: Session;
  user: CognitoUser;
  surveyEnabled: boolean;
}

/** interface for OnDemandPlayer component state  */
interface S {
  playing: boolean;
  seeking: boolean;
  blockControls: boolean;
  controls: boolean;
  muted: boolean;
  played: number;
  playedSeconds: number;
  maxPlayed: number;
  maxPlayedSeconds: number;
  loaded: number;
  duration: number;
  fullscreen: boolean;
  survey: boolean;
  remainingInterrupts: Interrupt[];
  activeInterrupt: Interrupt | null;
  allowSeek: boolean;
  volume: number;
  selectedSub: PlayerSub | null;
  playerSubs: PlayerSub[];
  playerAudios: Array<AudioTrack>;
  selectedAudio: AudioTrack | null;
}

/**
 * Player for on Demand Videos
 */

class OnDemandPlayer extends Component<P, S> {
  controlsTimeout: any;
  sendInterval: any;
  player: any;
  wrapper: any;
  initPlayedSeconds: number = 0;
  initPlayedSecondsMax: number = 0;

  constructor(props: P) {
    super(props);

    const getSubs = () => {
      if (props.session.subtitles) {
        const entries: any[] = [];
        Object.entries(props.session.subtitles).forEach(([key, value]) => {
          try {
            const l = allLangs.find((lang) => lang.code === key);
            if (l) {
              entries.push({
                src: (value as S3File).url,
                srcLang: l,
              });
            }
          } catch (e) {}
        });
        return entries.filter((v) => !!v);
      }
      return [];
    };

    const settings = getSessionSettings(props.session);
    const interrupts = props.session.interrupts || [];
    this.state = {
      playing: false,
      seeking: false,
      blockControls: false,
      controls: true,
      muted: false,
      played: 0,
      playedSeconds: 0,
      maxPlayed: 0,
      maxPlayedSeconds: 0,
      loaded: 0,
      duration: 0,
      fullscreen: false,
      survey: false,
      remainingInterrupts: this.getInterrupts(interrupts, props.session.played || 0),
      activeInterrupt: null,
      volume: defaultVolume,
      allowSeek: !interrupts.length && !!settings.allowForwardScrolling,
      selectedSub: null,
      playerSubs: getSubs(),
      playerAudios: [],
      selectedAudio: null,
    };

    this.controlsTimeout = null;
    this.sendInterval = null;
    this.wrapper = React.createRef();

    this.initPlayedSeconds = props.session.played || 0;
    this.initPlayedSecondsMax = props.session.playedMax || 0;
  }

  componentDidMount() {
    this.load();
    this.addFullscreenEventListeners();
  }

  componentWillUnmount() {
    this.handlePause();
    clearTimeout(this.controlsTimeout);
  }

  sendPlayProgress = async () => {
    const progress = {
      played: Math.round(this.state.playedSeconds),
      playedMax: Math.round(this.state.maxPlayedSeconds),
      totalLength: Math.round(this.state.duration),
    };
    await client.post(`sessions/${this.props.session.sessionId}/videohit`, progress);
  };

  sendInterruptStats = async (timeUsed: number, correctAnswers: number) => {
    const interruptID = this.state.activeInterrupt?.interruptId;

    const stats = {
      sessionId: this.props.session.sessionId,
      correctQuestions: correctAnswers,
      usedTime: Math.round(timeUsed),
    };
    await client.post(`interrupts/${interruptID}/stats`, stats);
  };

  load = () => {
    this.setState({
      played: 0,
      maxPlayed: 0,
      loaded: 0,
      seeking: false,
      survey: false,
    });
  };

  getInterrupts = (i: Interrupt[], since: number) => {
    return i
      .filter((s) => s.timeCode >= since - 1) // buffer so you cant close on interrupt
      .sort((a, b) => {
        if (a.timeCode < b.timeCode) return -1;
        return 1;
      });
  };

  handleControls = () => {
    clearTimeout(this.controlsTimeout);
    this.enableControls();
    this.controlsTimeout = setTimeout(() => {
      this.disableControls();
    }, 3000);
  };

  handlePlay = () => {
    this.setState({ playing: true });
    clearInterval(this.sendInterval);
    this.sendPlayProgress();
    this.sendInterval = setInterval(() => {
      this.sendPlayProgress();
    }, 5000);
  };

  handleSeekUp = (e: any) => {
    const played = e.target.value * this.state.duration;
    this.setState({
      remainingInterrupts: this.getInterrupts(this.props.session.interrupts || [], played),
      seeking: false,
    });
    this.sendPlayProgress();
  };

  handleSeekDown = () => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e: any) => {
    const affectedBySeekControl =
      doesRoleMatch(this.props.user, Role.VISITOR) && !this.state.allowSeek;
    if (affectedBySeekControl && e.target.value > this.state.maxPlayed) {
      return;
    }
    this.player.seekTo(e.target.value, 'fraction');
  };

  handlePause = () => {
    this.setState({ playing: false });
    clearInterval(this.sendInterval);
    this.sendPlayProgress();
  };

  handleProgress = (current: any) => {
    const maxPlayed = Math.max(this.state.maxPlayed, current.played);
    const maxPlayedSeconds = maxPlayed * this.state.duration;
    this.setState({ ...current, maxPlayed, maxPlayedSeconds });
    this.checkForInterrupt(current.playedSeconds);
  };

  handleEnded = async () => {
    /* if (this.props.interrupts.length === 0) {
      await this.sendStats(true);
    }*/
    if (this.props.surveyEnabled) this.setState({ survey: true });
    clearInterval(this.sendInterval);
    await this.sendPlayProgress();
  };

  closeSurvey = () => {
    this.setState({ survey: false });
  };

  handleDuration = (duration: any) => {
    if (this.state.maxPlayedSeconds < this.initPlayedSecondsMax) {
      this.setState({
        maxPlayed: this.initPlayedSecondsMax / duration,
      });
      this.player.seekTo(this.initPlayedSeconds);
    }
    this.setState({ duration });
  };

  checkForInterrupt = (playedSeconds: number) => {
    // assume the questions are ordered by time
    if (this.state.remainingInterrupts.length === 0) return;
    const i = this.state.remainingInterrupts[0];
    if (playedSeconds < i.timeCode) return;
    // found a question
    this.setState({
      remainingInterrupts: this.state.remainingInterrupts.slice(1),
    });
    this.mountInterrupt(i);
  };

  handleReady = () => {
    this.setInitialTracks();
  };

  enableControls = () => {
    if (!this.state.blockControls) this.setState({ controls: true });
  };

  disableControls = () => {
    this.setState({ controls: false });
  };

  blockControls = () => {
    this.setState({ controls: false, blockControls: true });
  };

  mountInterrupt = (i: Interrupt) => {
    this.handlePause();
    this.blockControls();
    this.setState({ activeInterrupt: i });
  };

  unmountInterrupt = async (timeUsed: number, correctAnswers: number) => {
    try {
      await this.sendInterruptStats(timeUsed, correctAnswers);
    } catch (e: any) {}

    this.setState({
      activeInterrupt: null,
      blockControls: false,
    });

    this.handlePlay();
  };

  ref = (player: any) => {
    this.player = player;
  };

  addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', this.handleEsc);
    document.addEventListener('mozfullscreenchange', this.handleEsc);
    document.addEventListener('fullscreenchange', this.handleEsc);
    document.addEventListener('MSFullscreenChange', this.handleEsc);
  };

  handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      this.exitFullscreen();
    }
  };

  exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    this.setState({ fullscreen: false });
  };

  toggleFullscreen = () => {
    if (this.state.fullscreen) {
      this.exitFullscreen();
    } else {
      this.setState({
        fullscreen: true,
      }); /* @ts-ignore*/
      /* eslint-disable-next-line react/no-find-dom-node */ screenfull.request(
        ReactDOM.findDOMNode(this.wrapper.current)
      );
    }
  };

  /** Selects the initial Audio and Sub Tracks
   * In case we find a track that matches our language we will use this and no sub.
   *
   * Otherwise we will try to find the English Track. If we cant find it we will just use the first one.
   * However we will always select a subtitle as well, assuming that the user isn't proficient with english.
   */

  setInitialTracks = () => {
    const l = strings.getLanguage() || 'English';
    const ip = this.player.getInternalPlayer('hls').audioTrackController;
    const matchingTrack = ip.tracks.find((s: AudioTrack) => s.name === l);
    if (matchingTrack) {
      ip.audioTrack = matchingTrack.id;
      this.setState({
        playerAudios: ip.tracks,
        selectedAudio: matchingTrack,
      });
    } else {
      const englishTrack = ip.tracks.find((s: AudioTrack) => s.name === 'eng');
      ip.audioTrack = englishTrack?.id || 0;
      this.setState({
        playerAudios: ip.tracks,
        selectedAudio: englishTrack || ip.tracks[0] || null,
      });
    }
  };
  changeAudio = (audioTrack: string) => {
    const ip = this.player.getInternalPlayer('hls').audioTrackController;
    const f = ip.tracks.find((s: AudioTrack) => s.name === audioTrack);
    if (!f) return;
    ip.audioTrack = f.id;
    this.setState({ selectedAudio: f });
  };

  changeLanguages = (newLang: SubtitleLanguage) => {
    if (newLang === this.state.selectedSub?.srcLang) return;
    const trackList = this.player.getInternalPlayer().textTracks;
    if (newLang.code === 'off') {
      for (let i = 0; i < trackList.length; i++) {
        if (trackList[i].mode === 'showing') {
          trackList[i].mode = 'disabled';
        }
      }
      this.setState({ selectedSub: { srcLang: { name: 'Off', code: 'off' } } });
    } else {
      const f = this.state.playerSubs.find((s) => s.srcLang.code === newLang.code);
      if (!f) return;
      for (let i = 0; i < trackList.length; i++) {
        if (trackList[i].mode === 'showing') {
          trackList[i].mode = 'disabled';
        }
        if (trackList[i].language === f.srcLang.code) {
          trackList[i].mode = 'showing';
        }
      }
      this.setState({ selectedSub: f });
    }
  };

  render() {
    const { playing, muted, duration, played, maxPlayed, seeking } = this.state;
    return (
      <div className="player-wrapper" onMouseMove={this.handleControls} ref={this.wrapper}>
        <ReactPlayer
          config={playerConfigTracks(this.state.playerSubs) || playerConfig}
          ref={this.ref}
          className="react-player"
          width="100%"
          height="100%"
          url={this.props.url}
          pip={false}
          playing={playing}
          controls={false}
          light={false}
          loop={false}
          playbackRate={1}
          progressInterval={100}
          volume={this.state.volume}
          muted={muted}
          onReady={this.handleReady}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          onEnded={this.handleEnded}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
        <VideoControls
          playerMode="full"
          visible={this.state.controls}
          duration={duration}
          played={played}
          maxPlayed={maxPlayed}
          muted={muted}
          playing={playing && !seeking}
          pause={this.handlePause}
          play={this.handlePlay}
          seekDown={this.handleSeekDown}
          seekUp={this.handleSeekUp}
          seekChange={this.handleSeekChange}
          setMuted={(muted: boolean) => this.setState({ muted: muted })}
          fullscreen={this.state.fullscreen}
          toggleFullscreen={this.toggleFullscreen}
          volume={this.state.volume}
          setVolume={(volume: number) => this.setState({ volume: volume })}
          allSubs={this.state.playerSubs}
          allAudio={this.state.playerAudios}
          selectedAudio={this.state.selectedAudio}
          changeLanguages={this.changeLanguages}
          changeAudio={this.changeAudio}
          selectedSub={
            this.state.selectedSub || {
              srcLang: {
                name: 'Off',
                code: 'off',
              },
            }
          }
        />
        {this.state.activeInterrupt && (
          <Quiz
            questions={this.state.activeInterrupt.questions}
            finish={this.unmountInterrupt}
            fullscreen={this.state.fullscreen}
          />
        )}
        {this.state.survey && <CallToAction close={this.closeSurvey} />}
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OnDemandPlayer);
