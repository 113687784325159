/**
 * Collection of various Interfaces representing Date from the API
 */

import { allThemes } from 'config';
import { CustomField, DefaultFields } from 'DCLX/CMS/AdminEventSetup/adminPanelTypes';
import { allLangs, ElementType } from '../Localization/Localizer';
import { TextKey } from '../features/Text/texts';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';
import { ValidationType } from 'utils/convert';

/** URL */
export type Url = string;
/** Signed Bucket URL */
export type SignedUrl = Url;
/** UNIX timestamps in seconds */
export type Timestamp = number;
/** Timezone offset in hours. Only relevant for displaying times to admins. */
export type Timezone = number;
/** timespan in seconds */
export type Seconds = number;
/** Any Json, can be parsed to something else */
export type Json = string;
/** Localized string. JSON. Can be parsed to MultiLanguage */
export type LocalizedString = Json;

/** Parsed type for Localized strings */
export interface MultiLanguage<T> {
  lang: string;
  value: T;
}
/** Menu Item and its configuration */
export interface MenuItem {
  menuItemId: number;
  title: string;
  menuItemKey: string;
  position: number;
}

/** The Cognito user from the id-token */
export interface CognitoUser {
  sub: string;
  email?: string;
  given_name: string;
  family_name: string;
  'cognito:groups'?: Array<string>;
  'custom:channels'?: string;
}

/** POST for a question answer */
export interface PostAnswer {
  answerText: LocalizedString;
  correct: boolean;
}

/** GET for a question answer */
export interface Answer extends PostAnswer {
  answerId: number;
}

/** POST for a question */
export interface PostQuestion {
  position: number;
  questionText: LocalizedString;
  answers: PostAnswer[];
  points: number;
}

/** GET for a question */
export interface Question extends PostQuestion {
  questionId: number;
  answers: Answer[];
}

/** Session interrupt, works as a quiz within onDemand sessions */
export interface Interrupt {
  interruptId: number;
  timeCode: Seconds;
  questions: Question[];
}

/** User Profile Pictures */
export interface S3UserFiles {
  thumbUrl: S3File;
  headerUrl: S3File;
}

/** File from S3 Bucket, pre-media library */
export interface S3File {
  url: SignedUrl;
  contentType: string;
  file?: string;
}

/** POST for a faq question (only one answer) */
export type PostFaq = {
  position: number;
  question: LocalizedString;
  answer: LocalizedString;
  channels: number[];
};

/** GET for a faq question (only one answer) */
export type FAQ = PostFaq & {
  faqId: number;
};

/** Different types of legal docs */
export enum LegalDocumentType {
  legal = 'legal',
  privacy = 'privacy',
  conditions = 'conditions',
}

/** Different types of sessions */
export enum SessionType {
  DEMAND = 'onDemand',
  LIVETERMINATED = 'onDemandTerminated',
  EXTERNAL = 'external',
  EXTERNALTERMINATED = 'externalTerminated',
  LIVE = 'live',
  TRAINING = 'training',
}

/** Different types of onDemand-AMA modules.
 * Convention AMAs end up at a central place and can then be answered in a live stream
 * CLX AMAS end up for all users, can be voted and end up for both users and trainers in the linked LOTs.
 */
export type AmaType = 'convention' | 'clx' | 'none';

/** Available SessionSettings after parsing them */
export interface SessionSettings {
  /** Can an onDemand video be scrolled forward? (only available without interrupts */
  allowForwardScrolling?: boolean;
  /** Should the live session use LiveAmas? */
  useLiveAmas?: boolean;
  /** Should only the number of participants be displayed in a live session? */
  hideParticipants?: boolean;
  /** Which AMAs should be used in an onDemand session? */
  amas?: AmaType;
  /** Should clapHands be available in a live session? */
  clapHands?: boolean;
}

/** Strongly typed setSetting function-type */
export type SetSetting = <Key extends keyof SessionSettings>(
  key: Key,
  value: SessionSettings[Key]
) => void;

/** Session Interfaces, central element of the platform */
export interface Session {
  topicIds: Array<number>;
  channelId: number;
  showInChannelContent: boolean;
  sessionId: number;
  title: LocalizedString;
  description: LocalizedString;
  position: number;
  thumbnail: MediaFile;
  thumbnailId: number;
  lobbyImage?: MediaFile;
  lobbyImageId?: number | null;
  documents: MediaFile[];
  documentIds: number[];
  sessionType: SessionType;
  video?: MediaFile;
  videoId?: number | null;
  playbackUrls?: Url[];
  interrupts?: Interrupt[];
  duration?: Seconds;
  score?: number;
  usedTime?: Seconds;
  startAt?: Timestamp;
  startTimezone?: Timezone;
  unlockAt?: Timestamp;
  endAt?: Timestamp;
  endTimezone?: Timezone;
  timeEnd?: Timestamp;
  trainer?: number;
  sessionRefs?: Array<number>;
  adapted?: boolean;
  played?: Seconds;
  playedMax?: Seconds;
  totalLength?: Seconds;
  stream?: string;
  externalUrl?: string;
  streamServers?: StreamInfo[];
  settings?: string;
  subtitles?: { [key: string]: MediaFile };
  subtitleIds?: { [key: string]: number | undefined };
  reportingTag?: string;
}

/** Stream Info (via IVS) */
export interface StreamInfo {
  ingestEndpoint: Url;
  streamKey: string;
}

/** Type of training. Either Streaming or Conference via Jitsi */
export enum TrainingType {
  STREAM = 'stream',
  CONFERENCE = 'conference',
}

/** Bookable specific slot of a training session */
export interface TimeSlot {
  maxParticipants: number;
  trainingType: TrainingType;
  trainingId: number;
  sessionId: number;
  trainerId: number;
  trainerName: string;
  coTrainerId: number;
  coTrainerName: string;
  startAt: Timestamp;
  endAt: Timestamp;
  bookingEndAt: Timestamp;
  documents: MediaFile[];
  documentIds: number[];
  conference: string;
  description: LocalizedString;
  bookings: number;
  videoId: number;
  video: MediaFile;
  streamServer?: StreamInfo;
  streamServers?: StreamInfo[];
  playbackUrls: Url[];
  attended?: boolean;
}

export interface TrainingWithUsers extends TimeSlot {
  bookingsUsers: Array<BookingUser>;
}

export interface BookingUser {
  bookingId: number;
  user: UserPicture;
  attended?: boolean;
}

/** Stat for a session, granted after completing interrupts */
export interface Stat {
  sessionId: number;
  score: number;
  usedTime: Seconds;
}

/** Profile of a User, either me or someone else */
export interface UserPicture {
  username: string;
  cognitoUsername: string;
  givenName: string;
  familyName: string;
  userId: number;
  aboutMe?: string;
  header?: SignedUrl;
  thumb: SignedUrl;
  email?: string;
  /** Can be parsed to CustomFields */
  customFields?: Json;
  userGroup?: string;
}
/** Users custom fields */
export type CustomFields = {
  'custom:channels'?: string | number;
  'custom:jobFunction'?: string;
};

/** Test/Exam types. Pre topic or post topic? */
export type TestType = 'pre' | 'post';

/** Basic Test/Exam for a Topic */
export interface BaseTest {
  examType: TestType;
  topicId: number;
  unlockAt: Timestamp;
  channelId: number;
  examName: LocalizedString;
  reportingTag?: string;
  points: number;
}

/** POST Exam */
export interface PostTest extends BaseTest {
  questions: PostQuestion[] | null;
}

/** GET Exam */
export interface GetTest extends BaseTest {
  examId: number;
  questions: Question[];
}

/** POST Support (Explainer and Text) */
export type PostSupport = {
  explainerText: LocalizedString;
  videoId: number | undefined;
  channels: number[];
  enableSupport: boolean;
  enableTechTest: boolean;
};

/** GET Support (Explainer and Text) */
export type Support = PostSupport & {
  expId: number;
  video: MediaFile;
};

/** CLX-LIke AMA */
export interface AMA {
  question: string;
  votes: number;
  selfVoted: boolean;
  supportQuestionId: number;
  username: string;
  sessionId: number;
}

/** Legacy Edit Level */
export enum EditLevel {
  NULL,
  TRAINER,
  MARKET,
  HQ,
}

/** User Pool Security. MFA meaning totp enabled, default password only, public no password */
export type Security = 'mfa' | 'default' | 'public';

/** User Pool Configuration object */
export interface UserPoolConfiguration {
  security: Security;
  customFields: CustomField[];
  defaultFields: DefaultFields;
  identityProviders?: IdentityProvider[];
}

/** Settings for IDP Providers */
export interface ProviderSetting {
  poolId: string;
  poolClientId: string;
  providers: { providerName: string; authorizeUrl: string; tokenUrl: string }[];
}

/** Basic Platform event/tenant */
export interface PlatformEvent {
  poolName: string;
  tenantId: number;
  failedUsers?: number;
  hidden?: boolean;
  title: string;
  identifier: string;
  idProviderIdentifier?: string;
  description: string;
  supportEmail?: string;
  eventStartTiming: number;
  eventEndTiming: number;
  favicon: string;
  logo: string;
  navLogo: string;
  heroImage: string;
  profileImage: string;
  userPoolConfiguration?: UserPoolConfiguration;
  poolId: string;
  poolClientId: string;
  poolSecurity: Security;
  configModules?: ConfigModule[];
  registerVideoIdentifier?: string;
  registerVideoThumb?: string;
  languages: string;
  theme: string;
  registerSettings: RegisterSettings;
}

export interface RegisterSettings {
  verificationTypes: ValidationType[];
}

/** IDP Config */
export interface IdentityProvider {
  providerName: string;
  clientId: string;
  clientSecret: string;
  issuer: string;
  jwksUrl: string;
}

/** Language Selection for Events/Channels */
export interface LanguagesSelection {
  languages: Language[];
  preferedLanguage: string;
}

/** Platform-Language */
export type Language = ElementType<typeof allLangs>;
/** UI-components Theme */
export type CustomTheme = ElementType<typeof allThemes>;
/** Subtitle Language, can also be off. */
export type SubtitleLanguage = Language | { name: 'Off'; code: 'off' };

/** List of the different names for configModules */
export enum ConfigModuleIdentifiers {
  onDemandSessions = 1,
  liveStreamingSessions = 2,
  videoConferenceSessions = 3,
  mainSurvey = 4,
  liveChatInVideoConferenceSessions = 5,
  pollInVideoConferenceSessions = 6,
  surveyInOnDemandSessions = 7,
  surveyInLiveStreamingSessions = 8,
  surveyInVideoConferenceSessions = 9,
  introRequired = 10,
  liveAma = 11,
  registerVideo = 12,
  gamification = 13,
}

/** API-Config Module */
export interface ConfigModule {
  moduleId: number;
  identifier: string;
  description: string;
  type: string;
}

/** POST topic, topics group exams and session together */
export type PostTopic = {
  channelId: number;
  title: LocalizedString;
  description: LocalizedString;
  startAt: Timestamp;
  endAt: Timestamp;
  thumb?: MediaFile;
  thumbId?: number;
  countdownImage?: MediaFile;
  countdownImageId?: number;
  badgeImage?: MediaFile;
  badgeImageId?: number;
  block?: string;
  closingMessage: string;
  disableVideoProgression: boolean;
};

/** GET topic, topics group exams and session together */
export type ChannelTopic = PostTopic & {
  topicId: number;
  disableVideoProgression: boolean;
};

/** Platform channel, used to group users together */
export interface Channel {
  channelId: number;
  channelName: string;
  /** Not present in non-airlist events */
  channelKey?: string;
  /** Not present in non-airlist events */
  categoryId?: number;
  categoryUniqueName: string;
  categoryLongName: string;
  /** Not implemented in API yet */
  requireUnlock?: boolean;
  languages: string;
  supportEmail?: string;
  menuItemKey?: string;
  menuItemTitle?: string;
  menuItemId?: number;
  menuItemPosition?: number;
}

/** Category of a channel, relevant for Airlist matching */
export interface ChannelCategory {
  categoryId: number;
  uniqueName: string;
  longName: string;
}

/** Airlist User Import Error */
export interface ImportError {
  tenant: string;
  tenantId: number;
  username: string;
  operation: string;
  message?: string;
  timestamp: Timestamp;
}

/** Answer modes for Live AMAs */
export type AnswerModes = 'public' | 'private' | 'live';
/** Basic Live AMA */
export interface BaseAma {
  question: string;
  liveAmaId: number;
  /** sub */
  username: string;
  fullUsername: string;
  sessionId: number;
  dismissed: boolean;
  startAnswer: number | null;
  answeredBy: string | null;
  answer: string | null;
  answerMode: AnswerModes | null;
  createdAt: number;
}

/** Assignment of AMA to a user */
export interface AmaAssign {
  startAnswer: number | null;
  answeredBy: string | null;
}

/** AMA blocked for answering */
export type AnsweringAma = BaseAma & AmaAssign;

/** Answer for a Live AMA */
export interface AmaAnswer extends AmaAssign {
  answer: 'pending' | 'live' | string;
  answerMode: AnswerModes;
  dismissed: false;
  question?: string;
}

/** Answered Live AMA */
export type AnsweredAma = AnsweringAma & AmaAnswer;

/** Some AMA in any state */
export type AnyAma = BaseAma | AnsweringAma | AnsweredAma;

/** Subtitle as used in the player */
export interface PlayerSub {
  src?: string;
  srcLang: SubtitleLanguage;
}

/** Audiotrack as used in the player */
export interface AudioTrack {
  name: string;
  id: number;
  attrs: any;
  groupid: string;
  type: string;
  default: boolean;
  autoselect: boolean;
  forced: boolean;
  lang: string;
  url: string;
}

/** Text from the Texts functionality, allows EventManagers to adapt texts on event-basis. */
export type ApiText = {
  textKey: TextKey;
  textString: string;
  isActive: boolean;
};

/** Different types for first steps */
export const allStepTypes = ['session', 'exam', 'survey', 'url', 'profile'] as const;

/** Type of first step types*/
export type StepType = ElementType<typeof allStepTypes>;

/** POST a first Step Item */
export type PostFirstStep = {
  position: number;
  stepType: StepType;
  title: string;
  mediaId: string;
  channelId: number;
  buttonText: string;
};

/** GET a first Step Item */
export type GetFirstStep = PostFirstStep & {
  firstStepId: number;
};

/** POST an item for mySchedule */
export type PostMySchedule = {
  myScheduleType: 'session';
  mediaId: string;
};

/** Get a mySchedule item */
export type GetMySchedule = PostMySchedule & {
  myScheduleId: number;
};

/** POST a Convention-Like AMA */
export type PostAma = {
  question: string;
  sessionId: number;
  topic: string;
};

/** GET a Convention-Like AMA */
export type GetAma = PostAma & {
  supportQuestionId: number;
  interesting: boolean;
  sentAt: number;
  jobFunction: string;
  givenName: string;
  familyName: string;
};

/** Gamefication progress */
export type Awards = {
  /** total number of points */
  score: number;
  /** total seconds used on on-Demand session interrupts */
  usedTime: number;
  badges: {
    /** Ids of completed topics */
    topics?: number[];
    /** Ids of perfect (100%) exams */
    exams?: number[];
  };
  /** How many sessions and exams the user has done/needs to do */
  progress: {
    done?: number;
    total?: number;
  };
  myRank?: number;
  totalUsers?: number;
};

/** Awards like they are presented in the top10 */
export type AllAward = Awards & { username: string };

export type AgendaItem = {
  /** Milli seconds */
  time: number;
  title: string;
  location: string;
  description: string;
  shortDayTitle: string;
  longDayTitle: string;
};

export type CSVAgendaItem = AgendaItem & {
  agendaKey: AgendaKey;
};

/** Follows the scheme color_slot */
export type AgendaKey = string;

type BaseAgenda = {
  agendaKey: AgendaKey;
  agendaKeyPattern: AgendaKey;
  channelId: number;
};
export type Agenda = BaseAgenda & {
  agendaItems: AgendaItem[];
};

export type RawAgenda = BaseAgenda & { agendaItems: string };
