import React from 'react';
import { Checkbox as AudiCheckbox } from '@audi/audi-ui-react';
import { Text } from '@audi/audi-ui-react';
import { Select } from '@pp-labs/ui-components';

import AudiSpacer from 'utils/AudiSpacer';
import { Language } from 'ApiHandler/dclxInterfaces';
import strings from 'Localization/Localizer';

/** interface for LanguagesSelector props coming from parent components ResultsDiagrams, ViewDiagrams and UserDiagrams */
interface P {
  languages: Language[];
  setSelectedLanguages: (languages: Language[]) => void;
  selectedLanguages: Language[];
  error?: any;
}

/** Language Selection utility for CMS */

export const LanguagesSelector = (props: P) => {
  const allChecked = () => {
    return props.languages.length <= props.selectedLanguages.length;
  };
  return (
    <>
      <div>
        <div style={{ paddingTop: '12px', paddingBottom: '8px' }}>
          <Text as="h3" variant="order4">
            <b>{strings.availableLanguages}</b>
          </Text>
        </div>

        <AudiCheckbox
          inputId={'checkAll'}
          name={'checkAll'}
          onChange={() => {
            if (allChecked()) {
              props.setSelectedLanguages([]);
            } else {
              props.setSelectedLanguages(props.languages);
            }
          }}
          checked={allChecked()}
        >
          <Text as="p" variant="copy1">
            {allChecked() ? strings.uncheckAll : strings.checkAll}
          </Text>
        </AudiCheckbox>
      </div>
      <AudiSpacer spaceStackEnd={'s'} />
      <div
        style={{
          width: '100%',
          display: 'inline-grid',
          gridGap: '8px',
          gridAutoRows: 'min-content',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        }}
      >
        {props.languages.map((lang) => {
          return (
            <div style={{ width: 'fit-content' }} key={lang.code}>
              <AudiCheckbox
                inputId={lang.code}
                name={lang.code}
                onChange={() => {
                  if (props.selectedLanguages.indexOf(lang) !== -1) {
                    props.setSelectedLanguages(
                      props.selectedLanguages.filter((l: Language) => l !== lang)
                    );
                  } else {
                    props.setSelectedLanguages([...props.selectedLanguages, lang]);
                  }
                }}
                checked={props.selectedLanguages.findIndex((l) => lang.code === l.code) !== -1}
              >
                <Text as="p" variant="copy1">
                  {lang.name}
                </Text>
              </AudiCheckbox>
            </div>
          );
        })}
      </div>
      <Select name={'preferedLanguage'} label={strings.preferedLanguage} error={props.error || ''}>
        {props.selectedLanguages.map((lang: Language) => {
          return (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          );
        })}
      </Select>
    </>
  );
};
