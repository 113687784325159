import React, { useMemo } from 'react';
import strings from '../../../Localization/Localizer';
import { AMA, Session, TrainingWithUsers } from '../../../ApiHandler/dclxInterfaces';
import FileButton from '../../../utils/FileButton';
import LOTAskedQuestions from './LOTAskedQuestions';
import { BreakoutRooms } from './Breakout/BreakoutRooms';
import { LotTabs, TabType } from './LotTabs/LotTabs';
import { useLotState } from './Provider/LotProvider';
import * as _ from 'lodash';

/** interface for LotBottomTabs props coming from parent component LOT  */
interface P {
  training: TrainingWithUsers;
  sessionRefs?: number[];
  refSessions: Session[];
  ama: AMA[];
  ourAma: AMA[];
  parentSession: Session;
}

/**
 * Tabs for the LOT, showing participants, files, asked questions and co.
 */

const LotBottomTabs = (props: P) => {
  const lotState = useLotState();

  // Provides a fix for the same document connected once to a session and once to a training.
  const docs = useMemo(
    () => _.uniqBy([...props.training.documents, ...props.parentSession.documents], 'mediaId'),
    [props.training, props.parentSession]
  );

  const tabs: TabType[] = [
    { label: strings.participants, content: <BreakoutRooms />, id: 'rooms' },
    {
      label: strings.trainerContents,
      content: (
        <div
          style={{
            height: 565,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
          }}
        >
          {docs.map((doc, index) => (
            <FileButton
              smallText
              key={doc.mediaId}
              mediaFile={doc}
              trainingId={props.training.trainingId}
              limitWidth={false}
            />
          ))}
        </div>
      ),
      id: 'contents',
    },
  ];

  if (lotState.users.iAmTrainer)
    tabs.push({
      label: strings.askedQuestions,
      content: (
        <div
          style={{
            minHeight: 565,
          }}
        >
          <LOTAskedQuestions {...props} />
        </div>
      ),
      id: 'ama',
    });

  return (
    <div>
      <LotTabs tabs={tabs} />
    </div>
  );
};

export default LotBottomTabs;
