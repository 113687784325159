import React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import Overview from '../../../features/Sessions/Watch/AskMeAnything/Overview';
import { AMA, Session } from '../../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from 'Localization/Localizer';

/** interface for LOTAskedQuestions props coming from parent component LOTBottomTabs  */
interface P {
  sessionRefs?: Array<number>;
  refSessions: Array<Session>;
  ama: Array<AMA>;
  ourAma: Array<AMA>;
}

/**
 * Displays the trainer asked questions related to the LOT
 */

const LOTAskedQuestions = (props: P) => {
  return (
    <div>
      {props.sessionRefs?.map((s, index) => (
        <>
          <div style={{ paddingBottom: 37 }}>
            {index !== 0 && <div style={{ paddingTop: '56px' }} />}
            <Text as="h3" variant="order3" weight={'bold'}>
              {getLocalizedValue(props.refSessions.find((a) => a.sessionId === s)?.title || '')}
            </Text>
          </div>
          <Grid container spacing={10} key={index}>
            <Grid item xs={6}>
              <Text as="h4" variant="order4" spaceStackEnd={'l'}>
                {strings.lotParticipants}
              </Text>
              <Overview
                questions={props.ourAma.filter((a) => a.sessionId === s)}
                refresh={() => {}}
                noInteract
                smallText
              />
            </Grid>
            <Grid item xs={6}>
              <Text as="h4" variant="order4" spaceStackEnd={'l'}>
                {strings.allUsers} (Top 10)
              </Text>
              <Overview
                questions={props.ama.filter((a) => a.sessionId === s)}
                refresh={() => {}}
                noInteract
                smallText
              />
            </Grid>
          </Grid>
        </>
      ))}
    </div>
  );
};

export default LOTAskedQuestions;
