import React from 'react';
import strings from '../../Localization/Localizer';
import { client } from '../../ApiHandler/client';
import { FilterParams, transformToRequestFilter } from './useDashboardData';
import { LabelButton } from '@pp-labs/ui-components';
import { DownloadSurveys } from 'features/Survey/DownloadSurveys';

/** interface for DownloadUserResults props coming from parent component Dashboard  */
interface P {
  filters: Omit<FilterParams, 'category'>;
  showDownload: boolean;
}

/** Download button for user results */
const DownloadUserResults = (props: P) => {
  return (
    <div style={{ padding: 56 }}>
      <LabelButton
        variant="primary"
        onClick={async () => {
          const data = await client.post('reports/download', {
            categories: ['users', 'views', 'results'],
            filters: transformToRequestFilter(props.filters),
          });
          const link = document.createElement('a');
          link.href = data.data.url;
          link.setAttribute('download', 'file.zip');
          document.body.appendChild(link);
          link.click();
          link.remove();
        }}
      >
        {strings.downloadRegistrationData}
      </LabelButton>
      <DownloadSurveys></DownloadSurveys>
    </div>
  );
};

export { DownloadUserResults };
