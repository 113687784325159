import React, { useState } from 'react';
import { IconButton, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { BookingUser, UserPicture } from '../../../ApiHandler/dclxInterfaces';

import DeleteDialogue from '../../../utils/ConfirmDialogue/DeleteDialogue';
import {
  AdaptedIcon,
  EditIcon,
  EraseIcon,
  MailIcon,
  UnadaptedIcon,
  LabelButton,
  tableStyles,
  TableCell,
  TableRow,
} from '@pp-labs/ui-components';
import AddParticipant from './AddParticipant';
import Rebook from './Rebook';
import strings from '../../../Localization/Localizer';
import { client } from '../../../ApiHandler/client';
import { useNotify } from 'utils/hooks';

const useStyles = makeStyles({
  ...tableStyles(),
});

/** interface for ParticipantManager props coming from parent component EditTraining */
interface P {
  participants: Array<BookingUser>;
  allVisitors: Array<UserPicture>;
  refresh: () => void;
  trainingsId: number;
  manager: boolean;
}

/**
 * Manage Participants
 */
const ParticipantManager = (props: P) => {
  const [toBeDeleted, setToBeDeleted] = useState<number | null>(null);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openRebook, setOpenRebook] = useState<BookingUser | null>(null);
  const Classes = useStyles();
  const notify = useNotify();

  const handleDelete = (id: number) => {
    setToBeDeleted(id);
  };

  const finishDelete = async () => {
    await client.delete(`trainings/bookings/${toBeDeleted}`);
    setToBeDeleted(null);
    props.refresh();
  };

  const abortDelete = () => {
    setToBeDeleted(null);
  };

  const closeAdd = (refresh?: boolean) => {
    setOpenAdd(false);
    if (refresh) props.refresh();
  };

  const openTheAdd = () => {
    setOpenAdd(true);
  };

  const closeRebook = (refresh?: boolean) => {
    setOpenRebook(null);
    if (refresh) props.refresh();
  };

  const openTheRebook = (id: BookingUser) => {
    setOpenRebook(id);
  };

  const mailTo = (mail?: string) => {
    if (!mail) notify(strings.noMailAvailable, 'error');
    mailLink(`mailto:${mail}`);
  };
  const mailToAll = (delimiter: string) => {
    let str = 'mailto:?';
    if (delimiter === ';') {
      // generate mailto for outlook
      str += 'bcc=';
      for (let i = 0; i < props.participants.length; i++) {
        if (!props.participants[i].user.email) continue;
        str += props.participants[i].user.email;
        if (i !== props.participants.length - 1) str += delimiter;
      }
    } else {
      // for everyone else
      for (let i = 0; i < props.participants.length; i++) {
        if (!props.participants[i].user.email) continue;
        str += 'bcc=';
        str += props.participants[i].user.email;
        if (i !== props.participants.length - 1) str += '&';
      }
    }

    mailLink(str);
  };

  const mailLink = (link: string) => {
    const a = document.createElement('a');
    a.href = link;
    a.click();
  };

  return (
    <div>
      <TableContainer style={{ marginTop: '48px' }}>
        <Table className={Classes.table} aria-label="simple table">
          <TableBody>
            {props.participants.map((book) => {
              const participant = book.user;
              return (
                <TableRow key={participant.userId}>
                  <TableCell center>
                    <div>
                      <div style={{ display: 'inline-block' }}>
                        <Text as="p" variant="copy1">
                          {participant.givenName} {participant.familyName}
                          {!!participant.email && (
                            <>
                              {' ('}
                              <a href={`mailto:${participant.email}`}>{participant.email}</a>

                              {')'}
                            </>
                          )}
                        </Text>
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton disabled>
                          {book.attended ? <AdaptedIcon /> : <UnadaptedIcon />}
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell center noLimitButtons>
                    <IconButton
                      onClick={() => {
                        mailTo(participant.email);
                      }}
                    >
                      <MailIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        openTheRebook(book);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleDelete(book.bookingId);
                      }}
                    >
                      <EraseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LayoutItem spaceStackEnd={'l'} spaceStackStart={'l'}>
        <Text>
          {props.participants.filter((p) => p.attended).length} / {props.participants.length}{' '}
          participants joined the training.
        </Text>
      </LayoutItem>
      {!!props.participants.length && (
        <>
          <div style={{ padding: '10px 0' }}>
            <LabelButton variant="secondary" onClick={() => mailToAll(';')}>
              Send Mail to all participants via Outlook (; seperated)
            </LabelButton>
          </div>
          <div style={{ padding: '10px 0' }}>
            <LabelButton variant="secondary" onClick={() => mailToAll(',')}>
              Send Mail to all participants via other Mailer (, seperated)
            </LabelButton>
          </div>
        </>
      )}
      <div style={{ padding: '10px 0' }}>
        <LabelButton variant="secondary" onClick={openTheAdd}>
          {strings.addParticipant}
        </LabelButton>
      </div>
      <DeleteDialogue toBeDeleted={toBeDeleted} delete={finishDelete} reject={abortDelete} />
      {openAdd && (
        <AddParticipant
          closeDialog={closeAdd}
          id={props.trainingsId}
          visitors={props.allVisitors.filter(
            (el) => !props.participants.find((e) => e.user.userId === el.userId)
          )}
        />
      )}
      {!!openRebook && (
        <Rebook
          closeDialog={closeRebook}
          id={props.trainingsId}
          partId={openRebook.user.userId}
          bookId={openRebook.bookingId}
        />
      )}
    </div>
  );
};

export default ParticipantManager;
