import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';

import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { toInt } from '../../../utils/convert';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { LabelButton, Select, TextField, EditDialog, Text } from '@pp-labs/ui-components';
import { UserPicture, TimeSlot, Session, TrainingType } from '../../../ApiHandler/dclxInterfaces';
import EditTraining from './EditTraining';
import { AxiosError } from 'axios';
import { client } from '../../../ApiHandler/client';
import { useNotify, useUser } from '../../../utils/hooks';
import { AdvancedDatePicker } from '../../utils/AdvancedDatePicker';
import AudiSpacer from 'utils/AudiSpacer';
import { fakeNumber } from '../../../utils/validator';
import { Editor } from 'features/Editor/Editor';

const validation = Yup.object({
  trainingType: Yup.string().required(strings.required),
  length: Yup.string()
    .required(strings.required)
    .test('max-240', strings.maxTrainingLength, (len) => Number(len) > 0 && Number(len) <= 240),
  description: Yup.string().required(strings.required),
  maxParticipants: fakeNumber()
    .required(strings.required)
    .test(
      'maxParticipants',
      'There is a maximum of 200 participants for a stream and 50 participants for a conference',
      function (maxParts) {
        return (this.parent.trainingType as TrainingType) === 'stream'
          ? Number(maxParts) <= 200
          : Number(maxParts) <= 50;
      }
    ),
  trainer: Yup.string(),
  cotrainer: Yup.string(),
}).required(strings.required);

const useStyles = makeStyles({
  len: {
    verticalAlign: 'top',
    maxWidth: '450px',
    padding: '0 12px',
  },
});

/** interface for AddTraining props coming from parent component TrainingForTopic */
interface P {
  manager: boolean;
  id: number;
  session?: Session;
  closeDialog: (refresh?: boolean) => void;
  trainers: Array<UserPicture>;
  pre?: TimeSlot;
}

interface FormValues {
  maxParticipants?: string;
  trainingType?: TrainingType;
  length: string;
  start: Date;
  bookingEnd: Date;
  trainer: string;
  cotrainer: string;
  description: string;
}

/**
 * Form for editing a training
 */

const AddTraining = (props: P) => {
  const user = useUser()!;
  const notify = useNotify();
  const cls = useStyles();

  const initialValues: FormValues = useMemo(() => {
    if (!props.pre) {
      const n = new Date();
      return {
        start: n,
        bookingEnd: n,
        length: '',
        trainer: '',
        cotrainer: '',
        description: props.session?.description ? getLocalizedValue(props.session.description) : '',
      };
    }

    const begin = new Date(props.pre.startAt * 1000);
    const duration = (props.pre.endAt - props.pre.startAt) / 60;
    const bookingEnd = new Date(props.pre.bookingEndAt * 1000);

    return {
      maxParticipants: props.pre.maxParticipants.toString(),
      trainingType: props.pre.trainingType,
      length: duration.toString(),
      start: begin,
      bookingEnd: bookingEnd,
      trainer: props.pre.trainerId.toString(),
      cotrainer: props.pre.coTrainerId ? props.pre.coTrainerId.toString() : '',
      description: props.pre.description || '',
    };
  }, [props.pre, props.session]);

  const [dateBegin, setDateBegin] = useState<Date>(initialValues.start);
  const [dateBookingEnd, setDateBookingEnd] = useState<Date>(initialValues.bookingEnd);

  const getErrors = (touched: any, errors: any) => {
    return {
      maxParticipants: touched.maxParticipants ? errors.maxParticipants : '',
      trainingType: touched.trainingType ? errors.trainingType : '',
      length: touched.length ? errors.length : '',
      trainer: touched.trainer ? errors.trainer : '',
      cotrainer: touched.cotrainer ? errors.cotrainer : '',
      description: touched.description ? errors.description : '',
    };
  };

  const submitToServer = async (v: FormValues) => {
    const end = dateBegin.getTime() + toInt(v.length) * 60 * 1000;
    const data: any = {
      maxParticipants: v.maxParticipants ? toInt(v.maxParticipants) : 0,
      trainingType: v.trainingType,
      startAt: dateBegin.getTime() / 1000,
      endAt: end / 1000,
      bookingEndAt: dateBookingEnd.getTime() / 1000,
      description: v.description,
    };
    if (props.manager) {
      if (!v.trainer) {
        notify(strings.specifyTrainer, 'error');
        return;
      }
    }
    let trainerId;
    if (props.manager) {
      trainerId = toInt(v.trainer);
    } else {
      trainerId = props.trainers.find((t) => t.username === user.sub)?.userId || 0;
    }
    data.trainerId = trainerId;

    if (v.cotrainer && toInt(v.cotrainer) === trainerId) {
      notify(strings.trainerCotrainerNotSame, 'error');
      return;
    }
    data.cotrainerId = v.cotrainer ? toInt(v.cotrainer) : 0;
    if (!props.pre) {
      data.sessionId = props.id;
    }
    if (!props.pre) {
      try {
        await client.post('trainings', data);
        props.closeDialog(true);
      } catch (e: any) {
        handleRequestError(e);
      }
    } else {
      try {
        await client.put(`trainings/${props.pre.trainingId}`, data);
        props.closeDialog(true);
      } catch (e: any) {
        handleRequestError(e);
      }
    }
  };

  const handleRequestError = (e: AxiosError) => {
    if (e.response?.status === 422) {
      notify(strings.maxNumberLOTBookedInTimeslot, 'error');
    }
  };

  return (
    <EditDialog
      title={
        props.pre
          ? `${strings.edit} ${
              props.pre.trainingType === 'stream' ? 'Stream LOT' : 'Jitsi Conference'
            }`
          : strings.scheduleTraining
      }
      close={props.closeDialog}
    >
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={submitToServer}>
        {({ errors, touched, values }) => {
          const et = getErrors(touched, errors);
          return (
            <Form>
              <div className={cls.len}>
                {!props.pre && (
                  <>
                    <Select
                      name="trainingType"
                      label={strings.selectSessionType}
                      error={et.trainingType}
                    >
                      <option value={'stream'}>{strings.typeBigLOT}</option>
                      <option value={'conference'}>{strings.typeJitsi}</option>
                    </Select>
                    <AudiSpacer spaceStackEnd="m" />
                  </>
                )}
              </div>
              {values.trainingType && (
                <>
                  <div className={cls.len}>
                    {props.manager && props.trainers && (
                      <>
                        <Select name="trainer" label={'Trainer'} error={et.trainer}>
                          {props.trainers.map((t) => (
                            <option key={`trainer-${t.username}`} value={t.userId}>
                              {t.givenName} {t.familyName}
                            </option>
                          ))}
                        </Select>

                        <div style={{ padding: '20px' }} />
                      </>
                    )}
                    {props.trainers && (
                      <Select name="cotrainer" label={strings.backupTrainer} error={et.cotrainer}>
                        {props.trainers.map((t) => (
                          // eslint-disable-next-line max-len
                          <option key={`cotrainer-${t.username}`} value={t.userId}>
                            {t.givenName} {t.familyName}
                          </option>
                        ))}
                      </Select>
                    )}
                    <div style={{ padding: '20px' }} />

                    <TextField name="length" label={strings.length} error={et.length} />
                    <div style={{ padding: '20px' }} />

                    <TextField
                      name="maxParticipants"
                      label={strings.maxParts}
                      error={et.maxParticipants}
                    />
                  </div>
                  <div style={{ padding: '20px' }} />
                  <Text as="p" variant="copy1">
                    {strings.trainingStart}
                  </Text>

                  <AdvancedDatePicker initialDate={initialValues.start} onChange={setDateBegin} />

                  <div style={{ padding: '20px' }} />
                  <Text as="p" variant="copy1">
                    {strings.endOfRegistration}
                  </Text>
                  <AdvancedDatePicker
                    initialDate={initialValues.bookingEnd}
                    onChange={setDateBookingEnd}
                  />

                  <div style={{ padding: '20px' }} />

                  <Editor formikName={'description'} label={strings.description}></Editor>

                  <div style={{ padding: '20px' }} />
                  <LabelButton type="submit" variant="primary">
                    {strings.save}
                  </LabelButton>
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      {props.pre && (
        <EditTraining ts={props.pre} manager={props.manager} trainers={props.trainers} />
      )}
    </EditDialog>
  );
};

export default AddTraining;
