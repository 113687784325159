import { LabelButton, LabelButtonProps, useIsTheme } from '@pp-labs/ui-components';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';

/** interface for SoftButton props coming from parent component FormSoftButton */
interface P extends LabelButtonProps {
  softDisabled: boolean;
}

const grey = '#B3B3B3 !important';

const useStyles = makeStyles({
  disabledPrim: {
    cursor: 'not-allowed !important',
    backgroundColor: grey,
    borderColor: grey,
  },
  disabledSec: {
    cursor: 'not-allowed',
    borderColor: grey,
    color: grey,
  },
  disabledText: {
    cursor: 'not-allowed',
    color: grey,
  },
});

export const SoftButton: React.FC<P> = (props) => {
  const theme = useIsTheme();
  const cls = useStyles();
  const { className, ...p } = props;
  return (
    <LabelButton
      {...p}
      className={clsx(
        props.className,
        props.softDisabled &&
          !theme.neutral &&
          (props.variant === 'primary'
            ? cls.disabledPrim
            : props.variant === 'secondary'
            ? cls.disabledSec
            : cls.disabledText)
      )}
    >
      {props.children}
    </LabelButton>
  );
};

interface FP extends LabelButtonProps {
  relevantFields: string[];
  errorCallback: (e: CustomError) => any;
  additionalSuccessCallback?: () => any;
}

export interface CustomError {
  type: 'missing' | 'validation';
  /** When missing fieldName, otherwise the validation error */
  message: string;
}

/** Button that is enabled even if displayed disabled.
 *
 * This allows to dispatch a notification why it currently is disabled.
 * Build for formik.
 * */
export const FormSoftButton: React.FC<FP> = (props) => {
  const { values, errors, submitForm } = useFormikContext();
  const { additionalSuccessCallback, errorCallback, relevantFields, ...p } = props;
  const hasError = (): CustomError | null => {
    for (let i = 0; i < relevantFields.length; i++) {
      const field = relevantFields[i];
      // @ts-ignore
      if (!!errors[field]) {
        return {
          type: 'validation',
          // @ts-ignore
          message: errors[field],
        };
      }
      // @ts-ignore
      if (!values[field]) {
        return {
          type: 'missing',
          message: field,
        };
      }
    }
    return null;
  };

  const err = hasError();

  const c = async () => {
    if (err) {
      errorCallback(err);
    } else {
      additionalSuccessCallback?.();

      await submitForm();
    }
  };

  return (
    <SoftButton type="button" {...p} softDisabled={!!err} onClick={c}>
      {props.children}
    </SoftButton>
  );
};
