import { makeStyles } from '@material-ui/core';

export const controlBarStyles = makeStyles(() => ({
  videoControlsBtn: {
    margin: '2px 8px 2px 4px',
    'pointer-events': 'all',
    padding: '0',
  },
  iconImage: {
    width: '48px',
    height: '48px',
    cursor: 'pointer',
    'pointer-events': 'all',
  },
  thumbStyle: { pointerEvents: 'all', marginRight: '9px' },
}));
