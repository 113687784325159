import React, { Component } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { Channel, EditLevel, Question } from '../../../ApiHandler/dclxInterfaces';
import DCLXCmsQuestionEdit from './DCLXCmsQuestionEdit';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { connect } from 'react-redux';
import DeleteDialogue from '../../../utils/ConfirmDialogue/DeleteDialogue';
import {
  EditIcon,
  EraseIcon,
  LabelButton,
  TableCell,
  TableRow,
  tableStyles,
  DraggableComponent,
  DroppableComponent,
  handleDrop,
} from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { byPosition } from '../../../utils/sort';

/** interface for DCLXCMSQuestionTable props coming from parent component QuestionTable */
interface P extends WithStyles<typeof tableStyles> {
  questions: Question[];
  interruptId: number;
  level: EditLevel;
  exam?: boolean;
  refresh: Function;
  triggerNotification: Function;
  channel: Channel;
}

/** interface for DCLXCMSQuestionTable component state  */
interface S {
  questions: Question[];
  activeQuestion: Question | null;
  openDialog: boolean;
  toBeDeleted: number | null;
}
/**
 * Component for listing Questions (for exams or interrupts)
 */
class DCLXCMSQuestionTable extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      questions: props.questions.sort(byPosition),
      activeQuestion: null,
      openDialog: false,
      toBeDeleted: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps: P, prevState: S, snapshot?: any) {
    if (prevProps.questions !== this.props.questions) {
      this.setState({
        questions: this.props.questions.sort(byPosition),
      });
    }
  }

  onDragEnd(result: any) {
    const newQuestions = handleDrop(result, this.state.questions);
    if (newQuestions) this.setState({ questions: newQuestions });
  }

  closeQuestionEdit = (refresh?: boolean) => {
    this.setState({ openDialog: false });
    if (refresh) this.props.refresh();
  };

  handleDelete = (id: number) => {
    this.setState({ toBeDeleted: id });
  };

  finishDelete = async () => {
    await client.delete(`questions/${this.state.toBeDeleted}`);
    this.setState({ toBeDeleted: null });
    this.props.refresh();
  };

  abortDelete = () => {
    this.setState({ toBeDeleted: null });
  };

  edit = (question: Question | null) => {
    this.setState({ activeQuestion: question, openDialog: true });
  };

  getEditButton = (question: Question) => (
    <div>
      <IconButton
        onClick={() => {
          this.edit(question);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          this.handleDelete(question.questionId);
        }}
      >
        <EraseIcon />
      </IconButton>
    </div>
  );

  confirmOrder = async () => {
    await client.post(
      `questions/order`,
      this.state.questions.map((q) => q.questionId)
    );
    this.props.triggerNotification([strings.orderSuccessfullyChanged, 'success']);
  };

  render() {
    return (
      <>
        <TableContainer>
          <Table className={this.props.classes.table} aria-label="simple table">
            {/* eslint-disable-next-line new-cap */}
            <TableBody component={DroppableComponent(this.onDragEnd)}>
              {this.state.questions.map((question, index) => {
                return (
                  <TableRow
                    // eslint-disable-next-line
                    component={DraggableComponent(question.questionId.toString(), index)}
                    key={question.questionId}
                  >
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {getLocalizedValue(question.questionText)}
                      </Text>
                    </TableCell>

                    <TableCell center buttons>
                      {this.getEditButton(question)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <LabelButton
          variant="primary"
          className={this.props.classes.btnLeft}
          onClick={() => {
            this.edit(null);
          }}
        >
          {strings.addQuestion}
        </LabelButton>
        <LabelButton
          variant="secondary"
          className={this.props.classes.btnRight}
          onClick={this.confirmOrder}
        >
          {strings.confirmNewOrder}
        </LabelButton>
        <DeleteDialogue
          toBeDeleted={this.state.toBeDeleted}
          delete={this.finishDelete}
          reject={this.abortDelete}
        />
        {this.state.openDialog && (
          <DCLXCmsQuestionEdit
            interruptId={this.props.interruptId}
            exam={this.props.exam}
            thisQuestion={this.state.activeQuestion}
            close={this.closeQuestionEdit}
            open={this.state.openDialog}
            level={this.props.level}
            newPosition={this.state.questions.length}
            channel={this.props.channel}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = { triggerNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(tableStyles)(DCLXCMSQuestionTable));
