import { PlatformEvent, ProviderSetting } from 'ApiHandler/dclxInterfaces';
import { publicClient } from 'ApiHandler/publicClient';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'utils/hooks';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

/** Login for SSO users.
 * Emulating the AWS-Auth class, because it is garbage.
 */
export const SSOLogin = () => {
  const query = useQuery();
  const { event } = useParams<{ event: string }>();

  const history = useHistory();

  useEffect(() => {
    const login = async () => {
      const loginInfo: ProviderSetting = (await publicClient.get(`event:${event}/loginInfo`)).data;
      window.location.replace(loginInfo.providers[0].authorizeUrl);
    };
    const getIdToken = async () => {
      const loginInfo: ProviderSetting = (await publicClient.get(`event:${event}/loginInfo`)).data;

      const res = await publicClient.get(`events`);

      const eventSettings: PlatformEvent = res.data.find(
        (e: PlatformEvent) => e.identifier === event
      );

      const urlencoded = new URLSearchParams();
      urlencoded.append('code', query.get('code') || '');
      urlencoded.append('grant_type', 'authorization_code');
      urlencoded.append('client_id', eventSettings.poolClientId);
      urlencoded.append('scope', 'openid');
      urlencoded.append('redirect_uri', `${window.location.origin}/${event}/ssologin`);

      const resp = await axios.post(loginInfo.providers[0].tokenUrl, urlencoded, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      const cognitoIdToken: CognitoIdToken = new CognitoIdToken({ IdToken: resp.data.id_token });
      const payload = cognitoIdToken.decodePayload();

      // Setting localStorage items so amplify can use them and the user can be logged in
      localStorage.setItem('amplify-signin-with-hostedUI', 'false');
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.${payload['cognito:username']}.idToken`,
        resp.data.id_token
      );

      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.${payload['cognito:username']}.accessToken`,
        resp.data.access_token
      );

      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.${payload['cognito:username']}.refreshToken`,
        resp.data.refresh_token
      );

      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.${payload['cognito:username']}.clockDrift`,
        '0'
      );

      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.LastAuthUser`,
        payload['cognito:username']
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${eventSettings.poolClientId}.${payload['cognito:username']}.userData`,
        JSON.stringify({
          UserAttributes: [
            {
              Name: 'sub',
              Value: payload.sub,
            },
            {
              Name: 'custom:user_group',
              Value: payload['custom:user_group'],
            },
            {
              Name: 'given_name',
              Value: payload.given_name,
            },
            {
              Name: 'family_name',
              Value: payload.family_name,
            },
            {
              Name: 'custom:channels',
              Value: payload['custom:channels'],
            },
          ],
          Username: payload.username,
        })
      );
      history.push(`/${event}/`);
    };
    if (query.get('code')) {
      getIdToken();
    } else {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
