import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { EditDialog, LabelButton, TextField } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { BaseBreakoutRoom, useLotState } from '../Provider/LotProvider';
import { nanoid } from '@reduxjs/toolkit';

const getValidation = () =>
  Yup.object({
    title: Yup.string().required(strings.required),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

/** interface for EditBreakoutRoom props coming from parent component BreakoutRooms  */
interface P {
  currentRoom: BaseBreakoutRoom | null;
  close: () => void;
}

/** Allows to change the title of a breakout Room */
export const EditBreakoutRoom = (props: P) => {
  const lotState = useLotState();
  const validationSchema = useMemo(getValidation, []);
  const initialValues: F = useMemo(
    () => ({
      title: props.currentRoom?.Title || '',
    }),
    [props.currentRoom]
  );
  const submit = async (values: F) => {
    props.currentRoom
      ? lotState.breakoutRooms.updateRoom(props.currentRoom, values.title)
      : lotState.breakoutRooms.createRoom({
          Participants: [],
          Title: values.title,
          Id: nanoid(),
          CustomProperties: { active: true, deactivateAt: null },
        });
    props.close();
  };
  return (
    <EditDialog title="" close={props.close} maxWidth="sm">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ touched, errors }) => {
          const et = {
            title: touched.title ? errors.title : '',
          };
          return (
            <Form>
              <TextField name="title" label="Title" error={et.title} />
              <LabelButton variant="primary" type="submit">
                {props.currentRoom ? strings.breakoutChange : strings.breakoutCreateNew}
              </LabelButton>
            </Form>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
