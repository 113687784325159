import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles, Theme } from '@material-ui/core';
import SnackMessage from './SnackMessage';

/** interface for Snackbar props coming from parent component Provider, SnackbarProvider */
interface P {
  children: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  audi: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '30% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  position: {
    top: 80,
  },
}));

/**
 * mange Toast/Snack Notifications
 */
const Snackbar = (props: P) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={6}
      hideIconVariant
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: classes.audi,
        variantError: classes.audi,
        variantWarning: classes.audi,
        variantInfo: classes.audi,
        containerAnchorOriginTopRight: classes.position,
      }}
      content={(key, message) => <SnackMessage id={key} message={message} />}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default Snackbar;
