import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    pos1: {
      [theme.breakpoints.up('md')]: {
        paddingRight: '40px',
      },
    },
    pos2: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '40px',
      },
    },
    sessionWrapper: {
      display: 'inline-block',
      position: 'relative',
      width: '100%',
      height: 'auto',
      boxSizing: 'border-box',
    },
    thumb: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      display: 'block',
    },
    fontTile: {
      paddingTop: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '32px !important',
        paddingLeft: '8px !important',
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: '8px !important',
        paddingLeft: '20px !important',
        paddingRight: '10px !important',
      },
    },
    categoryHeadline: {
      padding: '20px 0 10px 0',
    },
    book: {
      // minHeight: '60px',
      marginTop: 12,
    },
    bookButton: {
      height: '100%',
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
    },
    tile: {
      margin: '0 2px 28px',
    },
    bigTile: {
      margin: '0 2px 32px 2px',
    },
    eventMainWrapper: {
      padding: '0 56px 80px 56px',
      minWidth: '100%',
      [theme.breakpoints.only('md')]: {
        padding: '0 56px 80px 56px',
      },
      [theme.breakpoints.only('sm')]: {
        padding: '0 34px 80px 34px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 20px 80px 20px',
      },
    },
    trainingSliderWrapper: {
      padding: '80px 56px 0 56px',
      minWidth: '100%',
      [theme.breakpoints.only('md')]: {
        padding: '48px 56px 0 56px',
      },
      [theme.breakpoints.only('sm')]: {
        padding: '48px 34px 0 34px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '48px 0 0 0',
      },
    },
    firstStepsWrapper: {
      padding: '0 56px',
      minWidth: '100%',
    },
    watchWrapper: {
      padding: '0 56px 0 56px',
      [theme.breakpoints.only('md')]: {
        padding: '0 56px 0 28px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 34px 0 34px',
      },
    },
    lotBottomWrapper: {
      padding: '34px 56px 80px 56px',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
  });

export interface SessionClasses {
  pos1: string;
  pos2: string;
  sessionWrapper: string;
  thumb: string;
  fontTile: string;
  categoryHeadline: string;
  book: string;
  bookButton: string;
  tile: string;
  bigTile: string;
  eventMainWrapper: string;
  trainingSliderWrapper: string;
  watchWrapper: string;
}
export default styles;
