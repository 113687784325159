import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import { Session } from 'ApiHandler/dclxInterfaces';
import { EditorParser } from 'features/Editor/EditorParser';
import strings from 'Localization/Localizer';
import React from 'react';
import { humanTime } from 'utils/convert';
import { useEventSettings, useRealTime, useTexts } from 'utils/hooks';
import { Banner, BannerConfiguration } from './Banner';

const useStyles = makeStyles((theme) => ({
  bottomWrapper: {
    margin: '58px 40px',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      margin: '38px 40px',
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '38px 18px',
    },
  },
  counterWrapper: {
    width: '42%',
    margin: '28px 0px 32px 0px',
    [theme.breakpoints.down('md')]: {
      width: '52%',
      margin: '24px 0px 28px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  counterInnerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  counterTextWrapper: {
    textAlign: 'center',
  },
  countDownTitle: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '16px',
    },
  },
}));

/** interface for EventCountdown props coming from parent component Event  */
interface P {
  sessions: Session[] | undefined;
  bannerConfig: BannerConfiguration | undefined;
}

/** Countdown if the event is not running yet */
const EventCountdown = (props: P) => {
  const cls = useStyles();
  const { getText } = useTexts();
  const currentTime = useRealTime(1000);
  const eventSettings = useEventSettings();

  const startTime = eventSettings?.eventStartTiming!;
  const distanceStart = startTime * 1000 - currentTime;
  const t = humanTime(distanceStart);
  const countDownTitle = getText('countdownTitle');
  const countDownDescription = getText('countdownDescription');
  return (
    <div>
      {props.bannerConfig && (
        <Banner timeslots={[]} sessions={props.sessions} bannerConfiguration={props.bannerConfig} />
      )}
      <div className={cls.bottomWrapper}>
        <div>
          <Text as="p" variant={'order2'} weight="bold">
            {countDownTitle && <EditorParser inputString={countDownTitle} />}
          </Text>
        </div>
        <div className={cls.counterWrapper}>
          <div className={cls.counterInnerWrapper}>
            <div className={cls.counterTextWrapper}>
              <Text as="p" variant={'display2'} weight="bold">
                {t[0]}
              </Text>
              <Text as="p" variant={'copy2'} weight="bold">
                {strings.days}
              </Text>
            </div>
            <div className={cls.counterTextWrapper}>
              <Text as="p" variant={'display2'} weight="bold">
                {t[1]}
              </Text>
              <Text as="p" variant={'copy2'} weight="bold">
                {strings.hours}
              </Text>
            </div>
            <div className={cls.counterTextWrapper}>
              <Text as="p" variant={'display2'} weight="bold">
                {t[2]}
              </Text>
              <Text as="p" variant={'copy2'} weight="bold">
                {strings.minutes}
              </Text>
            </div>
            <div className={cls.counterTextWrapper}>
              <Text as="p" variant={'display2'} weight="bold">
                {t[3]}
              </Text>
              <Text as="p" variant={'copy2'} weight="bold">
                {strings.seconds}
              </Text>
            </div>
          </div>
        </div>
        <Text as="p" variant={'copy1'}>
          {countDownDescription && <EditorParser inputString={countDownDescription} />}
        </Text>
      </div>
    </div>
  );
};

export { EventCountdown };
