import { ThumbsTotal, ABCTotal } from './utils/interactionTypes';
import { BreakoutRoom, RoomId } from './Provider/LotProvider';
import { ClapData } from './Live';

/**
 * Types for Websocket Responses
 */

export interface PrivateMessage {
  Action: 'message';
  Timestamp: number;
  ChannelId?: string;
  UserId: string;
  Message: string;
  BreakoutRoom: string;
  MessageType?: MessageTypes;
}

export interface CustomHelloProps {
  trainer: string;
  runningState: RunningStateReturn;
  participantMode: 'muted' | 'audio';
  thumbs: ThumbsTotal;
  abc: ABCTotal;
  clapHands: string[];
  messages?: PrivateMessage[];
  breakoutRooms?: BreakoutRoom[];
  screenshareAllowed: boolean;
  currentStream: 0 | 1;
}

interface WelcomeMessage {
  Action: 'welcome';
  ChannelId: string;
  Users: Array<string>;
  CustomProperties: CustomHelloProps;
}

export enum MessageTypes {
  TRAINING = 'conference',
  LIVESESSION = 'session',
  CHANNEL = 'channel',
  ALL = 'all',
}

interface GenericCommand {
  Action: 'command';
  ChannelId: string;
}
/** Values for running State
 * -1: Websocket hasn't been connected yet
 * 0: Training hasn't started yet
 * 1: Training was stopped
 * 2: Training running in Presenter View
 * 3: Training running in Tile View
 */
export type RunningState = -1 | 0 | 1 | 2 | 3;
export type RunningStateReturn = '-1' | '0' | '1' | '2' | '3';
interface RunningStateCommand extends GenericCommand {
  CommandKey: 'runningState';
  CommandValue: RunningStateReturn;
}

interface SimpleCommand extends GenericCommand {
  CommandKey: 'mute' | 'cleared' | 'clearedABC' | 'clearedThumbs';
}

interface GenericStringCommand extends GenericCommand {
  CommandKey: 'hand' | 'unhand' | 'participantMode' | 'setTrainer' | 'hello' | 'goodbye';
  CommandValue: string;
}

interface ABCCommand extends GenericCommand {
  CommandKey: 'abcTotal';
  CommandValue: ABCTotal;
}

interface ThumbsCommand extends GenericCommand {
  CommandKey: 'thumbsTotal';
  CommandValue: ThumbsTotal;
}

interface ClapCommand extends GenericCommand {
  CommandKey: 'clapHands';
  CommandValue: ClapData;
}

interface Kick {
  name: 'kick';
}

interface RefreshAmaDirector {
  name: 'refreshAmaDir';
  time: number;
}

interface RefreshAmaVis {
  name: 'refreshAmaVis';
  // either all or the appropriate sub
  user: 'all' | string;
}

interface StartBreakoutCountdown {
  name: 'startBreakoutCountdown';
  room: RoomId | 'all';
}

export type CustomCommandValue = Kick | RefreshAmaDirector | RefreshAmaVis | StartBreakoutCountdown;

export interface CustomCommand extends GenericCommand {
  CommandKey: 'notify';
  CommandValue: CustomCommandValue;
}

export interface BreakoutCommand extends GenericCommand {
  CommandKey: 'breakoutRooms';
  CommandValue: BreakoutRoom[];
}

export interface Screenshare extends GenericCommand {
  CommandKey: 'screenshareAllowed';
  CommandValue: boolean;
}

export interface StreamChange extends GenericCommand {
  CommandKey: 'streamChange';
  CommandValue: { state: number };
}

export interface StreamChangeOut extends GenericCommand {
  CommandKey: 'streamChange';
  CommandValue: number;
}

export type GeneralCommand =
  | WelcomeMessage
  | SimpleCommand
  | GenericStringCommand
  | RunningStateCommand
  | ABCCommand
  | ThumbsCommand
  | PrivateMessage
  | CustomCommand
  | BreakoutCommand
  | StreamChange
  | Screenshare
  | ClapCommand;
