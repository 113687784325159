import { Text } from '@pp-labs/ui-components';
import { LabelButton, UploadButton } from '@pp-labs/ui-components';
import React, { useState } from 'react';
import { client } from '../../../ApiHandler/client';
import { S3File } from '../../../ApiHandler/dclxInterfaces';
import axios from 'axios';
import strings from 'Localization/Localizer';
import { DownloadCsv } from '../../../utils/csvParser/DownloadCsv';
import { useNotify } from '../../../utils/hooks';

interface F {
  file: File;
  name: string;
}

interface Ret {
  documentUrl: S3File;
  document: string;
}

/** allows to upload training bookings as a csv */
export const UploadTrainingsCSV = () => {
  const notify = useNotify();
  const [csvFile, setCsvFile] = useState<F | null>(null);

  const handleFile = (_: any, file: File) => {
    setCsvFile({ file: file, name: file.name });
  };

  const upload = async () => {
    if (!csvFile) return;
    const up: Ret = (await client.get('trainings/bookings/import/url')).data;
    await axios.put(up.documentUrl.url, csvFile.file, {
      headers: { 'Content-Type': up.documentUrl.contentType },
    });
    try {
      const res = await client.post(`trainings/bookings/import/${up.document.split('.')[0]}`);
      if (res.status === 200) {
        notify(
          strings
            .formatString(strings.importedDatasets, { imported: res.data.imported })
            .toString(),
          'success'
        );
      } else {
        handleError();
      }
    } catch {
      handleError();
    }
    setCsvFile(null);
  };

  const handleError = () => notify(strings.somethingWentWrongImport, 'error');

  const loadData = async () => [
    ['1', 'user.1'],
    ['2', 'user.1'],
    ['2', 'user.2'],
  ];

  return (
    <div>
      <Text variant="order2" weight="bold">
        {strings.uploadTrainingsCSV}
      </Text>

      <UploadButton
        handleFile={handleFile}
        type="csv"
        name=""
        label={csvFile?.name || strings.importCSV}
      />

      <DownloadCsv loadData={loadData} fileName={'example.csv'} title={'Download example data'} />

      <LabelButton variant="secondary" onClick={upload} disabled={!csvFile} spaceInlineStart="m">
        {strings.submit}
      </LabelButton>
    </div>
  );
};
