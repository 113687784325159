import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SessionTable } from './SessionTable';
import { isStreamingEvent } from '../../../utils/convert';
import DCLXCmsSessionEdit from './DCLXCmsSessionEdit';
import {
  ChannelTopic,
  ConfigModuleIdentifiers,
  EditLevel,
  Session,
  SessionType,
} from '../../../ApiHandler/dclxInterfaces';
import { client } from '../../../ApiHandler/client';
import { Text, LayoutItem } from '@pp-labs/ui-components';
import { useNamedConfigModule, useCMSChannel } from '../../../utils/hooks';
import { UploadTrainingsCSV } from './UploadTrainingsCSV';
import strings from 'Localization/Localizer';

/** interface for DCLXCmsModule props  */
interface P {
  level: EditLevel;
}

/**
 * Wrapper for Session Management
 */

type Base = {
  topic: ChannelTopic | null;
  stream: boolean;
  sessionType: SessionType;
};
type NewSession = Base & {
  type: 'new';
};

type EditSession = Base & {
  type: 'edit';
  session: Session;
};

const DCLXCmsModule = (props: P) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentEdit, setCurrentEdit] = useState<EditSession | NewSession | null>(null);
  const selectedChannel = useCMSChannel()!;

  const [topics, setTopics] = useState<ChannelTopic[]>([]);
  const usesModule = useNamedConfigModule();

  const editSession = useCallback(async (id: number, topic: ChannelTopic | null) => {
    const fullSession = (await client.get(`/sessions/${id}`)).data as Session;
    if (!fullSession) return;
    setCurrentEdit({
      type: 'edit',
      session: fullSession,
      topic: topic,
      sessionType: fullSession.sessionType,
      stream: isStreamingEvent(fullSession),
    });
  }, []);

  const createSession = useCallback(
    async (topic: ChannelTopic | null, type: SessionType, stream: boolean) => {
      setCurrentEdit({
        type: 'new',
        topic: topic,
        sessionType: type,
        stream: stream,
      });
    },
    []
  );

  const fetchData = useCallback(async () => {
    const s: Session[] = (await client.get('sessions')).data;
    setSessions(s);
  }, []);

  const closeEdit = useCallback(() => {
    setCurrentEdit(null);
  }, []);

  const successEdit = useCallback(async () => {
    closeEdit();
    await fetchData();
  }, [closeEdit, fetchData]);

  const loadTopics = async () => {
    try {
      const data = (await client.get<ChannelTopic[]>('topics')).data;
      setTopics(data);
    } catch {}
  };
  useEffect(() => {
    fetchData();
    loadTopics();
  }, [fetchData]);

  const channelTopics = useMemo(
    () =>
      selectedChannel ? topics.filter((t) => t.channelId === selectedChannel.channelId) : null,
    [selectedChannel, topics]
  );
  if (!sessions) return null;
  const demandSessions = sessions?.filter((s) => s.sessionType === SessionType.DEMAND) || [];
  return (
    <>
      {currentEdit && selectedChannel && (
        <DCLXCmsSessionEdit
          refs={demandSessions}
          thisSession={currentEdit.type === 'edit' ? currentEdit.session : null}
          success={successEdit}
          abort={closeEdit}
          type={currentEdit.sessionType}
          topicIds={currentEdit.topic ? [currentEdit.topic.topicId] : []}
          event
          channel={selectedChannel}
          level={props.level}
          newPosition={sessions.length}
          stream={currentEdit.stream}
        />
      )}
      <div>
        <Text variant={'order2'} weight={'bold'}>
          {strings.cmsMenuSessions}
        </Text>

        {/* session without any topic */}
        {selectedChannel && (
          <div>
            <SessionTable
              channelId={selectedChannel.channelId}
              sessions={sessions.filter((s) => {
                return s.topicIds?.length === 0 && s.channelId === selectedChannel.channelId;
              })}
              edit={editSession}
              level={props.level}
              topic={null}
              createSession={createSession}
              refresh={fetchData}
              events
              trainings={[]}
              trainers={[]}
            />
          </div>
        )}
        {/* session with topics */}
        {channelTopics?.map((topic, index) => (
          <div key={index}>
            <SessionTable
              channelId={topic.channelId}
              sessions={sessions.filter((s) => s.topicIds.includes(topic.topicId))}
              edit={editSession}
              level={props.level}
              topic={topic}
              createSession={createSession}
              refresh={fetchData}
              events
              trainings={[]}
              trainers={[]}
            />
          </div>
        ))}
        {usesModule(ConfigModuleIdentifiers.videoConferenceSessions) && (
          <LayoutItem spacing={['xxl', undefined, 'xxl', undefined]}>
            <UploadTrainingsCSV />
          </LayoutItem>
        )}
      </div>
    </>
  );
};

export default DCLXCmsModule;
