// @ts-ignore
import headerImage from '../../../media/fallbackHeader.jpg';
// @ts-ignore
import profileImage from '../../../media/fallbackProfile.png';
import { Text } from '@pp-labs/ui-components';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { useCustomFields } from '../../../utils/hooks';

const useStyles = makeStyles({
  toolTipUserCard: {
    position: 'absolute',
    zIndex: 10,
    left: 70,
    top: 0,
    width: 400,
    maxHeight: 800,
    backgroundColor: '#777777',
    '&::before': {
      content: '""',
      color: 'white',
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: 10,
      borderStyle: 'solid',
      borderColor: 'transparent #777 transparent transparent',
      top: 11,
      left: -20,
      position: 'absolute',
    },
  },

  pictureContainer: {
    position: 'relative',
  },
  banner: {
    textAlign: 'center',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    height: '80px',
  },
  whiteText: {
    color: 'white !important',
  },
  textContainer: {
    padding: '24px 24px 12px 120px',
  },
  textContainer2: {
    padding: '12px 24px 24px 24px',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '40vh',
  },
  cardThumb: {
    borderRadius: '50%',
    height: '80px',
    width: '80px',
    position: 'absolute',
    top: '40px',
    left: '20px',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
});
/** interface for UserInfo props coming from parent component DclxUserTile  */
interface P {
  user: UserPicture;
}

/**
 * Displays detailed user infos on a card
 */

export const UserInfo = (props: P) => {
  const cls = useStyles();
  const customFields = useCustomFields(props.user);
  return (
    <div className={cls.toolTipUserCard}>
      <div className={cls.pictureContainer}>
        <div
          style={{
            backgroundImage: `url(${props.user?.header || headerImage})`,
          }}
          className={cls.banner}
        />
        <div
          style={{
            backgroundImage: `url(${props.user.thumb || profileImage})`,
          }}
          className={cls.cardThumb}
        />
      </div>
      <div className={cls.textContainer}>
        <Text as="p" variant="copy1" className={cls.whiteText}>
          {props.user.givenName} {props.user.familyName}
        </Text>
      </div>
      <div className={cls.textContainer2}>
        <Text as="p" variant="copy1" className={cls.whiteText}>
          {customFields['custom:jobFunction']}
        </Text>
        <br />
        <Text as="p" variant="copy1" className={cls.whiteText}>
          {props.user.aboutMe}
        </Text>
      </div>
    </div>
  );
};
