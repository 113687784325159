import React from 'react';
import { Text } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';

import edge from '../../media/browserLogos/edge.png';
import chrome from '../../media/browserLogos/chrome.png';
import firefox from '../../media/browserLogos/firefox.png';
import safariCross from '../../media/browserLogos/SafariCross.svg';
import ie from '../../media/browserLogos/ie.png';
import strings from '../../Localization/Localizer';

const useStyles = makeStyles({
  warningContainer: {
    border: '2px solid red',
    padding: '16px',
    marginBottom: '16px',
    display: 'flex',
  },
  container: {
    width: '100%',
    display: 'table',
  },
  browser: {
    display: 'table-row',
  },
  browserIcon: {
    height: '72px',
    width: '52px',
    display: 'table-cell',
    padding: '10px 0',
  },
  browserText: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    paddingLeft: '20px',
  },
});
/**
 * Component rendered if the users browser is unsupported
 */
export const BadBrowser = () => {
  const cls = useStyles();
  return (
    <>
      <div className={cls.warningContainer}>
        <Text as="p" variant="copy1">
          {strings.browserCheckText}
        </Text>
      </div>
      <Text as="p" variant="copy1">
        <b>{strings.supportedBrowsers}</b>
      </Text>
      <div className={cls.container}>
        <div className={cls.browser}>
          <img src={edge} className={cls.browserIcon} alt="edge" />
          <Text as="span" variant="copy1" className={cls.browserText}>
            {strings.edge}
          </Text>
        </div>
        <div className={cls.browser}>
          <img src={chrome} className={cls.browserIcon} alt="chrome" />
          <Text as="span" variant="copy1" className={cls.browserText}>
            {strings.chrome}
          </Text>
        </div>
        <div className={cls.browser}>
          <img src={firefox} className={cls.browserIcon} alt="firefox" />
          <Text as="span" variant="copy1" className={cls.browserText}>
            {strings.firefox}
          </Text>
        </div>
        <div className={cls.browser}>
          <img src={safariCross} className={cls.browserIcon} alt="safari" />
          <Text as="span" variant="copy1" className={cls.browserText}>
            {strings.safari}
          </Text>
        </div>
        <div className={cls.browser}>
          <img src={ie} className={cls.browserIcon} alt="ie" />

          <Text as="span" variant="copy1" className={cls.browserText}>
            {strings.internetExplorer}
          </Text>
        </div>
      </div>
    </>
  );
};
