import React, { useMemo, useState } from 'react';
import StepIndicator from 'utils/StepIndicator';
import {
  LabelButton,
  TextField,
  TextArea,
  Select,
  LocalizedForm,
  ButtonColors,
} from '@pp-labs/ui-components';

import AudiSpacer from 'utils/AudiSpacer';
import { StepInfos, AdminPanelSteps, NeutralThemeButton } from './adminPanelTypes';
import { Form, Formik, FormikErrors } from 'formik';
import { Text } from '@pp-labs/ui-components';
import { toInt } from 'utils/convert';
import * as Yup from 'yup';
import strings, { allLangs } from '../../../Localization/Localizer';
import { adminClient } from 'ApiHandler/adminClient';
import { useNotify } from 'utils/hooks';
import { AdvancedDatePicker } from '../../utils/AdvancedDatePicker';
import { LanguagesSelector } from 'DCLX/utils/LanguagesSelector';
import { Language, LanguagesSelection, MultiLanguage } from 'ApiHandler/dclxInterfaces';
import { allThemes } from 'config';
import { atLeastOneEmail } from 'utils/validator';

/** type ThemeButtons consist types of upload neutralThemeButtons */
type ThemeButtons = { name: NeutralThemeButton; label: string };

const neutralThemeButtons: ThemeButtons[] = [
  { name: 'primaryButton', label: strings.primaryButton },
  { name: 'secondaryButton', label: strings.secondaryButton },
  { name: 'primaryText', label: strings.primaryText },
  { name: 'secondaryText', label: strings.secondaryText },
  { name: 'primaryHover', label: strings.primaryHover },
  { name: 'secondaryHover', label: strings.secondaryHover },
  { name: 'primaryDisabled', label: strings.primaryDisabled },
  { name: 'secondaryDisabled', label: strings.secondaryDisabled },
  { name: 'primaryDisabledText', label: strings.primaryDisabledText },
  { name: 'secondaryDisabledText', label: strings.secondaryDisabledText },
];

const color = () => Yup.string().min(6, 'Must be exactly 6').max(6, 'Must be exactly 6');
const validation = Yup.object({
  title: Yup.string().required(strings.required),
  identifier: Yup.string().required(strings.required),
  description: Yup.string().required(strings.required).max(150, strings.maxDescriptionLength),
  supportEmail: atLeastOneEmail(),
  preferedLanguage: Yup.string().required(strings.required),
  theme: Yup.string().required(strings.required),
  primaryButton: color(),
  secondaryButton: color(),
  primaryText: color(),
  secondaryText: color(),
  primaryHover: color(),
  secondaryHover: color(),
  primaryDisabled: color(),
  secondaryDisabled: color(),
  primaryDisabledText: color(),
  secondaryDisabledText: color(),
}).required(strings.required);
/** General information in Event Creation */
const EventSetupStepInfos = (props: AdminPanelSteps) => {
  const notify = useNotify();
  const initialValues: StepInfos = useMemo(() => {
    const startDate = props.values.eventStartTiming
      ? new Date(toInt(props.values.eventStartTiming) * 1000)
      : props.initialData?.eventStartTiming
      ? new Date(toInt(props.initialData?.eventStartTiming) * 1000)
      : new Date();
    const endDate = props.values.eventEndTiming
      ? new Date(toInt(props.values.eventEndTiming) * 1000)
      : props.initialData?.eventEndTiming
      ? new Date(toInt(props.initialData?.eventEndTiming) * 1000)
      : new Date();

    // checks the selected lang from props
    let langSelect: LanguagesSelection | undefined = undefined;
    try {
      langSelect = JSON.parse(props.values.languages);
    } catch (error) {}
    // checks the selected lang from initial data
    let initialLangSelect: LanguagesSelection | undefined = undefined;
    try {
      initialLangSelect = JSON.parse(props.initialData?.languages!);
    } catch (error) {}

    // checks the selected theme from props and initial data
    const getInitialTheme = () => {
      let themeSelect: { type: string } | undefined = undefined;
      try {
        themeSelect = JSON.parse(props.values.theme);
      } catch {}
      let initialThemeSelect: { type: string } | undefined = undefined;
      try {
        initialThemeSelect = JSON.parse(props.initialData?.theme!);
      } catch {}
      return themeSelect?.type || initialThemeSelect?.type || 'audi';
    };

    // check theme button colors and button text color
    const getButtonColors = (type: NeutralThemeButton) => {
      let buttonColors: ButtonColors | undefined = undefined;
      try {
        buttonColors = JSON.parse(props.values.theme);
      } catch {}
      let initialButtonColors = undefined;
      try {
        initialButtonColors = JSON.parse(props.initialData?.theme!);
      } catch {}
      return buttonColors?.[type] || initialButtonColors?.[type] || '';
    };

    let supportEmail: MultiLanguage<string>[] = [];

    try {
      if (props.initialData?.supportEmail)
        supportEmail = JSON.parse(props.initialData?.supportEmail);
    } catch {}
    try {
      supportEmail = JSON.parse(props.values.supportEmail);
    } catch {}

    return {
      title: props.values.title || props.initialData?.title || '',
      identifier: props.values.identifier || props.initialData?.identifier || '',
      description: props.values.description || props.initialData?.description || '',
      supportEmail: supportEmail,
      preferedLanguage: langSelect?.preferedLanguage || initialLangSelect?.preferedLanguage || '',

      selectedLanguages: langSelect?.languages || initialLangSelect?.languages || [],
      start: startDate,
      end: endDate,
      theme: getInitialTheme(),
      primaryButton: getButtonColors('primaryButton'),
      secondaryButton: getButtonColors('secondaryButton'),
      primaryText: getButtonColors('primaryText'),
      secondaryText: getButtonColors('secondaryText'),
      primaryHover: getButtonColors('primaryHover'),
      secondaryHover: getButtonColors('secondaryHover'),
      primaryDisabled: getButtonColors('primaryDisabled'),
      secondaryDisabled: getButtonColors('secondaryDisabled'),
      primaryDisabledText: getButtonColors('primaryDisabledText'),
      secondaryDisabledText: getButtonColors('secondaryDisabledText'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values, props.initialData]);

  const [begin, setBegin] = useState<Date>(initialValues.start);
  const [end, setEnd] = useState<Date>(initialValues.end);
  const [selectedLangs, setSelectedLangs] = useState<Language[]>(initialValues.selectedLanguages);

  const getErrors = (touched: any, errors: FormikErrors<StepInfos>) => {
    return {
      title: touched.title ? errors.title : '',
      identifier: touched.identifier ? errors.identifier : '',
      description: touched.description ? errors.description : '',
      supportEmail: touched.supportEmail
        ? Array.isArray(errors.supportEmail)
          ? errors.supportEmail
              .map((value: string | FormikErrors<MultiLanguage<string>>, index: number) =>
                value
                  ? `${typeof value === 'string' ? value : value.value} (${allLangs[index].name})`
                  : ''
              )
              .filter((s: string) => s)
              .toString()
          : errors.supportEmail
        : '',
      preferedLanguage: touched.preferedLanguage ? errors.preferedLanguage : '',
      theme: touched.theme ? errors.theme : '',
      primaryButton: touched.primaryButton ? errors.primaryButton : '',
      secondaryButton: touched.secondaryButton ? errors.secondaryButton : '',
      primaryText: touched.primaryText ? errors.primaryText : '',
      secondaryText: touched.secondaryText ? errors.secondaryText : '',
      primaryHover: touched.primaryHover ? errors.primaryHover : '',
      secondaryHover: touched.secondaryHover ? errors.secondaryHover : '',
      primaryDisabled: touched.primaryDisabled ? errors.primaryDisabled : '',
      secondaryDisabled: touched.secondaryDisabled ? errors.secondaryDisabled : '',
      primaryDisabledText: touched.primaryDisabledText ? errors.primaryDisabledText : '',
      secondaryDisabledText: touched.secondaryDisabledText ? errors.secondaryDisabledText : '',
    };
  };

  const setValues = async (v: StepInfos) => {
    let IdentifierCheckPassed;
    if (props.initialData) {
      IdentifierCheckPassed = true;
    } else {
      IdentifierCheckPassed = await (
        await adminClient(`/events/identifierAvailable/${v.identifier}`)
      ).data;
    }
    if (IdentifierCheckPassed) {
      const data = {
        title: v.title,
        identifier: v.identifier,
        description: v.description,
        supportEmail: JSON.stringify(v.supportEmail),
        eventStartTiming: begin.getTime() / 1000,
        eventEndTiming: end.getTime() / 1000,
        languages: JSON.stringify({
          languages: selectedLangs,
          preferedLanguage: v.preferedLanguage,
        }),
        theme: JSON.stringify({
          type: v.theme,
          primaryButton: v.primaryButton,
          secondaryButton: v.secondaryButton,
          primaryText: v.primaryText,
          secondaryText: v.secondaryText,
          primaryHover: v.primaryHover,
          secondaryHover: v.secondaryHover,
          primaryDisabled: v.primaryDisabled,
          secondaryDisabled: v.secondaryDisabled,
          primaryDisabledText: v.primaryDisabledText,
          secondaryDisabledText: v.secondaryDisabledText,
        }),
      };
      props.setValues({ ...props.values, ...data });
      props.setStep(2);
    } else {
      notify(strings.identifierAlrdyTaken, 'error');
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={setValues}>
      {({ errors, touched, values }) => {
        const et = getErrors(touched, errors);
        return (
          <>
            <StepIndicator step={1} numSteps={4} />
            <Form>
              <TextField name={'title'} label={strings.eventTitle} error={et.title} />
              <TextField
                name={'identifier'}
                label={strings.eventShortIdentifier}
                error={et.identifier}
                disabled={props.initialData !== null}
              />
              <TextArea
                name={'description'}
                label={strings.eventDescription}
                error={et.description}
              />

              <AudiSpacer spaceStackEnd={'s'} />
              <Text as="p" variant="copy1">
                {strings.startsAt}:
              </Text>
              <AdvancedDatePicker initialDate={initialValues.start} onChange={setBegin} />
              <AudiSpacer spaceStackEnd={'s'} />
              <Text as="p" variant="copy1">
                {strings.endsAt}:
              </Text>
              <AdvancedDatePicker initialDate={initialValues.end} onChange={setEnd} />
              <AudiSpacer spaceStackEnd={'s'} />
              <LanguagesSelector
                languages={[...allLangs]}
                setSelectedLanguages={setSelectedLangs}
                selectedLanguages={selectedLangs}
                error={et.preferedLanguage}
              />
              {selectedLangs.length > 0 && (
                <>
                  <AudiSpacer spaceStackEnd={'l'} />
                  <LocalizedForm
                    languages={selectedLangs.map((l) => l.name)}
                    localizeInputs={['supportEmail']}
                    initialValues={{
                      supportEmail: initialValues.supportEmail,
                    }}
                    autoParse={[]}
                  >
                    <TextField
                      name={'supportEmail'}
                      label={strings.supportEmail}
                      error={et.supportEmail}
                    />
                  </LocalizedForm>
                </>
              )}

              <AudiSpacer spaceStackEnd={'l'} />
              <Select name={'theme'} label={strings.selectTheme} required>
                {allThemes.map((theme) => {
                  return (
                    <option key={theme} value={theme}>
                      {theme}
                    </option>
                  );
                })}
              </Select>
              {(values.theme === 'light' || values.theme === 'dark') && (
                <div style={{ marginTop: '12px' }}>
                  <Text variant="copy1">Please enter the color values in Hex code without "#"</Text>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
                  >
                    {neutralThemeButtons.map((btn: ThemeButtons, i: number) => (
                      <div key={i} style={{ width: '45%' }}>
                        <TextField name={btn.name} label={btn.label} error={errors[btn.name]} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <AudiSpacer spaceStackEnd={'l'} />
              <LabelButton variant="primary" type="submit" id="next">
                {strings.next}
              </LabelButton>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export { EventSetupStepInfos };
