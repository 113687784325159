import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Chart, registerables, defaults } from 'chart.js';
import { Text, TableRow } from '@pp-labs/ui-components';
import { makeStyles, TableCell, Table, TableBody, TableHead } from '@material-ui/core';
import useMeasure from 'react-use-measure';

Chart.register(...registerables);

/** interface for BarDiagram props coming from parent components ResultsDiagrams, ViewDiagrams and UserDiagrams */
export interface P {
  /** Labels for the chart */
  labels: string[];
  /** Data values for the chart */
  items: (number | string)[];
  /** if the chart should be displayed vertically instead of horizontally*/
  vertical?: boolean;
  /** Optional Title for the chart */
  title?: string;
  /** Optional fixed height for the chart */
  height?: number;
  max?: number;
  furtherLabels?: string[];
  furtherItems?: Array<Array<number | string | null>>;
}

export const CHART_COLORS = {
  black: 'rgb(0, 0, 0)',
};
const minHeight = 33;

defaults.font.family = 'AudiType';
defaults.font.size = 16;

const useStyles = makeStyles(() => ({
  value: {
    minWidth: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  textCell: {
    height: 50,
    width: '100%',
    backgroundColor: 'white',
  },
  TableCell: {
    backgroundColor: 'white',
  },
}));

/**
 * Wrapper for Bar Charts
 */
export const BarDiagram = (props: P) => {
  const cls = useStyles();

  const [headRef, headDimensions] = useMeasure();
  const [bodyRef, bodyDimensions] = useMeasure();
  if (props.items.length === 0) {
    return null;
  }

  return (
    <div>
      <Text variant="order2" weight="bold">
        {props.title}
      </Text>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Table>
            {props.furtherLabels && props.furtherLabels.length > 0 && (
              <TableHead ref={headRef}>
                <TableRow>
                  <TableCell className={cls.TableCell}></TableCell>
                  <TableCell className={cls.TableCell}></TableCell>
                  {props.furtherLabels?.map((l: string) => (
                    <TableCell className={cls.TableCell}>
                      <Text as="p" variant="copy1" weight="bold">
                        {l}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody ref={bodyRef}>
              {props.labels.map((label) => {
                return (
                  <TableRow>
                    <TableCell className={cls.textCell} style={{ minWidth: 400, height: 80 }}>
                      <Text as="p" variant="copy1" className={cls.value}>
                        {label}
                      </Text>
                    </TableCell>
                    <TableCell className={cls.TableCell}>
                      <Text as="p" variant="copy1" className={cls.value}>
                        {props.items[props.labels.indexOf(label)]}
                      </Text>
                    </TableCell>
                    {props.furtherItems?.map((item) => (
                      <TableCell className={cls.TableCell}>
                        <Text as="p" variant="copy1" className={cls.value}>
                          {item[props.labels.indexOf(label)]}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        {bodyDimensions.height && (
          <div
            style={{
              marginTop: headDimensions.height + 1, //one pixel for the border for better alignment
              height: bodyDimensions.height + minHeight - 1,
              minWidth: '500px',
              width: '100%',
            }}
          >
            <Bar
              data={{
                labels: props.labels,
                datasets: [
                  {
                    data: props.items,
                    backgroundColor: [CHART_COLORS.black],
                    barPercentage: 1,
                    categoryPercentage: 1,
                    barThickness: 16,
                    maxBarThickness: 16,
                  },
                ],
              }}
              options={{
                indexAxis: props.vertical ? 'y' : 'x',
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    min: 0,
                    max: props.max,
                    ticks: {
                      precision: 0,
                    },
                  },
                  yAxes: {
                    type: 'category',

                    display: false,
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
