import React, { useState } from 'react';
import { doesRoleMatch, Role } from '../../../utils/RoleDefinitions';
import { Text, PlusIcon, RemoveIcon, useIsTheme } from '@pp-labs/ui-components';
import strings from '../../../Localization/Localizer';
import { CognitoUser, Session, TimeSlot } from '../../../ApiHandler/dclxInterfaces';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
    cursor: 'pointer',
  },
  removeIcon: {
    background: '#000000',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    padding: '10px',
  },
  notAllowed: {
    position: 'absolute',
    top: 5,
    right: 5,
    background: '#2A2F34',
    padding: '8px',
  },
  blackText: {
    color: '#000000',
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block',
    marginRight: '8px !important',
  },
});

interface GeneralProps {
  user: CognitoUser;
  book: (session: Session) => void;
  delete: (session: Session, slot: TimeSlot) => void;
}

interface BookingTabProps extends GeneralProps {
  session: Session;
  timeslot?: TimeSlot;
}

/** LOT Booking/unbooking buttons */
const BookingScheduleButtons = (props: BookingTabProps) => {
  const cls = useStyles();
  const theme = useIsTheme();
  const [showTip, setShowTip] = useState<boolean>(false);
  if (!doesRoleMatch(props.user, [Role.VISITOR])) {
    return (
      <div className={cls.notAllowed}>
        <Text as="p" variant="copy2">
          <b style={{ color: 'white' }}>{strings.bookingNotAllowed}</b>
        </Text>
      </div>
    );
  }
  if (props.timeslot) {
    return (
      <>
        <div className={cls.wrapper}>
          <div
            className={cls.removeIcon}
            onClick={() => props.delete(props.session, props.timeslot!)}
          >
            <RemoveIcon fontSize="medium" />
          </div>
        </div>
      </>
    );
  }
  return (
    <div className={cls.wrapper}>
      <div
        className={cls.addIcon}
        onMouseOver={() => setShowTip(true)}
        onMouseOut={() => setShowTip(false)}
        onClick={() => props.book(props.session)}
      >
        <Text
          variant="copy2"
          className={clsx(theme.neutralDark && cls.blackText, showTip ? cls.show : cls.hide)}
        >
          {strings.bookNow}
        </Text>

        <PlusIcon fontSize="medium" />
      </div>
    </div>
  );
};

export { BookingScheduleButtons };
