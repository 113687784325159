import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  makeStyles,
  TablePagination,
  TableFooter,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Checkbox,
  DownloadButton,
  EraseIcon,
  tableStyles,
  Text,
  LabelButton,
  Search,
  UploadIcon,
  AudiDialog,
  CancelIcon,
  EditIcon,
} from '@pp-labs/ui-components';
import { client } from 'ApiHandler/client';
import axios from 'axios';
import strings from 'Localization/Localizer';
import { useEffect, useRef, useState } from 'react';
import AudiSpacer from 'utils/AudiSpacer';
import { getVideoDuration } from 'utils/convert';
import { useProgressionChannels, useNotify } from 'utils/hooks';
import Duration from '../../../utils/Duration';
import { HtmlEditor } from './HtmlEditor';

const useStyles = makeStyles({
  ...tableStyles(),
  tableCell: {
    padding: '0px 16px',
  },
  tableRowRoot: {
    height: 10,
    padding: 0,

    '&$tableRowSelected, &$tableRowSelected:hover': {
      backgroundColor: 'lightgray',
    },
  },
  tableRowSelected: {
    backgroundColor: 'lightgray',
  },
});

export type MediaLibraryProps = {
  /** Specify to limit to specific file types */
  fileTypes?: string;
  interactType: 'select' | 'selectMultiple' | 'none';
  onSubmit: (files: MediaFile[]) => void;
  preselectedMediaIds: number[];
  /** Advanced allows replacing of videos */
  editMode?: 'simple' | 'advanced';
  /** Used to only filter for specific tag and create new docs with that tag */
  tags?: string;
  /** Used to only html editor */
  showCreateHtml?: boolean;
};
export interface MediaFile {
  originalFile: string;
  mediaId: number;
  url: string;
  downloadUrl: string;
  tags: string;
  contentType: string;
  metaData?: string;
  updatedAt?: number;
}

interface SubmitData {
  file: string;
  tags: string;
  channels: number[];
  metaData: string;
}

export const getAccept = (type: string): string => {
  switch (type) {
    case 'image':
      return 'image/png,image/jpeg';
    case 'pdf':
      return 'application/pdf';
    case 'mixed':
      return 'image/png,image/jpeg,application/pdf';
    case 'vtt':
      return '.vtt';
    case 'video':
      return 'video/mp4';
    case 'csv':
      return '.csv';
  }
  return '';
};

/** Central Media Library for all files */
export const MediaLibrary = (props: MediaLibraryProps) => {
  const cls = useStyles();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<MediaFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadETA, setUploadETA] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const [search, setSearch] = useState<string>('');
  const [editMedia, setEditMedia] = useState<MediaFile | null>(null);
  const [deleteMedia, setDeleteMedia] = useState<MediaFile | null>(null);
  const [htmlEditor, setHtmlEditor] = useState<MediaFile | null | 'new'>(null);

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const channels = useProgressionChannels();
  const notify = useNotify();

  const isMediaTypeAllowed = (fileType: string) => {
    if (props.fileTypes) {
      return props.fileTypes?.split(',').some((type) => {
        return type.includes(fileType) || fileType.includes(type.replace('.', ''));
      });
    } else return true;
  };

  const uploadFile = async (uploadedFiles: File[], mediaId?: number) => {
    let upFiles: File[] = uploadedFiles;
    const editingMediaId: number | undefined = mediaId || editMedia?.mediaId;
    const exist =
      !editingMediaId && files.find((f) => uploadedFiles.some((uf) => f.originalFile === uf.name));
    if (exist) {
      const existingFiles = files.filter((f) =>
        uploadedFiles.some((uf) => f.originalFile === uf.name)
      );
      // shows notification for all of the existing files with name
      existingFiles.forEach((f) => {
        setTimeout(() => notify(`${f.originalFile} ${strings.fileAlreadyExists}`, 'error'), 1);
      });
      // filter the files that are already existing and only upload the new ones
      upFiles = upFiles.filter((f) => !files.some((uf) => f.name === uf.originalFile));

      if (upFiles.length === 0) return;
    }
    setEditMedia(null);
    setUploading(true);
    const interval = setInterval(() => {
      setCurrentTime((prevTime) => prevTime + 1);
    }, 1000);
    setCurrentTime(0);
    const newFiles: SubmitData[] = [];
    // uploading files
    for (let i = 0; i < upFiles.length; i++) {
      const file = upFiles[i];
      setUploadETA(Math.round(8468880 / 1024 / 1024));
      //File MetaDatas
      const metaData: { [key: string]: any } = {};
      let videoDuration: number | null = null;
      try {
        for (let i = 0; i < upFiles.length; i++) {
          const uploadDuration = upFiles[i];
          let durationFile = await getVideoDuration(uploadDuration);
          if (videoDuration) {
            videoDuration = durationFile + videoDuration;
          } else {
            videoDuration = durationFile;
          }
          metaData.duration = videoDuration;
        }
      } catch {}
      if (videoDuration) {
        setUploadETA(Math.round(videoDuration) / 5.5);
        metaData.duration = videoDuration;
      }
      const data = {
        file: file?.name,
        tags: props.tags || 'media-library',
        channels: channels.map((c) => c.channelId),
        metaData: JSON.stringify(metaData),
      };
      newFiles.push(data);
    }

    const resp = editingMediaId
      ? (await client.put(`/media/${editingMediaId}`, newFiles[0])).data
      : (await client.post(`/media/bulk`, newFiles)).data;

    const promises: Promise<any>[] = [];
    try {
      resp.forEach(async (res: MediaFile, i: number) => {
        promises.push(
          axios.put(res.url, upFiles ? Object.values(upFiles)[i] : '', {
            headers: { 'Content-Type': res.contentType },
          })
        );
        await Promise.all(promises)
          .then((response) => {
            if (response.length === resp.length) {
              setUploading(false);
              clearInterval(interval);
            }
          })
          .then(() => {
            notify(`${upFiles[i].name} ${strings.uploadedDocument}`, 'success');
          });
      });
    } catch (err: any) {}
    if (resp && props.interactType !== 'none') {
      await fetchFiles(false, resp.mediaId);
    } else await fetchFiles();
  };

  const fetchFiles = async (init?: boolean, addSelectionMediaId?: number) => {
    const selection = selectedFiles.map((f) => f.mediaId);

    const filesData = (await client.get(`media`)).data.sort(
      (f1: MediaFile, f2: MediaFile) => f2.updatedAt! - f1.updatedAt! // Newests First
    );

    setFiles(
      filesData.filter(
        (f: MediaFile) =>
          isMediaTypeAllowed(f.contentType) && f.tags === (props.tags || 'media-library') && f.url
      )
    );
    if (init) {
      setSelectedFiles(
        filesData.filter((f: MediaFile) => props.preselectedMediaIds.includes(f.mediaId))
      );
    } else if (addSelectionMediaId) {
      const s = filesData.filter(
        (f: MediaFile) =>
          f.mediaId === addSelectionMediaId ||
          (props.interactType === 'selectMultiple' && selection.includes(f.mediaId))
      );

      setSelectedFiles(s);
      setPage(0); //reset pagination to see newly uploaded file
    } else {
      setSelectedFiles(filesData.filter((f: MediaFile) => selection.includes(f.mediaId)));
    }
  };
  useEffect(() => {
    fetchFiles(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadClick = () => {
    hiddenFileInput?.current?.click();
  };
  const deleteSelectedMedia = async () => {
    if (deleteMedia) {
      try {
        await client.delete(`media/${deleteMedia.mediaId}`);
      } catch (err: any) {
        err.response.status === 409
          ? notify(strings.fileIsUsedError, 'error')
          : notify(err.message, 'error');
      }
      setDeleteMedia(null);
      fetchFiles();
    }
  };

  if (uploading)
    return (
      <div style={{ padding: '0 50px' }}>
        <div className={'field-error'}>
          <Text as="p" variant="copy1">
            {strings.uploadingETA}:
          </Text>
          <Duration seconds={currentTime} className="duration" />/
          <Duration seconds={uploadETA} className="duration" />
          <Text as="p" variant="copy1">
            {strings.doNotLeavePageUntilUploadIsFinished}
          </Text>
        </div>
      </div>
    );
  return (
    <div style={{ padding: '0 12px' }}>
      <AudiSpacer spaceStackEnd="l" />
      <Search
        inputId={'search'}
        label={strings.searchFiles}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <AudiSpacer spaceStackEnd="l" />
      <div>
        <Table className={cls.table}>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={
                files.filter(
                  (f) =>
                    selectedFiles.includes(f) ||
                    f.originalFile.toLowerCase().includes(search.toLowerCase())
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, pageNumber) => {
                setPage(pageNumber);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableFooter>
          <TableBody id="media-files-table">
            {files
              .filter(
                (f) =>
                  selectedFiles.includes(f) ||
                  f.originalFile.toLowerCase().includes(search.toLowerCase())
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file: MediaFile, index) => (
                <TableRow
                  classes={{
                    root: cls.tableRowRoot,
                    selected: cls.tableRowSelected,
                  }}
                  key={index}
                  selected={selectedFiles.includes(file)}
                  onClick={(e) => {
                    if (props.interactType === 'selectMultiple') {
                      !selectedFiles.includes(file)
                        ? setSelectedFiles([...selectedFiles, file])
                        : setSelectedFiles(selectedFiles.filter((f) => f.mediaId !== file.mediaId));
                    } else if (props.interactType === 'select') setSelectedFiles([file]);
                  }}
                  hover={props.interactType !== 'none'}
                >
                  {props.interactType === 'selectMultiple' && (
                    <TableCell padding="checkbox" className={cls.tableCell}>
                      <div>
                        <Checkbox
                          handle={(name: string, checked: boolean) => {
                            !selectedFiles.includes(file)
                              ? setSelectedFiles([...selectedFiles, file])
                              : setSelectedFiles([...selectedFiles.filter((f) => f !== file)]);
                          }}
                          value={''}
                          currentValue={selectedFiles.includes(file)}
                          manual
                          name={file.mediaId.toString()}
                          label={''}
                        />
                      </div>
                    </TableCell>
                  )}
                  <TableCell className={cls.tableCell}>
                    <DownloadButton name={file.originalFile} url={file.downloadUrl} />
                  </TableCell>
                  <TableCell className={cls.tableCell}>
                    <IconButton
                      style={{ color: 'black' }}
                      onClick={() => {
                        setDeleteMedia(file);
                      }}
                    >
                      <EraseIcon />
                    </IconButton>
                    {props.editMode === 'advanced' && file.contentType.includes('video') && (
                      <IconButton
                        style={{ color: 'black' }}
                        onClick={() => {
                          setEditMedia(file);
                        }}
                      >
                        <UploadIcon />
                      </IconButton>
                    )}
                    {file.contentType.includes('html') && (
                      <IconButton
                        style={{ color: 'black' }}
                        onClick={() => {
                          setHtmlEditor(file);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ float: 'right' }}>
        {props.showCreateHtml &&
          (!props.fileTypes || props.fileTypes.includes('html')) &&
          !files.some((f) => f.originalFile === `${props.tags}.html`) && (
            <LabelButton
              variant="secondary"
              onClick={() => setHtmlEditor('new')}
              className={cls.btnLeft}
            >
              Create HTML
            </LabelButton>
          )}
        <LabelButton variant="secondary" onClick={uploadClick} className={cls.btnLeft}>
          <input
            type="file"
            accept={editMedia ? getAccept('video') : props.fileTypes}
            ref={hiddenFileInput}
            onChange={(event) => {
              if (event.target.files) uploadFile([...event.target.files]);
            }}
            onClick={(event) => {
              event.currentTarget.value = '';
            }}
            multiple={true}
            style={{ display: 'none' }}
          />
          <div style={{ paddingLeft: 12 }}>
            <Text as="span" variant="copy1">
              {strings.uploadDocument}
            </Text>
          </div>
        </LabelButton>
        {props.interactType !== 'none' && (
          <LabelButton
            id="confirm-select-media"
            variant="primary"
            onClick={() =>
              selectedFiles.length > 0 || props.interactType === 'selectMultiple'
                ? props.onSubmit(selectedFiles)
                : notify(strings.selectOneError, 'error')
            }
            className={cls.btnRight}
          >
            {strings.select}
          </LabelButton>
        )}
      </div>
      {!!deleteMedia && (
        <AudiDialog open={true}>
          <DialogTitle>
            <Text variant="order2">{strings.deleteFile}</Text>
            <IconButton
              onClick={() => setDeleteMedia(null)}
              style={{ position: 'absolute', right: '6px', top: '6px' }}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Text as="p" variant="copy1">
              {strings.confirmDeleteFile}
            </Text>
          </DialogContent>
          <DialogActions>
            <LabelButton onClick={() => setDeleteMedia(null)} variant="secondary">
              {strings.cancel}
            </LabelButton>
            <LabelButton onClick={() => deleteSelectedMedia()} variant="primary">
              {strings.yes}
            </LabelButton>
          </DialogActions>
        </AudiDialog>
      )}

      {!!editMedia && (
        <AudiDialog open={true}>
          <DialogTitle>
            <Text variant="order2">{strings.replaceFile}</Text>
            <IconButton
              onClick={() => setEditMedia(null)}
              style={{ position: 'absolute', right: '6px', top: '6px' }}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Text as="p" variant="copy1">
              {strings.confirmReplaceFile}
            </Text>
          </DialogContent>
          <DialogActions>
            <LabelButton onClick={() => setEditMedia(null)} variant="secondary">
              {strings.cancel}
            </LabelButton>
            <LabelButton onClick={() => uploadClick()} variant="primary">
              {strings.yes}
            </LabelButton>
          </DialogActions>
        </AudiDialog>
      )}

      {!!htmlEditor && (
        <HtmlEditor
          file={htmlEditor}
          onSubmit={async (file) => {
            await uploadFile([file], htmlEditor !== 'new' ? htmlEditor?.mediaId : undefined);
            setHtmlEditor(null);
          }}
          newName={`${props.tags}.html`}
          close={() => setHtmlEditor(null)}
        />
      )}
    </div>
  );
};
