import React, { useState } from 'react';
import StepIndicator from '../utils/StepIndicator';
import { NewPassword } from './DirectRegisterPhases/NewPassword';
import { RequestMail } from './DirectRegisterPhases/RequestMail';
import { SupportEmail } from './SupportEmail';
import {
  activateSms,
  checkUsername,
  CodeMethod,
  deactivateSms,
  getErrorMessage,
  getSentMessage,
} from './utils';
import { useNotify } from '../utils/hooks';
import * as Cognito from '../ApiHandler/Cognito';
import strings from '../Localization/Localizer';
import { MfaInfo } from './DirectRegisterPhases/MfaInfo';
import { PlatformEvent } from '../ApiHandler/dclxInterfaces';

/** DirectRegisterData interface the holds user attributes for normal/direct registration flow  */
export interface DirectRegisterData {
  username: string;
  code: string;
  password: string;
}
/** DirectRegisterForm props interface for normal/direct registration flow  */
interface P {
  finish: (data: DirectRegisterData) => void;
  userNorFound: () => void;
  toLogin: () => void;
  username?: string;
  mfa: boolean;
  eventSettings: PlatformEvent;
  maxSteps: number;
}

/** Handles the normal/direct registration flow. */
const DirectRegisterForm = (props: P) => {
  const [phase, setPhase] = useState<'mfaInfo' | 'requestMail' | 'newPass'>(
    props.mfa ? 'mfaInfo' : 'requestMail'
  );
  const [username, setUsername] = useState<string>('');
  const notify = useNotify();

  const sendRegistrationCode = async (usernameValue: string, sms: boolean): Promise<boolean> => {
    if (sms) {
      const success = await activateSms(usernameValue, props.eventSettings.poolId, notify);
      if (!success) return false;
    } else {
      // prevent bad state users
      await deactivateSms(usernameValue, props.eventSettings.poolId);
    }

    try {
      const e = await Cognito.forgotPassword(usernameValue);
      const dest = e.CodeDeliveryDetails.Destination;
      notify(getSentMessage(sms, dest), 'success');
      return true;
    } catch (e: any) {
      if (e.code === 'LimitExceededException') {
        notify(getErrorMessage(105, strings.limitExceeded), 'error');
      } else {
        notify('Unknown error', 'error');
      }
      return false;
    }
  };

  const finishAuthInfo = () => setPhase('requestMail');

  const finishNewPass = (code: string, password: string) => {
    const d: DirectRegisterData = {
      username: username,
      password: password,
      code: code,
    };
    props.finish(d);
  };

  const finishReqMail = async (usernameValue: string, codeMethod: CodeMethod) => {
    const r = await checkUsername(usernameValue);
    if (r === 'canLogIn') {
      notify(strings.alreadyRegistered, 'success');
      props.toLogin();
    } else if (r === 'notFound') {
      props.userNorFound();
    } else if (r === 'unknown') {
      notify(strings.corruptUser, 'error');
      props.toLogin();
    } else {
      // everything okay so far
      if (codeMethod) {
        const success = await sendRegistrationCode(usernameValue, codeMethod === 'sms');
        if (!success) return;
      }
      setUsername(usernameValue);
      setPhase('newPass');
    }
  };

  const getByPhase = () => {
    switch (phase) {
      case 'mfaInfo':
        return <MfaInfo finish={finishAuthInfo} mfa={props.mfa} />;
      case 'requestMail':
        return <RequestMail finish={finishReqMail} username={props.username} mfa={props.mfa} />;
      case 'newPass':
        return <NewPassword finish={finishNewPass} />;
    }
  };

  const getStep = () => {
    switch (phase) {
      case 'mfaInfo':
        return 1;
      case 'requestMail':
        return props.mfa ? 2 : 1;
      case 'newPass':
        return props.mfa ? 3 : 2;
    }
  };

  return (
    <div>
      <StepIndicator step={getStep()} numSteps={props.maxSteps} />
      {getByPhase()}
      <div style={{ paddingTop: '24px' }}>
        <SupportEmail />
      </div>
    </div>
  );
};

export default DirectRegisterForm;
