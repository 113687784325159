import React from 'react';
import { CMSAccess, DashboardAccess, doesRoleMatch, Role } from './RoleDefinitions';
import { useDispatch } from 'react-redux';
import { setUser } from '../AppSlice';
import { useHistory, useLocation, Route } from 'react-router-dom';
import { useNotify, useUser } from './hooks';
import { adminRoute } from '../config';
import { useSignOut } from '../ApiHandler/Cognito';
import { NavBar } from '../features/NavBar/NavBar';
import { getErrorMessage } from '../auth/utils';
import strings from '../Localization/Localizer';
import { SurveyProvider } from '../features/Survey/SurveyProvider';

/** interface for PrivateRoute props coming from parent component App */
interface PrivateRouteProps {
  path: string;
  requiredRole: Role | Array<Role>;
  component?: any;
  redirect?: string;
  exact?: boolean;
  toStart?: boolean;
  onlyP3?: boolean;
  computedMatch?: {
    params?: {
      event?: string;
    };
  };
}

/**
 * Protect Routes from unauthorized access
 *
 * All guards return whether the render can proceed. This is necessary, because history.push seems
 * to happen async and we run into infinite loops otherwise.
 */

export const PrivateRoute = (props: PrivateRouteProps) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const signOut = useSignOut();

  const currentUser = useUser();

  const history = useHistory();
  const location = useLocation();
  const urlEvent = props.computedMatch?.params?.event;

  const userGuard = (): boolean => {
    const redirect = urlEvent ? `/${urlEvent}/login` : '/';
    if (!currentUser) {
      if (location.pathname !== '/admin/create') {
        history.push(redirect, { origin: location?.pathname });
      } else {
        history.push('/admin/login', { origin: location?.pathname }); //workaround for admin/create as urlEvent is undefined in that case.
      }
      return false;
    }

    // Should rarely ever happen, so not in TM. But fixes impatient users.
    if (!currentUser['cognito:groups']?.length) {
      notify("Your user doesn't have any groups assigned yet. Please login again later.", 'error');
      signOut().then(() => history.push(redirect, { origin: location?.pathname }));

      return false;
    }

    return true;
  };

  const redirectGuard = (): boolean => {
    if (props.redirect) {
      history.push(props.redirect);
      return false;
    }
    return true;
  };

  const startGuard = (): boolean => {
    if (!props.toStart) return true;

    if (urlEvent) {
      if (doesRoleMatch(currentUser, CMSAccess)) {
        history.push(`/${urlEvent}/cms/start`);
      } else if (doesRoleMatch(currentUser, Role.EVENTADMIN)) {
        history.push(`/${adminRoute}/create`);
      } else if (doesRoleMatch(currentUser, [Role.VIEWER, Role.REPORTINGS, Role.VISITOR])) {
        history.push(`/${urlEvent}/events`);
      } else if (doesRoleMatch(currentUser, Role.DIRECTOR)) {
        history.push(`/${urlEvent}/amas`);
      } else if (doesRoleMatch(currentUser, DashboardAccess)) {
        history.push(`/${urlEvent}/dashboard/participants`);
      } else {
        signOut().then(() => {
          dispatch(setUser(null));
          notify(getErrorMessage(103, strings.invalidGroup), 'error');
          history.push('/');
        });
      }
    } else {
      history.push('/');
    }
    return false;
  };

  const roleGuard = (): boolean => {
    if (!doesRoleMatch(currentUser, props.requiredRole)) {
      history.push(urlEvent ? `/${urlEvent}` : '/');
      return false;
    } else {
      return true;
    }
  };

  const guard = (): boolean => userGuard() && redirectGuard() && startGuard() && roleGuard();

  if (!guard()) return null;

  return (
    <>
      <SurveyProvider>
        <NavBar />
      </SurveyProvider>
      <Route {...props} component={props.component} />
    </>
  );
};
