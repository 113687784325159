import React, { useMemo } from 'react';
import { Text } from '@pp-labs/ui-components';
import { IconButton, makeStyles, Theme, withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CancelIcon } from '@pp-labs/ui-components';
import { useTexts } from '../../../../utils/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  survey: {
    zIndex: 10,
    textAlign: 'left',
    backgroundColor: 'rgba(0, 0, 0)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  text: {
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      padding: '20px 60px 0 10px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '40px 60px 0 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 150px 0 50px',
    },
  },
  btn: {
    color: 'white',
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
}));

/** interface for CallToAction props coming from parent components InnerIVSLivePlayer, LivePlayer, OnDemandPlayer */
interface P {
  close: () => void;
  width: Breakpoint;
  noClose?: boolean;
}

/**
 * Overlay to ask the user to rate the session
 */

const CallToAction = (props: P) => {
  const { getText } = useTexts();
  const cls = useStyles();
  const size = useMemo(() => {
    switch (props.width) {
      case 'xs':
        return 'copy1';
      case 'sm':
        return 'order2';
      case 'md':
        return 'order3';
      case 'lg':
        return 'order2';
      default:
        return 'order1';
    }
  }, [props.width]);
  const ctaText = getText('onDemandCta');
  if (!ctaText) return null;
  return (
    <div className={cls.survey}>
      {!props.noClose && (
        <IconButton className={cls.btn} onClick={props.close}>
          <CancelIcon />
        </IconButton>
      )}

      <Text as="h2" variant={size} className={cls.text}>
        {ctaText}
      </Text>
    </div>
  );
};

export default withWidth()(CallToAction);
