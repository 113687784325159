import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { EditIcon, EraseIcon, LabelButton, tableStyles, ViewIcon } from '@pp-labs/ui-components';
import { TableContainer } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import { Channel, ChannelTopic } from 'ApiHandler/dclxInterfaces';
import { NotCreatedPlaceholder } from '../Sessions/NotCreatedPlaceholder';
import { client } from 'ApiHandler/client';
import EditTopic from './TopicForm';
import DeleteDialogue from 'utils/ConfirmDialogue/DeleteDialogue';
import EditChannel from './ChannelForm';
import { TopicTable } from './TopicTable';
import { ReactComponent as DuplicateIcon } from '../../../media/duplicateIcon.svg';
import { TableArrows } from 'DCLX/utils/TableArrows';
import { useNavigateEventIdNewTab } from 'utils/hooks';

const useStyle = makeStyles({
  ...tableStyles(),
  topicTitle: {
    padding: '30px',
    background: '#e5e5e5',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '12px',
  },
});

/** interface for ChannelTable props coming from parent component channelSettings  */
interface P {
  channel: Channel;
  topics: ChannelTopic[] | null;
  refresh: () => void;
}

/** List all channels */
const ChannelTable = (props: P) => {
  const [openChannel, setOpenChannel] = useState<boolean>(false);
  const [topics, setTopics] = useState<ChannelTopic[]>();
  const [openTopicForm, setOpenTopicForm] = useState<boolean>(false);
  const [openChannelForm, setOpenChannelForm] = useState<boolean>(false);
  const [currentTopic, setCurrentTopic] = useState<ChannelTopic | null>(null);
  const [toBeDeleted, setToBeDeleted] = useState<number | null>(null);
  const [deleteType, setDeleteType] = useState<'exam' | 'topic' | 'channel'>();
  const [duplicateChannel, setDuplicateChannel] = useState<boolean>(false);

  const newTab = useNavigateEventIdNewTab();

  const getTopics = async () => {
    const topicsData = (await client.get(`topics`)).data;
    const filteredTopics = topicsData.filter(
      (topic: ChannelTopic) => topic.channelId === props.channel.channelId
    );
    setTopics(filteredTopics);
  };
  useEffect(() => {
    getTopics();
    // eslint-disable-next-line
  }, []);

  const finishDelete = async () => {
    await client.delete(`${deleteType}s/${toBeDeleted}`);
    if (deleteType === 'channel') {
      props.refresh();
    } else if (deleteType === 'topic') {
      getTopics();
    }
    setToBeDeleted(null);
  };

  const abortDelete = () => {
    setToBeDeleted(null);
  };

  const handleDelete = (id: number) => {
    setToBeDeleted(id);
  };

  const cls = useStyle();
  return (
    <div>
      <div className={cls.topicTitle} style={{ justifyContent: 'space-between' }}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => setOpenChannel(!openChannel)}
        >
          <IconButton style={{ color: 'black' }} disabled>
            {<TableArrows open={openChannel} />}
          </IconButton>
          <Text variant="copy1" weight={'bold'}>
            {props.channel.channelName}
          </Text>
        </div>
        <div style={{ width: '39%', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Text variant="copy3">{strings.category}</Text>
            <Text variant="copy1">
              {props.channel.categoryId !== 1 ? props.channel.categoryUniqueName : strings.public}
            </Text>
          </div>
          <div>
            <Text variant="copy3">{strings.channelKey}</Text>
            <Text variant="copy1">{props.channel.channelKey}</Text>
          </div>
          <div>
            <IconButton
              onClick={() => {
                newTab(`page/preview/${props.channel.channelId}`);
              }}
            >
              <ViewIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setDuplicateChannel(true);
                setOpenChannelForm(true);
              }}
            >
              <DuplicateIcon />
            </IconButton>
            <IconButton
              style={{ color: 'black' }}
              onClick={() => {
                setDuplicateChannel(false);
                setOpenChannelForm(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              style={{ color: 'black' }}
              onClick={() => {
                handleDelete(props.channel.channelId);
                setDeleteType('channel');
              }}
            >
              <EraseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {openChannel && (
        <>
          {topics && topics.length > 0 ? (
            <TableContainer style={{ marginTop: '4px' }}>
              {topics.map((topic) => {
                return (
                  <TopicTable
                    key={topic.topicId}
                    topic={topic}
                    setCurrentTopic={setCurrentTopic}
                    handleDelete={handleDelete}
                    setOpenTopicForm={setOpenTopicForm}
                    setDeleteType={setDeleteType}
                  />
                );
              })}
            </TableContainer>
          ) : (
            <NotCreatedPlaceholder text={strings.noTopic} />
          )}
          <LabelButton
            id="addTopic"
            variant={'primary'}
            onClick={() => {
              setCurrentTopic(null);
              setOpenTopicForm(true);
            }}
          >
            {strings.newTopic}
          </LabelButton>
        </>
      )}
      {/* All popups */}
      <EditChannel
        open={openChannelForm}
        channel={props.channel}
        close={() => {
          setDuplicateChannel(false);
          setOpenChannelForm(false);
          props.refresh();
        }}
        duplicate={duplicateChannel}
      />

      {openTopicForm && (
        <EditTopic
          open={openTopicForm}
          topic={currentTopic}
          channel={props.channel}
          close={() => {
            setOpenTopicForm(false);
            getTopics();
          }}
        />
      )}
      <DeleteDialogue toBeDeleted={toBeDeleted} delete={finishDelete} reject={abortDelete} />
    </div>
  );
};
export { ChannelTable };
