import React, { useMemo, useEffect, useState } from 'react';
import { AppBar, IconButton, Link, Toolbar } from '@material-ui/core';
import {
  Channel,
  CognitoUser,
  ConfigModuleIdentifiers,
  EditLevel,
} from '../../ApiHandler/dclxInterfaces';
import { doesRoleMatch, Role } from '../../utils/RoleDefinitions';
import { CMSTrainings } from './Trainings/CMSTrainings';
import { SurveyCMS } from './Surveys/SurveyCMS';
import { Text, useSetTheme, ViewIcon } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import strings from '../../Localization/Localizer';
import ResetMfa from './Users/ResetMfa';
import {
  useIsTablet,
  useNamedConfigModule,
  useNavigateEventNewTab,
  useProgressionChannels,
  useSetEventTheme,
  useUser,
} from '../../utils/hooks';
import { ChannelSettings } from './Channels/ChannelSettings';
import DCLXEvents from './Sessions/DCLXEvents';
import CmsSupport from './Support/CmsSupport';
import DCLXSections from './DCLXSections';
import { gutterStyle } from '../../utils/gutter';
import clsx from 'clsx';
import DCLXBanner from './DCLXBanner';
import { LiveAmaRoute } from './LiveAma/LiveAmaRoute';
import { TextCms } from '../../features/Text/TextCms';
import { FirstStepsCms } from './FirstSteps/FirstSteps';
import { LobbyMediaEdit } from './LobbyMediaEdit';
import { AmaWrapper } from './ConventionAma/AmaWrapper';
import { Messages } from './Messages/Messages';
import { DCLXMediaLibrary } from './DCLXMediaLibrary';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { LegalDocEdit } from './Documents/LegalDocEdit';
import { Form, Formik, Field } from 'formik';
import { setCMSChannel } from '../../AppSlice';
import { useDispatch } from 'react-redux';
import { Select as AudiSelect } from '@audi/audi-ui-react';
import { MenuItemsCMS } from './MenuItemsCMS';
import { UploadAgenda } from '../../features/agenda/UploadAgenda';

interface Tab {
  text: string;
  hasAccess: boolean;
  component: any;
  route: string;
  noPadding?: boolean;
  channelDependend?: boolean;
}

const getTabRoute = (tab: Tab, selectedChannelId?: number) => {
  if (!tab) return '';
  return tab.channelDependend ? `${selectedChannelId}/${tab.route}` : tab.route;
};
const getTabRouteDefinition = (tab: Tab) => {
  if (!tab) return '';
  return tab.channelDependend ? `:channelId/${tab.route}` : tab.route;
};

const getTabs = (user: CognitoUser, usesNamedConfigModule: (var1: number) => boolean) => {
  const isSuper = doesRoleMatch(user, Role.SUPER);
  const isManager = doesRoleMatch(user, Role.MANAGER);
  const isMarket = doesRoleMatch(user, Role.MARKET);
  const isSupport = doesRoleMatch(user, [Role.SUPPORT, Role.SUPPORTSUPERVISOR]);
  const isTrainer = doesRoleMatch(user, Role.TRAINER);
  const isAdmin = isMarket || isSuper;
  const canEditUser = isSupport || isSuper;
  const canManageTrainings = isManager || isTrainer;

  const t: Tab[] = [
    {
      text: strings.cmsMenuChannels,
      hasAccess: isMarket,
      component: ChannelSettings,
      route: 'channels',
    },
    {
      text: strings.cmsMenuSessions,
      hasAccess: isAdmin,
      component: DCLXEvents,
      route: 'sessions',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuSections,
      hasAccess: isAdmin,
      component: DCLXSections,
      route: 'sections',
      channelDependend: true,
    },
    {
      text: 'Media Library',
      hasAccess: isAdmin,
      component: DCLXMediaLibrary,
      route: 'media',
    },
    {
      text: strings.cmsMenuSupport,
      hasAccess: isAdmin,
      component: CmsSupport,
      route: 'support',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuSurvey,
      hasAccess: isAdmin && usesNamedConfigModule(ConfigModuleIdentifiers.mainSurvey),
      component: SurveyCMS,
      route: 'survey',
      channelDependend: true,
    },
    // {
    //   text: strings.changeMail,
    //   show: isAdmin,
    //   component: ChangeMail,
    // },
    {
      text: strings.cmsMenuTrainings,
      hasAccess: canManageTrainings,
      component: CMSTrainings,
      route: 'trainings',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuResetMfa,
      hasAccess: canEditUser,
      component: ResetMfa,
      route: 'resetMfa',
    },
    {
      text: strings.cmsMenuLegalDocs,
      hasAccess: isMarket,
      component: LegalDocEdit,
      route: 'legal',
    },
    {
      text: strings.cmsMenuFeatureBanner,
      hasAccess: isMarket,
      component: DCLXBanner,
      route: 'banner',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuAskedQuestions,
      hasAccess: isMarket || isManager || isTrainer,
      component: LiveAmaRoute,
      noPadding: true,
      route: 'liveAma',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuTexts,
      hasAccess: isMarket,
      component: TextCms,
      route: 'texts',
    },
    {
      text: strings.lobbyMedia,
      hasAccess: isAdmin && usesNamedConfigModule(ConfigModuleIdentifiers.videoConferenceSessions),
      component: LobbyMediaEdit,
      route: 'lobby',
    },
    {
      text: strings.cmsMenuFirstSteps,
      hasAccess: isMarket,
      component: FirstStepsCms,
      route: 'firstSteps',
      channelDependend: true,
    },
    {
      text: strings.cmsMenuMessages,
      hasAccess: isMarket || isManager,
      component: Messages,
      route: 'messages',
    },
    {
      text: strings.cmsMenuAma,
      hasAccess: isMarket,
      component: AmaWrapper,
      noPadding: true,
      route: 'conventionAma',
      channelDependend: true,
    },

    {
      text: 'Menu Items',
      hasAccess: isMarket,
      component: MenuItemsCMS,
      noPadding: true,
      route: 'menuItems',
    },
    {
      text: 'Agenda',
      hasAccess: isMarket,
      component: UploadAgenda,
      channelDependend: true,
      route: 'agenda',
    },
  ];
  return t.filter((a) => a.hasAccess);
};

const useStyles = makeStyles({
  link: {
    margin: '4px 18px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover, &:active': {
      textDecoration: 'none',
    },
  },
  active: {
    borderBottom: '2px solid black',
    paddingBottom: '5px',
  },
  text: {
    color: '#666666',
  },
  activeText: {
    color: 'black',
  },
});

interface LinkProps {
  text: string;
  index: number;
  change: (newIndex: number) => void;
  currentValue: number;
}

const MyLink = (props: LinkProps) => {
  const cls = useStyles();
  const b = props.index === props.currentValue ? `${cls.active} ${cls.link}` : cls.link;
  const t = props.index === props.currentValue ? cls.activeText : cls.text;
  return (
    <Link className={b} onClick={() => props.change(props.index)}>
      <Text className={t} variant="copy1" id="cmsSubMenu">
        {props.text}
      </Text>
    </Link>
  );
};

const useCmsStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: 0,
    backgroundColor: 'white',
    minWidth: '100%',
  },
  link: {
    margin: '4px 18px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover, &:active': {
      textDecoration: 'none',
    },
  },
  channelText: {
    color: 'white',
  },

  channelActive: {
    color: 'white',
    borderBottom: '2px solid white',
    paddingBottom: '5px',
  },
  ...gutterStyle(theme),
}));

/**
 * Entry Point for Content Management System
 */

export const CMS = () => {
  const params = useParams<{ event: string; channelId?: string; tab: string }>();
  const history = useHistory();
  const user = useUser()!;
  const dispatch = useDispatch();

  const usesNamedConfigModule = useNamedConfigModule();
  const level = useMemo(() => {
    if (doesRoleMatch(user, Role.MARKET)) return EditLevel.MARKET;
    if (doesRoleMatch(user, Role.TRAINER)) return EditLevel.MARKET;
    return EditLevel.HQ;
  }, [user]);
  const cls = useCmsStyles();
  const newTab = useNavigateEventNewTab();
  const setTheme = useSetTheme();
  const channels = useProgressionChannels();
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>();
  const isTablet = useIsTablet();

  useEffect(() => {
    if (channels.length > 0) {
      if (params.channelId) {
        const c: Channel = channels.find((c) => c.channelId.toString() === params.channelId)!;
        setSelectedChannel(c);
        dispatch(setCMSChannel(c));
      }
    }
  }, [params.channelId, channels, dispatch, selectedChannel]);

  const tabs = useMemo(
    () => getTabs(user, usesNamedConfigModule),
    [user, usesNamedConfigModule]
  ).filter((t: Tab) => !!selectedChannel || !t.channelDependend);

  const setEventTheme = useSetEventTheme();
  const setSelectedTab = (n: number) => {
    const target = tabs[n];
    if (target) {
      history.push(`/${params.event}/cms/${getTabRoute(target, selectedChannel?.channelId)}`);
    }
  };
  const selectedTab = useMemo(() => tabs.findIndex((t) => t.route === params.tab), [tabs, params]);
  const initialValues = useMemo(
    () => ({
      channelId: selectedChannel ? selectedChannel.channelId.toString() : '',
    }),
    [selectedChannel]
  );
  const submitChannelSelection = async (v: { channelId: string }) => {
    const c: Channel = channels.find((c) => c.channelId.toString() === v.channelId)!;

    dispatch(setCMSChannel(c));
    if (!c) {
      setSelectedChannel(undefined);
      history.push(`/${params.event}/cms`);
    } else {
      setSelectedChannel(c);
      history.push(`/${params.event}/cms/${getTabRoute(tabs[selectedTab], Number(v.channelId))}`);
    }
  };

  useEffect(() => {
    setTheme({
      name: 'audi',
    });
    return () => {
      setEventTheme();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const props = {
    level: level,
    manager: doesRoleMatch(user, Role.MANAGER),
  };

  // wait for the data to load to render the CMS in case we want to assign a channel
  if ((channels.length === 0 || !selectedChannel) && !!params.channelId) {
    return <></>;
  }

  return (
    <>
      <AppBar
        color="inherit"
        elevation={0}
        className={clsx('navbarSep', cls.subNavWrapper)}
        position="static"
        style={{ zIndex: isTablet ? 999 : undefined }}
      >
        <Toolbar>
          <React.Fragment key={0}>
            <div style={{ maxWidth: '350px', backgroundColor: 'grey' }}>
              <div
                style={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '20px',
                  gap: '10px',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Formik initialValues={initialValues} onSubmit={submitChannelSelection}>
                  {({ submitForm }) => {
                    return (
                      <Form onChange={submitForm}>
                        <Field
                          inputId={'channelId'}
                          as={AudiSelect}
                          label={strings.selectAChannel}
                          name={'channelId'}
                          required={false}
                          type="select"
                          className={cls.channelText}
                        >
                          {channels.map((c, i) => (
                            <option key={i} value={c.channelId}>
                              {c.channelName}
                            </option>
                          ))}
                        </Field>
                      </Form>
                    );
                  }}
                </Formik>
                <IconButton
                  onClick={() => {
                    newTab(`page/preview/${selectedChannel?.channelId}`);
                  }}
                  disabled={!selectedChannel}
                >
                  <ViewIcon />
                </IconButton>
              </div>
            </div>
          </React.Fragment>
          <div
            style={{
              paddingLeft: '20px',
              paddingBottom: '20px',
              display: 'flex',
              flexWrap: 'wrap',
              alignSelf: 'end',
            }}
          >
            {tabs.map((t, i) => {
              return (
                <React.Fragment key={i}>
                  <MyLink
                    text={t.text}
                    index={i}
                    change={setSelectedTab}
                    currentValue={selectedTab}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </Toolbar>
      </AppBar>
      {tabs.length > 0 && (
        <Switch>
          {tabs.map((t) => {
            return (
              <Route key={t.route} path={`/:event/cms/${getTabRouteDefinition(t)}`}>
                <div className={clsx(cls.root, !t?.noPadding && cls.gutterAll)}>
                  <t.component {...props} />
                </div>
              </Route>
            );
          })}
          <Route key="start" path={`/:event/cms`}>
            <Start
              event={params.event}
              tabs={tabs}
              selectedChannelId={selectedChannel?.channelId}
            />
          </Route>
        </Switch>
      )}
    </>
  );
};

const Start = (props: { tabs: Tab[]; event: string; selectedChannelId?: number }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/${props.event}/cms/${getTabRoute(props.tabs[0], props.selectedChannelId)}`);
  }, [history, props]);
  return null;
};
