import React, { Component } from 'react';
import ParticipantManager from './ParticipantManager';
import {
  BookingUser,
  UserPicture,
  TimeSlot,
  TrainingWithUsers,
} from '../../../ApiHandler/dclxInterfaces';
import { Role } from '../../../utils/RoleDefinitions';
import { client } from '../../../ApiHandler/client';

/** interface for EditTraining props coming from parent component AddTraining */
interface P {
  ts: TimeSlot;
  manager: boolean;
  trainers: Array<UserPicture>;
}

/** interface for EditTraining component state  */
interface S {
  participants: Array<BookingUser> | null;
  visitors: Array<UserPicture> | null;
}

/**
 * Wrapper for Participant Manager
 */

class EditTraining extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      participants: null,
      visitors: null,
    };
  }

  componentDidMount() {
    this.fetchParts(true);
  }

  fetchParts = async (initial?: boolean) => {
    const t: TrainingWithUsers = (
      await client.get(`trainings/${this.props.ts.trainingId}/bookings/users`)
    ).data;
    const parts = t.bookingsUsers;
    if (initial) {
      const vis = (await client.get(`users/role/${Role.VISITOR}`)).data;
      this.setState({ participants: parts, visitors: vis });
    } else {
      this.setState({ participants: parts });
    }
  };

  render() {
    if (!this.state.participants || !this.state.visitors) return null;
    return (
      <ParticipantManager
        participants={this.state.participants}
        allVisitors={[...this.state.visitors, ...this.props.trainers]}
        refresh={this.fetchParts}
        trainingsId={this.props.ts.trainingId}
        manager={this.props.manager}
      />
    );
  }
}

export default EditTraining;
