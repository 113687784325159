import { GetFirstStep } from '../../ApiHandler/dclxInterfaces';
import { useLoad } from '../../utils/hooks';
import { ProgressionReturn } from '../../progression';
import { useMemo } from 'react';
import { FullFirstStep, getFirstStepMedia } from '../../DCLX/CMS/FirstSteps/utils';
import { InnerFirstSteps } from './InnerFirstSteps';

type P = {
  progression: ProgressionReturn;
  channelId: number;
};
/** Wrapper for first steps used to avoid costly loads when not needed */
export const FirstSteps = (props: P) => {
  const [firstSteps] = useLoad<GetFirstStep[]>('firstSteps');
  const withMedia = useMemo(() => {
    return firstSteps
      ?.filter((s) => s.channelId === props.channelId)
      .map((s) => ({ info: getFirstStepMedia(s, props.progression), firstStep: s }))
      .filter((m) => m.info.show) as FullFirstStep[] | undefined;
  }, [props.progression, firstSteps, props.channelId]);
  if (!withMedia || !withMedia.length) return null;
  return <InnerFirstSteps withMedia={withMedia} refresh={props.progression.refresh} />;
};
