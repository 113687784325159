import React from 'react';
import { GetTest } from '../../../ApiHandler/dclxInterfaces';
import strings from '../../../Localization/Localizer';
import { client } from '../../../ApiHandler/client';
import Test from './Test';
import { EditDialog } from '@pp-labs/ui-components';

/** interface for DCLXTest props coming from parent components Test and InnerFirstSteps */
interface P {
  test: GetTest;
  open: boolean;
  closeDialog: Function;
  finish: Function;
}

/**
 * Displays an exam
 */

const DCLXTest = (props: P) => {
  const close = () => {
    props.closeDialog();
  };

  const finish = async (correctAnswers: number) => {
    try {
      await client.post(`exams/${props.test.examId}/stats`, {
        correctAnswers: correctAnswers,
      });
    } catch {}
    props.finish();
  };

  if (!props.open) return null;
  return (
    <EditDialog
      title={props.test.examType === 'post' ? strings.postTest : strings.preTest}
      close={close}
    >
      <Test testType={props.test.examType} questions={props.test.questions} finish={finish} />
    </EditDialog>
  );
};

export default DCLXTest;
