import { LayoutItem } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';
import { Agenda, MultiLanguage, Session, SessionType, TimeSlot } from 'ApiHandler/dclxInterfaces';
import { useEffect, useState } from 'react';
import { gutterStyle } from 'utils/gutter';
import { byWeekAndPosition } from 'utils/sort';
import FeaturedEvent, { getFeaturedEvent } from './FeaturedEvent';
import image from '../../../media/eventsBG.jpg';
import { Text } from '@pp-labs/ui-components';
import styles from 'features/Sessions/styles';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';
import { getLocalizedValue } from '../../../Localization/Localizer';
import { useEventSettings } from 'utils/hooks';

export enum BannerType {
  off = 'off',
  static = 'static',
  dynamic = 'dynamic',
}

export interface BannerConfiguration {
  state: BannerType;
  image?: MediaFile;
  imageId?: number;
  channelId?: number;
  title?: string | MultiLanguage<string>[];
}

/** interface for Banner props coming from parent component DCLXBanner, Event and EventCountDown  */
interface P {
  bannerConfiguration?: BannerConfiguration;
  timeslots: TimeSlot[];
  sessions?: Session[];
  agendas?: Agenda[];
  interactable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  ...styles(theme),

  whiteText: {
    color: 'white !important',
  },
  featuredEventWrapper: {
    paddingTop: 96,
    paddingBottom: 96,
    background: '#0000008c',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 78,
      paddingBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: 38,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
    },
  },
  staticWrapper: {
    padding: 48,
    position: 'absolute',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 22,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      wordBreak: 'break-all',
    },
  },
}));

/** Featured Event Banner Wrapper */
export const Banner = (props: P) => {
  const cls = useStyles();
  const eventSetting = useEventSettings();
  const [trainings, setTrainings] = useState<Session[] | undefined>(undefined);
  useEffect(() => {
    setTrainings(
      props.sessions
        ?.filter((s: Session) => s.sessionType === SessionType.TRAINING)
        .sort(byWeekAndPosition)
    );
  }, [props.sessions]);

  if (!props.bannerConfiguration || props.bannerConfiguration.state === 'off') return <></>;
  return (
    <LayoutItem>
      <div
        style={
          props.bannerConfiguration.state === 'dynamic'
            ? {
                width: '100%',
                minHeight: '250px',
                backgroundImage: props.bannerConfiguration.image?.url
                  ? `url(${props.bannerConfiguration.image.url})`
                  : image,
                backgroundColor: 'black',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }
            : {
                width: '100%',
              }
        }
      >
        {props.bannerConfiguration.state === 'static' && (
          <div style={{ position: 'relative' }}>
            <div className={cls.staticWrapper}>
              <Text
                variant={'display1'}
                weight={'bold'}
                className={cls.whiteText}
                spaceStackEnd={'s'}
              >
                {props.bannerConfiguration.title &&
                typeof props.bannerConfiguration.title === 'string'
                  ? getLocalizedValue((props.bannerConfiguration.title as string) || '')
                  : getLocalizedValue(JSON.stringify(props.bannerConfiguration.title))}
              </Text>
            </div>
            <img
              src={props.bannerConfiguration.image?.url || image}
              alt="Banner"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        )}
        {props.bannerConfiguration.state === 'dynamic' && (
          <div className={cls.featuredEventWrapper}>
            <FeaturedEvent
              event={getFeaturedEvent(
                Date.now() / 1000,
                props.sessions,
                props.timeslots,
                trainings,
                props.agendas
              )}
              eventSettings={eventSetting!}
              timeSlots={props.timeslots}
              now={Date.now() / 1000}
              interactable={props.interactable}
            />
          </div>
        )}
      </div>
    </LayoutItem>
  );
};
