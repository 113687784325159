import React, { useMemo } from 'react';
import { AllAward, UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { getTime } from '../../../utils/convert';
import { makeStyles } from '@material-ui/core';
import strings from '../../../Localization/Localizer';
import { Text } from '@pp-labs/ui-components';
import { useLoad } from '../../../utils/hooks';
import { Role } from '../../../utils/RoleDefinitions';
import DCLXUserTile from '../../../DCLX/FE/shared/DCLXUserTile';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',

    marginBottom: 12,
    // paddingBottom: 32,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: 360,
      flexWrap: 'wrap',
    },
  },
  row: {
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingRight: 32,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  headerRow: {
    height: 'auto',
    marginBottom: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  hideOnLowWidth: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cell: {
    paddingRight: 28,
  },
}));

/**
 * Leader board/top 10
 */

const w1 = '15%';
const w2 = '45%';
const w3 = '20%';
const w4 = '20%';

/** Top 10 in the channel */
export const Top10 = () => {
  const [top10] = useLoad<AllAward[]>('awards/top');
  const [allUsers] = useLoad<UserPicture[]>(`users/role/${Role.VISITOR}`);
  const cls = useStyles();

  const matched = useMemo(() => {
    if (!top10 || !allUsers) return [];
    return top10
      .map((t) => ({
        awards: t,
        user: allUsers.find((u) => u.username === t.username),
      }))
      .filter((t) => t.user);
  }, [allUsers, top10]);
  if (!matched) return null;
  if (matched.length === 0) {
    return (
      <div>
        <Text as="h3" variant="order4" weight={'bold'}>
          No Users yet!
        </Text>
      </div>
    );
  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex' }}>
        <div className={clsx(cls.row, cls.headerRow)}>
          <div style={{ width: w1 }} />
          <div style={{ width: w2 }} />
          <div style={{ width: w3 }}>
            <Text as="h3" variant="copy2" weight={'bold'}>
              {strings.score}
            </Text>
          </div>
          <div style={{ width: w4 }}>
            <Text as="h3" variant="copy2" weight={'bold'}>
              {strings.responseTime}
            </Text>
          </div>
        </div>
      </div>
      <div className={cls.table}>
        {matched.map((match, index) => {
          return (
            <div key={`leaderboard-${index}`} className={cls.row}>
              <div className={cls.cell} style={{ width: w1, textAlign: 'center' }}>
                <Text as="h3" variant="order1" weight={'bold'}>
                  {index + 1}
                </Text>
              </div>
              <div className={cls.cell} style={{ width: w2 }}>
                <div style={{ margin: '10px 0' }}>
                  <DCLXUserTile user={match.user!} down showUserInfo noHover />
                </div>
              </div>
              <div className={cls.cell} style={{ width: w3 }}>
                <Text as="h3" variant="order4">
                  {match.awards.score}
                </Text>
              </div>
              <div className={cls.cell} style={{ width: w4 }}>
                <Text as="h3" variant="order4">
                  {getTime(match.awards.usedTime)}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
