import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import Snackbar from './utils/Snackbar';
import { Container, CssBaseline } from '@material-ui/core';
import { LanguageSwitcher } from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@pp-labs/ui-components';
import { env } from './config';
import { StateProvider } from './features/StateProvider/StateProvider';
import { LoginProvider } from './features/StateProvider/LoginProvider';

/** Collection of all the different providers required */
export const Providers = () => {
  /** Loads the usercentrics plugin */
  useEffect(() => {
    if (env() !== 'staging') return;
    const script = document.createElement('script');
    script.setAttribute('data-settings-id', 'xXbtB8WT0');
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    script.id = 'usercentrics-cmp';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      try {
        document.removeChild(script);
      } catch (e) {}
    };
  }, []);
  return (
    <QueryClientProvider client={new QueryClient()}>
      <Provider store={store}>
        <Router>
          <ThemeProvider>
            <Snackbar>
              <CssBaseline />
              <Container disableGutters={true} maxWidth={false}>
                <StateProvider>
                  <LoginProvider>
                    <LanguageSwitcher />
                  </LoginProvider>
                </StateProvider>
              </Container>
            </Snackbar>
          </ThemeProvider>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};
