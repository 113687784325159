import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import {
  LabelButton,
  makeThemedStyles,
  Text,
  useIsTheme,
  useThemedStyles,
} from '@pp-labs/ui-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings from '../../Localization/Localizer';
import {
  CancelIconWrapper,
  MailIconWrapper,
  UserIconWrapper,
  TextArea,
} from '@pp-labs/ui-components';
import { client } from '../../ApiHandler/client';
import { useNotify, useUser } from 'utils/hooks';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

const styles = makeThemedStyles((theme) => {
  return {
    supportContainer: {
      position: 'fixed',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
        right: '10%',
      },
      [theme.breakpoints.down('xs')]: {
        left: 0,
        width: '100%',
      },
      zIndex: 100,
      bottom: ({ isTheme }) => (isTheme.audi ? '0px' : '0px'),
      borderLeft: '1px black solid',
      borderRight: '1px black solid',
    },
    supportHeadline: {
      height: '60px',
      color: 'white',
      lineHeight: '60px',
      cursor: 'pointer',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: ({ colorScheme }) => colorScheme.primary,
    },
    white: {
      color: 'white',
    },
    cancelButton: {
      position: 'absolute',
      right: 0,
      top: '5px',
    },
    supportBody: {
      backgroundColor: ({ isTheme }) =>
        isTheme.audi ? '#EEEEEE' : isTheme.neutralLight ? '#E3E3E3' : '#000000',
      padding: '20px',
    },
    headlineFullPanel: {
      bottom: '432px',
    },
    formContainer: {
      position: 'relative',
      padding: ({ isTheme }) => (isTheme.audi ? '0 20px 20px 20px' : '20px 60px 20px 20px'),
      backgroundColor: ({ isTheme }) =>
        isTheme.audi ? '#f2f2f2' : isTheme.neutralLight ? '#E3E3E3' : '#000000',
    },
    inlineButton: {
      position: 'absolute',
      right: 0,
      bottom: '10px',
    },
    lotSupportContainer: {
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
        right: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        left: 0,
        width: '100%',
      },
      zIndex: 100,
      bottom: '32px',
      borderLeft: '1px black solid',
      borderRight: '1px black solid',
      borderBottom: '1px black solid',
    },
  };
});

const validation = Yup.object({
  question: Yup.string().required(strings.required),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

const initialValues: F = {
  question: '',
};

/** interface for ContactSupport props coming from parent components LotHelpDialogue, FAQ */
interface P {
  lotSupport?: boolean;
}

/**
 * Small box to contact the support when logged in
 */
const ContactSupport = (props: P) => {
  const cls = useThemedStyles(styles);
  const notify = useNotify();
  const user = useUser();
  const theme = useIsTheme();
  const [extend, setExtend] = useState<boolean>(false);
  const handleExtend = () => {
    setExtend(true);
  };

  const handleClose = (e: Event) => {
    e.stopPropagation();
    setExtend(false);
  };

  const submitToServer = async (values: F) => {
    const channels = user?.['custom:channels']?.split(',').map((e) => parseInt(e));
    const data: any = {
      // sessionId: 1,
      // username: this.props.user['cognito:username'],
      channels: channels,
      question: values.question,
    };
    try {
      await client.post('support/requests', data);
      // eslint-disable-next-line max-len
      notify(strings.thanksForContactingSupport, 'success');
    } catch {
      notify(strings.somethingWentWrong, 'error');
    }
  };

  const closeButton = (
    // @ts-ignore
    <IconButton onClick={handleClose} className={`${cls.cancelButton} ${cls.white}`}>
      <CancelIconWrapper />
    </IconButton>
  );

  const headline = (
    <div className={cls.supportHeadline} onClick={handleExtend}>
      <IconButton className={cls.white}>
        {theme.audi ? <ChatBubbleOutlineIcon /> : <UserIconWrapper support={true} />}
      </IconButton>
      <Text as="span" variant="copy1" className={cls.white}>
        {strings.contactSupportHeadline}
      </Text>
      {extend && closeButton}
    </div>
  );

  if (!extend) {
    return (
      <div className={props.lotSupport ? cls.lotSupportContainer : cls.supportContainer}>
        {headline}
      </div>
    );
  }
  return (
    <>
      <div className={props.lotSupport ? cls.lotSupportContainer : cls.supportContainer}>
        <div>{headline}</div>
        <div className={cls.supportBody}>
          <div>
            <Text as="p" variant="copy1">
              {strings.supportText}
            </Text>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          validationSchema={validation}
          initialValues={initialValues}
          validateOnBlur={false}
          onSubmit={(values: F, { resetForm }) => {
            submitToServer(values);
            resetForm();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={cls.formContainer}>
                <TextArea
                  name={'question'}
                  label={strings.typeMailHere}
                  rows={4}
                  error={touched.question ? errors.question : ''}
                />
                {theme.audi ? (
                  <LabelButton type={'submit'} variant="primary" stretch={true}>
                    {strings.send}
                  </LabelButton>
                ) : (
                  <IconButton className={cls.inlineButton} type="submit">
                    <MailIconWrapper />
                  </IconButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ContactSupport;
