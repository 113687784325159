import { Form, Formik } from 'formik';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import strings from '../../Localization/Localizer';
import { Checkbox, TextField } from '@pp-labs/ui-components';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { usernameValidator } from '../../utils/validator';
import { LegalDocument } from '../../features/Footer/Overlays/LegalDocument';
import { ConfigModuleIdentifiers, LegalDocumentType } from 'ApiHandler/dclxInterfaces';
import { useEventSettings, useNamedConfigModule, useNotify } from '../../utils/hooks';
import { getMfaType, getValidationType } from '../../utils/convert';
import { CodeMethod, getFormErrorMessage } from '../utils';
import { CustomError, FormSoftButton } from '../../SoftButton';
import { MfaBadges } from '../MfaBadges';
import { VimeoVideo } from './VimeoVideo';

/** interface for RequestMail props coming from parent component DirectRegisterForm */
interface P {
  username?: string;
  finish: (username: string, codeMethod: CodeMethod) => void;
  mfa: boolean;
}

const useStyles = makeStyles(() => ({
  textField: {
    maxWidth: 340,
  },
}));

const phase1V = () =>
  Yup.object({
    username: usernameValidator(),
    agb: Yup.boolean().required(strings.required).oneOf([true], strings.required),
  });

// This is sadly needed outside the component since setState is too slow and creates a race condition
let sendCode: CodeMethod = null;

/** Request a verification code via SMS or Mail during registration */
export const RequestMail = (props: P) => {
  const cls = useStyles();
  const notify = useNotify();
  const event = useEventSettings()!;
  const mfa = getMfaType(event);

  const configModule = useNamedConfigModule();
  const registerVideo = configModule(ConfigModuleIdentifiers.registerVideo);

  const validationTypes = getValidationType(event);
  const eventSetting = useEventSettings();
  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  return (
    <Formik
      initialValues={{
        username: props.username || '',
        agb: false,
      }}
      validationSchema={phase1V()}
      onSubmit={(values) => {
        if (values.agb) {
          props.finish(values.username, sendCode);
        }
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Text as="h2" variant="order3" weight={'bold'}>
            {strings.registration}
          </Text>
          <div style={{ padding: '32px 0' }} />
          {registerVideo && (
            <div style={{ padding: '0 0 64px' }}>
              <Text as="p" variant="copy1">
                {strings.stepByStepGuide}
              </Text>
              <div style={{ padding: '10px 0' }} />
              <VimeoVideo />
            </div>
          )}
          <div>
            <Text as="p" variant="order4">
              {strings.getRegistrationCode}
            </Text>
            <div style={{ padding: '10px 0' }} />
            <Text as="p" variant="copy1">
              {strings.enterUsername}
            </Text>
            <div style={{ padding: '10px 0' }} />
            <div className={cls.textField}>
              <TextField
                disabled={!!props.username}
                name={'username'}
                label={strings.username}
                error={touched.username ? errors.username : ''}
                autoComplete="username"
              />
            </div>
            {eventSetting?.poolSecurity === 'mfa' && (
              <LayoutItem spaceStackStart="xxxl" spaceStackEnd="m">
                <Text>{strings.downloadedMfa}</Text>
              </LayoutItem>
            )}
            {mfa === 'totp' && <MfaBadges noDownload />}
            <div style={{ padding: '24px 0 28px' }}>
              <Checkbox
                currentValue={values.agb}
                name={'agb'}
                error={touched.agb ? errors.agb : ''}
                value={'true'}
                handle={setFieldValue}
                label=""
              >
                <LegalDocument type={LegalDocumentType.conditions} inline />
              </Checkbox>
            </div>
            {validationTypes.includes('email') && (
              <div>
                <FormSoftButton
                  variant="primary"
                  relevantFields={['username', 'agb']}
                  additionalSuccessCallback={() => (sendCode = 'mail')}
                  errorCallback={handleError}
                >
                  {strings.sendMail}
                </FormSoftButton>
              </div>
            )}
            {validationTypes.includes('sms') && (
              <div>
                <FormSoftButton
                  variant="primary"
                  relevantFields={['username', 'agb']}
                  additionalSuccessCallback={() => (sendCode = 'sms')}
                  errorCallback={handleError}
                >
                  {strings.sendSms}
                </FormSoftButton>
              </div>
            )}
            <div style={{ paddingTop: 16 }}>
              <FormSoftButton
                variant="text"
                relevantFields={['username', 'agb']}
                errorCallback={handleError}
              >
                {strings.alreadyHaveCode}
              </FormSoftButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
