import { PlatformEvent, Security } from '../ApiHandler/dclxInterfaces';
import imageTest from '../media/ABS_Herobild.jpg';
import logo from '../media/Audi_Rings_bl-RGB.svg';

import { adminRoute } from '../config';

const adminMfa = process.env.REACT_APP_ADMIN_SECURITY as Security;

/** "event" for the admin event */
export const adminSettings: PlatformEvent = {
  poolName: 'admin',
  tenantId: 0,
  hidden: true,
  title: 'Event Configuration',
  identifier: adminRoute,
  description: 'Configurate the other events',
  supportEmail: '',
  eventStartTiming: 1622619611,
  eventEndTiming: new Date(2100, 1, 1).getTime() / 1000,
  favicon: 'https://www.audi.com/etc/designs/ciportal/favicon.ico',
  logo: imageTest,
  navLogo: logo,
  heroImage: imageTest,
  profileImage: imageTest,
  poolId: process.env.REACT_APP_ADMIN_POOL_ID || '',
  poolClientId: process.env.REACT_APP_ADMIN_POOL_CLIENT_ID || '',
  poolSecurity: adminMfa,
  configModules: [],
  languages: JSON.stringify({
    languages: [{ code: 'en', name: 'English' }],
    preferedLanguage: 'English',
  }),
  theme: JSON.stringify(['audi', 'light', 'dark']),
  registerSettings: {
    verificationTypes: ['email'],
  },
};
