import { useDispatch, useSelector } from 'react-redux';
import { triggerNotification } from './NotificationSlice';
import { Store } from '../store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApiText,
  ConfigModuleIdentifiers,
  CustomFields,
  CustomTheme,
  Language,
  LanguagesSelection,
  PlatformEvent,
  UserPicture,
} from '../ApiHandler/dclxInterfaces';
import { client } from '../ApiHandler/client';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { allLangs, defaultLanguage, getLocalizedValue } from 'Localization/Localizer';
import { ButtonColors, useSetTheme } from '@pp-labs/ui-components';
import { getCompleteTexts, TextKey } from '../features/Text/texts';
import { useProgression } from '../progression';
import { sleep } from './convert';
import { doesRoleMatch, Role } from './RoleDefinitions';
import { baseUrl } from 'config';

export type MessageType = 'success' | 'error';

export const useNotify = () => {
  const dispatch = useDispatch();
  return (message: string, type: MessageType, persist?: boolean) =>
    dispatch(triggerNotification([message, type, persist]));
};
export type NotifyType = ReturnType<typeof useNotify>;

export const useUser = () => useSelector((state: Store) => state.app.user);
export const useFullscreen = () => useSelector((state: Store) => state.app.fullscreen);
export const useEventSettings = () => useSelector((state: Store) => state.app.eventSetting);
export const useCMSChannel = () => {
  const history = useHistory();
  const params = useParams<{ event: string; tab: string }>();
  return useSelector((state: Store) => {
    if (state.app.cmsChannel) return state.app.cmsChannel;
    history.push(`/${params.event}/cms/`);
    return null;
  });
};

export const useCurrentTheme = (): CustomTheme => {
  const eventSettings = useEventSettings();
  try {
    const parsed: { type: CustomTheme } = JSON.parse(eventSettings?.theme!);
    return parsed.type;
  } catch {
    return 'audi';
  }
};

export const useSetEventTheme = () => {
  const setTheme = useSetTheme();
  const eventSettings = useEventSettings();
  const currentTheme = useCurrentTheme();
  const buttonColors: ButtonColors = eventSettings?.theme
    ? JSON.parse(eventSettings.theme)
    : undefined;
  return () => {
    const light = currentTheme === 'light';
    const dark = currentTheme === 'dark';
    if (light) {
      setTheme({
        name: 'neutral',
        variant: 'light',
        buttonColors: buttonColors,
      });
    } else if (dark) {
      setTheme({
        name: 'neutral',
        variant: 'dark',
        buttonColors: buttonColors,
      });
    } else {
      setTheme({
        name: 'audi',
      });
    }
  };
};

export const useEventLanguages = (): Language[] =>
  useSelector((state: Store) => {
    const langJson = state.app.eventSetting?.languages;
    if (langJson) {
      try {
        return JSON.parse(langJson).languages;
      } catch {}
    }
    return [];
  });

export const useEventLanguageSelection = (): LanguagesSelection =>
  useSelector((state: Store) => {
    const langJson = state.app.eventSetting?.languages;
    return langJson
      ? (JSON.parse(langJson) as LanguagesSelection)
      : { languages: [...allLangs], preferedLanguage: defaultLanguage.code };
  });

export const useLanguage = () => useSelector((state: Store) => state.app.language);

export const useNamedConfigModule = () => {
  const eventSettings = useEventSettings();
  return (id: ConfigModuleIdentifiers) => isUsingConfigModule(eventSettings, id);
};

export const isUsingConfigModule = (
  eventSettings: PlatformEvent | null,
  id: ConfigModuleIdentifiers
) => {
  if (eventSettings?.configModules) {
    return eventSettings?.configModules.some((e) => e.moduleId === id);
  }
  return false;
};

/** Navigates somewhere within this event */
export const useNavigateEvent = () => {
  const history = useHistory();
  const event = useEventSettings();
  return (path: string) => {
    if (!event) return;
    const t = path.startsWith('/') ? path : `/${path}`;
    history.push('/' + event.identifier + t);
  };
};

/** Navigates somewhere within this event by opening a new tab */
export const useNavigateEventNewTab = () => {
  const event = useEventSettings();
  return (path: string) => {
    if (!event) return;
    const t = path.startsWith('/') ? path : `/${path}`;
    window.open('/' + event.identifier + t);
  };
};

/** Navigates somewhere within this event with page id */
export const useNavigateEventIdNewTab = () => {
  const event = useEventSettings();
  return (path: string) => {
    if (!event) return;
    return window.open(`${baseUrl}/${event.identifier}/${path}`);
  };
};

/**
 * Get Current Page Channel Id from URL
 */
export const useCurrentPageChannelId = () => {
  const params = useParams<{ channelId: string }>();
  return useMemo(() => Number(params.channelId), [params.channelId]);
};

/**
 * Get channels through user progression
 */
export const useProgressionChannels = () => {
  const progression = useProgression();
  return progression === 'loading' ? [] : progression.info.channels;
};

export const useRefreshProgression = () => {
  const progression = useProgression();
  return () => {
    if (progression !== 'loading') progression.refresh();
  };
};

/**
 * Get topics through user progression
 */
export const useProgressionTopics = () => {
  const progression = useProgression();
  return progression === 'loading' ? [] : progression.info.topics;
};

/**
 * Get non empty topics through user progression
 */
export const useProgressionNonEmptyTopics = () => {
  const progression = useProgression();
  if (progression === 'loading') return [];
  return progression.info.topics.filter((topic) =>
    progression.info.sessions.some(
      (session) => session.adapted && session.topicIds.includes(topic.topicId)
    )
  );
};

/** Returns the current time in the specified interval */
export const useRealTime = (refreshInterval?: number) => {
  const [clockDrift, setClockDrift] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const start = () => {
      const setTime = () => {
        const realTime = new Date(Date.now() + clockDrift!);
        setCurrentTime(realTime);
      };
      setTime();
      return setInterval(setTime, refreshInterval || 5000);
    };
    if (clockDrift !== null) {
      const i = start();
      return () => clearInterval(i);
    }
  }, [clockDrift, refreshInterval]);

  useEffect(() => {
    const setDrift = async () => {
      const cd = await getClockDrift();
      setClockDrift(cd);
    };
    setDrift();
  }, []);

  return currentTime.getTime();
};

/** Returns the difference in milli seconds between the server and local time */
export const getClockDrift = async (): Promise<number> => {
  const serverTime = await getReliableUnixTime();
  const localTime = new Date().getTime();
  return serverTime - localTime;
};

/** Returns the current unix Time in milli seconds from an API */
// export const getReliableUnixTime = async () => {
//   try {
//     const res = await client.get('support/timestamp');
//     if (res.status !== 200) return new Date().getTime();
//     return res.data * 1000;
//   } catch {
//     return new Date().getTime();
//   }
// };

/** Returns the current unix Time in milli seconds from local.
 *  can be easily switched to api by activating the function above. */
export const getReliableUnixTime = async () => new Date().getTime();

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export interface AllDevices {
  tabletLandscape: boolean;
  tabletPortrait: boolean;
  mobilePortrait: boolean;
}

/** Switcher for devices */
export const useIsDevice = (): AllDevices => {
  const theme = useTheme();
  return {
    tabletLandscape: useMediaQuery(theme.breakpoints.only('md')),
    tabletPortrait: useMediaQuery(theme.breakpoints.only('sm')),
    mobilePortrait: useMediaQuery(theme.breakpoints.down('xs')),
  };
};

/** Returns query params */
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

/** Use the event based texts */
export const useTexts = () => {
  const [texts, setTexts] = useState<ApiText[] | null>(null);
  useEffect(() => {
    const load = async () => {
      setTexts((await client.get('texts')).data);
    };
    load();
  }, []);
  const completeTexts = getCompleteTexts(texts || []);
  return {
    texts: completeTexts,
    getText: (key: TextKey) => {
      const text = completeTexts.find((t) => t.key === key)!;
      return text.isActive && text.text ? getLocalizedValue(text.text) : text.defaultText;
    },
    status: texts === null ? 'loading' : ('loaded' as const),
  };
};

/** Is this event running? Requires 'countdownTitle' to be set in the cms of the event.  */
export const useEventStarted = () => {
  const user = useUser();
  const event = useEventSettings();
  const currentTime = useRealTime(1000);
  const { getText } = useTexts();
  // consider event as always running for them.
  if (doesRoleMatch(user, [Role.MARKET, Role.MANAGER, Role.VIEWER])) return true;
  const now = Math.floor(currentTime / 1000);
  const text = getText('countdownTitle');
  if (!event) return false;
  return !(event?.eventStartTiming >= now && text);
};

type UseLoadReturn<T> = [data: T | null, refresh: () => void];
/** Hook to load api data from a single endpoint like we do very often */
export const useLoad = <T>(
  endpoint: string,
  exception?: {
    defaultData?: T;
    handler?: () => void;
  }
): UseLoadReturn<T> => {
  const [data, setData] = useState<null | T>(null);
  const load = useCallback(async () => {
    try {
      const request = await client.get<T>(endpoint);
      setData(request.data);
    } catch (e) {
      if (exception?.defaultData) {
        setData(exception.defaultData);
      } else {
        exception?.handler?.();
      }
    }
  }, [setData, exception, endpoint]);
  useEffect(() => {
    load();
  }, [load]);

  return [data, load];
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = <RefElement extends Element>(
  ref: RefObject<RefElement> | null,
  handler: () => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref.current.contains(event.target)) {
        handler();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};

/** Forces a pause in the default render cycle.
 * Usage: const pause = useForceNoRender(deps)
 * if (pause) return null;
 */
export const useForceNoRender = (deps: unknown[]) => {
  const [recentSwitch, setRecentSwitch] = useState<boolean>(true);
  useEffect(() => {
    const registerSwitch = async () => {
      setRecentSwitch(true);
      await sleep(200);
      setRecentSwitch(false);
    };
    registerSwitch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return recentSwitch;
};

export const useCustomFields = (user?: UserPicture | null) =>
  useMemo<CustomFields>(
    () =>
      user?.customFields
        ? JSON.parse(user.customFields)
        : { 'custom:channels': undefined, 'custom:jobFunction': undefined },
    [user]
  );
