import { Form, Formik } from 'formik';
import { Text } from '@pp-labs/ui-components';
import strings from '../../Localization/Localizer';
import { TextField } from '@pp-labs/ui-components';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import {
  confirmPasswordValidator,
  newPasswordValidator,
  sixDigitCode,
} from '../../utils/validator';
import { CustomError, FormSoftButton } from '../../SoftButton';
import { useNotify } from '../../utils/hooks';
import { getFormErrorMessage } from '../utils';
import { PasswordVerifier } from '../PasswordVerifier';
import AudiSpacer from 'utils/AudiSpacer';

/** interface for NewPassword props coming from parent component directRegisterForm */
interface P {
  finish: (code: string, newPassword: string) => void;
}

const useStyles = makeStyles({
  textField: {
    maxWidth: 340,
  },
});

const phase2V = () =>
  Yup.object({
    code: sixDigitCode(),
    newPassword: newPasswordValidator(),
    confirmPassword: confirmPasswordValidator(),
  });
/**
 * Phase 2 of Register Process
 * Entering code from email and setting new password
 */
export const NewPassword = (props: P) => {
  const cls = useStyles();
  const notify = useNotify();

  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  return (
    <Formik
      initialValues={{
        code: '',
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={phase2V()}
      onSubmit={(values) => {
        props.finish(values.code, values.newPassword);
      }}
    >
      {({ values, errors, touched }) => {
        return (
          <Form>
            <Text as="h2" variant="order3" weight={'bold'}>
              {strings.registration}
            </Text>
            <div style={{ padding: '32px 0' }} />
            <Text as="h3" variant="order4">
              {strings.createPasswordHeadline}
            </Text>
            <div style={{ padding: '10px 0' }} />
            <div>
              <Text variant="copy1" as="p">
                {strings.registerCodeText}
              </Text>
            </div>
            <AudiSpacer neutralSpaceStackEnd={'m'} />
            <div className={cls.textField}>
              <TextField
                name={'code'}
                label={'Code'}
                error={touched.code ? errors.code : ''}
                autoComplete="one-time-code"
              />
            </div>
            <PasswordVerifier password={values.newPassword} />
            <div className={cls.textField}>
              <TextField
                type="password"
                name={'newPassword'}
                label={strings.newPassword}
                error={touched.newPassword ? errors.newPassword : ''}
                autoComplete="new-password"
              />
            </div>
            <AudiSpacer neutralSpaceStackEnd={'m'} />
            <div className={cls.textField}>
              <TextField
                type="password"
                name={'confirmPassword'}
                label={strings.confirmPassword}
                error={touched.confirmPassword ? errors.confirmPassword : ''}
                autoComplete="new-password"
              />
            </div>
            <div style={{ paddingBottom: 28 }} />

            <FormSoftButton
              variant="primary"
              relevantFields={['code', 'newPassword', 'confirmPassword']}
              errorCallback={handleError}
            >
              {strings.next}
            </FormSoftButton>
          </Form>
        );
      }}
    </Formik>
  );
};
