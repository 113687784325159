import { useProgression } from '../../../progression';
import { useEffect, useState } from 'react';
import AudiTabs from '../../../utils/AudiTabs/AudiTabs';
import { ScoreBoard } from './ScoreBoard';
import strings from '../../../Localization/Localizer';
import { Top10 } from './Top10';
import { Badges } from './Badges';
import { useIsTheme } from '@pp-labs/ui-components';

/** Tab Panel for the different Gamification modules */
export const Gamification = () => {
  const progression = useProgression();
  const theme = useIsTheme();
  const [badgesFetched, setBadgesFetched] = useState<boolean>(false);
  useEffect(() => {
    if (progression !== 'loading' && !badgesFetched) {
      setBadgesFetched(true);
      progression.refreshBadges();
    }
  }, [progression, badgesFetched]);
  if (progression === 'loading' || !progression.info.awards) return null;
  return (
    <AudiTabs
      tabContents={[<ScoreBoard awards={progression.info.awards} />, <Badges />, <Top10 />]}
      tabLabels={[strings.score, strings.myBadges, strings.topTenRanking]}
      gamificationDark={theme.neutralDark}
    />
  );
};
