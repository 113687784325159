import React, { useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AppBar, Grid, IconButton, Link, MenuItem, Toolbar } from '@material-ui/core';
import {
  makeThemedStyles,
  NavigationWrapper,
  Text,
  useIsTheme,
  useThemedStyles,
} from '@pp-labs/ui-components';
import ButtonAppBarCollapse from './AppBarMobile/ButtonAppBarCollapse';
import { Config, getConfig } from './getConfig';
import './NavBar.scss';
import {
  useEventSettings,
  useFullscreen,
  useIsTablet,
  useNamedConfigModule,
  useProgressionChannels,
  useUser,
} from '../../utils/hooks';
import { useSurveyTemplates } from '@pp-labs/survey';
import clsx from 'clsx';
import { RightSideNav } from './RightSideNav';
import { doesRoleMatch, Role } from 'utils/RoleDefinitions';

const disabled = ['watch', 'profile', 'dashboard'];

const styles = makeThemedStyles((theme) => {
  return {
    toolBar: {
      [theme.breakpoints.up('lg')]: {
        minHeight: '80px',
      },
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '16px',
      paddingRight: '16px',
      minHeight: '80px',

      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '-webkit-scrollbar': 'none',
      '-webkit-overflow-scrolling': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        minHeight: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        minHeight: '71px',
      },
    },
    buttonBar: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      paddingLeft: '32px',
      background: 'transparent',
      display: 'flex',
      flex: 1,
      alignItems: 'inherit',
    },
    navLogo: {
      background: 'transparent !important',
      height: '56px',
    },
  };
});

/**
 * The navbar, containing the desktop and mobile NavBar
 */

export const NavBar = () => {
  const user = useUser();
  const eventSetting = useEventSettings();
  const fullscreen = useFullscreen();
  const location = useLocation();
  const channels = useProgressionChannels();
  const useModule = useNamedConfigModule();
  const surveys = useSurveyTemplates(true, true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const isTablet = useIsTablet();
  const event = useMemo(() => {
    return location.pathname.split('/')[1];
  }, [location]);
  const config: Config[] = useMemo(
    () => getConfig(user, channels, event, useModule, !!surveys.length),
    [user, event, useModule, surveys, channels]
  );
  const hideNavbar: boolean = useMemo(
    () => fullscreen || location.pathname.includes('login') || location.pathname === '/',
    [fullscreen, location]
  );

  const showSeparationLine: boolean = useMemo(() => {
    return !disabled.some((e) => location.pathname.includes(e));
  }, [location]);

  const cls = useThemedStyles(styles);
  const theme = useIsTheme();
  if (hideNavbar) return null;
  return (
    <Grid item xs={12}>
      <AppBar
        color="inherit"
        elevation={0}
        className={showSeparationLine && !theme.neutral && !mobileMenuOpen ? 'navbarSep' : ''}
        position={doesRoleMatch(user, Role.VISITOR) ? 'fixed' : 'absolute'}
        style={{ border: isTablet && doesRoleMatch(user, Role.TRAINER) ? 'none' : undefined }}
      >
        <NavigationWrapper>
          <Toolbar className={cls.toolBar}>
            {config.length > 0 && (
              <ButtonAppBarCollapse
                event={event}
                logo={eventSetting!.navLogo}
                setMobileMenuOpen={setMobileMenuOpen}
              >
                <NavBarItems config={config} desktop={false} />
              </ButtonAppBarCollapse>
            )}
            <div id="navBarButtons" className={cls.buttonBar}>
              <Link
                component={NavLink}
                activeClassName=""
                underline="none"
                to={`/${event}/home`}
                className="navLink navLink--home"
              >
                <IconButton
                  color="inherit"
                  aria-label="start"
                  className={cls.navLogo}
                  disableRipple
                >
                  <img src={eventSetting?.navLogo} alt="audi" color="green" />
                </IconButton>
              </Link>
              <NavBarItems config={config} desktop={true} />
            </div>
            <RightSideNav user={user} mobileMenuOpen={mobileMenuOpen} />
          </Toolbar>
        </NavigationWrapper>
      </AppBar>
      <Toolbar className={cls.toolBar} />
    </Grid>
  );
};

interface NavBarItemsProps {
  config: Config[];
  desktop: boolean;
}

const NavBarItems = (props: NavBarItemsProps) => {
  const theme = useIsTheme();
  const tablet = useIsTablet();
  return (
    <div>
      {props.config
        .filter((c) => props.desktop || !c.desktopOnly)
        .map((c) => {
          const text = (
            <Text
              as="span"
              variant="copy1"
              className={clsx(
                theme.neutralDark ? 'darkSpan' : theme.neutralLight ? 'lightSpan' : 'audiSpan'
              )}
            >
              {c.text}
            </Text>
          );
          return (
            <Link
              component={NavLink}
              activeClassName="active"
              underline="none"
              to={c.route}
              className={!tablet ? 'navLink' : undefined}
              key={c.route}
            >
              {!tablet ? text : <MenuItem>{text}</MenuItem>}
            </Link>
          );
        })}
    </div>
  );
};
