import { IconButton, Link } from '@material-ui/core';
import { makeThemedStyles, MyScheduleIcon, useThemedStyles } from '@pp-labs/ui-components';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Text } from '@audi/audi-ui-react';
import { useNavigateEvent, useNotify, useOutsideAlerter } from 'utils/hooks';
import { ProgressionReturn, useProgression } from '../../progression';
import { blackStyle } from '../Sessions/Watch/blackStyle';
import strings, { getLocalizedValue } from '../../Localization/Localizer';
import {
  BookedLot,
  FullSchedule,
  getScheduleMedia,
  LiveSession,
} from '../../DCLX/CMS/FirstSteps/utils';
import { format } from 'date-fns';
import { standardFormat } from '../../config';
import CalendarExport from '../../utils/CalendarExport';
import clsx from 'clsx';
import { RemoveIcon, DownloadIcon } from '../../icons';
import AudiSpacer from 'utils/AudiSpacer';
import _ from 'lodash';
import { EditorParser } from 'features/Editor/EditorParser';
import { gutterStyle } from 'utils/gutter';

const style = makeThemedStyles((theme) => {
  return {
    ...blackStyle(theme),
    ...gutterStyle(theme),
    iconWrap: {
      position: 'relative',
    },
    scheduleLength: {
      position: 'absolute',
      top: ({ isTheme }) => (isTheme.audi ? -16 : -10),
      right: ({ isTheme }) => (isTheme.audi ? 0 : 4),
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: ({ isTheme }) => (isTheme.neutralDark ? 'white' : 'black'),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popOver: {
      position: 'absolute',
      top: 80,
      right: 0,
      width: '380px',
      border: '1px solid black',
      borderWidth: '1px 1px 1px 1px',
      [theme.breakpoints.down('xs')]: {
        top: 71,
        width: '320px',
      },
      maxHeight: '80vh',
      backgroundColor: 'white',
      zIndex: 10000,
      padding: '24px 0px',
      overflow: 'auto',
    },
    scheduleItem: {
      padding: '12px 24px',
      maxWidth: '30vw',
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
      justifyContent: 'right',
      '&:hover': {
        background: '#F2F2F2',
      },
    },
    title: {
      flexGrow: 1,
    },
    btn: {
      width: 48,
      height: 48,
      flexShrink: 0,
    },
    running: {
      cursor: 'pointer',
    },
    tip: {
      width: '20px',
      height: '20px',
      borderTop: '1px solid black',
      borderLeft: '1px solid black',
      position: 'absolute',
      right: ({ isTheme }) => (isTheme.audi ? '14px' : '20px'),
      bottom: ({ isTheme }) => (isTheme.audi ? '-26px' : '-23px'),
      backgroundColor: 'white',
      transform: 'rotate(45deg)',
      zIndex: 150000,
      [theme.breakpoints.down('xs')]: {
        bottom: ({ isTheme }) => (isTheme.audi ? '-22px' : '-19px'),
      },
    },
    whiteText: {
      color: ({ isTheme }) => (isTheme.neutralDark ? 'black' : 'white'),
    },
  };
});
/** My Schedule panel */
export const MySchedule = () => {
  const cls = useThemedStyles(style);
  const progression = useProgression();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const close = useCallback(() => setOpen(false), []);
  useOutsideAlerter<HTMLDivElement>(ref, close);

  const withMedia = useMemo<FullSchedule[]>(() => {
    if (progression === 'loading') return [];
    const uniques = _.uniqBy(progression.info.mySchedule, 'mediaId');
    const media = uniques.map((s) => ({ info: getScheduleMedia(s, progression), schedule: s }));
    const fil = media.filter(
      (m) => m.info.show && !(m.info.type === 'unbookedLot')
    ) as FullSchedule[];
    return fil;
  }, [progression]);

  return (
    <div ref={ref}>
      <Link underline="none" onClick={() => setOpen(!open)} className={cls.iconWrap}>
        <IconButton>
          <MyScheduleIcon />
        </IconButton>
        {withMedia.length > 0 && (
          <div className={cls.scheduleLength}>
            <Text className={cls.whiteText} variant="copy3">
              {withMedia.length}
            </Text>
          </div>
        )}
      </Link>

      {open && progression !== 'loading' && (
        <>
          <div style={{ position: 'relative' }}>
            <div className={cls.tip} />
          </div>
          <div className={cls.popOver}>
            <div style={{ padding: '0 24px' }}>
              <Text as="h4" variant="copy1" weight="bold">
                {strings.mySchedule}
              </Text>
            </div>
            <AudiSpacer spaceStackEnd="s" neutralSpaceStackEnd="s" />
            <InnerSchedule withMedia={withMedia} progression={progression} close={close} />
          </div>
        </>
      )}
    </div>
  );
};

const getStart = (medium: FullSchedule): number =>
  medium.info.type === 'bookedLot' ? medium.info.slot.startAt : medium.info.media.startAt || 0;

const getEnd = (medium: FullSchedule): number =>
  medium.info.type === 'bookedLot' ? medium.info.slot.endAt : medium.info.media.endAt || 0;

/** My Schedule panel, when it actually needs to render */
export const InnerSchedule = ({
  withMedia,
  progression,
  close,
}: {
  withMedia: FullSchedule[];
  progression: ProgressionReturn;
  close: () => void;
}) => {
  const cls = useThemedStyles(style);
  const goTo = useNavigateEvent();
  const navigate = (path: string) => {
    close();
    goTo(path);
  };
  const notify = useNotify();
  const lives = useMemo(
    () =>
      withMedia
        .filter((s) => s.info.type === 'liveSession' || s.info.type === 'bookedLot')
        .sort((a, b) => getStart(a) - getStart(b)),
    [withMedia]
  );
  const demands = useMemo(
    () => withMedia.filter((s) => s.info.type === 'demandSession'),
    [withMedia]
  );

  if (!withMedia.length) {
    return (
      <div style={{ padding: 24 }}>
        <Text>{strings.myScheduleAddItems}</Text>
      </div>
    );
  }

  return (
    <div>
      <div>
        {lives.map((s) => {
          const info = s.info as LiveSession | BookedLot;
          return (
            <div key={s.schedule.myScheduleId} className={cls.scheduleItem}>
              <div
                className={clsx(cls.title, info.running && cls.running)}
                onClick={() => {
                  if (info.running) {
                    navigate(info.link);
                  } else {
                    notify(strings.eventNotRunning, 'error');
                  }
                }}
              >
                <Text>{getLocalizedValue(s.info.media.title)}</Text>
                <Text variant="copy3">
                  {format(getStart(s) * 1000, standardFormat)} - {format(getEnd(s) * 1000, 'HH:mm')}
                </Text>
                <div id="scheduleDesc" className={cls.maxTwoLines}>
                  <EditorParser inputString={getLocalizedValue(s.info.media.description)} />
                </div>
              </div>
              <div className={cls.btn}>
                <CalendarExport
                  title={s.info.media.title}
                  icon={DownloadIcon}
                  description={s.info.media.description}
                  start={new Date(getStart(s) * 1000)}
                  sessionTile={false}
                />
              </div>
              {s.info.type !== 'bookedLot' && (
                <div className={cls.btn}>
                  <IconButton onClick={() => progression.schedule.removeFromSchedule(s.info.media)}>
                    <RemoveIcon fontSize="large" />
                  </IconButton>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {lives.length > 0 && demands.length > 0 && (
        <div style={{ borderBottom: '1px solid black', margin: '8px 24px' }} />
      )}
      <div>
        {demands.map((s) => {
          return (
            <div key={s.schedule.myScheduleId} className={cls.scheduleItem}>
              <div
                className={clsx(cls.title, cls.running)}
                onClick={() => {
                  navigate(s.info.link);
                }}
              >
                <Text>{getLocalizedValue(s.info.media.title)}</Text>
                <div id="scheduleDesc" className={cls.maxTwoLines}>
                  <EditorParser inputString={getLocalizedValue(s.info.media.description)} />
                </div>
              </div>
              <div className={cls.btn}>
                <IconButton onClick={() => progression.schedule.removeFromSchedule(s.info.media)}>
                  <RemoveIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
