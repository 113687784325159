import { configureStore } from '@reduxjs/toolkit';
import AppReducer, { AppState } from './AppSlice';
import NotificationReducer, { NotificationState } from './utils/NotificationSlice';

export default configureStore({
  reducer: {
    notification: NotificationReducer,
    app: AppReducer,
  },
});

export interface IStore {
  notification: NotificationState;
  app: AppState;
}

export type Store = IStore;
