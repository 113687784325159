import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useEventSettings } from '../utils/hooks';
import { NavLink } from 'react-router-dom';
import { useIsTheme } from '@pp-labs/ui-components';

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    textAlign: 'center',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '40vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 32px)',
    },
    position: 'relative',
  },
  logoWrapper: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 10px 0px 0px',
    },
    [theme.breakpoints.only('md')]: {
      margin: '80px 80px 0px 0px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '80px 80px 0px 0px',
    },
  },
}));

const useNavStyles = makeStyles((theme) => ({
  audiRings: {
    height: 'auto',
    width: '150px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 'calc((100% - 240px) / 2)',
      margin: '20px',
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: '70px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '100px',
    },
  },
}));

/** NavLink in the Login image */
const Nav = () => {
  const cls = useNavStyles();
  const neutralTheme = useIsTheme().neutral;
  const eventSettings = useEventSettings();
  if (neutralTheme) return null;
  return (
    <Grid item xs={12}>
      <NavLink to="/">
        <img src={eventSettings?.logo} alt="audi" className={cls.audiRings} />
      </NavLink>
    </Grid>
  );
};

/** Login image */
export const Image = () => {
  const eventSettings = useEventSettings();
  const cls = useStyles();
  return (
    <Grid
      item
      xs={12}
      md={7}
      lg={6}
      className={cls.backgroundImage}
      style={{
        backgroundImage: `url(${eventSettings?.heroImage})`,
      }}
    >
      <div className={cls.logoWrapper}>
        <Nav />
      </div>
    </Grid>
  );
};
