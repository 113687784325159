import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import strings, { formatFixedTime } from '../../Localization/Localizer';
import { AgendaItem } from '../../ApiHandler/dclxInterfaces';
import { format } from 'date-fns';
import { Text } from '@pp-labs/ui-components';
import { dateOnly, timeOnly } from '../../config';
import { Collapsable } from '../../utils/Collapsable';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: 16,
  },
  row: {
    width: '100%',
    display: 'flex',
  },
  timeCol: {
    width: '15%',
  },
  itemCol: {
    width: '50%',
  },
  locationCol: {
    width: '35%',
  },
  commonCol: {
    padding: 16,
    paddingTop: 0,
  },
  innerCol: {
    width: '100%',
    borderTop: '1px solid black',
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  headInnerCol: {
    width: '100%',
    borderTop: '2px solid black',
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  acc: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  headlineWrap: {
    padding: 16,
  },
});

type P = {
  agendaItems: AgendaItem[];
  agendaKey: string;
  dayTime: number;
  useTimezone?: number;
};
export const AgendaDay = (props: P) => {
  const cls = useStyles();
  const firstItem = props.agendaItems[0];
  return (
    <div className={cls.wrapper}>
      <div className={cls.headlineWrap}>
        <Text variant="order2">{firstItem?.longDayTitle || format(props.dayTime, dateOnly)}</Text>
      </div>
      <div className={cls.row}>
        <div className={clsx(cls.commonCol, cls.timeCol)}>
          <div className={cls.headInnerCol}>
            <Text weight="bold">{strings.agendaTime}</Text>
          </div>
        </div>
        <div className={clsx(cls.commonCol, cls.itemCol)}>
          <div className={cls.headInnerCol}>
            <Text weight="bold">{strings.agendaProgram}</Text>
          </div>
        </div>
        <div className={clsx(cls.commonCol, cls.locationCol)}>
          <div className={cls.headInnerCol}>
            <Text weight="bold">{strings.agendaLocation}</Text>
          </div>
        </div>
      </div>
      {props.agendaItems.map((a, i) => {
        return (
          <div key={i} className={cls.row} id={`agenda-${a.time}`}>
            <div className={clsx(cls.commonCol, cls.timeCol)}>
              <div className={cls.innerCol}>
                <Text>
                  {props.useTimezone !== undefined
                    ? formatFixedTime(a.time, timeOnly, props.useTimezone)
                    : format(a.time, timeOnly)}
                </Text>
              </div>
            </div>
            <div className={clsx(cls.commonCol, cls.itemCol)}>
              <div className={cls.innerCol}>
                <Collapsable outer={a.title} inner={a.description} />
              </div>
            </div>
            <div className={clsx(cls.commonCol, cls.locationCol)}>
              <div className={cls.innerCol}>
                <Text>{a.location}</Text>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
