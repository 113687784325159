import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const mappings = [
  // reading:direct links looking like eventname/direct/surveys/1 will be redirected to /eventId/page/ChannelName/channelId/survey/surveyTemplateId (eg.  /sec/page/Seite1/2/survey/11)
  {
    type: 'surveys',
    // [paramId, surveyTemplateId]
    links: [
      [1, 2],
      [2, 3],
      [3, 4],
      [4, 5],
      [5, 6],
      [6, 7],
      [7, 8],
    ],
    path: '/page/survey/3/survey/',
  },
];

/** Maps "tiny" links to full page links */
export const LinkMapper = () => {
  const params = useParams<{ event: string; type: 'surveys'; position: string }>();
  const history = useHistory();
  useEffect(() => {
    const thisMap = mappings.find((m) => m.type === params.type);
    const thisLink = thisMap?.links.find((l) => l[0] === Number(params.position));

    if (thisLink && thisMap) {
      const path = `/${params.event}${thisMap.path}${thisLink[1]}`;
      history.push(path);
    }
  }, [params.type, params.position, params.event, history]);
  return null;
};
