import { client } from 'ApiHandler/client';
import { Session } from 'ApiHandler/dclxInterfaces';
import React, { useEffect, useState } from 'react';
import {
  useCurrentPageChannelId,
  useEventStarted,
  useNavigateEvent,
  useProgressionChannels,
} from 'utils/hooks';
import { byPosition } from 'utils/sort';
import { BannerConfiguration } from './Banner';
import { DCLXMyTrainings } from './DCLXMyTrainings/DCLXMyTrainings';
import { EventCountdown } from './EventCountdown';

/** Wrapper for pages */
const Event = () => {
  const eventStarted = useEventStarted();
  const channelId = useCurrentPageChannelId();

  const navEvent = useNavigateEvent();

  const channels = useProgressionChannels();

  const [sessions, setSessions] = useState<Session[] | undefined>(undefined);
  const [bannerConfig, setBannerConfig] = useState<BannerConfiguration | undefined>(undefined);
  useEffect(() => {
    const fetchSessions = async () => {
      const sessionsData = (await client.get<Session[]>('sessions')).data
        .filter((s: Session) => s.adapted && s.channelId === channelId)
        .sort(byPosition);
      setSessions(sessionsData);
      const bc = (await client.get('banner')).data;
      try {
        setBannerConfig(bc.filter((b: BannerConfiguration) => b.channelId === channelId)[0]);
      } catch (e) {}
    };
    fetchSessions();
  }, [channelId]);

  useEffect(() => {
    if (!channelId && channels.length > 0) {
      const key = channels[0].menuItemKey ? channels[0].menuItemKey : 'public';
      navEvent(`/page/${key}/${channels[0].channelId}`);
    }
  }, [channelId, channels, navEvent]);

  return (
    <div style={{ width: '100%' }}>
      {eventStarted ? (
        <DCLXMyTrainings channelId={channelId} />
      ) : (
        <EventCountdown sessions={sessions} bannerConfig={bannerConfig} />
      )}
    </div>
  );
};

export { Event };
