import React, { Component } from 'react';
const JitsiUrl = process.env.REACT_APP_JITSI_URL;

/** interface for JitsiFrame props coming from parent component TechTest */
interface P {
  container: string;
  room: string;
  username: string;
  wait: number;
  setLoaded: Function;
  hidden?: boolean;
  countLoaded: number;
}

interface S {}

/**
 * Small Jitsi Frame
 */

class JitsiFrame extends Component<P, S> {
  timer: any;

  constructor(props: P) {
    super(props);
    this.timer = null;
  }

  render = () => (
    <div style={this.props.hidden ? { display: 'none' } : undefined} id={this.props.container} />
  );

  loadJitsiScript = async () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement('script');
    script.src = `https://${JitsiUrl}/libs/external_api.min.js`;
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  loadJitsi = async (container: string, room: String, username: String) => {
    // @ts-ignore
    if (!window.JitsiMeetExternalAPI) {
      await this.loadJitsiScript();
    }
    const options = {
      roomName: room,
      width: '100%',
      height: '100%',
      parentNode: document.querySelector(container),
      userInfo: {
        displayName: username,
      },
      configOverwrite: { startWithAudioMuted: true, toolbarButtons: [] },
    };
    // @ts-ignore
    return new window.JitsiMeetExternalAPI(JitsiUrl, options);
  };

  initialiseJitsi = async () => {
    let api = await this.loadJitsi(
      `#${this.props.container}`,
      this.props.room,
      this.props.username
    );

    api.addEventListener(`videoConferenceJoined`, () => {
      const listener = ({ enabled }: { enabled: boolean }) => {
        api.removeEventListener(`tileViewChanged`, listener);

        if (!enabled) {
          api.executeCommand(`toggleTileView`);
        }
      };

      api.addEventListener(`tileViewChanged`, listener);
      api.executeCommand(`toggleTileView`);
      if (this.props.countLoaded === 0) {
        this.props.setLoaded();
      }
    });
  };

  componentDidMount = () => {
    if (this.props.wait < 1) {
      this.initialiseJitsi();
      return;
    }
    this.timer = setTimeout(() => {
      this.initialiseJitsi();
    }, this.props.wait);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };
}

export default JitsiFrame;
