import React from 'react';
import { useIsTablet } from '../../../../utils/hooks';
import AudiSpacer, { CustomSpacer } from '../../../../utils/AudiSpacer';
import { useIsTheme } from '@pp-labs/ui-components';

/** interface for TrainingsSpacer props coming from parent component DclxMyTrainings */
interface P extends CustomSpacer {
  top?: boolean;
  custom?: string;
}

/** Different spacings for main page */
const TrainingsSpacer = (props: P) => {
  const notDesktop = useIsTablet();
  const theme = useIsTheme();
  const currentSpace: string = props.custom ? props.custom : props.top ? '48' : '28';
  if (theme.neutral) {
    return <AudiSpacer neutralSpaceStackEnd={props.neutralSpaceStackEnd} />;
  } else if (notDesktop) {
    return <div style={{ marginBottom: `${currentSpace}px` }} />;
  } else {
    return <AudiSpacer spaceStackEnd={props.spaceStackEnd} />;
  }
};

export { TrainingsSpacer };
