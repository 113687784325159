import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './slick.scss';
import { Providers } from './Providers';
import { baseUrl } from './config';

if (window.location.host.includes('www')) {
  window.location.href = baseUrl;
}

ReactDOM.render(<Providers />, document.getElementById('root'));
