import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import DCLXUserTile from '../../shared/DCLXUserTile';
import { UserPicture } from '../../../../ApiHandler/dclxInterfaces';
import { useLotState } from '../Provider/LotProvider';
import { getParticipants } from '../Breakout/BreakoutRooms';

export interface HandUp {
  sub: string;
  date: number;
}

const useStyles = makeStyles(() => ({
  topLeft: {
    position: 'absolute',
    top: '84px',
    left: '34px',
    width: '48px',
    height: '48px',
  },
  user: {
    display: 'inline-block',
    padding: '10px',
    width: '25%',
    minWidth: '300px',
  },
}));

/**
 * Component for displaying the Hand Queue to Trainers in the main room and everyone in breakout rooms
 */

export const useHands = () => {
  const lotState = useLotState();
  const participants = useMemo(() => getParticipants(lotState), [lotState]);
  return useMemo(() => {
    let users: UserPicture[] = [];
    if (lotState.breakoutRooms.myBreakoutRoom) {
      users =
        participants.breakout.find((r) => r.room.Id === lotState.breakoutRooms.myBreakoutRoom?.Id)
          ?.users || [];
    } else {
      if (lotState.users.iAmTrainer) users = participants.main;
    }
    const byPositionInQueue = (user1: UserPicture, user2: UserPicture) => {
      const i1 = lotState.handsUp.handQueue.findIndex((s) => user1.username === s.sub);
      const i2 = lotState.handsUp.handQueue.findIndex((s) => user2.username === s.sub);
      return i1 - i2;
    };
    return users
      .filter((user) => lotState.handsUp.handQueue.find((s) => user.username === s.sub))
      .sort(byPositionInQueue);
  }, [lotState, participants]);
};

export const HandQueue = () => {
  const cls = useStyles();
  const handsUp = useHands().slice(0, 3);

  if (!handsUp.length) return null;

  return (
    <div className={cls.topLeft}>
      {handsUp.map((user, index) => {
        return (
          <div key={index} className={cls.user}>
            <DCLXUserTile showUserInfo={false} hand={true} handQueueId={undefined} user={user} />
          </div>
        );
      })}
    </div>
  );
};
