import { Select } from '@audi/audi-ui-react';
import { ChangeEvent, useMemo, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { SectionType } from './QuestionSection';
import { useQuestionState } from './QuestionState';

/** interface for FunctionFilter props coming from parent component QuestionSection */
interface P {
  type: SectionType;
}

/** Allows the AMAs to be filtered by JobFunction (not used much though). */
export const FunctionFilter = (props: P) => {
  const [value, setValue] = useState<string>('all');
  const id = useMemo(() => nanoid(), []);
  const state = useQuestionState();
  const change = (c: ChangeEvent<HTMLSelectElement>) => {
    const v = c.target.value;
    setValue(v);
    state.setFilter(props.type, v);
  };
  return (
    <div style={{ width: 250 }} onClick={(e) => e.stopPropagation()}>
      <Select inputId={id} label="Job Function" value={value} onChange={change}>
        <option value="all">all</option>
      </Select>
    </div>
  );
};
