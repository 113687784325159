import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { Session, TimeSlot } from '../../../ApiHandler/dclxInterfaces';

import { LabelButton, CancelIcon, AudiDialog, Text, useIsTheme } from '@pp-labs/ui-components';

import { getDisplayDate } from '../../../utils/convert';
import { env } from '../../../config';
import { client } from '../../../ApiHandler/client';
import { byDate } from '../../../utils/sort';
import { useNotify } from '../../../utils/hooks';
import { useProgression } from '../../../progression';
import { EditorParser } from 'features/Editor/EditorParser';

const useStyles = makeStyles((theme: Theme) => ({
  bookingCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bookingTxt: {
    margin: '30px 30px 30px 0',
  },
  bookingTitle: {
    marginTop: '70px',
  },
  bookingSubTitle: {
    marginTop: '14px',
  },
  bookingDescription: {
    marginTop: '20px',
  },
  personIcon: {
    marginRight: 11,
    marginTop: 8,
    marginBottom: 2,
  },
  scrollPaper: {
    alignItems: 'baseline',
    marginTop: '50px',
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  bookingButton: {
    float: 'right',
    display: 'inline',
    marginRight: '0px',
  },
}));

/** interface for Calendar props coming from parent component FeatureEvent */
interface P {
  open: boolean;
  close: (refresh?: boolean) => void;
  session: Session;
}

/**
 * Allows you to book LOTs
 */

const Calendar = (props: P) => {
  const progression = useProgression();
  const [allTrainings, setAllTrainings] = useState<TimeSlot[]>();
  const [sessionTitle, setSessionTitle] = useState<string>('');
  const classes = useStyles();
  const notify = useNotify();
  const theme = useIsTheme();
  useEffect(() => {
    const fetch = async () => {
      const trainings: TimeSlot[] = (
        await client.get(`trainings/bySession/${props.session.sessionId}`)
      ).data;
      trainings.sort(byDate);
      const session: Session = (await client.get(`sessions/${props.session.sessionId}`)).data;
      setAllTrainings(trainings);
      setSessionTitle(getLocalizedValue(session.title));
    };
    fetch();
  }, [props.session]);

  const close = () => {
    props.close();
  };

  const submitToServer = async (v: number) => {
    try {
      await client.post(`trainings/${v}/book`);
      if (progression !== 'loading') await progression.schedule.addToSchedule(props.session);
      props.close(true);
      notify(strings.booked, 'success');
    } catch {
      props.close(true);
      notify(strings.error, 'error');
    }
  };

  if (!allTrainings || !sessionTitle) return null;
  const currentDate = Date.now() / 1000;
  const bookableTrainings = allTrainings.filter(
    (t) => currentDate < t.bookingEndAt && currentDate < t.endAt
  );
  const e = env();

  return (
    <AudiDialog
      open={props.open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      classes={{ scrollPaper: classes.scrollPaper }}
    >
      <div style={{ backgroundColor: theme.neutralDark ? 'black' : undefined }}>
        <DialogTitle id="form-dialog-title">
          <Text as="p" variant="order2" weight="bold" className={classes.bookingTitle}>
            {strings.booking}: {sessionTitle}
          </Text>
          <div style={{ padding: '0 0 20px 0' }}>
            <Text as="p" variant="copy2" className={classes.bookingSubTitle}>
              {strings.selectTrainingBelow}
            </Text>
          </div>
          <IconButton
            onClick={close}
            style={{
              position: 'absolute',
              right: '6px',
              top: '6px',
              color: theme.neutralDark ? 'white' : undefined,
            }}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.flexWrapper}>
            {!bookableTrainings.length && (
              <Text variant="copy1" as="p">
                {strings.noTrainingsAvailable}
              </Text>
            )}

            {bookableTrainings.map((t) => {
              const trainingId = t.trainingId; // to bind the trainingsId to this training
              const freePlaces = Math.max(t.maxParticipants - t.bookings, 0);
              return (
                <div key={`training-${trainingId}`}>
                  <Divider />
                  <div className={classes.bookingCont}>
                    <div className={classes.bookingTxt}>
                      <Text as="p" variant="copy1" weight="bold">
                        {getDisplayDate(t.startAt)} / {getDisplayDate(t.startAt, true, true)} -{' '}
                        {getDisplayDate(t.endAt, true, true)}
                      </Text>
                      <Text as="span" variant="copy1" weight="bold">
                        {strings.formatString(strings.placesFree, freePlaces)}
                      </Text>
                      <div style={{ display: 'inline', marginLeft: '6px' }}>
                        <Text as="span" variant="copy2">
                          {strings.bookableUntil}: {getDisplayDate(t.bookingEndAt, true)}
                        </Text>
                      </div>
                      <EditorParser inputString={getLocalizedValue(t.description)} />
                    </div>
                    <div className={classes.bookingButton}>
                      <LabelButton
                        variant="secondary"
                        onClick={() => submitToServer(trainingId)}
                        disabled={!freePlaces}
                      >
                        {strings.bookNow}
                      </LabelButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </div>
    </AudiDialog>
  );
};

export default Calendar;
