import { NotificationMessage } from 'DCLX/CMS/Messages/Messages';
import React from 'react';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';
import { EditorParser } from 'features/Editor/EditorParser';
import { client } from 'ApiHandler/client';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { humanTime } from 'utils/convert';
import clsx from 'clsx';

/** interface for NotificationTile props coming from parent component MessageNotification */
interface P {
  message: NotificationMessage;
  refresh: () => void;
  currentTime: number;
}

const useStyle = makeStyles({
  activeWrapper: {
    padding: '16px 24px',
    cursor: 'pointer',
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  inactiveWrapper: {
    padding: '16px 24px',
  },
  redDot: {
    height: '12px',
    width: '12px',
    backgroundColor: '#F50537',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  tipWrapper: {
    position: 'absolute',
    background: '#D7D7D7',
    padding: '0px 2px',
    border: '1px solid',
    width: '140px',
    textAlign: 'center',
    right: '0px',
    top: '30px',
  },
  whiteText: {
    color: '#000000',
  },
});

/** One single notification in the List */
const NotificationTile = (props: P) => {
  const cls = useStyle();
  const timeAgo = props.currentTime - props.message.validFrom * 1000;
  const t = humanTime(timeAgo);

  const handleClick = async (id: number) => {
    await client.post(`/notifications/visited/${id}`);
    props.refresh();
  };
  const theme = useIsTheme();
  return (
    <div
      className={clsx(!props.message.visited ? cls.activeWrapper : cls.inactiveWrapper)}
      onClick={() => (!props.message.visited ? handleClick(props.message.notificationId) : null)}
    >
      <div className={cls.titleWrapper}>
        <div className={cls.title}>
          <Text variant="copy2" weight="bold" className={clsx(theme.neutralDark && cls.whiteText)}>
            {getLocalizedValue(props.message.title)}
          </Text>
          <Text
            spaceInlineStart="xs"
            variant="copy3"
            className={clsx(theme.neutralDark && cls.whiteText)}
          >
            {t[1]} {strings.hoursAgo}
          </Text>
        </div>
        {!props.message.visited && (
          <div style={{ position: 'relative' }}>
            <span className={cls.redDot} />
          </div>
        )}
      </div>
      <div>
        <Text variant="copy3">
          <EditorParser inputString={props.message.message} enforceBlackText fontVariant="copy2" />
        </Text>
      </div>
    </div>
  );
};
export { NotificationTile };
