import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FAQ, Support } from '../../../ApiHandler/dclxInterfaces';
import { SupportEdit } from './SupportEdit';
import FAQTable from '../Questions/FAQTable';
import strings from '../../../Localization/Localizer';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { UploadMailTemplates } from '../UploadMailTemplates';
import { useCMSChannel, useForceNoRender } from '../../../utils/hooks';

/** interface for InnerSupport props coming from channelSwitcher */
interface P {}

/**
 * Wrapper for Explainer Video and FAQ Editing
 */

const CmsSupport = () => (
  <div>
    <InnerSupport></InnerSupport>
    <UploadMailTemplates />
  </div>
);

/** Explainer Video/Text management */
const InnerSupport = (props: P) => {
  const [supports, setSupports] = useState<Support[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  const channel = useCMSChannel()!;

  const fetchSupport = useCallback(async () => {
    const exps = (await client.get<Support[]>('faq/explainer')).data;
    setSupports(exps);
  }, []);

  const fetchFaq = useCallback(async () => {
    const faqsData = (await client.get<FAQ[]>('faq')).data;
    setFaqs(faqsData);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchSupport();
      await fetchFaq();
    };
    fetch();
  }, [fetchSupport, fetchFaq]);

  const finishEdit = async (refresh?: boolean) => {
    if (refresh) await fetchSupport();
  };

  const support = useMemo(
    () => supports.find((s) => s.channels.includes(channel.channelId)) || null,
    [channel, supports]
  );

  // we need this because of a bug in the localized form. When the initialValues change and there was no change yet,
  // the current value should (?) change as well.

  const pause = useForceNoRender([channel, support]);

  const faq = useMemo(
    () => faqs.filter((s) => s.channels.includes(channel.channelId)),
    [channel, faqs]
  );
  if (pause) return null;
  return (
    <LayoutItem spaceStackStart="l">
      <Text variant="order2" weight={'bold'}>
        {strings.cmsMenuSupport}
      </Text>
      <SupportEdit thisSupport={support} close={finishEdit} channel={channel} />
      <div style={{ padding: '20px 0' }} />
      <FAQTable refresh={fetchFaq} displayQuestions={faq} allQuestions={faq} channel={channel} />
    </LayoutItem>
  );
};

export default CmsSupport;
