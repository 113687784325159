import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Channel,
  ChannelTopic,
  ConfigModuleIdentifiers,
  PostTopic,
} from '../../../ApiHandler/dclxInterfaces';
import strings, { useLocalizedLanguages } from '../../../Localization/Localizer';
import {
  EditDialog,
  FormikCheckbox,
  LabelButton,
  LocalizedForm,
  Select,
  TextArea,
  TextField,
} from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import AudiSpacer from 'utils/AudiSpacer';
import { toInt } from 'utils/convert';
import { useNamedConfigModule, useNotify } from 'utils/hooks';
import { atLeastOneLanguage, requiredMediaFile } from 'utils/validator';
import { CmsNewDatePicker } from '../../utils/CmsNewDatePicker';
import { makeStyles } from '@material-ui/core';
import { MediaFile } from '../Documents/MediaLibrary';
import { SelectMediaButton } from '../Documents/SelectMediaButton';

/** interface for EditTopic props coming from parent component ChannelTable */
interface P {
  open: boolean;
  close: () => void;
  topic: ChannelTopic | null;
  channel: Channel;
}

interface FormValues {
  title: string;
  description: string;
  thumb?: MediaFile;
  countdown?: MediaFile;
  badgeImage?: MediaFile;
  block: string;
  closingMessage: string;
  disableVideoProgression: boolean;
}

const useStyle = makeStyles({
  uploadBtnWrapper: {
    display: 'flex',
    width: '615px',
    justifyContent: 'space-between',
  },
  smallBtnWrapper: {
    display: 'flex',
    width: '408px',
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: '18px',
  },
});

/** The various blocks grouping topics together */
const blocks = ['A', 'B', 'C', 'D'];

/**
 * Allows users to edit a topic
 */
const EditTopic = (props: P) => {
  const notify = useNotify();
  const languages = useLocalizedLanguages([props.channel]);
  const configModule = useNamedConfigModule();
  const gamification = configModule(ConfigModuleIdentifiers.gamification);
  const cls = useStyle();
  const initialValues: FormValues & { start: Date; end: Date } = useMemo(() => {
    const startDate = props.topic?.startAt
      ? new Date(toInt(props.topic?.startAt) * 1000)
      : new Date();
    const endDate = props.topic?.endAt ? new Date(toInt(props.topic?.endAt) * 1000) : new Date();
    return {
      title: props.topic?.title ? JSON.parse(props.topic.title) : '',
      description: props.topic?.description ? JSON.parse(props.topic.description) : '',
      start: startDate,
      end: endDate,
      thumb: props.topic?.thumb,
      countdown: props.topic?.countdownImage,
      badgeImage: props.topic?.badgeImage,
      block: props.topic?.block || blocks[0],
      closingMessage: props.topic?.closingMessage ? JSON.parse(props.topic.closingMessage) : '',
      disableVideoProgression: props.topic?.disableVideoProgression || false,
    };
  }, [props.topic]);

  const validationSchema = useMemo(() => {
    const obj: any = {
      title: atLeastOneLanguage(),
      description: atLeastOneLanguage(),
      block: Yup.string().required(),
      closingMessage: atLeastOneLanguage(),
      disableVideoProgression: Yup.boolean(),
    };
    if (!props.topic) {
      obj.thumb = requiredMediaFile;
      obj.countdown = requiredMediaFile;
      if (gamification) {
        obj.badgeImage = requiredMediaFile;
      }
    }
    return Yup.object(obj).required(strings.required);
  }, [props.topic, gamification]);

  const [dateStart, setDateStart] = useState<Date>(initialValues.start);
  const [dateEnd, setDateEnd] = useState<Date>(initialValues.end);

  const getErrors = (touched: any, errors: any) => {
    return {
      title: touched.title ? errors.title : '',
      description: touched.description ? errors.description : '',
      block: touched.block ? errors.block : '',
      thumb: touched.thumb ? errors.thumb : '',
      countdown: touched.countdown ? errors.countdown : '',
      badgeImage: touched.badgeImage ? errors.badgeImage : '',
      closingMessage: touched.closingMessage ? errors.closingMessage : '',
    };
  };

  const submitToServer = async (v: FormValues) => {
    const data: PostTopic = {
      channelId: props.channel.channelId,
      title: JSON.stringify(v.title),
      description: JSON.stringify(v.description),
      thumbId: v.thumb?.mediaId || props.topic?.thumbId,
      countdownImageId: v.countdown?.mediaId || props.topic?.countdownImageId,
      badgeImageId: v.badgeImage?.mediaId || props.topic?.badgeImageId,
      startAt: dateStart.getTime() / 1000,
      endAt: dateEnd.getTime() / 1000,
      block: v.block,
      closingMessage: JSON.stringify(v.closingMessage),
      disableVideoProgression: v.disableVideoProgression,
    };
    await getData(data, v);
  };

  const getData = async (data: PostTopic, v: FormValues) => {
    try {
      props.topic
        ? await client.put(`topics/${props.topic?.topicId}`, data)
        : await client.post(`topics`, data);
      notify(strings.topicCreated, 'success');
      props.close();
    } catch (error: any) {
      notify(error.message, 'error');
      props.close();
    }
  };

  if (!props.open) return null;

  return (
    <EditDialog
      title={props.topic ? strings.editTopic : strings.newTopic}
      close={props.close}
      maxWidth="lg"
      autoAdaptWidth
      cms
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitToServer}
      >
        {({ errors, touched, values, setFieldValue, handleChange }) => {
          const et = getErrors(touched, errors);
          return (
            <LocalizedForm
              languages={languages}
              localizeInputs={['title', 'description', 'closingMessage']}
              initialValues={{
                title: initialValues.title,
                description: initialValues.description,
                closingMessage: initialValues.closingMessage,
              }}
              autoParse={[]}
            >
              <TextField name="title" label={'title'} error={et.title} />
              <AudiSpacer spaceStackEnd={'s'} />
              <TextArea name="description" label={strings.description} error={et.description} />
              <AudiSpacer spaceStackEnd={'s'} />
              <CmsNewDatePicker
                text={strings.startsAt}
                initialDate={initialValues.start}
                onChange={setDateStart}
              />
              <AudiSpacer spaceStackEnd={'s'} />
              <CmsNewDatePicker
                text={strings.endsAt}
                initialDate={initialValues.end}
                onChange={setDateEnd}
              />
              <AudiSpacer spaceStackEnd={'m'} />
              <Select name="block" label="Topic Block" error={et.block}>
                {blocks.map((b) => (
                  <option key={b} value={b}>
                    {b}
                  </option>
                ))}
              </Select>
              <AudiSpacer spaceStackEnd={'m'} />
              <TextField
                name="closingMessage"
                label={strings.closingMessage}
                error={et.closingMessage}
              />
              <AudiSpacer spaceStackEnd={'m'} />
              <div className={gamification ? cls.uploadBtnWrapper : cls.smallBtnWrapper}>
                <SelectMediaButton
                  name="thumb"
                  label={strings.thumbnail}
                  type="image"
                  prevUrl={values.thumb?.url || props?.topic?.thumb?.url}
                  prevName={values.thumb?.originalFile || props?.topic?.thumb?.originalFile}
                  variant={'dark'}
                  imgDimensions="960 x 540 px"
                />
                <SelectMediaButton
                  name="countdown"
                  label={'Countdown-Image'}
                  type="image"
                  prevUrl={values.countdown?.url || props?.topic?.countdownImage?.url}
                  prevName={
                    values.countdown?.originalFile || props?.topic?.countdownImage?.originalFile
                  }
                  variant={'dark'}
                  imgDimensions="3000 x 520 px"
                />
                {gamification && (
                  <SelectMediaButton
                    name="badgeImage"
                    label={'Badge-Image'}
                    type="image"
                    prevUrl={values.badgeImage?.url || props?.topic?.badgeImage?.url}
                    prevName={
                      values.badgeImage?.originalFile || props?.topic?.badgeImage?.originalFile
                    }
                    variant={'dark'}
                    imgDimensions="500 x 500 px"
                  />
                )}
              </div>
              <AudiSpacer spaceStackEnd={'s'} />
              <FormikCheckbox
                name="disableVideoProgression"
                label={strings.disableVideoProgression}
              />
              <AudiSpacer spaceStackEnd={'s'} />
              <div className={cls.buttonsWrapper}>
                <LabelButton variant="secondary" onClick={props.close}>
                  {strings.cancel}
                </LabelButton>
                <LabelButton type="submit" variant="primary" className={cls.submitButton}>
                  {strings.save}
                </LabelButton>
              </div>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};

export default EditTopic;
