import { DialogContent } from '@material-ui/core';
import { EditDialog } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import { MediaLibrary, MediaLibraryProps } from './MediaLibrary';

export type MediaLibraryDialogProps = MediaLibraryProps & {
  onClose: () => void;
  open: boolean;
  showCreateHtml?: boolean;
};

/** Media library in a dialog.  */
export const MediaLibraryDialog = ({ open, onClose, ...props }: MediaLibraryDialogProps) => {
  if (!open) return null;
  return (
    <EditDialog title={strings.manageDocuments} close={onClose} maxWidth="lg">
      <DialogContent id="media-dialog">
        <MediaLibrary {...props} />
      </DialogContent>
    </EditDialog>
  );
};
