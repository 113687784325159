import React from 'react';

/** interface for StepIndicator props coming from multiple parent components */
interface P {
  step: number;
  numSteps: number;
}

/**
 * Indicate the progress of something
 */

const StepIndicator = (props: P) => {
  const a = new Array(props.numSteps).fill(0);
  return (
    <div style={{ position: 'relative', height: 16 }}>
      {a.map((value, index) => {
        const isFilled = index < props.step;
        return (
          <div
            style={{
              position: 'absolute',
              left: index * 24,
              width: 20,
              height: 8,
              background: `${isFilled ? '#333333' : '#F2F2F2'}`,
            }}
            key={index}
          ></div>
        );
      })}
    </div>
  );
};

export default StepIndicator;
