import React from 'react';
import { Text } from '@pp-labs/ui-components';
import { AdvancedDatePicker } from './AdvancedDatePicker';
import { makeStyles } from '@material-ui/core';

/** interface for CmsNewDatePicker props coming from parent components EventTimeSubform, MessageDialog and TopicForm */

interface P {
  text: string;
  initialDate: Date;
  initialTimezone?: number;
  onChange: (newVal: Date) => void;
  onTimezoneChange?: (newVal: number) => void;
}

const useStyles = makeStyles({
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

/** Wrapper for the Advanced date picker, adding a label */
const CmsNewDatePicker = (props: P) => {
  const cls = useStyles();
  return (
    <div className={cls.mainWrapper}>
      <Text variant="order4" weight={'bold'}>
        {props.text}:
      </Text>
      <AdvancedDatePicker initialDate={props.initialDate} onChange={props.onChange} initialTimezone={props.initialTimezone} onTimezoneChange={props.onTimezoneChange} />
    </div>
  );
};

export { CmsNewDatePicker };
