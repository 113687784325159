import { ArrowDown, ArrowLeft } from 'icons';
import React from 'react';

type P = {
  open: boolean;
};

/** Arrows for collapsable elements, avoiding doing it wrong ;) */
const TableArrows = (props: P) => {
  if (props.open) {
    return <ArrowDown />;
  } else {
    return <ArrowLeft />;
  }
};
export { TableArrows };
