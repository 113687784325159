import React, { Component } from 'react';
import { Search } from '@pp-labs/ui-components';
import strings from '../../../Localization/Localizer';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { connect } from 'react-redux';
import { LabelButton } from '@pp-labs/ui-components';

/** interface for SearchPart props coming from parent component AddParticipant */
interface P {
  triggerSearch: Function;
  triggerNotification: Function;
}

/** interface for SearchPart component state  */
interface S {
  search: string;
}

/**
 * Component to search for Participants
 */

class SearchPart extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      search: '',
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.searchEvent);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.searchEvent);
  }

  searchEvent = (event: any) => {
    if (event.keyCode !== 13) return;
    this.search();
  };

  search = () => {
    this.props.triggerSearch(this.state.search);
  };

  render() {
    return (
      <div>
        <Search
          inputId="search_part"
          label={strings.search}
          onChange={(e) => this.setState({ search: e.target.value })}
          value={this.state.search}
        />
        <LabelButton variant="secondary" onClick={this.search}>
          Search
        </LabelButton>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = { triggerNotification };

export default connect(mapStateToProps, mapDispatchToProps)(SearchPart);
