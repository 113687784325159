import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import {
  ConfigModuleIdentifiers,
  RawAgenda,
  Session,
  SessionType,
} from '../../../../ApiHandler/dclxInterfaces';
import AudiCompliance from '../../shared/AudiCompliance';
import { Topic } from './Topic';
import { client } from '../../../../ApiHandler/client';

import { useCurrentPageChannelId, useLoad, useNamedConfigModule } from '../../../../utils/hooks';
import { DCLXMyTrainingsSlider } from './DCLXMyTrainingsSilder';
import SessionTiles from '../DCLXSessionTiles';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { Banner, BannerConfiguration } from '../Banner';
import {
  ProgressionReturn,
  TopicProgression,
  useInnerProgression,
  useProgression,
} from '../../../../progression';
import { TrainingsSpacer } from './TrainingsSpacer';
import styles from '../../../../features/Sessions/styles';
import { Section, SectionType } from 'DCLX/CMS/DCLXSections';
import { FirstSteps } from '../../../../features/FirstSteps/FirstSteps';
import { RequireRefreshAfter } from '../../../../config';
import { SurveyList } from '@pp-labs/survey';
import { SurveyProvider } from 'features/Survey/SurveyProvider';
import { LocationTabs } from 'features/gallery/LocationTabs';
import FAQ from 'features/FAQ/FAQ';
import { useHistory, useParams } from 'react-router-dom';
import { AgendaWrapper } from '../../../../features/agenda/AgendaView';
import { parseAgenda } from 'utils/convert';
import { Survey } from 'features/Survey/Survey';
import AudiSpacer from 'utils/AudiSpacer';

const useStyles = makeStyles((theme: Theme) => ({
  ...styles(theme),
}));

/**
 * Main Trainings Component
 */

export const DCLXMyTrainings = (props: { channelId: number }) => {
  const cls = useStyles();
  const [bannerConfig, setBannerConfig] = useState<BannerConfiguration | undefined>(undefined);
  const [sections, setSections] = useState<Section[]>([]);
  const [agendi] = useLoad<RawAgenda[]>('agenda');
  const parsedAgendi = useMemo(
    () => agendi?.filter((a) => a.channelId === props.channelId).map((a) => parseAgenda(a)),
    [agendi, props.channelId]
  );

  const progression = useInnerProgression({
    channelId: props.channelId,
  });
  const fullProgression = useProgression();
  const theme = useIsTheme();
  // refreshes if coming to this page and our progression is older then 20 seconds.
  // This avoids unnecessary loads on login, but makes sure we always have the latest stats when coming back to this page.
  useEffect(() => {
    if (progression !== 'loading' && Date.now() - progression.info.loaded > RequireRefreshAfter) {
      progression.refresh();
    }
  }, [progression]);

  useEffect(() => {
    setSections([]);
    setBannerConfig(undefined);
    const fetchAll = async () => {
      const sectionsData: Section[] = (await client.get('channels/sections')).data;
      const channelSections = sectionsData.find((s: Section) => s.channelId === props.channelId)
        ? sectionsData.filter((s: Section) => s.channelId === props.channelId)
        : sectionsData
            .filter((s: Section) => s.channelId === 0)
            .map((s: Section) => {
              let nSection = s;
              nSection.channelId = props.channelId;
              return nSection;
            });

      setSections(channelSections.sort((a: Section, b: Section) => a.position - b.position));
      const bcs = (await client.get('banner')).data;
      setBannerConfig(bcs.find((bc: BannerConfiguration) => bc.channelId === props.channelId));
    };
    fetchAll();
  }, [props.channelId]);

  const onDemandSection = useMemo(() => {
    if (progression === 'loading') return [];
    return progression.info.sessions.filter(
      (s) =>
        (s.showInChannelContent && s.sessionType === SessionType.DEMAND) ||
        s.sessionType === SessionType.EXTERNAL
    );
  }, [progression]);

  const conferenceSection = useMemo(() => {
    if (progression === 'loading') return [];
    return progression.info.sessions.filter(
      (s) => s.showInChannelContent && s.sessionType === SessionType.TRAINING
    );
  }, [progression]);

  const liveSection = useMemo(() => {
    if (progression === 'loading') return [];
    const n = progression.now;
    return progression.info.sessions.filter(
      (s) =>
        s.showInChannelContent &&
        (s.sessionType === SessionType.EXTERNALTERMINATED ||
          s.sessionType === SessionType.LIVE ||
          s.sessionType === SessionType.LIVETERMINATED) &&
        /* If there is an end-date, make sure it is not over yet */
        (!s.endAt || n < s.endAt)
    );
  }, [progression]);

  const getShowTopicAnchor = useMemo(() => {
    const sectionWithKey = sections.find((s) => s.sectionKey === 'topic');
    if (sectionWithKey) {
      try {
        return JSON.parse(sectionWithKey.customSettings).showAnchor;
      } catch {}
      return true; // showing per default.
    }
    return false;
  }, [sections]);

  const refresh = async () => {
    if (fullProgression !== 'loading') fullProgression.refresh();
    if (progression !== 'loading') progression.refresh();
  };

  if (progression === 'loading') return null;
  return (
    <>
      <div style={{ minWidth: '100%' }}>
        {bannerConfig && (
          <Banner
            bannerConfiguration={bannerConfig}
            sessions={progression.info.sessions}
            timeslots={progression.info.timeSlots}
            agendas={parsedAgendi}
            interactable
          />
        )}
      </div>
      <div className={cls.firstStepsWrapper}>
        <FirstSteps progression={progression} channelId={props.channelId} />
      </div>
      <div className={cls.trainingSliderWrapper} style={{ minWidth: '100%' }}>
        {progression.info.topics.length > 0 && getShowTopicAnchor && (
          <DCLXMyTrainingsSlider topics={progression.info.topics} />
        )}
      </div>
      <div className={cls.eventMainWrapper} style={{ minWidth: '100%' }}>
        <Grid container justifyContent="center" spacing={0}>
          <Grid
            item
            style={{
              width: '100%',
            }}
          >
            <Sections
              sections={sections.filter((s: Section) => s.isActive)}
              onDemandSection={onDemandSection}
              conferenceSection={conferenceSection}
              liveSection={liveSection}
              refresh={refresh}
              progression={progression}
              channelId={props.channelId}
            />
          </Grid>
        </Grid>
        {theme.audi && <AudiCompliance />}
      </div>
      {theme.neutral && <AudiCompliance />}
    </>
  );
};

type SectionProps = {
  sections: Section[];
  onDemandSection: Session[];
  conferenceSection: Session[];
  liveSection: Session[];
  refresh: () => void;
  progression: ProgressionReturn;
  channelId: number;
};

const Sections = (props: SectionProps) => {
  const params = useParams<{ surveyId?: string; agendaId?: string; agendaItemId?: string }>();
  const history = useHistory();
  const currentChannelId = useCurrentPageChannelId();
  const [scrolled, setScrolled] = useState<boolean>(false);
  const surveyRef = useRef<HTMLDivElement>(null);
  const agendaRef = useRef<HTMLDivElement>(null);
  const usesModule = useNamedConfigModule();
  useEffect(() => {
    if (scrolled || (!params.surveyId && !params.agendaId) || !props.sections.length) return;
    // lazy loading leads towards scrolling to the wrong position without the timeout.
    // This sadly is a bit ugly, but I am out of ideas.
    setScrolled(true);
    if (!!params.surveyId)
      setTimeout(() => {
        surveyRef.current?.scrollIntoView({ behavior: 'smooth' });
        const splited = history.location.pathname.split('/survey/');
        splited.pop();
        history.push(splited.join('/survey/'));
        setScrolled(false);
      }, 1000);
    if (!!params.agendaId)
      setTimeout(() => {
        agendaRef.current?.scrollIntoView({ behavior: 'smooth' });
        const splited = history.location.pathname.split('/agenda/');
        splited.pop();
        history.push(splited.join('/agenda/'));
        setScrolled(false);
      }, 1000);
  }, [scrolled, surveyRef, props.sections, params.surveyId, params.agendaId, history]);

  return (
    <div>
      {props.sections.map((section) => {
        if (
          section.sectionKey === ('onDemand' as SectionType) &&
          usesModule(ConfigModuleIdentifiers.onDemandSessions) &&
          props.onDemandSection.length > 0
        ) {
          return (
            <React.Fragment key={section.sectionKey}>
              <TrainingsSpacer top spaceStackEnd={'xxxl'} neutralSpaceStackEnd={'l'} />
              <Text variant={'order2'} weight={'bold'}>
                {getLocalizedValue(section.title) || strings[`section_${section.sectionKey}`]}
              </Text>
              <TrainingsSpacer spaceStackEnd={'xl'} neutralSpaceStackEnd={'l'} />
              <SessionTiles
                maxCols={dynamicCols(props.onDemandSection)}
                unlockRequired={false}
                sessions={props.onDemandSection}
                firstUnlocked={true}
                refresh={props.refresh}
                disableVideoProgression={section.disableVideoProgression}
              />
            </React.Fragment>
          );
        } else if (
          section.sectionKey === ('live' as SectionType) &&
          usesModule(ConfigModuleIdentifiers.liveStreamingSessions) &&
          props.liveSection.length > 0
        ) {
          return (
            <React.Fragment key={section.sectionKey}>
              <TrainingsSpacer custom={'20'} spaceStackEnd={'xxxl'} neutralSpaceStackEnd={'l'} />
              <Text variant={'order2'} weight={'bold'}>
                {getLocalizedValue(section.title) || strings[`section_${section.sectionKey}`]}
              </Text>
              <TrainingsSpacer spaceStackEnd={'l'} neutralSpaceStackEnd={'l'} />
              <SessionTiles
                maxCols={dynamicCols(props.liveSection)}
                unlockRequired={false}
                sessions={props.liveSection}
                firstUnlocked={true}
                refresh={props.refresh}
                disableVideoProgression={section.disableVideoProgression}
              />
            </React.Fragment>
          );
        } else if (
          section.sectionKey === ('conf' as SectionType) &&
          usesModule(ConfigModuleIdentifiers.videoConferenceSessions) &&
          props.conferenceSection.length > 0
        ) {
          return (
            <React.Fragment key={section.sectionKey}>
              <TrainingsSpacer custom={'20'} spaceStackEnd={'xxl'} neutralSpaceStackEnd={'l'} />
              <Text variant={'order2'} weight={'bold'}>
                {getLocalizedValue(section.title) || strings[`section_${section.sectionKey}`]}
              </Text>
              <TrainingsSpacer spaceStackEnd={'xl'} neutralSpaceStackEnd={'l'} />
              <SessionTiles
                maxCols={dynamicCols(props.conferenceSection)}
                unlockRequired={false}
                sessions={props.conferenceSection}
                firstUnlocked={true}
                refresh={props.refresh}
                disableVideoProgression={section.disableVideoProgression}
              />
            </React.Fragment>
          );
        } else if (
          section.sectionKey === ('topic' as SectionType) &&
          props.progression.info.topics.length > 0
        ) {
          return (
            <React.Fragment key={section.sectionKey}>
              <TrainingsSpacer custom={'20'} spaceStackEnd={'xxl'} neutralSpaceStackEnd={'l'} />

              <Text variant={'order2'} weight={'bold'}>
                {getLocalizedValue(section.title) || strings[`section_${section.sectionKey}`]}
              </Text>
              {Object.entries(props.progression.matrix).map((e) => {
                const [block, topicsTests] = e;
                return (
                  <div key={block}>
                    {topicsTests
                      .filter((t) => !('examId' in t))
                      .map((t) => {
                        const topic = t as unknown as TopicProgression;
                        return (
                          <Topic
                            key={topic.topicId}
                            topic={topic}
                            progression={props.progression}
                            refresh={props.refresh}
                            block={block}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </React.Fragment>
          );
        } else if (section.sectionKey === ('surveys' as SectionType)) {
          return (
            <div ref={surveyRef}>
              {!!section.title && (
                <Text variant="order2" spaceStackEnd="l">
                  {getLocalizedValue(section.title)}
                </Text>
              )}

              <SurveyProvider>
                <Survey channelId={currentChannelId} id={0} context={'overall-m'}></Survey>
                <AudiSpacer spaceStackEnd="l" />
                <SurveyList
                  channelId={props.channelId}
                  surveyId={params.surveyId ? Number(params.surveyId) : undefined}
                />
              </SurveyProvider>
            </div>
          );
        } else if (section.sectionKey === ('imageGallery' as SectionType)) {
          return <LocationTabs title={getLocalizedValue(section.title)} />;
        } else if (section.sectionKey === ('faq' as SectionType)) {
          return <FAQ title={getLocalizedValue(section.title)} />;
        } else if (section.sectionKey === ('agenda' as SectionType)) {
          let useTimezone = undefined;
          try {
            useTimezone = Number.parseInt(JSON.parse(section.customSettings).useTimezone);
            if (isNaN(useTimezone)) {
              useTimezone = undefined;
            }
          } catch {}

          return (
            <div ref={agendaRef}>
              <AgendaWrapper channelId={props.channelId} useTimezone={useTimezone} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const dynamicCols = (data: unknown[]) => (data.length > 2 ? 4 : 2);
