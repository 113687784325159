import React from 'react';
import SimpleVideoPlayer from '../utils/SimpleVideoPlayer';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import { makeStyles, Theme } from '@material-ui/core';
import { Support } from '../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from 'Localization/Localizer';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: '64px',
  },
  text: {
    marginBottom: '32px !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: '28px !important',
    },
  },
}));

interface P {
  explainer: Support;
}

/**
 * Shows only the explainer Video and some LOTs during Phase2/Pre-Launch Phase
 */

const ExplainerVideo = (props: P) => {
  const cls = useStyles();
  const theme = useIsTheme();
  return (
    <div className={cls.wrapper}>
      {props.explainer.video?.url && (
        <Text
          className={cls.text}
          as="h2"
          variant={theme.audi ? 'order3' : 'order2'}
          weight={theme.audi ? 'bold' : 'normal'}
        >
          {strings.supportVideoTitle}
        </Text>
      )}
      {props.explainer.explainerText && (
        <Text className={cls.text} as="p" variant="copy1">
          {getLocalizedValue(props.explainer.explainerText)}
        </Text>
      )}
      {props.explainer.video?.url && <SimpleVideoPlayer url={props.explainer.video?.url} />}
    </div>
  );
};

export default ExplainerVideo;
