import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Text, TextField } from '@pp-labs/ui-components';
import { MFAData, validation } from './MFAUtils';
import strings from '../Localization/Localizer';
import { LabelButton } from '@pp-labs/ui-components';

/** interface for MFAForm props  */
interface P {
  finishMFA: (v: MFAData) => void;
}
/**
 * Form for logging in with MFA
 */

const MFAForm = (props: P) => {
  const initialValues: MFAData = { validationCode: '' };
  return (
    <div>
      <div style={{ padding: '10px 0' }} id="mfaWrapper" />
      <Text as="h2" variant="order4">
        {strings.loginMfa}
      </Text>
      <div style={{ padding: '10px 0' }} />

      <Text as="p" variant="copy1">
        {strings.enterMfaCodeLogin}
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validation()}
        onSubmit={(values: MFAData) => {
          props.finishMFA(values);
        }}
      >
        <Form>
          <Field
            inputId="validationCode"
            label={strings.enterMfaCode}
            as={TextField}
            name="validationCode"
            type="text"
            required={true}
          />
          <ErrorMessage name="validationCode" component="div" className="field-error" />
          <LabelButton variant="primary" type="submit">
            {strings.login}
          </LabelButton>
        </Form>
      </Formik>
    </div>
  );
};

export default MFAForm;
