import React, { useEffect, useState } from 'react';
import { useProgressionChannels, useNotify, useCMSChannel } from 'utils/hooks';

import {
  DraggableComponent,
  DroppableComponent,
  EditDialog,
  EditIcon,
  FormikCheckbox,
  handleDrop,
  LabelButton,
  LayoutItem,
  LocalizedForm,
  TableCell,
  TextField,
  Toggle,
} from '@pp-labs/ui-components';
import strings, { getLocalizedValue, useLocalizedLanguages } from 'Localization/Localizer';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { client } from 'ApiHandler/client';
import { Select, Text } from '@audi/audi-ui-react';
import { Formik } from 'formik';
import AudiSpacer from 'utils/AudiSpacer';

export type SectionType =
  | 'onDemand'
  | 'live'
  | 'conf'
  | 'topic'
  | 'agenda'
  | 'imageGallery'
  | 'surveys'
  | 'faq';

export interface Section {
  channelId: number;
  sectionId: number;
  sectionKey: SectionType;
  title: string;
  position: number;
  customSettings: string;
  disableVideoProgression: boolean;
  isActive?: boolean;
}

interface SectionCustomSettings {
  showAnchor: boolean;
}
/**
 * Wrapper for Section Management
 */
const DCLXSections = () => {
  const notify = useNotify();
  const channels = useProgressionChannels();
  const channel = useCMSChannel()!;
  const languages = useLocalizedLanguages(channels);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [editSection, setEditSection] = useState<Section | null>(null);
  const [topicSection, setTopicSection] = useState<Section | null>(null);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const fetch = async () => {
    const sectionsData: Section[] = (await client.get('channels/sections')).data;

    const channelSections = sectionsData.find((s: Section) => s.channelId === channel.channelId)
      ? sectionsData.filter((s: Section) => s.channelId === channel.channelId)
      : sectionsData
          .filter((s: Section) => s.channelId === 0)
          .map((s: Section) => {
            let nSection = s;
            nSection.channelId = channel.channelId;
            return nSection;
          });

    setSections(channelSections.sort((a, b) => a.position - b.position));
    setTopicSection(channelSections.find((s) => s.sectionKey === 'topic') || null);
  };

  const onDragEnd = (result: any) => {
    if (sections) {
      const newSections = handleDrop(result, sections);
      if (newSections) {
        newSections.forEach((section, index) => {
          section.position = index;
        });
        setSections(newSections);
      }
    }
  };
  const handleEdit = (section: Section) => {
    setEditSection(section);
  };
  const finishEditSection = async (values: any) => {
    const s = editSection;
    setEditSection(null);
    if (s) {
      try {
        s.title = JSON.stringify(values.title);
        s.disableVideoProgression = values.disableVideoProgression;
        s.customSettings = JSON.stringify({
          useTimezone: values.useTimezone,
        });
        s.isActive = values.isActive;
        await client.put(`channels/section`, s);
        fetch();
      } catch (e) {
        notify(strings.someError, 'error');
      }
    }
  };
  const submitConfig = async () => {
    await client.put('channels/sections', sections);
    notify(strings.orderSuccessfullyChanged, 'success');
  };

  const getShowAnchor = (section: Section) => {
    try {
      return JSON.parse(section.customSettings).showAnchor;
    } catch {}
    return true;
  };

  const getInitialValues = () => {
    let customSettings = undefined;
    if (editSection?.customSettings) {
      try {
        customSettings = JSON.parse(editSection?.customSettings);
      } catch (error) {}
    }
    return {
      title: editSection?.title || '',
      disableVideoProgression: editSection?.disableVideoProgression || false,
      useTimezone: customSettings?.useTimezone || false,
      isActive: editSection?.isActive || false,
    };
  };

  return (
    <>
      <div>
        {/* Section editing */}

        <TableContainer style={{ marginTop: '48px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.sections}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text as="h3" variant="order3">
                    {strings.title}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={DroppableComponent(onDragEnd)}>
              {sections &&
                sections.map((section: Section, index: number) => (
                  <TableRow
                    component={DraggableComponent(section.sectionKey, index)}
                    key={section.sectionKey}
                  >
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {strings[`section_${section.sectionKey}`]}
                      </Text>
                    </TableCell>
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {getLocalizedValue(section.title) ||
                          strings[`section_${section.sectionKey}`]}
                      </Text>
                    </TableCell>
                    <TableCell center>
                      <Text as="p" variant="copy1">
                        {section.isActive ? strings.adapted : strings.unadapted}
                      </Text>
                    </TableCell>
                    <TableCell center buttons>
                      <IconButton
                        style={{ color: 'black' }}
                        onClick={() => {
                          handleEdit(section);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <LabelButton variant="secondary" onClick={submitConfig}>
          {strings.confirmNewOrder}
        </LabelButton>
        {topicSection && (
          <>
            <AudiSpacer spaceStackEnd="l" />
            <Toggle
              inputId={`topicAnchorToggle`}
              onChange={async () => {
                try {
                  const customSettings: SectionCustomSettings = JSON.parse(
                    topicSection.customSettings
                  );
                  customSettings.showAnchor = !customSettings.showAnchor;
                  topicSection.customSettings = JSON.stringify(customSettings);
                  await client.put('channels/sections', sections);
                  fetch();
                } catch {
                  //turn on per default if JSON not available.
                  const customSettings: SectionCustomSettings = { showAnchor: true };
                  topicSection.customSettings = JSON.stringify(customSettings);
                }
              }}
              on={getShowAnchor(topicSection)}
            >
              <Text>{strings.enableTopicAnchor}</Text>
            </Toggle>
          </>
        )}

        {editSection && (
          <EditDialog
            maxWidth="lg"
            title={`${strings.edit} ${strings.title}`}
            close={() => setEditSection(null)}
          >
            <Formik initialValues={getInitialValues()} onSubmit={finishEditSection}>
              {({ values, errors, touched, setFieldValue }) => (
                <LocalizedForm
                  languages={languages}
                  localizeInputs={['title']}
                  initialValues={{ title: editSection.title || '' }}
                  autoParse={'all'}
                >
                  <TextField name={'title'} label={strings.title} />
                  {editSection.sectionKey === 'onDemand' && (
                    <LayoutItem spaceStackStart="m" spaceStackEnd="m">
                      <FormikCheckbox
                        name="disableVideoProgression"
                        label={strings.disableVideoProgression}
                      />
                    </LayoutItem>
                  )}
                  {editSection.sectionKey === 'agenda' && (
                    <LayoutItem spaceStackStart="m" spaceStackEnd="m">
                      <Select
                        name="useTimezone"
                        label={values.useTimezone ? 'Fixed Timezone' : 'User Timezone'}
                        inputId={'useTimezone'}
                        value={values.useTimezone}
                        onChange={(e) => {
                          setFieldValue('useTimezone', e.target.value);
                        }}
                      >
                        {values.useTimezone && (
                          <option value={undefined} label="User Timezone"></option>
                        )}
                        <option value={0}>UTC+00</option>
                        <option value={1}>UTC+01</option>
                        <option value={2}>UTC+02</option>
                      </Select>
                    </LayoutItem>
                  )}
                  <LayoutItem spaceStackStart="m" spaceStackEnd="m">
                    <FormikCheckbox name="isActive" label={strings.adapted} />
                  </LayoutItem>
                  <LabelButton variant="secondary" type="submit">
                    {strings.submit}
                  </LabelButton>
                </LocalizedForm>
              )}
            </Formik>
          </EditDialog>
        )}
      </div>
    </>
  );
};

export default DCLXSections;
