import { keepAliveCommand } from '../../../utils/convert';
import { useEffect, useMemo } from 'react';
import { getWebsocket } from '../../../ApiHandler/websocket';
import { customCommand, helloCommand, subscribeCommand } from '../../FE/LOT/lotCommands';
import { CustomCommandValue, GeneralCommand } from '../../FE/LOT/WebsocketCommands';
import { useEventSettings } from '../../../utils/hooks';
import { useQuestionState } from './QuestionState';

let keepAliveInterval: ReturnType<typeof setInterval> | null = null;
let checkForConnection: ReturnType<typeof setInterval> | null = null;
let websocket: WebSocket | null = null;
let websocketCurrentlyBuilding: boolean = false;

/** Specific websocket connection for Live AMAs */
export const QuestionWebsocket = (props: { sessionId: number }) => {
  const event = useEventSettings()!;
  const room = useMemo(() => `${event.tenantId}-${props.sessionId}`, [props.sessionId, event]);
  const { wsNext, wsSetLast } = useQuestionState();

  const keepAlive = () => {
    keepAliveInterval = setInterval(() => {
      websocketSend(keepAliveCommand(`conf-${room}`));
    }, 60000);
    return () => {
      if (keepAliveInterval) clearInterval(keepAliveInterval);
    };
  };
  const checkWebsocket = () => {
    checkForConnection = setInterval(async () => {
      if (!isWebsocketOpen()) {
        await buildWebsocket();
      }
    }, 2000);
    return () => {
      if (checkForConnection) clearInterval(checkForConnection);
    };
  };

  const closeWebsocket = () => {
    return () => {
      websocketCurrentlyBuilding = false;
      if (websocket) {
        websocket.close();
        websocket = null;
      }
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(keepAlive, [room]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkWebsocket, [room]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(closeWebsocket, [room]);
  useEffect(() => {
    if (!wsNext) return;
    websocketSend(customCommand(room, wsNext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsNext, room]);

  const isWebsocketOpen = (): boolean => {
    return !!websocket && websocket?.readyState === websocket?.OPEN;
  };

  const websocketSend = (command: string) => {
    if (isWebsocketOpen()) {
      websocket?.send(command);
    } else {
      buildWebsocket().then(() => {
        setTimeout(() => {
          websocket?.send(command);
        }, 1000);
      });
    }
  };

  const handleCustomMsg = (msg: CustomCommandValue) => {
    if (msg.name === 'refreshAmaDir') {
      wsSetLast(msg);
    }
  };

  const buildWebsocket = async () => {
    if (websocketCurrentlyBuilding) return;
    websocketCurrentlyBuilding = true;
    const ws = await getWebsocket();
    ws.onopen = () => {
      ws.send(subscribeCommand(room));
      ws.send(helloCommand(room));
      websocketCurrentlyBuilding = false;
    };
    ws.onmessage = (m) => {
      const parsed: GeneralCommand = JSON.parse(m.data);
      // Ignore messages sent in other channels. This should rarely happen but better be safe.
      if (parsed.ChannelId !== `conf-${room}`) return;
      if ('CommandKey' in parsed) {
        switch (parsed.CommandKey) {
          case 'notify':
            handleCustomMsg(parsed.CommandValue);
        }
      }
    };
    websocket = ws;
  };
  return null;
};
