import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { GetAma, Session, SessionType } from '../../../ApiHandler/dclxInterfaces';
import { client } from '../../../ApiHandler/client';
import { Select } from '@audi/audi-ui-react';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { useLoad, useCMSChannel } from '../../../utils/hooks';
import { makeStyles } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';
import { AmaOverview } from './AmaOverview';
import SimpleVideoPlayer from '../../../utils/SimpleVideoPlayer';

const useStyles = makeStyles({
  container: {
    width: '30%',
  },
  box: {
    display: 'flex',
    backgroundColor: '#EEE',
    padding: 32,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
    width: '100vw',
  },
});

/** allows to select various filters for the AMA List */
export const AmaWrapper = () => {
  const cls = useStyles();
  const currentChannelId = useCMSChannel()!.channelId;
  const [currentSessionId, setCurrentSessionId] = useState<string>('');
  const [currentSession, setCurrentSession] = useState<Session | null>(null);
  const [sessionsLoad] = useLoad<Session[]>('sessions');
  const [amas, setAmas] = useState<GetAma[]>([]);
  const sessions = useMemo(() => sessionsLoad?.filter((s) => s.sessionType === SessionType.DEMAND), [sessionsLoad]);
  useEffect(() => {
    const fetchAmas = async () => {
      if (!currentSessionId) return;
      // latest first
      setAmas(
        (await client.get<GetAma[]>(`sessions/${currentSessionId}/vodAmas`)).data.sort(
          (a, b) => b.supportQuestionId - a.supportQuestionId
        )
      );
    };

    const interval = setInterval(async () => {
      await fetchAmas();
    }, 30000);

    fetchAmas();
    return () => clearInterval(interval);
  }, [currentSessionId]);

  useEffect(() => {
    const fetchSession = async () => {
      if (currentSessionId) {
        setCurrentSession((await client.get<Session>(`sessions/${currentSessionId}`)).data);
      } else {
        setCurrentSession(null);
      }
    };
    fetchSession();
  }, [currentSessionId]);

  const switchSession = async (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    setCurrentSessionId(id);
  };

  return (
    <div>
      <div className={cls.box}>
        <div style={{ position: 'absolute', top: 32, left: 32 }}>
          <Text variant="order2" weight="bold">
            {strings.cmsMenuAma}
          </Text>
        </div>
        {currentChannelId && (
          <div className={cls.container}>
            <Select
              value={currentSessionId}
              onChange={switchSession}
              inputId="sessionSelect"
              label={strings.selectSession}
            >
              {sessions
                ?.filter((s) => s.channelId === currentChannelId)
                .map((s) => (
                  <option key={s.sessionId} value={s.sessionId.toString()}>
                    {getLocalizedValue(s.title)} ({s.sessionId})
                  </option>
                ))}
            </Select>
          </div>
        )}

        <div className={cls.container}>
          <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              {currentSession && <SimpleVideoPlayer url={currentSession.video?.url || ''} />}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          maxWidth: 2000,
          padding: 48,
        }}
      >
        <AmaOverview amas={amas} />
      </div>
    </div>
  );
};
