import { Link, Breadcrumbs as MuiBc } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Text } from '@pp-labs/ui-components';
import strings from '../Localization/Localizer';
import React from 'react';
import { useEventSettings } from './hooks';

/** interface for Breadcrumbs props coming from parent component EventWatch */
interface P {
  title: string;
}

/** Breadcrumbs for easier site navigation within a session */
export const Breadcrumbs = (props: P) => {
  const event = useEventSettings();
  return (
    <MuiBc separator="›" aria-label="breadcrumb">
      <Link component={NavLink} to={`/${event?.identifier}`} className="breadcrumb">
        <Text as="span" variant="copy2">
          {event?.title}
        </Text>
      </Link>
      <Link component={NavLink} to={`/${event?.identifier}/events`} className="breadcrumb">
        <Text as="span" variant="copy2">
          {strings.menuEvents}
        </Text>
      </Link>
      <Text as="span" variant="copy2">
        {props.title}
      </Text>
    </MuiBc>
  );
};
