import { Paper, Tab, Tabs } from '@material-ui/core';
import './AudiTabs/AudiTabs.scss';
import { Text } from '@audi/audi-ui-react';
import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

interface TabPanelProps {
  index: any;
  value: any;
  special?: boolean;
}

/**
 * Multi Tab Panel
 */

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, special, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lot-tabpanel-${index}`}
      aria-labelledby={`lot-tab-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const usesStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  blackRoot: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  transparentRoot: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  whiteText: {
    color: 'white',
  },
});

interface P {
  tabLabels: Array<string>;
  tabContents: Array<any>;
  /** related to the override class */
  tabType: 'black-tabs' | 'black-no-bg-tabs' | 'grey-tabs' | 'no-bg-tabs';
  start?: number;
}

export const GeneralTab = (props: P) => {
  const cls = usesStyles();
  const [value, setValue] = useState<number>(props.start || 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);

  const bgColor = (index: number) => {
    if (props.tabType === 'black-tabs') return 'black';
    if (value !== index) return 'transparent';
    if (props.tabType === 'no-bg-tabs') return 'transparent';
    return props.tabType === 'grey-tabs' ? '#E5E5E5' : 'black';
  };

  return (
    <div
      className={clsx(
        props.tabType,
        props.tabType !== 'no-bg-tabs' ? cls.blackRoot : cls.transparentRoot
      )}
    >
      <Paper square elevation={0} style={{ backgroundColor: 'transparent' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={'location tab'}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: { backgroundColor: props.tabType === 'no-bg-tabs' ? 'black' : 'transparent' },
          }}
        >
          {props.tabLabels
            .filter((l) => l.length)
            .map((l, index) => (
              <Tab
                key={index}
                label={
                  <Text
                    as="h4"
                    variant="copy1"
                    className={clsx(
                      (props.tabType === 'black-tabs' ||
                        (props.tabType === 'black-no-bg-tabs' && value === index)) &&
                        cls.whiteText
                    )}
                  >
                    {l}
                  </Text>
                }
                style={{
                  backgroundColor: bgColor(index),
                }}
                id={`audi-tab-${index}`}
              />
            ))}
        </Tabs>
      </Paper>
      <>
        {props.tabContents.map((c, index) => (
          <TabPanel key={index} value={value} index={index}>
            {c}
          </TabPanel>
        ))}
      </>
    </div>
  );
};
