import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Text, useSetTheme } from '@pp-labs/ui-components';
import { SubNav } from './SubNav';
import { DownloadUserResults } from './UsersResults';
import { Route, Switch, useParams } from 'react-router-dom';
import { useSetEventTheme, useUser } from '../../utils/hooks';
import { UserDiagrams } from './UsersDiagrams';
import { ViewsDiagrams } from './ViewsDiagrams';
import { ResultsDiagrams } from './ResultsDiagrams';
import { DashboardAccess, doesRoleMatch, Role } from 'utils/RoleDefinitions';
import { DashboardFilter } from './DashboardFilter';
import { FilterParams } from './useDashboardData';

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
const showRest: boolean = true;
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '100vw',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'minmax(200px, auto)',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

/** Landing for the KPI Dashboard, handles filters and routing */
const Dashboard = () => {
  const setTheme = useSetTheme();
  const setEventTheme = useSetEventTheme();
  const { category } = useParams<{
    category: 'participants' | 'views' | 'results';
  }>();
  const user = useUser();
  const classes = useStyles();
  const [filters, setFilters] = useState<FilterParams>({
    channels: [1, 2],
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    setTheme({
      name: 'audi',
    });
    return () => {
      setEventTheme();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permissions = useMemo(() => {
    const hasDashboard = doesRoleMatch(user, DashboardAccess);
    return {
      canSeeDiagrams: hasDashboard && !doesRoleMatch(user, Role.SUPPORTSUPERVISOR),
      canDownload: hasDashboard && !doesRoleMatch(user, Role.TRAINER),
    };
  }, [user]);

  return (
    <div style={{ width: '100vw' }}>
      <SubNav activeTab={category} />
      {showRest && (
        <div className={classes.container}>
          <div
            style={{
              backgroundColor: '#E5E5E5',
              padding: '64px',
              gridColumn: '1 / span 12',
            }}
          >
            <Text as="h2" variant="order1">
              {capitalize(category)}
            </Text>

            <DashboardFilter category={category} onSubmit={setFilters} />
          </div>
        </div>
      )}
      <Switch>
        <Route key="participants" path="/:event/dashboard/participants">
          {permissions.canSeeDiagrams && <UserDiagrams filters={filters} />}
          {permissions.canDownload && <DownloadUserResults filters={filters} showDownload={true} />}
        </Route>
        <Route key="views" path="/:event/dashboard/views">
          {permissions.canSeeDiagrams && <ViewsDiagrams filters={filters} />}
        </Route>
        <Route key="results" path="/:event/dashboard/results">
          {permissions.canSeeDiagrams && <ResultsDiagrams filters={filters} />}
        </Route>
      </Switch>
    </div>
  );
};
export { Dashboard };
