import { isIe } from '@pp-labs/ui-components';
import { BadBrowser } from './BadBrowser';
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { gutterStyle } from '../../utils/gutter';

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
}));

/** Rejects requests from Internet Explorer with a warning page */
export const BadBrowserGuard: React.FC = (props) => {
  const cls = useStyles();
  if (!isIe()) return <>{props.children}</>;
  return (
    <Grid item xs={12} md={8} lg={6} className={cls.gutterAll}>
      <BadBrowser />
    </Grid>
  );
};
