import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { confirmPasswordValidator, newPasswordValidator } from '../utils/validator';
import strings from '../Localization/Localizer';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import { changePassword } from '../ApiHandler/Cognito';
import { useNotify } from '../utils/hooks';
import { getErrorMessage } from './utils';
import { PasswordVerifier } from './PasswordVerifier';

const validation = () =>
  Yup.object({
    oldPassword: Yup.string().required(strings.required),
    newPassword: newPasswordValidator(),
    confirmPassword: confirmPasswordValidator(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof validation>>;

/**
 * Allows users to change their password
 */
const ChangePasswordForm = () => {
  const notify = useNotify();
  const initialValues: F = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const submit = async (values: F) => {
    if (values.newPassword !== values.confirmPassword) return;
    try {
      await changePassword(values.oldPassword, values.newPassword);
      notify(strings.passwordChanged, 'success');
    } catch (err: any) {
      if (err.code === 'NotAuthorizedException') {
        notify(getErrorMessage(101, strings.oldPasswordWrong), 'error');
      }
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validation()} onSubmit={submit}>
        {({ values, errors, touched }) => (
          <Form>
            <TextField
              name="oldPassword"
              label={strings.oldPassword}
              type="password"
              error={touched.oldPassword ? errors.oldPassword : ''}
              autoComplete="current-password"
            />
            <PasswordVerifier password={values.newPassword} />
            <TextField
              name="newPassword"
              label={strings.newPassword}
              type="password"
              error={touched.newPassword ? errors.newPassword : ''}
              autoComplete="new-password"
            />
            <div style={{ padding: '10px 0' }} />{' '}
            <TextField
              name="confirmPassword"
              label={strings.confirmPassword}
              type="password"
              error={touched.confirmPassword ? errors.confirmPassword : ''}
              autoComplete="new-password"
            />
            <div style={{ padding: '20px 0' }} />
            <LabelButton variant="secondary" type="submit">
              {strings.changePassword}
            </LabelButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePasswordForm;
