import {
  Awards,
  Channel,
  ChannelTopic,
  CognitoUser,
  ConfigModuleIdentifiers,
  GetMySchedule,
  GetTest,
  PlatformEvent,
  PostMySchedule,
  Session,
  SessionType,
  Stat,
  TimeSlot,
} from './ApiHandler/dclxInterfaces';
import * as _ from 'lodash';
import { isSessionDone } from './utils/convert';
import { doesRoleMatch, Role } from './utils/RoleDefinitions';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  loadChannels,
  loadExams,
  loadExamStats,
  loadSchedule,
  loadSessions,
  loadSurveyTemplates,
  loadTimeslots,
  loadTopics,
  loadUserSurveys,
} from './ApiHandler/api';
import { isUsingConfigModule, useEventSettings, useRealTime, useUser } from './utils/hooks';
import { GetSurveyTemplate, GetUserSurvey } from '@pp-labs/survey';
import { client } from './ApiHandler/client';
import { stateContext } from './features/StateProvider/StateProvider';
import { considerLotsInProgression } from './config';

type TestWithTopic = GetTest & { topic?: ChannelTopic };

type BlockTests = {
  [key: string]: TestWithTopic[];
};

type BlockTopics = {
  [key: string]: ChannelTopic[];
};

type CombinedBlock = {
  [key: string]: (ChannelTopic | TestWithTopic)[];
};

const blockTests = (tests: GetTest[], topics: ChannelTopic[]): BlockTests => {
  const withTopic: TestWithTopic[] = tests.map((t) => ({
    ...t,
    topic: topics.find((to) => to.topicId === t.topicId),
  }));
  const blocks: BlockTests = _.groupBy(withTopic, (t: TestWithTopic) => t.topic?.block || '');
  return _.mapValues(blocks, (v: TestWithTopic[]) =>
    v.sort((a, b) => {
      if (a.topicId === b.topicId) return a.examType === 'pre' ? -1 : 1;
      return (a.topic?.startAt || 0) - (b.topic?.startAt || 0);
    })
  );
};

const blockTopics = (topics: ChannelTopic[]): BlockTopics =>
  _.groupBy(
    topics.sort((a, b) => (a?.startAt || 0) - (b?.startAt || 0)),
    (t: ChannelTopic) => t.block || ''
  );

const comp = (
  topic: ChannelTopic | undefined,
  test: TestWithTopic | undefined,
  topics: ChannelTopic[],
  topicIndex: number
): 'test' | 'topic' => {
  if (!topic) return 'test';
  if (!test) return 'topic';
  const testTopicIndex = topics.findIndex((t) => t.topicId === test.topicId);
  if (testTopicIndex < topicIndex) {
    return 'test';
  } else if (testTopicIndex > topicIndex) {
    return 'topic';
  } else {
    return test.examType === 'pre' ? 'test' : 'topic';
  }
};

const mergeBlocks = (blockTopicsValue: BlockTopics, blockTestsValue: BlockTests) => {
  const obj: CombinedBlock = {};
  Object.entries(blockTopicsValue).forEach((e) => {
    const [block, topics] = e;
    const tests = blockTestsValue[block];
    const res: (ChannelTopic | TestWithTopic)[] = [];
    let topicIndex = 0;
    let testIndex = 0;
    while (true) {
      const topic = topics?.[topicIndex];
      const test = tests?.[testIndex];
      if (!topic && !test) break;
      const c = comp(topic, test, topics, topicIndex);
      if (c === 'test') {
        res.push(test);
        testIndex++;
      } else {
        res.push(topic);
        topicIndex++;
      }
    }
    obj[block] = res;
  });
  return obj;
};
type Progression = { unlocked: true } | { unlocked: false; reason: 'date' | 'prev' };
export type TestProgression = TestWithTopic & {
  progression: Progression;
  disableVideoProgression?: boolean;
};
export type TopicProgression = ChannelTopic & {
  progression: Progression;
  disableVideoProgression?: boolean;
};
type WithProgression = TestProgression | TopicProgression;
export type BlockProgression = {
  [key: string]: WithProgression[];
};

export type Info = {
  exams: GetTest[];
  solvedExams: number[];
  sessions: Session[];
  stats: Stat[];
  topics: ChannelTopic[];
  timeSlots: TimeSlot[];
  surveys?: GetSurveyTemplate[];
  mySurveys?: GetUserSurvey[];
  mySchedule: GetMySchedule[];
  channels: Channel[];
  loaded: number;
  awards: Awards | null;
};

const getProgression = (
  prevElement: WithProgression | undefined,
  e: ChannelTopic | TestWithTopic,
  now: number,
  info: Info,
  introMissing: boolean
): Progression => {
  if (introMissing) return { unlocked: false, reason: 'prev' };
  // If there is a previous element and it is not unlocked or not done
  if (prevElement && !(prevElement.progression.unlocked && isDone(prevElement, info)))
    return { unlocked: false, reason: 'prev' };
  // If the relevant date is not reached
  if ('examId' in e) {
    if (now < e.unlockAt * 1000) return { unlocked: false, reason: 'date' };
  } else {
    if (now < e.startAt) return { unlocked: false, reason: 'date' };
  }
  return { unlocked: true };
};

const isAnySessionDone = (session: Session, info: Info) => {
  if (session.sessionType === 'training')
    return (
      !considerLotsInProgression ||
      info.timeSlots.some((ts) => ts.attended && ts.sessionId === session.sessionId)
    );
  return isSessionDone(session, info.stats);
};

/** Determines the award stuff that the frontend is responsible for.
 * Backend handles exams, score, usedTime.
 * */
export const getBadges = (info: Info) => {
  let total = 0;
  let done = 0;
  const topics: number[] = [];
  info.topics.forEach((topic) => {
    const sessions = info.sessions.filter((s) => s.topicIds.includes(topic.topicId));
    // an empty topic is not worth a badge
    let wholeTopic = !!sessions.length;
    sessions.forEach((session) => {
      total++;
      const sessionDone = isAnySessionDone(session, info);
      if (sessionDone) {
        done++;
      } else {
        wholeTopic = false;
      }
    });
    if (wholeTopic) topics.push(topic.topicId);
  });
  return {
    progress: {
      done: done,
      total: total,
    },
    badges: {
      topics: topics,
    },
  };
};

export const createUnlockMatrix = (
  info: Info,
  now: number,
  eventSettings: PlatformEvent
): BlockProgression => {
  const bTopics = blockTopics(info.topics);
  const bTests = blockTests(info.exams, info.topics);
  const merged = mergeBlocks(bTopics, bTests);
  const withoutTopic = info.sessions.filter((s) => !s.topicIds.length);
  const introMissing =
    isUsingConfigModule(eventSettings, ConfigModuleIdentifiers.introRequired) &&
    withoutTopic.some((s) => !isSessionDone(s, info.stats));
  return _.mapValues(merged, (v: (ChannelTopic | TestWithTopic)[]) => {
    let prevElement: WithProgression | undefined = undefined;
    return v.map((e) => {
      const withProgression = {
        ...e,
        progression: getProgression(prevElement, e, now, info, introMissing),
      };
      // previous element updated only if VOD progression is enabled or if the element is a test
      if (
        !e.hasOwnProperty('disableVideoProgression') ||
        !(e as ChannelTopic).disableVideoProgression
      ) {
        prevElement = withProgression;
      }
      return withProgression;
    });
  });
};
const isDone = (e: GetTest | ChannelTopic, info: Info) =>
  'examId' in e ? isTestDone(e, info) : isTopicDone(e, info);
const isTestDone = (test: GetTest, info: Info) => info.solvedExams.includes(test.examId);
const isTopicDone = (topic: ChannelTopic, info: Info) => {
  return info.sessions
    .filter((s) => s.topicIds.includes(topic.topicId) && s.sessionType === SessionType.DEMAND)
    .every((s) => isAnySessionDone(s, info));
};

export const followProgression = (user: CognitoUser) =>
  doesRoleMatch(user, [Role.VISITOR, Role.TRAINER]);

export type ProgressionReturn = {
  info: Info;
  refresh: () => void;
  matrix: BlockProgression;
  isSessionUnlocked: (session: Session) => SessionProgression;
  isExamUnlocked: (exam: GetTest) => ExamProgression;
  /** now in SECONDS */
  now: number;
  schedule: {
    addToSchedule: (session: Session) => Promise<void>;
    removeFromSchedule: (session: Session) => Promise<void>;
    isInSchedule: (session: Session) => boolean;
  };
  refreshBadges: () => Promise<void>;
};
export type UseProgressionReturn = ProgressionReturn | 'loading';

type SessionProgression =
  | { unlocked: true }
  | { unlocked: false; reason: 'unknown' | 'topicLocked' }
  | { unlocked: false; reason: 'prev'; id: number };

type ExamProgression = { unlocked: false; reason: 'unknown' } | Progression;
type Options = {
  channelId?: number;
  passedInfo?: Info;
  refreshInterval?: number;
};

/** Provides all the necessary information for the page, allows to calculate whether a session is unlocked and so on. */
export const useInnerProgression = (options?: Options): UseProgressionReturn => {
  const now = useRealTime(options?.refreshInterval || 10000);
  const event = useEventSettings();
  const user = useUser();
  const [info, setInfo] = useState<Info | undefined>(undefined);

  const loadInfo = useCallback(async () => {
    const byChannelId = (e: { channelId?: number }) =>
      !options?.channelId || e.channelId === options?.channelId;
    const loadedInfo: Info = {
      exams: (await loadExams()).filter(byChannelId),
      sessions: (await loadSessions()).filter(byChannelId),
      solvedExams: await loadExamStats(),
      stats: [],
      topics: (await loadTopics()).filter(byChannelId),
      timeSlots: await loadTimeslots(),
      surveys: await loadSurveyTemplates(),
      mySurveys: await loadUserSurveys(),
      mySchedule: await loadSchedule(),
      channels: await loadChannels(),
      loaded: Date.now(),
      awards: null,
    };
    setInfo(loadedInfo);
  }, [options?.channelId]);

  useEffect(() => {
    if (options?.passedInfo) {
      setInfo(options?.passedInfo);
    } else {
      if (user) {
        loadInfo();
      } else {
        setInfo(undefined);
      }
    }
  }, [options?.passedInfo, loadInfo, user]);

  const blockProgressionMatrix = useMemo(
    () => (info && event ? createUnlockMatrix(info, now, event) : undefined),
    [info, now, event]
  );

  const isSessionUnlocked = useCallback(
    (session: Session): SessionProgression => {
      if (!user || !followProgression(user)) return { unlocked: true };
      if (!info || !blockProgressionMatrix) return { reason: 'unknown', unlocked: false };
      // Sessions outside topics are always unlocked
      if (!session.topicIds.length) return { unlocked: true };
      const topic = info.topics.find((t) => session.topicIds[0] === t.topicId);
      if (!topic) return { reason: 'unknown', unlocked: false };
      const progression = blockProgressionMatrix[topic.block || 'A'].find(
        (o) => !('examId' in o) && o.topicId === topic.topicId
      );
      if (!progression) return { reason: 'unknown', unlocked: false };

      if (!progression.progression.unlocked) return { reason: 'topicLocked', unlocked: false };
      const sessionsForTopic = info.sessions.filter((s) => s.topicIds.includes(topic.topicId));
      const lockMatrix = generateLockMatrix(
        sessionsForTopic,
        info.stats,
        false,
        topic.disableVideoProgression
      );
      const entry = lockMatrix.find((s) => s.sessionId === session.sessionId);
      return entry?.locked
        ? { reason: 'prev', unlocked: false, id: entry?.failedAt }
        : { unlocked: true };
    },
    [info, user, blockProgressionMatrix]
  );

  const isExamUnlocked = useCallback(
    (exam: GetTest): ExamProgression => {
      if (!user || !followProgression(user)) return { unlocked: true };
      if (!info || !blockProgressionMatrix) return { reason: 'unknown', unlocked: false };
      const topic = info.topics.find((t) => exam.topicId === t.topicId);
      if (!topic) return { reason: 'unknown', unlocked: false };
      const thisExam = blockProgressionMatrix[topic.block || 'A'].find(
        (e) => 'examId' in e && e.examId === exam.examId
      );
      if (!thisExam) return { reason: 'unknown', unlocked: false };
      return (thisExam as TestProgression).progression;
    },
    [info, user, blockProgressionMatrix]
  );

  const refreshSchedule = useCallback(async () => {
    if (!info) return;
    const schedule = await loadSchedule();
    const i: Info = { ...info, mySchedule: schedule };
    setInfo(i);
  }, [info]);

  const refreshBadges = useCallback(async () => {
    if (!info || !info.awards) return;
    const badges = getBadges(info);
    let change = false;
    if (
      badges.progress.total !== info.awards.progress.total ||
      badges.progress.done !== info.awards.progress.done
    ) {
      await client.put(`awards/progress`, {
        total: badges.progress.total,
        done: badges.progress.done,
      });
      change = true;
    }

    // Note that we do not revoke already granted topic badges.
    const notIncluded = badges.badges.topics.filter(
      (t) => !info.awards?.badges.topics?.includes(t)
    );
    if (notIncluded.length) {
      await Promise.all(notIncluded.map((m) => client.post(`topics/${m}/stats`)));
      change = true;
    }
    if (change) await loadInfo();
  }, [info, loadInfo]);

  const addToSchedule = useCallback(
    async (session: Session) => {
      const data: PostMySchedule = {
        myScheduleType: 'session',
        mediaId: session.sessionId.toString(),
      };
      await client.post('mySchedule', data);
      await refreshSchedule();
    },
    [refreshSchedule]
  );

  const removeFromSchedule = useCallback(
    async (session: Session) => {
      const schedule = info?.mySchedule.find((s) => Number(s.mediaId) === session.sessionId);
      if (!schedule) return;
      await client.delete(`mySchedule/${schedule.myScheduleId}`);
      await refreshSchedule();
    },
    [refreshSchedule, info]
  );

  const isInSchedule = useCallback(
    (session: Session) => {
      return !!info?.mySchedule.some((s) => Number(s.mediaId) === session.sessionId);
    },
    [info]
  );

  if (info && blockProgressionMatrix) {
    return {
      info: info,
      refresh: loadInfo,
      matrix: blockProgressionMatrix,
      isSessionUnlocked: isSessionUnlocked,
      isExamUnlocked: isExamUnlocked,
      now: now / 1000,
      schedule: {
        addToSchedule: addToSchedule,
        removeFromSchedule: removeFromSchedule,
        isInSchedule: isInSchedule,
      },
      refreshBadges: refreshBadges,
    };
  } else {
    return 'loading';
  }
};

interface LockMatrix {
  sessionId: number;
  locked: boolean;
  failedAt: number;
}

export const generateLockMatrix = (
  sessions: Session[],
  stats: Stat[],
  firstLocked: boolean,
  disableVideoProgression: boolean
): LockMatrix[] => {
  /** Unlock the first one if:
   * the user made the preTest (if it is available) and has
   * either completed the entire previous topic or there is no unlock Required for this topic
   */
  let nextLocked = firstLocked;
  let failedAt = 0;

  return sessions
    .filter((session) => session.sessionType === SessionType.DEMAND)
    .map((session) => {
      /* Lock this one if an unlock is required and there are no stats for this session. */
      const currentLocked = nextLocked;
      const done = disableVideoProgression || isSessionDone(session, stats);
      nextLocked = currentLocked || !done;
      if (!failedAt && !done) failedAt = session.sessionId;
      return {
        sessionId: session.sessionId,
        locked: !disableVideoProgression && currentLocked,
        failedAt: failedAt,
      };
    });
};

export const useProgression = () => useContext(stateContext).progression;
