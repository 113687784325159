import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FormGroup, LabelButton, RadioButton, Text } from '@pp-labs/ui-components';
import * as Yup from 'yup';
import axios from 'axios';
import strings from '../../Localization/Localizer';
import { conferenceTestUrl, techTestFiles } from '../../config';
import { getBrowser } from '@pp-labs/ui-components';
import { getUser } from '../../ApiHandler/Cognito';
import { getMarket } from '../../utils/convert';
import { SupportEmail } from '../../auth/SupportEmail';
import { languageString } from './getLangString';

/** interface for VideoForm props coming from parent component TechTest */
interface P {
  onFinish: Function;
}

/**
 * Submit the result of the Tech Test for Jitsi/LOT Technology, get help if needed
 */

const VideoForm = (props: P) => {
  // 0 - default, 1 - sending, 2 - sent (success), 3 - sent (no success)
  const [formState, setFormState] = useState(0);
  const validation = Yup.object({
    success: Yup.string().required(),
  }).required('Required');

  const onSubmitForm = async (formData: any, actions: any) => {
    setFormState(1);

    const browser = getBrowser();

    formData.success = formData.success === 'true';
    if (browser) formData.browser = `#${browser.getBrowserName()} (${browser.getBrowserVersion()})`;
    const user = await getUser();
    formData.email = user.email;
    formData.market = getMarket(user);

    const headers = {
      crossDomain: true,
      'Content-Type': 'application/json',
    };
    axios
      .post(conferenceTestUrl, formData, {
        headers,
      })
      .then(function () {
        setFormState(formData?.success === true ? 2 : 3);
      })
      .catch(function (error) {
        window.alert(error.message);
        setFormState(0);
      });
    actions.setSubmitting(false);
  };

  if (formState > 1) {
    props.onFinish();
  }

  const pdfFile = techTestFiles(languageString());

  return (
    <div>
      <Formik validationSchema={validation} onSubmit={onSubmitForm} initialValues={{ success: '' }}>
        {({ values, isSubmitting }) => {
          return (
            <Form noValidate>
              <FormGroup>
                <FormGroup>
                  <RadioButton inputId="success_1" name="success" value={'true'}>
                    <Text>{strings.techTestVideoSuccess}</Text>
                  </RadioButton>
                  <div style={{ padding: '10px 0' }} />
                  <RadioButton inputId="success_2" name="success" value={'false'}>
                    <Text>{strings.techTestNoVideo}</Text>
                    <Text>
                      (
                      <a download href={pdfFile} target="_blank" rel="noreferrer">
                        {strings.downloadInstructions}
                      </a>
                      )
                    </Text>
                  </RadioButton>
                </FormGroup>
                <div style={{ padding: '20px 0' }} />
                {formState === 1 ? (
                  <Text as="span" weight="bold">
                    {strings.sending}
                  </Text>
                ) : (
                  <LabelButton
                    variant="primary"
                    disabled={isSubmitting || !values.success}
                    type="submit"
                  >
                    {strings.next}
                  </LabelButton>
                )}
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
      <div style={{ paddingTop: '24px' }}>
        <SupportEmail />
      </div>
    </div>
  );
};
export default VideoForm;
