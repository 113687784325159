import React, { useState } from 'react';

import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { EventSetupStepInfos } from './EventSetupStepInfos';
import { EventSetupStepMappings } from './EventSetupStepMappings';
import { EventSetupStepImages } from './EventSetupStepImages';
import { EventSetupStepModules } from './EventSetupStepModules';
import { AdminPanelSteps } from './adminPanelTypes';
import { PlatformEvent } from 'ApiHandler/dclxInterfaces';
import { ModuleDetails } from './ModuleIds';

const useStyles = makeStyles(() =>
  createStyles({
    formWrapper: {
      width: '90%',
    },
    leftBtn: {
      marginRight: '16px',
    },
  })
);

/** interface for AdminEventSetup props coming from parent component AdminPanelTable  */
interface P {
  initialData: PlatformEvent | null;
  onFinish: () => void;
  getEvents: () => void;
  currentEventId: number;
}

/** Main event setup handler used in AdminPanelTable */
const AdminEventSetup = (props: P) => {
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const [step, setStep] = useState<number>(1);
  const classes = useStyles();

  // Consist of all the common props for each step
  const commonProps: AdminPanelSteps = {
    getEvents: props.getEvents,
    onFinish: props.onFinish,
    initialData: props.initialData,
    setStep: setStep,
    values: values,
    setValues: setValues,
    class: classes.leftBtn,
    currentEventId: props.currentEventId,
  };
  return (
    <div className={classes.formWrapper}>
      <Grid container justifyContent="center" spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          {step === 1 && <EventSetupStepInfos {...commonProps} />}
          {step === 2 && <EventSetupStepModules {...commonProps} />}
          {step === 2.5 && <ModuleDetails {...commonProps} />}
          {step === 3 && <EventSetupStepImages {...commonProps} />}
          {step === 4 && <EventSetupStepMappings {...commonProps} />}
        </Grid>
      </Grid>
    </div>
  );
};

export { AdminEventSetup };
