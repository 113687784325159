import React, { useEffect, useState } from 'react';
import {
  Session,
  SessionType,
  UserPicture,
  TimeSlot,
  ChannelTopic,
} from '../../../ApiHandler/dclxInterfaces';
import TrainingsForTopic from './TrainingsForTopic';
import { GutterClass, gutterStyle } from '../../../utils/gutter';
import { makeStyles, Theme } from '@material-ui/core';
import { Role } from '../../../utils/RoleDefinitions';
import { client } from '../../../ApiHandler/client';
import { byDate, byPosition } from '../../../utils/sort';
import { useCMSChannel } from 'utils/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
}));

/** interface for CMSTrainings props coming from parent component CMS */
interface P {
  classes: GutterClass;
  manager: boolean;
}

export interface SessionTimeslot {
  session: Session;
  timeslots: TimeSlot[];
}

export interface SessionTimeslots extends Array<SessionTimeslot> {}

/**
 * Component to List Trainings
 */
export const CMSTrainings = (props: P) => {
  const cls = useStyles();
  const channel = useCMSChannel();
  const [topics, setTopics] = useState<Array<ChannelTopic>>([]);
  const [sessionTimeslots, setSessionTimeslots] = useState<SessionTimeslots | undefined>();
  const [trainers, setTrainers] = useState<Array<UserPicture> | undefined>(undefined);
  useEffect(() => {
    fetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);
  const fetch = async (initial?: boolean) => {
    const topics: Array<ChannelTopic> = (await client.get('topics')).data.filter(
      (topic: ChannelTopic) => topic.channelId === channel?.channelId
    );
    setTopics(topics);
    const sessionData: Session[] = (await client.get('sessions')).data;
    const sessions = sessionData
      .filter((s) => s.sessionType === SessionType.TRAINING && s.channelId === channel?.channelId)
      .sort(byPosition);

    const timeslotArray: TimeSlot[] = (await client.get('trainings')).data;
    timeslotArray.sort(byDate);
    const st: SessionTimeslots = [];
    for (let i = 0; i < sessions.length; i++) {
      const o: SessionTimeslot = {
        session: sessions[i],
        timeslots: timeslotArray.filter((t) => t.sessionId === sessions[i].sessionId),
      };
      st.push(o);
    }

    if (initial) {
      const trainers = (await client.get(`users/role/${Role.TRAINER}`)).data;
      const manager = (await client.get(`users/role/${Role.MANAGER}`)).data;
      setSessionTimeslots(st);
      setTrainers([...manager, ...trainers]);
    } else {
      setSessionTimeslots(st);
    }
  };
  if (!sessionTimeslots || !trainers) return null;
  return (
    <div className={cls.gutterAll}>
      <TrainingsForTopic
        refresh={fetch}
        timeslots={sessionTimeslots.filter((st) => st.session.showInChannelContent)}
        topic={null}
        manager={props.manager}
        trainers={trainers}
      />
      {topics.map((topic, index) => {
        if (!trainers || !sessionTimeslots) return null;
        return (
          <TrainingsForTopic
            key={`week-${index}`}
            refresh={fetch}
            timeslots={sessionTimeslots.filter((st) => st.session.topicIds.includes(topic.topicId))}
            topic={topic}
            manager={props.manager}
            trainers={trainers}
          />
        );
      })}
    </div>
  );
};
// class CMSTrainings2 extends Component<P, S> {
//   constructor(props: P) {
//     super(props);
//     this.state = {
//       topics: [],
//       sessionTimeslots: undefined,
//       trainers: undefined,
//     };
//     this.fetch(true);
//   }

//   fetch = async (initial?: boolean) => {
//     const topics: Array<ChannelTopic> = (await client.get('topics')).data
//     this.setState({ topics: topics });
//     const sessionData: Session[] = (await client.get('sessions')).data;
//     const sessions = sessionData
//       .filter((s) => s.sessionType === SessionType.TRAINING)
//       .sort(byPosition);

//     const timeslotArray: TimeSlot[] = (await client.get('trainings')).data;
//     timeslotArray.sort(byDate);
//     const st: SessionTimeslots = [];
//     for (let i = 0; i < sessions.length; i++) {
//       const o: SessionTimeslot = {
//         session: sessions[i],
//         timeslots: timeslotArray.filter((t) => t.sessionId === sessions[i].sessionId),
//       };
//       st.push(o);
//     }

//     if (initial) {
//       const trainers = (await client.get(`users/role/${Role.TRAINER}`)).data;
//       const manager = (await client.get(`users/role/${Role.MANAGER}`)).data;
//       this.setState({
//         sessionTimeslots: st,
//         trainers: [...trainers, ...manager],
//       });
//     } else {
//       this.setState({ sessionTimeslots: st });
//     }
//   };

//   render() {
//     if (!this.state.sessionTimeslots || !this.state.trainers) return null;
//     return (
//       <div className={this.props.classes.gutterAll}>
//         <TrainingsForTopic
//           refresh={this.fetch}
//           timeslots={this.state.sessionTimeslots.filter((st) => st.session.showInChannelContent)}
//           topic={null}
//           manager={this.props.manager}
//           trainers={this.state.trainers}
//         />
//         {this.state.topics.map((topic, index) => {
//           if (!this.state.trainers || !this.state.sessionTimeslots) return null;
//           return (
//             <TrainingsForTopic
//               key={`week-${index}`}
//               refresh={this.fetch}
//               timeslots={this.state.sessionTimeslots.filter((st) =>
//                 st.session.topicIds.includes(topic.topicId)
//               )}
//               topic={topic}
//               manager={this.props.manager}
//               trainers={this.state.trainers}
//             />
//           );
//         })}
//       </div>
//     );
//   }
// }

// export default withStyles(styles)(CMSTrainings);
