import {
  GetFirstStep,
  GetMySchedule,
  GetTest,
  Session,
  SessionType,
  StepType,
  TimeSlot,
} from '../../../ApiHandler/dclxInterfaces';
import { ProgressionReturn } from '../../../progression';
import { isSessionDone } from '../../../utils/convert';
import { GetSurveyTemplate } from '@pp-labs/survey';

export type UnbookedLot = {
  type: 'unbookedLot';
  media: Session;
  show: true;
};

export type BookedLot = {
  type: 'bookedLot';
  media: Session;
  slot: TimeSlot;
  show: boolean;
  running: boolean;
  link: string;
};

export type LiveSession = {
  type: 'liveSession';
  media: Session;
  show: boolean;
  running: boolean;
  link: string;
};

export type DemandSession = {
  type: 'demandSession';
  media: Session;
  show: boolean;
  alreadyWatched: boolean;
  link: string;
};

export type Exam = {
  type: 'exam';
  media: GetTest;
  show: boolean;
};

type Survey = {
  type: 'survey';
  media: GetSurveyTemplate;
  show: boolean;
  link: string;
};

type Permanent = {
  type: 'profile' | 'url';
  show: true;
  link: string;
};

type NotFound = {
  type: 'notFound';
  media: null;
  show: false;
};

const notFound: NotFound = { type: 'notFound', media: null, show: false };

export type ValidFirstStep =
  | UnbookedLot
  | BookedLot
  | LiveSession
  | DemandSession
  | Exam
  | Survey
  | Permanent;
export type AllFirstStep = ValidFirstStep | NotFound;

export type FullFirstStep = {
  firstStep: GetFirstStep;
  info: ValidFirstStep;
};

export type FullSchedule = {
  schedule: GetMySchedule;
  info: LiveSession | DemandSession | BookedLot;
};

/** Gets the medium (like session, timeslot, survey...) based on the mediaId */
const getMedia = (
  type: StepType,
  mediaId: string,
  progression: ProgressionReturn
): AllFirstStep => {
  if (type === 'session') {
    const media = progression.info.sessions.find((s) => s.sessionId === Number(mediaId));
    if (!media) return notFound;
    if (media.sessionType === SessionType.TRAINING) {
      const training = progression.info.timeSlots.find((t) => t.sessionId === media.sessionId);
      if (training) {
        const show = progression.now < training.endAt;
        const running = show && progression.now + 15 * 60 > training.startAt;
        return {
          type: 'bookedLot',
          media: media,
          slot: training,
          show: show,
          link: `/lot/${training.trainingId}`,
          running: running,
        };
      } else {
        return { type: 'unbookedLot', media: media, show: true };
      }
    }
    if (media.sessionType === SessionType.LIVE) {
      const show = progression.now < (media.endAt || 0);
      const running = show && progression.now + 15 * 60 > (media.startAt || 0);

      return {
        type: 'liveSession',
        media: media,
        show: show,
        running: running,
        link: `/live/${media.sessionId}`,
      };
    }
    if (media.sessionType === SessionType.DEMAND) {
      const show = progression.isSessionUnlocked(media).unlocked;
      const watched = isSessionDone(media, progression.info.stats);

      return {
        type: 'demandSession',
        media: media,
        show: show,
        alreadyWatched: watched,
        link: `/onDemand/${media.sessionId}`,
      };
    }
    return notFound;
  }
  if (type === 'exam') {
    const exam = progression.info.exams.find((e) => e.examId === Number(mediaId));
    if (!exam) return notFound;
    const isUnlocked = progression.isExamUnlocked(exam);
    const show = isUnlocked && !progression.info.solvedExams.includes(exam.examId);
    return { type: 'exam', media: exam, show: show };
  }
  if (type === 'survey') {
    const survey = progression.info.surveys?.find((e) => e.surveyTemplateId === Number(mediaId));
    if (!survey) return notFound;
    const isUnlocked = progression.now < survey.endAt && progression.now > survey.startAt;
    const show =
      isUnlocked &&
      !progression.info.mySurveys?.some((s) => s.surveyTemplateId === survey.surveyTemplateId);
    return {
      type: 'survey',
      media: survey,
      show: show,
      link: `/survey/${survey.surveyTemplateId}`,
    };
  }
  if (type === 'profile') {
    return { type: 'profile', show: true, link: '/profile' };
  }
  if (type === 'url') {
    return { type: 'url', show: true, link: mediaId };
  }
  return notFound;
};

export const getFirstStepMedia = (
  firstStep: GetFirstStep,
  progression: ProgressionReturn
): AllFirstStep => {
  return getMedia(firstStep.stepType, firstStep.mediaId, progression);
};
export const getScheduleMedia = (
  schedule: GetMySchedule,
  progression: ProgressionReturn
): AllFirstStep => {
  return getMedia(schedule.myScheduleType, schedule.mediaId, progression);
};
