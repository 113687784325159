import badgeImage from '../../../media/perfectTestBadge.png';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useProgression } from '../../../progression';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { Text } from '@pp-labs/ui-components';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    rowGap: '20px',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

/** One single badge */
const Badge = (props: { image?: string }) => (
  <div
    style={{
      backgroundImage: `url(${props.image})`,
      borderRadius: '50%',
      height: '72px',
      width: '72px',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'relative',
      zIndex: 5,
    }}
  />
);

/** List all the users badges */
export const Badges = () => {
  const cls = useStyles();
  const progression = useProgression();
  const topicBadges = useMemo(() => {
    if (progression === 'loading') return [];
    return progression.info.topics.filter((t) =>
      progression.info.awards?.badges.topics?.includes(t.topicId)
    );
  }, [progression]);
  if (progression === 'loading') return null;
  return (
    <div className={cls.container}>
      {topicBadges.map((t) => (
        <div className={cls.badge} key={t.topicId}>
          <Badge image={t.badgeImage?.url || ''} />
          <Text spaceInlineStart="m">{getLocalizedValue(t.title)}</Text>
        </div>
      ))}
      {!progression.info.awards?.badges.exams?.length && (
        <div className={cls.badge}>
          <Badge image={badgeImage} />
          <Text spaceInlineStart="m">{strings.perfectExam}</Text>
        </div>
      )}
    </div>
  );
};
