import React, { useEffect, useRef, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Accordion, AccordionSection, Text, useIsTheme } from '@pp-labs/ui-components';
import { gutterStyle } from '../../utils/gutter';
import ExplainerVideo from '../../phases/ExplainerVideo';
import { ConfigModuleIdentifiers, FAQ as IFAQ, Support } from '../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from '../../Localization/Localizer';
import ContactSupport from './ContactSupport';
import { TechTestSupport } from './TechTestSupport';
import { client } from '../../ApiHandler/client';
import { byPosition } from '../../utils/sort';
import { useCurrentPageChannelId, useNamedConfigModule, useUser } from 'utils/hooks';
import clsx from 'clsx';
import AudiSpacer from 'utils/AudiSpacer';
import { doesRoleMatch, Role } from 'utils/RoleDefinitions';

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  mainWrapper: {
    width: '100%',
    margin: '0 56px',
    [theme.breakpoints.only('sm')]: {
      margin: '0 34px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 20px',
    },
  },
  videoContainer: {
    width: '100%',
  },
  supportTitle: {
    margin: '64px 0 !important',
    [theme.breakpoints.down('md')]: {
      margin: '52px 0',
    },
  },
  faqWrapper: {
    margin: '0px 0 128px 0',
    [theme.breakpoints.only('md')]: {
      margin: '0px 0 128px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0 80px 0',
    },
  },
  spacer: {
    padding: '25px 0',
  },
}));

interface QuestionProps {
  questions: IFAQ[];
}

/**
 * FAQ list for the user
 */

const Questions = (props: QuestionProps) => {
  if (!props.questions.length) return null;
  return (
    <Accordion>
      {props.questions.map((q) => (
        <AccordionSection
          id={q.faqId.toString()}
          key={q.faqId}
          label={getLocalizedValue(q.question)}
          headingLevel="h1"
        >
          <Text as="p" variant="copy1">
            {getLocalizedValue(q.answer)}
          </Text>
        </AccordionSection>
      ))}
    </Accordion>
  );
};

/** interface for FAQ props coming from parent component FAQedit */
interface P {
  title?: string;
}

/** List of FAQs for the User */
const FAQ = (props: P) => {
  const compRef = useRef() as React.RefObject<HTMLDivElement>;
  const user = useUser();
  const currentChannelId = useCurrentPageChannelId();
  const [faq, setFaq] = useState<IFAQ[]>([]);
  const cls = useStyles();
  const usesNamedConfigModule = useNamedConfigModule();
  const [explainer, setExplainer] = useState<Support | undefined>(undefined);

  useEffect(() => {
    async function getFaqs() {
      const faqs = (await client.get<IFAQ[]>('faq')).data;
      setFaq(
        faqs
          .filter((f) => currentChannelId && f.channels.includes(currentChannelId))
          .sort(byPosition)
      );
    }
    getFaqs();
  }, [user, currentChannelId]);

  useEffect(() => {
    const fetch = async () => {
      const exp: Support | Support[] = (
        await client.get('faq/explainer', { withCredentials: true })
      ).data;
      const e = Array.isArray(exp)
        ? exp.find((e) => e.channels.some((id) => id === currentChannelId))
        : exp;
      setExplainer(e);
      if (e && e.expId && doesRoleMatch(user, Role.VISITOR)) {
        await client.get(`faq/explainer/${e.expId}/videohit`);
      }
    };
    fetch();
  }, [user, currentChannelId]);

  const theme = useIsTheme();

  return (
    <div className={cls.mainWrapper} ref={compRef}>
      <Text
        className={cls.supportTitle}
        as="h2"
        variant={theme.audi ? 'order2' : 'order1'}
        weight={'bold'}
      >
        {props.title || strings.menuSupport}
      </Text>
      <Grid container spacing={0}>
        <Grid item xs={12} md={11} lg={7} xl={7}>
          <div className={cls.videoContainer}>
            {explainer && <ExplainerVideo explainer={explainer} />}
          </div>
          <div className={cls.faqWrapper}>
            <Text as="h2" variant={'order3'} weight={'bold'}>
              {strings.faqs}
            </Text>
            <AudiSpacer spaceStackEnd="s" neutralSpaceStackEnd="s" />
            <div
              className={clsx(
                theme.neutralLight && 'lightQuestionsWrapper',
                theme.neutralDark && 'darkQuestionsWrapper'
              )}
              style={{ paddingTop: '20px' }}
            >
              <Questions questions={faq} />
            </div>
          </div>
        </Grid>
        {usesNamedConfigModule(ConfigModuleIdentifiers.videoConferenceSessions) &&
          explainer?.enableTechTest && (
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <TechTestSupport />
            </Grid>
          )}
      </Grid>
      {explainer?.enableSupport && <ContactSupport />}
    </div>
  );
};

export default FAQ;
