import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useIsTheme } from '@pp-labs/ui-components';
import { NavLink } from 'react-router-dom';
import { useEventSettings } from '../hooks';

const useNavStyles = makeStyles((theme) => ({
  neutralLogo: {
    height: '75px',
    width: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '20px',
    },
  },
}));

export const NeutralLogo = () => {
  const event = useEventSettings();
  const cls = useNavStyles();
  const neutralTheme = useIsTheme().neutral;
  if (!neutralTheme) return null;
  return (
    <Grid item xs={12}>
      <NavLink to="/">
        {event?.logo && <img src={event?.logo} alt="Vw" className={cls.neutralLogo} />}
      </NavLink>
    </Grid>
  );
};
