import React from 'react';
import { Text, makeThemedStyles, useThemedStyles } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';
import { blackStyle } from 'features/Sessions/Watch/blackStyle';
import { ChannelTopic } from 'ApiHandler/dclxInterfaces';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from 'react-slick';
import { gutterStyle } from 'utils/gutter';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { SliderWrapper } from '@pp-labs/ui-components';
import clsx from 'clsx';

const styles = makeThemedStyles((theme) => {
  return {
    ...blackStyle(theme),
    ...gutterStyle(theme),
    sliderTitle: {
      textAlign: 'center',
      padding: ({ isTheme }) => (isTheme.audi ? '32px 30px' : '0px 30px 32px 30px'),
    },
    contentWrapper: {
      position: 'relative',
      cursor: 'pointer',
      paddingTop: '56.25%',
    },
    overlay: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 'calc(100% - 3px)',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    thumbnailImg: {
      width: '100%',
      height: '100%',
      paddingRight: '3px',
      position: 'absolute',
      top: 0,
      zIndex: -1,
    },
    textWrapper: {
      position: 'absolute',
      bottom: '3px',
      margin: '3px',
      left: '0',
      right: '0',
      textAlign: 'center',
      zIndex: 1,
    },
  };
});

interface ArrowProps {
  className?: string;
  onClick?: () => void;
  position: 'prev' | 'next';
}

const useArrowStyles = makeStyles({
  arrowContainer: {
    display: 'flex',
    background: 'white',
    color: 'black',
    zIndex: 2,
    height: '42px',
    width: '32px',
  },
});

const Arrow = (props: ArrowProps) => {
  const cls = useArrowStyles();
  return (
    <div className={`${cls.arrowContainer} ${props.className}`} onClick={props.onClick}>
      <Text as="span">{props.position === 'prev' ? '<' : '>'}</Text>
    </div>
  );
};

/** interface for DCLXMyTrainingsSlider props coming from parent component DCLXMyTrainings */
interface P {
  topics: ChannelTopic[];
}

/** Topic carousel slider */

const DCLXMyTrainingsSlider = (props: P) => {
  const classes = useThemedStyles(styles);
  const sliderSettings = {
    infinite: false,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <Arrow position="next" />,
    prevArrow: <Arrow position="prev" />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderWrapper>
      <div className={classes.sliderTitle}>
        <Text variant={'order4'}>{strings.topicOverview}</Text>
      </div>
      {props.topics.length >= 1 ? (
        <Slider {...sliderSettings}>
          {props.topics.map((topic: ChannelTopic, index: number) => (
            <div key={index} className={classes.contentWrapper}>
              <AnchorLink href={`#topic${topic.topicId}`} offset={32}>
                <div className={classes.overlay} />
                <img
                  src={topic.thumb?.url}
                  alt="thumbnail"
                  className={clsx(classes.thumbnailImg)}
                />
                <div className={classes.textWrapper} title={getLocalizedValue(topic.title)}>
                  <Text
                    className={`${classes.blackText} ${classes.maxTwoLines}`}
                    variant={'copy1'}
                    weight={'bold'}
                  >
                    {getLocalizedValue(topic.title)}
                  </Text>
                </div>
              </AnchorLink>
            </div>
          ))}
        </Slider>
      ) : (
        <div className={classes.sliderTitle}>
          <Text variant={'copy2'}>{strings.noTopicsAvailable}</Text>
        </div>
      )}
    </SliderWrapper>
  );
};

export { DCLXMyTrainingsSlider };
