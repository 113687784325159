import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Text } from '@pp-labs/ui-components';
import { DotsIcon } from '../../../../icons';
import { useState } from 'react';

const useStyles = makeStyles({
  wrapper: {
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    paddingLeft: 16,
    alignItems: 'center',
    cursor: 'pointer',
  },
  headWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  wrapperPrimary: {
    backgroundColor: 'black',
  },
  textPrimary: {
    color: 'white',
  },
  iconWrapper: {
    marginLeft: 24,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
  },
  menuItem: {
    padding: '8px 0',
    borderTop: '1px solid white',
    width: '100%',
  },
});
export type Identifier = string;
export type MenuItem = {
  text: string;
  onClick: (identifier: Identifier) => void;
  identifier: Identifier;
};
type P = {
  variant: 'primary' | 'secondary';
  text: JSX.Element;
  items: MenuItem[];
};

/** Popover menu */
export const Menu = (props: P) => {
  const [open, setOpen] = useState<boolean>(false);
  const cls = useStyles();
  return (
    <div style={{ position: 'relative', height: 36, minWidth: 300 }}>
      <div style={{ position: 'absolute', top: 0, right: 0, width: '100%', zIndex: 100 }}>
        <div className={clsx(cls.wrapper, props.variant === 'primary' && cls.wrapperPrimary)}>
          <div
            className={clsx(cls.headWrapper)}
            onClick={() => setOpen(!open)}
            style={{ paddingBottom: open ? 8 : 0 }}
          >
            <Text className={clsx(props.variant === 'primary' && cls.textPrimary)}>
              {props.text}
            </Text>
            <div className={cls.iconWrapper}>
              <DotsIcon />
            </div>
          </div>
          {open && (
            <div style={{ width: '100%' }}>
              {props.items.map((i) => {
                return (
                  <div
                    className={cls.menuItem}
                    onClick={() => {
                      i.onClick(i.identifier);
                      setOpen(false);
                    }}
                    key={i.identifier}
                  >
                    <Text className={clsx(props.variant === 'primary' && cls.textPrimary)}>
                      {i.text}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
