import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { EditDialog } from '@pp-labs/ui-components';
import { useHistory } from 'react-router-dom';
import { client } from '../../../ApiHandler/client';
import strings from '../../../Localization/Localizer';
import { useEventSettings, useNamedConfigModule, useNotify } from '../../../utils/hooks';
import { Survey } from '../../../features/Survey/Survey';
import { ConfigModuleIdentifiers } from '../../../ApiHandler/dclxInterfaces';
import { GetSurveyTemplate } from '@pp-labs/survey';

/** interface for LotLeaveDialogue props coming from parent component LOT  */
interface P {
  channelId: number;
  trainingId: number;
  triggerNotification: (o: string[]) => void;
}

/** Leaves the LOT and mounts a survey if necessary */
const LotLeaveDialogue = (props: P) => {
  const eventSettings = useEventSettings();
  const usesModule = useNamedConfigModule();
  const notify = useNotify();
  const [loaded, setLoaded] = useState<boolean>(false);

  const leaveTraining = () => history.push(`/${eventSettings?.identifier}`);

  // Before mounting skip the survey if it is already submitted.
  useEffect(() => {
    const checkSubmit = async () => {
      if (!usesModule(ConfigModuleIdentifiers.surveyInVideoConferenceSessions)) {
        leaveTraining();
        return;
      }
      try {
        const templates: GetSurveyTemplate[] = (await client.get(`surveys/templates`)).data;
        const lotTemplate = templates.find((t: GetSurveyTemplate) => t.surveyContext === 'lot');
        if (lotTemplate) {
          const response = await client.get(`trainings/${props.trainingId}/surveys`);
          //If no user survey results at all or no corresponding user result for the current template id are found, then show the survey dialog.
          if (
            response.status === 204 ||
            response.data.surveyTemplateId !== lotTemplate.surveyTemplateId
          ) {
            setLoaded(true);
          } else {
            leaveTraining();
          }
        } else {
          leaveTraining();
        }
      } catch (e) {
        console.error(e);
        leaveTraining();
      }
    };
    checkSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();

  const callback = async () => {
    notify(strings.thanksForFeedback, 'success');
    leaveTraining();
  };

  if (!loaded) return null;
  return (
    <EditDialog title="LOT Feedback" close={leaveTraining}>
      <Grid>
        <Grid item xs={9}>
          <Survey
            channelId={props.channelId}
            context="lot"
            id={props.trainingId}
            onFinish={callback}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </EditDialog>
  );
};

export { LotLeaveDialogue };
