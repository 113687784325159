import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { WhiteClapIcon, BlackClapIcon } from 'icons';
import React from 'react';
import { ClapData } from './Live';
import { Text } from '@pp-labs/ui-components';

const useStyle = makeStyles({
  mainWrapper: {
    position: 'absolute',
    zIndex: 100,
    right: 20,
    top: 20,
  },
  common: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '18px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  clapped: {
    background: '#000000',
  },
  notClapped: {
    background: '#ffffff',
  },
});

type P = {
  toggleClap: () => void;
  clapped: boolean;
  showCount: boolean;
  allClap: ClapData[];
};

/** Provides the clap functionality */
const FloatingClapButton = (props: P) => {
  const cls = useStyle();
  return (
    <div className={cls.mainWrapper}>
      {props.showCount ? (
        <div className={cls.common} style={{ background: 'white' }}>
          <Text>{props.allClap.length}</Text>
        </div>
      ) : (
        <div
          className={clsx(cls.common, props.clapped ? cls.clapped : cls.notClapped)}
          onClick={() => {
            props.toggleClap();
          }}
        >
          {props.clapped ? (
            <WhiteClapIcon fontSize="medium" />
          ) : (
            <BlackClapIcon fontSize="medium" />
          )}
        </div>
      )}
    </div>
  );
};

export { FloatingClapButton };
