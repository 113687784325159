import strings from '../../Localization/Localizer';

export const languageString = () => baseString().toUpperCase();
const baseString = () => {
  const lang = strings.getLanguage();
  if (lang.includes('Bulgarian')) return 'bg';
  if (lang.includes('Czech')) return 'cz';
  if (lang.includes('German')) return 'de';
  if (lang.includes('Danish')) return 'dk';
  if (lang.includes('Greek')) return 'el';
  if (lang.includes('English')) return 'en';
  if (lang.includes('Spanish')) return 'es';
  if (lang.includes('Finnish')) return 'fi';
  if (lang.includes('French')) return 'fr';
  if (lang.includes('Hungarian')) return 'hu';
  if (lang.includes('Italian')) return 'it';
  if (lang.includes('Dutch')) return 'nl';
  if (lang.includes('Norwegian')) return 'no';
  if (lang.includes('Polish')) return 'pl';
  if (lang.includes('Portuguese')) return 'pt';
  if (lang.includes('Russian')) return 'rus';
  if (lang.includes('Slovakian')) return 'sk';
  if (lang.includes('Slovenian')) return 'sl';
  if (lang.includes('Serbian')) return 'srb';
  if (lang.includes('Swedish')) return 'swe';
  if (lang.includes('Ukrainian')) return 'ua';
  return 'en';
};
