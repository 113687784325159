import { ChannelTopic } from 'ApiHandler/dclxInterfaces';
import React, { useState } from 'react';
import { IconButton, makeStyles, Table, TableBody } from '@material-ui/core';
import {
  tableStyles,
  TableRow,
  SessionTableCell,
  Text,
  EraseIcon,
  EditIcon,
} from '@pp-labs/ui-components';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { format } from 'date-fns';
import { TestTable } from './TestTable';
import { TableArrows } from 'DCLX/utils/TableArrows';
import { standardFormat } from 'config';

const useStyle = makeStyles({
  ...tableStyles(),
  topicTitle: {
    padding: '30px',
    background: '#e5e5e5',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '12px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

/** interface for TopicTable props coming from parent component ChannelTable  */
interface P {
  topic: ChannelTopic;
  setCurrentTopic: (topic: ChannelTopic) => void;
  handleDelete: (id: number) => void;
  setOpenTopicForm: (active: boolean) => void;
  setDeleteType: (string: 'exam' | 'topic' | 'channel') => void;
}

/** Displays all topics of a channel */
const TopicTable = (props: P) => {
  const cls = useStyle();
  const [examVisible, setExamVisible] = useState<boolean>(false);
  return (
    <React.Fragment key={props.topic.topicId}>
      <Table key={props.topic.topicId} className={cls.table} aria-label="simple table">
        <TableBody>
          <TableRow style={{ background: '#f2f2f2' }}>
            <SessionTableCell image>
              <img
                style={{ height: '100%', width: '140px' }}
                src={props.topic.thumb?.url}
                alt={getLocalizedValue(props.topic.title)}
              />
            </SessionTableCell>
            <SessionTableCell>
              <div onClick={() => setExamVisible(!examVisible)} style={{ cursor: 'pointer' }}>
                <IconButton style={{ color: 'black' }} disabled>
                  {<TableArrows open={examVisible} />}
                </IconButton>
              </div>
            </SessionTableCell>
            <SessionTableCell style={{ width: '42%' }}>
              <div className={cls.titleContainer}>
                <Text as="p" variant="copy1" weight={'bold'}>
                  {getLocalizedValue(props.topic.title)}
                </Text>
              </div>
            </SessionTableCell>
            <SessionTableCell>
              <Text as="p" variant="copy3">
                {strings.startsAt}
              </Text>
              <Text as="p" variant="copy1">
                {format(props.topic.startAt * 1000, standardFormat)}
              </Text>
            </SessionTableCell>
            <SessionTableCell>
              <Text as="p" variant="copy3">
                {strings.endsAt}
              </Text>
              <Text as="p" variant="copy1">
                {format(props.topic.endAt * 1000, standardFormat)}
              </Text>
            </SessionTableCell>
            <SessionTableCell controls>
              <IconButton
                style={{ color: 'black' }}
                onClick={() => {
                  props.setCurrentTopic(props.topic);
                  props.setOpenTopicForm(true);
                }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                style={{ color: 'black' }}
                onClick={() => {
                  props.handleDelete(props.topic.topicId);
                  props.setDeleteType('topic');
                }}
              >
                <EraseIcon />
              </IconButton>
            </SessionTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {examVisible && (
        <TestTable
          topic={props.topic}
          handleDelete={props.handleDelete}
          setDeleteType={props.setDeleteType}
        />
      )}
    </React.Fragment>
  );
};

export { TopicTable };
