import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text } from '@pp-labs/ui-components';

import { setUser, setEventSetting, setLanguage } from '../AppSlice';
import LoginForm from './LoginForm';
import { RegisterForm, RegistrationData } from './RegisterForm';
import MFARegisterForm from './MFARegisterForm';
import MFAForm from './MFAForm';
import { MFAData } from './MFAUtils';
import { RecoverPassword, RecoverPasswordData } from './RecoverPassword';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DirectRegisterForm, { DirectRegisterData } from './DirectRegisterForm';
import { getMfaType, removeWhitespaces } from '../utils/convert';

import strings, { allLangs, formatString } from '../Localization/Localizer';
import TechTest from '../features/TechTest/TechTest';
import { client } from '../ApiHandler/client';
import { useEventSettings, useNotify, useSetEventTheme } from '../utils/hooks';
import { StartPage } from './StartPage';
import * as Cognito from '../ApiHandler/Cognito';
import { publicClient } from 'ApiHandler/publicClient';
import { adminSettings } from 'mockData/eventSettings';
import {
  ConfigModuleIdentifiers,
  LanguagesSelection,
  PlatformEvent,
} from 'ApiHandler/dclxInterfaces';
import {
  activateSms,
  checkUsername,
  deactivateSms,
  getErrorMessage,
  getFailReason,
  getSentMessage,
} from './utils';
/**
 * Main Component responsible for Authorization and the Landing Page
 */

/** interface CurrentStep indicate the current step one of following is possible at a time  */
interface CurrentStep {
  step: 'loading' | 'techTest' | 'normalRegister' | 'passwordChange' | 'recoverPassword';
}

interface MfaLoginStep {
  step: 'mfaLogin';
  session: Cognito.TmpSession;
}

interface MfaRegisterStep {
  step: 'mfaRegister' | 'mfaLoading';
  code: string;
  session: Cognito.TmpSession;
}

interface NeedLogin {
  step: 'login';
  error: string | null;
}

type Current = CurrentStep | MfaRegisterStep | NeedLogin | MfaLoginStep;

const hasLot = (settings: PlatformEvent) =>
  settings.configModules?.find(
    (m) => m.moduleId === ConfigModuleIdentifiers.videoConferenceSessions
  );

const getMaxSteps = (settings: PlatformEvent) => {
  let steps = 2;
  if (getMfaType(settings) === 'totp') steps += 3;
  if (hasLot(settings)) steps += 1;
  return steps;
};

let localSettings: PlatformEvent | null = null;

/** Component handling the start page of an event and the login to it. */
const Login = () => {
  const params =
    useParams<{ event: string; username?: string; password?: string; redirect?: string }>();
  const dispatch = useDispatch();
  const notify = useNotify();
  const eventSettings = useEventSettings();
  const location = useLocation<{ origin: string | undefined }>();
  const history = useHistory();
  const [current, setCurrent] = useState<Current>({ step: 'loading' });
  const [username, setUsername] = useState<string>(params.username || '');
  const [password, setPassword] = useState<string>('');
  const [regCodeFails, setRegCodeFails] = useState<number>(0);
  const [resettingMfa, setResettingMfa] = useState<boolean>(false);
  const [maxSteps, setMaxSteps] = useState<number>(0);
  const setEventTheme = useSetEventTheme();

  useEffect(() => {
    const loadEventSetting = async (eventData: PlatformEvent[]) => {
      const settings = eventData.find(
        (s) => s.identifier.toLowerCase() === params.event.toLowerCase()
      );
      if (!settings) {
        history.push('/');
        return;
      }
      localSettings = settings;

      dispatch(setEventSetting(settings));
      Cognito.setupPool(settings.poolId, settings.poolClientId);
      setMaxSteps(getMaxSteps(settings));

      if (username) {
        if (params.password) {
          await tryLogin(username, decodeURIComponent(params.password), true);
        } else {
          const currentSession = await checkForCurrentSession();
          if (currentSession) return;
          const status = await checkUsername(username);
          if (status === 'notFound') {
            userNotFound();
            return;
          } else if (status === 'canLogIn') {
            setCurrent({ step: 'login', error: '' });
          } else if (status === 'canRegister') {
            triggerDirectRegister();
          } else {
            notify(strings.unknownUserStatus, 'error');
            setUsername('');
            setCurrent({ step: 'login', error: '' });
          }
        }
      } else {
        const currentSession = await checkForCurrentSession();
        if (currentSession) return;
        setCurrent(
          location.pathname.includes('register')
            ? { step: 'normalRegister' }
            : { step: 'login', error: '' }
        );
      }
    };
    const getData = async () => {
      try {
        const res = await publicClient.get(`events`);
        const data = [...res.data, adminSettings];
        await loadEventSetting(data);
      } catch {
        await loadEventSetting([adminSettings]);
        console.log('Event endpoint not working');
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEventTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSettings]);

  const checkForCurrentSession = async () => {
    try {
      await Cognito.currentSession();
      await currentSessionFound('cognito');
      return true;
    } catch {
      return false;
    }
  };

  const currentSessionFound = async (type: 'cognito' | 'idp') => {
    await createSession(false, type);
    continueToHome();
  };

  const triggerDirectRegister = () => setCurrent({ step: 'normalRegister' });

  const finishDirectRegister = async (v: DirectRegisterData) => {
    const validationCode = removeWhitespaces(v.code);
    try {
      await Cognito.forgotPasswordSubmit(v.username, validationCode, v.password);
      await deactivateSms(v.username, eventSettings?.poolId || '');
      setUsername(v.username);
      setPassword(v.password);
      const session = await Cognito.signIn(v.username, v.password);
      if (getMfaType(eventSettings!)) {
        const code = await Cognito.setupTOTP(session);
        setCurrent({ step: 'mfaRegister', code: code, session: session });
      } else {
        await completeLogin('register');
      }
    } catch (e: any) {
      notify(getErrorMessage(300, strings.validationCodeWrong), 'error');
      setRegCodeFails(regCodeFails + 1);
      setCurrent({ step: 'normalRegister' });
    }
  };

  const finishChangePassword = async (v: RegistrationData) => {
    try {
      const tmp = await Cognito.signIn(username, password);
      const session = await Cognito.completeNewPassword(tmp, v.newPassword);
      if (getMfaType(eventSettings!)) {
        const code = await Cognito.setupTOTP(session);
        setCurrent({ step: 'mfaRegister', code: code, session: session });
      } else {
        await completeLogin('register');
      }
    } catch (e: any) {
      notify(getErrorMessage(301, strings.validationCodeWrong), 'error');
    }
  };

  const completeLogin = async (origin: 'mfaReset' | 'register' | 'login' | 'quickLogin') => {
    await createSession(origin === 'mfaReset', 'cognito');
    continueToHome();
    /*const techTest =
      origin === 'register' &&
      eventSettings?.configModules?.find(
        (cm) => cm.moduleId === ConfigModuleIdentifiers.videoConferenceSessions
      ); //origin === 'register' && getMarket(u) !== 'APX_German';
    if (techTest) {
      setCurrent({ step: 'techTest' });
    } else {
      continueToHome();
    }*/
  };

  /* mfaWasReset is necessary, because getPreferredMFA returns an outdated value
   * if the MFA Method had been set to TOTP during this session */
  const createSession = async (mfaWasReset: boolean, type: 'cognito' | 'idp') => {
    let user = null;
    if (type === 'cognito') {
      const cognitoUser = await Cognito.currentAuthenticatedUser();
      if (cognitoUser) {
        const preferredMfa = await Cognito.getPreferredMFA(cognitoUser);
        if (!mfaWasReset && preferredMfa === 'SMS_MFA') {
          /* Evil user, tried some workaround to keep MFA.
          We will keep logging him out until he setups his MFA properly.*/
          // await Cognito.signOut();
        }
        user = await Cognito.getUser();
      }
    }
    if (!user) return;
    try {
      const data = (
        await client.post('users', {
          timezone: new Date().getTimezoneOffset(),
        })
      ).data;
      setLang(data);
    } catch {}
    dispatch(setUser(user));
    return user;
  };

  const setLang = (data: { customFields: string; language: string }) => {
    const customFields = JSON.parse(data.customFields || '{}');
    if (data.language) {
      dispatch(setLanguage(JSON.parse(data.language)));
      return;
    }
    if (customFields['custom:language'] && customFields['custom:language'].length > 0) {
      const lang = allLangs.find((l) => l.code === customFields['custom:language']);
      if (lang) {
        dispatch(setLanguage(lang));
        return;
      }
    }
    const s = eventSettings || localSettings;
    const eventLangs = s ? (JSON.parse(s.languages) as LanguagesSelection) : null;
    if (eventLangs && eventLangs.languages.length) {
      const preferred = allLangs.find((l) => l.code === eventLangs.preferedLanguage);
      dispatch(setLanguage(preferred || eventLangs.languages[0]));
    }
  };

  const finishMFARegister = async (v: MFAData) => {
    if (current.step !== 'mfaRegister') return;
    setCurrent({ step: 'mfaLoading', code: current.code, session: current.session });
    const validationCode = removeWhitespaces(v.validationCode);
    try {
      // response may be the session (normal register) or just success (reset)
      await Cognito.verifyTotpToken(current.session, validationCode);
      if (resettingMfa) {
        await client.post('users/pool/mfa/totp', {
          username: username,
        });
        await completeLogin('mfaReset');
      } else {
        await completeLogin('register');
      }
    } catch (e: any) {
      if (e.code === 'EnableSoftwareTokenMFAException') {
        notify(getErrorMessage(302, strings.mfaRegisterCodeWrong), 'error');
        setCurrent({ step: 'mfaRegister', code: current.code, session: current.session });
      } else if (e.code === 'NotAuthorizedException') {
        notify(getErrorMessage(303, strings.sessionExpired), 'error');
        await tryLogin(username, password);
      }
    }
  };

  const finishMFA = async (v: MFAData) => {
    if (current.step !== 'mfaLogin') return;
    const validationCode = removeWhitespaces(v.validationCode);
    try {
      await Cognito.confirmSignIn(current.session, validationCode, 'SOFTWARE_TOKEN_MFA');
      await completeLogin('login');
    } catch (e: any) {
      try {
        // try to use the code for SMS Verification in order to reset the TOTP verification
        await Cognito.confirmSignIn(current.session, validationCode, 'SMS_MFA');
        await resetMfa();
      } catch (error: any) {
        // Neither TOTP nor SMS worked, display error.
        notify(getErrorMessage(200, strings.invalidCode), 'error');
      }
    }
  };

  const resetMfa = async () => {
    if (!['mfaLogin', 'mfaRegister', 'mfaLoading'].includes(current.step)) return;
    try {
      const payload = { accessToken: await Cognito.getAccessToken() };
      const secret = (await client.post('users/pool/mfa/token', payload)).data;
      setResettingMfa(true);
      const c = current as MfaLoginStep | MfaRegisterStep;
      setCurrent({ step: 'mfaRegister', code: secret, session: c.session });
    } catch (e: any) {
      notify(getErrorMessage(304, strings.mfaResetFailed), 'error');
    }
  };

  const tryLogin = async (usernameValue: string, passwordValue: string, quick?: boolean) => {
    if (quick) {
      await Cognito.signIn(usernameValue, passwordValue);
      await completeLogin('quickLogin');
      return;
    }
    try {
      const session = await Cognito.signIn(usernameValue, passwordValue);
      if (session.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUsername(usernameValue);
        setPassword(passwordValue);
        setCurrent({ step: 'passwordChange' });
      } else if (session.challengeName === 'MFA_SETUP') {
        const code = await Cognito.setupTOTP(session);
        setUsername(usernameValue);
        setCurrent({ step: 'mfaRegister', code: code, session: session });
      } else {
        if (getMfaType(eventSettings!)) {
          setUsername(usernameValue);
          setCurrent({ step: 'mfaLogin', session: session });
        } else {
          setCurrent({ step: 'loading' });
          await completeLogin('login');
        }
      }
    } catch (e: any) {
      const reason = getFailReason(e);
      if (reason === 'canRegister') {
        setUsername(usernameValue);
        setCurrent({ step: 'normalRegister' });
      } else if (reason === 'notFound') {
        userNotFound();
      } else if (reason === 'disabled') {
        setCurrent({ step: 'login', error: getErrorMessage(107, strings.userDisabled) });
      } else {
        setCurrent({ step: 'login', error: getErrorMessage(101, strings.passwordWrong) });
      }
    }
  };

  const userNotFound = () => {
    const msg = formatString(strings.userDoesNotExist, { username: username });
    notify(getErrorMessage(100, msg), 'error');
    setUsername('');
    setCurrent({ step: 'login', error: '' });
  };

  const triggerForgotPassword = (usernameValue: string) => {
    setUsername(usernameValue);
    setCurrent({ step: 'recoverPassword' });
  };

  const requestPasswordForgotCode = async (
    usernameValue: string,
    sms: boolean
  ): Promise<boolean> => {
    if (sms) {
      const success = await activateSms(usernameValue, eventSettings?.poolId || '', notify);
      if (!success) return false;
    }
    try {
      const res = await Cognito.forgotPassword(usernameValue);
      notify(getSentMessage(sms, res.CodeDeliveryDetails.Destination), 'success');
      if (sms) {
        await deactivateSms(usernameValue, eventSettings?.poolId || '');
      }
      return true;
    } catch (e: any) {
      const reason = getFailReason(e);
      if (reason === 'notFound') {
        userNotFound();
      } else {
        notify(e.message || '', 'error');
      }
      return false;
    }
  };

  const finishForgotPassword = async (
    v: RecoverPasswordData,
    usernameValue: string,
    _sms: boolean
  ) => {
    try {
      await Cognito.forgotPasswordSubmit(usernameValue, v.code, v.newPassword);
      notify(strings.passwordChanged, 'success');
      setCurrent({ step: 'login', error: null });
    } catch (e: any) {
      notify(getErrorMessage(300, strings.validationCodeWrong), 'error');
    }
  };

  const finishTechTest = () => continueToHome();

  const continueToHome = () => {
    const redirect = params.redirect ? decodeURIComponent(params.redirect) : 'home';
    history.push(location.state?.origin || `/${params.event}/${redirect}`);
  };

  const getContent = () => {
    if (!eventSettings) return null;
    const now = Date.now() / 1000;
    if (eventSettings.eventEndTiming < now) {
      return (
        <div>
          <Text as="h1" variant="order2" weight={'bold'}>
            {strings.theEventLogin} {eventSettings?.title || ''} {strings.eventOverLogin}
          </Text>
          <Text as="h2" variant="order4">
            {strings.seeYouSoonLogin}
          </Text>
        </div>
      );
    }
    switch (current.step) {
      case 'loading':
        return null;
      case 'login':
        return (
          <LoginForm
            triggerRegister={triggerDirectRegister}
            triggerForgotPassword={triggerForgotPassword}
            login={tryLogin}
            error={current.error}
            title={eventSettings?.title || ''}
            username={username}
          />
        );
      case 'mfaLogin':
        return <MFAForm finishMFA={finishMFA} />;
      case 'techTest':
        return <TechTest onFinish={finishTechTest} external={false} hideSteps={false} />;
      case 'normalRegister':
        return (
          <DirectRegisterForm
            finish={finishDirectRegister}
            toLogin={() => setCurrent({ step: 'login', error: '' })}
            username={username}
            userNorFound={userNotFound}
            mfa={!!getMfaType(eventSettings!)}
            eventSettings={eventSettings!}
            maxSteps={maxSteps}
          />
        );
      case 'recoverPassword':
        return (
          <RecoverPassword
            requestCode={requestPasswordForgotCode}
            recover={finishForgotPassword}
            username={username}
          />
        );
      case 'passwordChange':
        return <RegisterForm register={finishChangePassword} />;
      case 'mfaRegister':
      case 'mfaLoading':
        return (
          <MFARegisterForm
            code={current.code}
            username={username}
            finishMFA={finishMFARegister}
            loading={current.step === 'mfaLoading'}
            reset={resettingMfa}
          />
        );
    }
  };

  if (current.step === 'loading') return null;

  return <StartPage noImage={current.step === 'techTest'}>{getContent()}</StartPage>;
};

export default Login;
