import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import { stringObject } from './lang';
import { BaseStrings } from './BaseStrings';
import { Channel, Language, LanguagesSelection, MultiLanguage } from 'ApiHandler/dclxInterfaces';
import { useMemo } from 'react';
import { format } from 'date-fns';

/**
 * Handles the localization of the whole website
 */

export const getBrowserLang = (): string =>
  // @ts-ignore
  navigator.language || navigator.userLanguage || 'en';

export const switchLangBrowser = (): string => {
  const b = getBrowserLang();
  if (b.includes('en')) return 'English';
  if (b.includes('de')) return 'German';
  return defaultLanguage.code;
};

export const allLangs = [
  { code: 'English', name: 'English' },
  { code: 'German', name: 'German' },
] as const;
export const defaultLanguage: Language = { code: 'English', name: 'English' };
export const getLocalizedValue = (value: string, lang?: Language) => {
  if (!lang) {
    // @ts-ignore
    lang = { code: strings.getLanguage(), name: strings.getLanguage() };
  } //TODO: get readable name
  try {
    const v: MultiLanguage<string>[] = JSON.parse(value);
    const filtered = v.filter((e) => e.value);
    const match = filtered.find((e) => e.lang === lang?.code);
    if (match) return match.value;

    const english = filtered.find((e) => e.lang.includes('en'));
    return english?.value || filtered[0]?.value || '';
  } catch (e: any) {
    //For backwards compatibility
    return value;
  }
};

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer InferedElementType
>
  ? InferedElementType
  : never;

type S = { [index in BaseStrings]: string };

export interface IStrings extends S, LocalizedStringsMethods {}

interface o {
  [key: string]: string;
}
export const formatString = (string: string, options: o): string => {
  try {
    // @ts-ignore
    return strings.formatString(string, options);
  } catch {
    return '';
  }
};

export const getChannelsLanguages = (channels: Channel[]) => {
  const langs: Language[] = [];
  channels.forEach((c) => {
    try {
      (JSON.parse(c.languages) as LanguagesSelection).languages.forEach((l: Language) => {
        if (!langs.some((lang: Language) => lang.code === l.code)) {
          langs.push(l);
        }
      });
    } catch (error) {
      console.error(error);
    }
  });
  return langs;
};

export const getLocalizedLanguages = (channels: Channel[]) =>
  getChannelsLanguages(channels).map((l) => l.name);

export const useLocalizedLanguages = (channels: Channel[]) =>
  useMemo(() => getLocalizedLanguages(channels), [channels]);

export const formatFixedTime = (
  time: Date | number,
  formatOptions: string,
  fixedTimezone: number,
  options?: {
    locale?: Locale | undefined;
    weekStartsOn?: 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined;
    firstWeekContainsDate?: number | undefined;
    useAdditionalWeekYearTokens?: boolean | undefined;
    useAdditionalDayOfYearTokens?: boolean | undefined;
  }
) => {
  const timeDate: Date = new Date(time);
  timeDate.setTime(
    timeDate.getTime() + timeDate.getTimezoneOffset() * 60 * 1000 + fixedTimezone * 60 * 60 * 1000
  );
  return format(timeDate, formatOptions, options);
};

const strings: IStrings = new LocalizedStrings(stringObject);
export { stringObject };
export default strings;
