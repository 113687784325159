import { Context, GeneralSurvey } from '@pp-labs/survey';
import { SurveyProvider } from './SurveyProvider';
import { LabelButton, Text } from '@pp-labs/ui-components';
import { useEventSettings } from 'utils/hooks';
import strings from 'Localization/Localizer';
import { Box, Link, ImageListItem, ImageList, } from '@material-ui/core';
import { LayoutItem } from '@audi/audi-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../media/MAT-survey.jpg';

/** interface for Survey props coming from parent components Watch, EventWatch */
interface P {
  id: number;
  channelId: number;
  context: Context;
  onFinish?: null | (() => void);
}

const useStyles = makeStyles({
  noShadow: {
    background: 'transparent !important',
  },
  img: {
    height: 'auto',
    width: 'auto',
    maxWidth: 400,
  },
});

/** Shows a survey for a specific context */
export const Survey = (props: P) => {

  const cls = useStyles();

  const event = useEventSettings();

  const url = 'https://easy-feedback.de/umfrage/1815196/74bzvx';

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    event?.identifier.toLocaleLowerCase() === "mat"
      ? <>
        <Link href={url} target='_blank'>
          <img src={logo}
            alt="audi"
            color="green"
            className={cls.img}
            draggable="false" />
          <Box m={2} />
          <LayoutItem spaceStackStart="m">
            <Text variant="order4" weight="bold">
              {strings.mainSurvey}
            </Text>
          </LayoutItem>
        </Link>
        <Box m={2} />
        <div>
          <LabelButton variant="secondary" onClick={() => handleClick()}>
            {strings.takeSurvey}
          </LabelButton>
        </div>
      </>
      : <SurveyProvider>
        <GeneralSurvey
          channelId={props.channelId}
          id={props.id}
          context={props.context}
          accessor={(str: string) => str}
          live={false}
          onFinish={props.onFinish}
        />
      </SurveyProvider>
  );
};
