import {
  Agenda,
  Answer,
  CognitoUser,
  FAQ,
  Interrupt,
  PlatformEvent,
  PlayerSub,
  Question,
  RawAgenda,
  Session,
  SessionSettings,
  Stat,
  TimeSlot,
} from '../ApiHandler/dclxInterfaces';
import strings from '../Localization/Localizer';
/**
 * Huge collection of unsorted utility methods
 */

export const toInt = (i: string | number): number => {
  if (typeof i === 'string') return parseInt(i);
  return i;
};

export const getMonth = (n: number) => {
  // by purpose defined here in case we later use strings
  const months = [
    strings.january,
    strings.february,
    strings.march,
    strings.april,
    strings.may,
    strings.june,
    strings.july,
    strings.august,
    strings.september,
    strings.october,
    strings.november,
    strings.december,
  ];
  return months[n];
};

export const getDay = (n: number) => {
  // by purpose defined here because we need the strings to be generated on the fly
  const days = [
    strings.sun,
    strings.mon,
    strings.tue,
    strings.wed,
    strings.thu,
    strings.fri,
    strings.sat,
  ];
  return days[n];
};

export const appendZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

export const getDisplayDate = (
  date: Date | number,
  showTime?: boolean,
  onlyTime?: boolean,
  miliseconds?: boolean
): string => {
  const d = typeof date === 'number' ? new Date(date * (!miliseconds ? 1000 : 1)) : date;
  const dateString = `${appendZero(d.getDate())}.${appendZero(
    d.getMonth() + 1
  )}.${d.getFullYear()}`;
  if (!showTime) return dateString;
  const time = `${appendZero(d.getHours())}:${appendZero(d.getMinutes())}`;
  if (onlyTime) return time;
  return `${dateString} | ${time}`;
};

export const humanTime = (distance: number): Array<string> => {
  const days = appendZero(Math.floor(distance / (1000 * 60 * 60 * 24)));
  const hours = appendZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = appendZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = appendZero(Math.floor((distance % (1000 * 60)) / 1000));
  return [days, hours, minutes, seconds];
};

export const getCurrentTimezone = () => {
  const offset = new Date().getTimezoneOffset() / 60;
  return getTimezone(offset);
};

export const getTimezone = (offset: number) => (offset > 0 ? `UTC-${offset}` : `UTC+${-offset}`);

// TODO: Replace this by proper CSS
export const stripByWidth = (text: string | null | undefined, width: string) => {
  if (!text) return '';
  let finalText;
  if (width === 'sm' && text.length > 36) {
    finalText = text.substr(0, 20) + ' [...]';
  } else if (width === 'md' && text.length > 36) {
    finalText = text.substr(0, 30) + ' [...]';
  } else if (width === 'lg' && text.length > 116) {
    finalText = text.substr(0, 110) + ' [...]';
  } else if (width === 'xl' && text.length > 306) {
    finalText = text.substr(0, 300) + ' [...]';
  } else {
    finalText = text;
  }
  return finalText;
};

export const removeWhitespaces = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const playerConfig = {
  file: {
    hlsOptions: {
      forceHLS: true,
      debug: false,
    },
  },
};
export const playerConfigTracks = (tracks: Array<PlayerSub>) => {
  return {
    file: {
      hlsOptions: {
        forceHLS: true,
        debug: false,
        xhrSetup: function (xhr: XMLHttpRequest) {
          xhr.withCredentials = true;
        },
      },
      attributes: {
        crossOrigin: 'true',
      },
      tracks: tracks.map((track: PlayerSub) => {
        return {
          kind: 'subtitles',
          label: track.srcLang.name,
          srcLang: track.srcLang.code,
          src: track.src || '',
        };
      }),
    },
  };
};

export const youtubeConfig = {
  youtube: {
    playerVars: {
      disablekb: 1,
      modestbranding: 1,
      controls: 0,
      autoplay: true,
      showinfo: 0,
    },
  },
};

export const getInterruptById = (interrupts: Interrupt[], id: number): Interrupt | undefined => {
  return interrupts.find((e) => e.interruptId === id);
};

export const getQuestionById = (questions: Question[], id: number): Question | undefined => {
  return questions.find((e) => e.questionId === id);
};

export const getFaqById = (faqs: FAQ[], id: number): FAQ | undefined => {
  return faqs.find((e) => e.faqId === id);
};

export const getAnswerById = (answers: Answer[], id: number): Answer | undefined => {
  return answers.find((e) => e.answerId === id);
};

export const getTime = (seconds: number, decimal?: boolean): string => {
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return `${appendZero(m)}:${appendZero(decimal ? Math.round(s * 10) / 10 : s)}`;
};

export const merge = (a: Array<any>, prop: string): Array<any> => {
  const newA: Array<any> = [];
  const parentsWithChild: Array<number> = [];
  // add sessions on market level
  for (let i = 0; i < a.length; i++) {
    const o = a[i];
    if (o.parentId !== null) {
      parentsWithChild.push(o.parentId);
      newA.push(o);
    }
  }
  // add sessions on parentId level whose child is not in the list
  for (let i = 0; i < a.length; i++) {
    const o = a[i];
    if (o.parentId === null) {
      const f = parentsWithChild.find((n) => n === o[prop]);
      if (!f) newA.push(o);
    }
  }
  return newA;
};

export const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

export const keepAliveCommand = (channel: string) =>
  JSON.stringify({
    Action: 'command',
    CommandKey: 'keepAlive',
    ChannelId: channel,
  });

export const isBigLOT = (training: TimeSlot): boolean => training.trainingType === 'stream';

export const isStreamingEvent = (session: Session): boolean => !!session.externalUrl;

export const getMarket = (_user: CognitoUser | null | undefined) => 'DEPRECATED_MARKET';

export const noNullUser = (user: CognitoUser | null): CognitoUser =>
  user || {
    sub: '',
    email: '',
    'cognito:groups': [],
    family_name: '',
    given_name: '',
  };

export type ValidationType = 'sms' | 'email';
export const getMfaType = (event: PlatformEvent): 'totp' | null =>
  event.poolSecurity === 'mfa' ? 'totp' : null;
export const getValidationType = (event: PlatformEvent): ValidationType[] =>
  event.registerSettings.verificationTypes;

export const getSessionSettings = (session: Session): SessionSettings => {
  if (!session.settings) return {};
  try {
    return JSON.parse(session.settings);
  } catch {
    return {};
  }
};

export const serverNow = () => Math.round(Date.now() / 1000);

export const isSessionWatched = (session: Session) =>
  (session.played || 0) / (session.totalLength || 1) > 0.95;

export const isSessionDone = (session: Session, stats: Stat[]) =>
  session.interrupts?.length
    ? stats.some((st) => st.sessionId === session.sessionId)
    : isSessionWatched(session);

export const fisherYatesShuffle = <T>(a: T[]): T[] => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

/** Loads the duration of a local file.
 * Throws an exception if not possible for some reason */
export const getVideoDuration = async (file: File): Promise<number> => {
  let duration: undefined | number;
  let trys = 0;
  const URL = window.URL || window.webkitURL;
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.onloadedmetadata = () => {
    URL.revokeObjectURL(video.src);
    duration = video.duration;
  };
  video.src = URL.createObjectURL(file);
  while (true) {
    await sleep(100);
    trys++;
    if (duration) return duration;
    if (trys > 20) {
      throw new Error('Invalid duration');
    }
  }
};

export const parseAgenda = (agenda: RawAgenda): Agenda => {
  let items;
  try {
    items = JSON.parse(agenda.agendaItems);
  } catch {
    items = [];
  }
  return { ...agenda, agendaItems: items };
};

export const buildAgendaKey = (row: string[], start: number) => {
  let i = start;
  const parts = [];
  while (row[i]) {
    parts.push(row[i]);
    i++;
  }
  return parts.join(';');
};

export const downloadFile = (url: string, name: string) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
      const blobUrl = window.URL.createObjectURL(xmlHttp.response);
      const e = document.createElement('a');
      e.href = blobUrl;
      e.download = name; //blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
      document.body.appendChild(e);
      e.click();
      document.body.removeChild(e);
    }
  };
  xmlHttp.responseType = 'blob';
  xmlHttp.open('GET', url, true);
  xmlHttp.send(null);
};
