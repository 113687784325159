import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gutterStyle } from '../utils/gutter';
import { Image } from './Image';
import { useIsTheme } from '@pp-labs/ui-components';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  neutralGutterRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: '34px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '64px 115px 80px 56px',
    },
  },
}));

/** interface for StartPage props coming from parent component Login */
interface P {
  noImage: boolean;
}

/** Wrapper for login component to handle the aspect ratios with the image */
export const StartPage: React.FC<P> = (props) => {
  const cls = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const neutralTheme = useIsTheme().neutral;
  if (mobile)
    return (
      <Grid container justifyContent="center" spacing={0}>
        <Image />
        <Grid item xs={12} className={cls.gutterAll}>
          {props.children}
        </Grid>
      </Grid>
    );
  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid
        item
        md={props.noImage ? 12 : 5}
        lg={props.noImage ? 12 : 6}
        className={clsx(neutralTheme ? cls.neutralGutterRoot : cls.gutterRoot)}
      >
        <Grid item md={12}>
          {props.children}
        </Grid>
      </Grid>
      {!props.noImage && <Image />}
    </Grid>
  );
};
