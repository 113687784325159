import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Text, FooterWrapper } from '@pp-labs/ui-components';
import { useFullscreen, useLanguage } from '../../utils/hooks';
import strings from '../../Localization/Localizer';
import { LegalDocument } from './Overlays/LegalDocument';
import './FooterButton.scss';
import { LegalDocumentType } from 'ApiHandler/dclxInterfaces';

const useStyles = makeStyles((theme) => ({
  copyright: {
    /* eslint-disable-next-line */
    display: 'inline-block',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '14px',
    },
  },
  copyrightText: {
    color: '#ffffff !important',
  },
  span: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

/** interface for Footer props coming from parent component App */
interface P {
  openCookieSettings: () => void;
}

/**
 * The Footer
 */

export const Footer = (_props: P) => {
  const fullscreen = useFullscreen();
  const cls = useStyles();
  const lang = useLanguage();
  const heightRef = useRef(null);

  const [currentHeight, setCurrentHeight] = useState<number>(128);
  useEffect(() => {
    function handleResize() {
      // @ts-ignore
      const height = heightRef?.current?.offsetHeight;
      if (height) setCurrentHeight(height);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (fullscreen || !lang) return null;
  return (
    <>
      <div style={{ height: currentHeight }} />
      <FooterWrapper refForward={heightRef}>
        <div className={cls.copyright}>
          <Text as="span" variant="copy2" className={cls.copyrightText}>
            &copy; {strings.copyrightHolder} {strings.allRightsReserved}
          </Text>
        </div>
        <span className={cls.span}>
          <div id="imprint" className={cls.copyright}>
            <LegalDocument type={LegalDocumentType.legal} />
          </div>
          <div id="conditions" className={cls.copyright}>
            <LegalDocument type={LegalDocumentType.conditions} />
          </div>
          <div id="gdpr" className={cls.copyright}>
            <LegalDocument type={LegalDocumentType.privacy} />
          </div>
        </span>
        {/* <div id="cookie_informations" className={cls.copyright}>
          <Link href="/cookieInformations" className="footerButton">
            <Text as="span" variant="copy2" className={cls.copyrightText}>
              {strings.cookieInformations}
            </Text>
          </Link>
        </div>
        <div id="cookie_settings" className={cls.copyright}>
          <Link
            onClick={() => {
              props.openCookieSettings();
            }}
            className="footerButton"
          >
            <Text as="span" variant="copy2" className={cls.copyrightText}>
              {strings.cookieSettings}
            </Text>
          </Link>
        </div> */}
      </FooterWrapper>
    </>
  );
};
