import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import strings from 'Localization/Localizer';
import { client } from 'ApiHandler/client';
import {
  LabelButton,
  TableRow,
  TableCell,
  TextField,
  EditDialog,
  Text,
} from '@pp-labs/ui-components';
import { makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import { Interrupt } from 'ApiHandler/dclxInterfaces';

const useStyle = makeStyles({
  table: {
    minWidth: '100%',
    borderSpacing: '0 4px',
    borderCollapse: 'separate',
  },
  btnLeft: {
    marginRight: '3px',
  },
  btnRight: {
    marginLeft: '3px',
  },
  timeCodeContainer: {
    maxWidth: '100px',
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '3px',
    marginRight: '3px',
  },
});

const validation = Yup.object({
  minutes: Yup.string().required(strings.required),
  seconds: Yup.string().required(strings.required),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

/** interface for EditTimeCode props coming from parent component InterruptDialogs */
interface P {
  close: () => void;
  currentInterrupt: Interrupt | null;
  sessionId: number;
  refreshInterrupts: () => Promise<void>;
}

/** Edit the interrupt timecode */
const EditTimeCode = (props: P) => {
  const cls = useStyle();
  const initialValues = {
    minutes: props.currentInterrupt
      ? Math.floor(props.currentInterrupt.timeCode / 60).toString()
      : '',
    seconds: props.currentInterrupt ? (props.currentInterrupt.timeCode % 60).toFixed(1) : '',
  };

  const getErrors = (touched: any, errors: any) => {
    return {
      minutes: touched.minutes ? errors.minutes : '',
      seconds: touched.seconds ? errors.seconds : '',
    };
  };

  const submit = async (v: F) => {
    const seconds = parseFloat(v.seconds.replace(',', '.'));
    const timecode = parseInt(v.minutes) * 60 + seconds;

    const data = {
      timecode: timecode,
      sessionId: props.sessionId,
    };
    if (props.currentInterrupt) {
      await client.put(`sessions/interrupt/${props.currentInterrupt.interruptId}`, data);
    } else {
      await client.post(`sessions/${props.sessionId}/interrupts`, data);
    }
    await props.refreshInterrupts();
    props.close();
  };
  return (
    <EditDialog title="Interrupts" close={props.close} maxWidth="md">
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={(values: F) => {
          submit(values);
        }}
      >
        {({ errors, touched }) => {
          const et = getErrors(touched, errors);
          return (
            <Form>
              <TableContainer>
                <Table className={cls.table} aria-label="simple table">
                  <TableBody>
                    <TableRow key="timestamp">
                      <TableCell>
                        <Text as="h3" variant="order3">
                          {strings.timecode}
                        </Text>
                        <div>
                          <div className={cls.timeCodeContainer}>
                            <TextField
                              name="minutes"
                              label={strings.minutesShort}
                              error={et.minutes}
                            />
                          </div>
                          <div className={cls.timeCodeContainer}>
                            <TextField
                              name="seconds"
                              label={strings.secondsShort}
                              error={et.seconds}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ float: 'right' }}>
                <LabelButton variant="secondary" className={cls.btnLeft} onClick={props.close}>
                  {strings.abort}
                </LabelButton>
                <LabelButton variant="primary" className={cls.btnRight} type="submit">
                  {strings.save}
                </LabelButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
export { EditTimeCode };
