import { Menu, MenuProps } from '@material-ui/core';
import React from 'react';

/**
 * Wrapper for MUI Menu, ensures that the Fonts are loaded correctly in Menu
 */

const AudiMenu = (props: MenuProps) => {
  return (
    <Menu {...props} container={() => document.getElementsByClassName('MuiContainer-root')[0]}>
      {props.children}
    </Menu>
  );
};

export default AudiMenu;
