import React, { useState } from 'react';
import { Text } from '@pp-labs/ui-components';
import { SessionTimeslots } from './CMSTrainings';
import AddTraining from './AddTraining';
import { IconButton, Table, TableBody, TableContainer, TableRow } from '@material-ui/core';
import { appendZero, getDisplayDate } from '../../../utils/convert';
import { Session, UserPicture, TimeSlot, ChannelTopic } from '../../../ApiHandler/dclxInterfaces';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import DeleteDialogue from '../../../utils/ConfirmDialogue/DeleteDialogue';
import {
  AdaptedIcon,
  EditIcon,
  EraseIcon,
  LabelButton,
  TableRow as DCLXTableRow,
  TableCell,
  tableStyles,
} from '@pp-labs/ui-components';
import { TrainingsSessionEdit } from './TrainingsSessionEdit';
import { client } from '../../../ApiHandler/client';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigateEvent, useUser } from '../../../utils/hooks';
import { MediaFile } from '../Documents/MediaLibrary';
import { MediaLibraryDialog } from '../Documents/MediaLibraryDialog';

/** interface for TrainingsForTopic props coming from parent component CMSTrainings */
interface P {
  timeslots: SessionTimeslots;
  topic: ChannelTopic | null;
  refresh: () => void;
  manager: boolean;
  trainers: UserPicture[];
}

/**
 * Component for listing Trainings per week/topic
 */

const useStyles = makeStyles({
  ...tableStyles(),
});

const TrainingsForTopic = (props: P) => {
  const cls = useStyles();
  const [addTrainingForSession, setAddTrainingForSession] = useState<number>(0);
  const [manageDoc, setManageDoc] = useState<TimeSlot | null>(null);
  const [toBeDeleted, setToBeDeleted] = useState<
    'none' | { type: 'session' | 'training'; id: number }
  >('none');
  const [editTimeslot, setEditTimeslot] = useState<TimeSlot | null>(null);
  const [editSession, setEditSession] = useState<'none' | { session: Session | null }>('none');

  const closeTraining = (refresh?: boolean) => {
    setAddTrainingForSession(0);
    setEditTimeslot(null);
    if (refresh) props.refresh();
  };

  const finishDelete = async () => {
    if (toBeDeleted === 'none') return;
    await client.delete(`${toBeDeleted.type}s/${toBeDeleted.id}`);
    setToBeDeleted('none');
    props.refresh();
  };

  const closeDocs = () => {
    setManageDoc(null);
    props.refresh();
  };

  const startEditSession = (thisSession: Session | null) =>
    setEditSession({ session: thisSession });

  const closeSessionEdit = (refresh?: boolean) => {
    setEditSession('none');
    if (refresh) props.refresh();
  };
  const getSession = () => {
    const sessionId = editTimeslot?.sessionId || addTrainingForSession;
    const st = props.timeslots.find((s) => s.session.sessionId === sessionId);
    return st?.session;
  };

  return (
    <>
      <div style={{ paddingBottom: '36px' }}>
        {props.topic && (
          <Text as="p" variant="order2">
            <b>{getLocalizedValue(props.topic.title)}</b>
          </Text>
        )}
        <div style={{ padding: '12px 0' }} />
        <TableContainer>
          <Table>
            <TableBody>
              {props.timeslots.map((st) => {
                return (
                  <TableRow key={`session-${st.session.sessionId}`}>
                    <TableCell style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                      <div>
                        <div style={{ float: 'left', padding: '25px 0' }}>
                          <Text as="p" variant="order3">
                            <b>{getLocalizedValue(st.session.title)}</b>
                          </Text>
                        </div>
                        <div style={{ float: 'right' }}>
                          <LabelButton
                            variant="secondary"
                            onClick={() => setAddTrainingForSession(st.session.sessionId)}
                          >
                            {strings.scheduleTraining}
                          </LabelButton>

                          {props.manager && (
                            <>
                              <IconButton onClick={() => startEditSession(st.session)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                id="delete-session"
                                onClick={() =>
                                  setToBeDeleted({ type: 'session', id: st.session.sessionId })
                                }
                              >
                                <EraseIcon />
                              </IconButton>

                              <SessionStatus session={st.session} />
                            </>
                          )}
                        </div>
                      </div>
                      <TableContainer>
                        <Table className={cls.table}>
                          <TableBody>
                            {st.timeslots.map((timeslot) => (
                              <TimeSlotView
                                key={timeslot.trainingId}
                                timeslot={timeslot}
                                trainers={props.trainers}
                                manager={props.manager}
                                delete={(id: number) =>
                                  setToBeDeleted({ type: 'training', id: id })
                                }
                                manageDocs={setManageDoc}
                                edit={setEditTimeslot}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {props.manager && (
          <LabelButton onClick={() => setEditSession({ session: null })} variant={'secondary'}>
            {strings.createNewSession}
          </LabelButton>
        )}
      </div>
      {(!!addTrainingForSession || editTimeslot) && (
        <AddTraining
          session={getSession()}
          id={addTrainingForSession}
          closeDialog={closeTraining}
          manager={props.manager}
          trainers={props.trainers}
          pre={editTimeslot || undefined}
        />
      )}
      <DeleteDialogue
        toBeDeleted={toBeDeleted === 'none' ? null : toBeDeleted.id}
        delete={finishDelete}
        reject={() => setToBeDeleted('none')}
        lot={toBeDeleted === 'none' ? false : toBeDeleted.type === 'training'}
      />
      {!!manageDoc && (
        <MediaLibraryDialog
          open={true}
          onClose={closeDocs}
          onSubmit={(files: MediaFile[]) => {
            manageDoc.documentIds = [...files.map((f) => f.mediaId)];
            client.put(`trainings/${manageDoc.trainingId}`, manageDoc);
            closeDocs();
          }}
          interactType={'selectMultiple'}
          fileTypes={'image/png,image/jpeg,application/pdf'}
          preselectedMediaIds={manageDoc.documentIds}
        />
      )}
      {props.manager && editSession !== 'none' && (
        <TrainingsSessionEdit
          topic={props.topic}
          session={editSession.session}
          close={closeSessionEdit}
        />
      )}
    </>
  );
};

interface TimeSlotProps {
  timeslot: TimeSlot;
  trainers: UserPicture[];
  manageDocs: (ts: TimeSlot) => void;
  manager: boolean;
  edit: (ts: TimeSlot) => void;
  delete: (id: number) => void;
}

const TimeSlotView = (props: TimeSlotProps) => {
  const cls = useStyles();
  const user = useUser()!;
  const navigateEvent = useNavigateEvent();

  const openTraining = () => navigateEvent(`lot/${props.timeslot.trainingId}`);

  const { timeslot } = props;
  const duration = (timeslot.endAt - timeslot.startAt) / 60;
  const trainer = props.trainers.find(
    (trainerEntry) => trainerEntry.username === timeslot.trainerName
  );
  const coTrainer = props.trainers.find(
    (trainerEntry) => trainerEntry.username === timeslot.coTrainerName
  );
  const myTraining = trainer?.username === user.sub;
  const myCoTraining = coTrainer?.username === user.sub;
  return (
    <>
      <DCLXTableRow key={`timeslot-${timeslot.trainingId}`}>
        <TableCell noLimitWidth>
          <div>
            <div style={{ display: 'inline-block' }}>
              <Text as="p" variant="copy1">
                {getDisplayDate(timeslot.startAt, true)}
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.duration}: {duration} min
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.numberParts}: {appendZero(timeslot.bookings || 0)}/
                {appendZero(timeslot.maxParticipants || 0)}
              </Text>
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.trainer}: {trainer?.givenName} {trainer?.familyName}
              </Text>
            </div>

            <div style={{ display: 'inline-block', paddingLeft: '24px' }}>
              <Text as="p" variant="copy1">
                {strings.backupTrainer}: {coTrainer?.givenName} {coTrainer?.familyName}
              </Text>
            </div>
          </div>
          <div style={{ paddingTop: '24px' }}>
            {timeslot.documents?.map((doc, index) => {
              return (
                <div key={`document-${index}`}>
                  <Text as="p" variant="copy1">
                    {doc.originalFile}
                  </Text>
                </div>
              );
            })}
          </div>
        </TableCell>
        <TableCell noLimitButtons noLimitWidth>
          {myTraining && (
            <LabelButton
              variant="secondary"
              className={cls.btnLeft}
              onClick={() => props.manageDocs(timeslot)}
            >
              {strings.openDocumentManager}
            </LabelButton>
          )}
          {(myTraining || myCoTraining) && (
            <LabelButton variant="secondary" className={cls.btnLeft} onClick={openTraining}>
              {myTraining ? strings.startTraining : strings.joinTrainingAsBackup}
            </LabelButton>
          )}
          {(props.manager || myTraining) && (
            <IconButton className={cls.btnMiddle} onClick={() => props.edit(timeslot)}>
              <EditIcon />
            </IconButton>
          )}
          {(props.manager || myTraining) && (
            <IconButton
              id="delete-session"
              className={cls.btnRight}
              onClick={() => props.delete(timeslot.trainingId)}
            >
              <EraseIcon />
            </IconButton>
          )}
        </TableCell>
      </DCLXTableRow>
    </>
  );
};

const SessionStatus = (props: { session: Session }) => {
  if (props.session.adapted) {
    return (
      <>
        <IconButton disabled>
          <AdaptedIcon />
        </IconButton>
        <Text as="span" variant="copy1">
          {strings.adapted}
        </Text>
      </>
    );
  } else {
    return (
      <Text as="span" variant="copy1">
        {strings.unadapted}
      </Text>
    );
  }
};

export default TrainingsForTopic;
