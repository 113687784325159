import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import React, { useMemo, useState } from 'react';
import { EditDialog, LabelButton, Select, TextField } from '@pp-labs/ui-components';
import {
  ChannelTopic,
  ConfigModuleIdentifiers,
  GetTest,
  PostAnswer,
  PostQuestion,
  PostTest,
  Question,
  TestType,
} from '../../../ApiHandler/dclxInterfaces';
import { AdvancedDatePicker } from '../../utils/AdvancedDatePicker';
import { RowParser, UploadCsv } from '../../../utils/csvParser/UploadCsv';
import { DownloadCsv } from '../../../utils/csvParser/DownloadCsv';
import AudiSpacer from '../../../utils/AudiSpacer';
import { Text } from '@audi/audi-ui-react';
import { useNamedConfigModule, useNotify } from '../../../utils/hooks';
import { client } from '../../../ApiHandler/client';
import { TaggingSubform } from '../Sessions/EditSubforms/TaggingSubform';
import { fakeNumber } from '../../../utils/validator';

const getValidation = () =>
  Yup.object({
    position: Yup.string().required(strings.required),
    name: Yup.string().required(strings.required),
    points: fakeNumber(),
    tag: Yup.string(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

/** interface for EditTest props coming from parent component testTable  */
interface P {
  initialTest: GetTest | null;
  topic: ChannelTopic;
  close: () => void;
  refresh: () => void;
  allowed: TestType[];
}

const getInitialDate = (initialTest: GetTest | null) =>
  initialTest?.unlockAt ? new Date(initialTest?.unlockAt * 1000) : new Date();

const exampleQuestions: Question[] = [
  {
    questionText: 'This is an example question',
    questionId: 1,
    position: 1,
    points: 0,
    answers: [
      { answerText: 'Answer 1', correct: true, answerId: 1 },
      { answerText: 'Answer 2', correct: false, answerId: 2 },
    ],
  },
  {
    questionText: 'This is another example question',
    questionId: 2,
    position: 1,
    points: 10,
    answers: [
      { answerText: 'Answer 3', correct: true, answerId: 1 },
      { answerText: 'Answer 4', correct: true, answerId: 2 },
      { answerText: 'Answer 5', correct: false, answerId: 2 },
    ],
  },
];

/** add/edit a test/exam */
export const EditTest = (props: P) => {
  const notify = useNotify();
  const usesModule = useNamedConfigModule();
  const gamification = usesModule(ConfigModuleIdentifiers.gamification);
  const [unlockAt, setUnlockAt] = useState<Date>(getInitialDate(props.initialTest));
  const [questions, setQuestions] = useState<PostQuestion[] | null>(
    props.initialTest?.questions ? null : []
  );
  const validationSchema = useMemo(getValidation, []);

  const initialValues: F = useMemo(
    () => ({
      position: props.initialTest?.examType || (props.allowed.length === 1 ? props.allowed[0] : ''),
      name: props.initialTest?.examName || '',
      tag: props.initialTest?.reportingTag || '',
      points: props.initialTest?.points.toString() || '',
    }),
    [props.initialTest, props.allowed]
  );
  const submit = async (values: F) => {
    if (questions !== null && !questions.length) {
      notify('Please upload some questions', 'error');
      return;
    }
    const data: PostTest = {
      examType: values.position as TestType,
      unlockAt: unlockAt.getTime() / 1000,
      topicId: props.topic.topicId,
      channelId: props.topic.channelId,
      questions: questions,
      examName: values.name,
      reportingTag: values.tag || getLocalizedValue(values.name),
      points: Number(values.points) || 0,
    };
    if (props.initialTest) {
      await client.put(`exams/${props.initialTest.examId}`, data);
    } else {
      await client.post('exams', data);
    }
    props.refresh();
    props.close();
  };
  const rowParser: RowParser<PostQuestion> = (lineNumber, s) => {
    const questionText = s[0];
    if (!questionText) throw new Error(`Line ${lineNumber + 1} is missing the question Text`);
    const points = gamification ? s[1] : '0';
    const isNumber = /^\d+$/.test(points);
    if (!isNumber) throw new Error(`Line ${lineNumber + 1}: Points is not a number`);
    const answers: PostAnswer[] = [];
    for (let i = gamification ? 2 : 1; i < s.length; i += 2) {
      const answerText = s[i];
      if (answerText) {
        const fakeBool = s[i + 1].toLowerCase();
        if (!['yes', 'no'].includes(fakeBool))
          throw new Error(
            `Line ${lineNumber + 1} is missing whether the answer is correct in column ${
              i + 2
            }. Valid values are yes or no.`
          );
        answers.push({
          answerText: answerText,
          correct: fakeBool === 'yes',
        });
      }
    }
    if (!answers.length) throw new Error(`Line ${lineNumber + 1} is missing an answer.`);
    return {
      questionText: questionText,
      answers: answers,
      position: lineNumber,
      points: Number(points),
    } as PostQuestion;
  };

  const getQuestions = (): Question[] =>
    props.initialTest?.questions.length ? props.initialTest.questions : exampleQuestions;

  const loadData = async () =>
    getQuestions().map((q) => {
      const res = [q.questionText];
      if (gamification) res.push(q.points.toString());
      return [...res, ...q.answers.map((a) => [a.answerText, a.correct ? 'yes' : 'no']).flat()];
    });

  return (
    <EditDialog title="Add Test" close={props.close}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ touched, errors, values }) => {
          const et = {
            position: touched.position ? errors.position : '',
            name: touched.name ? errors.name : '',
            points: touched.points ? errors.points : '',
          };
          return (
            <Form>
              <TextField name="name" label="Name" error={et.name} />
              <Select name="position" label="Pre/Post" error={et.position}>
                {props.allowed.map((a) => (
                  <option key={a} value={a}>
                    {a === 'pre' ? strings.preTest : strings.postTest}
                  </option>
                ))}
              </Select>
              {gamification && (
                <TextField name="points" label={strings.examPointsOnCompletion} error={et.points} />
              )}
              <AudiSpacer spaceStackEnd="l" />
              <Text>Unlock at:</Text>
              <AdvancedDatePicker initialDate={unlockAt} onChange={setUnlockAt} />
              <AudiSpacer spaceStackEnd="l" />

              <UploadCsv
                setResult={setQuestions}
                headerLine={null}
                additionalHeaderLine={null}
                constantLineLength={false}
                onFileChange={() => setQuestions([])}
                rowParser={rowParser}
                title={props.initialTest ? 'Replace existing questions' : 'Upload questions'}
              />

              <DownloadCsv
                loadData={loadData}
                fileName={props.initialTest?.questions.length ? 'test.csv' : 'exampleTest.csv'}
                title={
                  props.initialTest ? 'Download existing questions' : 'Download example questions'
                }
              />
              <AudiSpacer spaceStackEnd="l" />

              <TaggingSubform
                type="exams"
                placeholder={
                  !errors.name && values.tag?.length === 0
                    ? getLocalizedValue(JSON.stringify(values.name))
                    : undefined
                }
              />

              <LabelButton variant="primary" type="submit">
                Submit Test
              </LabelButton>
            </Form>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
