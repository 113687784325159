import { MediaLibrary } from './Documents/MediaLibrary';
import { Text } from '@pp-labs/ui-components';

/** Main CMS Media Library */
export const DCLXMediaLibrary = () => {
  return (
    <div>
      <Text variant="order2" weight="bold">
        Media Library
      </Text>
      <MediaLibrary
        onSubmit={() => {}}
        preselectedMediaIds={[]}
        interactType="none"
        editMode="advanced"
      />
    </div>
  );
};
