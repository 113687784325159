/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */

import React from 'react';
import { FilterParams, useDashboardData } from './useDashboardData';
import strings from 'Localization/Localizer';
import { BarDiagram } from './BarDiagram';
import { Select } from '@audi/audi-ui-react';
import AudiSpacer from 'utils/AudiSpacer';

/** interface for UserDiagrams props coming from parent component Dashboard */
interface P {
  filters: Omit<FilterParams, 'category'>;
}
interface DataSet {
  reportingTag: string;
  count: number;
}

interface UserData {
  invited: { [key: string]: number };
  registered: { [key: string]: number };
  online: { [key: string]: number };
  preTestsByTag: DataSet[];
  postTestsByTag: DataSet[];
  surveysByTag: DataSet[];
}

export const CHART_COLORS = {
  black: 'rgb(0, 0, 0)',
};

/** Renders the users page of the Dashboard */
export const UserDiagrams = ({ filters }: P) => {
  const data: UserData = useDashboardData({ category: 'users', ...filters }).data;
  const [role, setRole] = React.useState<string>('all');

  if (data) {
    return (
      <div style={{ padding: '64px', overflow: 'auto' }}>
        <div style={{ maxWidth: '400px' }}>
          <Select
            name={'role'}
            label={'User type'}
            value={role}
            onChange={(
              e: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => setRole(e.target.value as string)}
            inputId={'role'}
          >
            <option value={'all'}>All</option>
            <option value={'visitors'}>Visitors</option>
            <option value={'sessionModerators'}>Session Moderators</option>
            <option value={'sessionManagers'}>Session Managers</option>
          </Select>
        </div>

        <AudiSpacer spaceStackEnd="m" />
        <BarDiagram
          labels={
            role === 'all' || role === 'visitors'
              ? [
                  strings.invited,
                  strings.registered,
                  strings.onlineNow,
                  ...data.preTestsByTag.map(
                    (e: DataSet) => `${strings.preTest} ${e.reportingTag} ${strings.completed}`
                  ),
                  ...data.postTestsByTag.map(
                    (e: DataSet) => `${strings.postTest} ${e.reportingTag} ${strings.completed}`
                  ),
                  ...data.surveysByTag.map(
                    (e: DataSet) =>
                      `${strings.cmsMenuSurvey} ${e.reportingTag} ${strings.completed}`
                  ),
                ]
              : [strings.invited, strings.registered, strings.onlineNow]
          }
          items={
            role === 'all' || role === 'visitors'
              ? [
                  data.invited[role] || 0,
                  data.registered[role] || 0,
                  data.online[role] || 0,
                  ...data.preTestsByTag.map((e: DataSet) => e.count),
                  ...data.postTestsByTag.map((e: DataSet) => e.count),
                  ...data.surveysByTag.map((e: DataSet) => e.count),
                ]
              : [data.invited[role] || 0, data.registered[role] || 0, data.online[role] || 0]
          }
          vertical={true}

          // Will be used in future code, do not delete

          // furtherLabels={[strings.percentageInvitedP, strings.percentageRegisteredP]}
          // furtherItems={
          //   role === 'all' || role === 'visitors'
          //     ? [
          //         [
          //           null,
          //           !!data.invited[role]
          //             ? Math.round((data.registered[role] / data.invited[role]) * 100)
          //             : 0,

          //           !!data.invited[role]
          //             ? Math.round((data.online[role] / data.invited[role]) * 100)
          //             : 0,
          //           ...data.preTestsByTag.map((e: DataSet) =>
          //             !!data.invited[role] ? Math.round((e.count / data.invited[role]) * 100) : 0
          //           ),
          //           ...data.postTestsByTag.map((e: DataSet) =>
          //             !!data.invited[role] ? Math.round((e.count / data.invited[role]) * 100) : 0
          //           ),
          //           ...data.surveysByTag.map((e: DataSet) =>
          //             !!data.invited[role] ? Math.round((e.count / data.invited[role]) * 100) : 0
          //           ),
          //         ],
          //         [
          //           null,
          //           null,
          //           !!data.registered[role]
          //             ? Math.round((data.online[role] / data.registered[role]) * 100)
          //             : 0,
          //           ...data.preTestsByTag.map((e: DataSet) =>
          //             !!data.registered[role]
          //               ? Math.round((e.count / data.registered[role]) * 100)
          //               : 0
          //           ),
          //           ...data.postTestsByTag.map((e: DataSet) =>
          //             !!data.registered[role]
          //               ? Math.round((e.count / data.registered[role]) * 100)
          //               : 0
          //           ),
          //           ...data.surveysByTag.map((e: DataSet) =>
          //             !!data.registered[role]
          //               ? Math.round((e.count / data.registered[role]) * 100)
          //               : 0
          //           ),
          //         ],
          //       ]
          //     : [
          //         [
          //           null,
          //           !!data.invited[role]
          //             ? Math.round((data.registered[role] / data.invited[role]) * 100)
          //             : 0,
          //           !!data.invited[role]
          //             ? Math.round((data.online[role] / data.invited[role]) * 100)
          //             : 0,
          //         ],
          //         [
          //           null,
          //           null,
          //           !!data.invited[role]
          //             ? Math.round((data.online[role] / data.invited[role]) * 100)
          //             : 0,
          //         ],
          //       ]
          // }
        />
      </div>
    );
  } else {
    return <></>;
  }
};
