import { AMA, Answer } from '../ApiHandler/dclxInterfaces';

interface Pos {
  position: number;
}
export const byPosition = (a: Pos, b: Pos) => a.position - b.position;

interface WeekPos extends Pos {
  week?: number;
}

export const byWeekAndPosition = (a: WeekPos, b: WeekPos) =>
  (a.week || 0) - (b.week || 0) || a.position - b.position;

interface Date {
  startAt?: number;
}
export const byDate = (a: Date, b: Date): number => (a.startAt || 0) - (b.startAt || 0);

export const byVotes = (a: AMA, b: AMA) => b.votes - a.votes;

export const byAnswerId = (a: Answer, b: Answer) => a.answerId - b.answerId;
