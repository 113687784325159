import * as Yup from 'yup';
import strings from '../Localization/Localizer';
import { getErrorMessage } from '../auth/utils';

const images = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];
const files = ['application/pdf'];
const mixed = [...images, ...files];
const maxMb = 100;
const maxFileSize = maxMb * 1024 * 1024;
const maxMbProfile = 1;
const maxFileSizeProfile = maxMbProfile * 1024 * 1024;

export const requiredMediaFile = Yup.mixed().required(strings.required);

export const requiredVideo = Yup.mixed()
  .required(strings.required)
  .test('fileFormat', strings.pleaseUseMp4, (value) => value && ['video/mp4'].includes(value.type));

export const optionalVideo = Yup.mixed().test(
  'fileFormat',
  strings.pleaseUseMp4,
  (value) => !value || ['video/mp4'].includes(value.type)
);

export const requiredThumb = Yup.mixed()
  .required(strings.required)
  .test('fileFormat', strings.pleaseUseJpg, (value) => value && images.includes(value.type));

export const optionalThumb = Yup.mixed().test(
  'fileFormat',
  strings.pleaseUseJpg,
  (value) => !value || images.includes(value.type)
);

export const requiredProfilePicture = Yup.mixed().test(
  'fileFormat',
  strings.formatString(strings.unsupportedImageFileSize, { maxMbProfile: maxMbProfile }).toString(),
  (value) => value && mixed.includes(value.type) && value.size <= maxFileSizeProfile
);

export const optionalProfilePicture = Yup.mixed().test(
  'fileFormat',
  strings.formatString(strings.unsupportedImageFileSize, { maxMbProfile: maxMbProfile }).toString(),
  (value) => !value || (mixed.includes(value.type) && value.size <= maxFileSizeProfile)
);

export const requiredMixedFileWithLimit = Yup.mixed().test(
  'fileFormat',
  strings.formatString(strings.unsupportedFileSize, { maxMbProfile: maxMbProfile }).toString(),
  (value) => value && mixed.includes(value.type) && value.size <= maxFileSize
);

export const optionalMixedFileWithLimit = Yup.mixed().test(
  'fileFormat',
  strings.formatString(strings.unsupportedFileSize, { maxMbProfile: maxMbProfile }).toString(),
  (value) => !value || (mixed.includes(value.type) && value.size <= maxFileSize)
);

export const requiredFile = Yup.mixed().test(
  'fileFormat',
  strings.pleaseUsePdf,
  (value) => value && files.includes(value.type)
);

export const optionalFile = Yup.mixed().test(
  'fileFormat',
  strings.pleaseUsePdf,
  (value) => !value || files.includes(value.type)
);

export const newPasswordValidator = () =>
  Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[(@$!%*#?&,_)])[A-Za-z\d(@$!%*#?&,_)]{12,}$/,
      strings.passwordInvalid
    )
    .required(strings.required);

export const confirmPasswordValidator = () =>
  Yup.string()
    .oneOf([Yup.ref('newPassword')], strings.passwordsMustMatch)
    .required(strings.required);

export const sixDigitCode = () =>
  Yup.string()
    .matches(/^[0-9]{6}$/, strings.validationCode6Numbers)
    .required(strings.required);

export const usernameValidator = () =>
  Yup.string()
    .strict(true)
    .trim(strings.avoidWhitespaces)
    .required(getErrorMessage(104, strings.needprovideUsername));

export const atLeastOneLanguage = () =>
  Yup.array().test('oneLanguageRequired', strings.oneLanguageRequired, function (list) {
    return (
      list?.find((e) => {
        return e.value !== undefined;
      }) !== undefined
    );
  });

export const maxCharacterAndOneLang = (max: number) =>
  Yup.array()
    .test('oneLanguageRequired', strings.oneLanguageRequired, function (list) {
      return (
        list?.find((e) => {
          return e.value !== undefined;
        }) !== undefined
      );
    })
    .test('checkMaxCharacter', `max ${max} characters only`, function (list) {
      return (
        list?.find((e) => {
          return e.value?.length < max;
        }) !== undefined
      );
    });

export const atLeastOneEmail = () =>
  Yup.array()
    .of(
      Yup.object().shape({
        lang: Yup.string(),
        value: Yup.string().email(strings.invalid),
      })
    )
    .test('oneLanguageRequired', strings.oneLanguageRequired, function (list) {
      return (
        list?.find((e) => {
          return e.value !== undefined;
        }) !== undefined
      );
    });

/** Yup.number() causes the type to be an actual number with which the audi TextField can't deal,
 * especially when it is zero. */
export const fakeNumber = () => Yup.string().matches(/^\d+$/, strings.needsToBeNumber);
