import { Form, Formik } from 'formik';
import { Text } from '@pp-labs/ui-components';
import strings from '../../Localization/Localizer';
import { Checkbox } from '@pp-labs/ui-components';
import React from 'react';
import * as Yup from 'yup';
import { CustomError, FormSoftButton } from '../../SoftButton';
import { useNotify } from '../../utils/hooks';
import { getFormErrorMessage } from '../utils';
import { MfaBadges } from '../MfaBadges';
import { VimeoVideo } from './VimeoVideo';

/** interface for MfaInfo props coming from parent component directRegisterForm */
interface P {
  finish: () => void;
  mfa: boolean;
}

const phase3V = () =>
  Yup.object({
    mfa: Yup.boolean().required(strings.required).oneOf([true], strings.required),
    keepMfa: Yup.boolean().required(strings.required).oneOf([true], strings.required),
  });

/**
 * (optional) Phase 3 of Register Process
 * some checkboxes to ensure MFA Register runs smoothly
 */
export const MfaInfo = (props: P) => {
  const notify = useNotify();
  const handleError = (error: CustomError) => {
    notify(getFormErrorMessage(error), 'error');
  };

  return (
    <Formik
      initialValues={{
        mfa: false,
        keepMfa: false,
      }}
      validationSchema={phase3V()}
      onSubmit={() => {
        props.finish();
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <Text as="h2" variant="order3" weight={'bold'}>
            {strings.registration}
          </Text>
          <div style={{ padding: '32px 0' }} />
          {props.mfa && <VimeoVideo />}
          <div style={{ padding: '32px 0' }} />
          <Text as="h3" variant="order4">
            {strings.authenticatorApp}
          </Text>
          <div style={{ padding: '10px 0' }} />
          <div>
            <Text variant="copy1" as="p" spaceStackEnd="m">
              {strings.registerAuthenticatorText1}
            </Text>
            <Text variant="copy1" as="p" spaceStackEnd="m" weight="bold">
              {strings.registerAuthenticatorText2}
            </Text>
            <Text variant="copy1" as="p" spaceStackEnd="m" weight="bold">
              {strings.registerAuthenticatorText3}
            </Text>
          </div>
          <MfaBadges />
          <div style={{ padding: '20px 0' }}>
            <Checkbox
              currentValue={values.mfa}
              name={'mfa'}
              error={touched.mfa ? errors.mfa : ''}
              value={'true'}
              handle={setFieldValue}
              label=""
            >
              <Text>{strings.downloadedMfa}</Text>
            </Checkbox>
          </div>
          <div style={{ padding: '20px 0' }}>
            <Checkbox
              currentValue={values.keepMfa}
              name={'keepMfa'}
              error={touched.keepMfa ? errors.keepMfa : ''}
              value={'true'}
              handle={setFieldValue}
              label=""
            >
              <Text>{strings.keepMFA}</Text>
            </Checkbox>
          </div>
          <FormSoftButton
            variant="primary"
            relevantFields={['mfa', 'keepMfa']}
            errorCallback={handleError}
          >
            {strings.next}
          </FormSoftButton>
        </Form>
      )}
    </Formik>
  );
};
