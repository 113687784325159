import audiLogo from '../../media/Audi_Rings_bl-RGB.svg';
import TechTest from './TechTest';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  noShadow: {
    background: 'transparent !important',
  },
  img: {
    height: 'auto',
    width: 200,
  },
});

/** Open access for everyone to the Jitsi Techtest. No login required */
export const TechTestRoute = () => {
  const cls = useStyles();
  const history = useHistory();
  const close = () => history.push('/');
  return (
    <div style={{ padding: 56 }}>
      <IconButton
        color="inherit"
        aria-label="start"
        className={cls.noShadow}
        disableRipple
        onClick={close}
      >
        <img src={audiLogo} alt="audi" color="green" className={cls.img} />
      </IconButton>
      <TechTest onFinish={close} external hideSteps />
    </div>
  );
};
