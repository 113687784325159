import React from 'react';
import QRCode from 'qrcode.react';
import { Form, Formik } from 'formik';
import { Text, useThemeConfig } from '@pp-labs/ui-components';

import { MFAData, validation } from './MFAUtils';
import strings from '../Localization/Localizer';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import StepIndicator from '../utils/StepIndicator';
import { SupportEmail } from './SupportEmail';
import { useEventSettings } from 'utils/hooks';

/** interface for MFARegisterForm props coming from parent component Login  */
interface P {
  code: string;
  username: string;
  finishMFA: (values: MFAData) => void;
  loading: boolean;
  reset?: boolean;
}
/**
 * Form for setting up MFA
 */
const MFARegisterForm = (props: P) => {
  const eventSettings = useEventSettings();
  const theme = useThemeConfig();
  const initialValues: MFAData = {
    validationCode: '',
  };

  const str = `otpauth://totp/${props.username}?secret=${props.code}&issuer=${encodeURIComponent(
    eventSettings?.title!
  )}`;
  return (
    <div>
      {props.reset ? (
        <Text as="h2" variant="order3" weight={'bold'}>
          {strings.resetMFA}
        </Text>
      ) : (
        <>
          <StepIndicator step={4} numSteps={6} />
          <Text as="h2" variant="order3" weight={'bold'}>
            {strings.registration}
          </Text>
        </>
      )}
      <div style={{ padding: '32px 0' }} />
      <Text as="h3" variant="order4">
        {strings.setupMfa}
      </Text>
      <div style={{ padding: '10px 0' }} />
      <Text as="p" variant="copy1">
        {strings.step1}
      </Text>
      <div style={{ padding: '30px 0' }} />

      <Text as="p" variant="copy1">
        {strings.step2}
      </Text>

      <div style={{ padding: '8px 0' }} />
      <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 30 }}>
        <QRCode
          value={str}
          bgColor={theme.colorScheme.background}
          fgColor={theme.colorScheme.text}
        />
        <div style={{ padding: '0 10px' }}>
          <Text as="p" variant="copy2">
            {strings.enterCodeDirectly}
          </Text>
          <Text as="p" variant="copy2">
            {props.code}
          </Text>
        </div>
      </div>
      <div style={{ padding: '10px 0' }} />

      <Text as="p" variant="copy1">
        {strings.step3}
      </Text>

      <div style={{ padding: '7px 0' }} />

      <Formik
        initialValues={initialValues}
        validationSchema={validation()}
        onSubmit={props.finishMFA}
      >
        {({ touched, errors }) => (
          <Form>
            <div style={{ maxWidth: 340, marginBottom: 28 }}>
              <TextField
                name={'validationCode'}
                label={strings.enterMfaCode}
                error={touched.validationCode ? errors.validationCode : ''}
              />
            </div>
            <LabelButton variant="primary" type="submit" disabled={props.loading}>
              {strings.verifyMfaCode}
            </LabelButton>
          </Form>
        )}
      </Formik>
      <div style={{ paddingTop: '24px' }}>
        <SupportEmail />
      </div>
    </div>
  );
};

export default MFARegisterForm;
