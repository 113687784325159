import strings, { getLocalizedValue } from '../../Localization/Localizer';
import { useEffect, useMemo, useState } from 'react';
import { AgendaItem, Agenda as IAgenda, RawAgenda } from '../../ApiHandler/dclxInterfaces';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { AgendaDay } from './AgendaDay';
import { format } from 'date-fns';
import { dateOnly } from '../../config';
import { GeneralTab } from '../../utils/GeneralTab';
import { useCurrentPageChannelId, useLoad } from '../../utils/hooks';
import { parseAgenda } from '../../utils/convert';
import { client } from 'ApiHandler/client';

type Day = {
  daytime: number;
  items: AgendaItem[];
};

type SectionType =
  | 'onDemand'
  | 'live'
  | 'conf'
  | 'topic'
  | 'agenda'
  | 'imageGallery'
  | 'surveys'
  | 'faq';
interface Section {
  channelId: number;
  sectionId: number;
  sectionKey: SectionType;
  title: string;
  position: number;
  customSettings: string;
  disableVideoProgression: boolean;
  isActive?: boolean;
}

export const AgendaWrapper = (props: { channelId: number; useTimezone?: number }) => {
  const [agendi] = useLoad<RawAgenda[]>('agenda');
  const parsed = useMemo(
    () => agendi?.filter((a) => a.channelId === props.channelId).map((a) => parseAgenda(a)),
    [agendi, props.channelId]
  );
  if (!parsed) return null;
  return (
    <div>
      {parsed.map((agenda) => (
        <AgendaView agenda={agenda} useTimezone={props.useTimezone} />
      ))}
    </div>
  );
};

export const AgendaView = (props: { agenda: IAgenda | null; useTimezone?: number }) => {
  const [sectionName, setSectionName] = useState<string>('');
  const currentChannelId = useCurrentPageChannelId();
  const fetch = async () => {
    const sectionsData: Section[] = (await client.get('channels/sections')).data;
    setSectionName(
      sectionsData.filter((s) => s.channelId === currentChannelId && s.sectionKey === 'agenda')[0]
        .title
    );
  };
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);
  const agendaInfo = useMemo<{ days: Day[]; currentDay: number } | null>(() => {
    if (!props.agenda) return null;
    const agendi: Day[] = [];
    props.agenda.agendaItems.forEach((i) => {
      const fullDate = new Date(i.time);
      const dayTime = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate()
      ).getTime();
      const dayIndex = agendi.findIndex((d) => d.daytime === dayTime);
      if (dayIndex > -1) {
        agendi[dayIndex].items.push(i);
      } else {
        agendi.push({ daytime: dayTime, items: [i] });
      }
    });

    const selectedIndex = agendi
      .sort((a, b) => {
        return a.daytime - b.daytime;
      })
      .findIndex((d) => d.daytime > Date.now());

    return {
      days: agendi,
      currentDay:
        selectedIndex === 0
          ? selectedIndex
          : selectedIndex > -1
          ? selectedIndex - 1
          : agendi.length - 1,
    };
  }, [props.agenda]);
  if (!props.agenda || !agendaInfo) return null;
  return (
    <LayoutItem id="agenda" spaceStackEnd="xxl">
      {sectionName ? (
        <Text variant="order2" spaceStackEnd="l">
          {getLocalizedValue(sectionName)}
        </Text>
      ) : (
        <Text variant="order2" spaceStackEnd="l">
          {strings.agenda}
        </Text>
      )}
      <GeneralTab
        start={agendaInfo.currentDay}
        tabType="no-bg-tabs"
        tabLabels={agendaInfo.days.map(
          (d) => d.items[0]?.shortDayTitle || format(d.daytime, dateOnly)
        )}
        tabContents={agendaInfo.days.map((a, i) => (
          <AgendaDay
            key={i}
            agendaItems={a.items}
            dayTime={a.daytime}
            agendaKey={props.agenda!.agendaKey}
            useTimezone={props.useTimezone}
          />
        ))}
      />
    </LayoutItem>
  );
};
