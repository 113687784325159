import { SurveyLanding } from '@pp-labs/survey';
import { useParams } from 'react-router';
import { SurveyProvider } from './SurveyProvider';

/** Shows the suvey landing page */
export const OverallSurvey = () => {
  const params = useParams<{ event: string; id: string }>();

  return (
    <SurveyProvider>
      <SurveyLanding initialSelectedSurveyId={parseInt(params.id)} />
    </SurveyProvider>
  );
};
