import React, { useMemo, useState } from 'react';
import {
  EditDialog,
  LabelButton,
  LocalizedForm,
  NativeCheckbox,
  TextField,
} from '@pp-labs/ui-components';
import { CmsNewDatePicker } from 'DCLX/utils/CmsNewDatePicker';
import strings, { useLocalizedLanguages } from 'Localization/Localizer';
import AudiSpacer from 'utils/AudiSpacer';
import * as Yup from 'yup';
import { atLeastOneLanguage } from 'utils/validator';
import { makeStyles } from '@material-ui/core';
import { Channel } from 'ApiHandler/dclxInterfaces';
import { Editor } from 'features/Editor/Editor';
import { Formik } from 'formik';
import { client } from 'ApiHandler/client';

const useStyle = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: '18px',
  },
  checkboxes: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
  },
});

/** interface for MessageDialog props coming from parent component Message */
interface P {
  close: (open: boolean) => void;
  selectedChannels: number[];
  channels: Channel[];
  refresh: () => void;
}

interface FormData {
  title: string;
  message: string;
  channel: number[];
  startAt: Date;
}

const validation = Yup.object({
  title: atLeastOneLanguage(),
  message: atLeastOneLanguage(),
}).required(strings.required);

/** Allows to add/edit Messages */
const MessageDialog = (props: P) => {
  const cls = useStyle();
  const initialValues: FormData = {
    title: '',
    message: '',
    channel: [],
    startAt: new Date(),
  };
  const [dateStart, setDateStart] = useState<Date>(initialValues.startAt);
  const [scheduleMessage, setScheduleMessage] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
  const activeChannels = useMemo(
    () =>
      props.channels.filter(({ channelId: ids }) => props.selectedChannels.some((s) => s === ids)),
    [props.channels, props.selectedChannels]
  );
  const languages = useLocalizedLanguages(activeChannels);

  const getErrors = (touched: any, errors: any) => {
    return {
      title: touched.title ? errors.title : '',
      message: touched.message ? errors.message : '',
    };
  };

  const submitToServer = async (v: FormData) => {
    const data = {
      title: JSON.stringify(v.title),
      message: JSON.stringify(v.message),
      channels: props.selectedChannels,
      validFrom: Math.round(dateStart.getTime() / 1000),
    };

    try {
      await client.post(`/notifications`, data);
      props.close(false);
      props.refresh();
    } catch {}
  };

  return (
    <EditDialog title={strings.cmsMenuMessages} close={() => props.close(false)} maxWidth="md" cms>
      <Formik initialValues={initialValues} validationSchema={validation} onSubmit={submitToServer}>
        {({ errors, touched }) => {
          const et = getErrors(touched, errors);
          return (
            <LocalizedForm
              languages={languages}
              localizeInputs={['title', 'message']}
              initialValues={{
                message: '',
              }}
              autoParse={[]}
              onLanguageChange={(i: number) => {
                setSelectedLanguage(i);
              }}
            >
              <TextField name="title" label={'Title'} error={et.title} />
              <input name="message" hidden />
              <Editor formikName="message" label={'Message'} selectedLanguage={selectedLanguage} />
              <AudiSpacer spaceStackEnd={'m'} />
              <div className={cls.checkboxes}>
                <NativeCheckbox
                  inputId={`adapted`}
                  onChange={() => setScheduleMessage(false)}
                  checked={!scheduleMessage}
                >
                  {strings.sendNow}
                </NativeCheckbox>
                <NativeCheckbox
                  inputId={`adapted`}
                  onChange={() => setScheduleMessage(true)}
                  checked={scheduleMessage}
                >
                  {strings.scheduleMessage}
                </NativeCheckbox>
              </div>
              {scheduleMessage && (
                <>
                  <AudiSpacer spaceStackEnd={'s'} />
                  <CmsNewDatePicker
                    text={strings.startsAt}
                    initialDate={initialValues.startAt}
                    onChange={setDateStart}
                  />
                </>
              )}
              <AudiSpacer spaceStackEnd={'s'} />
              <div className={cls.buttonsWrapper}>
                <LabelButton variant="secondary" onClick={() => props.close(false)}>
                  {strings.cancel}
                </LabelButton>
                <LabelButton type="submit" variant="primary" className={cls.submitButton}>
                  {strings.save}
                </LabelButton>
              </div>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};

export { MessageDialog };
