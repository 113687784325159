import { makeStyles, Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import { useTheme } from '@pp-labs/ui-components';

/**
 * Styled Tooltip
 */

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#eee',
  },
  arrow: {
    color: '#eee',
  },
}));

const useDarkStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#151515',
    border: '1px solid #ffffff',
  },
  arrow: {
    color: '#eee',
    borderTop: '1px solid #ffffff',
  },
}));

const useLightStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#011D42',
    boxShadow: '0px 3px 15px 0px #000000',
  },
  arrow: {
    color: '#011D42',
  },
}));

export const AudiTooltip = (props: TooltipProps) => {
  const classes = useStyles();
  const darkClasses = useDarkStyles();
  const lightClasses = useLightStyles();
  const isTheme = useTheme();
  const { children, title, ...rest } = props;
  return (
    <MuiTooltip
      title={title}
      {...rest}
      classes={
        isTheme.name === 'audi' ? classes : isTheme.variant === 'dark' ? darkClasses : lightClasses
      }
      id={isTheme.variant === 'light' ? 'lightTooltip' : undefined}
    >
      {children}
    </MuiTooltip>
  );
};
