import React, { useMemo, useState } from 'react';
import { ImageList, ImageListItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import '../../../features/Sessions/Sessions.scss';
import styles from '../../../features/Sessions/styles';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { CognitoUser, Session, SessionType, TimeSlot } from '../../../ApiHandler/dclxInterfaces';
import DCLXCalendar from '../Booking/DCLXCalendar';
import { getDisplayDate, getTime } from '../../../utils/convert';
import { doesRoleMatch, Role } from '../../../utils/RoleDefinitions';
import { gutterStyle } from '../../../utils/gutter';
import DeleteDialogue from '../../../utils/ConfirmDialogue/DeleteDialogue';
import CalendarExport from '../../../utils/CalendarExport';
import { AlreadyDoneOverlay } from './AlreadyDoneOverlay';
import { client } from '../../../ApiHandler/client';
import { useEventSettings, useUser } from '../../../utils/hooks';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { followProgression, generateLockMatrix, useProgression } from '../../../progression';
import { SessionScheduleButtons } from './SessionScheduleButtons';
import { BookingScheduleButtons } from './BookingScheduleButtons';
import SessionsOverlay from './SessionsOverlay';
import { EditorParser, getSimpleText } from 'features/Editor/EditorParser';
import { AudiTooltip } from 'utils/AudiTooltip';
import Duration from 'utils/Duration';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  cancelBtn: {
    margin: '0',
  },
  ...gutterStyle(theme),
  tileContentWrap: {
    padding: '0 8px',
  },
  onDemandTime: {
    right: '20px',
    bottom: '8px',
    padding: '3px 12px',
    zIndex: 100,
    position: 'absolute',
    background: '#000000ba',
    color: '#ffffff !important',
  },
}));

/** interface for SessionTiles props coming from parent components Topic and DCLXMyTrainings  */
interface P {
  sessions: Session[];
  maxCols?: number;
  firstUnlocked: boolean;
  unlockRequired: boolean;
  refresh: () => void;
  disableVideoProgression: boolean;
}

/**
 * Displays Sessions of a topic in a tile view
 */

const SessionTiles = (props: P) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useUser()!;
  const progression = useProgression();
  /** Stores information about all locked sessions */
  const lockMatrix = useMemo(
    () =>
      followProgression(user) && progression !== 'loading'
        ? generateLockMatrix(
            props.sessions,
            progression.info.stats,
            !props.firstUnlocked,
            props.disableVideoProgression
          )
        : [],
    [props, user, progression]
  );
  const [bookingDeleted, setBookingDeleted] = useState<{ session: Session; slot: TimeSlot } | null>(
    null
  );
  const [bookSession, setBookSession] = useState<Session | null>(null);

  if (progression === 'loading') return null;
  const initDelete = (session: Session, timeslot: TimeSlot) =>
    setBookingDeleted({ session: session, slot: timeslot });
  const reject = () => setBookingDeleted(null);

  const cancel = async () => {
    if (!bookingDeleted) return;
    const id = bookingDeleted.slot.trainingId;
    await client.delete(`trainings/${id}/bookings`);
    await progression.schedule.removeFromSchedule(bookingDeleted.session);
    reject();
    props.refresh();
  };

  const openCalendar = (session: Session) => setBookSession(session);
  const closeCalendar = (refresh?: boolean) => {
    setBookSession(null);
    if (refresh) props.refresh();
  };
  const isSessionLocked = (session: Session): boolean =>
    !!lockMatrix.find((l) => l.sessionId === session.sessionId)?.locked;

  return (
    <>
      {!!bookingDeleted && (
        // 1 opens the dialog.
        <DeleteDialogue toBeDeleted={1} delete={cancel} reject={reject} booking />
      )}
      <ImageList cols={mobile ? 1 : props.maxCols || 2} rowHeight="auto" gap={0}>
        {props.sessions.map((s) => {
          return (
            <ImageListItem key={`session-${s.sessionId}`} cols={1}>
              {s.sessionType === SessionType.DEMAND ? (
                <OnDemandTile onDemand={s} locked={isSessionLocked(s)} user={user} />
              ) : s.sessionType === SessionType.TRAINING ? (
                <TrainingsTile training={s} book={openCalendar} user={user} delete={initDelete} />
              ) : (
                <EventTile event={s} />
              )}
            </ImageListItem>
          );
        })}
      </ImageList>

      {!!bookSession && <DCLXCalendar open={true} session={bookSession} close={closeCalendar} />}
    </>
  );
};

interface GeneralProps {
  user: CognitoUser;
  book: (session: Session) => void;
  delete: (session: Session, slot: TimeSlot) => void;
}

interface TTP extends GeneralProps {
  training: Session;
}

const TrainingsTile = (props: TTP) => {
  const cls = useStyles();
  const progression = useProgression();
  const event = useEventSettings()?.identifier || '';
  if (progression === 'loading') return null;
  const timeslot = progression.info.timeSlots.find(
    (ts) => ts.sessionId === props.training.sessionId
  );
  return (
    <div className={cls.tile}>
      <div className={cls.sessionWrapper}>
        <div style={{ position: 'relative' }}>
          <img className={cls.thumb} src={props.training.thumbnail?.url} alt="Thumbnail" />
          <BookingScheduleButtons session={props.training} timeslot={timeslot} {...props} />
        </div>
        {timeslot && <SessionsOverlay session={props.training} timeSlot={timeslot} />}
      </div>

      <div className={cls.tileContentWrap}>
        {timeslot ?
          <NavLink to={`/${event}/lot/${timeslot.trainingId}`}>
            <div style={{ paddingTop: 6 }} title={getLocalizedValue(props.training.title)}>
              <Text as="h3" variant="order4" weight={'bold'} className={cls.maxTwoLines}>
                {getLocalizedValue(props.training.title)}
              </Text>
            </div>
          </NavLink> : <div style={{ paddingTop: 6 }} title={getLocalizedValue(props.training.title)}>
            <Text as="h3" variant="order4" weight={'bold'} className={cls.maxTwoLines}>
              {getLocalizedValue(props.training.title)}
            </Text>
          </div>}
        {timeslot && (
          <div style={{ paddingTop: 16 }}>
            <Text as="p" variant="copy1" weight={'bold'}>
              {strings.booked}: {getDisplayDate(timeslot.startAt, true)} -{' '}
              {getDisplayDate(timeslot.endAt, true, true)}
            </Text>
          </div>
        )}

        <AudiTooltip
          title={<EditorParser inputString={getLocalizedValue(props.training.description)} />}
          enterDelay={800}
          placement="top-start"
          arrow
        >
          <div style={{ paddingTop: timeslot ? undefined : 6 }}>
            <Text as="p" variant="copy1" className={cls.maxTwoLines}>
              {getSimpleText(getLocalizedValue(props.training.description))}
            </Text>
          </div>
        </AudiTooltip>
        {timeslot && (
          <CalendarExport
            start={new Date(timeslot.startAt * 1000)}
            end={new Date(timeslot.endAt * 1000)}
            title={props.training.title}
            description={props.training.description}
            sessionTile={true}
          />
        )}
      </div>
    </div>
  );
};

interface ETP {
  event: Session;
}

const EventTile = (props: ETP) => {
  const cls = useStyles();
  const title = getLocalizedValue(props.event.title);
  const description = getLocalizedValue(props.event.description);
  const progression = useProgression();
  const event = useEventSettings()?.identifier || '';
  if (progression === 'loading') return null;

  return (
    <div className={cls.tile}>
      <div className={cls.sessionWrapper}>
        <div style={{ position: 'relative' }}>
          <img className={cls.thumb} src={props.event.thumbnail?.url || ''} alt="Thumbnail" />
          <SessionScheduleButtons
            isInSchedule={progression.schedule.isInSchedule(props.event)}
            removeFromSchedule={() => progression.schedule.removeFromSchedule(props.event)}
            addToSchedule={() => progression.schedule.addToSchedule(props.event)}
          />
        </div>
        <SessionsOverlay session={props.event} />
      </div>
      <div className={cls.tileContentWrap}>
        <div style={{ paddingTop: 6 }} title={title}>
          <NavLink to={`/${event}/live/${props.event.sessionId}`}>
            <Text as="h3" variant="order4" weight={'bold'} className={cls.maxTwoLines}>
              {title}
            </Text>
          </NavLink>
        </div>
        <div className={cls.book}>
          <Text as="p" variant="copy1" weight={'bold'}>
            {props.event.startAt && getDisplayDate(props.event.startAt, true)} -{' '}
            {props.event.endAt && getDisplayDate(props.event.endAt, true, true)}
          </Text>
          <AudiTooltip
            title={<EditorParser inputString={description} />}
            enterDelay={800}
            placement="top-start"
            arrow
          >
            <div>
              <Text as="p" variant="copy1" className={cls.maxTwoLines}>
                {getSimpleText(description)}
              </Text>
            </div>
          </AudiTooltip>
          {props.event.startAt && props.event.endAt && (
            <CalendarExport
              start={new Date(props.event.startAt * 1000)}
              end={new Date(props.event.endAt * 1000)}
              title={title}
              description={description}
              sessionTile={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface DTP {
  onDemand: Session;
  locked: boolean;
  user: CognitoUser;
}

const OnDemandTile = (props: DTP) => {
  const cls = useStyles();
  const theme = useIsTheme();
  const progression = useProgression();
  const event = useEventSettings()?.identifier || '';
  if (progression === 'loading') return null;
  const stat = progression.info.stats.find((s) => s.sessionId === props.onDemand.sessionId);
  return (
    <div className={cls.tile}>
      <div className={cls.sessionWrapper}>
        <div style={{ position: 'relative' }}>
          <img className={cls.thumb} src={props.onDemand.thumbnail?.url || ''} alt="Thumbnail" />
          <SessionScheduleButtons
            isInSchedule={progression.schedule.isInSchedule(props.onDemand)}
            removeFromSchedule={() => progression.schedule.removeFromSchedule(props.onDemand)}
            addToSchedule={() => progression.schedule.addToSchedule(props.onDemand)}
          />
        </div>
        {props.onDemand.duration && !props.locked && (
          <Text as="p" variant="copy2" className={cls.onDemandTime}>
            <Duration seconds={props.onDemand.duration} className="duration" />
          </Text>
        )}
        <SessionsOverlay session={props.onDemand} locked={props.locked} />
        <AlreadyDoneOverlay thisSession={props.onDemand} stats={progression.info.stats} />
      </div>

      <div className={cls.tileContentWrap}>
        <NavLink to={`/${event}/onDemand/${props.onDemand.sessionId}`}>
          <div style={{ paddingTop: 6 }} title={getLocalizedValue(props.onDemand.title)}>
            <Text as="h3" variant="order4" weight={'bold'} className={cls.maxTwoLines}>
              {getLocalizedValue(props.onDemand.title)}
            </Text>
          </div>
        </NavLink>

        <AudiTooltip
          title={<EditorParser inputString={getLocalizedValue(props.onDemand.description)} />}
          enterDelay={800}
          placement="top-start"
          arrow
        >
          <div style={{ paddingTop: 6 }}>
            <Text as="p" variant="copy1" className={cls.maxTwoLines}>
              {getSimpleText(getLocalizedValue(props.onDemand.description))}
            </Text>
          </div>
        </AudiTooltip>
        <div className={clsx(theme.audi && cls.book)}>
          {doesRoleMatch(props.user, Role.VISITOR) ? (
            <>
              {props.onDemand.interrupts?.length ? (
                <>
                  <Text as="p" variant="copy1">
                    {strings.score} {stat ? stat.score : '---'}
                  </Text>

                  <Text as="p" variant="copy1">
                    {strings.responseTime} {stat?.usedTime ? getTime(stat.usedTime) : '---'}
                  </Text>
                </>
              ) : (
                <Text as="p" variant="copy1">
                  {/* {strings.contentWithoutPoints} */}
                </Text>
              )}
            </>
          ) : (
            <Text as="p" variant="copy1" weight={'bold'}>
              {/*You can&apos;t achieve any scores.*/}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionTiles;
