import { LabelButton, Search, Text, UserIcon } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import DCLXUserTile from '../../shared/DCLXUserTile';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { gutterStyle } from '../../../../utils/gutter';
import styles from '../../../../features/Sessions/styles';
import { blackStyle } from '../../../../features/Sessions/Watch/blackStyle';
import { BaseBreakoutRoom, useLotState } from '../Provider/LotProvider';
import { UserPicture } from '../../../../ApiHandler/dclxInterfaces';
import { useIsMobile, useUser } from '../../../../utils/hooks';
import { BreakoutClosingSeconds } from '../../../../config';
import { Identifier, Menu } from './Menu';
import { LightPopover } from './LightPopover';
import { TimeCountdown } from './TimeCountdown';
import { GroupIcon } from '../../../../icons';
import { useHands } from '../utils/HandQueue';

const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
  ...styles(theme),
  ...blackStyle(theme),
  search: {
    maxWidth: '339px',
    display: 'inline-block',
    paddingLeft: '68px',
  },
  item: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

export type CommonBreakoutRoomProps = {
  currentSelected: string[];
  toggleSelected: (username: string) => void;
  resetSelected: () => void;
  startEdit: (room: BaseBreakoutRoom) => void;
  setSearch: (search: string) => void;
  searchValue: string;
};

export type BreakoutRoomProps = CommonBreakoutRoomProps & {
  room: BaseBreakoutRoom;
  index: number;
  users: UserPicture[];
  type: 'breakout' | 'main' | 'offline';
};

/** One single Room */
export const BreakoutRoom = (props: BreakoutRoomProps) => {
  const user = useUser()!;
  const lotState = useLotState();
  const mobile = useIsMobile();
  const hands = useHands();

  const users = useMemo(() => {
    return props.users
      .filter((userEntry) => {
        return props.searchValue.length
          ? (userEntry.givenName.toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1 ||
              userEntry.familyName.toLowerCase().indexOf(props.searchValue.toLowerCase())) > -1
          : true;
      })
      .map((u) => ({
        user: u,
        isTrainer: lotState.users.trainers.some((t) => t.username === u.username),
        handPosition: hands.findIndex((h) => h.username === u.username) + 1,
      }));
  }, [hands, props.users, props.searchValue, lotState]);

  const cls = useStyles();

  const deleteRoom = () => lotState.breakoutRooms.deleteRoom(props.room);
  const closeRoomSoon = () => {
    const ms = BreakoutClosingSeconds * 1000;
    lotState.breakoutRooms.closeRoomSoon(props.room, ms);
    setTimeout(deleteRoom, ms);
  };

  const menuClick = (identifier: Identifier) => {
    lotState.breakoutRooms.moveTo([{ users: props.currentSelected, id: identifier }]);
    props.resetSelected();
  };

  const join = () => lotState.breakoutRooms.moveTo([{ users: [user.sub], id: props.room.Id }]);

  const showJoin = () =>
    (props.type === 'breakout' &&
      lotState.users.iAmTrainer &&
      props.room.Id !== lotState.breakoutRooms.myBreakoutRoom?.Id) ||
    (props.type === 'main' && lotState.breakoutRooms.myBreakoutRoom);

  const getMenuItems = () => {
    const items =
      props.type === 'breakout' && lotState.users.iAmTrainer
        ? [
            {
              identifier: 'soon',
              text: strings.breakoutDeleteSoon,
              onClick: () => closeRoomSoon(),
            },
            {
              identifier: 'now',
              text: strings.breakoutDeleteNow,
              onClick: () => deleteRoom(),
            },
            {
              identifier: 'edit',
              text: strings.breakoutChangeTitle,
              onClick: () => props.startEdit(props.room),
            },
          ]
        : [];
    if (mobile && showJoin())
      items.push({
        identifier: 'join',
        text: strings.breakoutJoin,
        onClick: join,
      });
    return items;
  };
  const menuItems = getMenuItems();

  const CompleteMenu = (
    <Menu
      variant="primary"
      text={
        <>
          <b>{props.currentSelected.length}</b> {strings.breakoutParticipantsSelected}
        </>
      }
      items={[
        {
          identifier: 'main',
          text: `${strings.breakoutMoveTo} ${strings.breakoutMain}`,
          onClick: menuClick,
        },
        ...lotState.breakoutRooms.rooms.map((r) => ({
          identifier: r.Id,
          text: `${strings.breakoutMoveTo} ${r.Title}`,
          onClick: menuClick,
        })),
      ]}
    />
  );

  return (
    <div
      key={props.room.Id}
      style={{
        minHeight: 100,
      }}
    >
      {mobile && props.type === 'main' && props.currentSelected.length > 0 && CompleteMenu}
      <div
        style={{
          padding: '24px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: 24, lineHeight: 1 }}>
            {props.type === 'breakout' ? <GroupIcon /> : <UserIcon />}
          </div>
          {props.type === 'breakout' && lotState.users.iAmTrainer && (
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Text as="span" variant="copy1">
                {props.index + 1}
              </Text>
            </div>
          )}
          <div style={{ paddingLeft: 10, paddingRight: 24 }}>
            <Text as="span" variant="copy1">
              {props.room.Title || `Breakoutroom #${props.index}`} ({props.users.length})
            </Text>
          </div>
          {props.type === 'main' && (
            <Search
              inputId="search_part"
              label={strings.search}
              onChange={(e) => props.setSearch(e.target.value)}
              value={props.searchValue}
            />
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TimeCountdown room={props.room} mode="text" />
          {!mobile && props.type === 'main' && props.currentSelected.length > 0 && (
            <div className={cls.item}>{CompleteMenu}</div>
          )}
          {!mobile && showJoin() && (
            <div className={cls.item}>
              <LabelButton variant="primary" size="small" onClick={join}>
                {props.type === 'main' ? strings.breakoutBackToMain : strings.breakoutJoin}
              </LabelButton>
            </div>
          )}
          {menuItems.length > 0 && <LightPopover items={menuItems} />}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {users.map((userEntry, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'inline-block',
                padding: '10px',
                width: '25%',
                minWidth: '300px',
              }}
            >
              <DCLXUserTile
                appendName={userEntry.isTrainer ? `(${strings.trainer})` : undefined}
                hand={!!userEntry.handPosition}
                handQueueId={userEntry.handPosition || undefined}
                showUserInfo={true}
                user={userEntry.user}
                online={props.index >= 0}
                selected={props.currentSelected.includes(userEntry.user.username)}
                setSelected={
                  lotState.type === 'lot' && lotState.users.iAmTrainer
                    ? props.toggleSelected
                    : undefined
                }
                noHover
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
