import { makeStyles } from '@material-ui/core';
import { ConfigModuleIdentifiers } from '../../../ApiHandler/dclxInterfaces';
import strings from '../../../Localization/Localizer';
import React, { useEffect, useRef, useState } from 'react';
import { RunningState } from './WebsocketCommands';
import { LotChats } from '../Mates/LotChat';
import { Poll } from './Poll';
import { ABC } from './utils/ControlBar/ABCVote';
import { Thumbs } from './utils/ControlBar/ThumbsVote';
import { ThumbsTotal, ABCTotal } from './utils/interactionTypes';
import { Text, TabWrapper } from '@pp-labs/ui-components';
import { blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import AudiSpacer from '../../../utils/AudiSpacer';
import { useEventSettings } from '../../../utils/hooks';
import { LotTabs, TabType } from './LotTabs/LotTabs';
import { useLotState } from './Provider/LotProvider';

/** interface for SideBar props coming from parent components Live and LOT  */
interface P {
  toggleAbc: (abc: ABC) => void;
  uiAbc: ABC;
  abc: ABCTotal;
  toggleThumbs: (abc: Thumbs) => void;
  uiThumbs: Thumbs;
  thumbs: ThumbsTotal;
  maxParts: number;
  sendClearCommand: (what: string) => void;
  runningState: RunningState;
}

const useStyles = makeStyles((theme) => ({
  ...blackStyle(theme),
  tabsSideBarContainer: {
    backgroundColor: 'black',
    color: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  sidebarContainer: {
    backgroundColor: 'black',
    color: 'white',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: '38px',
  },
  right: {
    float: 'right',
    display: 'flex',
  },
  iconButton: {
    color: 'white !important',
  },
  tabs: {
    flexGrow: 1,
    width: '100%',
  },
  inlineSpacer: {
    display: 'inline-block',
    padding: '0 8px',
  },
  textWrapper: {
    padding: '200px 32px 32px 32px',
  },
}));

/** LOT Side-Bar*/
export const SideBar = (props: P) => {
  const cls = useStyles();
  const lotState = useLotState();
  const eventSettings = useEventSettings();
  const widthRef = useRef(null);
  const [currentWidth, setCurrentWidth] = useState<number>(280);
  useEffect(() => {
    function handleResize() {
      // @ts-ignore
      if (widthRef && widthRef.current && widthRef.current.offsetWidth) {
        // @ts-ignore
        setCurrentWidth(widthRef.current.offsetWidth);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (props.runningState === 1) return null;
  if (props.runningState === 0)
    return (
      <div className={cls.sidebarContainer} ref={widthRef}>
        <Text className={cls.blackText} variant="order3">
          {strings.welcomeToLot}
        </Text>
        <AudiSpacer spaceStackStart={'l'} />
        <Text className={cls.blackText} variant="copy1">
          {strings.welcomeToLotText}
        </Text>
      </div>
    );
  const tabs: TabType[] = [];
  if (
    eventSettings?.configModules?.map(
      (e) => e.moduleId === ConfigModuleIdentifiers.liveChatInVideoConferenceSessions
    )
  ) {
    tabs.push({ label: strings.lotChat, content: <LotChats />, id: 'chat' });
  }
  if (
    eventSettings?.configModules?.map(
      (e) => e.moduleId === ConfigModuleIdentifiers.pollInVideoConferenceSessions
    ) &&
    lotState.breakoutRooms.myBreakoutRoom === null
  ) {
    tabs.push({
      label: strings.lotPoll,
      content: <Poll {...props} maxWidth={currentWidth} />,
      id: 'poll',
    });
  }

  return (
    <div className={cls.tabsSideBarContainer} ref={widthRef}>
      <TabWrapper>
        <LotTabs tabs={tabs} sideBar />
      </TabWrapper>
    </div>
  );
};
