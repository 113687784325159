import { UploadIcon } from '@pp-labs/ui-components';
import { IconButton, makeStyles } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';
import React, { ChangeEventHandler, useState } from 'react';
import { useNotify } from '../hooks';
import { NEWFIELD } from './config';
import { parse } from 'csv-string';

type ParsedRow<T> = T;
type Row = string[];
export type RowParser<T> = (
  lineNumber: number,
  row: Row,
  header: Row | null,
  additionalHeader: Row | null
) => ParsedRow<T>;
export type SetResult<T> = (obj: ParsedRow<T>[]) => void;

export type ParserProps<T> = {
  rowParser: RowParser<T>;
  constantLineLength: boolean;
  headerLine: number | null;
  additionalHeaderLine: number | null;
};
type P<T> = ParserProps<T> & {
  setResult: SetResult<T>;

  onFileChange?: () => void;
  title?: string;
};

const useStyles = makeStyles({
  error: {
    color: '#BB0A30',
  },
});

export const parseCsvString = <T extends unknown>(csvString: string, props: ParserProps<T>) => {
  const csv = parse(csvString, NEWFIELD);
  const header: Row | null = props.headerLine !== null ? csv[props.headerLine] : null;
  const subjects: Row | null =
    props.additionalHeaderLine !== null ? csv[props.additionalHeaderLine] : null;
  const firstLen = header?.length || csv[0].length;
  let n = 0;
  if (header) n++;
  if (subjects) n++;
  return csv.slice(n).map((r, i) => {
    if (props.constantLineLength && r.length !== firstLen) {
      console.error('line', r, 'has wrong length');
      throw new Error(`Line ${i + n} has a different length then the first line.`);
    }
    try {
      return props.rowParser(i + n, r, header, subjects);
    } catch (e) {
      throw e;
    }
  });
};

/** Upload an csv file and parse it */
export const UploadCsv = <T extends unknown>(props: P<T>) => {
  const cls = useStyles();
  const notify = useNotify();
  const [error, setError] = useState<string>('');
  const [lastFileName, setLastFileName] = useState<string>('');
  const parseFile = async (file: File) => {
    try {
      const content = await file.text();
      const res = parseCsvString(content, props);
      props.setResult(res);
      notify('Successfully parsed csv file', 'success');
    } catch (e: any) {
      const m = e.message;
      if (m) {
        notify(m, 'error');
        setError(e.message);
      }
    }
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    props.onFileChange?.();
    const fileUploaded = event.target?.files?.[0];
    setLastFileName(fileUploaded?.name || '');
    if (fileUploaded) await parseFile(fileUploaded);
    event.target.value = '';
  };

  return (
    <div>
      <div>
        <IconButton style={{ color: 'black' }} onClick={handleClick}>
          <UploadIcon />
        </IconButton>
        <Text as="span" variant="copy1">
          {lastFileName ? `Currently selected: ${lastFileName}` : props.title || 'Upload .csv file'}
        </Text>
      </div>
      <Text as="p" variant="copy2" className={cls.error}>
        {error}
      </Text>
      <input
        type="file"
        accept=".csv"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};
