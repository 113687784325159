import React, { useEffect, useState } from 'react';
import { IconButton, Theme, makeStyles } from '@material-ui/core';

import { AudioIcon, WhiteClapIcon } from '../icons';
import {
  LanguageIcon,
  ExitFullScreenWrapper,
  EnterFullScreenWrapper,
  PauseIcon,
  PlayIcon,
  UnmuteIcon,
  MuteIcon,
  useIsTheme,
  ViewIcon,
} from '@pp-labs/ui-components';
import Duration from './Duration';
import { Text } from '@audi/audi-ui-react';
import strings from '../Localization/Localizer';
import { useIsMobile } from './hooks';
import { SubMenu } from './SubMenu';
import { AudioTrack, Language, PlayerSub } from 'ApiHandler/dclxInterfaces';
import clsx from 'clsx';

interface B1 {
  /** Mode for the player. Controls what else is required */
  playerMode: 'startOnly' | 'pauseOnly' | 'noSeek' | 'full';
  /** Is player currently playing */
  playing: boolean;
  /** Start Player */
  play: () => void;
  /** Are the controls visible. Note: unmute is always visible */
  visible: boolean;

  allSubs?: Array<PlayerSub>;
  selectedSub?: PlayerSub | { srcLang: { name: 'Off'; code: 'off' } };
  allAudio?: Array<AudioTrack>;
  selectedAudio?: AudioTrack | null;
  changeLanguages?: (lang: Language) => void;
  changeAudio?: (audioTrack: string) => void;
  // live session event settings
  viewCount?: number;
  clapCount?: number;
  live?: boolean;
  extendVolume?: boolean;
}

interface startOnly extends B1 {
  playerMode: 'startOnly';
}

interface B2 extends B1 {
  /** Pause Player */
  pause: () => void;
}

interface pauseOnly extends B2 {
  playerMode: 'pauseOnly';
}

interface B3 extends B2 {
  /** Is player muted */
  muted: boolean;
  /** Set Mute State */
  setMuted: (muted: boolean) => void;
  /** Is player currently in fullscreen */
  fullscreen: boolean;
  /** Change fullscreen */
  toggleFullscreen: () => void;
  /** Current volume as decimal percantage */
  volume: number;
  /** Set volume */
  setVolume: (volume: number) => void;
}

interface noSeek extends B3 {
  playerMode: 'noSeek';
}

interface fullControls extends B3 {
  /** Total video duration in seconds */
  duration: number;
  /** Decimal percentage already played */
  played: number;
  /** Seconds that can be played at a maximum */
  maxPlayed?: number;
  /** Seek started */
  seekDown: () => void;
  /** Seek ended */
  seekUp: (e: any) => void;
  /** Seeking */
  seekChange: (e: any) => void;
  playerMode: 'full';
}

const trackProgress = {
  height: '1px',
  width: '0px',
  pointerEvents: 'none',
  position: 'absolute',
  top: '-7px',
  zIndex: 2,
} as const;

type P = startOnly | pauseOnly | noSeek | fullControls;
const height = 120;
const useStyles = makeStyles((theme: Theme) => ({
  normalScreen: {
    bottom: 0,
  },
  fullScreen: {
    [theme.breakpoints.up('sm')]: {
      top: `calc(100vh - ${height}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      top: `calc(100vh - ${height / 2}px)`,
    },
  },
  videoControls: {
    display: 'flex',
    width: '100%',
    padding: '0px 22px',
    [theme.breakpoints.up('sm')]: {
      height: `${height}px`,
    },
    [theme.breakpoints.down('xs')]: {
      height: `${height / 2}px`,
      padding: '0px 12px',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    left: 0,
    right: 0,
    color: '#e5e5e5',
  },
  fullControls: {
    backgroundColor: 'rgba(0, 0, 0)',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    color: '#e5e5e5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigPlay: {
    fontSize: '150px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '80px',
      marginTop: '20px',
    },
  },
  playPauseIcon: {
    fontSize: '50px',
  },
  seekBar: {
    height: '2px',
    width: '100%',
    color: 'transparent',
    position: 'relative',
    zIndex: 3,
  },
  seekBarTrack: {
    position: 'relative',
  },
  seekBarTrackProgress: {
    ...trackProgress,
    backgroundColor: '#FFFFFF',
  },
  volumeBarTrackProgress: {
    ...trackProgress,
    backgroundColor: '#fff',
  },
  seekBarTrackBg: {
    height: '1px',
    width: '100%',
    backgroundColor: '#4C4C4C',
    pointerEvents: 'none',
    position: 'absolute',
    top: '-7px',
    zIndex: 1,
  },
  outer: {
    display: 'inline',
  },
  extendedVolBAr: {
    paddingBottom: '6px',
    flex: 0.2,
    [theme.breakpoints.down('md')]: {
      flex: 2.2,
    },
  },
  volBar: {
    paddingBottom: '6px',
    flex: 0.2,
  },
  middle: {
    padding: '0px 16px 6px 16px',
    flex: 1,
  },
  duration: {
    display: 'inline',
    padding: '0 12px',
    fontSize: '16px',
  },
  volWrapper: {
    display: 'contents',
  },
  neutralMute: {
    fontSize: '32px',
    paddingRight: '0px',
  },
  whiteTxt: {
    color: '#ffffff',
  },
  clapViewIcon: {
    display: 'inline',
    paddingLeft: '28px',
  },
  liveWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '40px',
    marginLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  liveDot: {
    background: 'red',
    width: '12px',
    height: '12px',
    borderRadius: '20px',
  },
  notLiveDot: {
    background: '#666666',
    width: '12px',
    height: '12px',
    borderRadius: '20px',
  },
}));

/**
 * Shared Custom-Controls, used in all Video Players
 */
let timeout: ReturnType<typeof setTimeout> | null = null;
const VideoControls = (props: P) => {
  const cls = useStyles();
  const mobile = useIsMobile();
  const theme = useIsTheme();
  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);
  const [volume, setVolume] = useState<boolean>(false);
  const [showSubs, setShowSubs] = useState<boolean>(false);

  const leave = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => setVolume(false), 600);
  };

  const enter = () => {
    if (timeout) clearTimeout(timeout);
    setVolume(true);
  };

  const toggleSubs = () => {
    setShowSubs(!showSubs);
  };

  const selectSub = (lang: Language) => {
    props.changeLanguages?.(lang);
    setShowSubs(false);
  };

  const selectAudio = (audioTrack: string) => {
    props.changeAudio?.(audioTrack);
    setShowSubs(false);
  };

  const controls =
    props.playerMode === 'full' && props.fullscreen
      ? `${cls.videoControls} ${cls.fullScreen}`
      : `${cls.videoControls} ${cls.normalScreen}`;
  if (props.playerMode === 'startOnly' || props.playerMode === 'pauseOnly') {
    if (props.playing && props.playerMode === 'startOnly') return null;
    if (!props.playing) {
      return (
        <div className={cls.fullControls}>
          <IconButton onClick={props.play} color="inherit" className={cls.bigPlay}>
            <PlayIcon />
          </IconButton>
        </div>
      );
    }
  }
  return (
    <>
      {props.visible && (
        <div className={controls}>
          {!props.live ? (
            <div className={cls.outer}>
              {props.playing ? (
                <>
                  {(props.playerMode === 'pauseOnly' ||
                    props.playerMode === 'noSeek' ||
                    props.playerMode === 'full') && (
                    <IconButton onClick={props.pause} color="inherit" className={cls.playPauseIcon}>
                      <PauseIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton onClick={props.play} color="inherit" className={cls.playPauseIcon}>
                  <PlayIcon />
                </IconButton>
              )}
            </div>
          ) : (
            <div className={cls.liveWrapper} onClick={props.play}>
              <div className={props.playing ? cls.liveDot : cls.notLiveDot} />
              <Text className={cls.whiteTxt} spaceInlineStart="s">
                Live
              </Text>
            </div>
          )}

          {props.viewCount! >= 0 ? (
            <>
              <div className={cls.clapViewIcon}>
                <IconButton color="inherit">
                  <ViewIcon />
                </IconButton>
              </div>
              <div className={cls.outer}>
                <Text className={cls.whiteTxt}>{props.viewCount}</Text>
              </div>
            </>
          ) : null}
          {props.clapCount! >= 0 ? (
            <>
              <div className={cls.clapViewIcon}>
                <IconButton color="inherit">
                  <WhiteClapIcon />
                </IconButton>
              </div>
              <div className={cls.outer}>
                <Text className={cls.whiteTxt}>{props.clapCount}</Text>
              </div>
            </>
          ) : null}
          {props.playerMode === 'full' && (
            <div className={cls.duration}>
              <Duration className="duration" seconds={props.duration * props.played} />
            </div>
          )}
          <div className={cls.middle}>
            {props.playerMode === 'full' && (
              <>
                <input
                  type="range"
                  min={0}
                  max={0.999999}
                  step="any"
                  value={props.played}
                  onMouseDown={props.seekDown}
                  onChange={props.seekChange}
                  onMouseUp={props.seekUp}
                  className={cls.seekBar}
                />
                <div className={cls.seekBarTrack}>
                  <div
                    className={cls.seekBarTrackProgress}
                    // Add conditional width to solve maxPlayed problem (In edge case maxPlayed become greater than 100 and cause overflow)
                    style={{
                      width:
                        (props.maxPlayed || props.played) * 100 > 100
                          ? '100%'
                          : `calc(${props.maxPlayed || props.played}*100%`,
                    }}
                  />
                  <div className={cls.seekBarTrackBg} />
                </div>
              </>
            )}
          </div>
          {props.playerMode === 'full' && (
            <div className={cls.duration}>
              <Duration className="duration" seconds={props.duration} />
            </div>
          )}
          {!mobile && (
            <div className={cls.volWrapper}>
              {(props.playerMode === 'noSeek' || props.playerMode === 'full') &&
                volume &&
                props.playerMode && (
                  <div
                    className={props.extendVolume ? cls.extendedVolBAr : cls.volBar}
                    onMouseEnter={enter}
                    onMouseLeave={leave}
                  >
                    <input
                      type="range"
                      min={0}
                      max={0.999999}
                      step="any"
                      // only change the display volume, not the intrinsic one.
                      value={props.muted ? 0 : props.volume}
                      onChange={(e) => props.setVolume(parseFloat(e.target.value))}
                      className={cls.seekBar}
                    />
                    <div className={cls.seekBarTrack}>
                      <div
                        className={cls.volumeBarTrackProgress}
                        style={{
                          width: `calc(${props.volume}*100%`,
                        }}
                      />
                      <div className={cls.seekBarTrackBg} />
                    </div>
                  </div>
                )}
              {(props.playerMode === 'noSeek' || props.playerMode === 'full') && (
                <IconButton
                  onClick={() => props.setMuted(!props.muted)}
                  color="inherit"
                  onMouseEnter={enter}
                  onMouseLeave={leave}
                  className={clsx(theme.neutral && cls.neutralMute)}
                >
                  {props.muted ? <MuteIcon /> : <UnmuteIcon />}
                </IconButton>
              )}
            </div>
          )}

          <div className={cls.outer}>
            {((props.allSubs && props.allSubs.length > 0) ||
              (props.allAudio && props.allAudio.length > 1)) && (
              <IconButton onClick={toggleSubs} color="inherit">
                <LanguageIcon />
              </IconButton>
            )}
            {(props.playerMode === 'noSeek' || props.playerMode === 'full') && (
              <IconButton onClick={props.toggleFullscreen} color="inherit">
                {props.fullscreen ? <ExitFullScreenWrapper /> : <EnterFullScreenWrapper />}
              </IconButton>
            )}
          </div>
        </div>
      )}
      {(props.playerMode === 'noSeek' || props.playerMode === 'full') && props.muted && (
        <div
          style={{
            position: 'absolute',
            top: 32,
            left: 32,
            backgroundColor: '#FFF',
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            padding: '8px 16px',
            fontSize: 24,
          }}
          onClick={() => props.setMuted(false)}
        >
          <AudioIcon />
          <div style={{ width: 8 }} />
          <Text weight="bold" variant="copy2">
            {strings.unmute}
          </Text>
        </div>
      )}
      {showSubs && (
        <SubMenu
          allSubs={props.allSubs}
          selectSub={selectSub}
          currentSub={props.selectedSub}
          allAudio={props.allAudio}
          selectAudio={selectAudio}
          currentAudio={props.selectedAudio}
        />
      )}
    </>
  );
};

export default VideoControls;
