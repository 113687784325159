import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FileTypes } from '@pp-labs/ui-components/dist/forms/FormProps';
import { DownloadButton, EraseIcon, UploadIcon } from '@pp-labs/ui-components';
import { getAccept, MediaFile } from './MediaLibrary';
import { MediaLibraryDialog } from './MediaLibraryDialog';

const useStyles = makeStyles({
  mainWrapper: {
    width: '200px',
    overflow: 'hidden',
  },
  imageWrapper: {
    background: '#666666',
    height: '128px',
  },
  wrapper: {
    height: '128px',
    border: 'solid 1px #5E5E5E',
  },
  iconWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundSize: 'cover',
  },
  white: {
    color: 'white',
  },
  error: {
    color: '#BB0A30',
  },
});

/** interface for SelectMediaButton props coming from parent component DCLXBanner,LobbyMedia, TopicForm  */
interface P {
  type: FileTypes;
  noText?: boolean;
  prevUrl?: string;
  prevName?: string;
  variant: 'dark' | 'light';
  name: string;
  label: string;
  optional?: boolean;
  imgDimensions?: string;
}

/** Button to select a medium from the media library.  */
const SelectMediaButton = (props: P) => {
  const cls = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { setFieldValue, errors, touched } = useFormikContext<any>();
  const handleClick = () => {
    setOpen(true);
  };

  const error = touched[props.name] ? errors[props.name] : '';
  return (
    <div className={cls.mainWrapper}>
      <div className={clsx(props.variant === 'dark' ? cls.imageWrapper : cls.wrapper)}>
        <div
          about={`${props.type}-upload-button`}
          className={clsx(cls.iconWrapper)}
          onClick={handleClick}
          style={{
            backgroundImage: props.prevUrl
              ? `linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,.5)),url(${props.prevUrl})`
              : '',
          }}
        >
          <IconButton disabled>
            <UploadIcon video={props.variant === 'light'} image={props.variant === 'dark'} />
          </IconButton>
          <Text className={clsx(props.variant === 'dark' && cls.white)} variant={'copy1'}>
            {props.label}
          </Text>
          {props.imgDimensions && (
            <Text className={clsx(props.variant === 'dark' && cls.white)} variant={'copy3'}>
              ({props.imgDimensions})
            </Text>
          )}
        </div>
        <MediaLibraryDialog
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={(files: MediaFile[]) => {
            setOpen(false);
            setFieldValue(props.name, files[0]);
          }}
          interactType={'select'}
          fileTypes={getAccept(props.type)}
          preselectedMediaIds={[]}
        />
      </div>
      {props.prevUrl && (
        <div style={{ display: 'flex', justifyContent: 'left', lineBreak: 'anywhere' }}>
          {props.optional ? (
            <>
              <DownloadButton url={props.prevUrl || ''} name={''} />
              <Text>{props.prevName || 'current_thumbnail'}</Text>
              <div>
                <IconButton
                  style={{ color: 'black' }}
                  onClick={() => setFieldValue(props.name, null)}
                >
                  <EraseIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <DownloadButton
              name={props.prevName || 'current_thumbnail'}
              url={props.prevUrl || ''}
            />
          )}
        </div>
      )}
      {error && (
        <Text className={cls.error} variant={'copy2'}>
          {error}
        </Text>
      )}
    </div>
  );
};
export { SelectMediaButton };
