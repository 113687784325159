import { MuteIcon } from '@pp-labs/ui-components';
import React, { useState } from 'react';
import { DotsIcon } from '../../../icons';
import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';

const useStyles = makeStyles((theme) => ({
  closeWrapper: {
    position: 'absolute',
    bottom: '30px',
  },
  closeInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'black',
    height: '40px',
    borderRight: '20px solid black',
    padding: '12px',
    borderRadius: '0px 20px 20px 0px',
    cursor: 'pointer',
    color: 'white',
  },
  openWrapper: {
    position: 'absolute',
    bottom: '-90px',
    display: 'flex',
    flexDirection: 'column',
    background: '#F1F1F1',
    border: '2px black solid',
    borderRadius: '23px',
    paddingBottom: '16px',
    zIndex: 1,
    width: '228px',
  },
  openIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    cursor: 'pointer',
    color: 'black',
    justifyContent: 'space-between',
  },
  whiteText: {
    color: 'white',
  },
  icons: {
    margin: '0px 4px',
    display: 'inline-flex',
    fontSize: '18px',
  },
  selectText: {
    padding: 12,
    cursor: 'pointer',
    '&:hover': {
      background: '#CCCCCC',
    },
  },
}));

/** interface for LotFloatingButton props coming from parent component LOT */
interface P {
  muteAll: () => void;
  allMuted: boolean;
  runningState: number;
  changeParticipantMode: (muted: boolean) => void;
  isTrainer: boolean;
  streamingLot: boolean;
  screenShareAllowed: boolean;
  toggleScreenshare: () => void;
}

/** Provides some interactions for the trainer */
const LotFloatingButton = (props: P) => {
  const cls = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (type?: 'permanent' | 'temporary' | 'screen') => {
    setOpen(!open);
    if (type === 'permanent') {
      props.changeParticipantMode(!props.allMuted);
    }
    if (type === 'temporary' && !props.allMuted) {
      props.muteAll();
    }
    if (type === 'screen') {
      props.toggleScreenshare();
    }
  };
  if (!props.isTrainer || props.streamingLot || props.runningState === 0) return null;
  return (
    <>
      {!open ? (
        <div className={cls.closeWrapper}>
          <div className={cls.closeInnerWrapper} onClick={() => handleClick()}>
            <div className={cls.icons}>
              <MuteIcon />
            </div>
            <Text className={cls.whiteText} variant={'copy2'}>
              Mute all Participants
            </Text>
            <div className={cls.icons}>
              <DotsIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className={cls.openWrapper}>
          <div className={cls.openIconWrapper} onClick={() => handleClick()}>
            <div className={cls.icons}>
              <MuteIcon />
            </div>
            <Text black variant={'copy2'}>
              Mute all Participants
            </Text>
            <div className={cls.icons}>
              <DotsIcon />
            </div>
          </div>
          <div className={cls.selectText} onClick={() => handleClick('temporary')}>
            <Text black variant={'copy2'}>
              {strings.muteOnce}
            </Text>
          </div>
          <div className={cls.selectText} onClick={() => handleClick('permanent')}>
            <Text black variant={'copy2'}>
              {props.allMuted ? strings.unMuteAll : strings.mutePermanently}
            </Text>
          </div>
          <div className={cls.selectText} onClick={() => handleClick('screen')}>
            <Text black variant={'copy2'}>
              {props.screenShareAllowed ? strings.disableScreenshare : strings.enableScreenshare}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};
export { LotFloatingButton };
