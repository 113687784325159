import strings from 'Localization/Localizer';
import { useEventSettings } from '../../utils/hooks';

/** Sets the metadata (favicon and title) of the page when the event changes */
export const MetaData = () => {
  const setFavicon = (url: string) => {
    const fav = document.getElementById('favicon');
    // @ts-ignore
    fav.href = url;
  };

  const eventSettings = useEventSettings();
  document.title = eventSettings?.title || strings.mainPageTitle;
  setFavicon(eventSettings?.favicon || process.env.PUBLIC_URL + '/favicon.ico');
  return null;
};
