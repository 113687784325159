import ReactPlayer from 'react-player/lazy';
import React, { useEffect, useState } from 'react';
import { playerConfig } from '../../../utils/convert';
import VideoControls from '../../../utils/VideoControls';
import { SoundMuteController } from 'utils/SoundMuteController';

/** interface for SeamlessVideoPlayer props coming from parent component MediaOverlay  */
interface P {
  url: string;
  loop?: boolean;
  muteControl?: boolean;
}

/**
 * VideoPlayer for Live Events
 */

export const SeamlessVideoPlayer = (props: P) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(false);
  useEffect(() => {
    return handlePause();
  }, []);

  const handlePlay = () => setPlaying(true);
  const handlePause = () => setPlaying(false);

  return (
    <div className="player-wrapper">
      <ReactPlayer
        config={playerConfig}
        className="react-player"
        controls={false}
        width="100%"
        height="100%"
        url={props.url}
        playing={playing}
        playbackRate={1}
        loop={props.loop}
        progressInterval={250}
        volume={0.8}
        muted={mute}
        onPlay={handlePlay}
        onPause={handlePause}
      />
      {props.muteControl && <SoundMuteController mute={mute} setMute={setMute} />}
      <VideoControls visible={false} playerMode="startOnly" playing={playing} play={handlePlay} />
    </div>
  );
};
