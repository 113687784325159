import { Select } from '@audi/audi-ui-react';
import { ChangeEvent, useMemo, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

export type TypeFilterValues = 'public' | 'private' | 'all';

/** interface for TypeFilter props coming from parent component QuestionSection */
interface P {
  setValue: (type: TypeFilterValues) => void;
}

/** Filter AMAs by types */
export const TypeFilter = (props: P) => {
  const [value, setValue] = useState<TypeFilterValues>('all');
  const id = useMemo(() => nanoid(), []);
  const change = (c: ChangeEvent<HTMLSelectElement>) => {
    const v = c.target.value as TypeFilterValues;
    setValue(v);
    props.setValue(v);
  };
  return (
    <div style={{ width: 250 }} onClick={(e) => e.stopPropagation()}>
      <Select inputId={id} label="Type" value={value} onChange={change}>
        <option value="all">all</option>
        <option value="public">public</option>
        <option value="private">private</option>
        <option value="live">live</option>
      </Select>
    </div>
  );
};
