import { makeStyles, Link, AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import { Text } from '@pp-labs/ui-components';
import { Link as RouterLink } from 'react-router-dom';
import { dashboardStrings } from './strings';
import { gutterStyle } from 'utils/gutter';
import clsx from 'clsx';

type TabName = 'participants' | 'results' | 'views';

interface LinkProps {
  children: React.ReactNode;
  tabName: TabName;
  activeTab: TabName;
}

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  link: {
    margin: '28px',

    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover, &:active': {
      textDecoration: 'none',
    },
  },
  activeLink: {
    borderBottom: '2px solid black',
    paddingBottom: '5px',
    margin: '28px',

    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover, &:active': {
      textDecoration: 'none',
    },
  },
  text: {
    color: '#666666',
  },
  activeText: {
    color: 'black',
  },
}));

const SubNavLink = ({ tabName, activeTab, children }: LinkProps) => {
  const classNames = useStyles();
  const isSelected = tabName === activeTab;
  return (
    <Link
      className={isSelected ? classNames.activeLink : classNames.link}
      component={RouterLink}
      to={tabName}
    >
      <Text
        className={isSelected ? classNames.activeText : classNames.text}
        as="span"
        variant="copy1"
      >
        {children}
      </Text>
    </Link>
  );
};

type SubNavProps = {
  activeTab: TabName;
};

const showRest: boolean = true;

/** Dashboard sub navigation */
function SubNav({ activeTab }: SubNavProps) {
  const classNames = useStyles();
  return (
    <AppBar
      color="inherit"
      elevation={0}
      className={clsx('navbarSep', classNames.subNavWrapper)}
      position="static"
    >
      <Toolbar>
        <div style={{ paddingLeft: '16px' }}>
          <SubNavLink tabName="participants" activeTab={activeTab}>
            {dashboardStrings.participantsDashboard}
          </SubNavLink>
          {showRest && (
            <>
              <SubNavLink tabName="views" activeTab={activeTab}>
                {dashboardStrings.views}
              </SubNavLink>
              <SubNavLink tabName="results" activeTab={activeTab}>
                {dashboardStrings.results}
              </SubNavLink>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
export { SubNav };
