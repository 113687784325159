import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Link } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import strings, { defaultLanguage } from '../../../Localization/Localizer';
import { DownloadButton, EditDialog } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { Language, LegalDocumentType } from 'ApiHandler/dclxInterfaces';
import { publicClient } from 'ApiHandler/publicClient';
import { useEventSettings, useNotify } from 'utils/hooks';
import clsx from 'clsx';
import { MediaFile } from '../../../DCLX/CMS/Documents/MediaLibrary';
import { getLatest, getLegalTag } from '../../../DCLX/CMS/Documents/LegalDocEdit';
import { EditorParser } from '../../Editor/EditorParser';
import axios from 'axios';

/** interface for LegalDocument props coming from parent components Footer, LegalDocEdit, RequestMail, CookieInfo */
interface P {
  type: LegalDocumentType;
  inline?: boolean;
  black?: boolean;
}

const useStyles = makeStyles({
  page: {
    margin: 32,
  },
  hyperLink: {
    textDecoration: 'underline',
  },
  whiteText: {
    color: '#ffffff !important',
  },
});
const getLanguageKey = () => strings.getLanguage() as Language['code'];

const getMyLegalDoc = (media: MediaFile[], type: LegalDocumentType): MediaFile | null => {
  const myLang = getLatest(media, getLegalTag(type, getLanguageKey()));
  if (myLang) return myLang;
  const defaultLang = getLatest(media, getLegalTag(type, defaultLanguage.code));
  if (defaultLang) return defaultLang;
  const some = media[media.length - 1];
  if (some) return some;
  return null;
};

/** Handles one of the Legal Doc types in the footer, can be either PDF or HTML */
export const LegalDocument = (props: P) => {
  const [open, setOpen] = useState<MediaFile | null>(null);
  const cls = useStyles();
  const eventSettings = useEventSettings();
  const notify = useNotify();

  const fetchUrl = async () => {
    const resp = (
      await publicClient.get<MediaFile[]>(
        `event:${eventSettings?.identifier}/documents/${props.type}/all`
      )
    ).data;
    const myDoc = getMyLegalDoc(resp, props.type);
    if (myDoc) {
      setOpen(myDoc);
    } else {
      notify(strings.legalDocumentNotFound, 'error');
    }
  };

  const string = useMemo(() => {
    switch (props.type) {
      case LegalDocumentType.conditions:
        return strings.conditionsOfUse;
      case LegalDocumentType.privacy:
        return strings.dataProtection;
      case LegalDocumentType.legal:
        return strings.legalNotice;
    }
  }, [props.type]);

  return (
    <>
      <Link onClick={() => fetchUrl()} className="footerButton">
        {props.inline ? (
          <Text as="span" variant="copy1">
            {/* Only using "a" for consistent styling */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {strings.formatString(strings.agb, <a className={clsx(cls.hyperLink)}>{string}</a>)}
          </Text>
        ) : (
          <Text as="span" variant="copy2" className={props.black ? 'black-text' : 'white-text'}>
            {string}
          </Text>
        )}
      </Link>
      {open && (
        <EditDialog title={string} close={() => setOpen(null)} maxWidth="md">
          {open.contentType.includes('pdf') ? (
            <Pdf url={open.url} name={string} />
          ) : (
            <Html url={open.url} />
          )}
        </EditDialog>
      )}
    </>
  );
};

const Html = (props: { url: string }) => {
  const [content, setContent] = useState<string | null>(null);
  useEffect(() => {
    const load = async () => {
      const file = await axios.get<Blob>(props.url, { responseType: 'blob' });
      const text = await file.data.text();
      setContent(text);
    };
    load();
  }, [props.url]);
  if (!content) return null;
  return (
    <div style={{ width: '100%' }}>
      <EditorParser inputString={content} />
    </div>
  );
};

const Pdf = (props: { url: string; name: string }) => {
  const cls = useStyles();
  const widthRef = useRef(null);
  const [currentWidth, setCurrentWidth] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  useEffect(() => {
    const handleResize = () => {
      // @ts-ignore
      if (widthRef && widthRef.current && widthRef.current.offsetWidth) {
        // @ts-ignore
        setCurrentWidth(widthRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [totalPages]);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  return (
    <div>
      <div style={{ width: '100%' }} ref={widthRef}>
        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess} loading={Loading} error="">
          {new Array(totalPages).fill(0).map((_el, i) => {
            return (
              <div key={i}>
                <Page
                  pageNumber={i + 1}
                  loading={Loading}
                  width={currentWidth - 64}
                  className={cls.page}
                />
              </div>
            );
          })}
        </Document>
      </div>
      <DownloadButton name={props.name} url={props.url} />
    </div>
  );
};

const Loading = () => <div />;
