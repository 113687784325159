import {
  AmaType,
  ConfigModuleIdentifiers,
  Interrupt,
  SessionSettings,
  SessionType,
  SetSetting,
} from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import { LayoutItem, Text, Toggle } from '@audi/audi-ui-react';
import React from 'react';
import AudiSpacer from '../../../../utils/AudiSpacer';
import { useNamedConfigModule } from '../../../../utils/hooks';
import { NativeSelect, TableCell } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';

/** interface for SettingsSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  settings: SessionSettings;
  interrupts: Interrupt[];
  setSetting: SetSetting;
}

/** Subform for session settings */
export const SettingsSubform = (props: P) => {
  const usesModule = useNamedConfigModule();
  return (
    <>
      {props.type === SessionType.DEMAND && (
        <TableRow key="new-interrupt">
          <TableCell>
            <LayoutItem key="video-forward" spaceStackStart="m">
              <Text>{strings.allowVideoForwarding}</Text>
              <AudiSpacer spaceStackEnd="s" />
              <Toggle
                inputId={'forward_scroll'}
                on={!!props.settings.allowForwardScrolling}
                onChange={() =>
                  props.setSetting('allowForwardScrolling', !props.settings.allowForwardScrolling)
                }
              />
            </LayoutItem>
          </TableCell>
        </TableRow>
      )}
      {props.type === SessionType.DEMAND && (
        <TableRow key="amas">
          <TableCell>
            <LayoutItem key="video-forward" spaceStackStart="m">
              <Text>{strings.amaType}</Text>
              <AudiSpacer spaceStackEnd="s" />
              <NativeSelect
                inputId="con_clx_amas"
                label={strings.pleaseChoose}
                value={props.settings.amas}
                onChange={(e) => props.setSetting('amas', e.target.value as AmaType)}
              >
                <option value="none">{strings.none}</option>
                <option value="convention">{strings.conventionLike}</option>
                <option value="clx">{strings.clxLike}</option>
              </NativeSelect>
            </LayoutItem>
          </TableCell>
        </TableRow>
      )}
      {props.type === SessionType.LIVE && (
        <TableRow key="ama">
          <LayoutItem spaceStackStart="m">
            {usesModule(ConfigModuleIdentifiers.liveAma) &&
              usesModule(ConfigModuleIdentifiers.liveStreamingSessions) && (
                <>
                  <Text>{strings.useModAmas}</Text>
                  <AudiSpacer spaceStackEnd="s" />
                  <Toggle
                    inputId={'live_ama'}
                    on={!!props.settings.useLiveAmas}
                    onChange={() => props.setSetting('useLiveAmas', !props.settings.useLiveAmas)}
                  />
                </>
              )}
            <AudiSpacer spaceStackEnd="s" />
            <Text>{strings.hideName}</Text>
            <AudiSpacer spaceStackEnd="s" />
            <Toggle
              inputId={'show-participants'}
              on={!!props.settings.hideParticipants}
              onChange={() =>
                props.setSetting('hideParticipants', !props.settings.hideParticipants)
              }
            />
            <AudiSpacer spaceStackEnd="s" />
            <Text>{strings.enableClap}</Text>
            <AudiSpacer spaceStackEnd="s" />
            <Toggle
              inputId={'clap-hands'}
              on={!!props.settings.clapHands}
              onChange={() => props.setSetting('clapHands', !props.settings.clapHands)}
            />
            <AudiSpacer spaceStackEnd="s" />
          </LayoutItem>
        </TableRow>
      )}
    </>
  );
};
