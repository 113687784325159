import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  Paper,
  Grid,
  IconButton,
  TableHead,
} from '@material-ui/core';

import { Toggle, Text } from '@audi/audi-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import { tableStyles } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { OpenInBrowserRounded } from '@material-ui/icons';
import { GetAma } from '../../../ApiHandler/dclxInterfaces';
import { useEventSettings } from '../../../utils/hooks';

import { format } from 'date-fns';
import { standardFormat } from 'config';
import strings from 'Localization/Localizer';

const useStyles = makeStyles(() => ({
  ...tableStyles(),
}));

type P = {
  amas: GetAma[];
};

/** List all amas for a session */
export const AmaOverview = (props: P) => {
  const event = useEventSettings();
  const [interestingAmaIds, setInterestingAmaIds] = useState<number[]>([]);
  const cls = useStyles();

  useEffect(() => {
    const interesting = props.amas
      .filter((ama) => ama.interesting)
      .map((ama) => ama.supportQuestionId);
    setInterestingAmaIds(interesting);
  }, [props.amas]);

  const interestingToTop = (a: GetAma, b: GetAma) => {
    const aInteresting = interestingAmaIds.find((id) => a.supportQuestionId === id);
    const bInteresting = interestingAmaIds.find((id) => b.supportQuestionId === id);
    if (aInteresting && !bInteresting) return -1;
    if (!aInteresting && bInteresting) return 1;
    return 0;
  };

  const changeToggle = async (id: number) => {
    const f = interestingAmaIds.findIndex((amaId) => amaId === id);
    if (f > -1) {
      const nextInteresting = interestingAmaIds.slice();
      nextInteresting.splice(f, 1);
      await client.put(`sessions/vodAmas/${id}/interesting/false`);
      setInterestingAmaIds(nextInteresting);
    } else {
      const nextInteresting = interestingAmaIds.concat([id]);
      await client.put(`sessions/vodAmas/${id}/interesting/true`);
      setInterestingAmaIds(nextInteresting);
    }
  };

  const open = (id: number) => window.open(`/${event?.identifier}/amaView/${id}`, '_blank');

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table className={cls.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Text>{strings.question}</Text>
              </TableCell>
              <TableCell>
                <Text>{strings.user}</Text>
              </TableCell>
              <TableCell>
                <Text>Job Function</Text>
              </TableCell>
              <TableCell>
                <Text>Sent At</Text>
              </TableCell>
              <TableCell>
                <Text>Show on Top</Text>
              </TableCell>
              <TableCell>
                <Text>Open</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.amas.sort(interestingToTop).map((ama) => (
              <AmaRow
                key={ama.supportQuestionId}
                ama={ama}
                interestingAmaIds={interestingAmaIds}
                changeToggle={changeToggle}
                open={open}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

interface AmaRowProps {
  ama: GetAma;
  interestingAmaIds: number[];
  changeToggle: (id: number) => void;
  open: (id: number) => void;
}

const AmaRow = (props: AmaRowProps) => (
  <TableRow>
    <TableCell>{props.ama.question}</TableCell>
    <TableCell>{`${props.ama.givenName} ${props.ama.familyName}`}</TableCell>
    <TableCell>{props.ama.jobFunction}</TableCell>
    <TableCell>{format(props.ama.sentAt * 1000, standardFormat)}</TableCell>

    <TableCell align="center">
      <Toggle
        inputId={`interesting_${props.ama.supportQuestionId}`}
        onChange={() => props.changeToggle(props.ama.supportQuestionId)}
        on={!!props.interestingAmaIds.find((id) => props.ama.supportQuestionId === id)}
      ></Toggle>
    </TableCell>
    <TableCell align="center">
      <IconButton
        style={{ color: 'black', padding: 0 }}
        onClick={() => props.open(props.ama.supportQuestionId)}
      >
        <OpenInBrowserRounded />
      </IconButton>
    </TableCell>
  </TableRow>
);
