import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import strings from 'Localization/Localizer';
import { CookieCategory } from '@pp-labs/ui-components/dist/utils/CookieDialog';

import { AppBar, Link } from '@material-ui/core';
import { CookieInformations, CookieDialog } from '@pp-labs/ui-components';
import { NavBar } from 'features/NavBar/NavBar';
import { Text } from '@pp-labs/ui-components';
import { LegalDocument } from 'features/Footer/Overlays/LegalDocument';
import { LegalDocumentType } from 'ApiHandler/dclxInterfaces';

/** Currently disabled custom cookie info */
export const CookieInfoPage = () => {
  return (
    <>
      <AppBar color="inherit" elevation={0} className={'navbarSep'} position="static">
        <NavBar />
      </AppBar>
      <div style={{ marginLeft: '40px', marginRight: '40px' }}>
        <CookieInformations cookies={cookieInformations} strings={strings} />
      </div>
    </>
  );
};

export interface CookieDialogWrapperRef {
  forceOpen: (v: boolean) => void;
}

export const CookieDialogWrapper = forwardRef((props: any, ref) => {
  const [openCookieDialog, setOpenCookieDialog] = useState<boolean>(true);
  useImperativeHandle(ref, () => ({
    forceOpen: (v: boolean) => {
      setOpenCookieDialog(v);
    },
  }));

  useEffect(() => {
    const cookie = localStorage.getItem('CookieSettings');
    setOpenCookieDialog(cookie === null);
  }, []);
  return openCookieDialog ? (
    <CookieDialog
      strings={strings}
      body={
        <Text as="p" variant="copy2">
          {strings.formatString(strings.cookieDialogExplainer, {
            cookieInfoLink: (
              <Link href="/cookieInformations" target="_blank">
                {strings.cookieInformations}
              </Link>
            ),
            legal: <LegalDocument type={LegalDocumentType.legal} black />,
            privacy: <LegalDocument type={LegalDocumentType.privacy} black />,
          })}
        </Text>
      }
      cookieCategories={cookieInformations}
      close={
        localStorage.getItem('CookieSettings')
          ? () => {
              setOpenCookieDialog(false);
            }
          : null
      }
      save={(ccs: CookieCategory[]) => {
        const list = ccs.map((cc) => {
          return cc.cookies.map((c) => {
            return { id: c.id, enabled: c.required || c.enabled };
          });
        });
        localStorage.setItem('CookieSettings', JSON.stringify(list.flat()));
        setOpenCookieDialog(false);
      }}
    ></CookieDialog>
  ) : (
    <></>
  );
});

export const cookieInformations: CookieCategory[] = [
  {
    required: true,
    id: 'needed_category',
    title: strings.requiredCookies,
    description: strings.formatString(strings.requiredCookiesDescription, {
      cookieInfoLink: (
        <Link href={'/cookieInformations'} target={'_blank'} rel="noreferrer">
          {strings.cookieInformations}
        </Link>
      ),
    }),
    cookies: [
      {
        required: true,
        id: 'CookieSettings',
        title: 'CookieSettings',
        description: strings.cookie1desc,
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.LastAuthUser',
        title: 'CognitoIdentityServiceProvider.*.LastAuthUser',
        description: strings.cookie2desc,
        expiration: strings.expireOnLogout,
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.*.accessToken',
        title: 'CognitoIdentityServiceProvider.*.*.accessToken',
        expiration: `${strings.expireOnLogout}/4 ${strings.shortHours}`,
        description: strings.formatString(strings.cookie3desc, {
          jwtLink: (
            <Link target={'_blank'} rel="noreferrer" href="https://jwt.io/introduction">
              https://jwt.io/introduction
            </Link>
          ),
        }),
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.*.idToken',
        title: 'CognitoIdentityServiceProvider.*.*.idToken',
        expiration: `${strings.expireOnLogout}/4 ${strings.shortHours}`,
        description: strings.cookie4desc,
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.*.refreshToken',
        title: 'CognitoIdentityServiceProvider.*.*.refreshToken',
        expiration: `${strings.expireOnLogout}/8 ${strings.shortHours}`,
        description: strings.cookie5desc,
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.clockDrift',
        title: 'CognitoIdentityServiceProvider.*.clockDrift',
        expiration: strings.expireOnLogout,
        description: strings.cookie6desc,
      },
      {
        required: true,
        id: 'CognitoIdentityServiceProvider.*.userData',
        title: 'CognitoIdentityServiceProvider.*.userData',
        expiration: strings.expireOnLogout,
        description: strings.cookie7desc,
      },
      {
        required: true,
        id: 'CloudFront-Signature, CloudFront-Policy, CloudFront-Key-Pair-Id',
        title: 'CloudFront-Signature, CloudFront-Policy, CloudFront-Key-Pair-Id',
        expiration: strings.expireOnLogout,
        description: strings.cookie8desc,
      },
    ],
  },
  {
    id: 'functional_category',
    title: strings.cookieFunctionCategory,
    description: strings.cookieFunctionCategoryDesc,
    cookies: [
      {
        id: 'amplify-signin-with-hostedUI',
        title: 'amplify-signin-with-hostedUI',
        expiration: `${strings.expireOnLogout}/3 ${strings.shortHours}`,
        description: strings.cookie9desc,
      },
      {
        id: 'lang',
        title: 'lang',
        description: strings.cookie10desc,
      },
    ],
  },
];
