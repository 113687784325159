import { CognitoUser } from '../ApiHandler/dclxInterfaces';

export enum Role {
  ANY = 'ANY',
  SUPER = 'SuperAdmin',
  MARKET = 'EventManager',
  TRAINER = 'SessionModerator',
  VIEWER = 'Viewer',
  VISITOR = 'Visitor',
  SUPPORT = 'Support',
  SUPPORTSUPERVISOR = 'SupportSupervisor',
  MANAGER = 'SessionManager',
  REPORTINGS = 'ReportingViewer',
  EVENTADMIN = 'EventAdmin',
  DIRECTOR = 'Director',
}

/**
 * Check the role of a user
 */

export const CMSAccess = [
  Role.SUPER,
  Role.MARKET,
  Role.TRAINER,
  Role.SUPPORT,
  Role.SUPPORTSUPERVISOR,
  Role.MANAGER,
];

export const DashboardAccess = [
  Role.REPORTINGS,
  Role.MARKET,
  Role.MANAGER,
  Role.TRAINER,
  Role.SUPPORTSUPERVISOR,
];

export function doesRoleMatch(user: CognitoUser | null, role: Role | Array<Role>): boolean {
  if (!user) return false;

  const groups = user['cognito:groups']?.map((group: string) => {
    return group.startsWith('eu-central-1') ? Role.VISITOR : group; //SSO users start with eu-central-1 and should be seen as visitors
  }) || [Role.VISITOR];

  if (role === Role.ANY) return true;
  if (Array.isArray(role)) {
    for (let i = 0; i < role.length; i++) {
      if (groups.includes(role[i])) return true;
    }
    return false;
  } else {
    return groups.includes(role);
  }
}

export const hasAwards = [Role.VISITOR];
