import React, { Component } from 'react';
import { createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import {
  AnsweredAma,
  CognitoUser,
  ConfigModuleIdentifiers,
  PlatformEvent,
  Session,
  SessionType,
  UserPicture,
} from '../../../ApiHandler/dclxInterfaces';
import { getSessionSettings, keepAliveCommand, noNullUser } from '../../../utils/convert';
import { getWebsocket } from '../../../ApiHandler/websocket';
import { Store } from '../../../store';
import { connect } from 'react-redux';
import './LOT.scss';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { enterFullscreen, exitFullscreen } from '../../../AppSlice';
import { ABC } from './utils/ControlBar/ABCVote';
import { Thumbs } from './utils/ControlBar/ThumbsVote';
import {
  abcCommand,
  clapCommand,
  clearABCCommand,
  clearCommand,
  clearThumbsCommand,
  customCommand,
  helloCommand,
  sendCommand,
  subscribeCommand,
  thumbCommand,
} from './lotCommands';
import {
  CustomCommandValue,
  CustomHelloProps,
  GeneralCommand,
  MessageTypes,
  PrivateMessage,
} from './WebsocketCommands';
import { SideBar } from './SideBar';
import screenfull from 'screenfull';
import { client } from '../../../ApiHandler/client';
import { ABCTotal, ThumbsTotal } from './utils/interactionTypes';
import { doesRoleMatch, Role } from '../../../utils/RoleDefinitions';
import DCLXUserTile from '../shared/DCLXUserTile';
import { gutterStyle } from '../../../utils/gutter';
import IVSLivePlayer from '../../../features/Sessions/Watch/VideoPlayer/IVSLivePlayer';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { Survey } from '../../../features/Survey/Survey';
import FileButton from '../../../utils/FileButton';
import { getLocalizedValue } from '../../../Localization/Localizer';
import { ExportChat } from './ExportChat';
import { GetSurveyTemplate } from '@pp-labs/survey';
import { TerminateButton } from './TerminateButton';
import { withRouter } from 'react-router-dom';
import { Amas } from './Amas';
import { lotContext } from './Provider/LotProvider';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';
import AudiSpacer from 'utils/AudiSpacer';
import OnDemandPlayer from 'features/Sessions/Watch/VideoPlayer/OnDemandPlayer';
import clsx from 'clsx';
import { FloatingClapButton } from './FloatingClapButton';
import { SwitchStream } from './SwitchStream';
import { DisclaimerDialogue } from '../shared/DisclaimerDialogue';
import { EditorParser } from 'features/Editor/EditorParser';

const styles = (theme: Theme) =>
  createStyles({
    ...gutterStyle(theme),
    streamWrapper: {
      width: '100%',
      paddingTop: `56.25%`,
      position: 'relative',
    },
    player: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    descriptionContainer: {
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        width: '75%',
      },
    },
    videoDescription: {
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
    },
    playerWrapper: {
      width: '80%',
      marginBottom: '62px',
      [theme.breakpoints.down('xl')]: {
        width: '75%',
      },
      [theme.breakpoints.down('lg')]: {
        width: '71%',
      },
      [theme.breakpoints.down('md')]: {
        width: '70%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    sideBar: {
      width: '20%',
      [theme.breakpoints.down('xl')]: {
        width: '25%',
      },
      [theme.breakpoints.down('lg')]: {
        width: '29%',
      },
      [theme.breakpoints.down('md')]: {
        width: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    playerWrapperWithAmas: {
      width: '65%',
      marginBottom: '62px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '18px',
      },
    },
    amasSideBar: {
      left: '65%',
      position: 'absolute',
      width: '35%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        position: 'relative',
        left: 0,
      },
    },
  });

/** interface for Live props coming from parent component App  */
interface P extends WithStyles<typeof styles> {
  match: {
    params: {
      id: string | number;
    };
  };
  user: CognitoUser;
  triggerNotification: (o: string[]) => void;
  fullscreen: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
  event: PlatformEvent;
  history: {
    push: (route: string) => void;
  };
}

/** interface for LOT component state  */
interface S {
  session?: Session;
  users: UserPicture[];
  activeABC: ABC;
  activeThumb: Thumbs;
  iAmCurrentlyClapping: boolean;
  totalClap: ClapData[];
  abcTotal: ABCTotal;
  thumbsTotal: ThumbsTotal;
  currentlyOnline: string[] | undefined;
  messages: PrivateMessage[];
  videoHeight: number;
  isTrainerOrCotrainer: boolean;
  room: string;
  streamUrl?: string;
  changeToChat: boolean;
  changeToPoll: boolean;
  surveyEnabled: boolean;
  liveAmas: AnsweredAma[];
  useAmas: boolean;
}

/**
 * Main component for Live Events
 */

type Interval = ReturnType<typeof setInterval>;

export interface ClapData {
  userId: string;
  time?: number;
  state: boolean;
}

class LOT extends Component<P, S> {
  wrapper: any;
  websocket: WebSocket | null = null;
  keepAliveInterval: Interval | null = null;
  checkForConnection: Interval | null = null;
  calculateClapInterval: Interval | null = null;
  setClappingTimeout: ReturnType<typeof setTimeout> | null = null;
  websocketCurrentlyBuilding: boolean = false;

  constructor(props: P) {
    super(props);
    this.state = {
      session: undefined,
      users: [],
      activeABC: ABC.NONE,
      activeThumb: Thumbs.NONE,
      iAmCurrentlyClapping: false,
      totalClap: [],
      abcTotal: { '1': 0, '2': 0, '3': 0 },
      thumbsTotal: { '1': 0, '2': 0 },
      currentlyOnline: undefined,
      messages: [],
      videoHeight: 700,
      isTrainerOrCotrainer: false,
      room: '',
      streamUrl: undefined,
      changeToChat: false,
      changeToPoll: false,
      surveyEnabled: true,
      liveAmas: [],
      useAmas: false,
    };
    this.wrapper = React.createRef();
  }

  async componentDidMount() {
    this.addFullscreenEventListeners();
    await this.fetch();
    window.addEventListener('resize', this.onResize);
    this.onResize();
    await this.buildWebsocket();
    this.keepAlive();
    this.checkWebsocket();
    this.autoResetClap();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.websocket?.close();
    if (this.keepAliveInterval) clearInterval(this.keepAliveInterval);
    if (this.checkForConnection) clearInterval(this.checkForConnection);
    if (this.calculateClapInterval) clearInterval(this.calculateClapInterval);
    if (this.setClappingTimeout) clearTimeout(this.setClappingTimeout);
  }

  onResize = () => {
    if (this.wrapper?.current) {
      this.setState({ videoHeight: this.wrapper.current.offsetHeight });
    } else {
      setTimeout(() => {
        this.onResize();
      }, 1000);
    }
  };

  fetchAllUsers = async () => {
    try {
      return (await client.get(`users/role/${Role.VISITOR}`)).data;
    } catch {
      return [];
    }
  };

  fetch = async () => {
    const sessionId = this.props.match.params.id;
    const users = await this.fetchAllUsers();
    const session: Session = (
      await client.get(`sessions/${sessionId}`, {
        withCredentials: true,
      })
    ).data;

    const surveyData: GetSurveyTemplate[] = [];
    try {
      surveyData.push(...(await client.get(`surveys/templates`)).data);
    } catch (error) {
      console.error(error);
    }

    const surveys = surveyData
      .filter((d) => d.channelId === session.channelId)
      .find((t) => {
        if (t.startAt * 1000 > Date.now() || t.endAt * 1000 < Date.now()) return false;
        switch (session?.sessionType) {
          case SessionType.DEMAND:
            return t.surveyContext === 'session';
          case SessionType.EXTERNAL:
            return t.surveyContext === 'session';
          case SessionType.LIVE:
            return t.surveyContext === 'liveSession';
          case SessionType.LIVETERMINATED:
            return t.surveyContext === 'liveSession';
          case SessionType.EXTERNALTERMINATED:
            return t.surveyContext === 'liveSession';
          case SessionType.TRAINING:
            return t.surveyContext === 'lot';
        }
        return false;
      }); // checks if a corresponding survey exists
    const url = session.playbackUrls?.[0] || session.video?.url || '';
    this.setState({
      useAmas: this.useAmas(session),
      session: session,
      streamUrl: url,
      room: `${this.props.event.tenantId}-${sessionId}`,
      isTrainerOrCotrainer: doesRoleMatch(this.props.user, [Role.MARKET, Role.MANAGER]),
      users: users,
      surveyEnabled: surveys !== undefined,
    });
    await this.setAmas(session);
  };

  useModule = (module: ConfigModuleIdentifiers) =>
    !!this.props.event.configModules?.some((m) => m.moduleId === module);

  /** Uses Amas depending on:
   * - session setting if both modules are enabled
   * - Whichever module is enabled
   * - If none is enabled (shouldn't happen) use false.
   */
  useAmas = (session: Session): boolean => {
    if (
      this.useModule(ConfigModuleIdentifiers.liveStreamingSessions) &&
      this.useModule(ConfigModuleIdentifiers.liveAma)
    ) {
      return !!getSessionSettings(session)?.useLiveAmas;
    }
    if (this.useModule(ConfigModuleIdentifiers.liveStreamingSessions)) return false;
    return this.useModule(ConfigModuleIdentifiers.liveAma);
  };

  setAmas = async (session: Session) => {
    const amas = (
      await client.get<AnsweredAma[]>(`sessions/${session.sessionId}/liveAmas`)
    ).data.filter(
      (a) =>
        a.answerMode === 'public' ||
        (a.answerMode === 'private' && a.username === this.props.user.sub)
    );
    this.setState({
      liveAmas: amas,
    });
  };

  toggleABC = (s: ABC) => {
    if (this.state.activeABC === s) {
      this.setAbc(ABC.NONE);
      this.setState({ activeABC: ABC.NONE });
    } else {
      this.setAbc(s);
      this.setState({ activeABC: s });
    }
  };

  setAbc = (abc: ABC) => {
    this.websocketSend(abcCommand(this.state.room, abc));
  };

  setThumb = (thumb: Thumbs) => {
    this.websocketSend(thumbCommand(this.state.room, thumb));
  };

  setClap = (clapping: boolean) => {
    if (clapping) {
      this.setClappingTimeout = setTimeout(() => {
        this.setState({
          iAmCurrentlyClapping: false,
        });
      }, 30000);
    } else {
      if (this.setClappingTimeout) clearTimeout(this.setClappingTimeout);
    }

    this.setState({ iAmCurrentlyClapping: clapping });
    this.websocketSend(clapCommand(this.state.room, clapping));
  };

  toggleClap = () => {
    this.setClap(!this.state.iAmCurrentlyClapping);
  };

  toggleThumb = (s: Thumbs) => {
    if (this.state.activeThumb === s) {
      this.setThumb(Thumbs.NONE);
      this.setState({ activeThumb: Thumbs.NONE });
    } else {
      this.setThumb(s);
      this.setState({ activeThumb: s });
    }
  };

  resetThumbs = () => {
    if (this.state.isTrainerOrCotrainer) {
      this.setState({
        thumbsTotal: { '1': 0, '2': 0 },
      });
    } else {
      this.setState({
        activeThumb: Thumbs.NONE,
        thumbsTotal: { '1': 0, '2': 0 },
      });
    }
  };

  resetABC = () => {
    if (this.state.isTrainerOrCotrainer) {
      this.setState({
        abcTotal: { '1': 0, '2': 0, '3': 0 },
      });
    } else {
      this.setState({
        activeABC: ABC.NONE,
        abcTotal: { '1': 0, '2': 0, '3': 0 },
      });
    }
  };

  resetSurveys = () => {
    this.resetThumbs();
    this.resetABC();
  };

  changeAbc = (obj: ABCTotal, calledByHello?: boolean) =>
    this.setState({ abcTotal: obj, changeToPoll: !calledByHello });
  changeThumbs = (obj: ThumbsTotal, calledByHello?: boolean) =>
    this.setState({ thumbsTotal: obj, changeToPoll: !calledByHello });
  changeClapping = (total: ClapData, calledByHello?: boolean) => {
    if (total.state) {
      this.setState({
        totalClap: [...this.state.totalClap, { ...total, time: Date.now() }],
      });
    }
  };

  clear = (what: string) => {
    switch (what) {
      case 'ABC':
        this.websocketSend(clearABCCommand(this.state.room));
        break;

      case 'thumbs':
        this.websocketSend(clearThumbsCommand(this.state.room));
        break;

      default:
        this.websocketSend(clearCommand(this.state.room));
        break;
    }
  };

  setOnline = (online: Array<string>) => {
    this.setState({ currentlyOnline: online });
  };

  fetchIfNotInList = async (sub: string): Promise<null | UserPicture[]> => {
    const inUsers = this.state.users.find((userEntry) => userEntry.username === sub);
    if (inUsers) return null;
    const userPictureData: UserPicture = (await client.get(`users/name/${sub}`)).data;
    const n = this.state.users.slice();
    n.push(userPictureData);
    return n;
  };

  changeOnline = async (add: boolean, online: string) => {
    // lets ignore this until "welcome" was received
    if (this.state.currentlyOnline === undefined) {
      setTimeout(() => {
        this.changeOnline(add, online);
      }, 1000);
      return;
    }
    let newUsers: null | UserPicture[] = null;
    const n = this.state.currentlyOnline.slice();
    let change = false;
    if (add) {
      const inOnline = n.find((o) => o === online);
      newUsers = await this.fetchIfNotInList(online);
      if (!inOnline) {
        n.push(online);
        change = true;
      } else if (newUsers) {
        // it may be possible that a user is already online but not in the list
        change = true;
      }
    } else {
      const f = n.findIndex((o) => o === online);
      if (f !== -1) {
        n.splice(f, 1);
        change = true;
      }
    }
    if (!change) return;
    let newState: any = { currentlyOnline: n };
    if (newUsers) newState.users = newUsers;
    this.setState(newState);
  };

  receiveMessage = (message: PrivateMessage) => {
    this.setState((previousState) => ({
      ...previousState,
      messages: [message, ...previousState.messages],
      changeToChat: true,
    }));
  };

  sendMsg = (m: string) => {
    this.websocketSend(sendCommand(this.state.room, m, 'main'));
  };

  addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', this.handleEsc);
    document.addEventListener('mozfullscreenchange', this.handleEsc);
    document.addEventListener('fullscreenchange', this.handleEsc);
    document.addEventListener('MSFullscreenChange', this.handleEsc);
  };

  handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      this.exitFullscreen();
    }
  };

  exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    this.props.exitFullscreen();
  };

  toggleFullscreen = () => {
    if (this.props.fullscreen) {
      this.exitFullscreen();
    } else {
      this.props.enterFullscreen();
      // @ts-ignore
      screenfull.request();
    }
  };

  keepAlive = () => {
    this.keepAliveInterval = setInterval(() => {
      this.websocketSend(keepAliveCommand(`conf-${this.state.room}`));
    }, 60000);
  };

  autoResetClap = () => {
    this.calculateClapInterval = setInterval(() => {
      if (this.state.totalClap) {
        const updatedClapData = this.state.totalClap.filter((c) => {
          const time = new Date(Date.now() - 30000).getTime();
          return c.time && c.time > time;
        });
        this.setState({
          totalClap: updatedClapData,
        });
      }
    }, 5000);
  };

  checkWebsocket = () => {
    this.checkForConnection = setInterval(async () => {
      if (!this.isWebsocketOpen()) {
        await this.buildWebsocket();
      }
    }, 2000);
  };

  isWebsocketOpen = (): boolean => {
    return !!this.websocket && this.websocket?.readyState === this.websocket?.OPEN;
  };

  websocketSend = (command: string) => {
    if (this.isWebsocketOpen()) {
      this.websocket?.send(command);
    } else {
      this.buildWebsocket().then(() => {
        setTimeout(() => {
          this.websocket?.send(command);
        }, 1000);
      });
    }
  };

  setInitialMessages = (m: PrivateMessage[]) => {
    this.setState({ messages: m });
  };

  hello = (online: Array<string>, p?: CustomHelloProps) => {
    this.setOnline(online);
    if (!p) return;
    if (p.messages) this.setInitialMessages(p.messages);
    this.changeAbc(p.abc, true);
    this.changeThumbs(p.thumbs, true);
    this.changeStream(p.currentStream);
  };

  goodBye = async (sub: string) => {
    await this.changeOnline(false, sub);
  };

  handleCustom = async (value: CustomCommandValue) => {
    if (value.name === 'kick') {
      this.props.triggerNotification(['The live session has ended', 'success']);
      this.props.history.push(`/${this.props.event.identifier}`);
    }
    if (value.name === 'refreshAmaVis') {
      if (value.user === 'all' || value.user === this.props.user.sub) {
        await this.setAmas(this.state.session!);
      }
    }
  };

  buildWebsocket = async () => {
    if (this.websocketCurrentlyBuilding) return;
    this.websocketCurrentlyBuilding = true;
    const ws = await getWebsocket();
    ws.onopen = () => {
      ws.send(subscribeCommand(this.state.room));
      ws.send(helloCommand(this.state.room));
      this.websocketCurrentlyBuilding = false;
    };
    ws.onmessage = (m) => {
      const parsed: GeneralCommand = JSON.parse(m.data);
      // Ignore messages sent in other channels. This should rarely happen but better be safe.
      if (parsed.ChannelId !== `conf-${this.state.room}`) return;
      if ('CommandKey' in parsed) {
        switch (parsed.CommandKey) {
          case 'abcTotal':
            this.changeAbc(parsed.CommandValue);
            break;
          case 'thumbsTotal':
            this.changeThumbs(parsed.CommandValue);
            break;
          case 'clapHands':
            this.changeClapping(parsed.CommandValue);
            break;
          case 'cleared':
            this.resetSurveys();
            break;

          case 'clearedABC':
            this.resetABC();
            break;

          case 'clearedThumbs':
            this.resetThumbs();
            break;
          case 'hello':
            this.changeOnline(true, parsed.CommandValue);
            break;
          case 'goodbye':
            this.goodBye(parsed.CommandValue);
            break;
          case 'notify':
            this.handleCustom(parsed.CommandValue);
            break;
          case 'streamChange':
            this.changeStream(parsed.CommandValue.state);
        }
      }
      if (parsed.Action === 'welcome' && 'Users' in parsed) {
        this.hello(parsed.Users, parsed.CustomProperties);
      }
      if (parsed.Action === 'message' && parsed.MessageType === MessageTypes.TRAINING) {
        this.receiveMessage(parsed as PrivateMessage);
      }
    };
    this.websocket = ws;
  };

  changeStream = (newStream: number) => {
    const newUrl = this.state.session?.playbackUrls?.[newStream];
    if (newUrl) this.setState({ streamUrl: newUrl });
  };

  resetChanges = () => {
    this.setState({ changeToChat: false, changeToPoll: false });
  };

  sendPlayProgress = async () => {
    const progress = {
      played: 1,
      playedMax: 1,
    };
    await client.post(`sessions/${this.state.session?.sessionId}/videohit`, progress);
  };

  sendTerminate = async () => {
    if (this.state.session) {
      const d: Partial<Session> = {
        title: this.state.session.title,
        description: this.state.session.description,
        thumbnailId: this.state.session.thumbnailId,
        showInChannelContent: this.state.session.showInChannelContent,
        adapted: this.state.session.adapted,
        startAt: this.state.session.startAt,
        startTimezone: this.state.session.startTimezone,
        endTimezone: this.state.session.endTimezone,
        settings: this.state.session.settings,
        reportingTag: this.state.session.reportingTag,
        documentIds: this.state.session.documentIds,
        channelId: this.state.session.channelId,
        topicIds: this.state.session.topicIds,
        sessionType: this.state.session.sessionType,
        endAt: Math.round(Date.now() / 1000),
      };
      await client.put(`sessions/${this.state.session.sessionId}`, d);
    }
    this.websocketSend(customCommand(this.state.room, { name: 'kick' }));
  };

  sendAmaSignal = async (command: CustomCommandValue) => {
    this.websocketSend(customCommand(this.state.room, command));
  };
  dummy = () => console.error('Not implemented');

  render() {
    if (!this.state.session) return null;
    const title = getLocalizedValue(this.state.session.title);
    const sessionSetting = getSessionSettings(this.state.session);
    return (
      <lotContext.Provider
        value={{
          type: 'live',
          breakoutRooms: {
            roomsWithDel: [],
            rooms: [],
            moveTo: this.dummy,
            createRoom: this.dummy,
            myBreakoutRoom: null,
            deleteRoom: this.dummy,
            closeRoomSoon: this.dummy,
            updateRoom: this.dummy,
          },
          handsUp: { handQueue: [] },
          online: { onlineSubs: this.state.currentlyOnline },
          users: {
            participants: this.state.users,
            trainers: [],
            allUsers: this.state.users,
            iAmTrainer: this.state.isTrainerOrCotrainer,
          },
          search: {
            currentSearch: '',
          },
          chat: {
            changeToChats: this.state.changeToChat ? ['main'] : [],
            resetChanges: this.resetChanges,
            selectedChat: 'main',
            messages: this.state.messages,
            switchSelectedChat: this.dummy,
            sendMessage: this.sendMsg,
          },
          polls: {
            changeToPoll: this.state.changeToPoll,
            resetChanges: this.resetChanges,
          },
          websocket: {
            send: this.websocketSend,
          },
          general: {
            streamUrl: this.state.streamUrl || '',
            training: null,
            session: this.state.session,
            roomId: this.state.room,
          },
        }}
      >
        <Grid container spacing={0} style={{ overflow: 'auto' }}>
          <Grid container style={{ position: 'relative' }}>
            <div
              className={clsx(
                this.state.useAmas
                  ? this.props.classes.playerWrapperWithAmas
                  : this.props.classes.playerWrapper
              )}
            >
              <div className={this.props.classes.streamWrapper} ref={this.wrapper}>
                <div className={this.props.classes.player}>
                  {sessionSetting.clapHands && (
                    <FloatingClapButton
                      clapped={this.state.iAmCurrentlyClapping}
                      toggleClap={this.toggleClap}
                      showCount={this.state.isTrainerOrCotrainer}
                      allClap={uniqueClaps(this.state.totalClap)}
                    />
                  )}
                  {this.state.streamUrl && this.state.session.sessionType === SessionType.LIVE ? (
                    <div>
                      <IVSLivePlayer
                        live={true}
                        url={this.state.streamUrl}
                        surveyEnabled={this.state.surveyEnabled}
                        onPlay={this.sendPlayProgress}
                        viewCount={
                          sessionSetting.hideParticipants
                            ? this.state.users.filter((u) =>
                                this.state.currentlyOnline?.includes(u.username)
                              ).length
                            : undefined
                        }
                        clapCount={
                          sessionSetting.clapHands
                            ? uniqueClaps(this.state.totalClap).length
                            : undefined
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <OnDemandPlayer
                        url={this.state.streamUrl || ''}
                        surveyEnabled={this.state.surveyEnabled}
                        session={this.state.session}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {this.state.useAmas ? (
              <div className={this.props.classes.amasSideBar}>
                <Amas
                  sessionId={this.state.session.sessionId}
                  sendAmaSignal={this.sendAmaSignal}
                  amas={this.state.liveAmas}
                  height={this.state.videoHeight}
                />
              </div>
            ) : (
              <div className={this.props.classes.sideBar}>
                <SideBar
                  runningState={3}
                  toggleThumbs={this.toggleThumb}
                  uiThumbs={this.state.activeThumb}
                  toggleAbc={this.toggleABC}
                  uiAbc={this.state.activeABC}
                  thumbs={this.state.thumbsTotal}
                  abc={this.state.abcTotal}
                  maxParts={this.state.currentlyOnline?.length || 10}
                  sendClearCommand={this.clear}
                />
              </div>
            )}
          </Grid>
          {this.state.isTrainerOrCotrainer && (
            <Grid item xs={12} className={this.props.classes.gutterAll}>
              <TerminateButton terminate={this.sendTerminate} />
            </Grid>
          )}
          <Grid item xs={12} className={this.props.classes.gutterSites}>
            <LayoutItem className={this.props.classes.descriptionContainer}>
              <AudiSpacer spaceStackStart="m" />

              <Text as="h2" variant="order2" weight="bold" spaceStackEnd={'xl'}>
                {title}
              </Text>
              <div className={this.props.classes.videoDescription}>
                <EditorParser inputString={getLocalizedValue(this.state.session.description)} />
              </div>
              {this.state.session.documents.length > 0 && (
                <LayoutItem spaceStackStart={'xl'}>
                  {this.state.session.documents.map((doc: MediaFile, index) => {
                    return (
                      <FileButton
                        key={doc.mediaId}
                        mediaFile={doc}
                        sessionId={this.state.session!.sessionId}
                        limitWidth={false}
                      />
                    );
                  })}
                </LayoutItem>
              )}
            </LayoutItem>
            {!sessionSetting.hideParticipants && (
              <Grid item xs={12} className={this.props.classes.gutterDown}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    marginTop: '30px',
                  }}
                >
                  {this.state.users
                    .filter((u) => this.state.currentlyOnline?.includes(u.username))
                    .map((user, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'inline-block',
                          padding: '10px',
                          width: '25%',
                          minWidth: '300px',
                        }}
                      >
                        <DCLXUserTile showUserInfo user={user} online noHover />
                      </div>
                    ))}
                </div>
              </Grid>
            )}
            <LayoutItem spaceStackStart={'xxxl'}>
              <Survey
                channelId={this.state.session.channelId}
                id={this.state.session.sessionId}
                context="liveSession"
              />
              <AudiSpacer spaceStackEnd="xxl" />
            </LayoutItem>
          </Grid>
          {this.state.isTrainerOrCotrainer && (
            <Grid item xs={12} className={this.props.classes.gutterDown}>
              <SwitchStream />
              {!this.state.useAmas && <ExportChat />}
            </Grid>
          )}
        </Grid>
        <DisclaimerDialogue type="live" />
      </lotContext.Provider>
    );
  }
}

export const uniqueClaps = (claps: ClapData[]) => {
  const resultingClaps: ClapData[] = [];
  claps.forEach((c) => {
    if (!resultingClaps.some((clap) => clap.userId === c.userId)) {
      resultingClaps.push(c);
    }
  });
  return resultingClaps;
};

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
  fullscreen: state.app.fullscreen,
  event: state.app.eventSetting!,
});

const mapDispatchToProps = {
  triggerNotification,
  enterFullscreen,
  exitFullscreen,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LOT)));
