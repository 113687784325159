import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { LayoutItem, NativeCheckbox, Text, useTheme } from '@pp-labs/ui-components';
import * as Yup from 'yup';
import { confirmPasswordValidator, newPasswordValidator } from '../utils/validator';
import strings from '../Localization/Localizer';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import { LegalDocument } from '../features/Footer/Overlays/LegalDocument';
import { LegalDocumentType } from 'ApiHandler/dclxInterfaces';

const validation = () =>
  Yup.object({
    newPassword: newPasswordValidator(),
    confirmPassword: confirmPasswordValidator(),
  }).required(strings.required);

type RegistrationData = Yup.InferType<ReturnType<typeof validation>>;

/** interface for RegisterForm props coming from parent component Login  */
interface P {
  register: (v: RegistrationData) => void;
}

/**
 * Form for registering users onboarded via the support tool
 */
const RegisterForm = (props: P) => {
  const [agb, setAgb] = useState<boolean>(false);
  const theme = useTheme();
  const initialValues: RegistrationData = {
    newPassword: '',
    confirmPassword: '',
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validation()}
        onSubmit={(values: RegistrationData) => {
          if (agb) props.register(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <LayoutItem spaceStackEnd="m">
              <Text as="h2" variant="order4">
                {strings.register}
              </Text>
            </LayoutItem>
            <TextField
              name="newPassword"
              label={strings.newPassword}
              type="password"
              error={touched.newPassword ? errors.newPassword : ''}
              autoComplete="new-password"
            />
            <TextField
              name="confirmPassword"
              label={strings.confirmPassword}
              type="password"
              error={touched.confirmPassword ? errors.confirmPassword : ''}
              autoComplete="new-password"
            />
            <LayoutItem spaceStackStart="l" spaceStackEnd="m">
              <NativeCheckbox inputId="agb" required={true} onChange={() => setAgb(!agb)}>
                <LegalDocument
                  black={theme.variant !== 'dark'}
                  type={LegalDocumentType.conditions}
                />
              </NativeCheckbox>
            </LayoutItem>
            <LabelButton variant="primary" type="submit">
              {strings.register}
            </LabelButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { RegisterForm };
export type { RegistrationData };
