import React from 'react';
import { IconButton } from '@material-ui/core';
import { controlBarStyles } from './ControlBarStyles';
import { LikeDislike } from '../../LikeDislike';
import { ABCTotal } from '../interactionTypes';
import { AbcIcon } from '@pp-labs/ui-components';

/** interface for ABCVote props coming from parent component Poll */
interface P {
  toggleABC: (abc: ABC) => void;
  uiAbc: ABC;
  abc: ABCTotal;
  maxParts: number;
  maxWidth: number;
  inactive: boolean;
}

export enum ABC {
  NONE,
  A,
  B,
  C,
}

/**
 * Component for voting ABCs
 */

export const ABCVote = (props: P) => {
  const cls = controlBarStyles();
  return (
    <div style={{ marginRight: '16px' }}>
      <div>
        <IconButton
          className={cls.videoControlsBtn}
          disabled={props.inactive}
          onClick={() => {
            props.toggleABC(ABC.A);
          }}
        >
          {props.uiAbc === ABC.A ? (
            <AbcIcon active={true} text="A" />
          ) : (
            <AbcIcon active={false} text="A" />
          )}
        </IconButton>
        <LikeDislike
          name={'A'}
          likes={props.abc['1']}
          maxWidth={props.maxWidth}
          maxLikes={props.maxParts}
        />
      </div>
      <div>
        <IconButton
          className={cls.videoControlsBtn}
          disabled={props.inactive}
          onClick={() => {
            props.toggleABC(ABC.B);
          }}
        >
          {props.uiAbc === ABC.B ? (
            <AbcIcon active={true} text="B" />
          ) : (
            <AbcIcon active={false} text="B" />
          )}
        </IconButton>
        <LikeDislike
          name={'B'}
          likes={props.abc['2']}
          maxWidth={props.maxWidth}
          maxLikes={props.maxParts}
        />
      </div>
      <div>
        <IconButton
          className={cls.videoControlsBtn}
          disabled={props.inactive}
          onClick={() => {
            props.toggleABC(ABC.C);
          }}
        >
          {props.uiAbc === ABC.C ? (
            <AbcIcon active={true} text="C" />
          ) : (
            <AbcIcon active={false} text="C" />
          )}
        </IconButton>
        <LikeDislike
          name={'C'}
          likes={props.abc['3']}
          maxWidth={props.maxWidth}
          maxLikes={props.maxParts}
        />
        <div style={{ marginBottom: '20px' }} />
      </div>
    </div>
  );
};
