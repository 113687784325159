import React, { Component } from 'react';
import { Form, Formik } from 'formik';

import { triggerNotification } from '../../../utils/NotificationSlice';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { LabelButton, Select, EditDialog } from '@pp-labs/ui-components';
import { TimeSlot } from '../../../ApiHandler/dclxInterfaces';
import { getDisplayDate } from '../../../utils/convert';
import { client } from '../../../ApiHandler/client';

const validation = Yup.object({
  training: Yup.string().required(strings.required),
}).required(strings.required);

const styles = () =>
  createStyles({
    len: {
      verticalAlign: 'top',
      maxWidth: '450px',
      padding: '0 12px',
    },
  });

/** interface for Rebook props coming from parent component ParticipantManager */
interface P {
  id: number;
  closeDialog: Function;
  classes: {
    len: string;
  };
  bookId: number;
  partId: number;
}

/** interface for Rebook component state  */
interface S {
  trainings: TimeSlot[] | null;
}

interface FormValues {
  training: string;
}

/**
 * Form for rebooking participants
 */

class Rebook extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      trainings: null,
    };
    this.fetch();
  }

  fetch = async () => {
    const trainings: TimeSlot[] = (await client.get(`trainings`)).data;
    const sessionId = trainings.find((t) => t.trainingId === this.props.id)?.sessionId;
    const viable = trainings.filter(
      (t) => t.sessionId === sessionId && t.trainingId !== this.props.id
    );
    this.setState({ trainings: viable });
  };

  close = () => {
    this.props.closeDialog();
  };

  getErrors = (touched: any, errors: any) => {
    return {
      training: touched.training ? errors.training : '',
    };
  };

  submitToServer = async (v: FormValues) => {
    await client.post(`trainings/rebook/${this.props.bookId}/${v.training}`);
    this.props.closeDialog(true);
  };

  render() {
    if (!this.state.trainings) return null;
    const initialValues: FormValues = {
      training: '',
    };
    return (
      <EditDialog title={'Rebook Participant'} close={this.close}>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={(values: FormValues) => {
            this.submitToServer(values);
          }}
        >
          {({ errors, touched }) => {
            const et = this.getErrors(touched, errors);
            if (!this.state.trainings) return null;
            return (
              <Form>
                <div className={this.props.classes.len}>
                  <Select name="training" label={strings.newTraining} error={et.training}>
                    {this.state.trainings.map((t) => (
                      // eslint-disable-next-line max-len
                      <option key={`training-${t.trainingId}`} value={t.trainingId}>
                        {getDisplayDate(t.startAt, true)}&nbsp;
                        {(t.endAt - t.startAt) / 60} min
                      </option>
                    ))}
                  </Select>
                </div>

                <div style={{ padding: '20px' }} />
                <LabelButton type="submit" variant="primary">
                  {strings.rebookParticipant}
                </LabelButton>
              </Form>
            );
          }}
        </Formik>
      </EditDialog>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = { triggerNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rebook));
