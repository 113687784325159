import React, { useState } from 'react';
import { LabelButton, makeThemedStyles, useThemedStyles, Text } from '@pp-labs/ui-components';
import { useEventSettings, useNavigateEvent, useTexts } from '../../utils/hooks';
import { Exam, FullFirstStep, UnbookedLot } from '../../DCLX/CMS/FirstSteps/utils';
import strings, { getLocalizedValue } from '../../Localization/Localizer';
import DCLXTest from '../../DCLX/FE/Test/DCLXTest';
import { GetTest, Session } from '../../ApiHandler/dclxInterfaces';
import { Link } from '@material-ui/core';
import DCLXCalendar from '../../DCLX/FE/Booking/DCLXCalendar';
import {
  BookIcon,
  LinkIcon,
  PlayIconFirstSteps,
  ProfileIcon,
  StreamIcon,
  SurveyIcon,
} from '../../icons';
import { useHistory } from 'react-router-dom';
import { useProgression } from 'progression';

const styles = makeThemedStyles((theme) => {
  return {
    wrapper: {
      backgroundColor: ({ isTheme }) =>
        isTheme.audi ? '#EEE' : isTheme.neutralLight ? '#ffffff' : '#000000',
      padding: 16,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    testWrapper: {
      backgroundColor: ({ isTheme }) =>
        isTheme.audi ? '#DDD' : isTheme.neutralLight ? '#EFEFEF' : '#151515',
      padding: 24,
      margin: 16,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    tests: {
      display: 'flex',
      [theme.breakpoints.up('lg')]: {
        width: '70%',
        flexDirection: 'row',
      },
      [theme.breakpoints.only('md')]: {
        width: '100%',
        flexDirection: 'row',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    welcome: {
      margin: 16,
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        width: '30%',
      },
      [theme.breakpoints.only('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    icon: {
      fontSize: '1.5em',
    },
  };
});
type Inner = {
  withMedia: FullFirstStep[];
  refresh: () => void;
};

type TestOpen = {
  type: 'test';
  exam: GetTest;
};

type BookingOpen = {
  type: 'booking';
  session: Session;
};

/** First Step list, only displayed when module is activated via texts */
export const InnerFirstSteps = (props: Inner) => {
  const cls = useThemedStyles(styles);
  const { getText } = useTexts();
  const title = getText('firstStepsTitle');
  if (!title) return null;
  return (
    <div className={cls.wrapper}>
      <div className={cls.welcome}>
        <Text variant="order3" weight="bold">
          {title}
        </Text>
        <Text variant="order4">{getText('firstStepsDescription')}</Text>
      </div>
      <div className={cls.tests}>
        {props.withMedia.map((m, i) => (
          <FirstStepTile item={m} key={i} refresh={props.refresh} />
        ))}
      </div>
    </div>
  );
};
/** Tile to render one first step, differs based on which type it is. */
export const FirstStepTile = ({ item, refresh }: { item: FullFirstStep; refresh: () => void }) => {
  const cls = useThemedStyles(styles);
  const history = useHistory();
  const event = useEventSettings();
  const progression = useProgression();
  const eventNav = useNavigateEvent();
  const [open, setOpen] = useState<TestOpen | BookingOpen | null>(null);
  const openExam = () => setOpen({ type: 'test', exam: (item.info as Exam).media });
  const openBooking = () => setOpen({ type: 'booking', session: (item.info as UnbookedLot).media });
  const close = (final?: boolean) => {
    setOpen(null);
    if (final) refresh();
  };
  const title = (
    <Text variant="order4" spaceStackEnd="l">
      {getLocalizedValue(item.firstStep.title)}
    </Text>
  );

  const button = getLocalizedValue(item.firstStep.buttonText);
  if (item.info.type === 'demandSession') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        {title}
        <LabelButton
          variant="secondary"
          onClick={() => eventNav(info.link)}
          icon={<PlayIconFirstSteps className={cls.icon} />}
        >
          {button}
        </LabelButton>
      </div>
    );
  }

  if (item.info.type === 'liveSession') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        {title}
        <LabelButton
          variant="secondary"
          onClick={() => eventNav(info.link)}
          disabled={!info.running}
          icon={
            <StreamIcon className={cls.icon} filter={!info.running ? 'opacity(0.5)' : undefined} />
          }
        >
          {button}
        </LabelButton>
      </div>
    );
  }

  if (item.info.type === 'bookedLot') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        {title}
        <LabelButton
          variant="secondary"
          onClick={() => eventNav(info.link)}
          disabled={!info.running}
          icon={
            <StreamIcon className={cls.icon} filter={!info.running ? 'opacity(0.5)' : undefined} />
          }
        >
          {button}
        </LabelButton>
      </div>
    );
  }

  if (item.info.type === 'unbookedLot') {
    return (
      <div className={cls.testWrapper}>
        {title}
        <LabelButton
          variant="secondary"
          onClick={openBooking}
          icon={<BookIcon className={cls.icon} />}
        >
          {strings.bookNow}
        </LabelButton>

        {open?.type === 'booking' && (
          <DCLXCalendar open={true} close={() => close(true)} session={open.session} />
        )}
      </div>
    );
  }

  if (item.info.type === 'survey') {
    const info = item.info;
    const channels = progression === 'loading' ? [] : progression.info.channels;
    const CurrentChannel = channels.find((c) => c.channelId === info.media.channelId);

    if (CurrentChannel?.channelName.includes('Survey')) {
      const url = `/${event?.identifier}/page/survey/${info.media.channelId}/survey/${info.media.surveyTemplateId}`;
      return (
        <div className={cls.testWrapper}>
          <Text variant="order4" spaceStackEnd="l">
            {getLocalizedValue(item.firstStep.title)}
          </Text>
          <LabelButton
            variant="secondary"
            onClick={() => history.push(url)}
            icon={<SurveyIcon className={cls.icon} />}
          >
            {button}
          </LabelButton>
        </div>
      );
    }
    return (
      <div className={cls.testWrapper}>
        <Text variant="order4" spaceStackEnd="l">
          {getLocalizedValue(item.firstStep.title)}
        </Text>
        <LabelButton
          variant="secondary"
          onClick={() => eventNav(info.link)}
          icon={<SurveyIcon className={cls.icon} />}
        >
          {button}
        </LabelButton>
      </div>
    );
  }

  if (item.info.type === 'profile') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        <Text variant="order4" spaceStackEnd="l">
          {getLocalizedValue(item.firstStep.title)}
        </Text>
        <LabelButton
          variant="secondary"
          onClick={() => eventNav(info.link)}
          icon={<ProfileIcon className={cls.icon} />}
        >
          {button}
        </LabelButton>
      </div>
    );
  }

  if (item.info.type === 'url') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        <Text variant="order4" spaceStackEnd="l">
          {getLocalizedValue(item.firstStep.title)}
        </Text>
        <Link target="_blank" href={info.link} style={{ textDecoration: 'none' }}>
          <LabelButton variant="secondary" icon={<LinkIcon className={cls.icon} />}>
            {button}
          </LabelButton>
        </Link>
      </div>
    );
  }

  if (item.info.type === 'exam') {
    const info = item.info;
    return (
      <div className={cls.testWrapper}>
        <Text variant="order4" spaceStackEnd="l">
          {getLocalizedValue(item.firstStep.title)}
        </Text>
        <LabelButton
          variant="secondary"
          onClick={openExam}
          icon={<PlayIconFirstSteps className={cls.icon} />}
        >
          {info.media.examType === 'pre' ? strings.preTest : strings.postTest}
        </LabelButton>
        {open?.type === 'test' && (
          <DCLXTest test={open.exam} open={true} closeDialog={close} finish={() => close(true)} />
        )}
      </div>
    );
  }

  return null;
};
