import { Session, TimeSlot, UserPicture } from '../../../../ApiHandler/dclxInterfaces';
import { createContext, useContext } from 'react';
import { PrivateMessage } from '../WebsocketCommands';

export type BaseRoomId = string;
export type RoomId = 'main' | 'offline' | BaseRoomId;
export type MoveTo = (newDestinations: { users: string[]; id: RoomId }[]) => void;
type CustomBreakoutProps = {
  active: boolean;
  deactivateAt: number | null;
};

export type BaseBreakoutRoom = {
  Id: RoomId;
  Title: string;
  CustomProperties: CustomBreakoutProps;
};

export type BreakoutRoom = BaseBreakoutRoom & {
  Participants: string[];
};

export type RoomWithUsers = {
  room: BreakoutRoom;
  users: UserPicture[];
};

/** Shared state of an lot */
export type LotState = {
  type: 'lot' | 'live';
  breakoutRooms: {
    roomsWithDel: BreakoutRoom[];
    rooms: BreakoutRoom[];
    moveTo: MoveTo;
    createRoom: (newRoom: BreakoutRoom) => void;
    myBreakoutRoom: BreakoutRoom | null;
    deleteRoom: (oldRoom: BaseBreakoutRoom | 'all') => void;
    closeRoomSoon: (oldRoom: BaseBreakoutRoom | 'all', ms: number) => void;
    updateRoom: (oldRoom: BaseBreakoutRoom, newTitle: string) => void;
  };
  handsUp: {
    handQueue: { sub: string }[];
  };
  online: {
    onlineSubs: string[] | undefined;
  };
  users: {
    allUsers: UserPicture[];
    trainers: UserPicture[];
    participants: UserPicture[];
    iAmTrainer: boolean;
  };
  search: {
    currentSearch: '';
  };
  chat: {
    changeToChats: string[];
    messages: PrivateMessage[];
    resetChanges: () => void;
    selectedChat: BaseBreakoutRoom | 'main';
    switchSelectedChat: (nextChat: BaseBreakoutRoom | 'main') => void;
    sendMessage: (msg: string) => void;
  };
  polls: {
    changeToPoll: boolean;
    resetChanges: () => void;
  };
  websocket: {
    send: (msg: string) => void;
  };
  general: {
    training: TimeSlot | null | undefined;
    streamUrl: string;
    roomId: string;
    session: Session | null | undefined;
  };
};

export const lotContext = createContext<LotState>({
  type: 'lot',
  breakoutRooms: {
    roomsWithDel: [],
    rooms: [],
    moveTo: () => {},
    createRoom: () => {},
    myBreakoutRoom: null,
    deleteRoom: () => {},
    closeRoomSoon: () => {},
    updateRoom: () => {},
  },
  handsUp: {
    handQueue: [],
  },
  users: {
    allUsers: [],
    trainers: [],
    participants: [],
    iAmTrainer: false,
  },
  online: {
    onlineSubs: undefined,
  },
  search: {
    currentSearch: '',
  },
  chat: {
    changeToChats: [],
    messages: [],
    resetChanges: () => {},
    selectedChat: 'main',
    switchSelectedChat: () => {},
    sendMessage: () => {},
  },
  polls: {
    changeToPoll: false,
    resetChanges: () => {},
  },
  websocket: {
    send: () => {},
  },
  general: {
    training: null,
    streamUrl: '',
    roomId: '',
    session: null,
  },
});

export const useLotState = () => useContext(lotContext);
