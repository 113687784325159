import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Text, LayoutItem, useIsTheme } from '@pp-labs/ui-components';
import { DownloadIcon, DownloadIconWrapper } from '@pp-labs/ui-components';
import { client } from '../ApiHandler/client';
import AudiSpacer from './AudiSpacer';
import clsx from 'clsx';
import { MediaFile } from '../DCLX/CMS/Documents/MediaLibrary';
import { downloadFile } from './convert';

const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  innerWrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  view: {
    padding: '0 12px 0 0',
  },
  fileName: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  limitedWidth: {
    'max-width': '178px',
  },
}));

type Id = { trainingId: number } | { sessionId: number } | {};

type P = {
  mediaFile: MediaFile;
  smallText?: boolean;
  limitWidth: boolean;
} & Id;

/**
 * Download/View Files
 */

const FileButton = (props: P) => {
  const isTheme = useIsTheme();
  const cls = useStyles();
  const download = async () => {
    try {
      if ('sessionId' in props) {
        await client.post(
          `sessions/${props.sessionId}/documents/${props.mediaFile.mediaId}/download`
        );
      } else if ('trainingId' in props) {
        await client.post(
          `trainings/${props.trainingId}/documents/${props.mediaFile.mediaId}/download`
        );
      } else {
        await client.post(`documents/${props.mediaFile.mediaId}/download`);
      }
    } catch {}
    downloadFile(props.mediaFile.url, props.mediaFile.originalFile);
  };

  return (
    <LayoutItem spaceStackStart={'s'} spaceStackEnd={'s'}>
      <AudiSpacer spaceStackStart={'l'} neutralSpaceStackStart={'l'} />
      <div className={cls.mainWrapper}>
        <div className={cls.innerWrapper}>
          <IconButton className={cls.view} onClick={download} disableRipple>
            {isTheme.audi ? <DownloadIcon /> : <DownloadIconWrapper />}
          </IconButton>
          <Text
            className={clsx(cls.fileName, props.limitWidth && cls.limitedWidth)}
            as="span"
            variant={props.smallText ? 'copy2' : 'copy1'}
          >
            {props.mediaFile.originalFile}
          </Text>
        </div>
      </div>
    </LayoutItem>
  );
};

export default FileButton;
