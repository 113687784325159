import React from 'react';
import { IconButton } from '@material-ui/core';
import { controlBarStyles } from './ControlBarStyles';
import { LikeDislike } from '../../LikeDislike';
import { ThumbsTotal } from '../interactionTypes';
import {
  LikeIconActive,
  LikeIconInactive,
  DisLikeIconActive,
  DisLikeIconInactive,
} from '@pp-labs/ui-components';

/** interface for ThumbsVote props coming from parent component Poll  */
interface P {
  toggleThumb: Function;
  uiThumbs: Thumbs;
  thumbsTotal: ThumbsTotal;
  maxParts: number;
  maxWidth: number;
  inactive: boolean;
}

export enum Thumbs {
  NONE,
  UP,
  DOWN,
}

/**
 * Component for voting thumbs
 */

export const ThumbsVote = (props: P) => {
  const cls = controlBarStyles();

  return (
    <div style={{ marginRight: '22px' }}>
      <div>
        <IconButton
          className={cls.videoControlsBtn}
          disabled={props.inactive}
          onClick={() => {
            props.toggleThumb(Thumbs.UP);
          }}
        >
          {props.uiThumbs === Thumbs.UP ? <LikeIconActive /> : <LikeIconInactive />}
        </IconButton>
        <LikeDislike
          name={'likes'}
          likes={props.thumbsTotal['1']}
          maxWidth={props.maxWidth}
          maxLikes={props.maxParts}
        />
      </div>
      <div>
        <IconButton
          className={cls.videoControlsBtn}
          disabled={props.inactive}
          onClick={() => {
            props.toggleThumb(Thumbs.DOWN);
          }}
        >
          {props.uiThumbs === Thumbs.DOWN ? <DisLikeIconActive /> : <DisLikeIconInactive />}
        </IconButton>
        <LikeDislike
          name={'dislikes'}
          likes={props.thumbsTotal['2']}
          maxWidth={props.maxWidth}
          maxLikes={props.maxParts}
        />
      </div>
    </div>
  );
};
