import React from 'react';
import { EditLevel } from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import strings from '../../../../Localization/Localizer';
import { LabelButton, NativeCheckbox } from '@pp-labs/ui-components';

/** interface for ControlsSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  level: EditLevel;
  changeAdapt: () => void;
  abort: () => void;
  adapted: boolean;
  classes: any;
  sending: boolean;
}

/** Controls for the session form */
const ControlsSubform = (props: P) => {
  return (
    <TableRow key="controls">
      <div className={props.classes.flushRight}>
        <div className={props.classes.inline}>
          {props.level !== EditLevel.HQ && (
            <NativeCheckbox
              inputId={`adapted`}
              onChange={props.changeAdapt}
              checked={props.adapted}
            >
              {strings.adaptionCompleted}
            </NativeCheckbox>
          )}
        </div>

        <div className={props.classes.inline} style={{ marginLeft: '32px' }}>
          <LabelButton
            variant="secondary"
            className={props.classes.btnLeft}
            onClick={props.abort}
            disabled={props.sending}
          >
            {strings.abort}
          </LabelButton>

          <LabelButton
            variant="primary"
            className={props.classes.btnRight}
            type="submit"
            disabled={props.sending}
          >
            {strings.save}
          </LabelButton>
        </div>
      </div>
    </TableRow>
  );
};

export default ControlsSubform;
