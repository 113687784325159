import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Text, useIsTheme } from '@pp-labs/ui-components';
import strings from '../../Localization/Localizer';
import { LabelButton, AudiDialog } from '@pp-labs/ui-components';

/** interface for DeleteDialogue props coming from parent component AdminPanelTable, ChannelTable, sessionTable,... */
interface P {
  toBeDeleted: number | null;
  delete: () => void;
  reject: () => void;
  lot?: boolean;
  booking?: boolean;
  toDeleteLabel?: string;
}

/**
 * Dialogue to confirm deletions
 */

const DeleteDialogue = (props: P) => {
  const reject = () => {
    props.reject();
  };
  const open = props.toBeDeleted !== null;
  const theme = useIsTheme();
  return (
    <AudiDialog
      open={open}
      onClose={reject}
      fullWidth={props.lot}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ backgroundColor: theme.neutralDark ? 'black' : undefined }}>
        <DialogTitle id="alert-dialog-title">
          <Text as="p" variant="order4">
            {props.booking ? strings.cancelBookingQuestion : strings.confirmDelete}
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.lot && (
              <div>
                <Text as="p" variant="copy1">
                  {strings.lotDelete0}
                  <br />
                  <br />
                  <br />
                  {strings.lotDelete1}
                  <br />
                  {strings.lotDelete2}
                  <br />
                  {strings.lotDelete3}
                  <br />
                  {strings.lotDelete4}
                  <br />
                  {strings.lotDelete5}
                  <br />
                  <br />
                  <br />
                </Text>
              </div>
            )}
            {!props.booking && !props.toDeleteLabel && (
              <Text as="p" variant="copy1">
                {strings.confirmDelete}
              </Text>
            )}
            {props.toDeleteLabel && (
              <Text as="p" variant="copy1">
                {props.toDeleteLabel}
              </Text>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LabelButton onClick={() => props.reject()} variant="secondary">
            {props.booking ? strings.abort : 'No'}
          </LabelButton>
          <LabelButton onClick={() => props.delete()} variant="secondary">
            {props.booking ? strings.confirmCancelBooking : strings.yes}
          </LabelButton>
        </DialogActions>
      </div>
    </AudiDialog>
  );
};
export default DeleteDialogue;
