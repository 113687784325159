import { createContext, FC, useContext, useState } from 'react';

/** interface for loginContext component state  */
interface State {
  currentUsername: string;
  setCurrentUsername: (username: string) => void;
}

export const loginContext = createContext<State>({
  currentUsername: '',
  setCurrentUsername: () => {},
});

/** Provides global state. Currently used for login/useIsSpecial hook */
export const LoginProvider: FC = (props) => {
  const [currentUsername, setCurrentUsername] = useState<string>('');
  return (
    <loginContext.Provider
      value={{
        currentUsername: currentUsername,
        setCurrentUsername: setCurrentUsername,
      }}
    >
      {props.children}
    </loginContext.Provider>
  );
};

export const useIsSpecial = () => useContext(loginContext).currentUsername.startsWith('ro.');
