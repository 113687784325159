import { Channel, EditLevel, Interrupt } from 'ApiHandler/dclxInterfaces';
import React from 'react';
import DCLXCmsQuestionTable from '../Questions/DCLXCmsQuestionTable';

/** interface for QuestionTable props coming from parent component InterruptTable */
interface P {
  interrupt: Interrupt;
  level: EditLevel;
  refreshInterrupts: Function;
  channel: Channel;
}

/** Interrupt question table */
const QuestionTable = (props: P) => {
  return (
    <div style={{ marginLeft: '50px' }}>
      <DCLXCmsQuestionTable
        questions={props.interrupt.questions}
        interruptId={props.interrupt.interruptId}
        level={props.level}
        refresh={props.refreshInterrupts}
        channel={props.channel}
      />
    </div>
  );
};

export { QuestionTable };
