import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStyles, Grid, Theme, withStyles } from '@material-ui/core';
import { Text, IsTheme, useIsTheme } from '@pp-labs/ui-components';
import OnDemandPlayer from './VideoPlayer/OnDemandPlayer';
import './Watch.scss';
import { BlackClasses, blackStyle } from './blackStyle';
import './blackForm.scss';
import { getWebsocket } from '../../../ApiHandler/websocket';
import { Store } from '../../../store';
import { doesRoleMatch, Role } from '../../../utils/RoleDefinitions';
import { GutterClass, gutterStyle } from '../../../utils/gutter';
import { CognitoUser, Session, SessionType, UserPicture } from '../../../ApiHandler/dclxInterfaces';
import FileButton from '../../../utils/FileButton';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { env } from '../../../config';
import DCLXUserTile from '../../../DCLX/FE/shared/DCLXUserTile';
import { isStreamingEvent, keepAliveCommand, noNullUser } from '../../../utils/convert';
import LivePlayer from '../../../utils/LivePlayer';
import { DisclaimerDialogue } from '../../../DCLX/FE/shared/DisclaimerDialogue';
import { client } from '../../../ApiHandler/client';
import { Survey } from '../../Survey/Survey';
import { Breadcrumbs } from '../../../utils/Breadcrumbs';
import { withRouter } from 'react-router-dom';
import { GetSurveyTemplate } from '@pp-labs/survey';
import { useIsMobile } from '../../../utils/hooks';
import { EditorParser } from 'features/Editor/EditorParser';

interface ThisClasses {
  liveDescriptionContainer: string;
  title: string;
  videoDescription: string;
}

interface Classes extends BlackClasses, GutterClass, ThisClasses {}

const styles = (theme: Theme) =>
  createStyles({
    ...blackStyle(theme),
    ...gutterStyle(theme),
    liveDescriptionContainer: {
      padding: '12px 0',
    },
    videoDescription: {
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
    },
    title: {
      padding: '10px 0 10px 0',
    },
  });

export interface WrapperWatchProps {
  match?: any;
  classes: Classes;
  user: CognitoUser;
}
export interface WatchProps extends WrapperWatchProps {
  critical: boolean;
  theme: IsTheme;
}

interface WatchState {
  session?: Session;
  loading: boolean;
  url?: string;
  error?: string;
  allUsers: Array<UserPicture>;
  onlineUsers: string[];
  buddieList: Array<UserPicture>;
  surveyEnabled: boolean;
}

interface Command {
  Action: 'command' | 'welcome';
  ChannelId: string;
  CommandKey: 'hello' | 'goodbye';
  CommandValue: string;
  Users: Array<any>;
  CustomProperties: any;
  MessageType: string;
}

/**
 * Watch an Event
 */

class EventWatch extends Component<WatchProps, WatchState> {
  websocket: WebSocket | null = null;
  interval: any;

  constructor(props: WatchProps) {
    super(props);
    this.state = {
      loading: true,
      allUsers: [],
      buddieList: [],
      onlineUsers: [],
      surveyEnabled: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  keepAlive = () => {
    this.interval = setInterval(() => {
      this.websocket?.send(keepAliveCommand(`session-${this.state.session?.sessionId}`));
    }, 540000);
  };

  fetch = async () => {
    await this.fetchSession();
    await this.fetchBuddies();
    await this.fetchAllUsers();

    const data: GetSurveyTemplate[] = (await client.get('surveys/templates')).data;
    const surveys = data
      .filter((d) => d.channelId === this.state.session?.channelId)
      .find((t) => {
        if (t.startAt * 1000 > Date.now() || t.endAt * 1000 < Date.now()) return false;
        switch (this.state.session?.sessionType) {
          case SessionType.DEMAND:
            return t.surveyContext === 'session';
          case SessionType.LIVE ||
            SessionType.LIVETERMINATED ||
            SessionType.EXTERNALTERMINATED ||
            SessionType.EXTERNAL:
            return t.surveyContext === 'liveSession';
          case SessionType.TRAINING:
            return t.surveyContext === 'lot';
        }
        return false;
      }); // checks if a corresponding survey exists
    this.setState({ surveyEnabled: surveys !== undefined });
    this.buildWebsocket();
    this.keepAlive();
  };

  subscribeCommand = () =>
    JSON.stringify({
      Action: 'subscribe',
      ChannelId: `session-${this.state.session?.sessionId}`,
    });

  helloCommand = () =>
    JSON.stringify({
      Action: 'hello',
      ChannelId: `session-${this.state.session?.sessionId}`,
    });

  buildWebsocket = () => {
    getWebsocket().then((ws) => {
      ws.onopen = () => {
        ws.send(this.subscribeCommand());
        ws.send(this.helloCommand());
      };
      ws.onmessage = (m) => {
        const parsed: Command = JSON.parse(m.data);
        if ('CommandKey' in parsed) {
          switch (parsed.CommandKey) {
            case 'hello':
              this.changeOnline(true, parsed.CommandValue);
              break;
            case 'goodbye':
              this.changeOnline(false, parsed.CommandValue);
              break;
          }
        }
        if (parsed.Action === 'welcome' && 'Users' in parsed) {
          this.hello(parsed.Users);
        }
      };
      this.websocket = ws;
    });
  };

  hello = (on: Array<string>) => {
    this.setOnline(on);
  };

  setOnline = (online: Array<string>) => {
    this.setState({ onlineUsers: online });
  };

  changeOnline = (add: boolean, online: string) => {
    const n = this.state.onlineUsers.slice();
    if (add) {
      const f = n.find((o) => o === online);
      if (!f) n.push(online);
    } else {
      const f = n.findIndex((o) => o === online);
      if (f !== -1) n.splice(f, 1);
    }
    this.setState({ onlineUsers: n });
  };

  fetchBuddies = async () => {
    let buddies;
    try {
      buddies = (await client.get('users/buddies')).data;
    } catch {
      buddies = [];
    }
    this.setState({ buddieList: buddies });
  };

  fetchAllUsers = async () => {
    let users;
    try {
      users = (await client.get(`users/role/${Role.VISITOR}`)).data;
    } catch {
      users = [];
    }
    this.setState({ allUsers: users });
  };

  fetchSession = async () => {
    const videoId = parseInt(this.props.match.params.id);
    const res = await client.get(`sessions/${videoId}`, {
      withCredentials: true,
    });
    this.init(res.data);
    // if (doesRoleMatch(this.props.user, Role.VISITOR)) {
    //   await h.get(`sessions/${videoId}/videohit`);
    // }
  };

  init = (session: Session) => {
    if (
      !session.startAt ||
      Date.now() / 1000 > session.startAt ||
      doesRoleMatch(this.props.user, [Role.VIEWER, Role.REPORTINGS]) ||
      env() === 'dev'
    ) {
      this.setState({
        loading: false,
        session: session,
        url: isStreamingEvent(session) ? session.externalUrl! : session.video?.url,
      });
    } else {
      this.setState({
        error: strings.eventNotRunning,
        loading: false,
      });
    }
  };

  render() {
    if (this.state.loading) return null;
    if (this.state.error) return <span>{this.state.error}</span>;
    if (!this.state.session) return <span>{strings.sessionNotAvailable}</span>;
    const { classes } = this.props;
    const title = getLocalizedValue(this.state.session.title);
    return (
      <Grid container justifyContent="center" spacing={0}>
        <DisclaimerDialogue type="live" />
        <Grid item xs={12} md={7} lg={8}>
          <Grid item xs={12}>
            {this.state.url && (
              <>
                {isStreamingEvent(this.state.session) ? (
                  <LivePlayer
                    sessionId={this.state.session.sessionId}
                    url={this.state.session.externalUrl || ''}
                    surveyEnabled={this.state.surveyEnabled}
                  />
                ) : (
                  <OnDemandPlayer
                    url={this.state.url}
                    surveyEnabled={this.state.surveyEnabled}
                    session={this.state.session}
                  />
                )}
              </>
            )}
          </Grid>

          {!this.props.critical && (
            <Grid item xs={12} className={this.props.classes.gutterAll}>
              <Survey
                channelId={this.state.session.channelId}
                id={this.state.session.sessionId}
                context="liveSession"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} lg={4} className={classes.amaWrapper}>
          <div className={classes.liveDescriptionContainer}>
            <Breadcrumbs title={title} />
            <div className={this.props.classes.title}>
              <Text as="h2" variant="order2">
                {title}
              </Text>
            </div>
            <div className={this.props.classes.videoDescription}>
              <EditorParser inputString={getLocalizedValue(this.state.session.description)} />
            </div>
            <div style={{ padding: '20px 0' }}>
              {this.state.session.documents.map((doc, index) => {
                return (
                  <FileButton
                    key={doc.mediaId}
                    mediaFile={doc}
                    sessionId={this.state.session!.sessionId}
                    limitWidth
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={this.props.classes.gutterAll}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {this.state.allUsers
              .filter((u) => this.state.onlineUsers.includes(u.username))
              .map((user, index) => (
                <div
                  key={index}
                  style={{
                    display: 'inline-block',
                    padding: '10px',
                    width: '25%',
                    minWidth: '300px',
                  }}
                >
                  <DCLXUserTile showUserInfo user={user} online noHover />
                </div>
              ))}
          </div>
        </Grid>
        {this.props.critical && (
          <Grid item xs={12} className={this.props.classes.gutterAll}>
            <Survey
              channelId={this.state.session.channelId}
              id={this.state.session.sessionId}
              context="liveSession"
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

const EventWatchWrapper = (props: WrapperWatchProps) => {
  const mobile = useIsMobile();
  const theme = useIsTheme();
  return <EventWatch {...props} critical={mobile} theme={theme} />;
};

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
});

const mapDispatchToProps = {};

const RawEventWatch = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventWatchWrapper))
);

export default RawEventWatch;
