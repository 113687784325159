import { Question } from '../../../../ApiHandler/dclxInterfaces';
import Test from '../../../../DCLX/FE/Test/Test';
import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import { GutterClass, gutterStyle } from '../../../../utils/gutter';
import { createStyles, withWidth } from '@material-ui/core';
import { EditDialog } from '@pp-labs/ui-components';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const styles = (theme: Theme) =>
  createStyles({
    ...gutterStyle(theme),
  });

/** interface for Quiz props coming from parent component onDemandPlayer  */
interface P {
  questions: Question[];
  finish: Function;
  classes: GutterClass;
  width: Breakpoint;
  fullscreen?: boolean;
}

/** interface for Quiz state  */
interface S {
  start: number;
}

/**
 * Wrapper to mount Interrupts
 */

class Quiz extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = { start: Date.now() };
  }

  finish = (correctAnswers: number) => {
    const end = Date.now();
    const s = (end - this.state.start) / 1000;
    this.props.finish(s, correctAnswers);
  };

  render() {
    const critical =
      this.props.width === 'xs' ||
      this.props.width === 'sm' ||
      this.props.width === 'md' ||
      this.props.width === 'lg';
    if (this.props.fullscreen || !critical) {
      return (
        <div className="quiz">
          <div className={this.props.classes.gutterAll}>
            <Test
              testType="interrupt"
              questions={this.props.questions}
              finish={this.finish}
              black
            />
          </div>
        </div>
      );
    } else {
      return (
        <EditDialog title={''} close={() => {}} maxWidth={this.props.width} top>
          <Test testType="interrupt" questions={this.props.questions} finish={this.finish} />
        </EditDialog>
      );
    }
  }
}

export default withStyles(styles)(withWidth()(Quiz));
