import React from 'react';
import { SessionType } from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import strings from '../../../../Localization/Localizer';
import { TextField } from '@pp-labs/ui-components';
import AudiSpacer from '../../../../utils/AudiSpacer';
import { Editor } from 'features/Editor/Editor';

/** interface for TitleDescriptionSubformContent props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  headline: string;
  et: any;
  selectedEventType?: string;
  selectedLanguage: number;
}

/** Subform to enter general session metadata */
const TitleDescriptionSubformContent = (props: P) => {
  return (
    <>
      <TableRow key="title">
        <TextField name="title" label={strings.title} error={props.et.title} />
      </TableRow>
      <AudiSpacer spaceStackStart={'xxs'} />
      <TableRow key="description">
        <input name="description" hidden />
        <div id="description">
          <Editor
            formikName={'description'}
            label={strings.description}
            selectedLanguage={props.selectedLanguage}
          ></Editor>
        </div>
      </TableRow>
    </>
  );
};

export default TitleDescriptionSubformContent;
