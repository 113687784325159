import React, { useEffect, useState } from 'react';
import AudiSpacer from 'utils/AudiSpacer';
import { LabelButton, Text } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import { Form, Formik } from 'formik';
import { requiredMediaFile } from 'utils/validator';
import * as Yup from 'yup';
import { client } from 'ApiHandler/client';
import { useNotify } from 'utils/hooks';
import { SelectMediaButton } from './Documents/SelectMediaButton';
import { MediaFile } from './Documents/MediaLibrary';

interface LobbyMedia {
  lobbyImage?: MediaFile;
  lobbyVideo?: MediaFile | null;
}

interface PostLobbyData {
  lobbyImageId?: number;
  lobbyVideoId?: number | null;
}

const validation = Yup.object({
  lobbyImage: requiredMediaFile,
}).required(strings.required);

/** Edit Event-wide default-lobby media */
const LobbyMediaEdit = () => {
  const notify = useNotify();
  const [prevData, setPrevData] = useState<LobbyMedia>();
  useEffect(() => {
    const fetchLobbyMedia = async () => {
      const res = await client.get(`/trainings/lobby`);
      setPrevData(res.data);
    };
    fetchLobbyMedia();
  }, []);

  const initialData: LobbyMedia = {
    lobbyImage: prevData?.lobbyImage,
    lobbyVideo: prevData?.lobbyVideo,
  };

  const onSubmit = (v: LobbyMedia) => {
    const data: PostLobbyData = {
      lobbyImageId: v.lobbyImage?.mediaId || prevData?.lobbyImage?.mediaId,
      lobbyVideoId: v.lobbyVideo?.mediaId || null,
    };
    getData(data, v);
  };

  const getData = async (data: PostLobbyData, v: LobbyMedia) => {
    try {
      await client.post(`/trainings/lobby`, data);
      notify('success', 'success');
    } catch (error: any) {
      notify(error.message, 'error');
    }
  };

  return (
    <div>
      <Text variant={'order2'} weight={'bold'}>
        {strings.lobbyMedia}
      </Text>
      <AudiSpacer spaceStackEnd={'xxl'} />
      <Formik
        initialValues={initialData}
        onSubmit={onSubmit}
        validationSchema={!initialData.lobbyImage ? validation : undefined}
      >
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form>
              <div>
                <SelectMediaButton
                  prevUrl={values.lobbyImage?.url || prevData?.lobbyImage?.url}
                  prevName={values.lobbyImage?.originalFile || prevData?.lobbyImage?.originalFile}
                  name="lobbyImage"
                  type="image"
                  label={strings.lobbyImage}
                  variant={'dark'}
                  imgDimensions="1920 x 1080 px"
                />
              </div>
              <AudiSpacer spaceStackEnd={'l'} />
              <div>
                <SelectMediaButton
                  prevUrl={
                    values.lobbyVideo === null
                      ? undefined
                      : values.lobbyVideo?.url || prevData?.lobbyVideo?.url
                  }
                  prevName={
                    values.lobbyVideo === null
                      ? undefined
                      : values.lobbyVideo?.originalFile || prevData?.lobbyVideo?.originalFile
                  }
                  name="lobbyVideo"
                  type="video"
                  label={strings.lobbyVideo}
                  variant={'light'}
                  optional
                />
              </div>

              <AudiSpacer spaceStackEnd={'l'} />
              <LabelButton variant="primary" type="submit">
                {strings.submit}
              </LabelButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { LobbyMediaEdit };
