import strings from 'Localization/Localizer';
import React, { useCallback, useEffect, useState } from 'react';
import { EditDialog, LabelButton, Text } from '@pp-labs/ui-components';

import { MessageTable } from './MessageTable';
import AudiSpacer from 'utils/AudiSpacer';
import { MessageDialog } from './MessagesDialog';
import { SelectChannels } from './SelectChannels';
import { useProgressionChannels } from 'utils/hooks';
import { client } from 'ApiHandler/client';

export interface NotificationMessage {
  title: string;
  notificationId: number;
  channels: number[];
  message: string;
  validFrom: number;
  validTo: number;
  visited?: boolean;
}

/** Landing for Message/Notification CMS. */
const Messages = () => {
  const [allMessages, setAllMessages] = useState<NotificationMessage[]>();
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [openChannelSelector, setOpenChannelSelector] = useState<boolean>(false);
  const [selectedChannels, setSelectedChannels] = useState<number[]>();
  const channels = useProgressionChannels();
  const getMessages = async () => {
    const data = await (await client.get('/notifications')).data;
    setAllMessages(data);
  };
  useEffect(() => {
    getMessages();
  }, []);

  const onSubmit = useCallback((channelIds: number[]) => {
    setSelectedChannels(channelIds);
    setOpenChannelSelector(false);
    setOpenMessage(true);
  }, []);

  return (
    <div>
      <Text variant={'order2'} weight={'bold'}>
        {strings.cmsMenuMessages}
      </Text>
      <AudiSpacer spaceStackStart="xxxl" />
      <LabelButton variant="primary" onClick={() => setOpenChannelSelector(true)}>
        {strings.newMessage}
      </LabelButton>
      <AudiSpacer spaceStackStart="xl" />
      {allMessages?.map((m, i) => (
        <MessageTable key={i} message={m} refresh={getMessages} />
      ))}
      {openChannelSelector && (
        <EditDialog
          title="Select Channel"
          close={() => setOpenChannelSelector(false)}
          maxWidth="sm"
        >
          <SelectChannels onSubmit={onSubmit} availableChannels={channels} />
        </EditDialog>
      )}
      {openMessage && selectedChannels && (
        <MessageDialog
          close={setOpenMessage}
          selectedChannels={selectedChannels}
          channels={channels}
          refresh={getMessages}
        />
      )}
    </div>
  );
};

export { Messages };
