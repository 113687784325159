import ReactPlayer from 'react-player/lazy';
import React, { Component } from 'react';
import VideoControls from './VideoControls';
import ReactDOM from 'react-dom';
import screenfull from 'screenfull';
import { youtubeConfig } from './convert';
import { client } from '../ApiHandler/client';
import CallToAction from '../features/Sessions/Watch/VideoPlayer/CallToAction';
import { defaultVolume } from '../config';

/** interface for LivePlayer props coming from parent component EventWatch, Watch  */
interface P {
  url: string;
  sessionId: number;
  surveyEnabled: boolean;
}

/** interface for LivePlayer state  */
interface S {
  playing: boolean;
  seeking: boolean;
  blockControls: boolean;
  controls: boolean;
  muted: boolean;
  played: number;
  playedSeconds: number;
  maxPlayed: number;
  maxPlayedSeconds: number;
  loaded: number;
  duration: number;
  fullscreen: boolean;
  survey: boolean;
  volume: number;
}

/**
 * VideoPlayer for Live Events
 */

class LivePlayer extends Component<P, S> {
  controlsTimeout: any;
  sendInterval: any;
  player: any;
  wrapper: any;

  constructor(props: P) {
    super(props);
    this.state = {
      playing: false,
      seeking: false,
      blockControls: false,
      controls: true,
      muted: false,
      played: 0,
      playedSeconds: 0,
      maxPlayed: 0,
      maxPlayedSeconds: 0,
      loaded: 0,
      duration: 0,
      fullscreen: false,
      survey: false,
      volume: defaultVolume,
    };
    this.controlsTimeout = null;
    this.sendInterval = null;
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.load();
    this.addFullscreenEventListeners();
  }

  componentWillUnmount() {
    this.handlePause();
    clearTimeout(this.controlsTimeout);
  }

  sendPlayProgress = async () => {
    const progress = {
      played: Math.round(this.state.playedSeconds),
      playedMax: Math.round(this.state.maxPlayedSeconds),
    };
    await client.post(`sessions/${this.props.sessionId}/videohit`, progress);
  };

  load = () => {
    this.setState({
      played: 0,
      maxPlayed: 0,
      loaded: 0,
      seeking: false,
      survey: false,
    });
  };

  handleControls = () => {
    clearTimeout(this.controlsTimeout);
    this.enableControls();
    this.controlsTimeout = setTimeout(() => {
      this.disableControls();
    }, 3000);
  };

  handlePlay = () => {
    this.setState({ playing: true });
    clearInterval(this.sendInterval);
    this.sendPlayProgress();
    this.sendInterval = setInterval(() => {
      this.sendPlayProgress();
    }, 5000);
  };

  handleSeekUp = () => {
    this.setState({ seeking: false });
    this.sendPlayProgress();
  };

  handleSeekDown = () => {
    this.setState({ seeking: true });
  };
  handleSeekChange = (e: any) => {
    this.player.seekTo(e.target.value, 'fraction');
  };

  handlePause = () => {
    this.setState({ playing: false });
    clearInterval(this.sendInterval);
    this.sendPlayProgress();
  };

  handleProgress = (current: any) => {
    const maxPlayed = Math.max(this.state.maxPlayed, current.played);
    const maxPlayedSeconds = maxPlayed * this.state.duration;
    this.setState({ ...current, maxPlayed, maxPlayedSeconds });
  };

  handleEnded = async () => {
    if (this.props.surveyEnabled) this.setState({ survey: true });
    clearInterval(this.sendInterval);
    await this.sendPlayProgress();
  };

  handleDuration = (duration: any) => {
    this.setState({ duration });
  };

  handleReady = () => {};

  enableControls = () => {
    if (!this.state.blockControls) this.setState({ controls: true });
  };

  disableControls = () => {
    this.setState({ controls: false });
  };

  blockControls = () => {
    this.setState({ controls: false, blockControls: true });
  };

  ref = (player: any) => {
    this.player = player;
  };

  addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', this.handleEsc);
    document.addEventListener('mozfullscreenchange', this.handleEsc);
    document.addEventListener('fullscreenchange', this.handleEsc);
    document.addEventListener('MSFullscreenChange', this.handleEsc);
  };

  handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      this.exitFullscreen();
    }
  };

  exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    this.setState({ fullscreen: false });
  };

  toggleFullscreen = () => {
    if (this.state.fullscreen) {
      this.exitFullscreen();
    } else {
      this.setState({
        fullscreen: true,
      }); /* @ts-ignore*/
      /* eslint-disable-next-line react/no-find-dom-node */ screenfull.request(
        ReactDOM.findDOMNode(this.wrapper.current)
      );
    }
  };

  render() {
    const { playing, muted, duration, played, maxPlayed, seeking } = this.state;
    return (
      <div className="player-wrapper" onMouseMove={this.handleControls} ref={this.wrapper}>
        <ReactPlayer
          config={youtubeConfig}
          ref={this.ref}
          className="react-player"
          controls={false}
          width="100%"
          height="100%"
          url={this.props.url}
          playing={playing}
          playbackRate={1}
          progressInterval={250}
          volume={this.state.volume}
          muted={muted}
          onReady={this.handleReady}
          onPlay={this.handlePlay}
          onPause={this.handlePause}
          onEnded={this.handleEnded}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          // block share button and co
          style={{ pointerEvents: 'none' }}
        />
        <VideoControls
          playerMode="full"
          visible={this.state.controls || played === 0}
          duration={duration}
          played={played}
          maxPlayed={maxPlayed}
          muted={muted}
          playing={playing && !seeking}
          pause={this.handlePause}
          play={this.handlePlay}
          seekDown={this.handleSeekDown}
          seekUp={this.handleSeekUp}
          seekChange={this.handleSeekChange}
          setMuted={(isMuted: boolean) => this.setState({ muted: isMuted })}
          fullscreen={this.state.fullscreen}
          toggleFullscreen={this.toggleFullscreen}
          volume={this.state.volume}
          setVolume={(volume: number) => this.setState({ volume: volume })}
        />
        {this.state.survey && <CallToAction noClose close={() => {}} />}
      </div>
    );
  }
}

export default LivePlayer;
