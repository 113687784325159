import React from 'react';
import { SessionType } from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import { CmsNewDatePicker } from '../../../utils/CmsNewDatePicker';
import AudiSpacer from '../../../../utils/AudiSpacer';
import strings from '../../../../Localization/Localizer';

/** interface for EventTimeSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  headline: string;
  et: any;
  initialValues: any;
  setDateStart: (d: Date) => void;
  setDateEnd: (d: Date) => void;
  setTimezoneStart?: (tz: number) => void;
  setTimezoneEnd?: (tz: number) => void;
}

/** Subform for session timings */

const EventTimeSubform = (props: P) => {
  if (props.type !== SessionType.LIVETERMINATED && props.type !== SessionType.LIVE) return null;
  return (
    <TableRow key="event-time">
      <AudiSpacer spaceStackStart={'l'} />
      <CmsNewDatePicker
        text={strings.startsAt}
        onChange={props.setDateStart}
        onTimezoneChange={props.setTimezoneStart}
        initialDate={props.initialValues.start}
        initialTimezone={props.initialValues.startTimezone}
      />
      <CmsNewDatePicker
        text={strings.endsAt}
        onChange={props.setDateEnd}
        onTimezoneChange={props.setTimezoneEnd}
        initialDate={props.initialValues.end}
        initialTimezone={props.initialValues.endTimezone}
      />
    </TableRow>
  );
};

export default EventTimeSubform;
