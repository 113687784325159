import React, { useState } from 'react';
import { IconButton, Link, makeStyles, MenuItem } from '@material-ui/core';
import { Text, useIsTheme, UserIcon } from '@pp-labs/ui-components';
import { useDispatch } from 'react-redux';
import { setUser } from '../../AppSlice';
import { NavLink } from 'react-router-dom';
import strings from '../../Localization/Localizer';
import AudiMenu from '../../utils/AudiMenu';
import { useEventSettings, useIsTablet, useUser } from '../../utils/hooks';
import { useSignOut } from '../../ApiHandler/Cognito';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  buttonCollapse: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    whiteSpace: 'nowrap',
  },
  whiteText: {
    color: '#000000',
  },
  black: {
    color: '#000',
  },
}));

/**
 * User Menu, Profile and Logout
 */

export const UserMenu = () => {
  const user = useUser()!;
  const dispatch = useDispatch();
  const signOut = useSignOut();
  const eventSettings = useEventSettings();
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const cls = useStyles();
  const tablet = useIsTablet();
  const handleClose = () => setAnchor(null);
  const theme = useIsTheme();

  const open = !!anchor;

  // const nameClass = location.pathname.includes('profile')
  //   ? 'navLink--name navLink active'
  //   : 'navLink--name navLink';
  const userName = `${user.given_name} ${user.family_name}`;
  return (
    <div className={cls.buttonCollapse}>
      <div
        onClick={(e) => setAnchor(e.currentTarget)}
        className={cls.buttonCollapse}
        style={{ cursor: 'pointer' }}
      >
        {!tablet && (
          <Text variant="copy1" className={cls.text}>
            {userName}
          </Text>
        )}
        <IconButton className={cls.black}>
          <UserIcon />
        </IconButton>
      </div>

      <AudiMenu
        id="menu-login"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // see https://stackoverflow.com/a/52551100
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        <div onClick={handleClose}>
          <Link
            component={NavLink}
            underline="none"
            to={eventSettings?.identifier ? `/${eventSettings.identifier}/profile` : '/'}
          >
            <MenuItem>
              <Text as="span" variant="copy1" className={clsx(theme.neutralDark && cls.whiteText)}>
                {strings.profile}
              </Text>
            </MenuItem>
          </Link>

          <Link
            underline="none"
            onClick={async () => {
              await signOut();
              dispatch(setUser(null));
            }}
          >
            <MenuItem>
              <Text as="span" variant="copy1" className={clsx(theme.neutralDark && cls.whiteText)}>
                {strings.logout}
              </Text>
            </MenuItem>
          </Link>
        </div>
      </AudiMenu>
    </div>
  );
};
