import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { LabelButton, Select, EditDialog } from '@pp-labs/ui-components';
import { UserPicture } from '../../../ApiHandler/dclxInterfaces';
import SearchPart from './SearchPart';
import { client } from '../../../ApiHandler/client';

const validation = Yup.object({
  participant: Yup.string().required(strings.required),
}).required(strings.required);

const useStyles = makeStyles(() => ({
  len: {
    verticalAlign: 'top',
    maxWidth: '450px',
    padding: '0 12px',
  },
}));

/** interface for AddParticipant props coming from parent component ParticipantManager  */
interface P {
  id: number;
  closeDialog: (refresh: boolean) => void;
  visitors: Array<UserPicture>;
}

interface FormValues {
  participant: string;
}

/**
 * Form for adding a participant to a training
 */

const AddParticipant = (props: P) => {
  const [search, setSearch] = useState<string>('');
  const classes = useStyles();
  const close = () => {
    props.closeDialog(false);
  };

  const getErrors = (touched: any, errors: any) => {
    return {
      participant: touched.participant ? errors.participant : '',
    };
  };

  const submitToServer = async (v: FormValues) => {
    await client.post(`trainings/${props.id}/book/${v.participant}`);
    props.closeDialog(true);
  };

  const partSearch = (string: '') => {
    setSearch(string);
  };

  const initialValues: FormValues = {
    participant: '',
  };
  return (
    <EditDialog title={strings.addParticipant} close={close}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values: FormValues) => {
          submitToServer(values);
        }}
      >
        {({ errors, touched }) => {
          const et = getErrors(touched, errors);
          return (
            <Form>
              <div className={classes.len}>
                <SearchPart triggerSearch={partSearch} />
                <Select
                  name="participant"
                  label={`${strings.participant} ${search ? `${strings.matches}: ${search}` : ''}`}
                  error={et.participant}
                >
                  {props.visitors
                    .filter((p) =>
                      `${p.givenName} ${p.familyName}`.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((t) => (
                      <option key={`part-${t.username}`} value={t.userId}>
                        {t.givenName} {t.familyName}
                      </option>
                    ))}
                </Select>
              </div>

              <div style={{ padding: '20px' }} />
              <LabelButton type="submit" variant="primary">
                {strings.addParticipant}
              </LabelButton>
            </Form>
          );
        }}
      </Formik>
    </EditDialog>
  );
};

export default AddParticipant;
