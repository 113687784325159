import { Button } from '@material-ui/core';
import { Text, TextField } from '@audi/audi-ui-react';
import React, { useEffect, useState } from 'react';
import { client } from 'ApiHandler/client';
import { Field, useFormikContext } from 'formik';
import strings from 'Localization/Localizer';

/** interface for TaggingSubform props coming from parent components DCLXCmsSessionEdit and EditTest */
interface P {
  headline?: string;
  type: string;
  placeholder?: string;
}

/** Subform for adding tags */
export const TaggingSubform = (props: P) => {
  const [loadedList, setLoadedList] = useState<boolean>(false);
  const [tagList, setTagList] = useState<Array<string>>([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const { errors, values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    const getSessionTagList = async () => {
      const data = (await client.get(`${props.type}/reportingTags`)).data as Array<string>;
      setTagList(data);
    };
    if (!loadedList) {
      setLoadedList(true);
      getSessionTagList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMatchingList = () => {
    return tagList.filter(
      (tag) => tag && values.tag && tag.toLocaleLowerCase().includes(values.tag.toLocaleLowerCase())
    );
  };

  return (
    <div>
      <Text as="h3" variant="order4" className={props.headline} weight="bold">
        {strings.tagManager}
      </Text>
      <Text variant="copy2">{props.placeholder || ''}</Text>
      <Field
        name="tag"
        as={TextField}
        autoComplete="off"
        type="text"
        validationMessage={errors.tag || ''}
        inputId={'tag'}
        onFocus={() => setOpenSuggestions(true)}
      />

      {openSuggestions &&
        getMatchingList().length > 0 &&
        getMatchingList().map((t) => (
          <Button
            onClick={() => {
              setFieldValue('tag', t);
              setOpenSuggestions(false);
            }}
          >
            {t}
          </Button>
        ))}
    </div>
  );
};
