import { client } from 'ApiHandler/client';
import { ChannelTopic, GetTest, TestType } from 'ApiHandler/dclxInterfaces';
import strings from 'Localization/Localizer';
import React, { useEffect, useState } from 'react';
import { EditTest } from '../Tests/EditTest';
import {
  EditIcon,
  EraseIcon,
  LabelButton,
  tableStyles,
  TableRow,
  SessionTableCell,
  Text,
} from '@pp-labs/ui-components';
import { IconButton, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import { NotCreatedPlaceholder } from '../Sessions/NotCreatedPlaceholder';
import { format } from 'date-fns';
import { standardFormat } from 'config';

const useStyle = makeStyles({
  ...tableStyles(),
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

/** interface for TestTable props coming from parent component TopicTable */
interface P {
  topic: ChannelTopic;
  handleDelete: (id: number) => void;
  setDeleteType: (string: 'exam' | 'topic' | 'channel') => void;
}

/** List the pre/post tests of a topic */
const TestTable = (props: P) => {
  const cls = useStyle();
  const [tests, setTests] = useState<GetTest[]>([]);
  const [testEdit, setTestEdit] = useState<boolean>(false);
  const [currentTest, setCurrentTest] = useState<GetTest | null>(null);
  const getTest = async () => {
    const allTests = (await client.get('exams')).data;
    const filteredTests = allTests
      .filter((t: GetTest) => t.topicId === props.topic.topicId)
      .sort((a: GetTest) => (a.examType === 'pre' ? -1 : 1));
    setTests(filteredTests);
  };

  useEffect(() => {
    getTest();
    // eslint-disable-next-line
  }, [props.handleDelete]);
  const getAllowedTests = (): TestType[] => {
    if (!testEdit) return [];
    const allowed: TestType[] = [];
    if (currentTest) allowed.push(currentTest?.examType);
    if (!tests.some((t) => t.examType === 'pre')) allowed.push('pre');
    if (!tests.some((t) => t.examType === 'post')) allowed.push('post');
    return allowed;
  };
  return (
    <div style={{ marginLeft: '82px', marginBottom: '42px' }}>
      {!!tests.length ? (
        <TableContainer style={{ marginTop: '4px' }}>
          {tests.map((test) => {
            return (
              <Table key={test.examId} className={cls.table} aria-label="simple table">
                <TableBody>
                  <TableRow style={{ background: '#f2f2f2' }}>
                    <SessionTableCell title>
                      <div className={cls.titleContainer}>
                        <Text as="p" variant="copy1" weight={'bold'}>
                          {test.examName}
                        </Text>
                      </div>
                    </SessionTableCell>
                    <SessionTableCell>
                      <Text as="p" variant="copy3">
                        {strings.type}
                      </Text>
                      <Text as="p" variant="copy1">
                        {test.examType}
                      </Text>
                    </SessionTableCell>
                    <SessionTableCell>
                      <Text as="p" variant="copy3">
                        {strings.unlocksAt}
                      </Text>
                      <Text as="p" variant="copy1">
                        {format(test.unlockAt * 1000, standardFormat)}
                      </Text>
                    </SessionTableCell>

                    <SessionTableCell controls>
                      <IconButton
                        onClick={() => {
                          setCurrentTest(test);
                          setTestEdit(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          props.handleDelete(test.examId);
                          props.setDeleteType('exam');
                        }}
                      >
                        <EraseIcon />
                      </IconButton>
                    </SessionTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
          })}
        </TableContainer>
      ) : (
        <NotCreatedPlaceholder text={strings.noTest} />
      )}
      <LabelButton
        variant="primary"
        onClick={() => {
          setCurrentTest(null);
          setTestEdit(true);
        }}
        disabled={tests.length > 1}
      >
        {strings.addTest}
      </LabelButton>
      {testEdit && (
        <EditTest
          topic={props.topic}
          initialTest={currentTest}
          close={() => {
            setTestEdit(false);
            getTest();
          }}
          refresh={getTest}
          allowed={getAllowedTests()}
        />
      )}
    </div>
  );
};

export { TestTable };
