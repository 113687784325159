import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { blackStyle } from '../features/Sessions/Watch/blackStyle';
import { getLocalizedValue } from 'Localization/Localizer';
import { SelectIcon, UnselectIcon } from 'icons';
import { AudioTrack, Language, PlayerSub } from 'ApiHandler/dclxInterfaces';

export type CurrentSub = PlayerSub | { srcLang: { name: 'Off'; code: 'off' } };

/** interface for SubMenu props coming from parent component VideoControls */
interface SubProps {
  thisSub: { srcLang: Language };
  selectSub: (lang: Language) => void;
  currentSub?: CurrentSub;
  whiteText: string;
}

/** Player subtitle/audiotrack management */
export const SubMenu = (props: SubMenuProps) => {
  const cls = useStyles();
  return (
    <div className={cls.langSwitch}>
      <div className={cls.arrow} />
      {props.allAudio && props.allAudio.length > 1 && (
        <div className={cls.subBlock}>
          <Text variant="order4" as="p" className={cls.blackText}>
            Audio
          </Text>
          <div style={{ paddingTop: 16 }}>
            {props.allAudio?.map((s) => (
              <Audio
                key={s.name}
                thisAudio={s}
                selectAudio={props.selectAudio}
                currentAudio={props.currentAudio}
                whiteText={cls.blackText}
              />
            ))}
          </div>
        </div>
      )}
      {props.allSubs && !!props.allSubs.length && (
        <div className={cls.subBlock}>
          <Text variant="order4" as="p" className={cls.blackText}>
            Subtitles
          </Text>

          <div style={{ paddingTop: 16 }}>
            {[{ srcLang: { name: 'Off', code: 'off' } }, ...props.allSubs].map((s: any) => (
              <Sub
                key={s.srcLang.code}
                thisSub={s}
                selectSub={props.selectSub}
                currentSub={props.currentSub}
                whiteText={cls.blackText}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Sub = (props: SubProps) => {
  return (
    <div
      key={props.thisSub.srcLang.code}
      onClick={() => props.selectSub(props.thisSub.srcLang)}
      style={{ cursor: 'pointer' }}
    >
      <div style={{ display: 'inline' }}>
        <IconButton color="inherit" style={{ padding: '6px' }}>
          {props.currentSub?.srcLang.code === props.thisSub.srcLang.code ? (
            <SelectIcon />
          ) : (
            <UnselectIcon />
          )}
        </IconButton>
      </div>

      <div style={{ display: 'inline' }}>
        <Text as="span" variant="copy1" className={props.whiteText}>
          {getLocalizedValue(props.thisSub.srcLang.name)}
        </Text>
      </div>
    </div>
  );
};

interface AudioProps {
  thisAudio: AudioTrack;
  selectAudio: (audioTrack: string) => void;
  currentAudio?: AudioTrack | null;
  whiteText: string;
}

const Audio = (props: AudioProps) => {
  return (
    <div
      key={props.thisAudio.name}
      onClick={() => props.selectAudio(props.thisAudio.name)}
      style={{ cursor: 'pointer' }}
    >
      <div style={{ display: 'inline' }}>
        <IconButton color="inherit" style={{ padding: '6px' }}>
          {props.currentAudio?.name === props.thisAudio.name ? <SelectIcon /> : <UnselectIcon />}
        </IconButton>
      </div>

      <div style={{ display: 'inline' }}>
        <Text as="span" variant="copy1" className={props.whiteText}>
          {getLocalizedValue(props.thisAudio.name)}
        </Text>
      </div>
    </div>
  );
};

interface SubMenuProps {
  allSubs?: PlayerSub[];
  selectSub: (lang: Language) => void;
  currentSub?: CurrentSub;
  allAudio?: AudioTrack[];
  selectAudio: (audioTrack: string) => void;
  currentAudio?: AudioTrack | null;
}

const useStyles = makeStyles((theme) => ({
  ...blackStyle(theme),
  arrow: {
    position: 'absolute',
    transform: 'rotate(45deg)',
    bottom: '-15px',
    width: 30,
    height: 30,
    left: '50%',
    backgroundColor: 'rgba(25, 25, 25)',
  },
  langSwitch: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '12px 24px 12px 24px',
      bottom: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 24px 12px 18px',
      bottom: '50px',
    },
    backgroundColor: 'rgba(25, 25, 25)',
    color: 'white !important',
    display: 'flex',
  },
  subBlock: {
    display: 'inline-block',
    marginRight: '16px',
    marginLeft: '16px',
  },
}));
