import { makeStyles } from '@material-ui/core';
import { AdaptedIcon, LabelButton, LayoutItem, NativeSelect, Text } from '@pp-labs/ui-components';
import strings, { allLangs } from '../../../Localization/Localizer';
import React, { useMemo, useState } from 'react';
import { Language, LegalDocumentType } from '../../../ApiHandler/dclxInterfaces';
import { BaseStrings } from '../../../Localization/BaseStrings';
import { MediaLibraryDialog } from './MediaLibraryDialog';
import { useEventLanguageSelection, useLoad } from '../../../utils/hooks';
import { MediaFile } from './MediaLibrary';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
});

type Doc = {
  type: LegalDocumentType;
  string: BaseStrings;
};
const allDocs: Doc[] = [
  {
    string: 'legalNotice',
    type: LegalDocumentType.legal,
  },
  {
    string: 'conditionsOfUse',
    type: LegalDocumentType.conditions,
  },
  {
    string: 'dataProtection',
    type: LegalDocumentType.privacy,
  },
];

export const getLegalTag = (legalDoc: LegalDocumentType, language: Language['code']) =>
  `${legalDoc[0].toUpperCase() + legalDoc.slice(1)}#${language}`;

export const getLatest = (media: MediaFile[], tags: string) => {
  const rel = media.filter((m) => m.tags === tags).sort((a, b) => a.mediaId - b.mediaId);
  if (!rel.length) return null;
  return rel[rel.length - 1];
};

const getMediaId = (media: MediaFile[], tag: string) => {
  const latest = getLatest(media, tag);
  return latest ? [latest.mediaId] : [];
};

/** Edit/Replace a legal document */
export const LegalDocEdit = () => {
  const cls = useStyles();
  const [media, refresh] = useLoad<MediaFile[]>(`media`);
  const langs = useEventLanguageSelection();
  const [editing, setEditing] = useState<LegalDocumentType | null>(null);
  const [language, setLanguage] = useState<Language | null>(null);
  const editingTag = useMemo(
    () => (editing && language ? getLegalTag(editing, language.code) : ''),
    [editing, language]
  );
  return (
    <div>
      <Text variant={'order2'} weight={'bold'}>
        {strings.legalDocuments}
      </Text>
      <NativeSelect
        inputId="languageSelect"
        label={strings.language}
        value={language?.code || ''}
        onChange={(e) => {
          const code = e.target.value;
          const lang = allLangs.find((l) => l.code === code)!;
          setLanguage(lang);
        }}
      >
        {langs.languages.map((l) => (
          <option key={l.code} value={l.code}>
            {l.name}
          </option>
        ))}
      </NativeSelect>
      {language &&
        allDocs.map((d, i) => (
          <LayoutItem key={i} spaceStackStart="l">
            <div className={cls.flex}>
              <Text spaceInlineEnd="m" weight="bold">
                {strings[d.string]}{' '}
              </Text>
              {media?.some((l) => l.tags === getLegalTag(d.type, language.code)) && <AdaptedIcon />}
            </div>
            <LabelButton variant="secondary" onClick={() => setEditing(d.type)}>
              {strings.edit}
            </LabelButton>
          </LayoutItem>
        ))}
      {editing && language && (
        <MediaLibraryDialog
          onClose={() => setEditing(null)}
          onSubmit={() => {
            setEditing(null);
            refresh();
          }}
          interactType="none"
          fileTypes={'text/html,application/pdf'}
          open
          tags={editingTag}
          preselectedMediaIds={getMediaId(media || [], editingTag)}
          showCreateHtml={true}
        />
      )}
    </div>
  );
};
