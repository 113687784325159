import { makeStyles } from '@material-ui/core';
import { LabelButton, useTheme } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import React from 'react';

const useStyles = makeStyles(() => ({
  whiteBtn: {
    borderColor: 'white !important',
    color: 'white !important',
    alignSelf: 'flex-start',
  },
  lightThemeBtn: {
    borderColor: 'black !important',
    color: 'black !important',
    alignSelf: 'flex-start',
    '&:hover': {
      color: 'white !important',
    },
  },
}));

const JoinEventButton = () => {
  const isTheme = useTheme();
  const classes = useStyles();
  return (
    <LabelButton
      spaceStackStart={'xl'}
      variant={'secondary'}
      className={isTheme.variant === 'light' ? classes.lightThemeBtn : classes.whiteBtn}
    >
      {strings.joinEvent}
    </LabelButton>
  );
};

export { JoinEventButton };
