import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Text } from '@pp-labs/ui-components';
const useStyle = makeStyles({
  wrapper: {
    padding: '26px',
    border: 'solid 1px #e5e5e5',
    textAlign: 'center',
    marginTop: '12px',
  },
});

/** interface for NotCreatedPlaceholder props coming from parent components ChannelSettings, ChannelTable, TestTable and SessionTable */
interface P {
  text: string;
}

/** Placeholder if nothing was created in the top list */
const NotCreatedPlaceholder = (props: P) => {
  const cls = useStyle();
  return (
    <div className={cls.wrapper}>
      <Text variant={'copy1'}>{props.text}</Text>
    </div>
  );
};

export { NotCreatedPlaceholder };
