import { EditDialog, LabelButton } from '@pp-labs/ui-components';
import { useState } from 'react';
import AudiSpacer from 'utils/AudiSpacer';
import strings from '../../../Localization/Localizer';

/** interface for TerminateButton props coming from parent component Live  */
interface P {
  terminate: () => void;
}

/** Terminates a Live-Stream */
export const TerminateButton = (props: P) => {
  const [open, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);
  return (
    <>
      <LabelButton variant="primary" onClick={() => setOpen(true)}>
        {strings.terminate}
      </LabelButton>
      {open && (
        <EditDialog title={strings.terminatePopup} close={close} maxWidth="md">
          <AudiSpacer spaceStackStart="l" />
          <LabelButton variant="secondary" onClick={close} spaceInlineEnd="m">
            {strings.abort}
          </LabelButton>
          <LabelButton variant="primary" onClick={props.terminate}>
            {strings.terminate}
          </LabelButton>
        </EditDialog>
      )}
    </>
  );
};
