import strings from '../../../Localization/Localizer';
import { ConfigModuleIdentifiers, SessionType } from '../../../ApiHandler/dclxInterfaces';
import { EditDialog, LabelButton, tableStyles } from '@pp-labs/ui-components';
import React from 'react';
import { useNamedConfigModule } from '../../../utils/hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ...tableStyles(),
  cancelButton: {
    textAlign: 'right',
  },
});

type P = {
  createSession: (session: SessionType, stream?: boolean) => void;
  close: () => void;
};

export const SessionSelection = (props: P) => {
  const cls = useStyles();
  const usesModule = useNamedConfigModule();
  return (
    <EditDialog maxWidth="md" title={strings.selectSessionType} close={props.close} cms>
      {usesModule(ConfigModuleIdentifiers.liveStreamingSessions) && (
        <>
          {/* Do not remove commented code, will be needed in the future */}
          {/*<LabelButton*/}
          {/*  variant="secondary"*/}
          {/*  className={cls.btnMiddle}*/}
          {/*  stretch*/}
          {/*  onClick={() => {*/}
          {/*    props.createSession(SessionType.LIVETERMINATED);*/}
          {/*    props.close();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {strings.addLiveSessionPreproduced}*/}
          {/*</LabelButton>*/}

          {/* Do not remove commented code, will be needed in the future */}
          {/* <LabelButton
                variant="secondary"
                className={cls.btnMiddle}
                disabled
                stretch
                onClick={() => {
                  props.createSession(SessionType.EXTERNALTERMINATED);
                  props.close();
                }}
              >
                {strings.addLiveSessionExternal}
              </LabelButton>*/}

          <LabelButton
            variant="secondary"
            className={cls.btnMiddle}
            stretch
            onClick={() => {
              props.createSession(SessionType.LIVE, true);
              props.close();
            }}
          >
            {strings.addLiveSessionRTMPS}
          </LabelButton>
        </>
      )}

      {usesModule(ConfigModuleIdentifiers.onDemandSessions) && (
        <>
          <LabelButton
            variant="secondary"
            className={cls.btnMiddle}
            stretch
            onClick={() => {
              props.createSession(SessionType.DEMAND, false);
              props.close();
            }}
          >
            {strings.addOnDemandPreproduced}
          </LabelButton>
          {/* Do not remove commented code, will be needed in the future */}
          {/* <LabelButton
                variant="secondary"
                className={cls.btnMiddle}
                stretch
                onClick={() => {
                  setAddSession(false);
                  createSession(SessionType.EXTERNAL);
                }}
              >
                {strings.addOnDemandExternal}
              </LabelButton> */}
        </>
      )}
      {usesModule(ConfigModuleIdentifiers.videoConferenceSessions) && (
        <LabelButton
          variant="secondary"
          className={cls.btnMiddle}
          stretch
          onClick={() => {
            props.createSession(SessionType.TRAINING, false);
            props.close();
          }}
        >
          {strings.addContainerForConference}
        </LabelButton>
      )}
      <div className={cls.cancelButton}>
        <LabelButton variant="primary" onClick={props.close}>
          {strings.cancel}
        </LabelButton>
      </div>
    </EditDialog>
  );
};
