/**
 * Configuration of the application
 */

const testEnv = {
  REACT_APP_ENV: 'dev',
  REACT_APP_BASE_URL: 'studio-tool-dev.pplabs.de',
  REACT_APP_WEBSOCKET_URL:
    'wss://4rek3bt9z2.execute-api.eu-central-1.amazonaws.com/socket?id_token=',
  REACT_APP_CONFERENCE_TEST_URL: '',
};

const e = Object.keys(process?.env).length ? process.env : testEnv;

const {
  REACT_APP_ENV,
  REACT_APP_BASE_URL,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_CONFERENCE_TEST_URL,
} = e;

type Env = 'local' | 'dev' | 'staging' | 'prod' | 'prod2';
export const env = (): Env => (REACT_APP_ENV as Env) || 'prod';

export const appUrl = REACT_APP_BASE_URL as string;
export const baseUrl = `https://${appUrl}`;
export const APIUrl = env() === 'local' ? appUrl : `https://api.${appUrl}`;
export const WebsocketUrl = REACT_APP_WEBSOCKET_URL as string;
export const conferenceTestUrl = REACT_APP_CONFERENCE_TEST_URL as string;

export const techTestFiles = (lang: string) =>
  `https://studio-tool-public-files.s3.eu-central-1.amazonaws.com/TechTest_${lang}.pdf`;

export const lotLobby = {
  waitingVideoUrl: `${baseUrl}/hls/M21_MainFilm_16to9_EN_VMS_UHD_2_1_10_min.mp4`,
  showVideo: true,
  // let participants enter the LOT 10 minutes before it actually starts
  preAccess: 600,
};

export const eventDate = new Date(Date.UTC(2021, 1, 9, 18));
export const clx: boolean = false;
export const adminRoute = 'admin';
export const standardFormat = 'dd.MM.yyyy | HH:mm';
export const csvFormat = 'dd.MM.yyyy HH:mm';
export const dateOnly = 'dd.MM';
export const timeOnly = 'HH:mm';
export const standardFormatWithSeconds = 'dd.MM.yyyy | HH:mm:ss';
export const defaultVolume = 0.7;
export const withIdp = ['ama', 'biglots', 'produktionsqualifizierung2022-pilot'];
export const allThemes = ['audi', 'light', 'dark'] as const;
export const BreakoutClosingSeconds = env() === 'dev' ? 15 : 60;
export const ModHandle = '@moderator';
/** Enforces a refresh of the global progression state after returning to a page after x ms.
 * This can be needed because the user could have done a video, survey etc. */
export const RequireRefreshAfter = 20000;
/** Switches whether LOTs are considered in Progress for Tests/Topic Badges or not */
export const considerLotsInProgression: boolean = false;
