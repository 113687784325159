import React, { useState } from 'react';
import { NotificationMessage } from './Messages';
import { EraseIcon, Text } from '@pp-labs/ui-components';
import { format } from 'date-fns';
import { IconButton, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { EditorParser } from 'features/Editor/EditorParser';
import { client } from 'ApiHandler/client';
import { useRealTime } from 'utils/hooks';
import AudiSpacer from 'utils/AudiSpacer';
import { ArrowDown, ArrowUp } from 'icons';
import { getLocalizedValue } from 'Localization/Localizer';
import { standardFormat } from 'config';

const useStyles = makeStyles({
  wrapper: {
    background: '#E5E5E5',
    padding: 30,
    margin: '6px 0px',
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightWrapper: {
    width: '25%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    width: '60%',
  },
});

/** interface for MessageTable props coming from parent component Message */
interface P {
  message: NotificationMessage;
  refresh: () => void;
}
/** List all messages. */
const MessageTable = (props: P) => {
  const cls = useStyles();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const currentTime = useRealTime(5000);
  const messageSent = currentTime >= props.message?.validFrom * 1000;
  const handleDelete = async (id: number) => {
    await client.delete(`/notifications/${id}`);
    props.refresh();
  };
  return (
    <div className={cls.wrapper}>
      <div className={cls.innerWrapper}>
        <div className={cls.text}>
          <Text variant="copy1" weight={'bold'}>
            {getLocalizedValue(props.message.title)}
          </Text>
        </div>
        <div className={cls.rightWrapper}>
          <IconButton style={{ color: 'black' }} onClick={() => setShowMessage(!showMessage)}>
            {showMessage ? <ArrowUp /> : <ArrowDown />}
          </IconButton>
          <div>
            <Text variant="copy1">sent</Text>
            <Text variant="copy1" weight={'bold'}>
              {format(props.message.validFrom * 1000, standardFormat)}
              {messageSent && <CheckCircleIcon fontSize="small" />}
            </Text>
          </div>
          <IconButton
            style={{ color: 'black' }}
            onClick={() => handleDelete(props?.message?.notificationId)}
          >
            <EraseIcon />
          </IconButton>
        </div>
      </div>
      {showMessage && (
        <div className={cls.text}>
          <AudiSpacer spaceStackStart={'m'} />
          <Text variant="copy1">
            <EditorParser inputString={props.message.message} />
          </Text>
        </div>
      )}
    </div>
  );
};

export { MessageTable };
