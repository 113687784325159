import React from 'react';
import AudiSpacer from '../../../utils/AudiSpacer';
import { useTexts } from '../../../utils/hooks';
import { EditorParser } from '../../../features/Editor/EditorParser';
import { useIsTheme } from '@pp-labs/ui-components';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

/**
 * Some legal texts
 */

const useStyles = makeStyles((theme) => ({
  neutralWrapper: {
    padding: '0 56px 20px 56px',
    minWidth: '100%',
    [theme.breakpoints.only('md')]: {
      padding: '0 56px 20px 56px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0 34px 20px 34px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px 20px 20px',
    },
  },
}));

const AudiCompliance = () => {
  const { getText } = useTexts();
  const text = getText('startPageDisclaimer');
  const theme = useIsTheme();
  const cls = useStyles();
  if (!text) return null;
  return (
    <div className={clsx(theme.neutral && cls.neutralWrapper)}>
      <AudiSpacer spaceStackStart={'xxxl'} neutralSpaceStackStart="xs" />
      <EditorParser inputString={text} />
      <AudiSpacer neutralSpaceStackStart="xxxl" />
    </div>
  );
};

export default AudiCompliance;
