import { CognitoUser } from '../../../ApiHandler/dclxInterfaces';
import { getIdToken } from '../../../ApiHandler/Cognito';

/**
 * Various methods using the jitsiAPI
 */

export const loadJitsiScript = async (url: string) => {
  let resolveLoadJitsiScriptPromise = null;

  const loadJitsiScriptPromise = new Promise((resolve) => {
    resolveLoadJitsiScriptPromise = resolve;
  });

  const script = document.createElement('script');
  script.src = `https://${url}/external_api.js?jwt=${await getIdToken()}`;
  script.async = true;
  script.onload = resolveLoadJitsiScriptPromise;
  document.body.appendChild(script);

  return loadJitsiScriptPromise;
};

export const jitsiSetDisplayName = (jitsi: Jitsi, name: string) => {
  jitsi?.executeCommand('displayName', name);
};

export const disposeJitsi = (jitsi: Jitsi) => {
  jitsi?.dispose?.();
};

export const hangUp = (jitsi: Jitsi) => {
  jitsi?.executeCommand('hangup');
};

export const jitsiSetAudioMuted = (jitsi: Jitsi, setMute: boolean) => {
  jitsi?.isAudioMuted().then((muted: boolean) => {
    if (setMute !== muted) {
      jitsi?.executeCommand('toggleAudio');
    }
  });
};

export const jitsiSetVideoMuted = (jitsi: Jitsi, setMute: boolean) => {
  jitsi?.isVideoMuted().then((muted: boolean) => {
    if (setMute !== muted) {
      jitsi?.executeCommand('toggleVideo');
    }
  });
};

export const jitsiSetView = (jitsi: Jitsi, view: 2 | 3) => {
  jitsi?.executeCommand('setTileView', view === 3);
};

export const jitsiToggleScreenshare = (jitsi: Jitsi) => {
  jitsi?.executeCommand('toggleShareScreen');
};

// not complete, extend if needed
export type ToolbarButton = 'microphone' | 'camera' | 'desktop' | 'settings';
type Config = {
  toolbarButtons: ToolbarButton[];
};
export const jitsiOverrideConfig = (jitsi: Jitsi, config: Config) => {
  jitsi?.executeCommand('overwriteConfig', config);
};

export const jitsiContainerId = 'jitsi-container-id';

interface J {
  isAudioMuted: Function;
  isVideoMuted: Function;
  executeCommand: Function;
  dispose: Function;
  addListener: Function;
  getParticipantsInfo: Function;
}

export type Jitsi = J | undefined;

interface Settings {
  room: string;
  role: Role;
  user: CognitoUser;
  muteListener: (muted: boolean) => void;
  videoMuteListener: (muted: boolean) => void;
  jitsiUrl: string;
  tileViewListener: (enabled: boolean) => void;
  screenShareListener: (enabled: boolean) => void;
  videoConferenceJoined: (d: { id: string }) => void;
  screenshareChanged: (d: { data: string[] }) => void;
  audioMutedInitial: boolean;
  videoMutedInitial: boolean;
  overwrite: Config;
}

export type Role = 'trainer' | 'participant' | 'backup';
export const getDisplayName = (me: CognitoUser, role: Role) => {
  // This was for cotrainers, but we now handle them as normal participants
  // if (role === 'backup') return 'inactive';
  const name = `${me.given_name} ${me.family_name}`;
  if (role === 'trainer') return `Moderator: ${name}`;
  return name;
};

export const initialiseJitsi = async (s: Settings): Promise<Jitsi> => {
  // @ts-ignore
  if (!window.JitsiMeetExternalAPI) {
    await loadJitsiScript(s.jitsiUrl);
  }
  // @ts-ignore
  const _jitsi: J = new window.JitsiMeetExternalAPI(`${s.jitsiUrl}`, {
    parentNode: document.getElementById(jitsiContainerId),
    roomName: s.room,
    jwt: await getIdToken(),
    configOverwrite: {
      startWithAudioMuted: s.audioMutedInitial,
      startWithVideoMuted: s.videoMutedInitial,
      ...s.overwrite,
    },
    userInfo: {
      displayName: getDisplayName(s.user, s.role),
    },
  });

  _jitsi.addListener('audioMuteStatusChanged', (muted: any) => {
    s.muteListener(muted.muted);
  });

  _jitsi.addListener('videoMuteStatusChanged', (muted: any) => {
    s.videoMuteListener(muted.muted);
  });

  _jitsi.addListener('tileViewChanged', (state: any) => {
    s.tileViewListener(state.enabled);
  });

  _jitsi.addListener('screenSharingStatusChanged', (state: any) => {
    s.screenShareListener(state.on);
  });

  _jitsi.addListener('videoConferenceJoined', (state: any) => {
    s.videoConferenceJoined(state);
  });

  _jitsi.addListener('contentSharingParticipantsChanged', (state: any) => {
    s.screenshareChanged(state);
  });

  return _jitsi;
};
