import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@audi/audi-ui-react';
import { useState } from 'react';
import { ArrowDown, ArrowUp } from '../icons';

interface P {
  outer: string;
  inner: string;
}
const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  outerText: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
  },
  arrow: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  innerText: {
    paddingTop: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
  },
});
export const Collapsable = (props: P) => {
  const cls = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={cls.wrapper} onClick={() => setOpen(props.inner ? !open : false)}>
      <div className={cls.outerText}>
        <Text weight={open ? 'bold' : 'normal'}>{props.outer}</Text>
        {open && (
          <div className={cls.innerText}>
            <Text>{props.inner}</Text>
          </div>
        )}
      </div>
      {props.inner && <div className={cls.arrow}>{open ? <ArrowUp /> : <ArrowDown />}</div>}
    </div>
  );
};
