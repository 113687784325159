import React from 'react';
import { SessionType } from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import strings from '../../../../Localization/Localizer';
import { TableCell, TextField } from '@pp-labs/ui-components';

/** interface for StreamSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  headline: string;
  et: any;
  initialValues: any;
}

/** Subform for entering external URLs */
const StreamSubform = (props: P) => {
  if (props.type !== SessionType.EXTERNAL) return null;
  return (
    <TableRow key="stream">
      <TableCell>
        <Text as="h3" variant="order4" className={props.headline}>
          <b>{strings.stream}</b>
        </Text>
        <TextField
          name="stream"
          label={`${strings.stream} (e.g. https://youtu.be/aBc123DE456)`}
          error={props.et.stream}
        />
      </TableCell>
    </TableRow>
  );
};

export default StreamSubform;
