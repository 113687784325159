import React, { useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Login from './auth/Login';
import { PrivateRoute } from './utils/PrivateRoute';
import { Footer } from './features/Footer/Footer';
import Profile from './features/Profile/Profile';
import { CMSAccess, DashboardAccess, Role } from './utils/RoleDefinitions';
import Notification from './utils/Notification';
import { CMS } from './DCLX/CMS/CMS';
import FAQ from './features/FAQ/FAQ';
import Watch from './features/Sessions/Watch/Watch';
import { adminRoute } from './config';
import { Dashboard } from './features/Dashboard/Dashboard';
import { LotWrap } from 'DCLX/FE/LOT/LotWrap';
import { MetaData } from './features/MetaData/MetaData';
import { MainPage } from 'features/MainPage/MainPage';
import { BadBrowserGuard } from './features/BadBrowser/BadBrowserGuard';
import { OverallSurvey } from './features/Survey/OverallSurvey';
import { AdminPanelTable } from 'DCLX/CMS/AdminEventSetup/AdminPanelTable';
import Live from './DCLX/FE/LOT/Live';
import { CookieDialogWrapperRef, CookieInfoPage } from 'cookieInfos';
import { PageWrapper } from '@pp-labs/ui-components';
import { LiveAmaRoute } from './DCLX/CMS/LiveAma/LiveAmaRoute';
import { TechTestRoute } from './features/TechTest/TechTestRoute';
import EventWatch from './features/Sessions/Watch/EventWatch';
import { useEventSettings, useForceNoRender, useLanguage } from './utils/hooks';
import { SSOLogin } from 'auth/SSOLogin';
import { Event } from './DCLX/FE/Startpages/Event';
import { AmaView } from './DCLX/CMS/ConventionAma/AmaView';
import { AmaWrapper } from './DCLX/CMS/ConventionAma/AmaWrapper';
import './uiComponents.css';
import { PlatformEvent } from 'ApiHandler/dclxInterfaces';
import { setLanguage } from 'AppSlice';
import { useDispatch } from 'react-redux';
import { LinkMapper } from './features/LinkMapper/LinkMapper';

/** Suspends all rendering during language switch, to make sure the whole site gets translated */
export const LanguageSwitcher = () => {
  const language = useLanguage();
  const event = useEventSettings();
  const dispatch = useDispatch();

  const setInitialLang = (event: PlatformEvent | null) => {
    const prefLang = event?.languages ? JSON.parse(event.languages).preferedLanguage : undefined;
    dispatch(setLanguage(prefLang));
  };

  useEffect(() => {
    //change default language on event change
    setInitialLang(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);
  const pause = useForceNoRender([language]);

  if (pause) return null;
  return <App />;
};
/***
 * Main component, handles always active Components (such as Navbar or Footer) and manages the Routing
 */
const App = () => {
  const cookieDialogRef = useRef<CookieDialogWrapperRef>();
  return (
    <PageWrapper>
      <Grid container direction={'row'} spacing={0}>
        <BadBrowserGuard>
          <MetaData />
          <Switch>
            <Route component={CookieInfoPage} path="/cookieInformations" exact />
            <Route component={TechTestRoute} path="/techTest" exact />
            <Route component={LinkMapper} path="/:event/direct/:type/:position" exact />
            <PrivateRoute requiredRole={CMSAccess} component={CMS} path="/:event/cms/" exact />
            <PrivateRoute requiredRole={CMSAccess} component={CMS} path="/:event/cms/:tab" exact />
            <PrivateRoute
              requiredRole={CMSAccess}
              component={CMS}
              path="/:event/cms/:channelId/:tab"
              exact
            />
            <PrivateRoute
              requiredRole={Role.ANY}
              component={Profile}
              path="/:event/profile"
              exact
            />

            <PrivateRoute requiredRole={Role.ANY} component={FAQ} path="/:event/support" exact />
            <PrivateRoute
              requiredRole={Role.ANY}
              component={Event}
              path={`/:event/page/:menuItem/:channelId`}
              exact
            />
            <PrivateRoute
              requiredRole={Role.ANY}
              component={Event}
              path={`/:event/page/:menuItem/:channelId/survey/:surveyId`}
              exact
            />
            <PrivateRoute
              requiredRole={Role.ANY}
              component={Event}
              path={`/:event/page/:menuItem/:channelId/agenda/:agendaId/:agendaItemId?`}
            />

            <PrivateRoute
              requiredRole={Role.ANY}
              component={OverallSurvey}
              path="/:event/survey/:id?"
              exact
              onlyP3
            />

            <PrivateRoute
              requiredRole={DashboardAccess}
              component={Dashboard}
              path="/:event/dashboard/:category"
              exact
            />

            <PrivateRoute
              requiredRole={Role.ANY}
              component={Watch}
              path="/:event/onDemand/:id"
              exact
            />

            <PrivateRoute
              requiredRole={Role.ANY}
              component={EventWatch}
              path="/:event/events/:id"
              exact
            />
            <PrivateRoute requiredRole={Role.ANY} component={Live} path="/:event/live/:id" exact />
            <PrivateRoute
              requiredRole={[
                Role.VISITOR,
                Role.VIEWER,
                Role.TRAINER,
                Role.MANAGER,
                Role.REPORTINGS,
                Role.MARKET,
              ]}
              component={Event}
              path="/:event/events"
              exact
            />
            <PrivateRoute
              requiredRole={[
                Role.VISITOR,
                Role.VIEWER,
                Role.TRAINER,
                Role.MANAGER,
                Role.REPORTINGS,
              ]}
              path="/:event/trainings"
              toStart
              exact
            />
            <PrivateRoute
              path="/:event/amas"
              requiredRole={[Role.DIRECTOR]}
              exact
              component={LiveAmaRoute}
            />
            <PrivateRoute
              requiredRole={Role.ANY}
              component={LotWrap}
              path="/:event/lot/:id"
              exact
            />
            <PrivateRoute
              requiredRole={[Role.DIRECTOR, Role.MANAGER, Role.REPORTINGS, Role.MARKET]}
              component={AmaView}
              path="/:event/amaView/:id"
              exact
            />
            <PrivateRoute
              requiredRole={[Role.DIRECTOR, Role.REPORTINGS]}
              component={AmaWrapper}
              path="/:event/amaCms"
              exact
            />
            <PrivateRoute
              requiredRole={Role.EVENTADMIN}
              component={AdminPanelTable}
              path={`/${adminRoute}/create`}
              exact
            />
            <PrivateRoute requiredRole={Role.ANY} path="/:event/home" toStart exact />
            <PrivateRoute requiredRole={Role.ANY} path="/:event" toStart exact />
            <Route component={SSOLogin} path="/:event/ssologin" exact />
            <Route component={Login} path="/:event/login" exact />
            <Route component={Login} path="/:event/register" exact />
            <Route component={Login} path="/:event/login/:username" exact />
            <Route component={Login} path="/:event/register/:username" exact />
            <Route component={Login} path="/:event/register/:username/:password/:redirect?" exact />
            <Route component={Login} path="/:event/login/:username/:password/:redirect?" exact />
            <Route component={MainPage} path="/" />
          </Switch>
        </BadBrowserGuard>
      </Grid>
      {/* <CookieDialogWrapper ref={cookieDialogRef} /> */}
      <Footer
        openCookieSettings={() =>
          cookieDialogRef.current && cookieDialogRef.current.forceOpen(true)
        }
      />
      <Notification />
    </PageWrapper>
  );
};
