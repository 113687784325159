import React, { useMemo } from 'react';
import { EventAttributes, createEvent } from 'ics';
import { LabelButton, isMobileSafari, useTheme } from '@pp-labs/ui-components';
import strings, { getLocalizedValue } from '../Localization/Localizer';
import { baseUrl } from '../config';
import { useEventSettings } from './hooks';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

/** interface for CalendarExport props coming from parent component TrainingsTile, FeaturedEvent, MySchedule */
interface P {
  start: Date;
  duration?: number;
  title: string;
  description: string;
  end?: Date;
  textClasses?: string;
  icon?: () => JSX.Element;
  sessionTile?: boolean;
}

/**
 * Export to Calendar Button
 */
const CalendarExport = (props: P) => {
  const eventSettings = useEventSettings()!;
  const isTheme = useTheme();
  const metadata = useMemo(
    () => ({
      title: getLocalizedValue(props.title),
      description: getLocalizedValue(props.description),
    }),
    [props.title, props.description]
  );

  const generateEvent = async () => {
    const end =
      props.end ||
      new Date(
        props.start.getFullYear(),
        props.start.getMonth(),
        props.start.getDate(),
        props.start.getHours(),
        props.start.getMinutes() + (props.duration || 60)
      );
    const url = `${baseUrl}/${eventSettings.identifier}`;
    const event: EventAttributes = {
      start: [
        props.start.getFullYear(),
        props.start.getMonth() + 1,
        props.start.getDate(),
        props.start.getHours(),
        props.start.getMinutes(),
      ],
      end: [end.getFullYear(), end.getMonth() + 1, end.getDate(), end.getHours(), end.getMinutes()],
      title: `${eventSettings.title} | ${metadata.title}`,
      description: metadata.description,
      url: url,
      location: url,
    };
    const d = createEvent(event);
    download(d.value);
  };

  const download = (e: any) => {
    const b = new Blob([e]);
    const url = URL.createObjectURL(b);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'event.ics';
    if (!isMobileSafari()) a.target = '_blank';
    a.click();
    a.remove();
  };

  if (props.icon)
    return (
      <IconButton onClick={generateEvent}>
        <props.icon />
      </IconButton>
    );
  return (
    <div id={clsx(isTheme.name === 'neutral' && !props.sessionTile ? 'lotDescFE' : undefined)}>
      <LabelButton onClick={generateEvent} variant="text" className={props.textClasses}>
        {strings.export}
      </LabelButton>
    </div>
  );
};

export default CalendarExport;
