import { IconButton, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import {
  AmaAnswer,
  AnsweredAma,
  AnsweringAma,
  AnyAma,
  BaseAma,
} from '../../../ApiHandler/dclxInterfaces';
import { Text } from '@audi/audi-ui-react';
import { FunctionFilter } from './FunctionFilter';
import { EditIcon, LabelButton, TableCell, TableRow, tableStyles } from '@pp-labs/ui-components';
import { format } from 'date-fns';
import { standardFormat } from '../../../config';
import { useState } from 'react';
import { useQuestionState } from './QuestionState';
import { client } from '../../../ApiHandler/client';
import { serverNow } from '../../../utils/convert';
import { TypeFilter, TypeFilterValues } from './TypeFilter';
import AudiSpacer from '../../../utils/AudiSpacer';
import { useUser } from '../../../utils/hooks';
import { gutterStyle } from '../../../utils/gutter';
import { TableArrows } from 'DCLX/utils/TableArrows';

const useStyles = makeStyles((theme) => ({
  ...tableStyles(),
  ...gutterStyle(theme),
  header: {
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 16,
  },
  wrap: {
    padding: '16px 0',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export type SectionType = 'answered' | 'live' | 'unanswered' | 'dismissed';

interface Answered {
  type: 'answered';
  questions: AnsweredAma[];
}

interface Live {
  type: 'live';
  questions: AnsweredAma[];
}

interface Unanswered {
  type: 'unanswered';
  questions: (BaseAma | AnsweringAma)[];
}

interface Dismissed {
  type: 'dismissed';
  questions: (BaseAma | AnsweringAma)[];
}

const getString = (type: SectionType) => {
  switch (type) {
    case 'answered':
      return 'Answered Questions';
    case 'unanswered':
      return 'Unanswered Questions';
    case 'dismissed':
      return 'Dismissed Questions';
    case 'live':
      return 'Live Questions';
  }
};

type P = Answered | Live | Unanswered | Dismissed;

/** Lists all the AMAs */
export const QuestionSection = (props: P) => {
  const [open, setOpen] = useState<boolean>(true);
  const [typeFilter, setTypeFilter] = useState<TypeFilterValues>('all');
  const cls = useStyles();
  return (
    <div className={cls.wrap}>
      <div className={cls.header} onClick={() => setOpen(!open)}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontSize: 24, lineHeight: '24px', color: 'black' }}>
            {<TableArrows open={open && !!props.questions.length} />}
          </div>
          <AudiSpacer spaceInlineEnd="l" />
          <Text weight="bold">{`${getString(props.type)} (${props.questions.length})`}</Text>
        </div>
        <div style={{ display: 'flex' }}>
          {props.type === 'answered' && <TypeFilter setValue={setTypeFilter} />}
          <AudiSpacer spaceInlineEnd="l" />
          <FunctionFilter type={props.type} />
        </div>
      </div>
      {open && (
        <TableContainer>
          <Table className={cls.table} aria-label="simple table">
            <TableBody>
              {props.questions
                .filter(
                  (q) =>
                    props.type !== 'answered' || typeFilter === 'all' || q.answerMode === typeFilter
                )
                .map((q: AnyAma) => {
                  return (
                    <TableRow key={q.liveAmaId}>
                      <TableCell center style={{ width: '25%' }}>
                        <Text as="p" variant="copy1" weight="bold" className={cls.maxOneLine}>
                          {q.question}
                        </Text>
                      </TableCell>
                      <TableCell center style={{ width: '15%' }}>
                        <Text variant="copy3">Submitted on</Text>
                        <Text as="p" variant="copy1">
                          {format(q.createdAt * 1000, standardFormat)}
                        </Text>
                      </TableCell>
                      <TableCell center style={{ width: '15%' }}>
                        <Text variant="copy3">User</Text>
                        <Text as="p" variant="copy1">
                          {q.fullUsername}
                        </Text>
                      </TableCell>
                      <TableCell center style={{ width: '10%' }}>
                        {props.type === 'answered' && (
                          <>
                            <Text variant="copy3">Type</Text>
                            <Text as="p" variant="copy1" className={cls.capitalize}>
                              {q.answerMode}
                            </Text>
                          </>
                        )}
                      </TableCell>
                      <TableCell center style={{ width: '35%' }}>
                        <Interactions type={props.type} ama={q} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

interface InteractionProps {
  type: SectionType;
  ama: AnyAma;
}

/** How many minutes a question stays occupied without change */
const thresholdMinutes = 2;
const Interactions = (props: InteractionProps) => {
  const user = useUser()!;
  const { setAnswering, wsSend, directors } = useQuestionState();
  if (
    props.ama.startAnswer &&
    props.ama.startAnswer * 1000 + thresholdMinutes * 60000 > Date.now() &&
    props.ama.answeredBy !== user.sub
  ) {
    const directorsFiltered = directors.find((d) => d.username === props.ama.answeredBy);
    const name = directorsFiltered
      ? `${directorsFiltered.givenName} ${directorsFiltered.familyName}`
      : 'Unknown';
    return <Text>This question is currently edited by {name}</Text>;
  }

  if (props.type === 'answered') {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'right' }}>
        <IconButton style={{ color: 'black' }} onClick={() => setAnswering(props.ama)}>
          <EditIcon />
        </IconButton>
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'right' }}>
        {props.type === 'live' && (
          <LabelButton
            variant="primary"
            size="small"
            spaceInlineEnd="s"
            onClick={async () => {
              const data: AmaAnswer = {
                answer: 'live',
                answerMode: 'live',
                dismissed: false,
                answeredBy: 'live',
                startAnswer: null,
              };
              await client.put(`sessions/liveAmas/${props.ama.liveAmaId}`, data);
              wsSend({ name: 'refreshAmaDir', time: serverNow() });
            }}
          >
            Answered in Live Stream
          </LabelButton>
        )}
        <LabelButton
          variant={props.type === 'live' ? 'secondary' : 'primary'}
          size="small"
          spaceInlineEnd="s"
          onClick={() => setAnswering(props.ama)}
        >
          Answer
        </LabelButton>
        {props.type !== 'live' && (
          <LabelButton
            variant="secondary"
            size="small"
            spaceInlineEnd="s"
            onClick={async () => {
              const data: AmaAnswer = {
                answer: 'pending',
                answerMode: 'live',
                dismissed: false,
                answeredBy: null,
                startAnswer: null,
              };
              await client.put(`sessions/liveAmas/${props.ama.liveAmaId}`, data);
              wsSend({ name: 'refreshAmaDir', time: serverNow() });
            }}
          >
            Live
          </LabelButton>
        )}
        {props.type !== 'dismissed' && (
          <LabelButton
            variant="secondary"
            size="small"
            spaceInlineEnd="s"
            onClick={async () => {
              await client.put(`sessions/liveAmas/${props.ama.liveAmaId}`, { dismissed: true });
              wsSend({ name: 'refreshAmaDir', time: serverNow() });
            }}
          >
            Dismiss
          </LabelButton>
        )}
      </div>
    );
  }
};
