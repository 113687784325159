import React, { useEffect, useState } from 'react';
import { GridList, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutItem, Text, useSetTheme } from '@pp-labs/ui-components';
import imageTest1 from '../../media/mainPage.png';
import logo from '../../media/Audi_Rings_bl-RGB.svg';
import { EventTile } from './EventTile';
import { useDispatch } from 'react-redux';
import { setEventSetting } from '../../AppSlice';
import { gutterStyle } from '../../utils/gutter';
import { publicClient } from '../../ApiHandler/publicClient';
import { PlatformEvent } from 'ApiHandler/dclxInterfaces';
import strings from '../../Localization/Localizer';
import AudiSpacer from '../../utils/AudiSpacer';

const useStyles = makeStyles((theme) => ({
  ...gutterStyle(theme),
  headlineWrapper: {
    textAlign: 'center',
  },
  mainArea: {
    width: '93%',
    margin: 'auto',
  },
  logo: {
    margin: '32px 0px',
    width: '7%',
    height: '7%',
  },
  mainPicture: {
    width: '100%',
    height: '90%',
  },
  description: {
    width: '650px',
    margin: 'auto',
  },
}));

/** Landing page, displays a list of all active events */
const MainPage = () => {
  const [eventData, setEventData] = useState<PlatformEvent[]>([]);
  const dispatch = useDispatch();
  const setTheme = useSetTheme();
  useEffect(() => {
    const getData = async () => {
      const res = await publicClient.get<PlatformEvent[]>('events');
      setEventData(
        res.data.filter((e) => e.hidden === false && e.eventEndTiming > Date.now() / 1000)
      );
      setTheme({ name: 'audi' });
    };
    getData();
    dispatch(setEventSetting(null));
    // eslint-disable-next-line
  }, [dispatch]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const large = useMediaQuery(theme.breakpoints.up('xl'));
  const cls = useStyles();

  return (
    <div>
      <LayoutItem spaceStackEnd={'xxxl'}>
        <div className={cls.mainArea}>
          <img className={cls.logo} src={logo} alt="AudiLogo" />
          <img className={cls.mainPicture} src={imageTest1} alt="Main-slider-img" />
        </div>
        <div className={cls.gutterAll}>
          <div className={cls.headlineWrapper}>
            <Text as="h1" variant="order1" weight="bold">
              {strings.mainPageTitle}
            </Text>
          </div>
          <AudiSpacer spaceStackEnd="xxxl" />
          <GridList cols={mobile ? 1 : large ? 4 : 2} cellHeight="auto" spacing={4}>
            {eventData.map((event) => {
              return (
                <div key={event.identifier}>
                  <EventTile event={event} />
                </div>
              );
            })}
          </GridList>
        </div>
      </LayoutItem>
    </div>
  );
};

export { MainPage };
