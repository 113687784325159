import { createStyles, Theme } from '@material-ui/core';

export const gutterStyle = (theme: Theme) =>
  createStyles({
    gutterSites: {
      [theme.breakpoints.down('sm')]: {
        padding: '0 34px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 56px',
      },
    },
    gutterAll: {
      [theme.breakpoints.down('sm')]: {
        padding: '34px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '56px',
      },
    },
    gutterDown: {
      [theme.breakpoints.down('sm')]: {
        padding: '34px',
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        padding: '56px',
        paddingTop: 0,
      },
    },
    gutterRoot: {
      [theme.breakpoints.down('sm')]: {
        padding: '34px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '80px 115px 80px 56px',
      },
    },
    stretch: {
      minHeight: '100vh',
      position: 'relative',
    },
    maxTwoLines: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    maxOneLine: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
    subNavWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  });

export interface GutterClass {
  gutterSites: string;
  gutterAll: string;
  gutterRoot: string;
  gutterDown: string;
  stretch: string;
  maxTwoLines: string;
  maxOneLine: string;
  subNavWrapper: string;
}
