import React, { useState } from 'react';
import { Text, useIsTheme, PlusIcon, RemoveIcon } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core';
import strings from 'Localization/Localizer';
import clsx from 'clsx';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
    cursor: 'pointer',
  },
  removeIcon: {
    background: '#000000',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  addIcon: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    padding: '10px',
  },
  blackText: {
    color: '#000000',
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block',
    marginRight: '8px !important',
  },
});

/** interface for SessionScheduleButtons props coming from parent component DCLXSessionTiles */
interface P {
  isInSchedule: boolean;
  removeFromSchedule: () => void;
  addToSchedule: () => void;
}

/** add/remove session from schedule */

const SessionScheduleButtons = (props: P) => {
  const [showTip, setShowTip] = useState<boolean>(false);
  const cls = useStyles();
  const theme = useIsTheme();

  return (
    <div className={cls.wrapper}>
      {props.isInSchedule ? (
        <div className={cls.removeIcon} onClick={() => props.removeFromSchedule()}>
          <RemoveIcon fontSize="medium" />
        </div>
      ) : (
        <div
          className={cls.addIcon}
          onClick={() => props.addToSchedule()}
          onMouseOver={() => setShowTip(true)}
          onMouseOut={() => setShowTip(false)}
        >
          <Text
            variant="copy2"
            className={clsx(theme.neutralDark && cls.blackText, showTip ? cls.show : cls.hide)}
          >
            {strings.addSchedule}
          </Text>

          <PlusIcon fontSize="medium" />
        </div>
      )}
    </div>
  );
};
export { SessionScheduleButtons };
