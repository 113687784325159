import React, { Component } from 'react';
// @ts-ignore
import image from '../../../media/locked.png';
import { createStyles, Theme, withStyles, withWidth } from '@material-ui/core';
import { BlackClasses, blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import { Text } from '@pp-labs/ui-components';
import strings from '../../../Localization/Localizer';
import { humanTime, noNullUser } from '../../../utils/convert';
import { Store } from '../../../store';
import { connect } from 'react-redux';
import { ChannelTopic, CognitoUser } from '../../../ApiHandler/dclxInterfaces';

const s = (theme: Theme) =>
  createStyles({
    ...blackStyle(theme),
    locked: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 'auto',
    },
    text: {
      display: 'flex',
    },
    container: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    textContainer: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0px 52px',
      minHeight: '335px',
      [theme.breakpoints.down('md')]: {
        minHeight: '235px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px 12px',
      },
    },
    countdownWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '350px',
    },
    dateBlock: {
      textAlign: 'center',
      marginRight: '36px',
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px',
      },
    },
    abs: {},
  });

interface Classes extends BlackClasses {
  locked: string;
  container: string;
  text: string;
  textContainer: string;
  flexContainer: string;
  countdownWrapper: string;
  abs: string;
  dateBlock: string;
}

/** interface for Locked props coming from parent component Topic */
interface P {
  classes: Classes;
  width: string;
  topic: ChannelTopic;
  user: CognitoUser;
}
/** interface for Locked component state  */
interface S {
  now: number;
  startDate: Date;
}

/**
 * Locked topic if it hasn't started yet
 */

class Locked extends Component<P, S> {
  interval: any;
  constructor(props: P) {
    super(props);
    this.state = {
      now: Date.now(),
      startDate: new Date(this.props.topic.startAt * 1000),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ now: Date.now() }), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const distance = this.state.startDate.getTime() - this.state.now;
    const t = humanTime(distance);
    const head = 'copy1';
    const number = 'display2';
    return (
      <div
        className={this.props.classes.container}
        style={{
          backgroundImage: `url(${this.props.topic.countdownImage?.url || image})`,
        }}
      >
        <div className={this.props.classes.text}>
          <div className={this.props.classes.textContainer}>
            <Text
              className={this.props.classes.blackText}
              as="p"
              variant={'order2'}
              weight={'bold'}
              spaceStackEnd={'l'}
            >
              {strings.availableIn}
            </Text>
            <div className={this.props.classes.countdownWrapper}>
              <div className={this.props.classes.flexContainer}>
                <div className={this.props.classes.dateBlock}>
                  <Text
                    as="span"
                    variant={number}
                    weight={'bold'}
                    className={this.props.classes.blackText}
                  >
                    {t[0]}
                  </Text>
                  <br />
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={head}
                    weight={'bold'}
                  >
                    {strings.days}
                  </Text>
                </div>
                <div className={this.props.classes.dateBlock}>
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={number}
                    weight={'bold'}
                  >
                    {t[1]}
                  </Text>
                  <br />
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={head}
                    weight={'bold'}
                  >
                    {strings.hours}
                  </Text>
                </div>
                <div className={this.props.classes.dateBlock}>
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={number}
                    weight={'bold'}
                  >
                    {t[2]}
                  </Text>
                  <br />
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={head}
                    weight={'bold'}
                  >
                    {strings.minutes}
                  </Text>
                </div>
                <div className={this.props.classes.dateBlock}>
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={number}
                    weight={'bold'}
                  >
                    {t[3]}
                  </Text>
                  <br />
                  <Text
                    className={this.props.classes.blackText}
                    as="span"
                    variant={head}
                    weight={'bold'}
                  >
                    {strings.seconds}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(s)(withWidth()(Locked)));
