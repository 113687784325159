import { QuestionSection } from './QuestionSection';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  AnsweredAma,
  AnsweringAma,
  AnyAma,
  BaseAma,
  Session,
  SessionType,
  UserPicture,
} from '../../../ApiHandler/dclxInterfaces';
import { client } from '../../../ApiHandler/client';
import { Select } from '@audi/audi-ui-react';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { QuestionProvider, useQuestionState } from './QuestionState';
import { AnswerDialog } from './AnswerDialog';
import { QuestionWebsocket } from './Websocket';
import { Role } from '../../../utils/RoleDefinitions';
import { useCMSChannel, useEventSettings } from '../../../utils/hooks';
import IVSLivePlayer from '../../../features/Sessions/Watch/VideoPlayer/IVSLivePlayer';
import { makeStyles } from '@material-ui/core';
import { Text } from '@audi/audi-ui-react';
import { getSessionSettings } from 'utils/convert';
import { getWebsocket } from 'ApiHandler/websocket';
import { helloCommand, subscribeCommand } from 'DCLX/FE/LOT/lotCommands';

/** Splits AMAs into their varios categories */
const splitQuestions = (questions: AnyAma[]) => {
  const dismissed = questions.filter((q) => q.dismissed) as BaseAma[];
  const remaining = questions.filter((q) => !q.dismissed);
  const answered = remaining.filter((q) => q.answer && q.answer !== 'pending') as AnsweredAma[];
  const live = remaining.filter((q) => q.answer === 'pending') as AnsweredAma[];
  const unanswered = remaining.filter((q) => !q.answer && q.answerMode !== 'live') as (
    | BaseAma
    | AnsweringAma
  )[];
  return {
    dismissed: dismissed,
    answered: answered,
    live: live,
    unanswered: unanswered,
  };
};

export const LiveAmaRoute = () => (
  <QuestionProvider>
    <LiveAma />
  </QuestionProvider>
);

const useStyles = makeStyles({
  container: {
    width: '30%',
  },
  box: {
    display: 'flex',
    backgroundColor: '#EEE',
    padding: 32,
    justifyContent: 'space-between',
    alignItems: 'end',
    position: 'relative',
    width: '100vw',
  },
});

/** Landing for AMA Module, allows to set all the filters etc. */
export const LiveAma = () => {
  const cls = useStyles();
  const { wsLast, setDirectors } = useQuestionState();
  // const [questions, setQuestions] = useState<AnyAma[]>([]);
  const [split, setSplit] = useState<ReturnType<typeof splitQuestions> | null>(null);

  const currentChannelId = useCMSChannel()!.channelId;
  const event = useEventSettings();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentSessionId, setCurrentSessionId] = useState<string>('');
  const [currentSession, setCurrentSession] = useState<Session | null>(null);
  const [allUsers, setAllUsers] = useState<UserPicture[]>([]);
  const [currentlyOnline, setCurrentlyOnline] = useState<any[]>();

  useEffect(() => {
    const load = async () => {
      const s = (await client.get<Session[]>(`sessions`)).data;
      const directors = (await client.get(`users/role/${Role.DIRECTOR}`)).data;
      const EventManager = (await client.get(`users/role/${Role.MARKET}`)).data;
      const SessionModerator = (await client.get(`users/role/${Role.TRAINER}`)).data;
      setSessions(s.filter((l) => l.sessionType === SessionType.LIVE));
      setDirectors([...directors, ...EventManager, ...SessionModerator]);
    };
    load();
  }, [setDirectors]);

  useEffect(() => {
    if (!wsLast) return;
    if (wsLast?.name === 'refreshAmaDir') loadAmas(currentSession);
  }, [wsLast, currentSession]);

  const hello = (online: Array<string>) => {
    setOnline(online);
  };
  const setOnline = (online: Array<string>) => {
    setCurrentlyOnline(online);
  };
  useEffect(() => {
    const changeOnline = async (add: boolean, online: string) => {
      if (currentlyOnline === undefined) {
        setTimeout(() => {
          changeOnline(add, online);
        }, 1000);
        return;
      }
      let newUsers: null | UserPicture[] = null;
      const n = currentlyOnline.slice();
      let change = false;
      if (add) {
        const inOnline = n.find((o) => o === online);
        newUsers = await fetchIfNotInList(online);

        if (!inOnline) {
          n.push(online);
          change = true;
        } else if (newUsers) {
          // it may be possible that a user is already online but not in the list
          change = true;
        }
      } else {
        const f = n.findIndex((o) => o === online);
        if (f !== -1) {
          n.splice(f, 1);
          change = true;
        }
      }
      if (!change) return;
      setCurrentlyOnline(n);
      newUsers && setAllUsers(newUsers);
    };

    const fetchIfNotInList = async (sub: string): Promise<null | UserPicture[]> => {
      const inUsers = allUsers.find((userEntry) => userEntry.username === sub);
      if (inUsers) return null;
      const userPictureData: UserPicture = (await client.get(`users/name/${sub}`)).data;
      const n = allUsers.slice();
      n.push(userPictureData);
      return n;
    };
    let ws: any;
    try {
      const fetch = async () => {
        const users = await client.get(`users/role/${Role.VISITOR}`);
        setAllUsers(users.data);
        ws = await getWebsocket();
        ws.onopen = () => {
          ws.send(subscribeCommand(`${event?.tenantId}-${currentSession?.sessionId}`));
          ws.send(helloCommand(`${event?.tenantId}-${currentSession?.sessionId}`));
        };
        ws.onmessage = (m: any) => {
          const parsed: any = JSON.parse(m.data);
          if ('CommandKey' in parsed) {
            if (parsed.CommandKey === 'hello') {
              changeOnline(true, parsed.CommandValue);
            }
          }
          if (parsed.Action === 'welcome' && 'Users' in parsed) {
            hello(parsed.Users);
          }
        };
      };
      currentSession && fetch();
    } catch (e) {
      console.log(e);
    }
    return () => {
      ws?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession]);

  const loadAmas = async (cs: Session | null) => {
    if (!cs) {
      setSplit(splitQuestions([]));
      return;
    }
    try {
      const q = await client.get(`sessions/${cs.sessionId}/liveAmas`);
      setSplit(splitQuestions(q.data));
    } catch {}
  };

  const switchSession = async (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    setCurrentSessionId(id);
    const session = id ? (await client.get<Session>(`sessions/${id}`)).data : null;
    setCurrentSession(session || null);
    await loadAmas(session || null);
  };
  const sessionSetting = currentSession && getSessionSettings(currentSession);

  return (
    <>
      {currentSession && <QuestionWebsocket sessionId={currentSession.sessionId} />}
      <div className={cls.box}>
        <div style={{ position: 'absolute', top: 32, left: 32 }}>
          <Text variant="order2" weight="bold">
            {strings.askMeAnything}
          </Text>
        </div>

        {currentChannelId && (
          <div className={cls.container}>
            <Select
              value={currentSessionId}
              onChange={switchSession}
              inputId="sessionSelect"
              label={strings.selectSession}
            >
              {sessions
                .filter((s) => s.channelId === currentChannelId)
                .map((s) => (
                  <option key={s.sessionId} value={s.sessionId.toString()}>
                    {getLocalizedValue(s.title)} ({s.sessionId})
                  </option>
                ))}
            </Select>
          </div>
        )}
        <div className={cls.container}>
          <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              {currentSession?.playbackUrls && (
                <IVSLivePlayer
                  url={currentSession.playbackUrls[0] || ''}
                  surveyEnabled={false}
                  extendVolume={true}
                  live={true}
                  viewCount={
                    sessionSetting?.hideParticipants
                      ? allUsers.filter((u) => currentlyOnline?.includes(u.username)).length
                      : undefined
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100vw',
          maxWidth: 2000,
          padding: 48,
        }}
      >
        {split && (
          <div>
            <QuestionSection type="unanswered" questions={split.unanswered} />
            <QuestionSection type="live" questions={split.live} />
            <QuestionSection type="answered" questions={split.answered} />
            <QuestionSection type="dismissed" questions={split.dismissed} />
          </div>
        )}

        <AnswerDialog />
      </div>
    </>
  );
};
