import React, { Component } from 'react';
import {
  createStyles,
  GridList,
  GridListTile,
  Theme,
  withStyles,
  withWidth,
} from '@material-ui/core';
import { LabelButton, LayoutItem, Text, useTheme } from '@pp-labs/ui-components';

import '../../../features/Sessions/Sessions.scss';
import styles, { SessionClasses } from '../../../features/Sessions/styles';
import { getDisplayDate, noNullUser, stripByWidth } from '../../../utils/convert';
import {
  Agenda,
  AgendaItem,
  CognitoUser,
  PlatformEvent,
  Session,
  SessionType,
  TimeSlot,
} from '../../../ApiHandler/dclxInterfaces';
import CalendarExport from '../../../utils/CalendarExport';
import { Store } from '../../../store';
import { connect } from 'react-redux';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Link, useLocation } from 'react-router-dom';
import { lotLobby } from '../../../config';
import { byDate } from 'utils/sort';
import clsx from 'clsx';
import AudiSpacer from 'utils/AudiSpacer';
import SessionsOverlay from './SessionsOverlay';
import { EditorParser, getSimpleText } from 'features/Editor/EditorParser';
import { AudiTooltip } from 'utils/AudiTooltip';
import { JoinEventButton } from './JoinEventButton';

interface Classes extends SessionClasses {
  whiteText: string;
  whiteBtn: string;
  neutralLightBtn: string;
  wrappedText: string;
  imgWrapper: string;
}

const s = (theme: Theme) =>
  createStyles({
    ...styles(theme),
    whiteText: {
      color: 'white !important',
    },
    wrappedText: {
      color: 'white !important',
      maxWidth: 419,
    },
    whiteBtn: {
      borderColor: 'white !important',
      color: 'white !important',
      alignSelf: 'flex-start',
    },
    neutralLightBtn: {
      alignSelf: 'flex-start',
    },
    imgWrapper: {
      minHeight: 100,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '38px',
        width: '90%',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
      },
    },
  });

/** interface for FeaturedEvent props coming from parent component Banner  */
interface P {
  classes: Classes;
  width: Breakpoint;
  event?: Session | AgendaItem;
  eventSettings?: PlatformEvent;
  user: CognitoUser;
  timeSlots: TimeSlot[];
  now: number;
  interactable?: boolean;
}

const ViewAgendaButton = (props: { classes: Classes; interactable?: boolean }) => {
  const location = useLocation();
  const isTheme = useTheme();
  return (
    <Link
      to={props.interactable ? location.pathname + `/agenda/0` : '#'}
      style={{ textDecoration: 'none' }}
    >
      <LabelButton
        spaceStackStart={'xl'}
        variant={'secondary'}
        className={
          isTheme.variant === 'light' ? props.classes.neutralLightBtn : props.classes.whiteBtn
        }
      >
        {'View Agenda'}
      </LabelButton>
    </Link>
  );
};

/**
 * Displays the currently featured event or training
 */

class FeaturedEvent extends Component<P> {
  // getRedirect = () => {
  //   if (!this.props.event?.hasOwnProperty('sessionId')) {
  //     return ``;
  //   } else if ((this.props.event as Session)?.sessionType === SessionType.LIVE)
  //     return `/events/${(this.props.event as Session)?.sessionId}`;
  //   const ts = this.props.timeSlots.find(
  //     (t) => t.sessionId === (this.props.event as Session)?.sessionId
  //   );
  //   if (!ts) return '/events';
  //   return `/lot/${ts.trainingId}`;
  // };

  buildTile(item?: Session | AgendaItem) {
    if (!item) {
      const columns = this.props.width === 'xs' || this.props.width === 'sm' ? 1 : 2;
      return [
        <GridListTile key={'event-stayTuned'} cols={columns}>
          <LayoutItem spaceStackStart={'xxs'} spaceStackEnd={'xxs'}>
            <div>
              <Text
                variant={'display1'}
                weight={'bold'}
                className={this.props.classes.whiteText}
                spaceStackEnd={'s'}
              >
                {strings.stayTuned}
              </Text>
              <Text
                variant={'order3'}
                weight={'bold'}
                className={this.props.classes.wrappedText}
                spaceStackEnd={'xl'}
              >
                {strings.currentlyNoEvents}
              </Text>
              <Text variant={'copy1'} className={this.props.classes.whiteText}>
                {strings.registerLOTCall}
              </Text>
            </div>
          </LayoutItem>
        </GridListTile>,
      ];
    }

    if (item.hasOwnProperty('sessionType')) {
      const event: Session = item as Session;
      if (event.sessionType === SessionType.TRAINING) {
        const timeslot = this.props.timeSlots.find((ts) => ts.sessionId === event.sessionId);
        if (!timeslot) return null;
        return [
          <GridListTile key={`${event.sessionId}-event-image`} cols={1}>
            <div className={clsx(this.props.classes.sessionWrapper, this.props.classes.imgWrapper)}>
              <img
                className={this.props.classes.thumb}
                src={event.thumbnail?.url || ''}
                alt="Thumbnail"
              />
              <SessionsOverlay timeSlot={timeslot} session={event} />
            </div>
          </GridListTile>,
          <GridListTile key={`${event.sessionId}-event-description`} cols={1}>
            <div
              className={this.props.classes.fontTile}
              style={{
                marginLeft: 14,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Text
                as="p"
                variant="copy2"
                className={this.props.classes.whiteText}
                weight={'bold'}
                spaceStackEnd={'xs'}
                spaceStackStart={'s'}
              >
                {strings.comingNext}
              </Text>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
                title={getLocalizedValue(event.title)}
              >
                <Text
                  as="h3"
                  variant="order2"
                  className={this.props.classes.whiteText}
                  weight={'bold'}
                  spaceStackEnd={'xs'}
                >
                  {getLocalizedValue(event.title)}
                </Text>
              </div>

              <Text as="p" variant="copy1" className={this.props.classes.whiteText} weight={'bold'}>
                {strings.booked}: {getDisplayDate(timeslot.startAt, true)}
              </Text>

              <AudiTooltip
                title={
                  <EditorParser
                    inputString={timeslot.description || getLocalizedValue(event.description)}
                  />
                }
                enterDelay={800}
                placement="top-start"
                arrow
              >
                <div id="lotDescFE" className={this.props.classes.whiteText}>
                  <EditorParser
                    inputString={stripByWidth(
                      timeslot.description || getLocalizedValue(event.description),
                      this.props.width
                    )}
                  />
                </div>
              </AudiTooltip>
              <CalendarExport
                textClasses={this.props.classes.whiteText}
                start={new Date(timeslot.startAt * 1000)}
                duration={(timeslot.endAt - timeslot.startAt) / 60}
                title={event.title}
                description={getSimpleText(event.description)}
                sessionTile={false}
              />
              {this.props.now > timeslot.startAt - lotLobby.preAccess &&
                this.props.now < timeslot.endAt && (
                  <Link
                    to={
                      this.props.interactable
                        ? `/${this.props?.eventSettings?.identifier}/lot/${timeslot.trainingId}`
                        : '#'
                    }
                    style={{ textDecoration: 'none' }}
                  >
                    <JoinEventButton />
                  </Link>
                )}
            </div>
          </GridListTile>,
        ];
      } else {
        if (!event.startAt) return null;
        const available = this.props.now > event.startAt - 15 * 60;
        return [
          <GridListTile key={`${event.sessionId}-event-image`} cols={1}>
            <div className={clsx(this.props.classes.sessionWrapper, this.props.classes.imgWrapper)}>
              <img
                className={this.props.classes.thumb}
                src={event.thumbnail?.url || ''}
                alt="Thumbnail"
              />
              <SessionsOverlay session={event} locked={!available} />
            </div>
          </GridListTile>,
          <GridListTile key={`${event.sessionId}-event-description`} cols={1}>
            <div
              className={this.props.classes.fontTile}
              style={{
                marginLeft: 12,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Text
                as="h3"
                variant={'order2'}
                className={this.props.classes.whiteText}
                spaceStackEnd={'xxs'}
              >
                {strings.comingNext}:{/*{event.category && '/ ' + event.category}*/}
              </Text>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
                title={getLocalizedValue(event.title)}
              >
                <Text
                  as="h3"
                  variant="order2"
                  weight="bold"
                  className={this.props.classes.whiteText}
                  spaceStackEnd={'xs'}
                >
                  {getLocalizedValue(event.title)}
                </Text>
              </div>
              <AudiSpacer spaceStackStart="s" />
              <Text as="p" variant="copy1" className={this.props.classes.whiteText} weight={'bold'}>
                {getDisplayDate(event.startAt, true)}
                {' - '}
                {event?.endAt && getDisplayDate(event?.endAt, true, true)}
              </Text>
              <AudiTooltip
                title={<EditorParser inputString={getLocalizedValue(event.description)} />}
                enterDelay={800}
                placement="top-start"
                arrow
              >
                <div>
                  <Text as="p" variant="copy1" className={this.props.classes.whiteText}>
                    {stripByWidth(
                      getSimpleText(getLocalizedValue(event.description)),
                      this.props.width
                    )}
                  </Text>
                </div>
              </AudiTooltip>

              <CalendarExport
                textClasses={this.props.classes.whiteText}
                start={new Date(event.startAt * 1000)}
                end={event.endAt ? new Date(event.endAt * 1000) : undefined}
                title={event.title}
                description={getSimpleText(event.description)}
                sessionTile={false}
              />

              {available && (
                <Link
                  to={{
                    pathname: this.props.interactable
                      ? `/${this.props?.eventSettings?.identifier}/${
                          event.sessionType === SessionType.LIVE ? 'live' : 'onDemand'
                        }/${event.sessionId}`
                      : '#',
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <JoinEventButton />
                </Link>
              )}
            </div>
          </GridListTile>,
        ];
      }
    } else if (item.hasOwnProperty('location')) {
      const event: AgendaItem = item as AgendaItem;
      return [
        <GridListTile key={`${event.location}-event-description`} cols={1}>
          <div
            className={this.props.classes.fontTile}
            style={{
              marginLeft: 14,
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text
              as="p"
              variant="copy2"
              className={this.props.classes.whiteText}
              weight={'bold'}
              spaceStackEnd={'xs'}
              spaceStackStart={'s'}
            >
              {strings.comingNext}
            </Text>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
              title={getLocalizedValue(event.title)}
            >
              <Text
                as="h3"
                variant="order2"
                className={this.props.classes.whiteText}
                weight={'bold'}
                spaceStackEnd={'xs'}
              >
                {getLocalizedValue(event.title)}
              </Text>
            </div>

            <Text as="p" variant="copy1" className={this.props.classes.whiteText} weight={'bold'}>
              {getDisplayDate(event.time, true, false, true)} | {event.location}
            </Text>
            <Text as="p" variant="copy1" className={this.props.classes.whiteText}>
              {stripByWidth(
                event.description || getLocalizedValue(event.description),
                this.props.width
              )}
            </Text>

            <ViewAgendaButton classes={this.props.classes} interactable={this.props.interactable} />
          </div>
        </GridListTile>,
      ];
    }
  }

  render() {
    const columns = this.props.width === 'xs' ? 1 : 2;
    return (
      <GridList cols={columns} cellHeight="auto" spacing={0}>
        {this.buildTile(this.props.event)}
      </GridList>
    );
  }
}
export const getFeaturedEvent = (
  now: number,
  events?: Session[],
  rawTimeSlots?: TimeSlot[],
  trainings?: Session[],
  agendas?: Agenda[]
) => {
  let featuredEvent;
  //determine latest event from all sessions
  let allEvents: Session[] = [];
  if (events) {
    allEvents = events.filter((e) => {
      // Feature Event for 15 minutes
      return e.startAt && now < e.startAt + 15 * 60;
    });
  }
  let relevantTimeSlots: TimeSlot[] = [];
  if (trainings && rawTimeSlots) {
    relevantTimeSlots = rawTimeSlots.filter((ts) => {
      if (!trainings?.find((t) => t.sessionId === ts.sessionId)) return false;
      return now < ts.endAt;
    });
  }
  relevantTimeSlots.sort(byDate);
  const agendaItems: AgendaItem[] = [];
  agendas?.forEach((agenda: Agenda) => {
    agendaItems.push(
      ...agenda.agendaItems.filter((ai) => {
        return now * 1000 < ai.time + 1 * 60 * 1000;
      })
    );
  });
  agendaItems?.sort((a, b) => a.time - b.time);
  allEvents.sort(byDate);

  if (
    relevantTimeSlots[0] &&
    (!allEvents[0] || relevantTimeSlots[0].startAt <= (allEvents[0].startAt || 0))
  ) {
    featuredEvent = trainings?.find((t) => t.sessionId === relevantTimeSlots[0].sessionId);
  } else if (
    (allEvents[0] || agendaItems[0]) &&
    (!relevantTimeSlots[0] ||
      (allEvents[0].startAt || 0) <= relevantTimeSlots[0].startAt ||
      (agendaItems[0].time / 1000 || 0) <= relevantTimeSlots[0].startAt)
  ) {
    if (!allEvents[0] || !allEvents[0].startAt) {
      featuredEvent = agendaItems[0];
    } else if (!agendaItems[0]) {
      featuredEvent = allEvents[0];
    } else {
      featuredEvent =
        allEvents[0].startAt < agendaItems[0].time / 1000 ? allEvents[0] : agendaItems[0];
    }
  } else {
    featuredEvent = undefined;
  }
  return featuredEvent;
};

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(withWidth()(FeaturedEvent)));
