import React from 'react';
import { SeamlessVideoPlayer } from '../SeamlessVideoPlayer';
import { Medium } from './WaitingOverlay';
import { makeStyles } from '@material-ui/styles';
import { controlBarHeight } from '../config';
import { useLotState } from '../Provider/LotProvider';
import { isBigLOT } from 'utils/convert';

/** interface for MediaOverlay props coming from parent components MediaOverlay  */
interface P {
  medium: Medium;
  height: number;
}

const useStyles = makeStyles({
  notStaredImg: (props: { height: number; withoutControlBar: boolean }) => ({
    width: '100%',
    height: props.height - (props.withoutControlBar ? controlBarHeight : 0),
  }),
});

/** Render waiting media */
const MediaOverlay = (props: P) => {
  const lotState = useLotState();
  const cls = useStyles({
    height: props.height,
    withoutControlBar:
      props.medium.type === 'image' &&
      !!lotState.general.training &&
      isBigLOT(lotState.general.training),
  });
  if (props.medium.type === 'video') {
    return <SeamlessVideoPlayer url={props.medium.url} loop muteControl />;
  } else {
    return <img src={props.medium.url} alt="lotLobbyImage" className={cls.notStaredImg} />;
  }
};

export { MediaOverlay };
