import React, { useState } from 'react';
import { UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import profileImage from '../../../media/fallbackProfile.png';
import { UserInfo } from './UserInfo';
import { OnlineIcon } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import clsx from 'clsx';
import { TickIcon } from '../../../icons';
import { useCustomFields } from '../../../utils/hooks';

const useStyles = makeStyles({
  handIndicator: {
    position: 'absolute',
    borderRadius: '50%',
    left: -3,
    top: -3,
    height: '54px',
    width: '54px',
    border: '2px solid #F50537',
  },
  handQueueID: {
    fontFamily: 'AudiType, sans-serif',
    fontWeight: 'bold',
    width: '14px',
    height: '20px',
    right: '-5px',
    top: '-4px',
    fontSize: '10px',
    lineHeight: '20px',
    textAlign: 'center',
    background: '#F2F2F2',
    color: '#F50537',
    position: 'absolute',
    transform: 'rotate(50deg)',
  },
  handQueueIDInner: {
    transform: 'rotate(-50deg)',
    marginTop: '-2px',
  },
  container: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: '56px',
  },
  nameContainer: {
    padding: '0 15px',
    height: '40px',
  },
  imgContainer: {
    display: 'inline-block',
  },
  online: {
    position: 'absolute',
    left: 36,
    bottom: -8,
    zIndex: 6,
  },
  outerWrapperClick: {
    cursor: 'pointer',
  },
});

/** interface for DCLXUserTile props coming from parent components Live, BreakoutRoom, HandQueue, EventWatch  */
interface P {
  user: UserPicture;
  appendName?: string;
  down?: boolean;
  hand?: boolean;
  handQueueId?: number;
  online?: boolean;
  showUserInfo?: boolean;
  selected?: boolean;
  setSelected?: (username: string) => void;
  /** The requirements said that the hover should be removed on all occasions.
   * However: Why should a user then upload the Header image and the about me text???
   * There is no other way for other users to see those information.
   *
   * This is just a bad requirement and I am quite sure it will be back in sooner or later,
   * which is why I leave it in and just pass noHover every time.
   */
  noHover?: boolean;
}

/**
 * A user Tile, displaying info about the use, showing online status, hand raises and co.
 */

const DCLXUserTile = (props: P) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const cls = useStyles();

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(props.setSelected && cls.outerWrapperClick)}
      onClick={() => props.setSelected?.(props.user.username)}
    >
      <div className={cls.container} style={{ paddingRight: !props.down ? 56 : 0 }}>
        <div className={cls.imgContainer}>
          {props.selected ? <Selected /> : <Thumb thumb={props.user.thumb} />}
          {props.hand && (
            <div className={cls.handIndicator}>
              {props.handQueueId && (
                <div className={cls.handQueueID}>
                  <div className={cls.handQueueIDInner}>{props.handQueueId}</div>
                </div>
              )}
            </div>
          )}
          {props.online !== undefined && (
            <div className={cls.online}>
              <OnlineIcon online={props.online} />
            </div>
          )}
        </div>
        <div className={cls.nameContainer}>
          {props.showUserInfo && <Header user={props.user} appendName={props.appendName} />}
        </div>
        {isHovered && !props.noHover && <UserInfo user={props.user} />}
        <br />
      </div>
    </div>
  );
};

interface ThumbProps {
  thumb: string;
}

const Thumb = (props: ThumbProps) => (
  <div
    style={{
      backgroundImage: `url(${props.thumb || profileImage})`,
      borderRadius: '50%',
      height: '48px',
      width: '48px',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'relative',
      zIndex: 5,
    }}
  />
);

const Selected = () => (
  <div
    style={{
      borderRadius: '50%',
      height: '48px',
      width: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
      fontSize: 30,
      color: 'white',
      backgroundColor: 'black',
    }}
  >
    <TickIcon />
  </div>
);

const useHeaderStyles = makeStyles({
  headerText: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'max-width': '178px',
  },
});

interface HeaderProps {
  user: UserPicture;
  appendName?: string;
}

const Header = (props: HeaderProps) => {
  const cls = useHeaderStyles();
  const customFields = useCustomFields(props.user);
  return (
    <>
      <Text as="p" variant="copy2" className={cls.headerText}>
        <b>
          {props.user.givenName || strings.unknown} {props.user.familyName || strings.name}
          {props.appendName ? ` ${props.appendName}` : ''}
        </b>
      </Text>
      <Text as="p" variant="copy2" className={cls.headerText}>
        {customFields['custom:jobFunction']}
      </Text>
    </>
  );
};

export default DCLXUserTile;
