import { createContext, FC, useContext, useState } from 'react';
import { SectionType } from './QuestionSection';
import { AnyAma, UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { CustomCommandValue } from '../../FE/LOT/WebsocketCommands';

interface Filter {
  type: SectionType;
  filter: string;
}

type SetFilter = (type: SectionType, filter: string) => void;
type SetAnswering = (ama: AnyAma | null) => void;
type WSMsg = CustomCommandValue;
interface QuestionState {
  filters: Filter[];
  setFilter: SetFilter;
  answering: AnyAma | null;
  setAnswering: SetAnswering;
  wsLast: WSMsg | null;
  wsNext: WSMsg | null;
  wsSend: (next: WSMsg) => void;
  wsSetLast: (next: WSMsg) => void;
  directors: UserPicture[];
  setDirectors: (dir: UserPicture[]) => void;
}

export const questionContext = createContext<QuestionState>({
  filters: [],
  setFilter: (_a, _b) => {},
  answering: null,
  setAnswering: (_a) => {},
  wsLast: null,
  wsNext: null,
  wsSend: (_a) => {},
  wsSetLast: (_a) => {},
  directors: [],
  setDirectors: (_a) => {},
});

/** Handles a shared state for the AMA modules */
export const QuestionProvider: FC = (props) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [answering, setAnswering] = useState<AnyAma | null>(null);
  const [lastMsg, setLastMsg] = useState<WSMsg | null>(null);
  const [nextMsg, setNextMsg] = useState<WSMsg | null>(null);
  const [directors, setDirectors] = useState<UserPicture[]>([]);
  const setFilter: SetFilter = (type, filter) => {
    const filterIndex = filters.findIndex((f) => f.type === type);
    const arr = filters.slice();
    const n: Filter = { type: type, filter: filter };
    if (filterIndex > -1) {
      arr[filterIndex] = n;
    } else {
      arr.push(n);
    }
    setFilters(arr);
  };
  const setLast = (msg: WSMsg | null) => {
    setLastMsg(msg);
  };
  return (
    <questionContext.Provider
      value={{
        setFilter: setFilter,
        filters: filters,
        setAnswering: setAnswering,
        answering: answering,
        wsLast: lastMsg,
        wsNext: nextMsg,
        wsSend: setNextMsg,
        wsSetLast: setLast,
        directors: directors,
        setDirectors: setDirectors,
      }}
    >
      {props.children}
    </questionContext.Provider>
  );
};
export const useQuestionState = () => useContext(questionContext);
