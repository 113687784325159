import strings from '../../../Localization/Localizer';
import { atLeastOneLanguage } from '../../../utils/validator';
import React, { useMemo } from 'react';
import { AdminPanelSteps } from './adminPanelTypes';
import AudiSpacer from '../../../utils/AudiSpacer';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { LabelButton, LocalizedForm, TextField } from '@pp-labs/ui-components';
import StepIndicator from '../../../utils/StepIndicator';
import { Language } from '../../../ApiHandler/dclxInterfaces';

const validation = Yup.object({
  registerVideoIdentifier: atLeastOneLanguage(),
}).required(strings.required);

interface FormValues {
  registerVideoIdentifier: string;
}

/**
 * Allows the admin to enter the registerVideoIdentifiers.
 */
export const ModuleDetails = (props: AdminPanelSteps) => {
  const initialValues: FormValues = useMemo(() => {
    return {
      registerVideoIdentifier: props.initialData?.registerVideoIdentifier
        ? JSON.parse(props.initialData.registerVideoIdentifier)
        : '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const langs = useMemo(() => {
    const langsValue: Language[] = JSON.parse(props.values.languages).languages;
    return langsValue.map((l) => l.name);
  }, [props.values.languages]);

  const getErrors = (touched: any, errors: any) => {
    return {
      registerVideoIdentifier: touched.registerVideoIdentifier
        ? errors.registerVideoIdentifier
        : '',
    };
  };

  const submitToServer = (v: FormValues) => {
    props.setValues({
      ...props.values,
      registerVideoIdentifier: JSON.stringify(v.registerVideoIdentifier),
    });
    props.setStep(3);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={submitToServer}>
      {({ errors, touched }) => {
        const et = getErrors(touched, errors);
        return (
          <LocalizedForm
            languages={langs}
            localizeInputs={['registerVideoIdentifier']}
            initialValues={{
              registerVideoIdentifier: props.initialData?.registerVideoIdentifier
                ? JSON.parse(props.initialData.registerVideoIdentifier)
                : '',
            }}
            autoParse={[]}
          >
            <StepIndicator step={2} numSteps={4} />
            <TextField
              name="registerVideoIdentifier"
              label={'Vimeo ID'}
              error={et.registerVideoIdentifier}
            />
            <AudiSpacer spaceStackEnd={'s'} />
            <LabelButton variant="primary" type="submit" id="next">
              {strings.next}
            </LabelButton>
          </LocalizedForm>
        );
      }}
    </Formik>
  );
};
