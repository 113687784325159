import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LabelButton, Text } from '@pp-labs/ui-components';
import { BlackClasses, blackStyle } from '../blackStyle';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Store } from '../../../../store';
import { triggerNotification } from '../../../../utils/NotificationSlice';
import strings from '../../../../Localization/Localizer';
import { AMA, CognitoUser, PlatformEvent } from '../../../../ApiHandler/dclxInterfaces';
import Overview from './Overview';
import { noNullUser } from '../../../../utils/convert';
import { TextArea } from '@pp-labs/ui-components';
import { client } from '../../../../ApiHandler/client';
import { byVotes } from '../../../../utils/sort';
import AudiSpacer from '../../../../utils/AudiSpacer';
import { LayoutItem } from '../../../../utils/Theming/LayoutItem';

const validation = Yup.object({
  question: Yup.string().test(
    'blank-or-5-characters-check',
    strings.question5chars,
    (question) => !question || (question.length >= 5 && question.trim() !== '')
  ),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

/** interface for AskMeAnything props coming from parent component Watch */
interface P {
  classes: BlackClasses;
  sessionId: number;
  user: CognitoUser;
  triggerNotification: Function;
  event: PlatformEvent;
}

/** interface for AskMeAnything component state  */
interface S {
  questions: Array<AMA>;
}

/**
 * Ask Me Anything Form for CLX-Like Amas
 */

class AskMeAnything extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      questions: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    let d: Array<AMA> = [];
    try {
      d = (await client.get(`sessions/${this.props.sessionId}/amas`)).data || [];
    } finally {
      this.setState({ questions: d.sort(byVotes) });
    }
  };

  submit = async (values: F) => {
    try {
      await client.post(`sessions/${this.props.sessionId}/amas`, values);
      this.props.triggerNotification([strings.amaThanks, 'success']);
      await this.fetch();
    } catch {
      this.props.triggerNotification([strings.unauthorized, 'error']);
    }
  };

  render() {
    const initialValues: F = {
      question: '',
    };
    return (
      <div>
        <LayoutItem spaceStackStart={'xs'}>
          <Text as="h3" variant="order3" weight={'bold'}>
            {`${strings.anyQuestions}`}
          </Text>
          <AudiSpacer neutralSpaceStackStart={'xl'} />
          <Formik
            enableReinitialize={true}
            validationSchema={validation}
            initialValues={initialValues}
            onSubmit={(values: F, { resetForm }) => {
              this.submit(values);
              resetForm();
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <LayoutItem spaceStackEnd={'xxxl'} neutralSpaceStackEnd={'xl'}>
                  <TextArea
                    rows={2}
                    name={'question'}
                    label={
                      this.props.event.identifier === 'adtf'
                        ? strings.typeQuestion
                        : strings.typeQuestionAlternative
                    }
                    error={values.question ? errors.question : ''}
                  />
                  <AudiSpacer neutralSpaceStackStart={'s'} />
                  <LabelButton
                    type="submit"
                    className={this.props.classes.button}
                    variant="primary"
                    disabled={!values.question}
                    spaceStackStart={'s'}
                  >
                    {strings.send}
                  </LabelButton>
                </LayoutItem>
              </Form>
            )}
          </Formik>
        </LayoutItem>
        <div>
          <Text as="h3" variant="order3" spaceStackEnd={'xl'}>
            {strings.viewAndVote}
          </Text>
          <AudiSpacer neutralSpaceStackStart={'l'} />
          <Overview questions={this.state.questions} refresh={this.fetch} />
        </div>
        <AudiSpacer spaceStackStart={'xxxl'} />
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
  event: state.app.eventSetting!,
});

const mapDispatchToProps = { triggerNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(blackStyle)(AskMeAnything));
