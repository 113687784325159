import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import strings, { getLocalizedValue, useLocalizedLanguages } from '../../../Localization/Localizer';
import { useMemo } from 'react';
import { EditDialog, LabelButton, LocalizedForm, Select, TextField } from '@pp-labs/ui-components';
import {
  allStepTypes,
  Channel,
  GetFirstStep,
  PostFirstStep,
  SessionType,
  StepType,
} from '../../../ApiHandler/dclxInterfaces';
import { Info } from '../../../progression';
import { client } from '../../../ApiHandler/client';
import { maxCharacterAndOneLang } from '../../../utils/validator';
import clsx from 'clsx';

const useStyles = makeStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
});

const getValidation = () =>
  Yup.object({
    title: maxCharacterAndOneLang(30),
    buttonText: maxCharacterAndOneLang(15),
    type: Yup.string().required(strings.required),
    sessionId: Yup.string(),
    surveyId: Yup.string(),
    examId: Yup.string(),
    url: Yup.string(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

/** interface for MediaField props coming from parent component EditFirstStepItem */
interface P {
  item: GetFirstStep | null;
  position: number;
  close: (refresh: boolean) => void;
  info: Info;
  channel: Channel;
}

const getStepLabel = (step: StepType) => {
  switch (step) {
    case 'session':
      return 'Session';
    case 'survey':
      return 'Survey';
    case 'exam':
      return 'Exam';
    case 'profile':
      return 'Profile';
    case 'url':
      return 'External URL';
  }
};

/** Select a medium related to the step */
export const MediaField = (props: {
  type: StepType;
  info: Info;
  setField: (name: 'mediaId', value: string) => void;
}) => {
  const cls = useStyles();
  return (
    <div>
      <div className={clsx(props.type !== 'session' && cls.hidden)}>
        <Select name="sessionId" label="Sessions">
          {props.info.sessions
            .filter(
              (s) =>
                s.sessionType === SessionType.DEMAND ||
                s.sessionType === SessionType.LIVE ||
                s.sessionType === SessionType.TRAINING
            )
            .map((s) => (
              <option key={s.sessionId} value={s.sessionId}>
                {getLocalizedValue(s.title)}
              </option>
            ))}
        </Select>
      </div>
      <div className={clsx(props.type !== 'exam' && cls.hidden)}>
        <Select name="examId" label="Exams">
          {props.info.exams.map((s) => (
            <option key={s.examId} value={s.examId}>
              {getLocalizedValue(s.examName)}
            </option>
          ))}
        </Select>
      </div>
      <div className={clsx(props.type !== 'survey' && cls.hidden)}>
        <Select name="surveyId" label="Surveys">
          {props.info.surveys?.map((s) => (
            <option key={s.surveyTemplateId} value={s.surveyTemplateId}>
              {getLocalizedValue(s.title)}
            </option>
          ))}
        </Select>
      </div>
      <div className={clsx(props.type !== 'url' && cls.hidden)}>
        <TextField name="url" label={'URL'} />
      </div>
    </div>
  );
};

const getMediaId = (values: F) => {
  const type = values.type as StepType;
  switch (type) {
    case 'session':
      return values.sessionId;
    case 'exam':
      return values.examId;
    case 'survey':
      return values.surveyId;
    case 'profile':
      return 'profile';
    case 'url':
      return values.url;
  }
  return '';
};

/** Add/Edit a First Step Item */
export const EditFirstStepItem = (props: P) => {
  const cls = useStyles();
  const languages = useLocalizedLanguages([props.channel]);
  const validationSchema = useMemo(getValidation, []);
  const initialValues = useMemo(() => {
    const type = props.item?.stepType || '';
    const mediaId = props.item?.mediaId || '';
    const base: F = {
      title: props.item?.title ? JSON.parse(props.item.title) : '',
      buttonText: props.item?.buttonText ? JSON.parse(props.item.buttonText) : '',
      type: type,
      sessionId: undefined,
      surveyId: undefined,
      examId: undefined,
      url: undefined,
    };
    if (type === 'session') base.sessionId = mediaId;
    if (type === 'survey') base.surveyId = mediaId;
    if (type === 'exam') base.examId = mediaId;
    if (type === 'url') base.url = mediaId;
    return base;
  }, [props.item]);

  const submit = async (values: F) => {
    const mediaId = getMediaId(values);
    if (!mediaId) return;
    const data: PostFirstStep = {
      position: props.item?.position || props.position,
      stepType: values.type as StepType,
      mediaId: mediaId,
      title: JSON.stringify(values.title),
      buttonText: JSON.stringify(values.buttonText),
      channelId: props.channel.channelId,
    };
    if (props.item) {
      await client.put(`firstSteps/${props.item.firstStepId}`, data);
    } else {
      await client.post('firstSteps', data);
    }

    props.close(true);
  };
  return (
    <EditDialog title="" close={() => props.close(false)} cms>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ touched, errors, values, setFieldValue }) => {
          const et = {
            title: touched.title ? errors.title : '',
            buttonText: touched.buttonText ? errors.buttonText : '',
            type: touched.type ? errors.type : '',
          };
          return (
            <LocalizedForm
              languages={languages}
              localizeInputs={['title', 'buttonText']}
              initialValues={initialValues}
              autoParse={[]}
            >
              <Select name="type" label="Type" error={et.type}>
                {allStepTypes.map((s) => (
                  <option key={s} value={s}>
                    {getStepLabel(s)}
                  </option>
                ))}
              </Select>
              <TextField name="title" label={'Title - (max. 30 characters)'} error={et.title} />
              <TextField name="buttonText" label={strings.ctaText} error={et.buttonText} />
              <MediaField
                type={values.type as StepType}
                info={props.info}
                setField={setFieldValue}
              />
              <div className={cls.buttonsWrapper}>
                <LabelButton variant="primary" type="submit">
                  {strings.save}
                </LabelButton>
              </div>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};
