import React from 'react';
import { Card, CardActions, IconButton, makeStyles, Theme } from '@material-ui/core';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Text } from '@pp-labs/ui-components';
import { CancelIcon } from '@pp-labs/ui-components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      // minWidth: '350px !important',
      maxWidth: '40vh !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  card: {
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  actionRoot: {
    padding: '8px 8px 8px 16px',
    position: 'relative',
  },
  icons: {
    marginRight: '8px',
    marginTop: '8px',
    right: 0,
    top: 0,
    position: 'absolute',
  },
  btn: {
    padding: 0,
    color: 'white !important',
  },

  text: {
    color: 'white !important',
  },

  textContainer: {
    marginRight: '40px',
  },
}));

/** interface for SnackMessage props coming from parent component snackbar */
interface P {
  id: SnackbarKey;
  message: any;
}

// eslint-disable-next-line react/display-name
const SnackMessage = React.forwardRef((props: P, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = () => {
    closeSnackbar(props.id);
  };

  const admin = props.message.substr(0, 5) === 'admin';
  let bgColor;
  if (admin) {
    bgColor = '#F50537';
  } else {
    bgColor = '#666666';
  }
  return (
    // @ts-ignore
    <div ref={ref} className={classes.root}>
      <Card className={classes.card} style={{ backgroundColor: bgColor }}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <div className={classes.textContainer}>
            <Text as="p" variant="copy1" className={classes.text}>
              {admin ? props.message.substr(5) : props.message}
            </Text>
          </div>
          <div className={classes.icons}>
            <IconButton className={classes.btn} onClick={handleDismiss}>
              <CancelIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </div>
  );
});

export default SnackMessage;
