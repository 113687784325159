import React, { useMemo } from 'react';
import strings, { getLocalizedLanguages } from '../../../Localization/Localizer';
import { Formik } from 'formik';
import { Channel, PostSupport, Support } from '../../../ApiHandler/dclxInterfaces';
import { LabelButton, TextArea, LocalizedForm, Text } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { SelectMediaButton } from '../Documents/SelectMediaButton';
import { MediaFile } from '../Documents/MediaLibrary';
import { useNotify } from '../../../utils/hooks';
import { LayoutItem, Toggle } from '@audi/audi-ui-react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  toggleWrapper: {
    display: 'flex',
    marginTop: '22px',
    alignItems: 'center',
  },
});

interface F {
  explainerText: string;
  video: MediaFile | null;
  enableSupport: boolean;
  enableTechTest: boolean;
}

type P = {
  thisSupport: Support | null;
  close: (refresh?: boolean) => void;
  channel: Channel;
};
/**
 * Form for editing Explainer Video/Text
 */

export const SupportEdit = (props: P) => {
  const notify = useNotify();
  const style = useStyles();
  const submit = async (v: F) => {
    const data: PostSupport = {
      explainerText: JSON.stringify(v.explainerText),
      channels: [props.channel.channelId],
      videoId: v.video?.mediaId,
      enableSupport: v.enableSupport,
      enableTechTest: v.enableTechTest,
    };

    await (props.thisSupport
      ? client.put(`faq/explainer/${props.thisSupport.expId}`, data)
      : client.post('faq/explainer', data));

    notify(strings.uploadedExplainerSection, 'success');
    props.close(true);
  };

  const initialValues = useMemo(() => {
    return {
      explainerText: props.thisSupport ? JSON.parse(props.thisSupport.explainerText) : [],
      video: props.thisSupport?.video || null,
      enableSupport: props.thisSupport?.enableSupport || false,
      enableTechTest: props.thisSupport?.enableTechTest || false,
    };
  }, [props.thisSupport]);

  const getErrors = (touched: any, errors: any) => {
    return {
      explainerText: touched.explainerText ? errors.explainerText : '',
      video: touched.video ? errors.video : '',
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ errors, touched, values, setFieldValue }) => {
        const et = getErrors(touched, errors);
        return (
          <LocalizedForm
            languages={getLocalizedLanguages([props.channel])}
            localizeInputs={['explainerText']}
            initialValues={initialValues}
            autoParse={[]}
          >
            <LayoutItem spaceStackStart="l">
              <TextArea name="explainerText" label={strings.description} error={et.explainerText} />
            </LayoutItem>
            <div className={style.toggleWrapper}>
              <Toggle
                inputId={'enableSupport'}
                on={values.enableSupport}
                onChange={() => setFieldValue('enableSupport', !values.enableSupport)}
              />
              <Text spaceInlineStart="m">{strings.enableSupport}</Text>
            </div>
            <div className={style.toggleWrapper}>
              <Toggle
                inputId={'enableTechTest'}
                on={values.enableTechTest}
                onChange={() => setFieldValue('enableTechTest', !values.enableTechTest)}
              />
              <Text spaceInlineStart="m">{strings.enableTechTest}</Text>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'end',
                marginTop: 16,
              }}
            >
              <SelectMediaButton
                type="video"
                variant="light"
                name="video"
                label={strings.explainerVideo}
                prevUrl={values.video === null ? undefined : values.video?.url}
                prevName={
                  values.video === null
                    ? undefined
                    : values.video?.originalFile || strings.explainerVideo
                }
                optional
              />
              <div>
                <LabelButton variant="primary" type="submit">
                  {strings.save}
                </LabelButton>
              </div>
            </div>
          </LocalizedForm>
        );
      }}
    </Formik>
  );
};
