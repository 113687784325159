import { makeStyles } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';

import React from 'react';
import { Awards } from '../../../ApiHandler/dclxInterfaces';
import strings from '../../../Localization/Localizer';
import { AudiTooltip } from '../../../utils/AudiTooltip';
import { getTime } from '../../../utils/convert';

type P = {
  awards: Awards;
};

const useStyles = makeStyles(() => ({
  score: {
    display: 'inline-block',
    paddingRight: '32px',
  },
}));

/** My personal score board */
export const ScoreBoard = (props: P) => {
  const cls = useStyles();
  return (
    <div style={{ marginBottom: 60 }}>
      <div style={{ padding: '10px 0px' }} />
      <div className={cls.score}>
        <Text as={'span'} variant={'display1'} weight={'bold'}>
          {props.awards.myRank || 0}
        </Text>
        <Text as={'span'} variant={'copy1'} weight={'bold'}>
          {`/ ${props.awards.totalUsers || 0}`}
        </Text>
        <Text as="p" variant="copy2" weight={'bold'}>
          {strings.myRank}
        </Text>
      </div>
      <AudiTooltip
        title={
          <Text as="p" variant="copy1">
            {strings.explainerScore}
          </Text>
        }
        arrow
      >
        <div className={cls.score}>
          <Text as="p" variant="order1">
            {props.awards.score}
          </Text>
          <Text as="p" variant="copy2" weight={'bold'}>
            {strings.score}
          </Text>
        </div>
      </AudiTooltip>
      <AudiTooltip
        title={
          <Text as="p" variant="copy1">
            {strings.explainerResponseTime}
          </Text>
        }
        arrow
      >
        <div className={cls.score}>
          <Text as="p" variant="order1">
            {getTime(props.awards.usedTime)}
          </Text>
          <Text as="p" variant="copy2" weight={'bold'}>
            {strings.responseTime}
          </Text>
        </div>
      </AudiTooltip>
    </div>
  );
};
