import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CancelIcon, EditDialog, LabelButton, useIsTheme, Text } from '@pp-labs/ui-components';
import { blackStyle } from '../../../features/Sessions/Watch/blackStyle';
import strings from '../../../Localization/Localizer';
import clsx from 'clsx';
import { useLotState } from './Provider/LotProvider';
import { useUser } from '../../../utils/hooks';
import AudiSpacer from 'utils/AudiSpacer';
/** interface for LeaveTraining props coming from parent component LotTopBar */
interface P {
  leaveTraining: () => void;
  mobile: boolean;
}

const useStyles = makeStyles((theme) => ({
  ...blackStyle(theme),
  iconWrapper: {
    background: '#F50537',
    color: '#FFFFFF',
    margin: '10px',
    display: 'flex',
    width: '41px',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: '0 4px',
  },
}));

const getButtonText = (breakout: boolean, trainer: boolean) => {
  if (breakout) return strings.breakoutBackToMain;
  return trainer ? strings.stopTraining : strings.lotleave;
};

/** Button to leave the training / breakout room */
export const LeaveTraining = (props: P) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const cls = useStyles();
  const user = useUser()!;
  const lotState = useLotState();
  const onClick = () => {
    if (lotState.breakoutRooms.myBreakoutRoom) {
      lotState.breakoutRooms.moveTo([{ users: [user.sub], id: 'main' }]);
    } else {
      if (lotState.users.iAmTrainer) {
        setShowPopup(true);
      } else {
        props.leaveTraining();
      }
    }
  };
  const theme = useIsTheme();
  return (
    <>
      {props.mobile ? (
        <div className={cls.iconWrapper} onClick={onClick}>
          <CancelIcon />
        </div>
      ) : (
        <LabelButton
          variant="primary"
          className={clsx(cls.button, theme.audi && cls.redButton)}
          onClick={onClick}
        >
          {getButtonText(!!lotState.breakoutRooms.myBreakoutRoom, lotState.users.iAmTrainer)}
        </LabelButton>
      )}
      {showPopup && (
        <EditDialog title="" close={() => setShowPopup(false)} maxWidth="sm">
          <Text variant="order3" weight="bold">
            {strings.endLotTitle}
          </Text>
          <AudiSpacer spaceStackEnd="m" neutralSpaceStackEnd="m" />
          <Text variant="copy1">{strings.endLotMessage}</Text>
          <AudiSpacer spaceStackEnd="m" neutralSpaceStackEnd="m" />
          <LabelButton variant="primary" spaceInlineEnd="s" onClick={() => props.leaveTraining()}>
            {strings.yes}
          </LabelButton>
          <LabelButton variant="secondary" onClick={() => setShowPopup(false)}>
            {strings.no}
          </LabelButton>
        </EditDialog>
      )}
    </>
  );
};
