import React from 'react';
// @ts-ignore
//import ReactStoreBadges from 'react-store-badges';
interface StoreBadgeProps {
  platform: 'android' | 'ios';
  url: string;
  locale?: string;
}

export const StoreBadge = (p: StoreBadgeProps) => <></>; //<ReactStoreBadges {...p} target="_blank" />;

interface MfaBadgeProps {
  platform: 'android' | 'ios';
  locale?: string;
  type: 'google' | 'microsoft';
}

/** Badge for a mfa authenticator */
export const MfaBadge = (props: MfaBadgeProps) => {
  if (props.platform === 'ios')
    return (
      <StoreBadge
        platform={'ios'}
        url={
          props.type === 'google'
            ? 'https://apps.apple.com/de/app/google-authenticator/id388497605'
            : 'https://apps.apple.com/de/app/microsoft-authenticator/id983156458'
        }
        locale={props.locale}
      />
    );
  return (
    <StoreBadge
      platform={'android'}
      url={
        props.type === 'google'
          ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          : 'https://play.google.com/store/apps/details?id=com.azure.authenticator'
      }
      locale={props.locale}
    />
  );
};
