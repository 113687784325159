import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutItem, Text, useIsTheme } from '@pp-labs/ui-components';

import OnDemandPlayer from './VideoPlayer/OnDemandPlayer';
import './Watch.scss';
import { blackStyle } from './blackStyle';
import './blackForm.scss';
import { doesRoleMatch, Role } from '../../../utils/RoleDefinitions';
import { gutterStyle } from '../../../utils/gutter';
import {
  ConfigModuleIdentifiers,
  Session,
  SessionSettings,
  SessionType,
} from '../../../ApiHandler/dclxInterfaces';
import FileButton from '../../../utils/FileButton';
import AskMeAnything from './AskMeAnything/AskMeAnything';
import strings, { getLocalizedValue } from '../../../Localization/Localizer';
import { DisclaimerDialogue } from '../../../DCLX/FE/shared/DisclaimerDialogue';
import { client } from '../../../ApiHandler/client';
import { Survey } from '../../Survey/Survey';
import { useIsDevice, useNamedConfigModule, useUser } from 'utils/hooks';
import IVSLivePlayer from './VideoPlayer/IVSLivePlayer';
import { GetSurveyTemplate } from '@pp-labs/survey';
import AudiSpacer from '../../../utils/AudiSpacer';
import styles from '../styles';
import { MediaFile } from 'DCLX/CMS/Documents/MediaLibrary';
import { ConventionAma } from './AskMeAnything/ConventionAma';
import LivePlayer from 'utils/LivePlayer';
import { EditorParser } from 'features/Editor/EditorParser';

const useStyles = makeStyles((theme: Theme) => ({
  ...styles(theme),
  ...blackStyle(theme),
  ...gutterStyle(theme),
  descriptionContainer: {
    textAlign: 'left',
  },
  videoDescription: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  title: {},
}));

interface WatchProps {
  match?: any;
}

/**
 * Watch an OnDemand Video
 */
const Watch = (props: WatchProps) => {
  const [surveyEnabled, setSurveyEnabled] = useState<boolean>(false);
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const classes = useStyles();
  const user = useUser();
  const theme = useTheme();
  const isTheme = useIsTheme();
  const critical = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletPortrait = useIsDevice().tabletPortrait;
  const usesNamedConfigModule = useNamedConfigModule();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetch = async () => {
    const videoId = parseInt(props.match.params.id);
    const sessionData: Session = (
      await client.get(`sessions/${videoId}`, { withCredentials: true })
    ).data;

    const data: GetSurveyTemplate[] = (await client.get('surveys/templates')).data;
    const surveys = data.find((t) => {
      if (t.startAt * 1000 > Date.now() || t.endAt * 1000 < Date.now()) return false;
      switch (sessionData?.sessionType) {
        case SessionType.DEMAND:
          return t.surveyContext === 'session';
        case SessionType.LIVE ||
          SessionType.LIVETERMINATED ||
          SessionType.EXTERNALTERMINATED ||
          SessionType.EXTERNAL:
          return t.surveyContext === 'liveSession';
        case SessionType.TRAINING:
          return t.surveyContext === 'lot';
      }
      return false;
    }); // checks if a corresponding survey exists
    setSurveyEnabled(surveys !== undefined);
    setLoading(false);
    setSession(sessionData);
    setUrl(sessionData.video?.url || sessionData.externalUrl);
  };

  const sendPlayProgress = async () => {
    const progress = {
      played: 1,
      playedMax: 1,
    };
    await client.post(`sessions/${session?.sessionId}/videohit`, progress);
  };
  if (loading) return null;
  if (!session) return <span>{strings.sessionNotAvailable}</span>;

  const title = getLocalizedValue(session.title);

  let sidePanel;
  const isVisitor = doesRoleMatch(user, [Role.VISITOR, Role.VIEWER, Role.REPORTINGS]);

  if (isVisitor) {
    const settings: SessionSettings = JSON.parse(session.settings || '');
    sidePanel =
      settings.amas === 'convention' ? (
        <ConventionAma sessionId={session.sessionId} />
      ) : settings.amas === 'clx' ? (
        <AskMeAnything sessionId={session.sessionId} />
      ) : null;
  } else {
    sidePanel = (
      <Text as="p" variant="copy1">
        {strings.onlyForVisitors}
      </Text>
    );
  }

  const description = () => {
    return (
      <LayoutItem className={classes.descriptionContainer} spaceStackStart={'xxxl'}>
        <Text as="h2" variant={isTheme.audi ? 'order2' : 'order1'} weight={'bold'}>
          {title}
        </Text>
        <AudiSpacer spaceStackStart={'xl'} neutralSpaceStackStart={'l'} />
        <div className={classes.videoDescription}>
          <EditorParser inputString={getLocalizedValue(session.description)} />
        </div>
        {session.documents.length > 0 && (
          <LayoutItem spaceStackStart={'s'}>
            {session.documents.map((doc: MediaFile, index) => {
              return (
                <FileButton
                  key={doc.mediaId}
                  mediaFile={doc}
                  sessionId={session.sessionId}
                  limitWidth={false}
                />
              );
            })}
          </LayoutItem>
        )}
      </LayoutItem>
    );
  };

  return (
    <Grid container justifyContent="center" spacing={0}>
      <DisclaimerDialogue type="demand" />
      <Grid item xs={12} md={7} lg={8}>
        <Grid item xs={12}>
          {url &&
            (session.sessionType === SessionType.EXTERNAL ? (
              <>
                <LivePlayer url={url} sessionId={session.sessionId} surveyEnabled={surveyEnabled} />
              </>
            ) : session.sessionType === SessionType.LIVE ? (
              <IVSLivePlayer url={url} surveyEnabled={surveyEnabled} onPlay={sendPlayProgress} />
            ) : (
              <OnDemandPlayer url={url} surveyEnabled={surveyEnabled} session={session} />
            ))}
        </Grid>
        <div className={classes.watchWrapper} style={{ paddingBottom: '56px' }}>
          {!tabletPortrait && description()}
          {usesNamedConfigModule(ConfigModuleIdentifiers.surveyInOnDemandSessions) && !critical && (
            <LayoutItem spaceStackStart={'xxxl'}>
              <Survey channelId={session.channelId} id={session.sessionId} context="session" />
            </LayoutItem>
          )}
        </div>
      </Grid>

      <Grid container xs={12} sm={12} md={5} lg={4}>
        {tabletPortrait && (
          <Grid item sm={6}>
            <div className={classes.watchWrapper} style={{ paddingBottom: '56px' }}>
              {description()}
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <LayoutItem spacing={['xxl', 'xxl', 'l', 'xl']}>{sidePanel}</LayoutItem>
        </Grid>
      </Grid>
      {usesNamedConfigModule(ConfigModuleIdentifiers.surveyInOnDemandSessions) && critical && (
        <Grid item xs={12} className={classes.watchWrapper}>
          <Survey channelId={session.channelId} id={session.sessionId} context="session" />
        </Grid>
      )}
    </Grid>
  );
};

export default Watch;
