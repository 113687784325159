import React, { useState } from 'react';
import { FilterParams, useDashboardData } from './useDashboardData';
import AudiSpacer from 'utils/AudiSpacer';
import { BarDiagram } from './BarDiagram';
import ReactSelect from 'react-select';
import { Text } from '@pp-labs/ui-components';

/** interface for TaggedDiagram props coming from parent component ViewDiagrams */
interface P {
  filters: Omit<FilterParams, 'category'>;
}

export const CHART_COLORS = {
  black: 'rgb(0, 0, 0)',
};

export interface ViewsDataStructure {
  title?: string;
  summary: ViewsEntryDataStructure[];
  byTag?: ViewsTagEntryDataStructure[][];
}
export interface ViewsEntryDataStructure {
  key: string;
  title: string;
  value: number;
  valueType?: 'percent';
}
export interface ViewsTagEntryDataStructure {
  key: string;
  title: string;
  value: number | string;
  valueType?: 'percent';
}
interface ViewsData {
  [key: string]: ViewsDataStructure;
}

export interface TaggedDiagramProps {
  value: ViewsDataStructure;
  key: string;
  max?: number;
}
export const TaggedDiagram = (props: TaggedDiagramProps) => {
  const [searchValue, setSearchValue] = useState<string[]>([]);

  return (
    <>
      <div>
        <Text variant="order3" weight="bold">
          {props.value.title}
        </Text>
        <AudiSpacer spaceStackEnd="m" />
        <BarDiagram
          labels={[
            ...props.value.summary
              .filter((entry) => entry.valueType !== 'percent')
              .map((entry) => `${entry.title}`),
          ]}
          items={props.value.summary
            .filter((entry) => entry.valueType !== 'percent')
            .map((entry) => entry.value)}
          vertical={true}
          max={props.max}
        />
        <BarDiagram
          labels={[
            ...props.value.summary
              .filter((entry) => entry.valueType === 'percent')
              .map((entry) => `${entry.title} in %`),
          ]}
          items={props.value.summary
            .filter((entry) => entry.valueType === 'percent')
            .map((entry) => Math.round((entry.value as number) * 100 * 100) / 100)}
          vertical={true}
          max={100}
        />
      </div>

      <AudiSpacer spaceStackEnd="s" />
      {props.value.byTag && (
        <div>
          <ReactSelect
            placeholder={`Select specific ${props.value.title}`}
            options={props.value.byTag.map((t) => {
              return { label: t[0].value.toString(), value: t[0].value.toString() };
            })}
            onChange={(e) => {
              setSearchValue([...e.map((v) => v.value.toString())]);
            }}
            value={searchValue?.map((v) => {
              return { label: v, value: v };
            })}
            isMulti={true}
          />
          <AudiSpacer spaceStackEnd="m" />
          {props.value.byTag
            .filter((t) =>
              searchValue
                ? searchValue.some((v) => {
                    return t[0].value && t[0].value === v;
                  })
                : false
            )
            .map((tagData, index) => {
              return (
                <div style={{ paddingLeft: '100px' }}>
                  <AudiSpacer spaceStackEnd="m" />
                  <Text variant="order4" weight="bold">
                    {tagData[0].value}
                  </Text>
                  <BarDiagram
                    labels={[
                      ...tagData
                        .filter(
                          (entry) => entry.key !== 'reportingTag' && entry.valueType === undefined
                        )
                        .map((entry) => `${entry.title}`),
                    ]}
                    items={[
                      ...tagData
                        .filter(
                          (entry) => entry.key !== 'reportingTag' && entry.valueType === undefined
                        )
                        .map((entry) => entry.value),
                    ]}
                    max={props.max}
                    vertical={true}
                  />
                  <BarDiagram
                    labels={[
                      ...tagData
                        .filter(
                          (entry) => entry.key !== 'reportingTag' && entry.valueType === 'percent'
                        )
                        .map((entry) => `${entry.title}`),
                    ]}
                    items={[
                      ...tagData
                        .filter(
                          (entry) => entry.key !== 'reportingTag' && entry.valueType === 'percent'
                        )
                        .map((entry) => Math.round((entry.value as number) * 100 * 100) / 100),
                    ]}
                    max={100}
                    vertical={true}
                  />
                </div>
              );
            })}
        </div>
      )}

      <AudiSpacer spaceStackEnd="xxl" />
    </>
  );
};

/** Renders the Views page of the Dashboard */
export const ViewsDiagrams = ({ filters }: P) => {
  const data: ViewsData = useDashboardData({ category: 'views', ...filters }).data;
  const getMax = () => {
    return Math.max(
      ...Object.entries(data)
        .map(([key, value]) => {
          return value.summary.map((item) => item.value);
        })
        .flat()
    );
  };

  return (
    <div style={{ padding: '64px', overflow: 'auto' }}>
      {data &&
        Object.entries(data).map(([key, value]) => {
          return <TaggedDiagram value={value} key={key} max={getMax()} />;
        })}
    </div>
  );
};
