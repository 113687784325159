import React from 'react';
import { IconButton } from '@material-ui/core';
import { EditDialog } from '@pp-labs/ui-components';
import { StreamInfo } from 'ApiHandler/dclxInterfaces';
import { Text } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import { CopyIcon } from 'icons';
import copy from 'copy-to-clipboard';
import { useNotify } from './hooks';

/** interface for DeleteDialogue props coming from parent component AdminPanelTable, ChannelTable, sessionTable,... */
interface P {
  streamServers?: StreamInfo[];
  open: boolean;
  onClose: () => void;
}

/**
 * Dialog to show stream access infos
 */
const StreamAccessDialog = (props: P) => {
  if (!props.open) return null;
  return (
    <EditDialog title={strings.streamAccessInfo} close={props.onClose}>
      {props.streamServers?.[0] && (
        <div>
          <Text as="p" variant="order4">
            Main Stream
          </Text>
          <StreamInfoDisplay streamServer={props.streamServers[0]} />
        </div>
      )}
      {props.streamServers?.[1] && (
        <div>
          <Text as="p" variant="order4">
            Backup Stream
          </Text>
          <StreamInfoDisplay streamServer={props.streamServers[1]} />
        </div>
      )}
    </EditDialog>
  );
};

const StreamInfoDisplay = (props: { streamServer: StreamInfo }) => {
  const notify = useNotify();
  return (
    <div>
      <Text as="p" variant="copy1">
        RTMPS URL:
      </Text>
      {props.streamServer && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text>{props.streamServer.ingestEndpoint}</Text>
          <IconButton
            style={{ marginLeft: '8px' }}
            onClick={() => {
              if (props.streamServer?.ingestEndpoint)
                if (copy(props.streamServer?.ingestEndpoint))
                  notify(strings.copiedToClipboard, 'success');
            }}
          >
            <CopyIcon />
          </IconButton>
        </div>
      )}

      <Text as="p" variant="copy1">
        {strings.streamKey}:
      </Text>
      {props.streamServer && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text>{props.streamServer.streamKey}</Text>
          <IconButton
            style={{ marginLeft: '8px' }}
            onClick={() => {
              if (props.streamServer?.streamKey)
                if (copy(props.streamServer?.streamKey))
                  notify(strings.copiedToClipboard, 'success');
            }}
          >
            <CopyIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default StreamAccessDialog;
