import React, { useRef, useEffect, createRef, useState } from 'react';
import './VideoPlayer.scss';
import VideoControls from '../../../../utils/VideoControls';
import screenfull from 'screenfull';
import ReactDOM from 'react-dom';
import { useForceNoRender, useNotify } from '../../../../utils/hooks';
import CallToAction from './CallToAction';
import { Player, IvsPlayerInterface } from './IVSPlayer';
import strings from 'Localization/Localizer';
import { defaultVolume } from '../../../../config';

/** interface for IVSLivePlayer props coming from parent components LiveAma, Live, Lot and Watch */
interface P {
  url: string;
  surveyEnabled: boolean;
  onPlay?: Function;
  viewCount?: number;
  clapCount?: number;
  live?: boolean;
  extendVolume?: boolean;
}

/**
 * LIVE player optimized for IVS streams
 */

let controlsTimeout: ReturnType<typeof setTimeout> | null = null;

const IVSLivePlayer = (props: P) => {
  const pause = useForceNoRender([props.url]);
  if (pause) return null;
  return <InnerIVSLivePlayer {...props} />;
};

function InnerIVSLivePlayer(props: P) {
  const videoEl = useRef(null);
  const player = useRef(null) as unknown as Player;
  const notify = useNotify();

  const [survey, setSurvey] = useState(false);
  const [controls, setControls] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentVolume, setCurrentVolume] = useState<number>(defaultVolume);
  const [rerender, setRerender] = useState<number>(0);

  let wrapper = createRef();

  const onStateChange = () => {
    const playerState = player.current.getState();
    switch (playerState.toString()) {
      case 'Ended':
        if (props.surveyEnabled) setSurvey(true);
        break;
      default:
        break;
    }
  };

  const forceRerender = () => setRerender(rerender + 1);

  // auto play called every 10 sec when the stream is not available

  const autoPlay = () => {
    player.current.load(props.url);
    player.current.play();
  };

  let playTimeout: ReturnType<typeof setTimeout>;

  const onError = (err: { type: string }) => {
    console.error('Player Event Error - ', err.type);
    if (err.type === 'ErrorNotAvailable') {
      if (!playTimeout) {
        notify(strings.streamUnavailable, 'error', false);
      }
      playTimeout = setTimeout(autoPlay, 10000);
    }
  };

  const onPlay = () => props.onPlay?.();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.live-video.net/1.4.0/amazon-ivs-player.min.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      // @ts-ignore
      const IvsPlayer: IvsPlayerInterface = IVSPlayer;
      if (IvsPlayer.isPlayerSupported) {
        const { ENDED } = IvsPlayer.PlayerState;
        const { ERROR } = IvsPlayer.PlayerEventType;
        // eslint-disable-next-line no-undef
        player.current = IvsPlayer.create();
        player.current.load(props.url);
        player.current.setVolume(currentVolume);
        player.current.setMuted(true);
        player.current.attachHTMLVideoElement(videoEl.current);
        player.current.addEventListener(ENDED, onStateChange);
        player.current.addEventListener(ERROR, onError);
        player.current.addEventListener('Playing', onPlay);
        addFullscreenEventListeners();
        player.current.play();
        setLoaded(true);
      }
    };

    return () => {
      if (playTimeout) clearTimeout(playTimeout);
      try {
        document.body.removeChild(script);
      } catch {}
      removeFullscreenEventListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      const isMuted = player.current.isMuted();
      if (isMuted) {
        player.current.setMuted(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const setVolume = (nextVolume: number) => {
    player.current.setVolume(nextVolume);
    setCurrentVolume(nextVolume);
  };

  const handleControls = () => {
    if (controlsTimeout) {
      clearTimeout(controlsTimeout);
    }
    enableControls();
    controlsTimeout = setTimeout(() => {
      disableControls();
    }, 3000);
  };

  const enableControls = () => {
    setControls(true);
  };

  const disableControls = () => {
    setControls(false);
  };

  const exitFullscreen = () => {
    /* @ts-ignore*/
    screenfull.exit();
    setFullscreen(false);
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      exitFullscreen();
    } else {
      setFullscreen(true);

      /* @ts-ignore */ /* eslint-disable-next-line react/no-find-dom-node */
      screenfull.request(ReactDOM.findDOMNode(wrapper.current));
    }
  };
  const addFullscreenEventListeners = () => {
    document.addEventListener('webkitfullscreenchange', handleEsc);
    document.addEventListener('mozfullscreenchange', handleEsc);
    document.addEventListener('fullscreenchange', handleEsc);
    document.addEventListener('MSFullscreenChange', handleEsc);
  };
  const removeFullscreenEventListeners = () => {
    document.removeEventListener('webkitfullscreenchange', handleEsc);
    document.removeEventListener('mozfullscreenchange', handleEsc);
    document.removeEventListener('fullscreenchange', handleEsc);
    document.removeEventListener('MSFullscreenChange', handleEsc);
  };

  const handleEsc = () => {
    if (
      /* @ts-ignore*/
      !document.fullscreenElement &&
      /* @ts-ignore*/
      !document.webkitIsFullScreen &&
      /* @ts-ignore*/
      !document.mozFullScreen &&
      /* @ts-ignore*/
      !document.msFullscreenElement
    ) {
      exitFullscreen();
    }
  };
  const muted = !!player?.current?.isMuted?.();

  return (
    // @ts-ignore
    <div className="player-wrapper" onMouseMove={handleControls} ref={wrapper}>
      {!survey && (
        <>
          <video
            className="react-player"
            style={{ pointerEvents: 'none' }}
            id="video-player"
            ref={videoEl}
            playsInline
            width={'100%'}
            height={'100%'}
          />
          {player.current && (
            <VideoControls
              extendVolume={props.extendVolume}
              live={props.live}
              viewCount={props?.viewCount}
              clapCount={props?.clapCount}
              playerMode="noSeek"
              visible={controls}
              muted={muted}
              playing={!player.current.isPaused()}
              pause={player.current.pause}
              play={() => {
                player.current.load(props.url);
                player.current.play();
              }}
              setMuted={(e) => {
                player.current.setMuted(e);
                forceRerender();
              }}
              fullscreen={fullscreen}
              toggleFullscreen={toggleFullscreen}
              volume={currentVolume}
              setVolume={setVolume}
            />
          )}
        </>
      )}

      {survey && (
        <CallToAction
          close={() => {
            setSurvey(false);
          }}
        />
      )}
    </div>
  );
}

export default IVSLivePlayer;
