import { makeStyles } from '@material-ui/core';
import { CognitoUser } from 'ApiHandler/dclxInterfaces';
import React from 'react';
import { doesRoleMatch, Role } from 'utils/RoleDefinitions';
import { LanguageMenu } from './LanguageMenu';
import { MessageNotification } from './MessageNotification/MessageNotification';
import { UserMenu } from './UserMenu';
import { MySchedule } from '../MySchedule/MySchedule';
import { useIsDevice } from 'utils/hooks';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '12px',
    },
  },
}));

/** interface for RightSideNav props coming from parent component NavBar */
interface P {
  user: CognitoUser | null;
  mobileMenuOpen: boolean;
}

/** Right, icon based, part of the navigation bar */
const RightSideNav = (props: P) => {
  const cls = useStyles();
  const mobile = useIsDevice().mobilePortrait;
  const isVisitor = doesRoleMatch(props.user, Role.VISITOR);
  return (
    <div className={cls.wrapper}>
      {!props.mobileMenuOpen && mobile ? null : (
        <>
          {props.user && <UserMenu />}
          <LanguageMenu />
        </>
      )}
      {isVisitor && <MessageNotification />}
      {isVisitor && <MySchedule />}
    </div>
  );
};
export { RightSideNav };
