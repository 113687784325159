import React, { useEffect, useMemo, useState } from 'react';
import { ChannelTopic, EditLevel, Session, SessionType } from '../../../ApiHandler/dclxInterfaces';
import DCLXCmsSessionEdit from '../Sessions/DCLXCmsSessionEdit';
import { EditDialog } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { useCurrentPageChannelId, useProgressionChannels } from '../../../utils/hooks';

/** interface for TrainingsSessionEdit props coming from parent component TrainingsForTopic  */
interface P {
  session: Session | null;
  topic: ChannelTopic | null;
  close: (refresh?: boolean) => void;
}

/**
 * Wrapper for SessionEdit for TrainingsManagers
 */

export const TrainingsSessionEdit = (props: P) => {
  const currentChannelId = useCurrentPageChannelId();
  const channels = useProgressionChannels();

  /** The current channel is the one of the session, if we are editing a session.
   * If not it is the one of the topic or, when we are outside a topic, simply the first channel the user has access to.
   */

  const channel = useMemo(
    () => channels.find((c) => c.channelId === currentChannelId),
    [channels, currentChannelId]
  );

  const [sessions, setSessions] = useState<Session[] | null>(null);
  useEffect(() => {
    const fetch = async () => {
      setSessions((await client.get('sessions')).data);
    };
    fetch();
  }, []);

  const successEdit = () => props.close(true);
  const closeEdit = () => props.close();

  const demandSessions = sessions?.filter((s) => s.sessionType === SessionType.DEMAND) || [];

  const pos =
    sessions?.filter((s) =>
      props.topic ? s.topicIds.includes(props.topic.topicId) : s.showInChannelContent
    ).length || 0;
  if (!sessions) return null;
  const topicId = props.topic?.topicId ? [props.topic.topicId] : [];

  return (
    <EditDialog title={''} close={closeEdit}>
      <DCLXCmsSessionEdit
        refs={demandSessions}
        thisSession={props.session}
        success={successEdit}
        abort={closeEdit}
        type={SessionType.TRAINING}
        topicIds={topicId}
        channel={channel!}
        level={EditLevel.MARKET}
        newPosition={pos}
        stream={false}
      />
    </EditDialog>
  );
};
