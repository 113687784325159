import strings from 'Localization/Localizer';

/** Some strings special to the dashboard.
 *
 * Keys need to be changed in regards to the API, so it is different.*/
export const dashboardStrings = {
  timeRangeFrom: strings.periodFrom,
  timeRangeTo: strings.periodUntil,
  participantsDashboard: strings.participants,
  results: strings.results,
  views: strings.views,
};
