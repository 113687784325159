import React, { useEffect, useState } from 'react';
import { NativeTextArea, useIsTheme } from '@pp-labs/ui-components';
import strings from 'Localization/Localizer';
import clsx from 'clsx';
import { useLotState } from '../LOT/Provider/LotProvider';

/**
 * Form for sending a chat message
 */

const IsolatedSend = () => {
  const theme = useIsTheme();
  const lotState = useLotState();
  const [msg, setMsg] = useState<string>('');
  useEffect(() => {
    document.addEventListener('keydown', s);
    return () => {
      document.removeEventListener('keydown', s);
    };
  });

  const onChange = (event: any) => {
    if (event.target.value === '\n' && msg === '') return;
    setMsg(event.target.value);
  };

  const s = (event: any) => {
    if (event.keyCode === 13 && !event.shiftKey) send();
  };

  const send = () => {
    if (!msg) return;
    lotState.chat.sendMessage(msg);
    setMsg('');
  };

  return (
    <div>
      <NativeTextArea
        className={clsx(theme.neutralDark && 'lot-textarea')}
        onChange={onChange}
        rows={3}
        value={msg}
        inputId="message"
        label={
          lotState.users.iAmTrainer || lotState.type === 'live'
            ? strings.typeAndPressEnterToSend
            : strings.typeAndPressEnterToSendBreakout
        }
      />
    </div>
  );
};

export default IsolatedSend;
