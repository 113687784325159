import { Channel, CognitoUser } from '../../ApiHandler/dclxInterfaces';
import { CMSAccess, DashboardAccess, doesRoleMatch, Role } from '../../utils/RoleDefinitions';
import strings, { getLocalizedValue } from '../../Localization/Localizer';

export interface Config {
  text: string;
  route: string;
  visible: boolean;
  desktopOnly: boolean;
}

/** Navbar config, controls who sees what etc. */
export const getConfig = (
  user: CognitoUser | null,
  allowedChannels: Channel[],
  event: string,
  hasModule: (id: number) => boolean,
  surveys: boolean
) => {
  const seeCms = doesRoleMatch(user, CMSAccess);

  const seeKPIDashboard = doesRoleMatch(user, DashboardAccess);
  const config: Config[] = [
    {
      visible: seeCms,
      text: strings.menuCms,
      route: `/${event}/cms/start`,
      desktopOnly: false,
    },
    {
      visible: doesRoleMatch(user, Role.DIRECTOR),
      text: strings.cmsMenuAskedQuestions,
      route: `/${event}/amas`,
      desktopOnly: true,
    },
    {
      visible: seeKPIDashboard,
      text: strings.menuDashboard,
      route: `/${event}/dashboard/participants`,
      desktopOnly: true,
    },
  ];
  if (seeCms) {
    // CMS only in navigation bar
    return config.filter((s) => s.visible);
  }

  const mergedConfig = [
    ...allowedChannels
      .sort((c_a, c_b) => (c_a.menuItemPosition || 0) - (c_b.menuItemPosition || 0))
      .filter((c) => c.menuItemKey && c.menuItemKey !== '')
      .map((c) => {
        const cConfig: Config = {
          visible: true,
          text: getLocalizedValue(c.menuItemTitle || ''),
          route: `/${event}/page/${c.menuItemKey}/${c.channelId}`,
          desktopOnly: false,
        };
        return cConfig;
      }),
    ...config,
  ];

  return mergedConfig.filter((s) => s.visible);
};
