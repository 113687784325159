import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { appendZero, getMonth, getTimezone, toInt } from '../../utils/convert';
import { Select } from '@pp-labs/ui-components';
import { nanoid } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  timeCodeContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '150px',
    padding: '0px 12px',
  },
  timeZoneContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '300px',
    padding: '0px 12px',
  },
}));
/** interface for AdvancedDatePicker props coming from parent component EventSetupStepInfos, EditTest, AddTraining and CmsNewDatPicker  */
interface P {
  initialDate: Date;
  initialTimezone?: number;
  onChange: (newVal: Date) => void;
  onTimezoneChange?: (newVal: number) => void;
}

/**
 * A nice DatePicker, with still a crappy UI.
 * Supports Timezone Switches.
 */

export const AdvancedDatePicker = (props: P) => {
  const [lastTz, setLastTz] = useState<number>(offset());
  const fields = useMemo(() => {
    const nano = nanoid();
    return {
      year: `${nano}-year`,
      month: `${nano}-month`,
      day: `${nano}-day`,
      hour: `${nano}-hour`,
      minute: `${nano}-minute`,
      timezone: `${nano}-timezone`,
    };
  }, []);

  const { errors, touched, values, setFieldValue } = useFormikContext<{ [key: string]: string }>();

  const setDate = (date: Date) => {
    setFieldValue(fields.year, date.getFullYear().toString());
    setFieldValue(fields.month, date.getMonth().toString());
    setFieldValue(fields.day, date.getDate().toString());
    setFieldValue(fields.hour, date.getHours().toString());
    setFieldValue(fields.minute, (Math.floor(date.getMinutes() / 5) * 5).toString());
  };

  const getDate = (mode: 'display' | 'final') => {
    const pureDate = new Date(
      toInt(values[fields.year] || props.initialDate.getFullYear()),
      toInt(values[fields.month] || props.initialDate.getMonth()),
      toInt(values[fields.day] || props.initialDate.getDate()),
      toInt(values[fields.hour] || props.initialDate.getHours()),
      toInt(values[fields.minute] || props.initialDate.getMinutes())
    );
    if (mode === 'display') return pureDate;
    const tz = toInt(values[fields.timezone]);
    const diff = values[fields.timezone] !== '' ? tz - offset() : 0;
    return new Date(pureDate.getTime() + diff * msH);
  };

  useEffect(() => {
    setDate(props.initialDate);
    setFieldValue(fields.timezone, props.initialTimezone || offset().toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.onChange(getDate('final'));
    props.onTimezoneChange && props.onTimezoneChange(toInt(values[fields.timezone]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (
      !(
        values[fields.year] &&
        values[fields.month] &&
        values[fields.day] &&
        values[fields.hour] &&
        values[fields.minute]
      )
    )
      return;
    const tz = toInt(values[fields.timezone]);
    const diffH = tz - lastTz;
    if (!diffH) return;
    const prevDate = getDate('display');
    const newDate = new Date(prevDate.getTime() - diffH * msH);
    setDate(newDate);
    setLastTz(tz);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[fields.timezone]]);

  const cls = useStyles();

  const et = {
    year: touched[fields.year] ? errors[fields.year] : '',
    month: touched[fields.month] ? errors[fields.month] : '',
    day: touched[fields.day] ? errors[fields.day] : '',
    hour: touched[fields.hour] ? errors[fields.hour] : '',
    minute: touched[fields.minute] ? errors[fields.minute] : '',
    timezone: touched[fields.timezone] ? errors[fields.timezone] : '',
  };

  const currentYear = new Date().getFullYear();
  return (
    <>
      <div>
        <div className={cls.timeCodeContainer} about="select-year">
          <Select name={fields.year} label={'Year'} error={et.year} required>
            {new Array(3).fill(0).map((_, index) => {
              const date = index + currentYear - 1;
              return (
                <option key={`year-${date}`} value={date}>
                  {date}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={fields.month} label={'Month'} error={et.month} required>
            {new Array(12).fill(0).map((_, index) => {
              const date = index;
              return (
                <option key={`month-${date}`} value={date}>
                  {getMonth(date)}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={fields.day} label={'Day'} error={et.day} required>
            {new Array(31).fill(0).map((_, index) => {
              const date = index + 1;
              return (
                <option key={`date-${date}`} value={date}>
                  {date}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={fields.hour} label={'Hour'} error={et.hour} required>
            {new Array(24).fill(0).map((_, index) => {
              return (
                <option key={`hour-${index}`} value={index}>
                  {index}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={cls.timeCodeContainer}>
          <Select name={fields.minute} label={'Minute'} error={et.minute} required>
            {new Array(12).fill(0).map((_, index) => {
              const v = index * 5;
              return (
                <option key={`minute-${v}`} value={v}>
                  {appendZero(v)}
                </option>
              );
            })}
          </Select>
        </div>

        <div className={cls.timeZoneContainer}>
          <Select name={fields.timezone} label={'Timezone'} error={et.timezone}>
            {Timezones.map((t) => (
              <option value={t.value.toString()} key={t.value}>
                {t.string}
              </option>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

const range = (low: number, top: number): number[] =>
  new Array(top - low).fill(0).map((_, i) => i + low);

const Timezones = range(-14, 10).map((e) => {
  const app = e === -1 ? '(Wintertime, MEZ)' : e === -2 ? '(Summertime, MESZ)' : '';
  return {
    value: e,
    string: `${getTimezone(e)} ${app}`,
  };
});

const offset = () => new Date().getTimezoneOffset() / 60;

const msH = 60 * 60 * 1000;
