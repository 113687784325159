import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import strings from '../../../Localization/Localizer';
import { useEffect, useMemo, useState } from 'react';
import { EditDialog, LabelButton } from '@pp-labs/ui-components';
import { Editor } from '../../../features/Editor/Editor';
import axios from 'axios';
import { MediaFile } from './MediaLibrary';

const getValidation = () =>
  Yup.object({
    html: Yup.string().required(strings.required),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

type P = {
  file: MediaFile | 'new';
  onSubmit: (file: File) => void;
  newName: string;
  close: () => void;
};

/** Allows to create a new html file using the editor we know from CKEditor */
export const HtmlEditor = (props: P) => {
  const [content, setContent] = useState<string | null>(null);
  useEffect(() => {
    const load = async () => {
      if (props.file === 'new') return;
      const file = await axios.get<Blob>(props.file.url, { responseType: 'blob' });
      const text = await file.data.text();
      setContent(text);
    };
    if (props.file === 'new') {
      setContent('');
    } else {
      load();
    }
  }, [props.file]);
  const initialValues = useMemo(() => {
    return { html: content || '' };
  }, [content]);
  const validationSchema = useMemo(getValidation, []);
  const submit = async (values: F) => {
    const type = 'text/html;charset=utf-8';
    const name = props.file === 'new' ? props.newName : props.file.originalFile;
    const blob = new Blob([values.html], { type: type });
    const file = new File([blob], name);
    props.onSubmit(file);
  };
  if (content === null) return null;
  return (
    <EditDialog title="" close={props.close}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        <Form>
          <Editor formikName="html" label={'HTML'} />
          <LabelButton variant="primary" type="submit">
            {strings.save}
          </LabelButton>
        </Form>
      </Formik>
    </EditDialog>
  );
};
