import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Channel,
  ChannelCategory,
  Language,
  LanguagesSelection,
  MenuItem,
} from '../../../ApiHandler/dclxInterfaces';
import { makeStyles } from '@material-ui/core';

import strings from '../../../Localization/Localizer';
import { LabelButton, TextField, EditDialog, Select, tableStyles } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { useEventLanguageSelection, useNotify, useRefreshProgression } from 'utils/hooks';
import AudiSpacer from 'utils/AudiSpacer';

import { toInt } from 'utils/convert';
import AudiTabs from 'utils/AudiTabs/AudiTabs';
import { LanguagesSelector } from 'DCLX/utils/LanguagesSelector';

/** interface for EditChannel props coming from parent component channelSettings and ChannelTable  */
interface P {
  open: boolean;
  close: () => void;
  channel: Channel | null;
  duplicate: boolean;
}

interface FormValues {
  channelName: string;
  categoryName?: string;
  categoryId?: number;
  channelKey?: string;
  preferedLanguage?: string;
  supportEmail?: string;
  menuItemKey?: string;
}
const useStyles = makeStyles({
  ...tableStyles(),
  tabs: {
    flexGrow: 1,
    width: '100%',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: '18px',
  },
});
/**
 * Allows users to add or edit a channel
 */
const EditChannel = (props: P) => {
  const cls = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const refreshProgression = useRefreshProgression();
  const notify = useNotify();
  const languageSelection = useEventLanguageSelection();
  const [categories, setCategories] = useState<ChannelCategory[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [createNewCategory, setCreateNewCategory] = useState<boolean>(false);

  const [selectedLangs, setSelectedLangs] = useState<Language[]>([]);

  const [initialPreferedLanguage, setInitialPreferedLanguage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    try {
      const p: LanguagesSelection = JSON.parse(props.channel?.languages!);
      setSelectedLangs(p.languages);
      setInitialPreferedLanguage(p.preferedLanguage);
    } catch (error) {}
  }, [props.channel]);

  const refresh = async () => {
    refreshProgression();
    try {
      const menuItems = (await client.get('channels/menuItems')).data;
      setMenuItems(menuItems);

      const cdata = (await client.get(`channels/categories`)).data;
      setCategories(cdata);

      if (cdata.length === 0) {
        setCreateNewCategory(true);
      } else {
        setCreateNewCategory(false);
      }
    } catch (error: any) {
      notify(error.message, 'error');
    }
  };
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPublicChannelCategory = (cId: number | string | undefined) =>
    !createNewCategory &&
    cId &&
    !!categories.find((c) => c.categoryId === Number(cId) && c.longName === 'public');

  const newCatValidation = Yup.object({
    channelName: Yup.string().required(strings.required),
    channelKey: Yup.string().required(strings.required),
    categoryName: Yup.string().required(strings.required),
    preferedLanguage: Yup.string().required(strings.required),
    supportEmail: Yup.string().email(strings.invalid).required(strings.required),
  }).required(strings.required);

  const existCatValidation = Yup.object({
    channelName: Yup.string().required(strings.required),
    categoryId: Yup.number().required(strings.required),
    channelKey: Yup.string().when('categoryId', {
      is: (cId: any) => {
        return isPublicChannelCategory(cId);
      },
      then: undefined,
      otherwise: Yup.string().required(strings.required),
    }),

    preferedLanguage: Yup.string().required(strings.required),
    supportEmail: Yup.string().email(strings.invalid).required(strings.required),
  }).required(strings.required);

  const validation = useMemo(() => {
    if (createNewCategory) {
      return newCatValidation;
    }
    return existCatValidation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewCategory, categories]);

  const getErrors = (touched: any, errors: any) => {
    return {
      channelName: touched.channelName ? errors.channelName : '',
      channelKey: touched.channelKey ? errors.channelKey : '',
      categoryId: touched.categoryId ? errors.categoryId : '',
      categoryName: touched.categoryName ? errors.categoryName : '',
      preferedLanguage: touched.preferedLanguage ? errors.preferedLanguage : '',
      supportEmail: touched.supportEmail ? errors.supportEmail : '',
    };
  };

  const submitToServer = async (v: FormValues) => {
    setSubmitting(true);
    if (createNewCategory) {
      try {
        const data = {
          uniqueName: v.categoryName,
          longName: v.categoryName,
        };
        const res = await client.post('channels/category', data);
        v.categoryId = res.data;
      } catch (error: any) {
        notify(error.message, 'error');
        return;
      }
    }
    try {
      const data = {
        channelName: v.channelName,
        channelKey: v.channelKey,
        categoryId: v.categoryId ? toInt(v.categoryId!) : undefined,
        languages: JSON.stringify({
          languages: selectedLangs,
          preferedLanguage: v.preferedLanguage,
        }),
        supportEmail: v.supportEmail,
        menuItemKey: v.menuItemKey,
      };
      if (!props.duplicate) {
        if (props.channel?.channelId) {
          await client.put(`channels/${props.channel?.channelId}`, data);
        } else {
          await client.post('channels', data);
        }
        notify(strings.channelCreated, 'success');
      } else {
        const duplicateData = { ...data, channelId: props.channel?.channelId };
        await client.post('channels/clone', duplicateData);
        notify('Channel duplicated', 'success');
      }
    } catch (error: any) {
      notify(error.message, 'error');
      console.error(error);
    }

    props.close();
    refresh();

    setSubmitting(false);
  };

  const getTabs = (et: any) => {
    return [
      <>
        <AudiSpacer spaceStackEnd={'s'} />
        <Select name="categoryId" label={strings.channelCategory} error={et.categoryId}>
          {categories.map((c, i) => {
            return <option value={i + 1}>{c.longName}</option>;
          })}
        </Select>
      </>,
      <>
        <AudiSpacer spaceStackEnd={'s'} />
        <TextField name="categoryName" label={strings.categoryName} error={et.categoryName} />
      </>,
    ];
  };

  const initialValues: FormValues = {
    channelName: props.duplicate ? '' : props.channel?.channelName || '',
    categoryId: props.channel?.categoryId,
    channelKey: props.duplicate ? '' : props.channel?.channelKey || '',
    categoryName: '',
    preferedLanguage: initialPreferedLanguage || '',

    supportEmail: props.channel?.supportEmail || '',
    menuItemKey: props.channel?.menuItemKey,
  };

  return (
    <>
      {props.open && (
        <EditDialog
          title={
            props.duplicate
              ? strings.duplicateChannel
              : props.channel
              ? strings.editChannel
              : strings.addChannel
          }
          close={props.close}
          maxWidth="md"
          cms
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values: FormValues) => {
              submitToServer(values);
            }}
          >
            {({ errors, touched, values }) => {
              const et = getErrors(touched, errors);
              return (
                <Form>
                  <TextField name="channelName" label={strings.name} error={et.channelName} />
                  {categories.length > 0 && (
                    <div className={cls.tabs}>
                      <AudiSpacer spaceStackEnd={'l'} />
                      <AudiTabs
                        tabLabels={[strings.selectCategory, strings.createNewCategory]}
                        tabContents={getTabs(et)}
                        onChange={(index: number) => {
                          if (index === 1) {
                            setCreateNewCategory(true);
                          } else {
                            setCreateNewCategory(false);
                          }
                        }}
                      />
                    </div>
                  )}
                  {categories.length === 0 && (
                    <>
                      <AudiSpacer spaceStackEnd={'s'} />
                      <TextField
                        name="categoryName"
                        label={strings.categoryName}
                        error={et.categoryName}
                      />
                    </>
                  )}

                  {
                    // Formik casts value to string despite it beeing a number
                    // eslint-disable-next-line eqeqeq
                    values.categoryId && !isPublicChannelCategory(values.categoryId) && (
                      <>
                        <AudiSpacer spaceStackEnd={'s'} />

                        <TextField
                          name="channelKey"
                          label={strings.channelKeyCreate}
                          error={et.channelKey}
                        />
                      </>
                    )
                  }

                  {languageSelection && (
                    <>
                      <AudiSpacer spaceStackEnd={'s'} />
                      <LanguagesSelector
                        languages={languageSelection.languages}
                        setSelectedLanguages={setSelectedLangs}
                        selectedLanguages={selectedLangs}
                        error={et.preferedLanguage}
                      />
                    </>
                  )}
                  <AudiSpacer spaceStackEnd={'s'} />
                  <TextField
                    name="supportEmail"
                    label={strings.supportEmail}
                    error={et.supportEmail}
                  />
                  <AudiSpacer spaceStackEnd={'s'} />
                  <Select name={'menuItemKey'} label={'Menu Item'}>
                    {menuItems.map((item: MenuItem) => {
                      return <option value={item.menuItemKey}>{item.menuItemKey}</option>;
                    })}
                  </Select>

                  <AudiSpacer spaceStackEnd={'m'} />
                  <div className={cls.buttonsWrapper}>
                    <LabelButton variant="secondary" onClick={props.close} disabled={submitting}>
                      {strings.cancel}
                    </LabelButton>
                    <LabelButton
                      type="submit"
                      variant="primary"
                      className={cls.submitButton}
                      disabled={submitting}
                    >
                      {props.duplicate ? strings.duplicateChannel : strings.save}
                    </LabelButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </EditDialog>
      )}
    </>
  );
};

export default EditChannel;
