import React from 'react';

import { makeStyles, Theme, GridListTile } from '@material-ui/core';
import styles from '../../features/Sessions/styles';
import { gutterStyle } from '../../utils/gutter';
import { useHistory } from 'react-router-dom';
import { LayoutItem, Text } from '@pp-labs/ui-components';
import { PlatformEvent } from '../../ApiHandler/dclxInterfaces';
import { getDisplayDate } from '../../utils/convert';
import { getLocalizedValue } from '../../Localization/Localizer';

/** interface for EventTile props coming from parent components SessionTiles, MainPage */
interface P {
  event: PlatformEvent;
}
const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
  ...styles(theme),

  tileContentWrap: {
    padding: '0 8px',
  },
  tile: {
    '&:hover': {
      backgroundColor: '#CCC',
    },
    cursor: 'pointer',
  },
}));

/** Displays one Platform Event */
const EventTile = (props: P) => {
  const history = useHistory();

  const cls = useStyles();

  return (
    <GridListTile>
      <div className={cls.tile} onClick={() => history.push(`/${props.event.identifier}/login`)}>
        <div className={cls.sessionWrapper}>
          <img className={cls.thumb} src={props.event.heroImage} alt="Thumbnail" />
          <div className={cls.tileContentWrap}>
            <div style={{ paddingTop: 6 }} title={props.event.title}>
              <Text as="h3" variant="order4" weight={'bold'} className={cls.maxTwoLines}>
                {props.event.title}
              </Text>
            </div>
            <div className={cls.book}>
              <Text as="p" variant="copy1" weight={'bold'}>
                {getDisplayDate(props.event.eventStartTiming, true)}
              </Text>
            </div>
            <LayoutItem spaceStackEnd="l">
              <Text>{getLocalizedValue(props.event.description)}</Text>
            </LayoutItem>
          </div>
        </div>
      </div>
    </GridListTile>
  );
};

export { EventTile };
