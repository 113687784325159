import { Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { LotTabsPanel } from './LotTabsPanel';
import { NewsIcon, Text, useIsTheme } from '@pp-labs/ui-components';
import clsx from 'clsx';
import { useLotState } from '../Provider/LotProvider';

const useStyles = makeStyles((theme) => {
  const isTheme = useIsTheme();
  return {
    wrapper: {
      background: isTheme.audi ? '#F1F1F1' : undefined,
    },
    newsIcon: {
      display: 'inline-block',
      marginRight: '8px',
    },
    title: {
      display: 'inline-block',
    },
    whiteText: {
      color: 'white',
    },
    tab: {
      minWidth: '130px',
      [theme.breakpoints.down('lg')]: {
        minWidth: '112px',
      },
    },
    greyBackground: {
      background: '#333333',
    },
    white: {
      background: '#ffffff',
    },
  };
});

type Id = 'chat' | 'poll' | string;
export type TabType = {
  label: string;
  id: Id;
  content: JSX.Element;
};

/** interface for LotTabs props coming from parent components LotBottomTabs, LotTabs and SideBar  */
interface P {
  tabs: TabType[];
  onChange?: (index: number) => void;
  sideBar?: boolean;
}

/** Tabs for an LOT */
const LotTabs = (props: P) => {
  const cls = useStyles();
  const lotState = useLotState();
  const [current, setCurrent] = React.useState<(TabType & { index: number }) | null>(
    props.tabs[0] ? { ...props.tabs[0], index: 0 } : null
  );
  const theme = useIsTheme();
  const audiSidebar = theme.audi && props.sideBar;
  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    const match = props.tabs.some((t) => t.id === current?.id);
    if (!match) {
      handleChange(null, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabs]);

  const tabTitle = (text: string, tab: TabType) => {
    const isActive = tab.id === current?.id;
    const title = (
      <Text
        variant="copy1"
        weight={isActive ? 'bold' : 'normal'}
        className={clsx(!isActive && !theme.neutralLight && audiSidebar && cls.whiteText)}
      >
        {text}
      </Text>
    );
    const chatChange = lotState.users.iAmTrainer
      ? lotState.chat.changeToChats.length > 0
      : lotState.chat.changeToChats.includes(lotState.breakoutRooms.myBreakoutRoom?.Id || 'main');
    return (
      <div>
        {!isActive &&
          ((tab.id === 'chat' && chatChange) ||
            (tab.id === 'poll' && lotState.polls.changeToPoll)) && (
            <div className={cls.newsIcon}>
              <NewsIcon />
            </div>
          )}
        <div className={cls.title}>{title}</div>
      </div>
    );
  };

  const handleChange = (_event: React.ChangeEvent<{}> | null, newValue: number) => {
    const prevTab = props.tabs.find((t) => t.id === current?.id);
    if (prevTab?.id === 'chat') {
      lotState.chat.resetChanges();
    } else if (prevTab?.id === 'poll') {
      lotState.polls.resetChanges();
    }
    setCurrent({ ...props.tabs[newValue], index: newValue });
    if (props.onChange) props.onChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} className={clsx(!props.sideBar && cls.wrapper)}>
      <Tabs
        value={current?.index || 0}
        onChange={handleChange}
        className={clsx(
          audiSidebar && cls.greyBackground,
          theme.audi && !audiSidebar && cls.white,
          theme.neutralDark ? 'black-tabs' : 'normal-tabs'
        )}
        aria-label={audiSidebar ? 'side tabs' : theme.audi ? 'bottom tabs' : 'neutral tabs'}
        textColor="primary"
      >
        {props.tabs.map((tab, index) => (
          <Tab
            className={cls.tab}
            key={index}
            label={tabTitle(tab.label, tab)}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {props.tabs.map((tab, index) => (
        <LotTabsPanel value={current?.index || 0} index={index}>
          {tab.content}
        </LotTabsPanel>
      ))}
    </Box>
  );
};

export { LotTabs };
