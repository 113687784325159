import React, { useState } from 'react';
import { FilterParams, useDashboardData } from './useDashboardData';
import AudiSpacer from 'utils/AudiSpacer';
import strings from 'Localization/Localizer';
import { BarDiagram } from './BarDiagram';
import ReactSelect from 'react-select';
import { TaggedDiagram, ViewsDataStructure } from './ViewsDiagrams';

/** interface for ResultsDiagrams props coming from parent component Dashboard */
interface P {
  filters: Omit<FilterParams, 'category'>;
}

export const CHART_COLORS = {
  black: 'rgb(0, 0, 0)',
};

interface SurveyTag {
  reportingTag: string;
  average: number;
  bySurveyItemTag: SurveyItemTag[];
}
interface SurveyItemTag {
  reportingTag: string;
  average: number;
}

/** Renders the Results page of the Dashboard */
export const ResultsDiagrams = ({ filters }: P) => {
  const data: { bySurveyTag: SurveyTag[]; prePostTests: ViewsDataStructure } = useDashboardData({
    category: 'results',
    ...filters,
  }).data;
  const [searchValue, setSearchValue] = useState<string[]>([]);

  return (
    <>
      <div style={{ padding: '64px', overflow: 'auto' }}>
        {data && data.bySurveyTag && (
          <>
            <ReactSelect
              placeholder={'Select surveys'}
              options={data.bySurveyTag.map((surveyTag) => {
                return { label: surveyTag.reportingTag, value: surveyTag.reportingTag };
              })}
              onChange={(e) => {
                setSearchValue([...e.map((v) => v.value.toString())]);
              }}
              value={searchValue?.map((v) => {
                return { label: v, value: v };
              })}
              isMulti={true}
            />
            <AudiSpacer spaceStackEnd="m" />
          </>
        )}

        {data &&
          data.bySurveyTag
            .filter((s) => searchValue.some((f) => s.reportingTag === f))
            .map((surveyTag: SurveyTag) => {
              return (
                <div>
                  <BarDiagram
                    title={surveyTag.reportingTag}
                    labels={[
                      strings.average,
                      ...surveyTag.bySurveyItemTag.map(
                        (entry: SurveyItemTag) => `${entry.reportingTag} ${strings.average}`
                      ),
                    ]}
                    items={[
                      surveyTag.average,
                      ...surveyTag.bySurveyItemTag.map((entry) => entry.average),
                    ]}
                    vertical={true}
                  />
                </div>
              );
            })}
        {data?.prePostTests && <TaggedDiagram value={data.prePostTests} key="prePostTests" />}
      </div>
    </>
  );
};
