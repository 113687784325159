import strings from '../../Localization/Localizer';
import { client } from '../../ApiHandler/client';
import React, { useMemo } from 'react';
import { allSurveyTypes, Context, SurveyProvider as SP } from '@pp-labs/survey';
import { useEventSettings, useNotify } from '../../utils/hooks';
import { ConfigModuleIdentifiers } from 'ApiHandler/dclxInterfaces';

/** assigns the required data to each survey */
export const SurveyProvider: React.FC = (props) => {
  const eventSettings = useEventSettings();
  const notify = useNotify();
  const surveyContexts = useMemo(() => {
    const c = ['overall', 'session', 'liveSession'] as Context[];
    if (
      eventSettings?.configModules?.map(
        (e) => e.moduleId === ConfigModuleIdentifiers.videoConferenceSessions
      )
    )
      c.push('lot');
    return c;
  }, [eventSettings]);

  return (
    <SP
      value={{
        strings: Object.assign(strings, {
          survey: 'survey',
        }),
        client: client,
        notify: notify,
        importerLogic: false,
        surveyContexts: surveyContexts,
        useTag: true,
        surveyTypes: allSurveyTypes,
      }}
    >
      {props.children}
    </SP>
  );
};
