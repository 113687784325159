import { createStyles, Theme } from '@material-ui/core';

export const blackStyle = (theme: Theme) =>
  createStyles({
    amaWrapper: {
      // backgroundColor: 'black',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 96px)',
        overflow: 'hidden',
        padding: '48px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '24px 34px',
      },
      marginLeft: 0,
      marginRight: 0,
      // transform: 'translateX(-1px)',
    },
    blackWrapper: {
      backgroundColor: 'black',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 96px)',
        overflow: 'hidden',
        padding: '48px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '24px 34px',
      },
      marginLeft: 0,
      marginRight: 0,
      transform: 'translateX(-1px)',
    },
    blackButton: {
      backgroundColor: 'white !important',
      color: 'black !important',
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
    whiteButton: {
      backgroundColor: 'black !important',
      color: 'white !important',
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
    button: {
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
    blackText: {
      color: 'white !important',
    },
    redText: {
      color: 'red !important',
    },
    redButton: {
      backgroundColor: '#F50537 !important',
      color: 'white !important',
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
    signalRedButton: {
      backgroundColor: '#EB0D3F !important', //from https://react.ui.audi/?path=/story/brand-identity-colors--page
      color: 'white !important',
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
    signalGreenButton: {
      backgroundColor: '#009900 !important', //from https://react.ui.audi/?path=/story/brand-identity-colors--page
      color: 'white !important',
      '&:disabled': {
        backgroundColor: '#AAAAAA !important',
        borderColor: '#AAAAAA !important',
      },
    },
  });

export interface BlackClasses {
  amaWrapper: string;
  blackWrapper: string;
  blackButton: string;
  button: string;
  blackText: string;
  redText: string;
  redButton: string;
}
