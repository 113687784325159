import React, { useMemo } from 'react';
import {
  Text,
  useIsTheme,
  UserIcon,
  makeThemedStyles,
  useThemedStyles,
} from '@pp-labs/ui-components';
import IsolatedSend from './IsolatedSend';
import { PrivateMessage } from '../LOT/WebsocketCommands';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { BaseBreakoutRoom, useLotState } from '../LOT/Provider/LotProvider';
import { ModHandle } from '../../../config';

const styles = makeThemedStyles((theme) => {
  return {
    supportContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    supportBody: {
      overflow: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column-reverse',
      border: '1px solid',
      padding: '12px',
      borderColor: ({ isTheme }) =>
        isTheme.audi ? '#000000' : isTheme.neutralLight ? '#909090' : '#FFFFFF !important',
    },
    chatText: {
      color: 'black',
      fontSize: '12px',
      lineHeight: '1.4',
    },
    chatTextNeutral: {
      color: 'white',
      fontSize: '12px',
      lineHeight: '1.4',
    },
    message: {
      margin: '8px 0',
    },
    sendWrapper: {
      marginTop: '16px',
    },
    mentionText: {
      color: 'red',
    },
  };
});

const useChatsStyles = makeStyles({
  chatBox: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  chatButton: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    backgroundColor: '#F1F1F1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: 4,
    position: 'relative',
  },
  whiteText: {
    color: 'white',
  },
  blackText: {
    color: 'black',
  },
  activeMain: {
    color: 'white',
    backgroundColor: 'black',
  },
  inactiveMain: {
    color: 'black',
    backgroundColor: 'white',
  },
  newIndicator: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: 'red',
    position: 'absolute',
    top: 4,
    right: 4,
  },
});

/** interface for LotChats props coming from parent component SideBar  */
interface P {
  messages: PrivateMessage[];
}

const useMainStyles = makeStyles((theme) => ({
  wrapper: {
    height: '70vh',
    [theme.breakpoints.down('xl')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '65vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '62vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
    minHeight: '500px',
  },
}));

/**
 * Chat component
 */

export const LotChats = () => {
  const cls = useMainStyles();
  return (
    <div className={cls.wrapper}>
      <LotChatSwitch />
    </div>
  );
};

export const LotChatSwitch = () => {
  const lotState = useLotState();
  if (lotState.type === 'live') return <LotChat messages={lotState.chat.messages} />;
  if (!lotState.users.iAmTrainer)
    return (
      <LotChat
        messages={lotState.chat.messages.filter((m) =>
          lotState.breakoutRooms.myBreakoutRoom
            ? m.BreakoutRoom === lotState.breakoutRooms.myBreakoutRoom.Id
            : m.BreakoutRoom === 'main'
        )}
      />
    );
  return <MultiChats />;
};

const MultiChats = () => {
  const cls = useChatsStyles();
  const lotsState = useLotState();
  const roomsWithMessage = useMemo(
    () =>
      lotsState.breakoutRooms.rooms.map((r) => ({
        ...r,
        messages: lotsState.chat.messages.filter((m) => m.BreakoutRoom === r.Id),
      })),
    [lotsState.breakoutRooms.rooms, lotsState.chat.messages]
  );
  const selectChat = (chat: 'main' | BaseBreakoutRoom) => {
    lotsState.chat.resetChanges();
    lotsState.chat.switchSelectedChat(chat);
  };

  const current = lotsState.chat.selectedChat;

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className={cls.chatBox}>
        <div
          className={clsx(cls.chatButton, current === 'main' ? cls.activeMain : cls.inactiveMain)}
          onClick={() => selectChat('main')}
        >
          {lotsState.chat.changeToChats.includes('main') && current !== 'main' && (
            <div className={cls.newIndicator} />
          )}
          <UserIcon />
        </div>
        {roomsWithMessage.map((r, i) => {
          const isActive = current !== 'main' && r.Id === current.Id;
          return (
            <div
              className={cls.chatButton}
              onClick={() => selectChat(r)}
              style={isActive ? { backgroundColor: 'black' } : undefined}
              title={r.Title}
              key={r.Id}
            >
              {lotsState.chat.changeToChats.includes(r.Id) && !isActive && (
                <div className={cls.newIndicator} />
              )}
              <Text as="span" className={clsx(isActive ? cls.whiteText : cls.blackText)}>
                {i + 1}
              </Text>
            </div>
          );
        })}
      </div>
      <div style={{ flex: 1, minHeight: 0 }}>
        <LotChat
          messages={
            current === 'main'
              ? lotsState.chat.messages.filter((m) => m.BreakoutRoom === 'main')
              : roomsWithMessage.find((r) => r.Id === current.Id)?.messages || []
          }
        />
      </div>
    </div>
  );
};

export const LotChat = (props: P) => {
  const lotState = useLotState();
  const cls = useThemedStyles(styles);
  const theme = useIsTheme();
  return (
    <>
      <div className={cls.supportContainer}>
        <div
          className={`${cls.supportBody} no-scrollbar`}
          style={{ borderColor: theme.audi ? '#000000' : '#909090' }}
        >
          {props.messages.map((message, i) => {
            const p = lotState.users.allUsers.find((pEntry) => pEntry.username === message.UserId);
            const split = message.Message.split(ModHandle);
            return (
              <div className={cls.message} key={i}>
                <Text as="p" variant="copy1" className={clsx(theme.audi && cls.chatText)}>
                  <b>
                    {p?.givenName} {p?.familyName}:{' '}
                  </b>
                  {split[0]}
                  {split.length > 1 && (
                    <Text as="span" className={clsx(theme.audi && cls.chatText, cls.mentionText)}>
                      {ModHandle}
                    </Text>
                  )}
                  {split[1]}
                </Text>
              </div>
            );
          })}
        </div>
        <div className={cls.sendWrapper}>
          <IsolatedSend />
        </div>
      </div>
    </>
  );
};
