import { UserPicture } from '../../../ApiHandler/dclxInterfaces';
import { useEffect, useState } from 'react';
import { client } from '../../../ApiHandler/client';
import { LabelButton } from '@pp-labs/ui-components';
import { useLotState } from './Provider/LotProvider';
import strings from '../../../Localization/Localizer';
import { format } from 'date-fns';
import { standardFormatWithSeconds } from '../../../config';

const NewLine = '\n';
const NewCell = ';';
const Escape = '';

const download = (content: string, filename: string) => {
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  link.click();
};

export const parseCsv = (data: unknown[][]) =>
  'data:text/csv;charset=utf-8,' +
  data.map((e) => e.map((v) => Escape + v + Escape).join(NewCell)).join(NewLine);

const header = ['Name', 'Message'];

/** Exports the Chat History */
export const ExportChat = () => {
  const lotState = useLotState();
  const [users, setUsers] = useState<UserPicture[]>([]);
  useEffect(() => setUsers(lotState.users.allUsers), [lotState.users.allUsers]);
  const loadMissingUsers = async () => {
    const missingSubs = lotState.chat.messages
      .filter((m) => !users.some((u) => u.username === m.UserId))
      .map((m) => m.UserId);
    const uniqueSubs = [...new Set(missingSubs)];
    const newUsers: UserPicture[] = await Promise.all(
      uniqueSubs.map(async (sub) => (await client.get(`users/name/${sub}`)).data)
    );
    return [...users, ...newUsers];
  };

  const generateData = async () => {
    const completeUsers = await loadMissingUsers();
    const messages = lotState.chat.messages.slice();
    messages.reverse();
    return messages.map((m) => {
      const user = completeUsers.find((u) => u.username === m.UserId);
      const breakout = lotState.breakoutRooms.roomsWithDel.find((r) => r.Id === m.BreakoutRoom);
      return [
        `${user?.givenName} ${user?.familyName}`,
        m.Message,
        breakout?.Title || strings.breakoutMain,
        breakout?.Id || 'main',
        format(m.Timestamp, standardFormatWithSeconds),
      ];
    });
  };

  const downloadCSV = async () => {
    const data = await generateData();
    const csv = parseCsv([header, ...data]);
    download(csv, 'chat.csv');
  };

  return (
    <LabelButton variant="secondary" onClick={downloadCSV}>
      Download Chat
    </LabelButton>
  );
};
