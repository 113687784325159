import strings, { ElementType } from '../../Localization/Localizer';
import { BaseStrings } from '../../Localization/BaseStrings';
import { ApiText } from '../../ApiHandler/dclxInterfaces';
import { CmsText } from './TextCms';

type Text = {
  type: 'normal' | 'editor';
  key: TextKey;
};

/** List of all texts of the platform */
export const allTexts: Text[] = [
  {
    type: 'editor',
    key: 'startPageDisclaimer',
  },
  {
    type: 'editor',
    key: 'onDemandDisclaimer',
  },
  {
    type: 'editor',
    key: 'liveDisclaimer',
  },
  {
    type: 'editor',
    key: 'lotDisclaimer',
  },
  {
    type: 'editor',
    key: 'countdownTitle',
  },
  {
    type: 'editor',
    key: 'countdownDescription',
  },
  {
    type: 'normal',
    key: 'firstStepsTitle',
  },
  {
    type: 'normal',
    key: 'firstStepsDescription',
  },
  {
    type: 'normal',
    key: 'onDemandCta',
  },
];

export const allTextKeys = [
  'startPageDisclaimer',
  'onDemandDisclaimer',
  'liveDisclaimer',
  'lotDisclaimer',
  'countdownTitle',
  'countdownDescription',
  'firstStepsTitle',
  'firstStepsDescription',
  'onDemandCta',
] as const;

export type TextKey = ElementType<typeof allTextKeys>;

export const getString = (type: 'description' | 'default', key: TextKey) =>
  strings[`${type}${key.charAt(0).toUpperCase() + key.slice(1)}` as BaseStrings] || null;
export const getCompleteTexts = (apiTexts: ApiText[]): CmsText[] =>
  allTextKeys.map((key) => {
    const apiText = apiTexts.find((t) => t.textKey === key);
    const localText = allTexts.find((t) => t.key === key);
    return {
      key: key,
      text: apiText?.textString || null,
      defaultText: getString('default', key),
      descriptionText: getString('description', key),
      isActive: !!apiText?.isActive,
      type: localText?.type || 'normal',
    };
  });
