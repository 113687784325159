import { createContext, FC } from 'react';
import { useInnerProgression, UseProgressionReturn } from '../../progression';

/** interface for stateContext component state  */
interface State {
  progression: UseProgressionReturn;
}

export const stateContext = createContext<State>({
  progression: 'loading',
});

/** Provides global state. Currently only progression, but can be expanded */
export const StateProvider: FC = (props) => {
  const progression = useInnerProgression();
  return (
    <stateContext.Provider
      value={{
        progression: progression,
      }}
    >
      {props.children}
    </stateContext.Provider>
  );
};
