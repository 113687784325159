import React from 'react';
import { HorizontalBar } from '../../../features/BarChart/HorizontalBar';
import { useIsTheme } from '@pp-labs/ui-components';
type Key = 'likes' | 'dislikes' | 'A' | 'B' | 'C';
/** interface for LikeDislike props coming from parent components ABCVote and ThumbsVote  */
interface P {
  name: Key;
  likes: number;
  maxLikes: number;
  maxWidth: number;
}

/** Diagram bar for interactions */
export const LikeDislike = (props: P) => {
  const percentage = props.likes / props.maxLikes;
  const theme = useIsTheme();
  return (
    <div
      style={{
        width: `${props.maxWidth}px`,
        height: '40px',
        display: 'inline',
        position: 'relative',
      }}
    >
      <svg width={props.maxWidth} height={15}>
        <g clipPath="url(#barClip)">
          <HorizontalBar
            key={props.name}
            x={0}
            y={7.5}
            height={15}
            width={props.maxWidth * percentage - 15 - props.likes.toString().length * 8}
            color={'#333333'}
            textColor={theme.neutralDark ? 'white' : 'black'}
            textOutside={props.likes.toString()}
          />
        </g>
      </svg>
    </div>
  );
};
