import React, { useEffect, useMemo, useState } from 'react';
import { EditLevel, RawAgenda, Session, TimeSlot } from '../../ApiHandler/dclxInterfaces';
import { client } from '../../ApiHandler/client';
import { Form, Formik } from 'formik';
import { Select, LabelButton, TextField, LocalizedForm } from '@pp-labs/ui-components';
import AudiSpacer from 'utils/AudiSpacer';
import strings, { useLocalizedLanguages } from 'Localization/Localizer';
import { byDate } from 'utils/sort';
import { Banner, BannerConfiguration, BannerType } from 'DCLX/FE/Startpages/Banner';
import * as Yup from 'yup';
import { useNotify, useCMSChannel, useLoad } from 'utils/hooks';
import { Grid } from '@material-ui/core';
import { Text } from '@pp-labs/ui-components';
import _ from 'lodash';
import { SelectMediaButton } from './Documents/SelectMediaButton';
import { parseAgenda } from 'utils/convert';

/** interface for DCLXBanner props coming from parent component CMS */
interface P {
  level: EditLevel;
}
const validation = Yup.object({
  state: Yup.string().required(strings.required),
}).required(strings.required);

/**
 * Wrapper for Banner Management
 */
const DCLXBanner = (props: P) => {
  const notify = useNotify();
  const selectedChannel = useCMSChannel()!;
  const languages = useLocalizedLanguages([selectedChannel]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [timeslots, setTimeslots] = useState<TimeSlot[]>([]);
  const [agendi] = useLoad<RawAgenda[]>('agenda');
  const parsedAgendi = useMemo(
    () =>
      agendi?.filter((a) => a.channelId === selectedChannel.channelId).map((a) => parseAgenda(a)),
    [agendi, selectedChannel]
  );

  const [initialBannerConfiguration, setInitialBannerConfiguration] =
    useState<BannerConfiguration | null>(null);

  const [currentBannerConfiguration, setCurrentBannerConfiguration] =
    useState<BannerConfiguration | null>(null);

  const fetch = async () => {
    try {
      const bcs: BannerConfiguration[] = (await client.get('banner')).data;
      const bc: BannerConfiguration | undefined = bcs.find(
        (bcEntry) => bcEntry.channelId === selectedChannel.channelId
      );
      if (bc) {
        setCurrentBannerConfiguration(_.cloneDeep(bc));
        setInitialBannerConfiguration(bc);
      } else {
        setInitialBannerConfiguration({ state: BannerType.off, title: '' });
      }
    } catch (error) {
      setInitialBannerConfiguration({ state: BannerType.off, title: '' });
    }
    const data = (await client.get('sessions')).data;

    setSessions(data.filter((s: Session) => s.adapted));
    const ts = (await client.get('trainings/bookings')).data.sort(byDate);
    setTimeslots(ts);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel]);

  const submitConfig = async (v: BannerConfiguration) => {
    client
      .post('banner', {
        imageId: v.image?.mediaId,
        state: v.state,
        title: JSON.stringify(v.title),
        channelId: selectedChannel.channelId,
      })
      .then(() => {
        notify('success', 'success');
        fetch();
      })
      .catch(() => {
        notify('error', 'error');
      });
  };

  return (
    <>
      <div>
        {/* Banner editing */}

        {initialBannerConfiguration && languages.length > 0 && (
          <div>
            <Formik
              initialValues={initialBannerConfiguration}
              onSubmit={submitConfig}
              validationSchema={validation}
            >
              {({ errors, touched, values, setFieldValue }) => {
                return (
                  <>
                    <Text variant={'order2'} weight={'bold'}>
                      Feature Banner
                    </Text>
                    <Grid container spacing={8}>
                      {currentBannerConfiguration && (
                        <Grid item xs={6}>
                          <Text as="h3" variant="order4">
                            <b>{strings.current}</b>
                          </Text>
                          <Banner
                            bannerConfiguration={currentBannerConfiguration}
                            timeslots={timeslots}
                            sessions={sessions}
                            agendas={parsedAgendi}
                          ></Banner>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Text as="h3" variant="order4">
                          <b>{strings.new}</b>
                        </Text>
                        <Banner
                          bannerConfiguration={values}
                          timeslots={timeslots}
                          sessions={sessions}
                          agendas={parsedAgendi}
                        ></Banner>
                      </Grid>
                    </Grid>
                    <AudiSpacer spaceStackStart={'l'} />
                    <Form>
                      <Select label="Setting" name="state" error={errors.state}>
                        <option value="off">{strings.noBanner}</option>
                        <option value="static">{strings.staticImage}</option>
                        <option value="dynamic">{strings.featureEvents}</option>
                      </Select>
                      <AudiSpacer spaceStackEnd={'l'} />
                      <div>
                        <SelectMediaButton
                          type="image"
                          variant="light"
                          name="image"
                          label={strings.staticImage}
                          prevUrl={values.image?.url}
                          prevName={values.image?.originalFile || strings.staticImage}
                          imgDimensions="3000 x 1200 px"
                        />
                      </div>
                      <AudiSpacer spaceStackEnd={'l'} />
                      <LocalizedForm
                        initialValues={{ title: initialBannerConfiguration.title }}
                        languages={languages}
                        localizeInputs={['title']}
                        autoParse={[]}
                      >
                        <TextField name={'title'} label={strings.title} />
                      </LocalizedForm>

                      <AudiSpacer spaceStackEnd={'l'} />
                      <LabelButton variant="primary" type="submit">
                        {strings.submit}
                      </LabelButton>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default DCLXBanner;
