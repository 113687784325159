import React, { useMemo, useState } from 'react';
import {
  EditDialog,
  LabelButton,
  makeThemedStyles,
  Text,
  useThemedStyles,
} from '@pp-labs/ui-components';
import strings from '../Localization/Localizer';
import { useEventLanguages, useEventSettings } from 'utils/hooks';
import { MultiLanguage } from 'ApiHandler/dclxInterfaces';

const style = makeThemedStyles(() => {
  return {
    emailText: {
      color: ({ colorScheme }) => colorScheme.text,
      fontFamily: 'inherit',
    },
    emailButton: {
      margin: 0,
      color: '#333333 !Important',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '10px',
    },
    loginText: {
      fontSize: ({ isTheme }) => (isTheme.neutral ? '16px !Important' : '12px !Important'),
    },
  };
});
/**
 * Support E-Mail Component, Pre-Login
 */

export const SupportEmail = () => {
  const eventSettings = useEventSettings();
  const supportEmail = eventSettings?.supportEmail;
  const eventLangs = useEventLanguages();
  const [open, setOpen] = useState<boolean>(false);
  const cls = useThemedStyles(style);

  const email = useMemo(() => {
    if (supportEmail) {
      try {
        const mails = (JSON.parse(supportEmail) as MultiLanguage<string>[]).filter((e) => e.value);
        const filteredMails = mails.filter((e) => eventLangs.find((l) => l.code === e.lang));
        return filteredMails;
      } catch {}
    }
  }, [supportEmail, eventLangs]);

  const handleOpen = () => {
    if (!email) return;
    if (email?.length === 1) {
      const a = document.createElement('a');
      a.href = `mailto:${email[0].value}`;
      a.click();
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <div>
        <Text as="p" variant="copy3" className={cls.loginText}>
          {strings.problemsLoginContactSupport}
        </Text>
        <LabelButton size="small" variant="text" onClick={handleOpen}>
          {strings.selectEmail}
        </LabelButton>
      </div>
      {open && (
        <EditDialog title={strings.listOfEmails} close={() => setOpen(false)} maxWidth="sm">
          {email ? (
            email?.map((m, i) => (
              <Text as="p" variant="copy1" key={i}>
                {m.lang}:{' '}
                <a href={`mailto:${m.value}`} className={cls.emailText}>
                  {m.value}
                </a>
              </Text>
            ))
          ) : (
            <Text as="p" variant="copy1">
              {strings.supportUnavailable}
            </Text>
          )}
        </EditDialog>
      )}
    </>
  );
};
