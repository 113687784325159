import React from 'react';
import { Session, SessionType } from '../../../../ApiHandler/dclxInterfaces';
import { TableRow } from '@material-ui/core';
import { NativeCheckbox } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell } from '@pp-labs/ui-components';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { Text } from '@pp-labs/ui-components';
import { useCurrentPageChannelId } from 'utils/hooks';

/** interface for MediaSubform props coming from parent component DCLXCmsSessionEdit */
interface P {
  type: SessionType;
  changeRef: Function;
  refs?: Session[];
  selectedRefs: Array<boolean>;
  headline: string;
}

const useStyles = makeStyles({
  checkboxWrapper: {
    padding: '3px 12px',
  },
});

/** Subform to reference other sessions within a session. */
const SessionRefSubform = (props: P) => {
  const cls = useStyles();
  const currentPageId = useCurrentPageChannelId();
  const getCheckboxes = () => {
    if (!props.refs) return null;
    return props.refs
      .filter((r) => r.channelId === currentPageId)
      .map((session, index) => {
        return (
          <div key={`ref-${session.sessionId}`} className={cls.checkboxWrapper}>
            <NativeCheckbox
              inputId={`ref-${session.sessionId}`}
              onChange={() => props.changeRef(index)}
              checked={props.selectedRefs[index]}
            >
              {getLocalizedValue(session.title)}
            </NativeCheckbox>
          </div>
        );
      });
  };

  if (props.type !== SessionType.TRAINING) return null;
  return (
    <>
      <TableRow key="refs">
        <TableCell>
          <Text as="h3" variant="order4" className={props.headline}>
            <b>{strings.addSession}</b>
          </Text>
          {getCheckboxes()}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SessionRefSubform;
