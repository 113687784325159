import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import strings, { allLangs, defaultLanguage, switchLangBrowser } from 'Localization/Localizer';
import { Channel, CognitoUser, Language, PlatformEvent } from './ApiHandler/dclxInterfaces';

export interface AppState {
  user: CognitoUser | null;
  fullscreen: boolean;
  eventSetting: PlatformEvent | null;
  language: Language;
  cmsChannel: Channel | null;
}

const initialState: AppState = {
  user: null,
  fullscreen: false,
  eventSetting: null,
  language: defaultLanguage,
  cmsChannel: null,
};

export const AppSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setUser: (state: Draft<AppState>, action: PayloadAction<CognitoUser | null>) => {
      state.user = action.payload;
    },
    enterFullscreen: (state: Draft<AppState>) => {
      state.fullscreen = true;
    },
    exitFullscreen: (state: Draft<AppState>) => {
      state.fullscreen = false;
    },
    setEventSetting: (state: Draft<AppState>, action: PayloadAction<PlatformEvent | null>) => {
      state.eventSetting = action.payload;
    },
    setLanguage: (state: Draft<AppState>, action: PayloadAction<Language>) => {
      if (action?.payload?.code) {
        strings.setLanguage(action.payload.code); // refreshes on select
        state.language = action.payload;
      } else {
        const defaultLang = switchLangBrowser();
        strings.setLanguage(defaultLang);
        const lang = allLangs.find((l) => (l.name as string).includes(defaultLang));
        if (lang) {
          state.language = lang;
        }
      }
    },
    setCMSChannel: (state: Draft<AppState>, action: PayloadAction<Channel | undefined>) => {
      state.cmsChannel = action.payload || null;
    },
  },
});

export const {
  setUser,
  enterFullscreen,
  exitFullscreen,
  setCMSChannel,
  setLanguage,
  setEventSetting,
} = AppSlice.actions;

export default AppSlice.reducer;
