import { DatePicker } from '@audi/audi-ui-react';
import { DateValue } from '@audi/audi-ui-react/dist/types/components/DatePicker/DatePicker.types';
import { FormikCheckbox, LabelButton, Layout, Select } from '@pp-labs/ui-components';
import { Form, Formik } from 'formik';
import strings, { getLocalizedValue } from 'Localization/Localizer';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import AudiSpacer from 'utils/AudiSpacer';
import { useProgressionChannels } from 'utils/hooks';
import { FilterParams } from './useDashboardData';

/** interface for DashboardFilter props coming from parent component Dashboard */
export interface P {
  category: 'participants' | 'views' | 'results';
  onSubmit: (filters: FilterParams) => void;
}

interface F {
  channelId: string | 'all';
  from: DateValue;
  to: DateValue;
  filterDate?: boolean;
}

export const DashboardFilter = (props: P) => {
  const channels = useProgressionChannels();
  const initialValues = {
    channelId: 'all',
    from: new Date('2022-01-06'),
    to: new Date(),
    filterDate: true,
  };
  const [filterDate, setFilterDate] = useState<boolean>(false);
  useEffect(() => {
    const from =
      initialValues.from && filterDate ? format(initialValues.from, 'yyyy-MM-dd') : undefined;
    const to = initialValues.to && filterDate ? format(initialValues.to, 'yyyy-MM-dd') : undefined;
    props.onSubmit({
      channels:
        initialValues.channelId === 'all'
          ? channels.map((c) => c.channelId)
          : [parseInt(initialValues.channelId)],
      from: from,
      to: to,
    });
    setFilterDate(initialValues.filterDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AudiSpacer spaceStackEnd="l" />
      <Formik
        initialValues={initialValues}
        onSubmit={(v: F) => {
          const from = v.from && filterDate ? format(v.from, 'yyyy-MM-dd') : undefined;
          const to = v.to && filterDate ? format(v.to, 'yyyy-MM-dd') : undefined;
          props.onSubmit({
            channels:
              v.channelId === 'all' ? channels.map((c) => c.channelId) : [parseInt(v.channelId)],
            from: from,
            to: to,
          });
        }}
      >
        {({ values, setFieldValue }) => {
          if (values.filterDate !== filterDate) setFilterDate(values.filterDate);
          return (
            <Form>
              <Layout wrap="wrap">
                <div style={{ padding: 5 }}>
                  <Select name={'channelId'} label={strings.channels} required>
                    <option value={'all'}>All</option>
                    {channels.map((c) => (
                      <option value={c.channelId} key={c.channelId}>
                        {getLocalizedValue(c.channelName)}
                      </option>
                    ))}
                  </Select>
                </div>

                <div style={{ padding: 5, paddingTop: 28, paddingBottom: 0 }}>
                  <FormikCheckbox name="filterDate" label={'filter time range'} />
                </div>

                <div style={{ padding: 5 }}>
                  <DatePicker
                    label={strings.periodFrom}
                    inputId={'from'}
                    disabled={!filterDate}
                    value={values.from}
                    onChange={(v) => setFieldValue('from', v)}
                  ></DatePicker>
                </div>
                <div style={{ padding: 5 }}>
                  <DatePicker
                    label={strings.periodUntil}
                    inputId={'to'}
                    disabled={!filterDate}
                    value={values.to}
                    onChange={(v) => setFieldValue('to', v)}
                  ></DatePicker>
                </div>

                <div style={{ padding: 5 }}>
                  <LabelButton variant="primary" type="submit">
                    {strings.search}
                  </LabelButton>
                </div>
              </Layout>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
