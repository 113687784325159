import { Text } from '@pp-labs/ui-components';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { Language } from '../../ApiHandler/dclxInterfaces';
import { HTMLAttributeAnchorTarget, useMemo } from 'react';
import { getLocalizedValue } from '../../Localization/Localizer';
import { makeStyles } from '@material-ui/styles';
import { appUrl } from '../../config';
import { TypographyVariantLabels } from '@audi/audi-ui-react';
import clsx from 'clsx';

type EditorParserProps = {
  /** Can be a language json or raw input */
  inputString: string;
  /** Forces a specific language */
  language?: Language;
  enforceBlackText?: boolean;
  fontVariant?: TypographyVariantLabels;
};

const useStyles = makeStyles({
  renderedContent: {
    '& td': {
      border: '1px solid black',
      padding: '8px',
    },
  },
  customLink: {
    color: 'black !important',
  },
});

export const getSimpleText = (input: string) => {
  // var plainString = input.replace(/<[^>]+>/g, '');
  // return plainString;
  var divContainer = document.createElement('div');
  divContainer.innerHTML = input;
  return divContainer.textContent || divContainer.innerText || '';
};

/** Used to render the Editor HTML */
export const EditorParser = (props: EditorParserProps) => {
  const options = useMemo<HTMLReactParserOptions>(() => {
    return {
      replace: (domNode) => {
        if (domNode.type !== 'tag' || !(domNode instanceof Element)) return;
        if (domNode.name === 'p') {
          return (
            <Text as="p" black={props.enforceBlackText} variant={props.fontVariant || 'copy1'}>
              {domToReact(domNode.children, options)}
            </Text>
          );
        }
        if (domNode.name === 'span') {
          return <Text as="span">{domToReact(domNode.children, options)}</Text>;
        }
        if (domNode.name === 'h2') {
          return (
            <Text as="h2" variant="order2" black={props.enforceBlackText}>
              {domToReact(domNode.children, options)}
            </Text>
          );
        }
        if (domNode.name === 'h3') {
          return (
            <Text as="h3" variant="order3" black={props.enforceBlackText}>
              {domToReact(domNode.children, options)}
            </Text>
          );
        }
        if (domNode.name === 'h4') {
          return (
            <Text as="h4" variant="order4" black={props.enforceBlackText}>
              {domToReact(domNode.children, options)}
            </Text>
          );
        }
        if (domNode.name === 'a') {
          const { href } = domNode.attribs;
          // opens internal link in the current window, externals in a new tab
          const target: HTMLAttributeAnchorTarget = href.includes(appUrl) ? '_self' : '_blank';
          return (
            <a target={target} {...domNode.attribs} className={cls.customLink}>
              {domToReact(domNode.children, options)}
            </a>
          );
        }
      },
    };
    // eslint-disable-next-line
  }, [props.enforceBlackText, props.fontVariant]);
  const cls = useStyles();
  const localized = useMemo(
    () => getLocalizedValue(props.inputString, props.language),
    [props.inputString, props.language]
  );
  return (
    <div className={clsx(cls.renderedContent, 'editor')}>
      <Text>{parse(localized, options)}</Text>
    </div>
  );
};
