import { useEffect, useMemo, useState } from 'react';
import { ApiText } from '../../ApiHandler/dclxInterfaces';
import { client } from '../../ApiHandler/client';
import { getCompleteTexts, TextKey } from './texts';
import { IconButton, makeStyles } from '@material-ui/core';
import { EditIcon, EraseIcon, Text } from '@pp-labs/ui-components';
import { EditText } from './EditText';
import { EditorParser } from '../Editor/EditorParser';
import { useNotify } from 'utils/hooks';
import strings from 'Localization/Localizer';

const useStyles = makeStyles({
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F1F1F1',
    alignItems: 'center',
    margin: 8,
    padding: 24,
  },
});

export type CmsText = {
  key: TextKey;
  text: string | null;
  defaultText: string | null;
  descriptionText: string | null;
  isActive: boolean;
  type: 'normal' | 'editor';
};

/** Allows users to administer Texts */
export const TextCms = () => {
  const cls = useStyles();
  const notify = useNotify();
  const [apiTexts, setApiTexts] = useState<ApiText[]>([]);
  const [editText, setEditText] = useState<CmsText | null>(null);
  const load = async () => {
    setApiTexts((await client.get('texts')).data);
  };
  useEffect(() => {
    load();
  }, []);

  const texts = useMemo(() => getCompleteTexts(apiTexts), [apiTexts]);

  const close = async (refresh?: boolean) => {
    if (refresh) await load();
    setEditText(null);
  };

  const handelRemove = async (data: CmsText) => {
    const payload = {
      textKey: data.key,
      textString: '',
    };
    await client.post('texts', [payload]);
    notify(strings.deleted, 'success');
    load();
  };

  return (
    <div>
      {texts.map((t, i) => {
        return (
          <div className={cls.textRow} key={i}>
            <div style={{ width: '20%' }}>
              <Text variant="copy2">{t.key}</Text>
            </div>
            <div style={{ width: '30%' }}>{t.descriptionText}</div>
            <div style={{ width: '40%' }}>
              {<EditorParser inputString={t.text || t.defaultText || ''} />}
            </div>
            <div style={{ width: '10%' }}>
              <IconButton style={{ color: 'black' }} onClick={() => setEditText(t)}>
                <EditIcon />
              </IconButton>
              <IconButton style={{ color: 'black' }} onClick={() => handelRemove(t)}>
                <EraseIcon />
              </IconButton>
            </div>
          </div>
        );
      })}
      {editText && <EditText close={close} prevText={editText} />}
    </div>
  );
};
