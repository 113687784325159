import { EditDialog, LabelButton } from '@pp-labs/ui-components';
import { Text } from '@pp-labs/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import strings from 'Localization/Localizer';
import { CameraIcon, CameraMutedIcon } from 'icons';

export type Join = 'muted' | 'video';

/** interface for JoinPopup props coming from parent component Lot */
interface P {
  join: (j: Join) => void;
}

const useStyles = makeStyles(() => ({
  btn: {
    display: 'inline-block',
    margin: '8px',
  },
  container: {
    display: 'flex',
    alignItems: 'initial',
    color: 'inherit',
  },
  cameraIcon: {
    width: '35px',
    paddingRight: '13px',
  },
  audioIcon: {
    width: '33px',
    paddingRight: '3px',
  },
}));

/** Dialogue asking the user how they want to join the LOT */
export const JoinPopup = (props: P) => {
  const cls = useStyles();
  return (
    <EditDialog title={strings.lotJoinTitle || ''} close={null} autoAdaptWidth>
      <Text>{strings.lotJoinSubTitle}</Text>
      <div className={cls.btn}>
        <LabelButton variant="secondary" onClick={() => props.join('muted')}>
          <div className={cls.container}>
            <span style={{ marginRight: '6px' }}>
              <CameraMutedIcon />
            </span>
            {strings.lotMuted}
          </div>
        </LabelButton>
      </div>
      <div className={cls.btn}>
        <LabelButton variant="secondary" onClick={() => props.join('video')}>
          <div className={cls.container}>
            <span style={{ marginRight: '6px' }}>
              <CameraIcon />
            </span>
            {strings.lotVideoAudio}
          </div>
        </LabelButton>
      </div>
    </EditDialog>
  );
};
