import { LabelButton } from '@pp-labs/ui-components';
import { useLotState } from './Provider/LotProvider';
import { setStreamCommand } from './lotCommands';

export const SwitchStream = () => {
  const lotState = useLotState();

  const getCurrentIndex = () => {
    const urls =
      lotState.type === 'lot'
        ? lotState.general.training?.playbackUrls
        : lotState.general.session?.playbackUrls;
    return urls?.findIndex((u) => u === lotState.general.streamUrl);
  };

  return (
    <LabelButton
      variant="secondary"
      spaceStackEnd="l"
      spaceInlineEnd="l"
      onClick={() => {
        const newValue = getCurrentIndex() ? 0 : 1;
        lotState.websocket.send(setStreamCommand(lotState.general.roomId, newValue));
      }}
    >
      {`Switch to ${getCurrentIndex() ? 'Main' : 'Backup'} Stream`}
    </LabelButton>
  );
};
