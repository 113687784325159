import React, { FC } from 'react';
import { useIsTheme } from '@pp-labs/ui-components';
import { LayoutItem as AudiLayoutItem, LayoutItemProps } from '@audi/audi-ui-react';
import { SpacingLabel } from '@audi/audi-ui-react/dist/types/themes';
import { SpacingShorthandList } from '@audi/audi-ui-react/dist/types/interfaces/layoutProps';

/** interface for LayoutItem props coming from parent components(used at multiple locations) */
interface P extends LayoutItemProps {
  neutralSpacing?: SpacingShorthandList;
  neutralSpaceStackStart?: SpacingLabel;
  neutralSpaceInlineEnd?: SpacingLabel;
  neutralSpaceStackEnd?: SpacingLabel;
  neutralSpaceInlineStart?: SpacingLabel;
}

export const LayoutItem: FC<P> = (props) => {
  const theme = useIsTheme();
  if (
    theme.neutral &&
    (props.neutralSpacing ||
      props.neutralSpaceStackStart ||
      props.neutralSpaceInlineEnd ||
      props.neutralSpaceStackEnd ||
      props.neutralSpaceInlineStart)
  ) {
    return (
      <AudiLayoutItem
        spacing={
          props.neutralSpacing || [
            props.neutralSpaceStackStart,
            props.neutralSpaceInlineEnd,
            props.neutralSpaceStackEnd,
            props.neutralSpaceInlineStart,
          ]
        }
      >
        {props.children}
      </AudiLayoutItem>
    );
  } else {
    return <AudiLayoutItem {...props}>{props.children}</AudiLayoutItem>;
  }
};
