import React, { useState } from 'react';
import JitsiFrame from './JitsiFrame';
import VideoForm from './VideoForm';
import { Text } from '@pp-labs/ui-components';
import StepIndicator from '../../utils/StepIndicator';
import './TechTest.scss';
import { Theme } from '@material-ui/core';
import { gutterStyle } from '../../utils/gutter';
import { makeStyles } from '@material-ui/core/styles';
import strings from '../../Localization/Localizer';
import { baseUrl } from '../../config';
import ExternalVideoForm from './ExternalVideoForm';

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const room = 'room_' + getRandomInt(10, 10000);

const useStyles = makeStyles((theme: Theme) => ({
  ...gutterStyle(theme),
  text: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '67%',
    },
  },
}));

/** interface for TechTest props coming from parent components Login, FAQ, TechTestSupport, TechTestRoute */
interface P {
  onFinish: () => void;
  external: boolean;
  hideSteps: boolean;
}

/**
 * Tech Test for Jitsi/LOT Technology
 */

const TechTest = (props: P) => {
  const [jitsiLoaded, setJitsiLoaded] = useState<number>(0);
  const classes = useStyles();
  const setLoaded = () => {
    setJitsiLoaded(jitsiLoaded + 1);
  };
  return (
    <div>
      {!props.external && !props.hideSteps && (
        <>
          <StepIndicator step={5} numSteps={6} />
          <Text as="h2" variant="order3" weight={'bold'}>
            {strings.registration}
          </Text>
        </>
      )}
      <div style={{ padding: '32px 0' }} />
      <Text as="h3" variant={props.external ? 'order3' : 'order4'} className="header-text">
        {strings.techTest}
      </Text>
      <div style={{ padding: '20px 0' }} />
      <div className={classes.text}>
        <Text as="span" variant="copy1">
          {strings.techTestText1}
        </Text>
      </div>
      <div style={{ padding: '10px 0' }} />
      <div className="content">
        <div className="jitsi">
          <div
            className="Jitsi-container"
            style={{ display: 'flex', width: '100%', margin: '0 -10px' }}
          >
            <JitsiFrame
              container="meet1"
              room={room}
              username={strings.techTest}
              wait={0}
              setLoaded={setLoaded}
              countLoaded={jitsiLoaded}
            />
            {jitsiLoaded === 1 && (
              <>
                <JitsiFrame
                  container="meet2"
                  room={room}
                  username={strings.techTest}
                  wait={1500}
                  setLoaded={setLoaded}
                  countLoaded={jitsiLoaded}
                  hidden
                />
                <JitsiFrame
                  container="meet3"
                  room={room}
                  username={strings.techTest}
                  wait={3000}
                  setLoaded={setLoaded}
                  countLoaded={jitsiLoaded}
                  hidden
                />
              </>
            )}
          </div>
          <div className={classes.text} style={{ paddingTop: 54 }}>
            <Text as="span" variant="copy1">
              {strings.techTestText2} &nbsp;
            </Text>
            <div style={{ padding: '10px 0' }} />
            <Text as="span" variant="copy1">
              <a href={`${baseUrl}/techTest`} target="_blank" rel="noreferrer">
                {strings.techTest}
              </a>
              &nbsp;
            </Text>
            <div style={{ padding: '23px 0' }} />
            <Text as="span" variant="copy1">
              {strings.techTestText3}
              &nbsp;
            </Text>
          </div>
        </div>
        <div style={{ padding: '20px 0' }} />
        <div className="video-form-container">
          <div className={classes.text}>
            {props.external ? (
              <ExternalVideoForm onFinish={props.onFinish} />
            ) : (
              <VideoForm onFinish={props.onFinish} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechTest;
