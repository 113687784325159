import React, { useState } from 'react';
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import {
  EditDialog,
  EraseIcon,
  FormGroup,
  LabelButton,
  LocalizedForm,
  Radio,
  TableCell,
  tableStyles,
  Text,
  TextArea,
  TextField,
} from '@pp-labs/ui-components';
import strings, { useLocalizedLanguages } from '../../../Localization/Localizer';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Answer,
  Channel,
  ConfigModuleIdentifiers,
  EditLevel,
  PostQuestion,
  Question,
} from '../../../ApiHandler/dclxInterfaces';
import { getAnswerById } from '../../../utils/convert';
import { client } from '../../../ApiHandler/client';
import { byAnswerId } from '../../../utils/sort';
import { atLeastOneLanguage, fakeNumber } from 'utils/validator';
import { useNamedConfigModule } from '../../../utils/hooks';

const useStyles = makeStyles(() => ({
  ...tableStyles(),
  top: {
    verticalAlign: 'top',
  },
}));

const validation = Yup.object({
  question: atLeastOneLanguage(),
  points: fakeNumber(),
  answers: Yup.array().of(
    Yup.object({
      answerText: atLeastOneLanguage(),
      correct: Yup.boolean().required(strings.required),
      answerId: Yup.number().required(strings.required),
    })
  ),
  checkboxes: Yup.boolean().test('checkboxes', strings.atLeastOneAnswerCorrect, function () {
    for (const answer of this.parent.answers) {
      if (answer.correct) return true;
    }
    return false;
  }),
}).required(strings.required);

type F = Yup.InferType<typeof validation>;

const defaultAnswer = {
  answerText: '',
  correct: false,
  answerId: -1,
};

/** interface for DCLXCmsQuestionEdit props coming from parent component DCLXCMSQuestionTable  */
interface P {
  thisQuestion: Question | null;
  close: (refresh?: boolean) => void;
  open: boolean;
  interruptId: number;
  level: EditLevel;
  exam?: boolean;
  newPosition: number;
  channel: Channel;
}

/**
 * Form for editing Questions (for exams or interrupts)
 */

const DCLXCmsQuestionEdit = (props: P) => {
  const classes = useStyles();
  const useModule = useNamedConfigModule();
  const languages = useLocalizedLanguages([props.channel]);
  const [showCheckboxError, setShowCheckboxError] = useState<boolean>(false);

  const gamification = useModule(ConfigModuleIdentifiers.gamification);

  const submitToServer = async (v: F) => {
    if (!props.thisQuestion) {
      const data: PostQuestion = {
        questionText: JSON.stringify(v.question),
        position: props.newPosition,
        points: v.points ? Number(v.points) : 0,
        answers: [],
      };
      const id: number = (
        await client.post(
          `${props.exam ? 'exams' : 'interrupts'}/${props.interruptId}/questions`,
          data
        )
      ).data;
      // @ts-ignore
      await sendAnswers(id, v.answers);
    } else {
      const t = props.thisQuestion;
      await client.put(`questions/${t.questionId}`, {
        questionText: JSON.stringify(v.question),
      });
      // @ts-ignore
      await sendAnswers(t.questionId, v.answers);
    }
  };

  const answersAreDifferent = (answer1: Answer, answer2: Answer) => {
    return answer1.answerText !== answer2.answerText || answer1.correct !== answer2.correct;
  };

  const sendAnswers = async (questionId: number, answers?: Answer[]) => {
    if (!answers) return;
    const answerPromises = [];

    if (props.thisQuestion) {
      const toSend = props.thisQuestion.answers.map((a) => a.answerId);
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        const data = {
          answerText: JSON.stringify(answer.answerText),
          correct: answer.correct,
        };
        let found = false;
        for (let j = 0; j < toSend.length; j++) {
          if (answer.answerId === toSend[j]) {
            toSend.splice(j, 1);
            found = true;
            const answer2 = getAnswerById(props.thisQuestion.answers, answer.answerId);
            if (answer2 && answersAreDifferent(answer, answer2)) {
              answerPromises.push(client.put(`questions/answers/${answer.answerId}`, data));
            }
            break;
          }
        }
        if (!found) {
          await client.post(`questions/${questionId}/answers`, data);
        }
      }
      toSend.forEach((toSendEntry) => {
        answerPromises.push(client.delete(`questions/answers/${toSendEntry}`));
      });
    } else {
      for (let i = 0; i < answers.length; i++) {
        const answer = answers[i];
        const data = {
          answerText: JSON.stringify(answer.answerText),
          correct: answer.correct,
        };
        await client.post(`questions/${questionId}/answers`, data);
      }
    }

    Promise.all(answerPromises).then(() => {
      props.close(true);
    });
  };

  const close = () => {
    props.close();
  };

  const getInitialValues = () => {
    return {
      question: props.thisQuestion ? JSON.parse(props.thisQuestion.questionText) : '',
      answers: generateInitialAnswers(),
      points: props.thisQuestion?.points?.toString() || '',
    };
  };

  const generateInitialAnswers = (): Answer[] => {
    const all = props.thisQuestion?.answers || [];
    return all.map((a) => ({ ...a, answerText: JSON.parse(a.answerText) })).sort(byAnswerId);
  };

  const initialValues = getInitialValues();
  return (
    <EditDialog title={strings.question} close={close}>
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        // @ts-ignore
        onSubmit={(values: F, { resetForm }) => {
          submitToServer(values);
          setShowCheckboxError(false);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => {
          const et = {
            question: touched.question ? errors.question?.toString() : '',
            points: touched.points ? errors.points : '',
          };

          const checkboxError =
            // @ts-ignore
            showCheckboxError && errors.checkboxes
              ? // @ts-ignore
                errors.checkboxes
              : '';
          return (
            <LocalizedForm
              languages={languages}
              localizeInputs={[
                'question',
                ...values.answers.map((a, k) => `answers.${k}.answerText`),
              ]}
              initialValues={JSON.parse(JSON.stringify(initialValues))}
              autoParse={[]}
            >
              <TableContainer>
                <Table className={`${classes.table} ${classes.top}`} aria-label="simple table">
                  <TableBody>
                    <TableRow key="question">
                      <TableCell noBorder>
                        <Text as="h3" variant="order4">
                          <b>{strings.question}</b>
                        </Text>
                        <div style={{ padding: '12px' }} />
                        <TextArea name="question" label={strings.question} error={et.question} />
                        <div style={{ padding: '12px' }} />

                        {gamification && (
                          <TextField
                            name="points"
                            label={strings.questionPointsOnCompletion}
                            error={et.points}
                          />
                        )}
                      </TableCell>
                      <TableCell noBorder />
                    </TableRow>
                    <TableRow key="answer-headline">
                      <TableCell style={{ paddingBottom: '8px' }} noBorder>
                        <Text as="h3" variant="order4">
                          <b>{strings.answerOptions}</b>
                        </Text>
                      </TableCell>
                      <TableCell noBorder />
                    </TableRow>

                    <FieldArray name="answers" key={'answers'}>
                      {({ remove, push }) => {
                        return (
                          <>
                            {values.answers.length > 0 &&
                              values.answers.map((answer: Answer, index: number) => {
                                let textError: any = undefined;
                                let correctError: any = undefined;
                                // eslint-disable-next-line max-len
                                if (
                                  touched &&
                                  errors &&
                                  touched.answers &&
                                  errors.answers &&
                                  touched.answers[index] &&
                                  errors.answers[index]
                                ) {
                                  const e = errors.answers[index];
                                  if (typeof e !== 'string') {
                                    textError = e.answerText;
                                    correctError = e.correct;
                                  }
                                }
                                const right = answer.correct;
                                return (
                                  <TableRow key={`answer-${index}`}>
                                    <TableCell noBorder>
                                      <div ref={`answers.${index}.answerText`}>
                                        <TextArea
                                          name={`answers.${index}.answerText`}
                                          label={strings.description}
                                          error={textError}
                                        />
                                      </div>

                                      <div style={{ padding: '3px 0' }} />
                                      <FormGroup>
                                        <Radio
                                          name={`answers.${index}.correct`}
                                          label={strings.answerCorrect}
                                          handle={setFieldValue}
                                          value={'true'}
                                          default={right}
                                        />

                                        <Radio
                                          name={`answers.${index}.correct`}
                                          label={strings.answerWrong}
                                          handle={setFieldValue}
                                          value={'false'}
                                          default={!right}
                                        />
                                        <div className="field-error">{correctError}</div>
                                      </FormGroup>
                                      <div style={{ padding: '2px 0' }} />
                                    </TableCell>

                                    <TableCell buttons noBorder center>
                                      {
                                        <IconButton onClick={() => remove(index)}>
                                          <EraseIcon />
                                        </IconButton>
                                      }
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            <TableRow key="buttons">
                              <TableCell noBorder>
                                <div className="field-error">{checkboxError}</div>
                                <LabelButton
                                  variant="secondary"
                                  onClick={() => push(defaultAnswer)}
                                >
                                  {strings.addAnswer}
                                </LabelButton>
                              </TableCell>
                            </TableRow>
                            <TableRow key="buttons2">
                              <TableCell noBorder>
                                <div style={{ float: 'right' }}>
                                  <LabelButton
                                    variant="secondary"
                                    className={classes.btnLeft}
                                    onClick={close}
                                  >
                                    {strings.abort}
                                  </LabelButton>

                                  <LabelButton
                                    variant="primary"
                                    type="submit"
                                    className={classes.btnRight}
                                    onClick={() => {
                                      setShowCheckboxError(true);
                                    }}
                                  >
                                    {strings.save}
                                  </LabelButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }}
                    </FieldArray>
                  </TableBody>
                </Table>
              </TableContainer>
            </LocalizedForm>
          );
        }}
      </Formik>
    </EditDialog>
  );
};

export default DCLXCmsQuestionEdit;
