/* @ts-ignore */
import './AudiTabs.scss';
import React from 'react';
import Box from '@material-ui/core/Box';
import styles from '../../features/Sessions/styles';
import { gutterStyle } from '../gutter';
import { blackStyle } from '../../features/Sessions/Watch/blackStyle';
import { Theme } from '@material-ui/core/styles';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Text } from '@pp-labs/ui-components';
import { NewsIcon } from '@pp-labs/ui-components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  special?: boolean;
  notification?: boolean;
  gamificationDark?: boolean;
}

/**
 * Multi Tab Panel
 */

function TabPanel(props: TabPanelProps) {
  const { children, value, index, special, notification, gamificationDark, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lot-tabpanel-${index}`}
      aria-labelledby={`lot-tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {value === index && (
        <>
          {notification ? (
            <div style={{ padding: '8px 0px 24px 0px', height: '100%' }}>{children}</div>
          ) : gamificationDark ? (
            <div style={{ padding: '16px 32px 0 32px', height: '100%', background: '#151515' }}>
              {children}
            </div>
          ) : special ? (
            <div style={{ padding: '16px 32px 0 32px', height: '100%' }}>{children}</div>
          ) : (
            <Box p={3}>
              <div>{children}</div>
            </Box>
          )}
        </>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `audi-tab-${index}`,
    'aria-controls': `audi-tabpanel-${index}`,
  };
}

const s = (theme: Theme) =>
  createStyles({
    ...gutterStyle(theme),
    ...styles(theme),
    ...blackStyle(theme),
    root: {
      flexGrow: 1,
      backgroundColor: '#F2F2F2',
    },
    notificationRoot: {
      flexGrow: 1,
      backgroundColor: '#FFFFFF',
    },
    blackRoot: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: '#1A1A1A',
      overflow: 'hidden',
    },
    whiteText: {
      color: '#000000',
    },
  });

/** interface for AudiTabs props coming from parent component AdminEventUserManager, EditChannel, MessageNotification and Gamification  */
interface P extends WithStyles<typeof s> {
  tabLabels: Array<string>;
  tabContents: Array<any>;
  black?: boolean;
  resetChanges?: () => void;
  changeToChat?: boolean;
  changeToPoll?: boolean;
  onChange?: (index: number) => void;
  notification?: boolean;
  gamificationDark?: boolean;
  secondary?: boolean;
  scrollable?: boolean;
  spacingBetweenInPixel?: number;
}

/** interface for AudiTabs component state  */
interface S {
  value: number;
}

class AudiTabs extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
    this.props.resetChanges?.();
    if (this.props.onChange) this.props.onChange(newValue);
  };

  getTabLabel = (text: string, index: number) => {
    const t = (
      <Text
        as="h4"
        variant="copy1"
        className={
          this.props.black
            ? this.props.classes.blackText
            : this.props.notification
            ? this.props.classes.whiteText
            : undefined
        }
      >
        {text}
      </Text>
    );
    if (this.props.resetChanges) {
      return (
        <div>
          {index !== this.state.value &&
            ((index === 0 && this.props.changeToChat) ||
              (index === 1 && this.props.changeToPoll)) && (
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '8px',
                }}
              >
                <NewsIcon />
              </div>
            )}
          <div style={{ display: 'inline-block' }}>{t}</div>
        </div>
      );
    }
    return t;
  };

  render() {
    return (
      <div
        className={`${
          this.props.black || this.props.gamificationDark ? 'black-tabs' : 'normal-tabs'
        } ${
          this.props.black
            ? this.props.classes.blackRoot
            : this.props.notification
            ? this.props.classes.notificationRoot
            : this.props.classes.root
        }`}
      >
        <Paper square elevation={0} style={{ backgroundColor: 'transparent' }}>
          <Tabs
            style={this.props.secondary ? { backgroundColor: '#F2F2F2' } : {}}
            value={this.state.value}
            onChange={this.handleChange}
            variant={this.props.scrollable ? 'scrollable' : 'standard'}
            aria-label={
              this.props.gamificationDark
                ? 'gamification tabs'
                : this.props.black
                ? 'side tabs'
                : this.props.notification
                ? 'noti tabs'
                : 'lot tabs'
            }
            indicatorColor="primary"
          >
            {this.props.tabLabels
              .filter((l) => l.length)
              .map((l, index) => (
                <Tab
                  style={
                    this.props.spacingBetweenInPixel
                      ? {
                          margin: '0px',
                          padding: '0px',
                          paddingLeft: this.props.spacingBetweenInPixel,
                          paddingRight: this.props.spacingBetweenInPixel,
                          minWidth: 'auto',
                        }
                      : {}
                  }
                  key={index}
                  label={this.getTabLabel(l, index)}
                  {...a11yProps(index)}
                />
              ))}
          </Tabs>
        </Paper>
        <>
          {this.props.tabContents.map((c, index) => (
            <TabPanel
              key={index}
              value={this.state.value}
              index={index}
              notification={this.props.notification}
              special={this.props.black}
              gamificationDark={this.props.gamificationDark}
            >
              {c}
            </TabPanel>
          ))}
        </>
      </div>
    );
  }
}

export default withStyles(s)(AudiTabs);
