import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import { LabelButton, TextField } from '@pp-labs/ui-components';
import { format, parse } from 'date-fns';
import { Text } from '@audi/audi-ui-react';
import strings from '../../Localization/Localizer';
import { DownloadCsv } from '../../utils/csvParser/DownloadCsv';
import { RowParser, UploadCsv } from '../../utils/csvParser/UploadCsv';
import { client } from '../../ApiHandler/client';
import { useCMSChannel, useNotify } from '../../utils/hooks';
import { Agenda, AgendaItem, AgendaKey, CSVAgendaItem, RawAgenda } from 'ApiHandler/dclxInterfaces';
import { csvFormat } from '../../config';
import { buildAgendaKey, parseAgenda } from '../../utils/convert';

const getValidation = () =>
  Yup.object({
    slot: Yup.string(),
  }).required(strings.required);

type F = Yup.InferType<ReturnType<typeof getValidation>>;

const initialValues: F = {
  slot: '',
};

const getExampleData = (): Agenda[] => [
  {
    agendaKeyPattern: 'color;slot',
    agendaKey: 'blue;7',
    channelId: 1,
    agendaItems: [
      {
        time: Date.now(),
        title: 'Event 1',
        location: 'Location 1',
        description: 'This is the first test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
      {
        time: Date.now(),
        title: 'Event 2',
        location: 'Location 2',
        description: 'This is the second test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
      {
        time: Date.now(),
        title: 'Event 3',
        location: 'Location 3',
        description: 'This is the third test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
    ],
  },
  {
    agendaKeyPattern: 'color;slot',
    agendaKey: 'red;1',
    channelId: 1,
    agendaItems: [
      {
        time: Date.now(),
        title: 'Event 1',
        location: 'Location 1',
        description: 'This is the first test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
      {
        time: Date.now(),
        title: 'Event 2',
        location: 'Location 2',
        description: 'This is the second test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
      {
        time: Date.now(),
        title: 'Event 3',
        location: 'Location 3',
        description: 'This is the third test event.',
        shortDayTitle: 'Day 1',
        longDayTitle: 'On your first day',
      },
    ],
  },
];

export const UploadAgenda = () => {
  const channel = useCMSChannel()!;
  const notify = useNotify();
  const [agendi, setAgendi] = useState<RawAgenda[]>([]);
  const [parsedCsv, setParsedCsv] = useState<CSVAgendaItem[]>([]);
  const validationSchema = useMemo(getValidation, []);

  useEffect(() => {
    refresh();
  }, []);
  const refresh = async () => setAgendi((await client.get('agenda')).data);

  const submit = async (values: F) => {
    if (!parsedCsv.length) notify('Please upload an agenda file', 'error');

    const all: { [agendaKey in AgendaKey]: AgendaItem[] } = {};

    parsedCsv.forEach((item) => {
      if (all[item.agendaKey]) {
        all[item.agendaKey].push(item);
      } else {
        all[item.agendaKey] = [item];
      }
    });

    const agendi: RawAgenda[] = Object.entries(all).map(([agendaKey, items]) => {
      return {
        agendaKey: agendaKey,
        agendaItems: JSON.stringify(items),
        channelId: channel.channelId,
        agendaKeyPattern: values.slot || '',
      };
    });
    await client.post(`agenda/${channel.channelId}`, agendi);
    notify('Agenda uploaded', 'success');
    await refresh();
  };

  const rowParser: RowParser<CSVAgendaItem> = (lineNumber, row) => {
    const date = row[0];
    if (!date) throw new Error(`No parsable time provided in line ${lineNumber}`);
    const d = parse(date, csvFormat, new Date());
    const time = d.getTime();
    if (!time) {
      console.error('Failed to parse', date, 'came up with', d, time);
      throw new Error(`Invlid time format in line ${lineNumber}`);
    }
    const title = row[1];
    if (!title) throw new Error(`No parsable title provided in line ${lineNumber}`);
    const location = row[2];
    if (!location) throw new Error(`No parsable location provided in line ${lineNumber}`);
    const description = row[3] || '';
    const shortDayTitle = row[4] || '';
    const longDayTitle = row[5] || '';
    const agendaKey = buildAgendaKey(row, 6);
    return {
      time: time,
      title: title,
      location: location,
      description: description,
      shortDayTitle: shortDayTitle,
      longDayTitle: longDayTitle,
      agendaKey: agendaKey,
    };
  };

  const loadData = (): [string[][], boolean] => {
    const rawAgendi = agendi.filter((a) => a.channelId === channel.channelId);
    const useReal = !!rawAgendi.length;
    const parsedAgendi = useReal ? rawAgendi.map((a) => parseAgenda(a)) : getExampleData();
    const csvItems: CSVAgendaItem[] = parsedAgendi
      .map((agenda) => agenda.agendaItems.map((i) => ({ ...i, agendaKey: agenda.agendaKey })))
      .flat();
    return [
      csvItems.map((i) => [
        format(i.time, csvFormat),
        i.title,
        i.location,
        i.description,
        i.shortDayTitle,
        i.longDayTitle,
        ...i.agendaKey.split(';'),
      ]),
      !useReal,
    ];
  };

  return (
    <div>
      <Text variant="order2">Upload Agenda</Text>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        {({ touched, errors, values }) => {
          const et = {
            slot: touched.slot ? errors.slot : '',
          };
          const [items, example] = loadData();
          return (
            <Form>
              <TextField name="slot" label={'Airlist fields (; separated)'} error={et.slot} />

              <UploadCsv
                rowParser={rowParser}
                setResult={setParsedCsv}
                constantLineLength={true}
                headerLine={null}
                additionalHeaderLine={null}
                onFileChange={() => setParsedCsv([])}
              />

              <LabelButton variant="primary" type="submit" spaceInlineEnd="s">
                Submit Agenda
              </LabelButton>

              <DownloadCsv
                loadData={async () => items}
                fileName={example ? 'exampleAgenda.csv' : `agenda.csv`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
