import { makeStyles } from '@material-ui/core';
import { BaseBreakoutRoom } from '../Provider/LotProvider';
import { useRealTime } from '../../../../utils/hooks';
import { useMemo } from 'react';
import { Text } from '@pp-labs/ui-components';
import { appendZero } from '../../../../utils/convert';
import strings from '../../../../Localization/Localizer';
import { controlBarHeight } from '../config';
import clsx from 'clsx';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    height: 57,
    width: '100%',
    top: controlBarHeight,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: 'column',
  },
  text: {
    color: 'white',
  },
});

/** interface for TimeCountdown props coming from parent components Lot and BreakoutRoom */
interface P {
  room: BaseBreakoutRoom | null;
  mode: 'overlay' | 'text';
}

/** Countdown displayed if a breakout room is expiring */

export const TimeCountdown = (props: P) => {
  const cls = useStyles();
  const time = useRealTime(1000);
  const remainingSeconds = useMemo(() => {
    if (!props.room?.CustomProperties.deactivateAt) return null;
    return Math.floor(Math.max(props.room.CustomProperties.deactivateAt - time, 0) / 1000);
  }, [props.room, time]);
  if (!remainingSeconds) return null;

  return (
    <div className={clsx(props.mode === 'overlay' && cls.wrapper)}>
      <Text className={clsx(props.mode === 'overlay' && cls.text)}>
        {strings.breakoutRemainingTime} | <b>0:{appendZero(remainingSeconds)}</b>
      </Text>
    </div>
  );
};
