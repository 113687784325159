import React, { Component } from 'react';
import {
  createStyles,
  Table,
  TableBody,
  TableContainer,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { EditDialog, LayoutItem, TableCell, TableRow, Text } from '@pp-labs/ui-components';
import strings, {
  getChannelsLanguages,
  getLocalizedLanguages,
  getLocalizedValue,
} from '../../../Localization/Localizer';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Channel,
  CognitoUser,
  EditLevel,
  MultiLanguage,
  Session,
  SessionSettings,
  SessionType,
  SetSetting,
} from '../../../ApiHandler/dclxInterfaces';

import { getSessionSettings, noNullUser, toInt } from '../../../utils/convert';
import { triggerNotification } from '../../../utils/NotificationSlice';
import { connect } from 'react-redux';
import Duration from '../../../utils/Duration';
import { Store } from '../../../store';
import SessionRefSubform from './EditSubforms/SessionRefSubform';
import MediaSubform from './EditSubforms/MediaSubform';
import EventTimeSubform from './EditSubforms/EventTimeSubform';
import ControlsSubform from './EditSubforms/ControlsSubform';
import StreamSubform from './EditSubforms/StreamSubform';
import { LocalizedForm, tableStyles } from '@pp-labs/ui-components';
import { client } from '../../../ApiHandler/client';
import { atLeastOneLanguage, requiredMediaFile } from '../../../utils/validator';
import TitleDescriptionSubformContent from './EditSubforms/TitleDescriptionSubform';
import { SettingsSubform } from './EditSubforms/SettingsSubform';
import { TaggingSubform } from './EditSubforms/TaggingSubform';
import { MediaFile } from '../Documents/MediaLibrary';
import { MediaLibraryDialog } from '../Documents/MediaLibraryDialog';
import { InterruptsDialogs } from './InterruptsDialogs';

const styles = () =>
  createStyles({
    ...tableStyles(),
    flushRight: {
      position: 'relative',
      float: 'right',
    },
    inline: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    headline: {
      paddingBottom: '36px',
    },
  });

//  Yup Validation for all session types
const liveTerminated = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  thumbnail: requiredMediaFile,
  video: requiredMediaFile,
}).required(strings.required);

const liveTerminatedEdit = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
}).required(strings.required);

const onDemand = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  thumbnail: requiredMediaFile,
  video: requiredMediaFile,
  subtitle: Yup.array(),
}).required(strings.required);

const onDemandEdit = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  subtitle: Yup.array(),
}).required(strings.required);

const externalSession = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  stream: Yup.string().required(strings.required),
  thumbnail: requiredMediaFile,
}).required(strings.required);

const externalSessionEdit = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  stream: Yup.string().required(strings.required),
}).required(strings.required);

const trainingSession = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  thumbnail: requiredMediaFile,
}).required(strings.required);

const trainingSessionEdit = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
}).required(strings.required);

const live = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
  thumbnail: requiredMediaFile,
}).required(strings.required);

const liveEdit = Yup.object({
  title: atLeastOneLanguage(),
  description: atLeastOneLanguage(),
}).required(strings.required);

interface OnDemand {
  topicId: number;
  title: string;
  description: string;
  thumbnail: MediaFile | null;
  video: MediaFile | null;
  subtitle: Array<MultiLanguage<MediaFile>> | null;
  tag: string;
}

interface External {
  topicId: number;
  title: string;
  description: string;
  thumbnail: MediaFile | null;
  video: MediaFile | null;
  externalUrl: string;
  adapted: boolean;
  stream: string;
  tag: string;
}

interface Live {
  topicId: number;
  title: string;
  description: string;
  thumbnail: MediaFile | null;
  adapted: boolean;
  tag: string;
}

interface LiveTerminated extends OnDemand {}

interface Training {
  topicId: number;
  description: string;
  title: string;
  thumbnail: MediaFile | null;
  lobbyImage?: MediaFile | null;
  video?: MediaFile | null;
  tag: string;
}

/** interface for DCLXCmsSessionEdit props coming from parent component DCLXEvents, TrainingSessionEdit */
interface P extends WithStyles<typeof styles> {
  refs?: Session[];
  thisSession: Session | null;
  abort: () => void;
  success: Function;
  type: SessionType;
  event?: boolean;
  topicIds: Array<number>;
  channel: Channel;
  level: EditLevel;
  triggerNotification: Function;
  newPosition: number;
  user: CognitoUser;
  stream: boolean;
}
/** interface for DCLXCmsSessionEdit component state  */
interface S {
  openInterruptDialog: boolean;
  selectedRefs: Array<boolean>;
  id?: number;
  uploading: boolean;
  currentTime: number;
  uploadETA: number;
  documentManager: boolean;
  adapted: boolean;
  selectedEventType: string;
  startDate: Date;
  startTimezone?: number;
  endDate: Date;
  endTimezone?: number;
  settings: SessionSettings;
  selectedLanguage: number;
  session: Session | null;
  sending: boolean;
  documentIds: number[];
}
/**
 * Form for editing Session
 */
class DCLXCmsSessionEdit extends Component<P, S> {
  interval: any;
  constructor(props: P) {
    super(props);
    this.state = {
      openInterruptDialog: false,
      id: this.props.thisSession?.sessionId,
      selectedRefs: new Array<boolean>(props.refs ? props.refs.length : 0).fill(false),
      uploading: false,
      currentTime: 0,
      uploadETA: 0,
      documentManager: false,
      adapted: false,
      selectedEventType: '',
      startDate: new Date(),
      startTimezone: this.props.thisSession?.startTimezone,
      endTimezone: this.props.thisSession?.endTimezone,
      endDate: new Date(),
      settings: this.props.thisSession ? getSessionSettings(this.props.thisSession) : {},
      selectedLanguage: 0,
      session: null,
      sending: false,
      documentIds: this.props.thisSession?.documentIds || [],
    };
  }
  componentDidMount() {
    this.setInitialRefs();
    this.setInitialAdapt();

    const getThisSession = async () => {
      const data = (await client.get(`sessions/${this.props.thisSession?.sessionId}`))
        .data as Session;
      this.setState({ session: data });
    };
    if (this.props.thisSession) {
      getThisSession();
    }
  }

  componentWillUnmount() {
    this.cancelUploadTimer();
  }

  getInitialValues = () => {
    if (this.props.type === SessionType.LIVETERMINATED) {
      const start = this.props.thisSession?.startAt
        ? new Date(toInt(this.props.thisSession?.startAt) * 1000)
        : new Date();
      const end = this.props.thisSession?.endAt
        ? new Date(toInt(this.props.thisSession?.endAt) * 1000)
        : new Date();
      return {
        title: this.props.thisSession?.title ? JSON.parse(this.props.thisSession?.title) : '',
        description: this.props.thisSession?.description
          ? JSON.parse(this.props.thisSession?.description)
          : '',
        thumbnail: this.props.thisSession?.thumbnail,
        video: this.props.thisSession?.video,
        start: start,
        startTimezone: this.props.thisSession?.startTimezone,
        end: end,
        tag: this.props.thisSession?.reportingTag || '',
        endTimezone: this.props.thisSession?.endTimezone,
      };
    } else if (this.props.type === SessionType.DEMAND) {
      return {
        title: this.props.thisSession?.title ? JSON.parse(this.props.thisSession?.title) : '',
        description: this.props.thisSession?.description
          ? JSON.parse(this.props.thisSession?.description)
          : '',
        thumbnail: null,
        video: this.props.thisSession?.video,
        tag: this.props.thisSession?.reportingTag || '',
      };
    } else if (this.props.type === SessionType.TRAINING) {
      // eslint-disable-next-line max-len
      return {
        title: this.props.thisSession?.title ? JSON.parse(this.props.thisSession?.title) : '',
        description: this.props.thisSession?.description
          ? JSON.parse(this.props.thisSession?.description)
          : '',
        thumbnail: this.props.thisSession?.thumbnail,
        lobbyImage: this.props.thisSession?.lobbyImage || undefined,
        video: this.props.thisSession?.video || undefined,
        tag: this.props.thisSession?.reportingTag || '',
      };
    } else if (this.props.type === SessionType.LIVE) {
      const startDate = this.props.thisSession?.startAt
        ? new Date(toInt(this.props.thisSession?.startAt) * 1000)
        : new Date();
      const endDate = this.props.thisSession?.endAt
        ? new Date(toInt(this.props.thisSession?.endAt) * 1000)
        : new Date();
      return {
        title: this.props.thisSession?.title ? JSON.parse(this.props.thisSession?.title) : '',
        description: this.props.thisSession?.description
          ? JSON.parse(this.props.thisSession?.description)
          : '',
        start: startDate,
        end: endDate,
        startTimezone: this.props.thisSession?.startTimezone || 0,
        endTimezone: this.props.thisSession?.endTimezone || 0,
        thumbnail: this.props.thisSession?.thumbnail,
        tag: this.props.thisSession?.reportingTag || '',
      };
    } else {
      //what session type should this be ?
      const d = this.props.thisSession?.startAt || 0;
      const date = new Date(d * 1000);

      return {
        title: this.props.thisSession?.title ? JSON.parse(this.props.thisSession?.title) : '',
        description: this.props.thisSession?.description
          ? JSON.parse(this.props.thisSession?.description)
          : '',
        start: date,
        startTimezone: this.props.thisSession?.startTimezone || 0,
        thumbnail: this.props.thisSession?.thumbnail,
        stream: this.props.thisSession?.externalUrl || '',
        tag: this.props.thisSession?.reportingTag || '',
      };
    }
  };

  sendToServer = async (data: any, v: any) => {
    await (this.state.id
      ? client.put(`sessions/${this.state.id}`, data)
      : client.post('sessions', data));
    this.setState({ uploading: false, currentTime: 0, uploadETA: 0, sending: false });
    this.cancelUploadTimer();
    this.props.triggerNotification([strings.sessionUploaded, 'success']);
    this.props.success();
  };

  shouldShowInChannelContent = () => {
    return this.props.thisSession
      ? this.props.thisSession?.showInChannelContent
      : !this.props.topicIds || this.props.topicIds?.length === 0;
  };

  submitliveTerminated = (v: LiveTerminated) => {
    const subtitles: { [key: string]: number } = {};
    v.subtitle?.forEach((element) => {
      subtitles[element.lang] = element.value?.mediaId;
    });
    const t = JSON.stringify(v.title);
    const data: Partial<Session> = {
      title: t,
      description: JSON.stringify(v.description),
      thumbnailId: v.thumbnail?.mediaId || this.props.thisSession?.thumbnailId,
      videoId: v.video?.mediaId || this.props.thisSession?.videoId,
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      channelId: this.props.channel.channelId,
      showInChannelContent: this.shouldShowInChannelContent(),
      adapted: this.state.adapted,
      position: this.props.thisSession ? this.props.thisSession.position : this.props.newPosition,
      startAt: this.state.startDate.getTime() / 1000,
      endAt: this.state.endDate.getTime() / 1000,
      reportingTag: v.tag || getLocalizedValue(t),
      subtitleIds: subtitles,
      startTimezone: this.state.startTimezone,
      endTimezone: this.state.endTimezone,
      documentIds: this.state.documentIds,
    };
    this.sendToServer(data, v);
  };

  setSetting: SetSetting = (key, value) => {
    const override: SessionSettings = { ...this.state.settings };
    override[key] = value;
    this.setState({ settings: override });
  };

  submitOnDemand = async (v: OnDemand) => {
    const subtitles: { [key: string]: number | undefined } = {};
    v.subtitle?.forEach((element) => {
      subtitles[element.lang] =
        element.value?.mediaId || this.props.thisSession?.subtitleIds?.[element.lang];
    });

    const videoMetaData = v.video?.metaData ? JSON.parse(v.video?.metaData) : {};

    const t = JSON.stringify(v.title);
    const data: Partial<Session> = {
      title: t,
      description: JSON.stringify(v.description),
      thumbnailId: v.thumbnail?.mediaId || this.props.thisSession?.thumbnailId,
      videoId: v.video?.mediaId || this.props.thisSession?.videoId,
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      channelId: this.props.channel.channelId,
      showInChannelContent: this.shouldShowInChannelContent(),
      adapted: this.state.adapted,
      position: this.props.thisSession ? this.props.thisSession.position : this.props.newPosition,
      settings: JSON.stringify(this.state.settings),
      subtitleIds: subtitles,
      reportingTag: v.tag || getLocalizedValue(t),
      duration: Math.round(videoMetaData.duration),
      documentIds: this.state.documentIds,
    };
    await this.sendToServer(data, v);
  };

  submitExternal = (v: External) => {
    const t = JSON.stringify(v.title);
    const data: Partial<Session> = {
      title: t,
      description: JSON.stringify(v.description),
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      thumbnailId: v.thumbnail?.mediaId || this.props.thisSession?.thumbnailId,
      channelId: this.props.channel.channelId,
      showInChannelContent: this.shouldShowInChannelContent(),
      position: this.props.thisSession ? this.props.thisSession.position : this.props.newPosition,
      externalUrl: v.stream,
      adapted: this.state.adapted,
      settings: JSON.stringify(this.state.settings),
      reportingTag: v.tag || getLocalizedValue(t),
    };
    this.sendToServer(data, v);
  };

  refreshInterrupts = async () => {
    const id = this.state.id;
    if (!id) return;
    const s: Session = (await client.get(`sessions/${id}`)).data;
    this.setState({ session: s });
  };

  submitTraining = (v: Training) => {
    const t = JSON.stringify(v.title);
    const data: Partial<Session> = {
      title: t,
      description: JSON.stringify(v.description),
      thumbnailId: v.thumbnail?.mediaId || this.props.thisSession?.thumbnailId,
      lobbyImageId:
        v.lobbyImage === null ? null : v.lobbyImage?.mediaId || this.state.session?.lobbyImageId,
      videoId: v.video === null ? null : v.video?.mediaId || this.state.session?.videoId,
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      channelId: this.props.channel.channelId,
      showInChannelContent: this.shouldShowInChannelContent(),
      sessionRefs: this.getRefIds(),
      adapted: this.state.adapted,
      position: this.props.thisSession ? this.props.thisSession.position : this.props.newPosition,
      settings: JSON.stringify(this.state.settings),
      reportingTag: v.tag || getLocalizedValue(t),
      documentIds: this.state.documentIds,
    };
    this.sendToServer(data, v);
  };

  submitLive = (v: Live) => {
    const t = JSON.stringify(v.title);
    const data: Partial<Session> = {
      title: t,
      description: JSON.stringify(v.description),
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      thumbnailId: v.thumbnail?.mediaId || this.props.thisSession?.thumbnailId,
      channelId: this.props.channel.channelId,
      showInChannelContent: this.shouldShowInChannelContent(),
      adapted: this.state.adapted,
      startAt: this.state.startDate.getTime() / 1000,
      endAt: this.state.endDate.getTime() / 1000,
      startTimezone: this.state.startTimezone,
      position: this.props.thisSession ? this.props.thisSession.position : this.props.newPosition,
      endTimezone: this.state.endTimezone,
      settings: JSON.stringify(this.state.settings),
      reportingTag: v.tag || getLocalizedValue(t),
      documentIds: this.state.documentIds,
    };
    this.sendToServer(data, v);
  };

  getRefIds = (): Array<number> => {
    const refIds: Array<number> = [];
    if (this.props.refs) {
      for (let i = 0; i < this.state.selectedRefs.length; i++) {
        if (this.state.selectedRefs[i]) {
          refIds.push(this.props.refs[i].sessionId);
        }
      }
    }
    return refIds;
  };

  setInitialRefs = () => {
    if (!this.props.thisSession || !this.props.thisSession.sessionRefs || !this.props.refs) return;
    const refsArray: Array<boolean> = [];
    for (let i = 0; i < this.props.refs.length; i++) {
      const session = this.props.refs[i];
      const f = this.props.thisSession.sessionRefs.find((n) => n === session.sessionId);
      refsArray.push(f !== undefined);
    }
    this.setState({
      selectedRefs: refsArray,
    });
  };

  setInitialAdapt = () => {
    if (!this.props.thisSession?.adapted) return;
    this.setState({
      adapted: this.props.thisSession.adapted,
    });
  };

  createDummy = async () => {
    const data = {
      channelId: this.props.channel.channelId,
      sessionType: this.props.type,
      topicIds: this.props.topicIds,
      title: 'dummy',
      thumbnailId: 1,
      videoId: 1,
    };
    const id = toInt((await client.post('sessions', data)).data);
    await new Promise((resolve) => {
      //@ts-ignore
      this.setState({ id: id }, resolve);
    });
    return id;
  };

  startUploadTimer = () => {
    this.interval = setInterval(
      () => this.setState({ currentTime: this.state.currentTime + 1 }),
      1000
    );
  };

  cancelUploadTimer = () => {
    clearInterval(this.interval);
  };

  openDocManager = async () => {
    this.setState({ documentManager: true });
  };

  closeDocManager = () => {
    this.setState({ documentManager: false });
  };

  getValidator = () => {
    const edit = !!this.props.thisSession;
    switch (this.props.type) {
      case SessionType.LIVETERMINATED:
        return edit ? liveTerminatedEdit : liveTerminated;
      case SessionType.DEMAND:
        return edit ? onDemandEdit : onDemand;
      case SessionType.EXTERNAL:
        return edit ? externalSessionEdit : externalSession;
      case SessionType.LIVE:
        return edit ? liveEdit : live;
      case SessionType.TRAINING:
        return edit ? trainingSessionEdit : trainingSession;
    }
  };

  closeInterrupt = () => {
    this.setState({ openInterruptDialog: false });
  };

  openInterrupt = async () => {
    if (this.state.id === undefined) await this.createDummy();
    this.setState({ openInterruptDialog: true });
  };

  getErrors = (touched: any, errors: any) => {
    if (this.props.type === SessionType.LIVETERMINATED) {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
        video: touched.video ? errors.video : '',
      };
    } else if (this.props.type === SessionType.DEMAND) {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
        video: touched.video ? errors.video : '',
      };
    } else if (this.props.type === SessionType.EXTERNAL) {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        stream: touched.stream ? errors.stream : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
      };
    } else if (this.props.type === SessionType.LIVE) {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
      };
    } else if (this.props.type === SessionType.TRAINING) {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
      };
    } else {
      return {
        title: touched.title ? errors.title : '',
        description: touched.description ? errors.description : '',
        thumbnail: touched.thumbnail ? errors.thumbnail : '',
        stream: touched.stream ? errors.stream : '',
      };
    }
  };

  changeRef = (index: number) => {
    const n = this.state.selectedRefs.slice();
    n[index] = !n[index];
    this.setState({
      selectedRefs: n,
    });
  };

  changeAdapt = () => {
    this.setState({ adapted: !this.state.adapted });
  };

  render() {
    if (this.state.uploading && this.state.uploadETA)
      return (
        <div style={{ padding: '0 50px' }}>
          <div className="field-error">
            <Text as="p" variant="copy1">
              {strings.uploadingETA}:
            </Text>
            <Duration seconds={this.state.currentTime} className="duration" />/
            <Duration seconds={this.state.uploadETA} className="duration" />
            <Text as="p" variant="copy1">
              {strings.doNotLeavePageUntilUploadIsFinished}
            </Text>
          </div>
        </div>
      );

    const initialValues = this.getInitialValues();
    return (
      <div style={{ padding: '0 50px' }}>
        <Formik
          validationSchema={this.getValidator()}
          initialValues={initialValues}
          onSubmit={(values: any) => {
            this.setState({ sending: true });
            if (this.props.type === SessionType.DEMAND) {
              this.submitOnDemand(values);
            } else if (this.props.type === SessionType.TRAINING) {
              this.submitTraining(values);
            } else if (this.props.type === SessionType.EXTERNAL) {
              this.submitExternal(values);
            } else if (this.props.type === SessionType.LIVETERMINATED) {
              this.submitliveTerminated(values);
            } else if (this.props.type === SessionType.LIVE) {
              this.submitLive(values);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => {
            const et = this.getErrors(touched, errors);
            return (
              <EditDialog
                title={this.props.thisSession ? strings.editSession : strings.createSession}
                close={this.props.abort}
                maxWidth="lg"
                cms
              >
                <LocalizedForm
                  languages={getLocalizedLanguages([this.props.channel])}
                  localizeInputs={[
                    'title',
                    'description',
                    'subtitle',
                    'TitleDescriptionSubformContent',
                    'MediaSubform',
                  ]}
                  initialValues={initialValues}
                  onLanguageChange={(i) => {
                    this.setState({ selectedLanguage: i });
                  }}
                  autoParse={[]}
                >
                  <TableContainer>
                    <Table className={this.props.classes.table} aria-label="simple table">
                      <TableBody>
                        <TitleDescriptionSubformContent
                          type={this.props.type}
                          headline={this.props.classes.headline}
                          et={et}
                          key="TitleDescriptionSubformContent"
                          selectedLanguage={this.state.selectedLanguage}
                        />

                        <EventTimeSubform
                          type={this.props.type}
                          headline={this.props.classes.headline}
                          et={et}
                          initialValues={initialValues}
                          setDateStart={(d) => this.setState({ startDate: d })}
                          setTimezoneStart={(t) => this.setState({ startTimezone: t })}
                          setDateEnd={(d) => this.setState({ endDate: d })}
                          setTimezoneEnd={(t) => this.setState({ endTimezone: t })}
                        />
                        <StreamSubform
                          type={this.props.type}
                          headline={this.props.classes.headline}
                          et={et}
                          initialValues={initialValues}
                        />

                        <SessionRefSubform
                          type={this.props.type}
                          changeRef={this.changeRef}
                          selectedRefs={this.state.selectedRefs}
                          refs={this.props.refs}
                          headline={this.props.classes.headline}
                        />

                        <MediaSubform
                          key="MediaSubform"
                          type={this.props.type}
                          thisSession={this.state.session}
                          headline={this.props.classes.headline}
                          inline={this.props.classes.inline}
                          et={et}
                          openInterruptDialog={this.openInterrupt}
                          openDocManager={this.openDocManager}
                          setFieldValue={setFieldValue}
                          values={values}
                          initialSubtitles={this.state.session?.subtitles}
                          sessionId={this.state.id}
                          level={this.props.level}
                          selectedLanguageCode={
                            getChannelsLanguages([this.props.channel])[this.state.selectedLanguage]
                              ?.code
                          }
                        />

                        <SettingsSubform
                          type={this.props.type}
                          settings={this.state.settings}
                          interrupts={this.state.session?.interrupts || []}
                          setSetting={this.setSetting}
                        />

                        <TableRow key="ama">
                          <TableCell>
                            <LayoutItem spaceStackStart="m">
                              <TaggingSubform
                                headline={this.props.classes.headline}
                                type="sessions"
                                placeholder={
                                  !errors.title && values.tag.length === 0
                                    ? getLocalizedValue(JSON.stringify(values.title))
                                    : undefined
                                }
                              />
                            </LayoutItem>
                          </TableCell>
                        </TableRow>

                        <ControlsSubform
                          level={this.props.level}
                          changeAdapt={this.changeAdapt}
                          abort={this.props.abort}
                          adapted={this.state.adapted}
                          classes={this.props.classes}
                          sending={this.state.sending}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LocalizedForm>
              </EditDialog>
            );
          }}
        </Formik>
        <MediaLibraryDialog
          open={!!this.state.documentManager}
          onClose={this.closeDocManager}
          onSubmit={(files: MediaFile[]) => {
            this.setState({ documentIds: [...files.map((f) => f.mediaId)] });
            this.closeDocManager();
          }}
          interactType={'selectMultiple'}
          fileTypes={'image/png,image/jpeg,application/pdf'}
          preselectedMediaIds={this.state.documentIds}
        />

        {this.state.openInterruptDialog && this.state.id && (
          <InterruptsDialogs
            openQuestionDialog={this.state.openInterruptDialog}
            closeQuestionDialog={this.closeInterrupt}
            sessionId={this.state.id}
            interrupts={this.state.session?.interrupts || []}
            level={this.props.level}
            refreshInterrupts={this.refreshInterrupts}
            channel={this.props.channel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  user: noNullUser(state.app.user),
});

const mapDispatchToProps = { triggerNotification };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DCLXCmsSessionEdit));
