import LOT from './LOT';
import { LotBadBrowser } from './LotBadBrowser';
import { useIsMobile, useIsDevice } from '../../../utils/hooks';

/** interface for LotWrap props coming from parent component LOT  */
interface P {
  match: {
    params: {
      id: string | number;
    };
  };
}

/** Loads the lot and checks the browser */
const LotWrap = (props: P) => {
  const isBadBrowser = false;
  const tablet = useIsMobile();
  const mobile = useIsDevice().mobilePortrait;
  if (isBadBrowser) {
    return <LotBadBrowser />;
  } else {
    return <LOT match={props.match} tablet={tablet} mobile={mobile} />;
  }
};

export { LotWrap };
